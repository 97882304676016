import { LOCALES } from 'src/i18n/locales';
import { TLocaleId } from 'src/i18n';

const germanLangTranslations: { [key: string]: { [idKey in TLocaleId]: string } } = {
  [LOCALES['Deutsch (German)']]: {
    'MursionPortal.SimAttendance.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Learner’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'SessionReports.ErrorOnLoadingVideo': 'Bitte verwenden Sie einen Laptop oder Desktop-Computer, um Ihre Aufzeichnung anzusehen.',
    'MursionPortal.ConfirmationModal.Project.InvitationsConfirmationText': 'Sind Sie sicher, dass Sie <strong>alle zugewiesenen Lernenden</strong>, die noch keine Szenarien im Projekt abgeschlossen haben, einladen möchten? Sie werden eine Einladung per E-Mail erhalten, um einen Plan über Nacht zu erstellen.',
    'MursionPortal.ConfirmationModal.Scenario.InvitationsConfirmationText': 'Sind Sie sicher, dass Sie <strong>alle zugewiesenen Lernenden</strong>, die dieses Szenario noch nicht abgeschlossen haben, einladen möchten? Sie werden eine Einladung per E-Mail erhalten, um einen Plan über Nacht zu erstellen.',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays.Tooltip': 'Anzahl der Tage, die der Kunde seine Videoaufzeichnungsdaten aufbewahren möchte. Nach Ablauf dieser Anzahl von Tagen werden die Videoaufzeichnungen gelöscht.',
    'Settings.SSO.CopyScenarioLink': 'Kopieren Sie den Szenario-Einladungslink',
    'MursionPortal.Label.VideoRecordingSharing': 'Freigabe oder Herunterladen von Sitzungsvideos durch Benutzer',
    'MursionPortal.Status.Capitalized.LateCancelled': 'SPÄT_STORNIERT',
    'MursionPortal.Status.Capitalized.EarlyCancelled': 'FRÜH_STORNIERT',
    'MursionPortal.Status.Abbreviation.LateCancelled': 'LC',
    'MursionPortal.Status.Abbreviation.Cancelled': 'C',
    'MursionPortal.Text.Congratulation': 'Herzlichen Glückwunsch!',
    'MursionPortal.Text.PasswordChanged': 'Ihr Passwort wurde geändert',
    'MursionPortal.Text.PasswordShouldBeSame': 'Ihre neuen Passwörter müssen übereinstimmen.',
    'Filters.LateRescheduled': 'Verspätet umgeplant',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Scheduled': 'Anstehende Sitzungen, die geplant sind, aber noch nicht abgeschlossen wurden (einschließlich laufender Sitzungen)',
    'MursionPortal.Checkbox.EnableSmartMetrics': 'Aktivieren Sie die Sitzungs-Sprachanalyse für Lernende',
    'Dashboard.SimulationTable.Column.TimesLateRescheduled': 'Zeiten verspätet umgeplant',
    'Dashboard.SimulationTable.ColumnTooltip.TimesLateRescheduled': 'Zeiten, in denen diese Simulation verspätet umgeplant wurde',
    'SessionReports.Label.CopyVideoLink': 'Videolink kopieren',
    'Login.LoginAgain': 'Erneut anmelden',
    'Dashboard.VideosTable.Column.SessionStart': 'Sitzungsbeginn',
    'Filters.Date.LastSevenDays': 'Letzte Woche',
    'Filters.Date.NextSevenDays': 'Nächste Woche',
    'Filters.Date.ThisYear': 'Dieses Jahr',
    'Mursion.Portal.Status.LearnerMissed': 'Lernenden verpasst',
    'Mursion.Portal.Status.SystemCancelled': 'Storniert',
    'Mursion.Portal.Status.LateCancelled': 'Spät storniert',
    'Mursion.Portal.Status.EarlierCancelled': 'Früh storniert',
    // 'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': 'Abgeschlossen',
    'MursionPortal.Dashboard.UpcomingSessions': 'Kommende Sitzungen',
    'MursionPortal.Label.Opened': 'Geöffnet',
    'MursionPortal.Label.ActedOn': 'Gearbeitet an',
    'MursionPortal.Label.Unread': 'Ungelesen',
    'MursionPortal.Label.Timeline': 'Projekt-Zeitleiste',
    'Projects.ProjectInfo.Label.TimelineUtc': 'Projekt-Zeitleiste (UTC)',
    'MursionPortal.ScenarioInfo.Header.Description': 'Beschreibung',
    'MursionPortal.TimelineUTC': 'Projekt-Zeitleiste<code>{word}</code> (UTC)',
    'MursionPortal.Label.ChangePassword': 'Passwort ändern',
    'MursionPortal.Label.ChangeYourPassword': 'Ändern Sie Ihr Passwort',
    'MursionPortal.Label.NewChangePassword': 'Geben Sie Ihr neues Passwort ein',
    'MursionPortal.Label.OldPassword': 'Geben Sie ihr aktuelles Passwort ein',
    'MursionPortal.Label.RepeatChangePassword': 'Geben Sie Ihr neues Passwort erneut ein',
    'MursionPortal.Prompt.SessionScheduled': '<code>Sitzung</code> wurde storniert!',
    'MursionPortal.Status.Booked': 'Gebucht',
    'MursionPortal.Status.Pending': 'Ausstehend',
    'MursionPortal.Completed': 'Abgeschlossen',
    'MursionPortal.Status.Missed': 'Verpasst',
    'MursionPortal.Status.Cancelled': 'Storniert',
    'MursionPortal.Status.LateCancelled': 'Spät storniert',
    'MursionPortal.Status.EarlyCancelled': 'Früh storniert',
    'MursionPortal.Status.LicenseCancelled': 'Storniert',
    'MursionPortal.Status.Error': 'Fehler',
    'MursionPortal.ConversationalFlow.label.zoom.all': 'Alle',
    'Clients.ImportUsers.DownloadErrorsButton': 'Download-Fehler',
    'Mursion.Portal.Edit.Team.DownloadErrorButton': 'Download-Fehler',
    'MursionPortal.Dashboard.NextOnProject': 'Zu planende Szenarien',
    'MursionPortal.Dashboard.NextOnProject.Footer.EndDate': 'Sie haben bis zum {endDate} Zeit, um dieses Szenario abzuschließen.',
    'MursionPortal.Dashboard.NextOnProject.Footer.CompletionRate': 'Sie haben bis zum {endDate} Zeit, um bis zu {completionRate} Mal abzuschließen.',
    'MursionPortal.Dashboard.NothingPlanned.text1': 'Sie haben alles aufgeholt! Wenn Sie',
    'MursionPortal.Dashboard.NothingPlanned.text2': 'etwas zu planen haben, wird es hier angezeigt.',
    'MursionPortal.Learner.Missed': 'LERNENDEN VERPASST',
    'MursionPortal.AriaImage.Attribute.Next': 'Nächste:',
    'MursionPortal.AriaImage.Attribute.Close': 'Schließen:',
    'MursionPortal.AriaImage.Attribute.Back': 'Zurück:',
    'MursionPortal.AriaImage.Attribute.Delete': 'Löschen:',
    'MursionPortal.AriaImage.Attribute.Remove': 'Entfernen',
    'MursionPortal.AriaImage.Attribute.Document': 'Dokument',
    'MursionPortal.AriaImage.Attribute.Video': 'Video',
    'MursionPortal.AriaImage.Attribute.DeleteDocument': 'Dokument löschen',
    'MursionPortal.ScenarioInfo.CompletionRate': '{completionRate} Zeiten',
    'MursionPortal.Button.RefreshPage': 'Seite neu laden',
    'MursionPortal.Prompt.CalendarUpdatedOutdatedNotification': 'Sind Sie sicher, dass Sie die Seite schließen möchten, denn dann wäre Ihr Kalenderblatt veraltet.',
    'Reports.SessionListHeader.Label.AverageTime': 'Durchschnittliche Zeit <code>in jeder Sitzung</code>',
    'MursionPortal.Users.Archive.HasSessions': 'Der Simulationsspezialist kann nicht archiviert werden, da eine oder mehrere Sitzungen für diesen Simulationsspezialisten geplant sind. Bitte tauschen oder ändern Sie den Simulations-Spezialisten.',
    'MursionPortal.Label.InactiveSIMCertification.Tooltip': 'Verwenden Sie diese Option, wenn es einen Simulator gibt, der für diese Simulation zertifiziert ist, aber nicht für neue Simulationen verfügbar ist.',
    'MursionPortal.Label.InactiveSIMCertification': 'Inaktive Sim-Zertifizierung',
    'Dashboard.Filters.FilterBySessionDate': 'NACH SITZUNGSDATEN FILTERN',
    'Dashboard.LeftPane.CategoryHeader.Emails': 'E-MAILS',
    'Projects.ProjectInfo.Label.CancellationDeadline.Tooltip': 'Dies ist die Anzahl der Tage/Stunden/Minuten, in denen ein Lernender stornieren kann, sobald er für eine Simulation innerhalb des Projekts registriert ist. Wenn Lernende innerhalb dieser Frist stornieren, wird die Sitzung für die Abrechnung als abgeschlossen betrachtet.',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording': 'Sitzungsaufzeichnung zulassen',
    'MursionPortal.Label.SendRecordedVideosTo': 'Senden Sie aufgezeichnete Videos an:',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording': 'Erlauben Sie dem Lernenden, an der Sitzung teilzunehmen, wenn er der Aufnahme nicht zustimmt',
    'Projects.ProjectInfo.SchedulingInterval': 'Planungsintervall',
    'Projects.ProjectInfo.SchedulingInterval.Tooltip': 'Ermöglicht die Planung in einer anderen Kadenz als der Dauer der Sitzung.',
    'Projects.ProjectInfo.SchedulingIntervalLength': 'Länge des Planungsintervalls',
    'Projects.ProjectInfo.SchedulingIntervalLength.tooltip': 'Bestimmt die Häufigkeit der Planungsblöcke, die ein Lernender sieht. Wenn Sie 15 einstellen, sehen die Lernenden die Planungsblöcke in 15-Minuten-Intervallen. Hier wird nicht die Dauer der Sitzung festgelegt, sondern nur, wie häufig die Sitzungen stattfinden.',
    'Projects.ProjectInfo.DeliveryMode.tooltip': 'Die von Ihren Lernenden verwendete Plattform. Stellen Sie auf ML3, wenn Sie Mursion Magic und/oder die ML3 Direktverbindungssoftware aktivieren möchten. ML3z = Zoom. Mursion Meet = Google Meet.',
    'MursionPortal.Label.WebOnly': 'Nur Web',
    'MursionPortal.Label.DesktopOnly': 'Nur Desktop',
    'MursionPortal.Label.DesktopAndWeb': 'Desktop und Web',
    'MursionPortal.EmailCategory.CallsToAction': 'Handlungsaufforderungen',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Sent': 'Gesendet',
    'MursionPortal.Dashboard.EmailAnalytics.Label.AllEmails': 'Alle E-Mails',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Trends': 'Trends',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CategorySummary': 'Kategorie-Zusammenfassungen',
    'MursionPortal.Dashboard.EmailAnalytics.Label.RegistrationEmail': 'Registrierungs-E-Mails',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CallToActionEmail': 'Handlungsaufforderungs-E-Mails',
    'MursionPortal.Dashboard.EmailAnalytics.Label.SchedulingEmail': 'E-Mails planen',
    'MursionPortal.Dashboard.EmailAnalytics.Label.ReSchedulingEmail': 'E-Mails umplanen',
    'MursionPortal.Dashboard.EmailAnalytics.Label.VideoEmail': 'Video-E-Mails',
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailSent': 'E-Mail gesendet',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailAnalytics': 'Die E-Mail-Analyse gibt Ihnen einen Überblick darüber, wie die Lernenden mit <link>E-Mails von Mursion</link> umgehen. E-Mails können in eine der folgenden drei Kategorien fallen',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailOpened': 'Ein Lernender hat eine oder mehrere der ihm zugesandten E-Mails geöffnet.',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailActedOn': 'Ein Lernender hat die E-Mail geöffnet und auf einen oder mehrere Links geklickt, wie z. B. dem <strong>Plan</strong> oder <strong>Teilnahme an Simulation</strong>.',
    'MursionPortal.Dashboard.EmailAnalytics.Info.Unread': 'Ein Lernender hat die E-Mail nicht geöffnet. Wenn es eine große Anzahl ungelesener E-Mails gibt, vergewissern Sie sich, dass Ihre Lernenden diese auch erhalten. Wenden Sie sich an Ihr IT-Team, um sicherzustellen, dass <color>no-reply@mursion.com</color> nicht von den Spamfiltern Ihres Unternehmens blockiert wird.',
    'MursionPortal.Dashboard.Tooltip.RegisterEmail': 'E-Mails an einen Benutzer, in denen dieser aufgefordert wird, ein Mursion-Konto zu erstellen',
    'MursionPortal.Dashboard.Tooltip.CallToActionEmail': 'Erinnerungs-E-Mails, die von einem Account Owner von der Projekt- oder Szenario-Seite aus gesendet werden',
    'MursionPortal.Dashboard.Tooltip.SchedulingEmail': 'Sitzungsspezifische E-Mails. Einladungen zu Plänen und andere E-Mails zur Teilnahme an Sitzungen',
    'MursionPortal.Dashboard.Tooltip.ReschedulingEmail': 'E-Mails mit der Aufforderung an den Lernenden, den Plan nach einer verpassten oder stornierten Simulation zu verschieben',
    'MursionPortal.Dashboard.Tooltip.VideoEmail': 'E-Mails, die einen Lernenden darauf hinweisen, dass ein Video seiner Simulation verfügbar ist',
    'MursionPortal.Dashboard.EmailCategoryFilter': 'E-Mail-Kategorie',
    'MursionPortal.Dashboard.EmailCategoryFilter.Search': 'E-Mail-Kategorien durchsuchen...',
    'MursionPortal.Dashboard.EmailTypeFilter.SessionRescheduled': 'Sitzung umgeplant',
    'MursionPortal.Dashboard.EmailTypeFilter.Search': 'E-Mail-Typen suchen...',
    'MursionPortal.Text.NoImageAvailable': 'Kein Bild verfügbar',
    'Mursion.Portal.Edit.Team.Instruction.ExportFile': 'Klicken Sie unten auf <strong>Aktuelle Mitgliedschaften herunterladen</strong>, um die Teamzuordnungen für Lernende zu aktualisieren (<strong>edit_learner_team_memberships.xlsx</strong>).',
    'Mursion.Portal.Edit.Team.DownloadExcelTemplate': 'Aktuelle Mitgliedschaften herunterladen',
    'MursionPortal.Project.SameDay': 'Am selben Tag',
    'MursionPortal.Project.CancelationDeadline.Validation': 'Die Frist für die Stornierung sollte größer als 0 sein.',
    'ClientUserTable.AddNewUsers.RolesHeading': 'Sie können immer nur eine Art von Benutzer hinzufügen. Wählen Sie die Art von Benutzer, die Sie hinzufügen möchten.',
    'ClientUserTable.AddNewUsers.Roles.AccountOwner.Tooltip': 'Ein Account Owner ist der Hauptadministrator für Ihr gesamtes Unternehmen.',
    'ClientUserTable.AddNewUsers.Roles.Facilitator.Tooltip': 'Ein Facilitator ist ein Administrator auf Teamebene, der ein bestimmtes Team oder mehrere Teams verwaltet.',
    'ClientUserTable.AddNewUsers.Roles.Learner.Tooltip': 'Ein Lernender ist jeder, der an einer Mursion-Simulation teilnimmt. Das ist der größte Teil Ihrer Nutzerschaft.',
    'ClientUserTable.AddNewUsers.Heading': 'Neue(n) Benutzer hinzufügen',
    'ClientUserTable.AddNewUsers.Roles.Heading': 'Weisen Sie den Benutzern eine Rolle zu',
    'ClientUserTable.AddNewUsers.ImportUsers': 'Bitte führen Sie die folgenden Schritte für bis zu 500 Benutzer aus. Wenn Sie mehr als 500 importieren möchten, verwenden Sie unsere Funktion "<code>Benutzer importieren</code>".',
    'ClientUserTable.AddNewAttributes.label': 'Benutzerattribute hinzufügen',
    'ClientUserTable.AddNewAttributes.Header.Text': 'Beachten Sie, dass diese Attribute auf alle Benutzer in dieser Liste angewendet werden.',
    'ClientUserTable.UserAuthentication.label': 'Benutzerauthentifizierung',
    'ClientUserTable.NewUsersAdded.Heading': 'Neue(r) Benutzer hinzugefügt!',
    'ClientUserTable.AddNewUsers.AssignTeams.Heading': 'Weisen Sie den Benutzern (ein) Team(s) zu',
    'ClientUserTable.AddNewUsers.AssignTeams.CreateTeam': 'Lernende und Facilitators müssen einem Team zugewiesen werden. Wenn Sie noch kein Team erstellt haben, <code>klicken Sie bitte hier.</code>',
    'ClientUserTable.AddNewUsers.AssignTeams.SelectTeams': 'Team(s) auswählen',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Validation': 'Bitte fügen Sie 500 oder weniger Benutzer hinzu. Um mehr als 500 Benutzer zu importieren, können Sie unsere Funktion "Benutzer importieren" verwenden.',
    'ClientUserTable.AddNewUsers.AssignTeams.Validation': 'Sie müssen mindestens ein Team auswählen.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Heading': 'Benutzer-E-Mail-Adressen hinzufügen',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.SubHeading': 'Sie können eine E-Mail nach der anderen hinzufügen oder eine Liste einfügen. Die E-Mails sollten durch ein Leerzeichen, Komma oder Semikolon getrennt werden.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Placeholder': 'Geben Sie die E-Mail-Adressen hier ein oder fügen Sie sie ein.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.MaximumUsersAllowed': '{maximumUsersAllowed} Benutzer gleichzeitig',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Total': 'Gesamt',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Duplicate': 'Duplikat',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.AlreadyExists': 'Ist bereits vorhanden',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Invalid': 'Ungültig',
    'ClientUserTable.AddNewUsers.AddNewAttributes.SignLanguageInterpreter': 'Dolmetscher für Gebärdensprache',
    'ClientUserTable.AssignTeams.TotalUsers': 'Sie sind dabei, <code>{users} neue {role}</code> hinzuzufügen',
    'ClientUserTable.AssignTeams.AddAndUpdateUsers': 'Sie sind dabei, <code>{users} neue {role}</code> hinzuzufügen und <code>{existingUsers} vorhandene {role}</code> zu aktualisieren',
    'ClientUserTable.AssignTeams.UpdateUsers': 'Sie sind dabei, <code>{existingUsers} vorhandene {role}</code> zu aktualisieren',
    'ClientUserTable.AssignTeams.NumberOfTeams': 'Wird <code>{numberOfTeams} Team(s)</code> zugewiesen',
    'ClientUserTable.Confirmation.Heading': 'Bitte vor dem Hinzufügen bestätigen',
    'MursionPortal.InviteToSchedule.Confirmation.Text': 'Erfahren Sie hier mehr:<code>Zum Planen einladen</code>',
    'MursionPortal.AccountOwnerGuides.Confirmation.Text': 'Anleitungen für Account Owner',
    'MursionPortal.FacilitatorGuides.Confirmation.Text': 'Facilitator-Leitfaden',
    'MursionPortal.Users.Confirmation.Text': 'Ihr(e) Benutzer wurden dem Mursion-Portal hinzugefügt.',
    'MursionPortal.Confirmation.Header.Text.BackToUsers': 'Zurück zu den Benutzern',
    'MursionPortal.Users.Next.Steps.Text': 'Nächste Schritte',
    'MursionPortal.Users.StepOne.Text': 'Schritt 1:',
    'MursionPortal.Users.StepTwo.Text': 'Schritt 2:',
    'MursionPortal.Users.StepThree.Text': 'Schritt 3:',
    'MursionPortal.Users.ThatIt.Text': 'Das war\'s!',
    'MursionPortal.Users.InviteToScheduleLearner.Text': 'Laden Sie Ihre Lernenden zum Planen ein',
    'MursionPortal.Users.LearnersLink.Text': 'Sie können diesen Link an Ihre Lernenden senden',
    'MursionPortal.Users.InviteToSchedule.ProjectorScenario.Text': 'Verwenden Sie die Schaltfläche <strong>Zum Planen einladen</strong> für ein beliebiges Projekt oder Szenario.',
    'MursionPortal.Users.CopyInviteLink.Text': 'Einladungslink kopieren',
    'MursionPortal.Users.ShareContent.Text': 'Teilen Sie Support-Inhalte mit Ihren Administratoren',
    'MursionPortal.Users.AOAndFacilitators.Responsibilities.Text': 'Account Owner und Facilitators haben unterschiedliche Aufgaben und müssen möglicherweise mehr über das Portal erfahren. Geben Sie diese Links an Ihre Account Owner und Facilitators weiter, damit sie Antworten auf alle Fragen finden können, die sie auf ihrer Mursion-Reise haben könnten.',
    'MursionPortal.Users.Administrators.Text': 'Ihre Administratoren haben nun Zugriff auf das Mursion-Portal.',
    'MursionPortal.Users.Progress.Text': 'Ihre Lernenden beginnen mit der Planung ihrer Simulationen. Sie können ihren Fortschritt auf Ihrem Dashboard verfolgen.',
    'MursionPortal.Users.Access.Text': 'Stellen Sie sicher, dass Ihre IT-Abteilung diesen Benutzern Zugriff auf die Mursion-App gewährt hat',
    'MursionPortal.Users.Registrationemail.Text': 'Ihre Benutzer sollten gerade die Registrierungs-E-Mail von no-reply@mursion.com erhalten haben.',
    'MursionPortal.AddMoreUsers.Btn.Text': 'Weitere Benutzer hinzufügen',
    'MursionPortal.Users.Ok.Btn.Text': 'OK, verstanden!',
    'Users.Button.AddNewUsers': 'Neue Benutzer hinzufügen',
    'MursionPortal.Project.CancelationDeadline.UpperLimit.Validation': 'Die Frist für die Stornierung darf nicht länger als 365 Tage sein.',
    'MursionPortal.Dashboard.EmailTypeFilter.PasswordReset': 'Passwort zurücksetzen',
    'MursionPortal.Dashboard.EmailTypeFilter.Verification': 'Überprüfung',
    'MursionPortal.Dashboard.EmailTypeFilter.InviteToSchedule': 'Zum Plan einladen',
    'MursionPortal.Dashboard.EmailTypeFilter.Summary': 'Zusammenfassung',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleMissed': 'Umplanung verpasst',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleCancelled': 'Umplanung storniert',
    'MursionPortal.Dashboard.EmailTypeFilter.PSRescheule': 'Umgeplant durch PS (Professioneller Service)',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingCorrupted': 'Videoaufzeichnungen beschädigt',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingNotUploaded': 'Videoaufzeichnungen nicht hochgeladen',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingAvailable': 'Videoaufzeichnung verfügbar',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingShared': 'Videoaufzeichnung geteilt',
    'MursionPortal.Dashboard.EmailTypeFilter.Booking': 'Buchung',
    'MursionPortal.Dashboard.EmailTypeFilter.CodeOfConuct': 'Verhaltenskodex',
    'MursionPortal.Dashboard.EmailTypeFilter.UserRemoved': 'Benutzer entfernt',
    'MursionPortal.Dashboard.EmailTypeFilter.AttendanceReminer': 'Anwesenheitserinnerung',
    'Users.Button.AddNewLearners': 'Neue Lernende hinzufügen',
    'MursionPortal.Scenario.Label.LastDelivered': 'Zuletzt geliefert',
    'MursionPortal.Scenario.Label.TotalDeliveries': 'Gesamtlieferungen',
    'MursionPortal.Scenario.Text.NotYetDelivered': 'Noch nicht geliefert',
    'ClientUserTable.AddNewUsers.UpdateUserWarning': 'Diese {existingUsers} Benutzer existieren bereits. Wenn Sie fortfahren, werden diese Benutzer den ausgewählten Teams hinzugefügt (zusätzlich zu allen Teams, denen sie bereits zugewiesen sind).',
    'MursionPortal.AddNewUsers.Facilitators': 'Facilitator(s)',
    'ClientUserTable.AddNewUsers.Learners': 'Lernende(r)',
    'MursionPortal.Scheduling.TimeSlotsUnavailableMessage': 'Die ausgewählten Zeitfenster sind nicht mehr verfügbar. Bitte aktualisieren Sie, um planbare Zeitfenster anzuzeigen',
    'MursionPortal.Client.EmailTypeFilter.SessionFirstReminder': 'Erste Sitzungserinnerung',
    'MursionPortal.Client.EmailTypeFilter.SessionSecondReminder': 'Zweite Sitzungserinnerung',
    'Projects.ProjectInfo.Label.ShowSIMAvailaiblity': 'SIM-Verfügbarkeit anzeigen',
    'Projects.ProjectInfo.Label.ShowAvailaiblity.Tooltip': 'Wenn ein Kursteilnehmer einen Plan aufstellt, sieht er die Verfügbarkeit von SIM-Spezialisten für den von Ihnen ausgewählten Bereich.',
    'Projects.InputValidation.GreaterThanZero': 'Sollte größer als 0 sein.',
    'MursionPortal.Weeks': 'Woche(n)',
    'MursionPortal.Scenario.CompletionRateFulfilled': 'You cannot schedule this simulation as you have completed the maximum number of simulations for this scenario or engagement as set by your organization.',// TODO add translations
    'MursionPortal.Dashboard.EmailTypeFilter.TimeSlotRequest': 'Zeitfensteranfrage',
    'MursionPortal.Dashboard.EmailAnalytics.OpenAndActed.Label': 'Offene und bearbeitete Daten entsprechen dem Stand vom 24. November 2022.',
    'MursionPortal.Dashboard.EmailAnalytics.Prior.Label': 'Vor diesem Datum werden alle E-Mails als ungelesen kategorisiert.',
    'MursionPortal.Learners.Filters.LearnerCompletion': 'Abgeschlossen durch den Lernenden',
    'MursionPortal.Learners.Filters.LearnerCompletion.NotYetCompleted': 'Noch nicht abgeschlossen',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedOneSession': '1 Sitzung abgeschlossen',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedTwoPlusSessions': '2 und mehr Sitzungen abgeschlossen',
    'MursionPortal.Learners.Filters.MissedLateActivity': 'Versäumte/verspätete Aktivität',
    'MursionPortal.Learners.Filters.MissedLateActivity.1Session': '1 Sitzung',
    'MursionPortal.Learners.Filters.MissedLateActivity.2Sessions': '2 Sitzungen',
    'MursionPortal.Learners.Filters.MissedLateActivity.3Sessions': '3 Sitzungen',
    'MursionPortal.Learners.Filters.MissedLateActivity.4PlusSessions': '4 und mehr Sitzungen',
    'MursionPortal.BackToCompletedSessions': 'Zurück zu Abgeschlossene Sitzungen',
    'MursionPortal.Scenario.InviteToSchedule.Modal.Text': 'Möchten Sie Einladungen an <strong>alle</strong> zugewiesenen Lernenden senden, die dieses Szenario noch nicht abgeschlossen haben, oder <strong>ausgewählte Lernende</strong> einladen?',
    'MursionPortal.BackToSessionSummaries': 'Zurück zu Sitzungszusammenfassungen',
    'MursionPortal.BackToLearnerSessions': 'Zurück zu den Sitzungen für Lernende',
    'MursionPortal.LearnerSessionReport.SessionObjective': 'Sitzungsziel',
    'MursionPortal.LearnerSessionReport.SessionStrategy': 'Wie gut haben Sie die Strategien angewendet?',
    'MursionPortal.LearnerSessionReport.SessionStrategyIcon': 'Sitzungsstrategie: Der Lernende sollte aktives Lernen demonstrieren',
    'MursionPortal.LearnerSessionReport.SessionPerspective.Title': 'Weiter so!',
    'MursionPortal.LearnerSessionReport.SessionDetail': 'Szenariobeschreibung',
    'MursionPortal.LearnerSessionReport.SessionDate': 'Sitzungsdatum',
    'ClientUserTable.AddNewUsers.Procced.Button.Tooltip': 'Bitte entfernen Sie alle doppelten oder ungültigen E-Mails, bevor Sie fortfahren.',
    'MursionPortal.BackToSessionDetail': 'Zurück zu den Sitzungsdetails',
    'MursionPortal.SimAttendance.Instructions.Label': 'Mursion Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText': 'Select an attendance status for each attendee (mouse over the status to learn more). Your status is gathered automatically – these statuses are for the learner ONLY.',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.Label1': 'Software License Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText1': 'Select an attendance status for each attendee (mouse over the status to learn more). If you experienced an error in the session, please also provide the error details. To do this, select the type of error under the “SIM Reported Error” section and add context in the Additional Details text box. Additional details about attendance--even if it’s not an Error--are encouraged, but are optional. If you have any further questions about attendance, please reach out to your Simulation Program Admin.',// TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailAnalytics': 'E-Mail-Analyse',
    'MursionPortal.Session.LearnerSession.Report': 'Bericht',
    'MursionPortal.Session.LearnerSession.Recording': 'Aufzeichnung',
    'MursionPortal.Session.LearnerSession.SessionDetails': 'Sitzungsdetails',
    'MursionPortal.Session.LearnerSessionReport': 'Sitzungsbericht des Lernenden',
    'MursionPortal.Scenario.InviteToScedule.Modal.SelectLearners': 'Lernende auswählen',
    'MursionPortal.Scenario.Button.Label': 'Kopieren Sie die E-Mails der Lernenden',
    'MursionPortal.VideoStatusFilter.Label': 'Videostatus',
    'MursionPortal.TooltipText.VideoNotUploaded': 'Wenn dieses Video nach 48 Stunden nicht hochgeladen wird, wenden Sie sich bitte an den Support.',
    'MursionPortal.VideoStatusFilter.SearchText': 'Videostatus suchen...',
    'MursionPortal.VideoStatusFilter.Uploaded': 'Hochgeladen',
    'MursionPortal.VideoStatusFilter.NotUploaded': 'Nicht hochgeladen',
    'MursionPortal.Scenario.Invite.Modal.Button.Label': 'Einladen',
    'MursionPortal.Scenario.CopyEmails.Modal.Button.Label': 'E-Mails kopieren',
    'MursionPortal.Scenario.AllLearners.InvitationsSentOvernight': 'Ihre Einladungen werden über Nacht verschickt, wenn der Lernende in den letzten 2 Tagen nicht bereits einen Plan erstellt oder eine Einladung erhalten hat.',
    'MursionPortal.Scenario.SelectedLearners.InvitationsSentOvernight': '<code>Ausgewählte Lernende</code> erhalten über Nacht eine E-Mail, in der sie eingeladen werden, einen Plan zu erstellen, wenn sie in den letzten 2 Tagen noch keinen Plan erstellt oder eine Einladung erhalten haben.',
    'MursionPortal.Scenario.SelectedLearners.Confirm.Text': 'Sind Sie sicher, dass Sie <code>alle ausgewählten Lernenden</code> einladen möchten',
    'ClientUserTable.Column.LastInvited': 'Zuletzt eingeladen',
    'ClientUserTable.ColumnTooltip.LastInvited': 'Das Datum, an dem die letzte E-Mail-Einladung zur Planung verschickt wurde. Dies kann morgen sein, wenn Sie gerade eingeladen wurden.',
    'MursionPortal.Scenario.SelectedLearners.EmailsCopied': 'Die E-Mail-Adressen der ausgewählten Lernenden wurden in Ihre Zwischenablage kopiert',
    'MursionPortal.ScenarioInfo.MarkAllInactive': 'Alle als inaktiv markieren',
    'MursionPortal.ScenarioInfo.DecertifyAll': 'Alle dezertifizieren',
    'MursionPortal.ScenarioInfo.MarkAllInactive.Confirmation.Text': 'Möchten Sie wirklich alle SIM-Spezialisten deaktivieren?',
    'MursionPortal.ScenarioInfo.DecertifyAll.Confirmation.Text': 'Möchten Sie wirklich alle SIM-Spezialisten dezertifizieren?',
    'Dashboard.Button.ViewSession': 'Sitzung anzeigen',
    'MursionPortal.SessionReport.NotAvailable': 'Nicht verfügbar',
    'Projects.ProjectInfo.Checkbox.EnableReportTabSurvey.OneToOne': 'Registerkarte „Bericht“ für 1:1-Lieferungen aktivieren',
    'MursionPortal.Learner.Session.Report': 'Sitzungsbericht des Lernenden',
    'MursionPortal.Scenario.Sim.InactivedAll': '{effectedSims} von {totalSims} SIMs deaktiviert.',
    'MursionPortal.Scenario.Sim.DecertifiedAll': '{effectedSims} von {totalSims} SIMs dezertifiziert.',
    'MursionPortal.Scenario.Sim.DecertifiedFew': '{remainingSims} SIMs können nicht dezertifiziert werden, da für sie Sitzungen geplant sind. Markieren Sie sie als inaktiv, um ihre Sitzungen zu würdigen und zukünftige Sitzungszuweisungen zu verhindern.',
    'MursionPortal.AriaLabel.ViewMore': 'Mehr sehen',
    'MursionPortal.SimAttendance.SimulationAttendance': 'Anwesenheit der Simulationslernenden',
    'Mursion.Portal.Status.LearnerTechnicalError': 'Learner Technical Error',// TODO add translations
    'Mursion.Portal.Status.TooLateToComplete': 'Zu spät zum Abschließen',
    'Mursion.Portal.Status.LearnerElectedToLeave': 'Learner Elected to Leave',// TODO add translations
    'Mursion.Portal.Status.Unprepared.Tooltip': 'Der Lernende hat an der Simulation teilgenommen, sie aber nicht beendet, weil er nicht vorbereitet war oder aus anderen Gründen.',
    'MursionPortal.Label.SchedulingRate': 'Planquote',
    'Mursion.Portal.Status.No.Show': 'Nicht erscheinen',
    'MursionPortal.ScenarioInfo.SchedulingRate.Text': '{Code}-Zeiten',
    'MursionPortal.Scenario.Modal.Search.Placeholder': 'E-Mail Adresse, Vorname, Nachname suchen',
    'MursionPortal.Scenario.SchedulingRateFulfilled': 'Sie müssen zuerst die geplante Sitzung abschließen.',
    'MursionPortal.Scenario.InviteToScedule.Modal.Button.Tooltip': 'Wählen Sie den einzuladenden Lernenden',
    'MursionPortal.Learner.Session.Report.Report.SimulationRecording': 'Denken Sie über das Gespräch nach, indem Sie Ihre Simulationsaufzeichnung abspielen',
    'Dashboard.SimulationTable.ColumnTooltip.SoftwareVersion': 'Bezeichnet die verwendete Sitzungssoftware',
    'Dashboard.SimulationTable.Column.SoftwareVersion': 'Software',
    'MursionPortal.LearnerSessionReport.YourPerspective': 'Ihre Sichtweise',
    'MursionPortal.LearnerSessionReport.YourPerspectiveDescription': 'Ihr Selbstvertrauen zu Beginn der Simulation war <strong>{preConfidenceLevel}</strong> und nach der Simulation war <strong>{postConfidenceLevel}.</strong>',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryLow': 'sehr niedrig',
    'MursionPortal.LearnerSessionReport.YourPerspective.Low': 'niedrig',
    'MursionPortal.LearnerSessionReport.YourPerspective.Moderate': 'mäßig',
    'MursionPortal.LearnerSessionReport.YourPerspective.High': 'hoch',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryHigh': 'sehr hoch',
    'MursionPortal.LearnerSessionReport.Strategies.SuccessfullyStrategy': 'Sie haben diese Strategie <strong>erfolgreich</strong> demonstriert',
    'MursionPortal.LearnerSessionReport.Strategies.BenefitStrategy': 'Sie würden bei der Umsetzung dieser Strategie <strong>von etwas mehr Übung profitieren</strong>',
    'MursionPortal.SchedulingRate.ValidationMessage': 'Die Planungsquote muss kleiner oder gleich der Abschlussquote sein',
    'MursionPortal.LearnerSessionReport.Footer.FooterTitle': 'Nächste Schritte: Übung macht den Meister!',
    'MursionPortal.LearnerSessionReport.Footer.FooterSubTitle': 'Wiederholung ist der Schlüssel, um diese Fähigkeiten zur zweiten Natur zu machen.',
    'MursionPortal.LearnerSessionReport.SessionObjective.Body': 'Ihr Bericht wird in Kürze fertig sein',
    'MursionPortal.Dashboard.NextOnProject.Footer.schedulingRateFulfilled.Text': 'You cannot schedule this simulation at this time as you have currently reached the scheduling limit for this scenario or engagement set by your organization.',// TODO add translations
    'MursionPortal.Status.Abbreviation.LicenseCancelled': 'C',
    'MursionPortal.Scenario.CharacterLimitExceeded.Message': 'Sie haben das Zeichenlimit um {exceededCharLength} Zeichen überschritten. Bitte überarbeiten.',
    'MursionPortal.Scenario.AddedCharacter.Message': '{hinzugefügt} von {totalChar}',
    'MursionPortal.Scenario.CharacterLimit.Message': 'Zeichenbeschränkung: {maxLength}',
    'MursionPortal.Scenario.Description.block.default.text': 'Beschreibung hier hinzufügen',
    'MursionPortal.Scenario.AttachmentField.text': 'Sie können weitere Dateien hinzufügen',
    'MursionPortal.sessionTable.Column.OriginalSim': 'Ursprüngliche SIM',
    'MursionPortal.sessionTable.Column.NewSim': 'Neue SIM',
    'MursionPortal.Users.EditUser.InactivatedAll.Message': '{code} Zertifikat(e) wurde(n) deaktiviert.',
    'MursionPortal.Users.EditUser.InactivateAll.Confirmation': 'Möchten Sie wirklich alle Zertifikate für diesen SIM-Spezialisten deaktivieren?',
    'MursionPortal.Users.Modal.ActiveCerts.EmptyMessage': 'Keine aktiven Zertifikate',
    'MursionPortal.Users.Modal.InactiveCerts.EmptyMessage': 'Keine inaktiven Zertifikate',
    'MursionPortal.Users.Modal.ActiveCerts': 'Aktive Zertifikate',
    'MursionPortal.Users.Modal.InactiveCerts': 'Inaktive Zertifikate',
    'MursionPortal.Users.ActiveCerts.MarkAllInactive.Button': 'Alle als inaktiv markieren',
    'MursionPortal.SurveyFilter.Incomplete': 'Unvollständig',
    'MursionPortal.SurveyFilter.SearchText': 'Umfrage suchen...',
    'MursionPortal.Dashboard.SurveyReminder.Text': 'Sie haben {surveyCount} Umfragen zu beantworten',
    'MursionPortal.Dashboard.RemindMeLater': 'Erinnern Sie mich später',
    'MursionPortal.Dashboard.SurveysIncomplete': 'Umfragen unvollständig',
    'MursionPortal.AriaLabel.NormalFont': 'Normale Schrift',
    'MursionPortal.AriaLabel.MediumFont': 'Mittlere Schrift',
    'MursionPortal.AriaLabel.LargeFont': 'Große Schrift',
    'MursionPortal.AriaLabel.Font': 'Schrift',
    'Dashboard.LeftPane.CategoryHeader.RescheduledAndCancelled': 'Umgeplant & Storniert',
    'MursionPortal.Dashboard.Summary.Certifications': 'Zertifizierungen',
    'MursionPortal.SessionsTable.ActionTitle.Pickup': 'abholen',
    'MursionPortal.Label.MissCancellationRate': 'Fehl-/Stornierungsquote',
    'MursionPortal.Label.NotAllowed': 'Nicht erlaubt',
    'Dashboard.LeftPane.CategoryHeader.Simulations': 'SITZUNGEN',
    'Dashboard.LeftPane.Simulations.SimulationSummaries': 'Sitzungszusammenfassungen',
    'Dashboard.LeftPane.Simulations.SimulationDetail': 'Sitzungsdetail',
    'MursionPortal.Message.NotEnoughDataToDisplay': 'Es waren nicht genügend Daten aus dieser Sitzung vorhanden.',
    'MursionPortal.Message.Notdata.SessionMatrix': 'Es waren nicht genügend Daten aus dieser Sitzung vorhanden, um Sitzungsanalysen bereitzustellen.',
    'MursionPortal.Tooltip.SchedulingWindow': 'Endbenutzer können diesen Wert in Tagen, Stunden und Minuten festlegen, nach denen nur der Benutzer eine Sitzung planen kann.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableSelectDate': 'Wir haben im Moment keine verfügbaren Zeitfenster. Bitte versuchen Sie es später erneut. <code1></code1>Wenden Sie sich an den <code>support</code> für sofortige Hilfe.',
    'Session.Edit.Modal.JoinSession': 'Sitzung starten',
    'MursionPortal.SimAttendance.Tooltip.Completed': 'Der Lernende hat den Simulationsteil der Sitzung abgeschlossen (ohne die Abschlussbesprechung am Ende der Sitzung).',
    'MursionPortal.SimAttendance.Tooltip.Late': 'Der Lernende hat sich zu spät angeschlossen, um die Simulation abzuschließen.',
    'MursionPortal.SimAttendance.Tooltip.Left': 'Der Lernende hat die Verbindung absichtlich getrennt, bevor er die Simulation abgeschlossen hat. Dies kann darauf zurückzuführen sein, dass ein Lernender unvorbereitet ist oder ein Lernender für einen Notfall abreisen muss.',
    'Session.Edit.Modal.JoinSessionBtnTooltip': 'Die Schaltfläche „Sitzung starten“ würde vor <code>{minutesBeforeJoin}</code> Minuten nach Beginn der Sitzung aktiviert.',
    'Dashboard.Simulations.SimulationSummaries.TableHeader': 'Sitzungszusammenfassungsdaten',
    'Dashboard.Learners.LearnerActivity.TableHeader': 'Aktivitätsdaten der Lernenden',
    'Dashboard.Learners.LearnerSimulation.TableHeader': 'Sitzungsdaten der Lernenden',
    'Dashboard.ContractProgress.Column.Missed': 'Verpasste Sitzungen der Lernenden',
    'Dashboard.ContractProgress.Column.TotalRescheduled': 'Gesamte verschobene Sitzungen',
    'Dashboard.ContractProgress.Column.RescheduledMakeup': 'Verschobener Ersatz',
    'Dashboard.ContractProgress.Column.LateRescheduledSimulations': 'Spät verschobene Sitzungen',
    'Dashboard.ContractProgress.Column.EarlyRescheduledSimulations': 'Früh verschobene Sitzungen',
    'Dashboard.ContractProgress.Column.CompletedUtilization': 'Sitzungsabschluss',
    'Dashboard.ContractProgress.Column.ScheduledUtilization': 'Vorraussichtlicher Abschluss',
    'Dashboard.ContractProgress.ColumnTooltip.Remaining': 'Gekaufte Sitzungen abzüglich abgeschlossener Sitzungen.',
    'Dashboard.ContractProgress.ColumnTooltip.TotalRescheduled': 'Gesamte verschobene Simulationen',
    'Dashboard.ContractProgress.ColumnTooltip.RescheduledMakeup': 'Gesamte Simulationen, die als verschobener Ersatz markiert sind',
    'Dashboard.ContractProgress.ColumnTooltip.LateRescheduledSimulations': 'Simulationen, die zu spät verschoben werden.',
    'Dashboard.ContractProgress.ColumnTooltip.EarlyRescheduledSimulations': 'Simulationen, die frühzeitig verschoben werden.',
    'Dashboard.Learners.Column.SimulationId': 'Sitzung-ID',
    'Dashboard.Learners.Column.SimulationId.CopyHover': 'Sitzung-ID kopieren',
    'Dashboard.Learners.Column.SSO_ID': 'SSO-ID',
    'Dashboard.Learners.Column.Recorded': 'Aufgezeichnet',
    'Dashboard.Learners.Column.LearnerRecordingConsent': 'Zustimmung zur Aufzeichnung der Lernenden',
    'Dashboard.Learners.Column.RecordingRecipients': 'Aufzeichnungsempfänger',
    'Dashboard.Learners.Column.RecordingUploaded': 'Hochgeladene Aufnahme ',
    'Dashboard.Learners.ColumnTooltip.SSOID': 'Benutzer, die bereitgestellt wurden',
    'Dashboard.Learners.ColumnTooltip.Recorded': 'Geben Sie an, ob die Simulation aufgezeichnet oder nicht zugelassen wurde.',
    'Dashboard.Learners.ColumnTooltip.LearnerRecordingConsent': 'Geben Sie an, ob der Lernende der Aufzeichnung der Simulation zugestimmt hat.',
    'Dashboard.Learners.ColumnTooltip.RecordingRecipients': 'Rollen, an die die Simulationsaufzeichnung gesendet wird.',
    'Dashboard.Learners.ColumnTooltip.RecordingUploaded': 'Geben an, ob die Simulationsaufzeichnung hochgeladen wurde.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateReschedule': 'Sitzungen, die vom Lernenden zu spät verschoben wurden.',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateReschedule': 'Verspätete Umplanung',
    'MursionPortal.Dashboard.Chart.Header.LearnerStatus': 'Lernenderstatus (alle Verträge)',
    'Dashboard.SimulationTable.Column.SimulationStatus': 'Sitzungsstatus',
    'MursionPortal.Placeholder.ContractValue': 'Einen Vertrag auswählen',
    'MursionPortal.Placeholder.NoContractValue': 'Kein Vertrag verfügbar',
    'Dashboard.Learners.Column.AssignedTeams': 'Zugewiesene Teams',
    'SessionReports.VideoRecordingDeletedWithSupportCase': 'Leider kann dieses Video aufgrund eines ausstehenden # {supportCase} nicht angezeigt werden.',
    'MursionPortal.Dashboard.CompletedSessions': 'Abgeschlossene Sitzungen',
    'MursionPortal.Dashboard.ViewAll': 'Alle ansehen',
    'MursionPortal.Dashboard.DateOfSimulation': 'Datum der Sitzung',
    'Dashboard.LeftPane.CategoryHeader.Videos': 'VIDEOS',
    'Dashboard.LeftPane.Videos.NotUploaded': 'Nicht hochgeladene Aufzeichnungen',
    'Dashboard.NotUploaded.TableHeader': 'Nicht hochgeladene Daten der Aufzeichnungen',
    'Dashboard.VideosTable.Column.SessionId': 'Session-ID',
    'Dashboard.VideosTable.Column.SimspecialistId': 'SIMSpezialistID',
    'Dashboard.VideosTable.Column.ClientId': 'Kunde-ID',
    'Dashboard.VideosTable.Column.SessionEnd': 'Sitzungsende',
    'Dashboard.VideosTable.Column.SoftwareVersion': 'Szenarioversion',
    'Dashboard.VideosTable.NoVideosError': 'Es wurden keine Videos gefunden.',
    'Filters.SimSpecialist': 'SIM-Name',
    'Filters.SimSpecialistPlaceHolderHint': 'SimSpezialisten\' suchen',
    'MursionPortal.Placeholder.SimSpecialistValue': 'Wähleen Sie einen SimSpezialisten',
    'Users.Table.ErrorOnLoadUserMessage': 'Beim Laden des Benutzers ist ein Fehler aufgetreten!',
    'Dashboard.ContractProgress.InfoBadge.ContractDates': 'Vertragsdaten',
    'Dashboard.ContractProgress.InfoBadge.ContractID': 'Vertrag-ID',
    'Dashboard.Learners.Column.EarlyCancelled': 'Früh abgesagte Sitzungen',
    'Dashboard.Learners.Column.LateReschedules': 'Späte Umplanungen',
    'Dashboard.Learners.Column.EarlyReschedules': 'Frühe Terminverschiebungen',
    'Dashboard.Learners.Column.RescheduledMakeUp': 'Verschobener Ersatz',
    'Dashboard.Learners.Column.ErrorSim': 'Fehler (SIM)',
    'Dashboard.Learners.Column.CancelledOps': 'Abgebrochen (Ops)',
    'Dashboard.Learners.ColumnTooltip.AssignedProjects': 'Projekte, denen dieser Lernende derzeit zugewiesen ist',
    'Dashboard.Learners.ColumnTooltip.AssignedTeams': 'Teams, denen dieser Lernende derzeit zugewiesen ist',
    'Dashboard.Learners.ColumnTooltip.AssignedScenarios': 'Szenarien, denen dieser Lernende derzeit zugewiesen ist',
    'Dashboard.Learners.ColumnTooltip.ScheduledScenarios': 'Szenarien, die dieser Lernende geplant, aber noch nicht abgeschlossen hat',
    'Dashboard.Learners.ColumnTooltip.CompletedScenarios': 'Szenarien, die dieser Lernende abgeschlossen hat',
    'Dashboard.Learners.ColumnTooltip.EarlyCancelled': 'Simulationen, die vorzeitig (vor Ablauf der Stornierungsfrist) vom Lernenden, Moderator oder Kontoinhaber oder Simulationen abgebrochen wurden',
    'Dashboard.Learners.ColumnTooltip.LateReschedules': 'Simulationen, die zu spät (nach Ablauf der Stornierungsfrist) vom Lernenden, Moderator oder Kontoinhaber verschoben wurden',
    'Dashboard.Learners.ColumnTooltip.RescheduledMakeUp': 'Simulationen, die zuvor vom Lernenden, Moderator, Kontoinhaber oder Simulationen verpasst wurden',
    'Dashboard.Learners.ColumnTooltip.EarlyReschedules': 'Simulationsstunden, die vorzeitig (vor Ablauf der Stornierungsfrist) vom Lernenden, Moderator oder Kontoinhaber verschoben wurden.',
    'Dashboard.Learners.ColumnTooltip.ErrorSim': 'Simulationen, die auf der SIM-Seite der Verbindung mit einem Fehler endeten',
    'Dashboard.Learners.ColumnTooltip.CancelledOps': 'Simulationen, die von Mursion abgebrochen wurden',
    'SessionReports.VideoRecordingPermissionNotGranted': 'Dieses Video ist nicht verfügbar, weil ein Lernende nicht aufgezeichnet werden wollte.',
    'SessionReports.NoVideoAvailableForSession': 'Für diese Sitzung gibt es kein Video.',
    'MursionPortal.NotAvailable': 'N/A',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.OneToOne': 'Aktivieren Sie die SIM-Feedback-Umfrage nach der Simulation für 1',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.Workshop': 'SIM-Feedback-Umfrage nach der Simulation für Workshop-Durchführungen aktivieren',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.OneToOne': 'SIM-Feedback-Umfrage nach der Simulation 1',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.Workshop': 'Post-Simulation SIM-Feedback-Umfrage Workshop-Durchführungen',
    'Clients.Portal.Button.EditMemberships': 'Mitgliedschaften bearbeiten',
    'Calendar.ProjectsList.ShowOnlyActiveProjects': 'Nur aktive Projekte anzeigen',
    'MursionPortal.Status.Undefined': 'Nicht definiert',
    'MursionPortal.Status.Orphan': 'Waise',
    'MursionPortal.Status.Reserved': 'Reserviert',
    'MursionPortal.Status.Waif': 'Waif',
    'MursionPortal.Status.Upcoming': 'Bevorstehende',
    'MursionPortal.Status.Running': 'Läuft',
    'MursionPortal.Status.NeedsReview': 'Überprüfung erforderlich',
    'MursionPortal.Status.Reviewed': 'Bewertet',
    'MursionPortal.ErrorMessage.NoDataAvailable': 'Es waren nicht genügend Daten aus dieser Sitzung vorhanden, um Sitzungsanalysen bereitzustellen.',
    'Dashboard.NotUploaded.MainHeader': 'Nicht hochgeladene Aufzeichnungen',
    'MursionPortal.Message.NotEnoughDataToDisplay.ConversationalFlow': 'Es waren nicht genügend Daten aus dieser Sitzung vorhanden, um dieses Diagramm zu erstellen',
    'MursionPortal.Label.SIMSpecialist': 'SIM-Spezialist',
    'Dashboard.SimulationTable.Column.LearnerRecordingConsent': 'Zustimmung der Lernenden zur Aufzeichnung',
    'Dashboard.SimulationTable.Column.RecordingRecipients': 'Aufzeichnungsempfänger',
    'Dashboard.SimulationTable.Column.RecordingUploaded': 'Hochgeladene Aufzeichnung',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerRecordingConsent': 'Geben Sie an, ob der Lernende der Aufzeichnung der Simulation zugestimmt hat',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingRecipients': 'Rollen, an die die Simulationsaufzeichnung gesendet wird',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingUploaded': 'Gibt an, ob die Simulationsaufzeichnung hochgeladen wurde',
    'MursionPortal.ConversationalFlow.label.zoom': 'Zoomen',
    'MursionPortal.label.LearnerInterjects': 'Lerner-Einwürfe',
    'MursionPortal.label.AvatarInterjects': 'Avatar-Einwürfe',
    'MursionPortal.label.LearnerInterrupted': 'Eingeworfene Lernende',
    'MursionPortal.label.AvatarInterrupted': 'Eingeworfener Avatar',
    'MursionPortal.label.Silence': 'Schweigen',
    'Clients.Modal.Label.UserRecordingConsent': 'Zustimmung zur Aufzeichnung des Benutzers',
    'Clients.Modal.Label.UserRecordingConsent.ToolTip': 'Zustimmung zur Aufzeichnung des Benutzers einholen',
    'MursionPortal.Notset': 'Nicht festgelegt',
    'Clients.Modal.Label.UserML3SocialRecordingConsent': 'Zustimmung zur Aufzeichnung von Mursion-Social-Nutzern',
    'Clients.Modal.Label.UserML3SocialRecordingConsent.ToolTip': 'Bitten Sie den Benutzer um seine Zustimmung zur Aufzeichnung. Wenn dies nicht aktiviert ist, fragt der SIM-Spezialist mündlich nach.',
    'MursionPortal.LearnerSimSelector.Sim.Available.ToolTip': 'Zertifizierte SIMs, die nicht für eine Sitzung gebucht sind und für die ausgewählte Zeit verfügbar sind',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable.ToolTip': 'Zertifizierte SIMs, die keine Verfügbarkeit im System haben und zum ausgewählten Zeitpunkt nicht für eine andere Sitzung gebucht sind',
    'Settings.Config.Client.VideoRecordingDataRetentionPolicy': 'Konfiguration der Datenaufbewahrungsrichtlinie für Videoaufzeichnungen anzeigen',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToScenario': 'Zurück zum Szenario',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToCalendar': 'Zurück zum Kalender',
    'SessionReports.VideoRecordingDeletedDueToDataRetentionPolicy': 'Dieses Video ist aufgrund der Datenaufbewahrungsrichtlinie Ihres Unternehmens nicht verfügbar.',
    'SessionReports.VideoRecordingAvailabilityMessage': 'Dieses Video kann bis zum {videoAvailabilityDate} angesehen werden',
    'SessionReports.SessionOrLearnerStatusError': 'Aufgrund eines technischen Fehlers wurde für diese Sitzung kein Video aufgezeichnet.',
    'Clients.ImportUsers.NextStepButton': 'Nächster Schritt',
    'Clients.ImportUsers.CancelButton': 'Abbrechen',
    'Clients.ImportUsers.UploadValidateButton': 'Hochladen + Validieren',
    'Session.Table.Column.Attendance': 'Anwesenheit',
    'Session.Table.Column.Value.CompleteAttendance': 'Vollständige Anwesenheit',
    'Session.Table.Column.Value.AttendanceComplete': 'Anwesenheit abgeschlossen',
    'Session.Table.Column.Value.NotApplicable': '(Unzutreffend)',
    'Session.Table.Column.Survey': 'Umfrage',
    'Session.Table.Column.Value.CompleteGoogleForm': 'Füllen Sie das Google-Formular aus',
    'Session.Table.Column.Value.CompleteSurvey': 'Vollständige Umfrage',
    'Session.Table.Column.Value.SurveyComplete': 'Umfrage abgeschlossen',
    'Projects.Modal.Text.LearnerSelected': 'Es müssen mindestens {maximal-Learners} Lernende ausgewählt werden',
    'Session.Table.Column.Value.SessionNotCompleted': 'Sitzung ist nicht abgeschlossen',
    'MursionPortal.Project.InviteToSchedule': 'Zum Zeitplan einladen',
    'MursionPortal.Project.InvitedToday': 'Heute eingeladen',
    'MursionPortal.Project.YourInvitationsText': 'Ihre Einladungen werden über Nacht verschickt.',
    'MursionPortal.Project.InviteToSchedule.Tooltip': 'Für das Szenario werden bereits Einladungen verschickt. Morgen können Sie wieder Einladungen versenden.',
    'MursionPortal.Project.InvitedToday.Tooltip': 'Lernende, die dieses Szenario nicht geplant oder abgebrochen/verpasst haben, erhalten eine E-Mail-Einladung zur Planung.',
    'MursionPortal.Project.InviteMessage.SentDate': 'Zuletzt gesendet am {sentDate}',
    'ITManager.TechRestriction.EditDialog.ProviderErrorMsg': 'Bitte Anbieter auswählen',
    'ITManager.TechRestriction.EditDialog.GlobalRestriction': 'Globale Beschränkung',
    'ITManager.TechRestriction.EditDialog.HolidayRestriction': 'Feiertagsbeschränkung',
    'ITManager.TechRestriction.Table.Provider': 'Anbieter',
    'Session.Table.Column.Survey.HoverText': 'Wenn aktiviert, erhalten SIMs einen Link zum vollständigen Feedback zum Lernenden',
    'MursionPortal.Project.InviteMessage.InvitedBy': 'Eingeladen von {user} - {role}',
    'MursionPortal.Project.LearnersNotYetScheduled': '{learners} Lernende müssen noch planen',
    'Session.Table.Column.Value.AttendanceRequired': 'Anwesenheit erforderlich',
    'MursionPortal.Button.Schedule.Another': 'Planen Sie einen anderen',
    'MursionPortal.EulaAgreement.CodeOfConduct': 'An jeder Mursion-Simulation ist ein einzelner Live-Simulationsspezialist beteiligt, der den gesprochenen Dialog für bis zu fünf virtuelle Avatare in einer Lernsitzung bereitstellt. Die Aufgabe des Simulationsspezialisten besteht darin, Konversationsherausforderungen zu präsentieren, damit Lernende wie Sie selbst die komplexen menschlichen Fähigkeiten in jedem Szenario üben können. Bei Mursion wissen wir, wie wichtig es ist, einen sicheren Raum zu schaffen, in dem Menschen mutig potenziell stressige, nuancierte und komplexe Gespräche führen können. Um sicherzustellen, dass sowohl die Lernenden als auch der Simulationsspezialist vertrauensvoll teilnehmen können, bitten wir Einzelpersonen in beiden Gruppen, sich einem grundlegenden Verhaltenskodex zu verpflichten.',
    'MursionPortal.EulaAgreement.CodeOfConduct.Header': 'Methodenüberblick & Verhaltenskodex',
    'MursionPortal.EulaAgreement.UserAgreement.Header': 'Nutzungsbedingungen',
    'MursionPortal.Import.BackToUser': 'Zurück zu Benutzer',
    'MursionPortal.Import.BackToTeam': 'Zurück zum Team',
    'Mursion.Portal.Edit.Team.Upload.AccurateUploading': 'Stellen Sie vor dem Hochladen sicher, dass Ihre Daten korrekt sind.',
    'Mursion.Portal.Edit.Team.Upload.AskedReUpload': 'Wenn Ihre Datei Fehler enthält, werden diese in Schritt 4 in einer resultierenden Tabelle angezeigt. Folgen Sie den Anweisungen in Schritt 4, um die Fehler in der ursprünglichen Vorlage zu korrigieren, und laden Sie die Datei erneut hoch.',
    'Mursion.Portal.Edit.Team.Upload.IncludeNewUser': 'Sie können beim erneuten Hochladen auch neue Benutzer zusammen mit den fehlgeschlagenen Benutzern hinzufügen, die importiert werden sollen.',
    'Mursion.Portal.Edit.Team.ReadyToUpload': 'Wenn Ihre Datei fertig ist, klicken Sie auf Hochladen + Validieren',
    'Mursion.Portal.Edit.Team.UploadAndValidationFile': 'Datei wird hochgeladen + validiert',
    'Mursion.Portal.Edit.Team.TeamAssignmentsUpdateSuccessfully': 'Teamzuweisungen erfolgreich aktualisiert',
    'Mursion.Portal.Edit.Team.UploadSuccessTitle': '{userCount} Benutzer importiert!',
    'Mursion.Portal.Edit.Team.UploadFailureTitle': 'Fehler!',
    'Mursion.Portal.Edit.Team.UploadSuccess': 'Alle Benutzer wurden erfolgreich zum Portal hinzugefügt.',
    'Mursion.Portal.Edit.Team.DoneButton': 'Fertig',
    'Mursion.Portal.Edit.Team.DownloadSuccessful': 'Download erfolgreich - Zum nächsten Schritt',
    'Mursion.Portal.Edit.Team.DownloadError': 'Download-Fehler - Bitte versuchen Sie es erneut',
    'Mursion.Portal.Edit.Team.UploadFileAgain': 'Datei erneut hochladen',
    'Mursion.Portal.Edit.Team.ContinueWithoutCorrecting': 'Ohne Fehlerkorrektur fortfahren',
    'Mursion.Portal.Edit.Team.SuccessfulImportTitle': 'Erfolgreicher Import',
    'Mursion.Portal.Edit.Team.UnSuccessfulImportTitle': 'Import fehlgeschlagen',
    'Mursion.Portal.Import.User.HeaderTitle': 'Benutzer importieren',
    'Mursion.Portal.Import.User.Upload.AskedReUpload': 'Wenn Ihre Datei Fehler enthält, werden diese in Schritt 4 in einer resultierenden Tabelle angezeigt. Folgen Sie den Anweisungen in Schritt 4, um die Fehler in der <strong>ursprünglichen Vorlage</strong> zu korrigieren, und laden Sie die Datei erneut hoch.',
    'Mursion.Portal.Import.User.ReadyToUpload': 'Wenn Ihre Datei fertig und richtig formatiert ist, laden Sie die Datei bitte zur Validierung hoch',
    'Mursion.Portal.Import.User.ValidatingYourDataUntilTheUpload': 'Wir validieren gerade die Daten und importieren Benutzer. Bitte melden Sie sich in 15 Minuten zurück oder wenn Sie eine Benachrichtigung erhalten, dass dieser Vorgang abgeschlossen ist.',
    'Mursion.Portal.Import.User.UploadSuccessTitle': '{successCount} Benutzer importiert!',
    'Mursion.Portal.Import.User.ErrorContinueTitle': 'Fahren Sie fort, ohne Fehler zu korrigieren!',
    'Mursion.Portal.Import.User.ErrorContinue': '{failedCount} users failed to import as the errors were not fixed.', // TODO add translations
    'Settings.ScenarioBank.Form.ArtBundlesWarning': 'Bitte benennen Sie die Kunstpakete von den Standardnamen um',
    'Mursion.Portal.Import.User.Instruction.Upload': 'Klicken Sie unten auf <strong>Excel-Vorlage herunterladen</strong> und öffnen Sie die heruntergeladene Datei (entweder <strong>client_internal_users.xlsx</strong> oder <strong>client_external_users.xlsx</strong>).',
    'Mursion.Portal.Import.User.Instruction.Format': '<strong>In der Vorlage sind die Felder E-Mail, Rolle und SSO-ID erforderlich – lassen Sie diese Felder nicht leer. Fügen Sie die Benutzerinformationen korrekt unter jeder Kopfzeile hinzu.</strong> Ändern Sie NICHT die Kopfzeilen, die Vorlagenstruktur oder den Dateinamen, da dies zu einem Fehlschlagen des Imports führen könnte.',
    'Mursion.Portal.Import.User.Instruction.NewUser': 'Fügen Sie in <strong>Spalten JS oder FO</strong> die Namen der Teams hinzu, denen der Benutzer zugewiesen werden soll (achten Sie darauf, dass Ihre Schreibweise genau mit dem Namen übereinstimmt).',
    'Mursion.Portal.Import.User.Instruction.Error': 'Sobald Sie alle Ihre Benutzer eingegeben haben, speichern Sie die Datei auf Ihrem Desktop oder an einem anderen Ort, den Sie sich merken können, und klicken Sie auf <strong>Fortfahren</strong>.',
    'Mursion.Portal.Import.User.Instruction.OriginalTemplate': 'Wenn Ihre Datei Fehler enthält, werden diese in Schritt 4 in einer resultierenden Tabelle angezeigt. Folgen Sie den Anweisungen, um die Fehler in der <strong>ursprünglichen Vorlage</strong> zu korrigieren, und laden Sie die Datei erneut hoch.',
    'Mursion.Portal.Import.User.Instruction.UploadList': 'Wenn Sie fortfahren möchten, ohne die Fehler zu beheben, ist die Datei <strong>Liste der fehlgeschlagenen Uploads herunterladen</strong> nur 7 Tage lang verfügbar.',
    'Mursion.Portal.Import.User.DownloadExcelTemplate': 'Excel-Vorlage herunterladen',
    'Mursion.Portal.Import.User.UploadFileStatus': 'Datei wird hochgeladen',
    'Mursion.Portal.Import.User.DoNotCloseUntilUpload': 'Wir sind dabei, die Daten zu validieren und Benutzer zu importieren. Dieser Vorgang wird im Hintergrund fortgesetzt, sodass Sie zu einem anderen Tab oder Fenster navigieren und jederzeit zu diesem Bildschirm zurückkehren können. Dieser Importvorgang sollte weniger als 5 Minuten dauern.',
    'Mursion.Portal.Import.User.FileStatus.validation': 'Benutzer importieren',
    'Mursion.Portal.Import.User.CorrectErrorSubHeading': 'Letzter Schritt: Fehler korrigieren',
    'Mursion.Portal.Import.User.SuccessCount': '{successCount} users were successfully imported; {failedCount} users failed to import – please review.', // TODO add translations
    'Mursion.Portal.Import.User.DownloadFile': 'Click the <strong>Download Errors</strong> button and open the file to view the errors.', // TODO add translations
    'Mursion.Portal.Import.User.FileTabs': '<strong>In der Vorlage sind die E-Mail- und Rollenfelder erforderlich – lassen Sie diese nicht leer</strong>. Ändern Sie NICHT die Kopfzeilen, die Vorlagenstruktur oder den Dateinamen, da dies zu einem Fehlschlagen des Imports führen könnte.',
    'Mursion.Portal.Import.User.ReviewCorrect': 'Korrigieren Sie die Fehler in der <strong>ursprünglichen Vorlage</strong> (entweder <strong>client_internal_users.xlsx</strong> oder <strong>client_external_users.xlsx</strong>) und laden Sie die Datei erneut hoch.',
    'Mursion.Portal.Import.User.DownloadErrorButton': 'Download-Fehler',
    'Mursion.Portal.Edit.Team.EditTeamTitle': 'Teammitgliedschaften bearbeiten',
    'Mursion.Portal.Edit.Team.Instruction.UpdateFile': 'Fügen Sie die Benutzerinformationen korrekt unter jeder Kopfzeile hinzu. Fügen Sie in den Spalten L bis U die Namen aller Teams hinzu, denen der Lernende zugewiesen werden soll (i.e. Teams, die hinzugefügt oder aktualisiert werden sollen), eine Spalte pro Team. Es ist in Ordnung, einige Teamspalten bei Bedarf leer zu lassen. Bitte stellen Sie sicher, dass dies korrekt durchgeführt wird, da dies bestehende Teamzuweisungen überschreibt.',
    'Mursion.Portal.Edit.Team.Instruction.Process': 'Laden Sie jeweils nur eine Datei hoch – begrenzen Sie die Anzahl auf 5.000 Benutzer pro Datei.',
    'Mursion.Portal.Edit.Team.Instruction.ProfileFields': '<strong>Die E-Mail- und Rollenfelder sind erforderlich – lassen Sie diese nicht leer.</strong> Ändern Sie nicht die Kopfzeilen, die Vorlagenstruktur oder den Dateinamen, da dies zu einem Fehlschlagen des Imports führen könnte.',
    'Mursion.Portal.Edit.Team.Instruction.ErrorOccur': 'Unterstützte Dateiformate sind nur .xlsx.',
    'Mursion.Portal.Edit.Team.DoNotCloseUntilUpload': 'Wir validieren die Daten und aktualisieren die Teamzuweisungen. Dieser Vorgang wird im Hintergrund fortgesetzt, sodass Sie zu einem anderen Tab oder Fenster navigieren und jederzeit zu diesem Bildschirm zurückkehren können. Dieser Vorgang sollte weniger als 5 Minuten dauern.',
    'Mursion.Portal.Edit.Team.FileStatus.validation': 'Teammitgliedschaften werden aktualisiert',
    'Mursion.Portal.Edit.Team.UploadFailureNotification': 'Ihre Datei enthält mehr als 5.000 Benutzer. Bitte wenden Sie sich an den Support oder laden Sie mehrere Dateien mit weniger als 5.000 Benutzern hoch.',
    'Mursion.Portal.Edit.Team.ReviewCorrect': '<strong>In der Vorlage sind die E-Mail- und Rollenfelder erforderlich – lassen Sie diese nicht leer.</strong> Ändern Sie NICHT die Kopfzeilen, die Vorlagenstruktur oder den Dateinamen, da dies zu einem Fehlschlagen des Imports führen könnte.',
    'Mursion.Portal.Edit.Team.DownloadFile': 'Korrigieren Sie die Fehler in der ursprünglichen Vorlagendatei (<strong>edit_learner_team_memberships.xlsx</strong>) – Ändern Sie NICHT die Kopfzeilen, die Vorlagenstruktur oder den Dateinamen, da dies zu einem Fehlschlagen des Imports führen könnte.',
    'Mursion.Settings.ScenarioBank.Modal.ChecklistURL': 'URL der SIM-Checkliste',
    'Mursion.Settings.ScenarioBank.Modal.MustBeAValidURL': 'Muss eine gültige URL sein',
    'MursionPortal.Label.SpeakingIsZero': 'ist 0 %',
    'MursionPortal.Label.InterruptionIsZero': 'das 0 % ist',
    'MursionPortal.CodeOfConduct.SchedulingFlow.Text': 'Indem Sie sich entscheiden, Ihre Mursion-Sitzung zu planen, stimmen Sie dem Simulationsdurchführungsmodell von Mursion zu und bestätigen, dass Sie den Mursion-Verhaltenskodex einhalten und einhalten werden. Lesen Sie mehr <code>hier</code>.',
    'Mursion.Portal.Import.User.InvalidFile': 'Es werden nur CSV- oder Excel-Vorlagen hochgeladen.',
    'Mursion.Portal.Import.User.MaxFileSize': 'Die Dateigröße darf 5MB nicht überschreiten',
    'Mursion.Portal.Import.User.Tooltip': 'Klicken Sie hier, um neue Benutzer zu importieren oder vorhandene Benutzer aus einer Exportdatei zu aktualisieren.',
    'Mursion.Portal.Edit.Team.Tooltip': 'Klicken Sie hier, um Teammitgliedschaften in großen Mengen zuzuweisen oder neu zuzuweisen.',
    'MursionPortal.Project.InviteLearnersToSchedule': 'Lernende zum Planen einladen',
    'MursionPortal.CheckboxLabel.SchedulingByBookIt': 'Terminplanung von BookIt',
    'Dashboard.Report.SendReport': 'Bericht senden',
    'Dashboard.Report.ScheduleReport': 'Zeitplanbericht',
    'Dashboard.Report.EditSchedule': 'Zeitplan bearbeiten',
    'Dashboard.Report.ViewSchedule': 'Zeitplan anzeigen',
    'Dashboard.Report.DeleteSchedule': 'Zeitplan löschen',
    'Dashboard.Report.Filter.Title': 'Filter ausgewählt',
    'Dashboard.Report.SelectReportFrequency.Label': 'Berichtshäufigkeit auswählen',
    'Dashboard.Report.SelectSessions.Label': 'Sitzungen auswählen, die eingeschlossen werden sollen',
    'Dashboard.Report.SelectReportStartDate.Label': 'Startdatum des Berichts auswählen',
    'Dashboard.Report.SelectReportStartDate.Tooltip': 'Wenn Sie ausgewählt haben, Berichte wöchentlich zu erhalten, erhalten Sie Ihren Bericht jede Woche an dem von Ihnen ausgewählten Tag. Wenn Sie "Monatlich" ausgewählt haben, erscheint es jeden Monat an diesem Datum.',
    'Dashboard.Report.SelectAccountOwner.Label': 'Wählen Sie den Kontoinhaber oder Moderator aus',
    'Dashboard.Report.Dropdown.Select.Placeholder': 'Auswählen',
    'Dashboard.Report.ManuallyAddRecipients.Label': 'Empfänger manuell hinzufügen',
    'Dashboard.Report.ManuallyAddRecipients.Label.Placeholder': 'E-Mail hier hinzufügen',
    'Dashboard.Report.RecipientsBox.Label': 'Empfänger',
    'Dashboard.Report.RecipientsBox.RecipientMaxLimit': 'Empfänger dürfen nicht älter als 30 sein',
    'Dashboard.Report.SendReport.ConfirmationModal.Text': 'Der Bericht wurde an die Empfänger gesendet.',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.HeadingText': 'Ihr Bericht wurde geplant!',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.Text': 'Stoppen oder bearbeiten Sie ihn jederzeit, indem Sie auf <strong>Zeitplan bearbeiten</strong> klicken',
    'Dashboard.Report.EditSchedule.ConfirmationModal.HeadingText': 'Die Änderungen wurden gespeichert!',
    'Dashboard.Report.EditSchedule.ConfirmationModal.Text': 'Stoppen Sie Berichte jederzeit, indem Sie auf <strong>Zeitplan bearbeiten</strong> klicken.',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.Text': 'Zeitplan wurde gelöscht',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.ConfirmationText': 'Möchten Sie den Zeitplan für diesen Bericht wirklich löschen?',
    'Dashboard.Report.ConfirmationModal.Button': 'Ich habs!',
    'Dashboard.Report.DeleteSchedule.ConfirmationButton': 'Ja, löschen',
    'Dashboard.Report.EditSchedule.EditButton': 'Planungsänderungen speichern',
    'Dashboard.Report.ReportFrequencyOption.Daily': 'TÄGLICH',
    'Dashboard.Report.ReportFrequencyOption.Weekly': 'WÖCHENTLICH',
    'Dashboard.Report.ReportFrequencyOption.Monthly': 'MONATLICH',
    'Dashboard.Report.ReportSessionOption.PreviousDay': 'Sitzungen Vortag',
    'Dashboard.Report.ReportSessionOption.Previous7Days': 'Sitzungen der letzten 7 Tage',
    'Dashboard.Report.ReportSessionOption.CurrentSessions': 'Sitzungen des aktuellen Tages',
    'Dashboard.Report.ReportSessionOption.Next7Days': 'Sitzungen in den nächsten 7 Tagen',
    'Dashboard.Report.ReportSessionOption.MonthToDate': 'Sitzungen bisherigen Monats',
    'MursionPortal.Dashboard.UpcomingSession': 'Kommende Sitzung',
    'Dashboard.Report.ReportSessionOption.PreviousFullMonth': 'Sitzungen im letzten vollen Monat',
    'Dashboard.Report.ReportSessionOption.Previous30Days': 'Sitzungen der letzten 30 Tage',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing': 'Szenario-Sequenzierung',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing.Text': 'Klicken Sie auf die Schaltfläche, um die Reihenfolge der Szenarien in diesem Projekt anzuzeigen und zu konfigurieren.',
    'Projects.ProjectInfo.Button.ScenarioSequence': 'Szenario-Sequenz',
    'MursionPortal.Projects.DragAndDropModal.Text': 'Ziehen und legen Sie Szenarien in bevorzugter Reihenfolge ab',
    'MursionPortal.Projects.DragAndDropModal.DragHandlerImage': 'Profilbild ziehen',
    'MursionPortal.Projects.DragAndDropModal.ModalCloseIcon': 'Schließen-Symbol',
    'MursionPortal.Project.Scheduled.NextDate': 'Geplant {nextDate}',
    'MursionPortal.Project.Sequencing': 'Sequenzierung',
    'MursionPortal.Project.Checkbox.Sequencing': 'Sequenzierung aktivieren',
    'MursionPortal.Dashboard.Join.Tooltip': 'Sobald diese Sitzung verfügbar ist, können Sie ihr beitreten',
    'MursionPortal.Project.InviteToScheduleProject.Tooltip': 'Lernende, die keine Szenarien in diesem Projekt geplant oder abgesagt/verpasst haben, erhalten eine E-Mail-Einladung zur Planung.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts': 'Tastatürkürzel',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar': 'Leertaste',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar.Text': 'Wählen Sie das Szenario aus oder löschen Sie es.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys': 'Pfeiltasten nach rechts und links oder Pfeiltasten nach oben und unten',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys.Text': 'Szenario nach links und rechts ziehen.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc': 'Esc-Taste',
    'MursionPortal.Esc': 'Esc',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc.Text': 'Szenariosequenz-Modus schließen',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter': 'Enter-Taste',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter.Text': 'Tastaturkürzel-Tooltip schließen',
    'MursionPortal.Projects.DragAndDropModal.Tooltip': 'Öffnen Sie den Tooltip für Tastaturkürzel',
    'Dashboard.Report.DuplicateEntry.ErrorMessage': 'Doppelte Eingabe ist nicht erlaubt',
    'Projects.ProjectInfo.EnableSessionTimeStep': 'Sitzungszeitschritt aktivieren',
    'Projects.ProjectInfo.SessionTimeStepInfo': 'Der Sitzungszeitschritt auf Lizenznehmerebene wird verwendet, wenn der Sitzungszeitschrittwert nicht auf Projektebene festgelegt ist ',
    'Session.Edit.Modal.JoinSimulation': 'Sitzung beitreten',
    'MursionPortal.Selector.RequestStatus': 'Anforderungsstatus',
    'MursionPortal.Dashboard.RetakeSimulation': 'Simulation wiederholen',
    'MursionPortal.Dashboard.NextSimulationAssigned': 'Nächste Simulation zugewiesen',
    'MursionPortal.AriaLabel.SelectTime': 'Zeit auswählen',
    'Dashboard.Report.Frequency.Date.Select.Placeholder': 'Startdatum auswählen',
    'MursionPortal.InstructoScenarioTeamMapping.Header': 'Wählen Sie das Szenario und das Team aus, das abgebildet werden sollten',
    'MursionPortal.InstructoScenarioTeamMapping.MappingAlreadyExistMessage': 'Dieses Szenario und dieses Team wurden bereits einem anderen Kontext zugeordnet.',
    'MursionPortal.DataSummaryPoints.manualFulFilled': 'Manuell ausgefüllt',
    'MursionPortal.DataSummaryPoints.pendingRequest': 'Basierend auf ausstehendem Bedarf',
    'MursionPortal.DataSummaryPoints.declined': 'Abgelehnte Anfragen',
    'MursionPortal.DataSummaryPoints.avgFulfilled': 'Durchschnittliche Erfüllung für Manuell',
    'MursionPortal.DataSummaryPoints.totalSwap': 'Gesamte Swaps',
    'MursionPortal.DataSummaryPoints.swapAccepted': 'Akzeptierte Swaps',
    'MursionPortal.Dashboard.MissedSimulations': 'Verpasste Simulationen',
    'MursionPortal.Dashboard.RescheduledSimulations': 'Verschobene Simulationen',
    'MursionPortal.Dashboard.BookedSimulations': 'Gebuchte Simulationen',
    'MursionPortal.Dashboard.CancelledSimulations': 'Abgebrochene Simulationen',
    'Dashboard.UserTable.Column.In': 'In',
    'Projects.ProjectInfo.ConfirmationDialog.ScenarioSequencing.Text': 'Möchten Sie die Sequenzierung wirklich deaktivieren? Dies würde auch die Sequenznummerierung in jedem Szenario entfernen.',
    'Projects.ProjectInfo.Disable.Sequencing': 'Sequenzierung deaktivieren',
    'MursionPortal.ErrorMessage.TechnicalIssueMsg': 'Bitte versuche es erneut. Wir sind auf ein technisches Problem gestoßen.',
    'SessionFlow.Text.Event.DeletedByUsername': 'Von {user} gelöscht',
    'Settings.ScenarioBank.Modal.timestepShouldBeAtLeast': 'Deaktivieren Sie entweder „Sitzungszeitschritt aktivieren“ oder geben Sie einen Zeitschrittwert von mindestens {Minuten} Minuten ein',
    'MursionPortal.AriaImage.Attribute.Previous': 'Vorherige',
    'MursionPortal.AriaImage.Attribute.Notify': 'Benachrichtigen',
    'MursionPortal.AriaImage.Attribute.Upload': 'Hochladen',
    'MursionPortal.Report.FilterHeading.SoftwareVersions': 'Softwareversionen',
    'MursionPortal.Report.FilterHeading.DeliveryModes': 'Durchführungsmodi',
    'MursionPortal.Report.FilterHeading.LearnerSessionStatuses': 'Sitzungsstatus der Lernenden',
    'MursionPortal.Report.FilterHeading.LearnerStatuses': 'Status der Lernenden',
    'MursionPortal.Report.FilterHeading.SimulationStatus': 'Simulationsstatus',
    'MursionPortal.Project.DuplicateProjectBtnTooltip': 'Dies würde alle Einstellungen und Konfigurationen dieses Projekts in einem neuen Projekt duplizieren',
    'MursionPortal.Project.DuplicateProjectBtnText': 'Duplizieren zu Magic Project',
    'MursionPortal.ScenarioInfo.Label.Until': 'Sie haben bis zum {date} Zeit, dies bis zu {completionRate} mal in Anspruch zu nehmen.',
    'Clients.Modal.Button.Schedule.Tooltip.Text': 'Sie müssen zuerst das vorherige Szenario planen',
    'MursionPortal.Scenario.Name': 'Szenarioname',
    'MursionPortal.ScenarioCard.BackToProjects.Button': 'Zurück zu Projekte',
    'MursionPortal.ScenarioCard.ViewMore.Button': 'Mehr anzeigen',
    'MursionPortal.ScenarioInfo.Documents': 'Szenariodokumente',
    'MursionPortal.ScenarioInfo.Documents.File': '{Erweiterung} Datei',
    'MursionPortal.ScenarioCard.ViewSessions': 'Sitzungen anzeigen',
    'MursionPortal.Label.ScenarioPreview': 'Szenariovorschau',
    'MursionPortal.ScenarioInfo.AssignedTeams': 'Team(s) suchen oder auswählen',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialists': 'Zertifizierte SIM-Spezialisten',
    'MursionPortal.ScenarioInfo.AddSimSpecialist': 'Sim-Spezialist hinzufügen',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.PencilIcon': 'Symbol bearbeiten',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.DeleteButton': 'SIM löschen',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.SearchText': 'SIM-Spezialisten suchen oder auswählen',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.AddSim': 'Sim hinzufügen',
    'MursionPortal.Label.InactiveSIMSpecialists': 'Inaktive SIM-Spezialisten',
    'MursionPortal.Label.Search.InactiveSIMSpecialists': 'Suchen oder wählen Sie inaktive SIM-Spezialisten aus',
    'MursionPortal.Project.InviteToSchedule.Sequencing.Tooltip': 'Die Sequenzierung ist für dieses Szenario eingestellt, und noch hat kein Lernender das vorherige Szenario geplant.',
    'Clients.Modal.Label.FacilitatorView.Tooltip': 'Wenn dies auf Kundenebene eingestellt ist, können Moderatoren Details auf Kontoinhaberebene sehen. Bei Einstellung auf Teamebene kann der Moderator nur Informationen über die Teams sehen, denen er zugewiesen ist.',
    'Clients.Modal.Label.VoiceAnalytics.Tooltip': 'Bietet den Lernenden eine automatisierte Analyse ihrer Sitzung.',
    'Clients.Modal.Label.Show.Utilization.Reporting.Tooltip': 'Fügt Nutzungsberichte zum Dashboard des Kontoinhabers hinzu.',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy.Tooltip': 'Aktivieren, wenn der Client eine benutzerdefinierte Datenaufbewahrungsrichtlinie hat.',
    'MursionPortal.Label.SoftwareType.Tooltip': '„Nur Web“ bedeutet, dass Lernende nur über Mursion Magic, die browserbasierte Plattform, auf ihre Sitzungen zugreifen können.',
    'Projects.ProjectInfo.Label.Timeline.Tooltip': 'Alle Sitzungen für dieses Projekt müssen innerhalb der Projektzeitachse abgeschlossen werden.',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording.Tooltip': 'Deaktivieren Sie. um Aufzeichnungen zu verhindern.',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording.Tooltip': 'Die Lernenden können die Aufzeichnung deaktivieren. Wenn diese Option deaktiviert ist und ein Lernender die Aufnahmeerlaubnis verweigert, kann er nicht an der Sitzung teilnehmen.',
    'Projects.ProjectInfo.label.Tags.Tooltip': 'Tags sind organisatorische Gruppierungen, um ähnliche Projekte auf Kundenebene schnell zu finden.',
    'Projects.ProjectInfo.label.DemandTime.Tooltip': 'Zeitrahmen, in dem Lernende Simulationen für die Bedarfsslots anfordern können',
    'Projects.ProjectInfo.label.DemandSlots.Tooltip': 'Legen Sie die Anzahl der Planungsoptionen fest, die ein Lernender beim Planen sieht. Wenn der Wert auf null gesetzt ist, kann der Lernende sofort ein offenes Zeitfenster buchen.',
    'Projects.ProjectInfo.label.Rescheduling.Tooltip': 'Ermöglicht Lernenden, Sitzungen neu zu planen. Beachten Sie, dass der Lernende keine Sitzung am selben Tag buchen kann, es sei denn, Gleicher-Tag ist aktiviert.',
    'MursionPortal.Project.SameDay.Tooltip': 'Ermöglicht den Lernenden, den Termin auf einen späteren Zeitpunkt am selben Kalendertag zu verschieben.',
    'Cleints.Modal.Label.AvailablePrivateSSOLabel': 'Privates SSO aktivieren:',
    'Cleints.Modal.Label.DomainsLabel': 'Kunden-Webdomäne:',
    'Clients.Modal.Label.Logo': 'Kundenlogo:',
    'Projects.ProjectInfo.Label.CancellationDeadline': 'Stornierungsfrist',
    'Clients.Modal.Label.MursionSocialAttendance.ToolTip': 'Sammeln Sie die Anwesenheit der Lernenden während der Mursion Social-Sitzungen.',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays': 'Aufbewahrung von Videoaufzeichnungsdaten (in Tagen):',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy': 'Datenaufbewahrungsrichtlinie für Videoaufzeichnungen:',
    'MursionPortal.PasswordRequirementMessage.SpecialCharaters': 'mindestens ein Sonderzeichen',
    'MursionPortal.ErrorMessage.InvalidCaptcha': 'Ungültiges Captcha. Bitte versuchen Sie es erneut.',
    'MursionPortal.Project.MissedAlert.TooLateJoin': 'Du bist etwas zu spät gekommen, um beizutreten',
    'MursionPortal.Project.MissedAlert.DontWorry': 'Machen Sie sich keine Sorgen! Es passiert.',
    'MursionPortal.Project.MissedAlert.ClickReschedule': 'Klicken Sie auf Ihre Simulation neu planen.',
    'MursionPortal.Project.EmailMissedLink.TooLateJoin.ClickRescheduleTxt': 'Klicken Sie unten, um Ihre Simulation neu zu planen.',
    'MursionPortal.Password.Strength.Tooweak': 'Passwort ist Zu schwach',
    'MursionPortal.Password.Strength.Weak': 'Passwort ist Schwach',
    'MursionPortal.Password.Strength.Medium': 'Passwort ist Mittel',
    'MursionPortal.Password.Strength.Strong': 'Passwort ist Stark',
    'Dashboard.LeftPane.User.UserActivity': 'Benutzeraktivität',
    'Dashboard.LeftPane.Performance.SelfService': 'Self-Service-Nutzung',
    'MursionPortal.Placeholder.ClientValue': 'Kunde auswählen',
    'Filters.Date.FromDate': 'Von',
    'Filters.Date.ToDate': 'Bis',
    'Dashboard.UserActivity.MainHeader': 'Benutzeraktivität',
    'Dashboard.SelfService.MainHeader': 'Self-Service-Nutzung',
    'Dashboard.SelfService.Column.SelfServiceProvider': 'Anbieter',
    'Dashboard.SelfService.Column.SelfServiceTotalHoursUtilized': 'Insgesamt genutzte Stunden',
    'Dashboard.SelfService.Column.SelfServiceHoursCompleted': 'Stunden absolviert',
    'Dashboard.SelfService.Column.SelfServiceHoursMissed': 'Stunden verpasst',
    'Dashboard.SelfService.Column.SelfServiceHoursErrored': 'Stunden mit Fehlern',
    'Dashboard.SelfService.Column.SelfServiceSimulationsComplete': 'Simulationen abgeschlossen',
    'Dashboard.SelfService.Column.SelfServiceSimulationsMissed': 'Simulationen verpasst',
    'Dashboard.SelfService.Column.SelfServiceSimulationsErrored': 'Simulationsfehler',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceProvider': 'Anbieter',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceTotalHoursUtilized': 'Erfolgreich abgeschlossene Simulationen',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursCompleted': 'Stunden absolviert',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursMissed': 'Simulationen, die die/der Lernende verpasst hat.',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursErrored': 'Simulationen, die auf der Seite der/des Lernenden mit einem Fehler endeten.',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsComplete': 'Simulationen abgeschlossen',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsMissed': 'Simulationen verpasst',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsErrored': 'Simulationsfehler',
    'Dashboard.Learners.Column.AssignedProjects': 'Zugewiesene Projekte',
    'Dashboard.Learners.Column.AssignedScenarios': 'Zugewiesene Szenarien',
    'Dashboard.Learners.Column.ScheduledScenarios': 'Geplante Szenarien',
    'Dashboard.Learners.Column.CompletedScenarios': 'Abgeschlossene Szenarien',
    'Settings.Config.SessionReport.ShowSmartMetrics': 'Stimmanalyse',
    'Settings.SSO.CopySsoDeepLink': 'SSO-Deep-Link kopieren',
    'Settings.SSO.CopySsoDeepLinkNotification': 'Ihr SSO-Link wurde in Ihre Zwischenablage kopiert',
    'MursionPortal.Dashboard.Chart.Assigned': 'Zugewiesen',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Simulation': 'Simulationen, nach Status aufgeschlüsselt.',
    'MursionPortal.Dashboard.Chart.Header.NoDataAvailable': 'Keine Daten verfügbar',
    'Projects.ProjectInfo.Label.SurveyControl': 'Projektumfrage',
    'Projects.ProjectInfo.Checkbox.EnablePreSimulationSurvey': 'Teilnehmerumfrage vor der Simulation aktivieren',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey': 'Teilnehmerumfrage nach der Simulation aktivieren',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled': 'Umfrage aktivieren',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PreSimulationSurvey': 'Teilnehmerumfrage vor der Simulation',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey': 'Teilnehmerumfrage nach der Simulation',
    'Settings.SSO.ModalBody.SelectDestination': 'Linkziel auswählen',
    'Settings.SSO.ModalBody.SelectDestination.SessionsTab': 'Registerkarte Sitzung',
    'Settings.SSO.ModalBody.SelectDestination.CalendarTab': 'Registerkarte Kalender',
    'Settings.SSO.ModalBody.SelectDestination.ReportsTab': 'Registerkarte Berichte',
    'Settings.SSO.ModalBody.SelectDestination.DashboardTab': 'Registerkarte Dashboard',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioCard': 'Szenariokarte',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioTabSelectTitle': 'Wählen Sie zuerst ein Projekt und dann ein Szenario aus',
    'Settings.SSO.Button.CreateDeepLink': 'SSO-Deep-Link erstellen',
    'Settings.SSO.ModalHeader.DeepLink': 'SSO-Deep-Link kopieren',
    'MursionPortal.Dashboard.Chart.Header.LearnerSimulations': 'Anzahl zugewiesener Szenarien',
    'Clients.Modal.Label.MursionSocialAttendance': 'Teilnahme an Mursion Social:',
    'MursionPortal.CodeOfConduct.Text': 'Indem Sie sich entscheiden, Ihre Mursion-Sitzung zu planen, stimmen Sie zu, den <code>Verhaltenskodex</code> von Mursion einzuhalten und zu respektieren.',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.CompletedAllAvailable': 'Lernende, die alle ihnen zur Verfügung stehenden Szenarien abgeschlossen haben',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NoneAvailableToSchedule': 'Lernende, denen noch kein Szenario zur Planung zur Verfügung stand',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NotYetScheduled': 'Lernende mit zur Verfügung stehenden Szenarien, die aber noch keine Simulation geplant haben',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.Scheduled': 'Lernende, für die eine oder mehrere Simulationen geplant sind, die sie noch nicht abgeschlossen haben',
    'MursionPortal.DateRangeSelector.Label.DateRange': 'Datumsbereich',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReservedText': 'Bereits ausgewählt – bitte wählen Sie ein anderes Zeitfenster.',
    'MursionPortal.EndDate': 'Enddatum',
    'MursionPortal.Label.FilterSIMsBy': 'SIMs filtern nach',
    'MursionPortal.LearnerSimSelector.Learner': 'Lernende',
    'MursionPortal.LearnerSimSelector.Learner.Selected': 'Ausgewählte(r) Lernende(r)',
    'Dashboard.Filters.SimulationID': 'Simulations-ID',
    'MursionPortal.LearnerSimSelector.Sim.Selected': 'Sim-Spezialist',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable': 'Zertifiziert und nicht verfügbar',
    'MursionPortal.LearnerSimSelector.Tab.Search.Learner': 'Lernende suchen oder auswählen',
    'MursionPortal.LearnerSimSelector.Tab.Search.Sim': 'SIM-Spezialisten auswählen oder suchen',
    'MursionPortal.Placeholder.ProvidersValue': 'Anbieter auswählen',
    'MursionPortal.ProjectContract.Confirmation': 'Möchten Sie den Vertrag aus diesem Projekt wirklich löschen?',
    'MursionPortal.Scheduling.Label.DateTimeTab': 'Datum + Uhrzeit',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedAndAvailableSimSpecialist': 'Für eine(n) zertifizierte(n) SIM liegt Verfügbarkeit vor.',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedSimSpecialist': 'Es sind keine zertifizierten Sims verfügbar',
    'MursionPortal.SessionFlow.Label.AreYouSure': 'Sind Sie sicher?',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing': 'Sorgen Sie vor dem Löschen bitte für Folgendes:',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text1': 'Dass Sie geprüft haben, ob der Benutzer, der die Anfrage stellt, zum Löschen berechtigt ist (Vorsicht bei Gruppensimulationen und Zustimmung der Lernenden).',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text2': 'Dass Sie einen Support-Fall eröffnet haben, der angibt, wer die Löschung beantragt, und dies gegebenenfalls beim Kontoinhaber überprüft haben.',
    'MursionPortal.SessionFlow.Label.DeleteRecording': 'Aufzeichnung löschen',
    'MursionPortal.SessionFlow.Label.ProviderName': 'Name des Anbieters',
    'MursionPortal.SessionFlow.Label.ReminderThisCanNotBeUndone': 'ERINNERUNG: Der Vorgang kann nicht rückgängig gemacht werden!',
    'MursionPortal.SessionFlow.Label.ScenarioDetails': 'Szenariodetails',
    'MursionPortal.SessionFlow.Label.SessionStartEndDateTime': 'Start- und Enddatum der Sitzung',
    'MursionPortal.SessionFlow.Label.SupportCase': 'Support-Fall Nr.*',
    'MursionPortal.SessionFlow.Label.YouAreAboutToDeletevideoRecording': 'Sie sind dabei, eine Videoaufzeichnung zu löschen',
    'MursionPortal.SessionNotStarted.AudioVideoSettings':'„Überprüfen Sie Ihre Audio- und Videoeinstellungen“',
    'MursionPortal.SessionNotStarted.DescriptionOne':'„Während Sie warten, können Sie:“',
    'MursionPortal.SessionNotStarted.DescriptionTwo':'„Dieses Pop - up - Fenster wird < code > { value } < /code> Minuten vor Ihrer Simulation ausgeblendet. Klicken Sie auf Aktualisieren, wenn die Seite zu diesem Zeitpunkt nicht automatisch aktualisiert wird.“',
    'MursionPortal.SessionNotStarted.Heading':'„Sie sind ein bisschen zu früh dran“',
    'MursionPortal.SessionNotStarted.PreSimTips':'„Schauen Sie sich diese < code > Tipps zur Vorsimulation < /code> an“',
    'MursionPortal.SessionNotStarted.ScenarioDetails':'„Schauen Sie in die < code > Szenariodetails < /code>“',
    'MursionPortal.StartDate':'Startdatum',
    'MursionPortal.Survey.Thankyou.Msg': 'Wir danken Ihnen, dass Sie sich die Zeit genommen haben, an dieser Umfrage teilzunehmen. Ihre Antwort wurde gespeichert.',
    'MursionPortal.Title.Hours': 'Stunde(n)',
    'MursionPortal.Title.Minutes': 'Minute(n)',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionDoesNotLieWithLearner':'Bitte geben Sie an, ob Sie damit einverstanden sind, fortzufahren und Ihre Simulation(en) aufzuzeichnen.Wenn Sie nicht zustimmen, können Sie mit Ihrer Simulationssitzung nicht fortfahren und die Software wird beendet.Wenn Sie zustimmen, werden Ihre Simulationssitzung(en) aufgezeichnet.Jede Aufzeichnung wird 12 Monate lang sicher gespeichert und im Anschluss daran archiviert.',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionsLiesWithLearner': 'Sind Sie sicher? Um ohne Aufzeichnung fortzufahren, klicken Sie auf „Bestätigen“. Um Ihre Zustimmung zur Aufzeichnung zu ändern, klicken Sie auf „Abbrechen“.',
    'Session.Edit.Modal.JoinSimulationBtnTooltip': 'Sie können <code>{minutesBeforeJoin}</code> Minuten vor Beginn Ihrer Simulation beitreten.',
    'Session.Edit.Modal.LastEmailTrigger': 'Letzte E-Mail, die an den Lernenden gesendet wurde: {sentDate}',
    'Session.Edit.Modal.LastEmailTrigger.Sender.UserName': 'Von: {userName} {sentTime}',
    'Session.Rescheduling.Email.Reminder.Msg': 'Senden einer E-Mail-Erinnerung zur Terminverschiebung an den Lernenden!',
    'Settings.Config.Invitation.MursionSocialAttendance': 'Teilnahme Gather Mursion Social:',
    'Settings.LTI.Modal.SendScore': 'Punktzahl senden',
    'Dashboard.ContractProgress.Column.Provider': 'Anbieter',
    'Settings.SSO.CopyDeepLinkNotification': 'Ihr Link wurde in Ihre Zwischenablage kopiert',
    'MursionPortal.Dashboard.Table.Empty.Body.Message': 'Details werden geladen, sobald Sie eine Auswahl vorgenommen haben',
    'Settings.SSO.ModalBody.ProjectSelector.DisabledTitle': 'Wählen Sie zuerst die Option rechts aus',
    'Settings.SSO.ModalBody.ScenarioSelector.DisabledTitle': 'Wählen Sie zuerst ein Projekt aus',
    'MursionPortal.Label.LearnerListening': 'Zuhören eines Lernenden',
    'MursionPortal.Label.InterruptionCount': 'Zeitzonen',
    'MursionPortal.ConversationalFlow.label.videoTimeLine': 'Video-Zeitachse',
    'MursionPortal.ConversationalFlow.label.zoomTimeLine': 'Zoomen',
    'MursionPortal.LearnerSimSelector.Sim.Available': 'Zertifiziert und verfügbar',
    'MursionPortal.Button.View': 'Anzeigen',
    'MursionPortal.Dashboard.NewSimulationAssigned': 'Neue zugewiesen Simulation',
    'MursionPortal.Dashboard.MakeUpSimulation': 'Verpasste Simulation',
    'MursionPortal.Dashboard.MissedSimulation': 'Verpasster Workshop',
    'MursionPortal.Dashboard.CompletedSimulations': 'Abgeschlossene Simulationen',
    'MursionPortal.Dashboard.BackToDashboard': 'Zurück zum Dashboard',
    'MursionPortal.Dashboard.CancelledSimulation': 'Verpasster Workshop',
    'MursionPortal.ScenarioSearchList.Dialog.Title': 'Sie haben während dieser Zeit eine Dauerverfügbarkeit geplant. Möchten Sie auch Ihre Dauerverfügbarkeit aktualisieren? ',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText': 'Ich möchte meine Verfügbarkeit nur für {startDate} aktualisieren',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText.ToDate': 'Ich möchte meine Dauerverfügbarkeit ab {date} aktualisieren',
    'Mursion.Portal.Status.Connected':'VERBUNDEN',
    'Mursion.Portal.Status.Late':'VERSPÄTET',
    'Mursion.Portal.Status.Immersing':'EINTAUCHEND',
    'Mursion.Portal.Status.Left':'VERLASSEN',
    'Mursion.Portal.Status.Declined':'ABGELEHNT',
    'Dashboard.LeftPane.User.SchedulingInfo': 'Zeitplan-Info',
    'Dashboard.Button.ExportToEmail':'Exportdaten',
    'Filters.All':'Alle',
    'Filters.More':' mehr',
    'Filters.AllExcept':'Alle, außer',
    'Filters.MoreClients':' Mehr Kunden',
    'Filters.AllClients':'Alle Kunden',
    'Filters.Role':'Rolle',
    'Filters.TimeZone':'Zeitzone',
    'Dashboard.UserActivity.TableHeader':'Daten zur Benutzeraktivität',
    'MursionPortal.GenerateLearnerSurveyLink':'Umfrage - Link kopieren',
    'MursionPortal.SurveyLinkLabel':'Ihr Umfrage - Link wurde in Ihre Zwischenablage kopiert',
    'MursionPortal.Attention':'Achtung!',
    'MursionPortal.LinkCannotBeGenerated':'Link kann nicht generiert werden, da das Vertrags - ID - Feld in diesem Szenario - Projekt fehlt.',
    'Dashboard.UserTable.Column.UserName':'Name',
    'Dashboard.UserTable.Column.DateInvited':'Eingeladen',
    'Dashboard.UserTable.Column.DateRegistered':'Registriert',
    'Dashboard.UserTable.Column.DateArchived':'Archiviert',
    'Dashboard.UserTable.Column.DateLastLogin':'Letzte Anmeldung',
    'Dashboard.UserTable.Column.UsedSoftwareVersions':'Software',
    'Dashboard.UserTable.Column.SessionsScheduled':'Simulationen geplant',
    'Dashboard.UserTable.Column.SessionsCompleted':'Simulationen abgeschlossen',
    'Dashboard.UserTable.Column.SessionsMissed':'Simulationen verpasst',
    'Dashboard.UserTable.Column.LateCancellations':'Simulationen abgebrochen',
    'Dashboard.UserTable.Column.SessionsError':'Simulationsfehler',
    'Session.Table.Column.ProjectId':'Vertrags - ID',
    'Session.Edit.Modal.Button.Leave':'Meine Sitzung abbrechen',
    'Users.TableModal.SSOIDPlaceHolder':'SSO ID',
    'Users.Table.Modal.SSOID':'SSO ID',
    'Dashboard.SchedulingInfo.Filter.IndustriesAll':'Branche',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion':'Software',
    'Dashboard.SchedulingInfo.MainHeader':'Zeitplan - Info',
    'Dashboard.SchedulingInfo.TableHeader':'Zeitplan - Infodaten',
    'RestorePassword.ResetPassword':'Passwort zurücksetzen',
    'RestorePassword.EmailHint':'E - Mail - Adresse eingeben',
    'Dashboard.ProjectUtilization.MainHeader':'Projektauslastung',
    'Settings.Config.ProjectAndScenario.AllowMursionMagic':'Mursion Magic - Typ',
    'Dashboard.SessionLearners.MainHeader':'Sitzungsteilnehmer',
    'MursionPortal.Table.ScrollMessage':'Scrollen Sie nach links, um weitere Spalten anzuzeigen.',
    'Dashboard.ProjectsActivity.MainHeader':'Projektaktivität',
    'MursionPortal.Table.ColumnHeader.ProjectId':'Vertrags - ID',
    'MursionPortal.Button.ClearAll':'Alle löschen',
    'MursionPortal.Dashboard.Chart.Registration':'Benutzerregistrierung',
    'MursionPortal.Dashboard.Chart.InviteAll':'Alle einladen',
    'MursionPortal.Dashboard.Chart.Unregistered':'Nicht registriert',
    'MursionPortal.Dashboard.Chart.Registered':'Registriert',
    'MursionPortal.Dashboard.Chart.Login':'Anmelden',
    'MursionPortal.Dashboard.Chart.NotLoggedIn':'Nicht angemeldet',
    'MursionPortal.Dashboard.Chart.LoggedIn':'Angemeldet',
    'MursionPortal.Dashboard.Chart.Scheduling':'Benutzer geplant',
    'MursionPortal.Dashboard.Chart.NotScheduled':'Nicht geplant',
    'MursionPortal.Dashboard.Chart.Simulations':'Simulationen',
    'MursionPortal.Dashboard.Chart.LateCancellations':'Späte Absagen',
    'MursionPortal.Dashboard.Chart.Completed':'Abgeschlossen',
    'MursionPortal.Dashboard.Chart.Errors':'Fehler',
    'MursionPortal.Dashboard.Chart.Scheduled':'Geplant',
    'MursionPortal.Dashboard.Chart.Tooltip.Registered':'Eingeladene Benutzer oder bereitgestellte und angemeldete SSO - Benutzer.',
    'MursionPortal.Dashboard.Chart.Tooltip.Unregistered':'Eingeladene, jedoch noch nicht angemeldete, Benutzer oder bereitgestellte, jedoch noch nicht angemeldete, SSO - Benutzer.',
    'MursionPortal.Dashboard.Chart.Tooltip.NotScheduled':'Registrierte / bereitgestellte SSO - Benutzer, die noch keine Simulation geplant haben',
    'MursionPortal.Dashboard.Chart.Tooltip.Scheduled':'Registrierte / bereitgestellte SSO - Benutzer, die mindestens eine Simulation geplant haben',
    'MursionPortal.Label.ProjectId':'Vertrags - ID',
    'MursionPortal.Label.SoftwareType':'Typ',
    'MursionPortal.Label.SharingOrDownloading':'Freigeben oder Herunterladen nicht zulassen',
    'Settings.SSO.Modal.EmployeeIDClaimName':'Mitarbeiter - ID Name beanspruchen',
    'Settings.SSO.Modal.LocationClaimName':'Standort Name beanspruchen',
    'Settings.SSO.Modal.DepartmentClaimName':'Abteilung Name beanspruchen',
    'Settings.SSO.Modal.PhoneClaimName':'Telefon Name beanspruchen',
    'Settings.SSO.Modal.TitleClaimName':'Titel Name beanspruchen',
    'Settings.SSO.Modal.TimezoneClaimName':'Zeitzone Name beanspruchen',
    'Settings.SSO.Modal.LanguageClaimName':'Sprache Name beanspruchen',
    'MursionPortal.ProjectId':'Vertrags - ID',
    'MursionPortal.AltText.MursionPortal':'Mursion Portal',
    'MursionPortal.Message.ClickToOpenApplicationDefault':'Klicken Sie unten, um an Ihrer Simulation teilzunehmen',
    'MursionPortal.Message.ClickToOpenApplicationDesktopWeb':'Klicken Sie auf die Schaltfläche unten, um über die Mursion - Desktopanwendung auf Ihre Simulation zuzugreifen',
    'MursionPortal.Message.OpenWebApplication':'Sie können Ihre Simulation auch in Mursion Magic, unserer webbasierten Anwendung, aufrufen',
    'MursionPortal.Button.OpenMursionWeb':'Mursion Magic starten',
    'MursionPortal.Message.Or':'ODER',
    'MursionPortal.Message.PortalHeadingText':'EQ - Trainingsimulation',
    'ITManager.TechRestriction.Table.CreateButton':'Einschränkung hinzufügen',
    'ITManager.TechRestriction.EditDialog.TagName':'Tag - Name',
    'ITManager.TechRestriction.EditDialog.Title':'Technik - Einschränkung bearbeiten',
    'ITManager.TechRestriction.CreateDialog.Title':'Neue Technik - Einschränkung',
    'MursionPortal.DateTimePicker.StartDate':'Startdatum',
    'MursionPortal.DateTimePicker.EndDate':'Enddatum',
    'MursionPortal.DateTimePicker.TimeNotAvailable':'Die ausgewählte Uhrzeit ist nicht verfügbar.',
    'ITManager.TechRestriction.ViewDialog.Title':'Technik - Einschränkung anzeigen',
    'MursionPortal.DateTimePicker.Timezone':'Zeitzone',
    'MursionPortal.Text.Team':'Team',
    'MursionPortal.Text.Industry':'branche',
    'MursionPortal.Text.Environment':'Umfeld',
    'MursionPortal.Text.Avatar':'Avatar',
    'MursionPortal.Status.Capitalized.Upcoming':'BEVORSTEHEND',
    'MursionPortal.Status.Capitalized.Undefined':'UNBESTIMMT',
    'MursionPortal.Status.Capitalized.Orphan':'VERWAIST',
    'MursionPortal.Status.Capitalized.Waif':'VERWAIST_ZUR_GEBUCHTEN_ZEIT',
    'MursionPortal.Status.Capitalized.Reserved':'RESERVIERT',
    'MursionPortal.Status.Capitalized.Booked':'GEBUCHT',
    'MursionPortal.Status.Capitalized.Pending':'AUSSTEHEND',
    'MursionPortal.Status.Capitalized.Running':'IM GANGE',
    'MursionPortal.Status.Capitalized.Missed':'VERPASST',
    'MursionPortal.Status.Capitalized.NeedsReview':'BEWERTUNG_NOTWENDIG',
    'MursionPortal.Status.Capitalized.Reviewed':'BEWERTET',
    'MursionPortal.Status.Capitalized.Cancelled':'ABGESAGT',
    'MursionPortal.Status.Capitalized.LicenseCancelled':'ABGESAGT',
    'MursionPortal.Status.Capitalized.Error':'FEHLER',
    'MursionPortal.Status.Capitalized.SimError': 'ERROR',// TODO add translations
    'MursionPortal.Status.Capitalized.Offline':'OFFLINE',
    'MursionPortal.Status.Abbreviation.PendingNoLearners':'P',
    'MursionPortal.Status.Abbreviation.PendingNoParticipants':'R',
    'MursionPortal.Status.Abbreviation.Swap':'S',
    'MursionPortal.Status.Abbreviation.Prebooked':'PB',
    'MursionPortal.Status.Abbreviation.Booked':'B',
    'MursionPortal.Status.Abbreviation.Active':'A',
    'MursionPortal.Status.Abbreviation.Completed':'C',
    'MursionPortal.Status.Abbreviation.Missed':'M',
    'MursionPortal.Status.Abbreviation.NeedsReview':'NR',
    'MursionPortal.Status.Abbreviation.Error':'E',
    'Settings.SSO.Table.NoSsoConfigurationsFound':'Es wurden keine SSO - Konfigurationen gefunden.',
    'MursionPortal.Label.SelfReviewTracker':'SELBSTBEURTEILUNGSTRACKER',
    'MursionPortal.ToggleButton.All':'alle',
    'MursionPortal.Label.PerformanceTracker':'PERFORMANCE - TRACKER',
    'MursionPortal.ToggleButton.TurnTaking':' RUNDENSPIEL',
    'MursionPortal.Tooltip.Collaborative':'Dies ist ein Maß für die Zeit, in der Sie und der / die Avatar(e) sich abwechselten, ohne sich während des Gesprächs gegenseitig zu unterbrechen.',
    'MursionPortal.Tooltip.Disruptive':'Dies ist ein Maß für die Zeit, in der Sie oder die Avatare sich während des Gesprächs gegenseitig unterbrochen haben.',
    'MursionPortal.Tooltip.Speaking':'Dies ist ein Maß für die Zeit, die Sie im Gespräch mit dem / den Avatar(en) gesprochen haben.',
    'MursionPortal.Tooltip.Listening':'Dies ist ein Maß für die Zeit, die Sie im Gespräch dem / den Avatar(en) zugehört haben.',
    'MursionPortal.Tooltip.MiScore':'Ihre soziale Effizienz während des Gesprächs.',
    'MursionPortal.Tooltip.Percentile':'fügen Sie irgendwo in der Benutzeroberfläche eine Beschreibung dessen hinzu, was dies bedeutet',
    'MursionPortal.Label.MiScore':'MI Punktzahl',
    'MursionPortal.Label.Percentile':'Perzentil',
    'MursionPortal.Label.NotEnoughData':'Nicht genug Daten vorhanden',
    'MursionPortal.Label.Listening':'Zuhören',
    'MursionPortal.Label.Speaking':'Sprechen',
    'MursionPortal.Label.Collaborative':'Gemeinschaftlich',
    'MursionPortal.Label.Disruptive':'Störend',
    'MursionPortal.ToggleButton.LocalLeaderboard':'Lokale Bestenliste',
    'MursionPortal.ToggleButton.GlobalLeaderboard':'Globale Bestenliste',
    'MursionPortal.ToggleButton.DeepInsights':'Tiefgehende Einblicke',
    'MursionPortal.ErrorMessage.DataBeingProcessed':'Sitzungsanalysedaten werden derzeit verarbeitet.Die Daten werden auf der Seite angezeigt, sobald die Verarbeitung abgeschlossen ist.',
    'MursionPortal.ErrorMessage.UnsupportedFormat':'Die Analyse der Sitzung ist leer oder hat ein nicht unterstütztes Format.',
    'MursionPortal.Label.Positive':'Positiv',
    'MursionPortal.Label.Negative':'Negativ',
    'MursionPortal.Label.Neutral':'Neutral',
    'MursionPortal.Header.Tracking':'Verfolgung',
    'MursionPortal.Button.ReadMore':'Mehr erfahren',
    'MursionPortal.Button.Hide':'Ausblenden',
    'MursionPortal.Title.Interchange':'Austausch.Natürliches Gespräch und Rundenspiel ohne Unterbrechungen oder Zwischenrufe',
    'MursionPortal.Title.InterventionByYou':'Eingriff durch Sie.Jemanden, der spricht, erfolgreich zu unterbrechen und ihn daran zu hindern, weiterzumachen',
    'MursionPortal.Title.InterventionByAvatars':'Eingriff durch die Avatare.Jemanden, der spricht, erfolgreich zu unterbrechen und ihn daran zu hindern, weiterzumachen',
    'MursionPortal.Title.InterjectionByYou':'Zwischenrufe von Ihnen.Erfolglos versuchen, jemanden, der spricht, zu unterbrechen',
    'MursionPortal.Title.InterjectionByAvatars':'Zwischenrufe von den Avataren.Erfolglos versuchen, jemanden, der spricht, zu unterbrechen',
    'MursionPortal.Title.Pauses':'Anhalten.Länger als 0, 2 Sek.und kürzer als 0, 75 Sek.',
    'MursionPortal.Label.VoiceSelf':'Stimme eigen',
    'MursionPortal.Label.VoiceOthers':'Stimme anderer',
    'MursionPortal.Label.Avatars':'Avatare',
    'MursionPortal.Label.You':'Sie',
    'MursionPortal.ColumnHeader.User':'Benutzer',
    'MursionPortal.ColumnHeader.Percentile':'Perzentil',
    'MursionPortal.ColumnHeader.ScenariosCompleted':'Abgeschlossene Szenarien',
    'MursionPortal.Message.LeaderboardsUpdated':'Bestenlisten werden aktualisiert, sobald mehr Menschen ihre Simulationen abgeschlossen haben.',
    'MursionPortal.Message.CheckBack':'Bitte schauen Sie gelegentlich wieder vorbei, um zu sehen, welchen Platz Sie belegt haben!',
    'MursionPortal.Label.SimStart':'Sim Start',
    'MursionPortal.Label.SimEnd':'Sim Ende',
    'MursionPortal.Tooltip.Summary.Speaking':'Zeit, in der Sie während des gesamten Gesprächs mit dem / den Avatar(en) gesprochen haben',
    'MursionPortal.Tooltip.Summary.Listening':'Zeit, in der Sie während des gesamten Gesprächs dem / den Avatar(en) zugehört haben',
    'MursionPortal.Capitalized.NotAvailable':'N / Z',
    'MursionPortal.ColumnHeader.Trait':'Merkmal',
    'MursionPortal.ColumnHeader.Me':'Ich',
    'MursionPortal.ColumnHeader.OthersAvg':'Andere(durchschn.)',
    'MursionPortal.TooltipText.Speaking':'Sie sprechen mehr als { percentage } von Menschen.',
    'MursionPortal.TooltipText.Listening':'Sie hören mehr als { percentage } von Menschen zu.',
    'MursionPortal.TooltipText.Collaborative':'Sie sind gemeinschaftlicher als { percentage } der Menschen.',
    'MursionPortal.TooltipText.Disruptive':'Sie sind störender als { percentage } der Menschen.',
    'MursionPortal.Label.FeedbackValue':'Feedback - Wert',
    'MursionPortal.Button.AnalyticsData':'Analysedaten',
    'MursionPortal.Button.RawAnalyticsData':'Rohe Analysedaten',
    'MursionPortal.Header.SocialEffectiveness':'Soziale Effizienz',
    'MursionPortal.Label.Current':'Aktuell',
    'MursionPortal.Header.ArticulationRate':'Artikulationsrate',
    'MursionPortal.Label.Average':'Durchschnittswert',
    'MursionPortal.Header.Conversation':'Gespräch',
    'MursionPortal.Header.SpeakingListening':'Sprechen / Zuhören',
    'MursionPortal.Header.CumulativeTurnTaking':'Kumulatives Rundenspiel',
    'MursionPortal.Header.InstantaneousTurnTaking':'Sofortiges Rundenspiel',
    'MursionPortal.ColumnHeader.ScenarioTemplate':'SCENARIO_TEMPLATE',
    'MursionPortal.ColumnHeader.Provider':'ANBIETER',
    'MursionPortal.Label.AbbreviatedMinute':'m',
    'MursionPortal.Header.Mursion':' < code > M < /code>ursion',
    'MursionPortal.Header.Index':' < code > I < /code>ndex',
    'MursionPortal.Header.Score':'Punktzahl',
    'MursionPortal.visibilityHidden.Selected':'ausgewählt',
    'MursionPortal.AriaLabel.CloseTechSupport':'Technischen Support schließen',
    'MursionPortal.Monitoring':'Überwachung',
    'MursionPortal.Monitoring.User.FirstLetter':'U',
    'MursionPortal.Monitoring.Peer.Label':'PEER - PEER',
    'MursionPortal.Monitoring.Peer.NoUser':'Bitte wählen Sie einen Benutzer, um Details zu sehen',
    'MursionPortal.Monitoring.Sessions.Total':'Gesamtzahl der Sitzungen',
    'MursionPortal.Monitoring.Sessions.Displayed':'ML3 - Sitzungen(angezeigt)',
    'MursionPortal.Monitoring.Sessions.NotDisplayed':'Nicht - ML3 - Sitzungen',
    'MursionPortal.Monitoring.Card.StartTime':'Startzeit',
    'MursionPortal.Monitoring.Notification.NewSession':'Neue Sitzung wurde hinzugefügt.',
    'MursionPortal.ClickRefresh':'Klicken Sie auf „Aktualisieren“, um die Änderungen anzuzeigen.',
    'MursionPortal.AriaLabel.DraftScenarioSuperScript':'Szenario - Entwurf Superskript { statusConfiguration } ',
    'MursionPortal.AriaLabel.FinalizedScenarioSuperScript':'Fertiggestelltes Szenario Superskript { statusConfiguration } ',
    'MursionPortal.AriaLabel.StartedScenarioSuperScript':'Angefangenes Szenario Superskript { statusConfiguration } ',
    'MursionPortal.AriaLabel.FinishedScenarioSuperScript':'Abgeschlossenes Szenario Superskript { statusConfiguration } ',
    'MursionPortal.AriaLabel.ArchivedScenarioSuperScript':'Archiviertes Szenario Superskript { statusConfiguration } ',
    'Calendar.Button.ScheduleEventON':'Veranstaltung geplant am { thisDate } ',
    'Calendar.Button.CantScheduleON':'Veranstaltung kann am { thisDate } nicht geplant werden',
    'MursionPortal.AriaLabel.ChooseFileButton':'Datei wählen - Taste',
    'MursionPortal.Label.VisibilityHidden.FileUploaded':'Datei hochgeladen',
    'MursionPortal.VisibilityHidden.EntityAdded':'Entität hinzugefügt',
    'MursionPortal.VisibilityHidden.EntityRemoved':'Entität entfernt',
    'MursionPortal.Legend.VisibilityHidden.StationTypeHomeOffice':'Stationsart(Büro, Home - Office)',
    'MursionPortal.Legend.VisibilityHidden.HoursFullTimePartTime':'Stunden(Vollzeit, Teilzeit)',
    'MursionPortal.AriaLabel.ShowTechSupport':'Tech - Support anzeigen',
    'MursionPortal.Monitoring.Card.UntilTheEnd':'Verbleibende Zeit',
    'MursionPortal.Monitoring.Card.OverTime':'Überstunden',
    'MursionPortal.Title.DemandBasedSessionScheduling':'Bedarfsabhängige Sitzungsplanung',
    'MursionPortal.CheckboxLabel.SchedulingByDemand':'Zeitplanung nach Bedarf',
    'MursionPortal.CheckboxLabel.ProjectTimezone':'Projektzeitzone',
    'MursionPortal.CheckboxLabel.DemandTime':'Bedarfszeit',
    'MursionPortal.Label.DemandTimeStart':'Bedarfszeit Start',
    'MursionPortal.Label.DemandTimeEnd':'Bedarfszeit Ende',
    'MursionPortal.Label.DemandWindow':'Bedarfsfenster',
    'MursionPortal.Project.Days':'Tag(e)',
    'MursionPortal.MyProfile.Hover.Profile':'Profil { userName } ',
    'Mursion.Portal.SessionUserStatus.Connected':'VERBUNDEN',
    'Mursion.Portal.SessionUserStatus.Late':'VERSPÄTET',
    'Mursion.Portal.SessionUserStatus.Immersing':'EINTAUCHEND',
    'Mursion.Portal.SessionUserStatus.Left':'VERBLEIBEND',
    'Mursion.Portal.SessionUserStatus.Declined':'ABGELEHNT',
    'MursionPortal.DemandBasedScheduling.SubmitPage.ThankYou':'Vielen Dank!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.Message':'Wir arbeiten daran, Ihre Simulation basierend auf den von Ihnen bereitgestellten Zeitfenstern fertigzustellen.Wir lassen Ihnen eine E - Mail mit weiteren Details zukommen.Bis bald!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.GoToDashboard':'Zu Mein Dashboard wechseln',
    'MursionPortal.DemandBasedScheduling.ScheduleASession':'Sitzung planen',
    'MursionPortal.DemandBasedScheduling.Details':'DETAILS',
    'MursionPortal.DemandBasedScheduling.Slot':'ZEITFENSTER { count } ',
    'MursionPortal.DemandBasedScheduling.PickDateAndTime':'Datum + Uhrzeit für die Einstellung auswählen',
    'MursionPortal.DemandBasedScheduling.Button.Set':'EINSTELLEN',
    'MursionPortal.DemandBasedScheduling.Button.Clear':'LÖSCHEN',
    'MursionPortal.DemandBasedScheduling.Jumbotron.Text':'Szenario - Details werden geladen, sobald Sie eine Auswahl vorgenommen haben',
    'MursionPortal.AriaDescribedby.StartDate':'Startdatum: Drücken Sie die Fragezeichen - Taste, um die Tastenkombinationen für die Datumsänderung zu erhalten.',
    'MursionPortal.AriaDescribedby.EndDate':'Enddatum: Drücken Sie die Fragezeichen - Taste, um die Tastenkombinationen für die Datumsänderung zu erhalten.',
    'MursionPortal.VisibilityHidden.Footer':'Fußzeile',
    'MursionPortal.VisibilityHidden.Header':'Kopfzeile',
    'MursionPortal.AriaLabel.FullScreen':'Vollbild',
    'MursionPortal.AriaLabel.ClientNameEventsDisplayed':'Client { clientName } -Veranstaltungen, die im Kalender angezeigt werden',
    'MursionPortal.AriaLabel.SideNavigationToggle':'Seitlicher Navigationsumschalter',
    'MursionPortal.AriaLabel.AddTags':'Tags hinzufügen',
    'MursionPortal.VisibilityHidden.Navigation':'Navigation',
    'MursionPortal.AriaLabel.SortByDescendingOrderButton':'absteigend sortieren',
    'MursionPortal.AriaLabel.SortByAscendingOrderButton':'aufsteigend sortieren',
    'MursionPortal.VisibilityHidden.ProjectsTabSelected':'Registerkarte Projekte ausgewählt',
    'MursionPortal.VisibilityHidden.TeamsTabSelected':'Registerkarte Teams ausgewählt',
    'MursionPortal.VisibilityHidden.SessionsTabSelected':'Registerkarte Sitzungen ausgewählt',
    'MursionPortal.VisibilityHidden.UsersTabSelected':'Registerkarte Benutzer ausgewählt',
    'MursionPortal.Error.PageNotFound':'404 Seite nicht gefunden',
    'MursionPortal.Text.PageNotFound':'Seite nicht gefunden.Prüfen Sie, ob Sie die Berechtigung haben, die Seite anzuzeigen, wenn der Link korrekt ist.',
    'MursionPortal.Text.Success':'Erfolg!',
    'MursionPortal.Text.YouAreNowSignedIn':'Sie sind jetzt bei der Simulationssoftware Mursion angemeldet.',
    'MursionPortal.Text.PleaseReturnToDesktop':'Bitte kehren Sie zur Desktop - Anwendung zurück, indem Sie auf das Symbol \'M\' in der Taskleiste Ihres Computers (Windows) oder Docks (Mac) klicken.',
    'MursionPortal.Client.EmailSettings.Header.DemandBased':'Bedarfsabhängig',
    'MursionPortal.Client.EmailSettings.TimeSlotsReceived':'Erhaltene Zeitfenster',
    'MursionPortal.Label.Past':'Vergangen',
    'MursionPortal.Monitoring.SessionsSummary.Preparation':'Vorbereitung',
    'MursionPortal.Monitoring.SessionsSummary.Normal':'Normal',
    'MursionPortal.Monitoring.SessionsSummary.Error':'Fehler',
    'MursionPortal.Monitoring.SessionsSummary.Issue':'Ausgabe',
    'MursionPortal.Monitoring.SessionsSummary.NotLoggedIn':'Nicht angemeldet',
    'MursionPortal.Monitoring.Legend.Label':'Legende',
    'MursionPortal.Monitoring.Legend.Dashboard':'Dashboard',
    'MursionPortal.Label.SimSpecialist':'Sim - Spezialist',
    'MursionPortal.Monitoring.SessionsSummary.ConnectedToPeers':'Verbunden mit Kollegen',
    'Requests.Table.CertifiedScenarios':'Zertifizierte Szenarien',
    'Requests.Table.UncertifiedScenarios':'Nicht zertifizierte Szenarien',
    'Requests.Table.NoUpComingDemands':'Sie haben keine bedarfsabhängigen Anfragen mit den oben genannten Kriterien.',
    'Requests.Table.DeclineReason':'Ablehnen – Grund auswählen',
    'Requests.Table.UnAvailable':'Nicht verfügbar',
    'Requests.Table.NotPrepared':'Nicht vorbereitet',
    'Requests.Table.Other':'Sonstiges',
    'Requests.Table.Availability':'Verfügbarkeit wählen',
    'Requests.Table.Declined':'Abgelehnt',
    'Requests.Table.SuccessfullySent':'Anfrage erfolgreich gesendet',
    'Requests.Table.RequestCertificate':'Zertifikat anfordern',
    'Requests.Table.DeclineReasonText':'Ablehnungsgrund',
    'Requests.Table.AvailabilitySlot':'Verfügbarkeits - Zeitfenster',
    'Requests.Table.DemandBased':'Bedarfsabhängig',
    'MursionPortal.Setting.Integrations':'Integration',
    'MursionPortal.Setting.Integrations.Title':'Bitte Integration auswählen',
    'MursionPortal.Setting.Integrations.Header.PlatformConfigurations':'Plattformkonfigurationen',
    'MursionPortal.Button.AddConfigurations':'Konfigurationen hinzufügen',
    'Settings.LTI.PlaceHolder.EnterConfigName':'Konfigurationsname eingeben',
    'Settings.LTI.Table.ColumnHeader.DeploymentID':'Bereitstellungs - ID',
    'Settings.LTI.Table.ColumnHeader.Version':'Version',
    'Settings.LTI.Table.Caption':'lti - Konfigurationsliste',
    'Settings.LTI.Table.Hover.ViewEditLti':'Lti anzeigen / bearbeiten',
    'Settings.LTI.Table.NoLtiConfigFound':'Keine LTI - Konfiguration gefunden',
    'MursionPortal.EditLtiModal.Button.DeleteLti':'Konfiguration löschen',
    'MursionPortal.EditLtiModal.Button.SaveLtiConfig':'Konfiguration speichern',
    'MursionPortal.Lti.NewLTIConfig':'Neue LTI - Konfiguration',
    'MursionPortal.Lti.CreateLTIConfig':'LTI - Konfiguration erstellen',
    'Settings.LTI.Modal.PlatformIssuerIdentifier':'Kennung des Plattformausstellers',
    'Settings.LTI.Modal.PublicKeysetEndpoint':'Endpunkt des öffentlichen Schlüsselsatzes',
    'Settings.LTI.Modal.DeploymentId':'Bereitstellungs - ID',
    'Settings.LTI.Modal.RolesMapping':'Rollenzuordnung',
    'Settings.LTI.Modal.RolesMapping.PortalRole':'Portal - Rolle',
    'Settings.LTI.Modal.RolesMapping.LTIRole':'LTI - Rolle',
    'MursionPortal.ErrorMessage.LtiIdMustBeSpecified':'LTI - ID muss angegeben werden.',
    'MursionPortal.Setting.Integrations.LTIVersion':'LTI - Version',
    'MursionPortal.Dialog.CreateLTIConfiguration.SuccessMessage':'LTI - Konfiguration wurde erfolgreich erstellt.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReserved':'Zeitfenster nicht verfügbar – reserviert für eine andere geplante Simulation.',
    'Requests.Table.ViewAvailability':'Verfügbarkeit anzeigen',
    'Settings.LTI.Modal.DeletionConfirmationText':'Sind Sie sicher, dass Sie diese Rollenzuordnung löschen möchten ? ',
    'Settings.ScenarioBank.Modal.InactiveCertification':'Inakitive Zertifizierung',
    'Settings.ScenarioBank.Modal.NoInactiveSimSpecialist':'Kein inaktiver Sim - Spezialist vorhanden',
    'Settings.ScenarioBank.Modal.EditInactiveCertification':'Inakitive Zertifizierung bearbeiten',
    'Settings.ScenarioBank.Modal.NoInactiveCertification':'Keine inakitive Zertifizierung vorhanden',
    'MursionPortal.RequestStatus.All':'Anforderungsstatus: Alle',
    'MursionPortal.RequestStatus.Accepted':'Anforderungsstatus: Akzeptiert',
    'MursionPortal.RequestStatus.Declined':'Anforderungsstatus: Abgelehnt',
    'MursionPortal.RequestStatus.Pending':'Anforderungsstatus: Ausstehend',
    'Requests.Table.RequestCertification':'Zertifizierung anfordern',
    'Requests.Table.RequestTime':'Uhrzeit der Anforderung',
    'Requests.Table.ScheduleType':'Zeitplantyp',
    'Requests.Table.AcceptTime':'Uhrzeit der Genehmigung',
    'Requests.Table.Scheduling':'Planung',
    'Requests.Table.FulfilledIn':'Erfüllt in ',
    'MursionPortal.DemandBasedScheduling.Timezone':'Zeitzone',
    'MursionPortal.DemandBasedScheduling.Timezone.Tooltip':'Gehen Sie zu Ihren Profileinstellungen, um die Zeitzone zu ändern.',
    'MursionPortal.Results.Found':'{ count } Ergebnisse gefunden',
    'MursionPortal.Header.VisibilityHidden.ClientUsers':'Client - Benutzer',
    'MursonPortal.SchedulingType.Auto':'AUTOMATISCH',
    'MursonPortal.SchedulingType.Manual':'MANUELL',
    'MursionPortal.ScheduleTypeSelector.All':'Zeitplan: Alle',
    'MursionPortal.ScheduleTypeSelector.Auto':'Zeitplan: Automatisch',
    'MursionPortal.ScheduleTypeSelector.Manual':'Zeitplan: Manuell',
    'MursionPortal.ScheduleTypeSelector.NA':'Zeitplan: N / Z',
    'Integrations.LTI.AppId':'App - ID',
    'Integrations.LTI.ConfigurationId':'Konfigurations - ID',
    'Settings.LTI.ModalHeader.EditLtiConfiguration':'LTI - Konfiguration bearbeiten',
    'Settings.LTI.Modal.DeletionConfigConfirmationText':'Sind Sie sicher, dass Sie diese LTI - Konfiguration löschen möchten ? ',
    'MursionPortal.Rescheduling.SubmitPage.Message':'Wir senden Ihnen in wenigen Augenblicken eine E - Mail mit mehr Details.Bis bald!',
    'MursionPortal.Rescheduling.RescheduleSimulation':'Simulation verlegen',
    'MursionPortal.Rescheduling.SlotSelectionMessage':'Es tut uns leid, dass Sie Ihre Simulation nicht machen konnten.Wählen Sie einen Tag und eine Uhrzeit, die Ihnen passt!',
    'MursionPortal.IVSBrowserWarning.Safari17.Message1': 'The browser version you are currently using is not supported for your Mursion session',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Message2': 'When joining your Mursion simulation, use one of these browsers:',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Firefox': "Firefox",  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome': "Chrome", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome.Subtitle': "Recommended", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Edge': "Edge", // TODO: add translations
    'MursionPortal.Result.Found':'{ count } Ergebnisse gefunden',
    'MursionPortal.Project.Rescheduling':'Terminverschiebung',
    'Session.Edit.Modal.MissedSession':'VERPASSTE SITZUNG',
    'Session.Edit.Modal.NoLongerRequired':'nicht länger benötigt',
    'Session.Edit.Modal.TechDifficulties':'es bestehen technische Schwierigkeiten',
    'Session.Edit.Modal.Other':'sonstiges',
    'Session.Edit.Modal.CancelBecause':'Ja, die Sitzung abbrechen, weil',
    'Session.Edit.Modal.CancelSimulation':'Simulation absagen',
    'MursionPortal.UserConsent.Heading':'Aufzeichnungsberechtigungen',
    'MursionPortal.UserConsent.Title':'Möchten Sie eine Aufzeichnung dieser Simulationssitzung für Ihre eigene Lernreise ? ',
    'MursionPortal.UserConsent.Description':'Indem Sie mit „Ich stimme zu“ Ihre Zustimmung erteilen, verarbeitet die Mursion-Software Ihre Live-Simulation und erstellt im Mursion-Softwareportal eine Videoaufzeichnung Ihrer Simulationssitzung zur Rezension. Bitte beachten Sie, dass auf Wunsch des Kunden, der die Mursion-Software in Ihrem Namen lizenziert hat, eine Kopie Ihrer aufgezeichneten Simulationssitzung(en) für einen vom Kunden ausgewählten Coach, Mentor oder Rezensent freigegeben wird. Alle Rezensenten sind vertraglich verpflichtet, die <code>Datenschutzrichtlinie von Mursion</code> zu befolgen, es sei denn, Sie haben anderen Datenschutzbestimmungen zugestimmt. Sofern nicht durch geltendes Recht vorgeschrieben, wird Mursion Ihre Videoaufzeichnung nicht ohne Ihre Erlaubnis verbreiten oder offenlegen. Mursion wird anonymisierte Daten aus Ihrer Nutzung seiner Software verwenden, um seine Simulationstechnologie zu verbessern.',
    'MursionPortal.UserConsent.IConsent':'Ich stimme zu',
    'MursionPortal.UserConsent.IDoNotConsent':'Ich stimme nicht zu',
    'MursionPortal.RecordingLinkPermisssion.Title':'Aufzeichnungsberechtigungen',
    'MursionPortal.RecordingLinkPermisssion.DescriptionOne':'Möchten Sie eine Aufzeichnung dieser Sitzung erhalten ? ',
    'MursionPortal.RecordingLinkPermisssion.DescriptionTwo':'Falls ja, überprüfen Sie bitte Ihren E - Mail - Posteingang und bestätigen Sie Ihre E - Mail - Adresse.Falls sie verfügbar ist, erhalten Sie morgen einen Link.',
    'MursionPortal.PublicSession.PageTitle':'Geben Sie Ihre Daten ein, um an einer Simulation teilzunehmen',
    'MursionPortal.PublicSession.Form.label.FirstName':'Vorname',
    'MursionPortal.PublicSession.Form.label.LastName':'Nachname',
    'MursionPortal.PublicSession.Form.label.Email':'E - Mail - Adresse(Optional)',
    'PublicSession.Form.Button.Join':'Teilnehmen',
    'MursionPortal.PublicSession.Form.Placeholder.FirstName':'Ihr Vorname',
    'MursionPortal.PublicSession.Form.Placeholder.LastName':'Ihr Nachname',
    'MursionPortal.PublicSession.Form.Placeholder.Email':'name @provider.com',
    'MursionPortal.PublicSession.Form.Placeholder.HaveAccount':'Sie haben bereits ein Konto ? ',
    'MursionPortal.PublicSession.Form.Placeholder.LogInHere':'Hier anmelden',
    'Session.Edit.Modal.ToolTip.Google':'Fügen Sie ein Ereignis zu Ihrem Google - Kalender hinzu.Bestätigen Sie die Autorisierung, falls erforderlich',
    'Session.Edit.Modal.ToolTip.OutlookIcalOther':'Klicken Sie hier, um das Ereignis auf Ihren Desktop herunterzuladen und zu Ihrer Kalender - App hinzuzufügen',
    'Session.Edit.Modal.SimSpecialistAssigned':'Es wurde ein Sim - Spezialist zugewiesen',
    'Session.Edit.Modal.SessionSimSpecialistField.AddSIMSpecialist':'SIM - Spezialisten hinzufügen',
    'Session.Edit.Modal.DemandBasedTimeSlots':'Bedarfsabhängige Zeitfenster',
    'Session.Edit.Modal.Reschedule':'Terminverschiebung',
    'Session.Edit.Modal.AddLearner':'Lernende hinzufügen',
    'MursionPortal.Label.SearchOrSelectLearners':'Lernende suchen oder auswählen',
    'MursionPortal.Client.EmailSettings.Header.Rescheduling':'Terminverschiebung',
    'MursionPortal.Client.EmailSettings.RescheduleMissedSession':'Neuen Termin für verpasste Sitzung festlegen',
    'Session.Edit.Modal.OtherPrioritiesAtWork':'andere Prioritäten bei der Arbeit',
    'MursionPortal.Modal.Header.Sorry':'Sorry!',
    'MursionPortal.Modal.UnschedulableSessionMsg':'Es kann kein Termin für die Sitzung festgelegt werden.',
    'MursionPortal.Rescheduling.Slot.NoLongerAvailable':'Die ausgewählte Uhrzeit ist nicht mehr verfügbar, bitte wählen Sie eine andere Option.',
    'MursionPortal.EmailVerificationPage.Success':'E - Mail - Adresse erfolgreich bestätigt!',
    'MursionPortal.EmailVerificationPage.SuccessDesc':'Ihre E - Mail - Adresse wurde erfolgreich bestätigt',
    'MursionPortal.EmailVerificationPage.Error':'Bestätigung der E - Mail - Adresse fehlgeschlagen!',
    'MursionPortal.EmailVerificationPage.ErrorDesc':'Etwas ist schiefgelaufen, bitte versuchen Sie es erneut, Ihre E - Mail - Adresse zu bestätigen',
    'Settings.Config.Heading.SplashPageAttendance':'TEILNAHME',
    // 'Clients.Modal.Label.ShowSplashPage':'Teilnahme - / Aufzeichnungszustimmung eines nicht registrierten Benutzers',
    // 'Clients.Modal.Label.ShowSplashPage.ToolTip':'Teilnahme und Aufzeichnungszustimmung für nicht registrierte Benutzer in ML3z / Meet - Simulationen sammeln.',
    // 'Settings.Config.Invitation.ShowSplashPage':'Teilnahme und Aufzeichnungszustimmung für nicht registrierte Benutzer in ML3z / Meet - Simulationen sammeln.',
    'Settings.Config.External.Users':'(Kann in der Konfiguration des Kunden deaktiviert werden)',
    'Settings.Config.Invitation.ExternalUser':'Für die Teilnahme externer Benutzer',
    'MursionPortal.Scenario.Vignette.ButtonText.ShowMore.SessionDetails':'Sitzungsdetails',
    'MursionPortal.DemandBasedScheduling.ProjectDropdown.Placeholder':'Projekt auswählen',
    'MursionPortal.DemandBasedScheduling.ScenarioDropdown.Placeholder':'Szenario auswählen',
    'Session.Edit.Modal.Label.SearchOrSelectASimSpecialist':'SIM - Spezialisten auswählen oder suchen',
    'MursionPortal.Button.Exclamation.GotIt':'Verstanden!',
    'MursionPortal.Modal.UnavailableSlotMsg':'Die ausgewählte Uhrzeit ist jetzt nicht verfügbar.Bitte wählen Sie ein neues Zeitfenster.',
    'MursionPortal.SimAttendance.NoLongerAvailable':'Sie können die Teilnahme für diese Simulation nicht mehr bearbeiten.',
    'MursionPortal.SimAttendance.Midnight':'Sie müssen jeden Tag bis Mitternacht die Teilnahme abschließen.Die Teilnahme an dieser Simulation entspricht standardmäßig dem Systemstatus.Bitte wenden Sie sich an den Support, falls etwas nicht ordnungsgemäß verläuft.',
    'MursionPortal.SimAttendance.Instructions':'Anweisungen',
    'MursionPortal.SimAttendance.Attendee':'Teilnehmer',
    'MursionPortal.SimAttendance.Tooltip.Missed':'Die / Der Lernende hat sich nie mit der Simulation verbunden.',
    'MursionPortal.SimAttendance.Tooltip.Declined':'Die / Der Lernende hat sich mit der Simulation verbunden, lehnte aber die Teilnahme ab und beendete die Simulation, bevor sie / er mindestens 50 % der Simulation abgeschlossen hatte.',
    'MursionPortal.SimAttendance.Add.Attendee':'Teilnehmer hinzufügen',
    'Session.Edit.Modal.EditAttendance':'Teilnehmer bearbeiten',
    'Session.Edit.Modal.CompleteAttendance':'Teilnahme abschließen',
    'MursionPortal.SimAttendance.Late':'Verspätet',
    'MursionPortal.SimAttendance.Left':'Verbleibend',
    'MursionPortal.Instructions.First':'Wählen Sie für jeden Teilnehmer einen Anwesenheitsstatus aus(bewegen Sie die Maus über den Status, um mehr zu erfahren).Wenn ein Lernender nicht aufgeführt ist, klicken Sie auf',
    'MursionPortal.Instructions.Second':'die Funktion unten links.',
    'MursionPortal.TableColumn.SimsAction':'Sim - Aktion',
    'Session.Edit.Modal.Label.SelectTrainer':'Trainer auswählen oder suchen',
    'MursionPortal.Project.DemandSlots':'Zeitfenster anfordern',
    'MursionPortal.CompanyCard.InviteOperations':'Einsatzplanung einladen',
    'MursionPortal.CompanyCard.Modal.Header.NewOperations':'Neue Einsatzplanung',
    'Settings.Config.Invitation.ShowContractFeature':'Hinzufügen von Verträgen zulassen',
    'Settings.Config.Contract.Description':'(zur Verfolgung des Vertragsfortschritts)',
    'MursionPortal.Contract.Edit':'Bearbeiten',
    'MursionPortal.Contract.ContractName':'Vertragsname',
    'MursionPortal.Contract.ContractID':'Vertrags - ID',
    'MursionPortal.Contract.ContractID.PlaceHolder':'Vertrags - ID von Hubspot eingeben',
    'MursionPortal.Contract.StartAndEndDates':'Vertragsbeginn und - ende',
    'MursionPortal.Contract.Notes.PlaceHolder':'Geben Sie andere Informationen ein, die nur CSMs und BDDs angezeigt werden sollen.',
    'MursionPortal.Contract.LineItem.Add':'Vertragsposten hinzufügen',
    'MursionPortal.Contract.LineItem.Add.Instruction':'Sie können mehrere Simulationsposten hinzufügen.Alle anderen Typen können nur einen Posten haben.',
    'MursionPortal.Contract.LineItemType':'Postenart',
    'MursionPortal.Contract.LineItemType.PlaceHolder':'Postenart auswählen',
    'MursionPortal.Contract.Quantity':'Menge',
    'MursionPortal.Contract.Quantity.PlaceHolder':'Menge eingeben',
    'MursionPortal.Contract.DeliveryMode.PlaceHolder':'Lieferart auswählen',
    'MursionPortal.Contract.SaveContract':'Vertragsdetails speichern',
    'MursionPortal.Contract.Length.PlaceHolder':'Länge auswählen',
    'MursionPortal.Contract.SowName.PlaceHolder':'Vertragsname eingeben',
    'MursionPortal.Client.Tab.Contracts':'Verträge',
    'MursionPortal.Client.Contracts':'Kundenverträge',
    'MursionPortal.Client.Create.Contracts':'Vertrag erstellen',
    'MursionPortal.Client.Contract.ID':'Vertrags - ID',
    'MursionPortal.NewUser.Optional':'(optional)',
    'MursionPortal.NewUser.SuchAGoogleAccountOrOtherSsoProvider.TooltipText':'Zum Beispiel ein Google - Konto oder ein anderer SSO - Anbieter',
    'MursionPortal.UserConsentDeclineConfirmation.Heading':'Bitte bestätigen Sie Ihre Auswahl.',
    // 'MursionPortal.UserConsentDeclineConfirmation.Title':'Sind Sie sicher ? Um ohne Aufzeichnung fortzufahren, klicken Sie auf „Bestätigen“. Um Ihre Zustimmung zur Aufzeichnung zu ändern, klicken Sie auf „Abbrechen“.',
    'Settings.Config.Invitation.ShowSowFeature':'Hinzufügen von SOW zulassen',
    'Settings.Config.SOW.Description':'(zur Verfolgung des SOW - Fortschritts)',
    'MursionPortal.ScenarioBank.StandardizedScenario':'Standardisiertes Szenario',
    'MursionPortal.ScenarioBank.ShowOnlyBundleScenario':'Nur standardisierte Szenarien anzeigen',
    'Settings.Config.Invitation.SimWorkforceManagement':'SIM - Personaleinsatzplanung',
    'Settings.Config.SimWorkforceManagement.Description':'„Aktiviert“ bedeutet, dass die Berichterstattung auf der Dashboard - Seite verfügbar ist, und „deaktiviert“ bedeutet, dass die Berichterstattung überhaupt nicht vorhanden ist.',
    'MursionPortal.ScenarioBank.BundleScenario':'Szenarien bündeln',
    'MursionPortal.Scheduling.SimAvailable.TimeSlotsTooltipMsg':'Wählen Sie dieses Zeitfenster, um Ihre Szenariobuchung automatisch zu bestätigen!',
    'Dashboard.LeftPane.CategoryHeader.SupplyManagement':'SIM - Personaleinsatzplanung',
    'Dashboard.LeftPane.SupplyManagement.Scheduling':'Planung',
    'Dashboard.SupplyManagement.Scheduling.MainHeader':'Planung',
    'Dashboard.SupplyManagement.Scheduling.TableHeader':'Planungsdaten',
    'Dashboard.LeftPane.SupplyManagement.ClientRoster':'Dienstplan des Kunden',
    'Dashboard.SupplyManagement.ClientRoster.MainHeader':'Dienstplan des Kunden',
    'Dashboard.SupplyManagement.ClientRoster.TableHeader':'Dienstplandaten des Kunden',
    'MursionPortal.Table.ColumnHeader.Time':'Uhrzeit',
    'MursionPortal.Table.ColumnHeader.AllScheduledSessions':'Alle geplanten(Sitzungen)',
    'MursionPortal.Table.ColumnHeader.SimsAvailable':'Verfügbare SIMs(Leute)',
    'MursionPortal.Table.ColumnHeader.AvailabilityHours':'Verfügbarkeit(Stunden)',
    'MursionPortal.Table.ColumnHeader.DemandBasedRequests':'Alle bedarfsabhängigen Anfragen(Gesamt – alle 3 Anfragen)',
    'MursionPortal.Table.ColumnHeader.SlotOneRequests':'Anfragen für Zeitfenster 1',
    'MursionPortal.Table.ColumnHeader.SlotTwoRequests':'Anfragen für Zeitfenster 2',
    'MursionPortal.Table.ColumnHeader.SlotThreeRequests':'Anfragen für Zeitfenster 3',
    'MursionPortal.Table.ColumnHeader.PendingRequests':'Ausstehende Anfragen(Sitzungen)',
    'MursionPortal.Table.ColumnHeader.Auto':'Automatisch',
    'MursionPortal.Table.ColumnHeader.Manual':'Manuell',
    'MursionPortal.Table.ColumnHeader.CapacityIndicator':'Kapazitätsanzeige',
    'MursionPortal.EmptyMessage.NoSupplyManagementDataYet':'Es liegen noch keine Daten zur Sim - Personaleinsatzplanung vor',
    'MursionPortal.Table.ColumnHeader.SimCertified':'SIM - zertifiziert',
    'MursionPortal.Table.ColumnHeader.ScheduledHours':'Geplant(Stunden)',
    'MursionPortal.Table.ColumnHeader.Sessions':'Sitzungen',
    'MursionPortal.Table.ColumnHeader.AvgFullfilled':'Im Durchschn.erfüllt in ',
    'MursionPortal.Table.ColumnHeader.SlotOneFullFillment':'Erfüllung Zeitfenster 1',
    'MursionPortal.Table.ColumnHeader.Cancelled':'Abgesagt',
    'MursionPortal.Table.ColumnHeader.Missed':'Verpasst',
    'MursionPortal.Table.ColumnHeader.Reschedules':'Terminverschiebungen',
    'MursionPortal.Table.ColumnHeader.Error':'Fehler',
    'MursionPortal.Table.ColumnHeader.Completed':'Abgeschlossen',
    'MursionPortal.Table.ColumnHeader.Other':'Sonstiges',
    'MursionPortal.Table.ColumnHeader.SchedulingWindow':'Fenster für Terminverschiebungen',
    'MursionPortal.Table.ColumnHeader.RequestDemand':'Nr.der Anfrage(Nachfrage)',
    'MursionPortal.Table.ColumnHeader.AutoFulfilled':'Automatisch erfüllt',
    'MursionPortal.Table.ColumnHeader.Pending':'Ausstehend',
    'MursionPortal.Table.ColumnHeader.SlotTwoFullFillment':'Erfüllung Zeitfenster 2',
    'MursionPortal.Table.ColumnHeader.SlotThreeFullFillment':'Erfüllung Zeitfenster 3',
    'MursionPortal.Table.ColumnHeader.UnutilizedAvailability':'Ungenutzte Verfügbarkeit(Stunden)',
    'MursionPortal.Table.ColumnHeader.TotalAvailabilityEntered':'Eingegebene Gesamtverfügbarkeit',
    'MursionPortal.Table.ColumnHeader.AverageHoursWeek':'Durchschnittliche Stunden / Woche',
    'MursionPortal.Table.ColumnHeader.AvailabilityUtilization':'Verfügbarkeitsnutzung',
    'MursionPortal.SupplyManagement.Above':'Über',
    'MursionPortal.SupplyManagement.Below':'Unter',
    'MursionPortal.SupplyManagement.At':'Bei',
    'MyProfile.ProfServiceRole.Tooltiptext':'Benutzer mit unserer höchsten Zugriffsebene',
    'MyProfile.AccManager.Tooltiptext':'Erstellt Szenarien, Teams, fügt Benutzer hinzu und weist Teams den Simulationen zu.',
    'MyProfile.SimSpecialist.Tooltiptext':'Diese Rolle wird auch als „Sim“ bezeichnet und führt Simulationen für die Lernenden durch.',
    'MyProfile.Buyer.Tooltiptext':'Ein Benutzer, der ein Mursion - Projekt für sein Unternehmen verwaltet',
    'MyProfile.Facilitator.Tooltiptext':'Simulationsteilnehmer, der bei der Bereitstellung der Simulation behilflich ist',
    'MyProfile.Learner.Tooltiptext':'Simulationsteilnehmer',
    'MyProfile.Operations.Tooltiptext':'Mursion - Mitarbeiter, der Verträge hinzufügen und bearbeiten kann',
    'MyProfile.SimDesigner.Tooltiptext':'Ein Benutzer, der Bankszenarien erstellen und bearbeiten kann',
    'MursionPortal.Label.AssignedUserRoles':'Zugewiesene Benutzerrollen',
    'MursionPortal.Label.AssignedUserRole':'Zugewiesene Benutzerrolle',
    'Clients.Modal.Projects.Button.CreateFromBank':'Von der Bank erstellen',
    'MursionPortal.Legend.VisibilityHidden.ScenarioPanel.Buttons':'Neu erstellen, Kunde wiederholen, Aus Bankszenario - Schaltflächen erstellen',
    'MursionPortal.Label.ArtBundle':'Kunstpaket',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContentMsg':'Dies ist der 3D - Kunstinhalt von Umgebungen und Avataren, die in der Simulation verwendet werden.Der Inhalt dieses Ordners wird unten im Abschnitt mit dem Titel „Kunstpaket“ angezeigt.',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoArtBundles':'Dem Szenario sind keine Kunstpakete zugewiesen.',
    'MursionPortal.Label.ArtProject':'Kunstprojekt',
    'MursionPortal.Label.UpperCase.ArtProject':'KUNSTPROJEKT',
    'Settings.Config.SimulationContent.ArtProjectFolder':'Kunstprojekt S3 - Ordner',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoArtProject':'Dem Szenario sind keine Kunstprojekte zugewiesen.',
    'MursionPortal.SessionWizard.Label.SelectArtContentForUse':'Kunstprojekt zur Verwendung auswählen',
    'Projects.Message.NoArtProjectFound':'Kein Kunstprojekt gefunden',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenarioBank':'Aus diesem Bankszenario wurden aktive untergeordnete Szenarien erstellt.Sie müssen diese einzeln archivieren.',
    'Settings.StandardizedScenarioBank.EditConfirmation':'Sind Sie sicher, dass Sie alle bestehenden Szenarien aktualisieren möchten, die aus diesem Szenario erstellt wurden ? ',
    'Settings.StandardizedScenarioBank.AttachmentsEditConfirmation':'Die Aktualisierung der Anhänge ändert alle bestehenden Szenarien, die aus diesem Szenario erstellt wurden.',
    'MursionPortal.Contract.Ending':'Abschluss',
    'MursionPortal.ProjectContracts.DuplicateError':'Der von Ihnen ausgewählte Vertrag überschneidet sich mit einem anderen Vertrag, der diesem Projekt hinzugefügt wurde.Bitte wählen Sie einen anderen aus oder wenden Sie sich an die Einsatzplanung, um eine Auftragsänderung anzufordern.',
    'MursionPortal.ProjectContracts.DateErrorHeading':'Für diese Projektzeiten liegt kein Vertrag vor',
    'MursionPortal.ProjectContracts.DateError':'Es gibt keinen Vertrag zur Unterstützung dieses Projektbeginns und Projektendes.Ohne einen gültigen Vertrag sollten keine Projekte erstellt werden.Wollen Sie fortfahren ? ',
    'MursionPortal.Contract.ViewDetails':'Details anzeigen',
    'MursionPortal.Contract.PleaseAddContractToProject':'Bitte Vertrag zum Projekt hinzufügen',
    'MursionPortal.Contract.AddContractToProject':'Vertrag zum Projekt hinzufügen',
    'MursionPortal.Contract.SelectContractToProject':'Wählen Sie den Vertrag aus, den Sie diesem Projekt hinzufügen möchten – bitte fügen Sie nur einen auf einmal hinzu.',
    'MursionPortal.Contract.AddSelectedContract':'Ausgewählten Vertrag hinzufügen',
    'MursionPortal.Contract.AddContract':'Vertrag hinzufügen',
    'MursionPortal.Contract.LineItemList':'Liste der Posten',
    'MursionPortal.DemandBasedScheduling.InstantBooking':'Sofortbuchung',
    'MursionPortal.DemandBasedScheduling.AllSlots':'Zeitfenster absenden',
    'MursionPortal.DemandBasedScheduling.ScheduleYourScenario':'Wir planen Ihr Szenario in einem der von Ihnen gewählten Zeitfenster.',
    'MursionPortal.DemandBasedScheduling.SelectOneOfTheseSlotsForAnInstantBooking':'Wählen Sie eines dieser Zeitfenster für eine Sofortbuchung aus.',
    'MursionPortal.DemandBasedScheduling.TheseSlotDoNotwork':'Wenn diese Zeitfenster nicht funktionieren, wählen Sie bitte „Zeitfenster absenden“, um Zeiten vorzulegen, die für Sie funktionieren.',
    'MursionPortal.AltText.CrossButton':'Bild mit Kreuzschaltfläche',
    'MursionPortal.Title.BankScenario':'Bankszenario',
    'Dashboard.LeftPane.SupplyManagement.SimRoster':'Sim - Dienstplan',
    'Dashboard.SupplyManagement.SimRoster.MainHeader':'Sim - Dienstplan',
    'Dashboard.SupplyManagement.SimRoster.TableHeader':'Sim - Dienstplandaten',
    'MursionPortal.Table.ColumnHeader.SimFullName':'SIM Vollständiger Name',
    'MursionPortal.Table.ColumnHeader.LanguagesDelivered':'Angebotene Sprachen(zertifiziert nach Sprache)',
    'MursionPortal.Table.ColumnHeader.Tier':'Ebene',
    'MursionPortal.Table.ColumnHeader.Timezone':'Zeitzone',
    'MursionPortal.Table.ColumnHeader.Scheduled':'Geplant',
    'MursionPortal.Table.ColumnHeader.ProjectsHoursWeekTotal':'Voraussichtlich Stunden / Woche insgesamt',
    'MursionPortal.Table.ColumnHeader.ActiveClientCertifications':'Aktive Kunden - Zertifizierungen(kostenpflichtig)',
    'MursionPortal.Table.ColumnHeader.InactiveCertifications':'Inakitive Zertifizierung',
    'MursionPortal.Table.ColumnHeader.Declined':'Abgelehnt',
    'MursionPortal.Table.ColumnHeader.AvgFullfillTimeForManual':'Durchschnittliche Erfüllungszeit für manuell',
    'MursionPortal.Table.ColumnHeader.Queue':'Warteschlange',
    'MursionPortal.Table.ColumnHeader.Availability':'Verfügbarkeit',
    'MursionPortal.Filters.Tier':'Ebene',
    'MursionPortal.Filters.PlaceHolder.SearchTier':'Suchebene',
    'MursionPortal.TierOptions.Tier1':'Ebene 1',
    'MursionPortal.TierOptions.Tier2':'Ebene 2',
    'MursionPortal.TierOptions.Tier3':'Ebene 3',
    'MursionPortal.TierOptions.Tier4':'Ebene 4',
    'MursionPortal.TierOptions.LeadSimulation':'Leitender Simulationsspezialist',
    'MursionPortal.Contract.BackToCLient':'Zurück zum Kunden',
    'MursionPortal.DeliveryMode.Workshop':'Workshop',
    'MursionPortal.DeliveryMode.PrivatePractice':'Privatpraxis',
    'MursionPortal.DemandBasedScheduling.SimOnlySlotSelectionMessage':'Bitte wählen Sie Tag und Zeitfenster, die Ihnen passen.',
    'Dashboard.LeftPane.CategoryHeader.Performance':'AUSLASTUNG',
    'Dashboard.LeftPane.Performance.ContractOverview':'Überblick',
    'Dashboard.LeftPane.Performance.ContractSummaries':'Vertragszusammenfassungen',
    'Dashboard.LeftPane.Performance.ContractProgress':'Vertragsfortschritt',
    'Dashboard.LeftPane.CategoryHeader.Learners':'LERNENDE',
    'Dashboard.LeftPane.Learners.LearnerActivity':'Lernaktivität',
    'Dashboard.LeftPane.Learners.LearnerSimulations':'Lernsimulationen',
    'Dashboard.Total':'Einträge insgesamt',
    'Filters.SimulationStatus':'Simulationsstatus',
    'Filters.SimulationStatusPlaceholder':'Simulationsstatus suchen ...',
    'Filters.LearnerStatus':'Status der / des Lernenden',
    'Filters.LearnerStatusPlaceHolderHint':'Status der / des Lernenden suchen ...',
    'Filters.SimulationRecorded':'Simulation aufgezeichnet',
    'Filters.Standardized':'Standardisiert',
    'Filters.Boolean.Yes':'Ja',
    'Filters.Boolean.No':'Nein',
    'Dashboard.UserTable.Column.Roles':'Rollen',
    'Dashboard.ContractSummaries.MainHeader':'Vertragszusammenfassungen',
    'Dashboard.ContractOverview.MainHeader':'Überblick',
    'Dashboard.ContractProgress.MainHeader':'Vertragsfortschritt',
    'Dashboard.ContractProgress.Navigation.ContractItems':'Vertragsgegenstände',
    'Dashboard.ContractProgress.Navigation.Projects':'Projekte',
    'Dashboard.ContractProgress.Navigation.Scenarios':'Szenarien',
    'Dashboard.ContractProgress.Column.Item':'Posten',
    'Dashboard.ContractProgress.Column.Purchased':'Gekauft',
    'Dashboard.ContractProgress.Column.Remaining':'Verbleibend',
    'Dashboard.ContractProgress.Column.Completed':'Abgeschlossen',
    'Dashboard.ContractProgress.Column.Scheduled':'Geplant',
    'Dashboard.ContractProgress.Column.LateCancellations':'Späte Absagen',
    'Dashboard.ContractProgress.Column.ProjectName':'Projektname',
    'Dashboard.ContractProgress.Column.StartDate':'Startdatum',
    'Dashboard.ContractProgress.Column.EndDate':'Enddatum',
    'Dashboard.ContractProgress.Column.Error':'Fehler',
    'Dashboard.ContractProgress.Column.ScenarioName':'Szenarioname',
    'Dashboard.ContractProgress.Column.Standardized':'Standardisiert',
    'Dashboard.ContractProgress.Column.LearnersAssigned':'Zugewiesene Lernende',
    'Dashboard.ContractProgress.Column.LearnersCompleted':'Abschließende Lernende',
    'Dashboard.ContractProgress.Column.LearnersCompletedPercent':'Abschließende Lernende % ',
    'Dashboard.ContractProgress.Column.Client':'Kunde',
    'Dashboard.ContractProgress.Column.ContractName':'Vertragsname',
    'Dashboard.ContractProgress.Column.ContractId':'Vertrags - ID',
    'Dashboard.ContractProgress.Column.ContractNameHoverTitle':'Kopie - ID',
    // 'Dashboard.ContractProgress.Column.PercentComplete':' % abgeschlossen',
    'Dashboard.ContractProgress.Column.ErrorLearner':'Fehler(Lernender)',
    'Dashboard.ContractProgress.Column.LearnersInvited':'Eingeladene Lernende',
    'Dashboard.ContractProgress.Column.LearnersScheduledPercent':' % geplanter Lernenden',
    'Dashboard.ContractProgress.ColumnTooltip.Client':'Name des Kunden',
    'Dashboard.ContractProgress.ColumnTooltip.ContractName':'Name des Vertrages',
    'Dashboard.ContractProgress.ColumnTooltip.PercentComplete':'Prozentsatz der abgeschlossenen vertraglichen Tage',
    'Dashboard.ContractProgress.ColumnTooltip.CompletedUtilization':'Abgeschlossene Simulationen als Prozentsatz der gekauften Simulationen',
    'Dashboard.ContractProgress.ColumnTooltip.ScheduledUtilization':'Abgeschlossene und geplante Simulationen als Prozentsatz der gekauften Simulationen',
    'Dashboard.ContractProgress.ColumnTooltip.MissedLearner':'Simulationen, die die / der Lernende verpasst hat.',
    'Dashboard.ContractProgress.ColumnTooltip.ErrorLearner':'Simulationen, die auf der Seite der / des Lernenden mit einem Fehler endeten',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersInvited':'Lernende, die eingeladen wurden, eine Simulation zu planen',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersScheduledPercent':'Lernende, die mindestens eine Simulation geplant haben, als Prozentsatz derjenigen, die eingeladen wurden, eine Simulation zu planen',
    'Dashboard.ContractProgress.ColumnTooltip.Item':'Vertragsgegenstand im Vertrag',
    'Dashboard.ContractProgress.ColumnTooltip.Purchased':'Gesamtzahl der im Rahmen eines Vertrags erworbenen Simulationen',
    'Dashboard.ContractProgress.ColumnTooltip.Completed':'Erfolgreich abgeschlossene Simulationen',
    'Dashboard.ContractProgress.ColumnTooltip.Scheduled':'Bevorstehende Simulationen, die geplant, aber noch nicht abgeschlossen sind(einschließlich der derzeit laufenden)',
    'Dashboard.ContractProgress.ColumnTooltip.Missed':'Simulationen, die die / der Lernende verpasst hat.',
    'Dashboard.ContractProgress.ColumnTooltip.LateCancellations':'Simulationen, die spät(nach Ablauf der Absagefrist) vom Lernenden, Moderator oder Kontoinhaber abgesagt wurden, oder Simulationen, die die / der Lernende verlassen oder abgelehnt hat',
    'Dashboard.ContractProgress.ColumnTooltip.Error':'Simulationen, die auf der Seite der / des Lernenden mit einem Fehler endeten',
    'Dashboard.ContractProgress.ColumnTooltip.ProjectName':'Projektname',
    'Dashboard.ContractProgress.ColumnTooltip.StartDate':'Vertragsbeginn wie im Vertrag angegeben.',
    'Dashboard.ContractProgress.ColumnTooltip.EndDate':'Vertragsende wie im Vertrag angegeben.',
    'Dashboard.ContractProgress.ColumnTooltip.ScenarioName':'Name des Szenarios',
    'Dashboard.ContractProgress.ColumnTooltip.Standardized':'Dies ist ein standardisiertes Szenario',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersAssigned':'Anzahl der Lernenden, denen ein Szenario zugewiesen wurde',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompleted':'Lernende, die mindestens eine Simulation abgeschlossen haben, als Prozentsatz derjenigen, die eingeladen wurden, eine Simulation zu planen',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompletedPercent':'Prozentsatz der einzelnen Lernenden, die das Szenario abgeschlossen haben',
    'Dashboard.Learners.Column.Provider':'Anbieter',
    'Dashboard.Learners.Column.Client':'Kunde',
    'Dashboard.Learners.Column.LearnerName':'Name der / des Lernenden',
    'Dashboard.Learners.Column.DateInvited':'Datum der Einladung',
    'Dashboard.Learners.Column.LastLogin':'Letzte Anmeldung',
    'Dashboard.Learners.Column.NoAssignedTeams':'Keine Teams zugewiesen',
    'Dashboard.Learners.Column.NoAssignedScenarios':'Keine Szenarien zugewiesen',
    'Dashboard.Learners.Column.NoCompletedScenarios':'Keine abgeschlossenen Szenarien',
    'Dashboard.Learners.Column.Completed':'Abgeschlossen',
    'Dashboard.Learners.Column.Scheduled':'Geplant',
    'Dashboard.Learners.Column.LateCancelled':'Spät abgesagt',
    'Dashboard.Learners.Column.EndedInError':'Mit Fehler beendet',
    'Dashboard.Learners.Column.ProjectName':'Projektname',
    'Dashboard.Learners.Column.ScenarioName':'Szenarioname',
    'Dashboard.Learners.Column.ScheduledStartDate':'Geplantes Startdatum',
    'Dashboard.Learners.Column.ScheduledStartTime':'Geplante Startzeit',
    'Dashboard.Learners.Column.ScheduledEndTime':'Geplante Endzeit',
    'Dashboard.Learners.Column.SimulationStatus':'Simulationsstatus',
    'Dashboard.Learners.Column.LearnerStatus':'Status der / des Lernenden',
    'Dashboard.Learners.Column.LearnerTimeSpent':'Zeitaufwand des Lernenden',
    'Dashboard.Learners.Column.SimSpecialistName':'Name des Sim - Spezialisten',
    'Dashboard.Learners.Column.SimSpecialistStatus':'Status des Sim - Spezialisten',
    'Dashboard.Learners.Column.Report':'Bericht',
    'Dashboard.Learners.ColumnTooltip.Provider':'Name des Anbieters / Lizenznehmers',
    'Dashboard.Learners.ColumnTooltip.Client':'Name des Kunden',
    'Dashboard.Learners.ColumnTooltip.LearnerName':'Nach - und Vorname des Benutzers',
    'Dashboard.Learners.ColumnTooltip.DateInvited':'Datum, an dem ein(e) Lernende(r) zum ersten Mal eingeladen wurde, ein Szenario zu planen.',
    'Dashboard.Learners.ColumnTooltip.LastLogin':'Datum der letzten Anmeldung eines Benutzers im Portal',
    'Dashboard.Learners.ColumnTooltip.NoAssignedTeams':'Anzahl der Teams, die einer / m Lernenden zugewiesen wurden',
    'Dashboard.Learners.ColumnTooltip.NoAssignedScenarios':'Anzahl einzelner Szenarien, die einer / m Lernenden zugewiesen wurden',
    'Dashboard.Learners.ColumnTooltip.NoCompletedScenarios':'Anzahl einzelner Szenarien, die von einer / m Lernenden abgeschlossen wurden',
    'Dashboard.Learners.ColumnTooltip.Completed':'Erfolgreich abgeschlossene Simulationen',
    'Dashboard.Learners.ColumnTooltip.Scheduled':'Bevorstehende Simulationen, die geplant, aber noch nicht abgeschlossen sind(einschließlich der derzeit laufenden)',
    'Dashboard.Learners.ColumnTooltip.Missed':'Simulationen, die die / der Lernende verpasst hat.',
    'Dashboard.Learners.ColumnTooltip.LateCancelled':'Simulationen, die vom Lernenden, Moderator oder Kontoinhaber verspätet(nach Ablauf der Absagefrist) oder Simulationen abgesagt wurden.',
    'Dashboard.Learners.ColumnTooltip.EndedInError':'Simulationen, die auf der Seite der / des Lernenden mit einem Fehler endeten',
    'Dashboard.Learners.ColumnTooltip.ProjectName':'Projektname',
    'Dashboard.Learners.ColumnTooltip.ScenarioName':'Name des Szenarios',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartDate':'Datum, an dem diese Simulation planmäßig beginnen sollte',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartTime':'Uhrzeit, an dem diese Simulation planmäßig beginnen sollte',
    'Dashboard.Learners.ColumnTooltip.ScheduledEndTime':'Datum, an dem diese Simulation planmäßig enden sollte',
    'Dashboard.Learners.ColumnTooltip.SimulationStatus':'Endstatus für die Simulation',
    'Dashboard.Learners.ColumnTooltip.LearnerStatus':'Endgültiger Status der / des Lernenden für die Simulation',
    'Dashboard.Learners.ColumnTooltip.LearnerTimeSpent':'Minuten, die die / der Lernende in der Simulation verbracht hat.Für Zoom - und Meet - Lernende wird dies anhand des SIM - Spezialisten geschätzt, wenn sie abgeschlossen ist',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistName':'Beauftragter Simulationsspezialist, die Simulation bereitzustellen',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistStatus':'Endgültiger Status des SIM - Spezialisten für die Simulation',
    'Dashboard.Learners.ColumnTooltip.SimulationId':'Eindeutige Kennung der Simulation',
    'Dashboard.Learners.ColumnTooltip.Report':'Link zum Simulationsbericht, um(falls verfügbar) die Aufzeichnung und Analyse für die Simulation anzuzeigen',
    'Dashboard.SessionLearners.TableHeader':'Daten des Sitzungsteilnehmers',
    'MursionPortal.FilterHeading.Filter':'FILTER',
    'MursionPortal.FilterHeading.Graph':'DIAGRAMME',
    'MursionPortal.Table.NoRecordsMessage':'Keine Ergebnisse gefunden!',
    'MursionPortal.Filters.DeliveryMode':'Bereitstellungsmodus',
    'MursionPortal.Filters.PlaceHolder.DeliveryMode':'Bereitstellungsmodus suchen ...',
    'MursionPortal.Dashboard.Chart.Tooltip.Utilized':'Gesamtzahl, die für die Vertragsposition verwendet wurde.Beinhaltet abgeschlossene, verpasste oder(nach Ablauf der Frist) abgesagte / verlegte Simulationen.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Completed':'Erfolgreich abgeschlossene Simulationen',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LearnerMissed':'Simulationen, die die / der Lernende verpasst hat.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Error':'Simulationen, die auf der Seite der / des Lernenden mit einem Fehler endeten.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateCancellation':'Simulationen, die spät(nach Ablauf der Absagefrist) vom Lernenden, Moderator oder Kontoinhaber abgesagt wurden, oder Simulationen, die die / der Lernende verlassen oder abgelehnt hat',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.EarlyCancellation':'Simulationen, die vom Lernenden, Moderator oder Kontoinhaber frühzeitig(nach Ablauf der Absagefrist) abgesagt wurden',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Completed':'Abgeschlossen',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Error':'Fehler',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateCancellation':'Späte Absage',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EarlyCancellation':'Frühe Absage',
    'MursionPortal.Dashboard.Chart.PrivatePractice1':'30 m Privatpraxis',
    'MursionPortal.Dashboard.Chart.PrivatePractice2':'60 m Privatpraxis',
    'MursionPortal.Dashboard.Chart.Workshop1':'60 m Workshop',
    'MursionPortal.Dashboard.Chart.Workshop2':'90 m Workshop',
    'MursionPortal.Dashboard.Chart.StringType.Utilized':'Genutzt',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown':'Simulationsaufschlüsselung',
    'MursionPortal.SessionRecording':'Sitzungsaufzeichnung',
    'MursionPortal.VoiceAnalytics':'Stimmanalyse',
    'MursionPortal.VideoTab':'Video',
    'MursionPortal.SessionInformation':'Sitzungs - Info',
    'MursionPortal.SmartMatrix':'Intelligente Metrik',
    'MursionPortal.DeliveryDetails':'Bereitstellungsdetails',
    'MursionPortal.ConversationalFlow':'Gesprächsfluss',
    'MursionPortal.ConversationalFlowTooltip':'Dieses Diagramm zeigt Ihre Effizienz während eines Gesprächs.Es besteht aus abwechselnden Eingaben, die zeigen, wie gut Sie in der Lage sind, sich an einem konstruktiven Gespräch zu beteiligen.',
    'MursionPortal.BalancedTalkTime':'Gespräch',
    'MursionPortal.BalancedFeedback':'Ausgewogenes Feedback',
    'MursionPortal.ConversationalDisruptions':'Unterbrechung',
    'MursionPortal.DisruptionsFeedback':'Störungs - Feedback',
    'MursionPortal.Heading.SessionSmartMetrics':'Sitzungsstimmenanalyse: ',
    'Clients.Modal.Label.SmartMetrics':'Stimmanalyse: ',
    'Clients.Modal.Projects.Header.SessionSmartMetrics':'Sitzungsstimmenanalyse: ',
    'Clients.Modal.Projects.Checkbox.EnableSmartMetrics':' Stimmanalyse aktivieren',
    'Filters.Contract':'Vertrag',
    'Filters.Session': 'Sitzung',
    'Clients.Modal.Label.ShowSowReporting':'SOW - Berichterstattung anzeigen: ',
    'Clients.Modal.Label.ShowSurveyEnabled':'Teilnehmerbefragung nach der Simulation senden: ',
    'Dashboard.SimulationTable.Column.Provider':'Anbieter',
    'Dashboard.SimulationTable.Column.Client':'Kunde',
    'Dashboard.SimulationTable.Column.Project':'Projekt',
    'Dashboard.SimulationTable.Column.ScenarioName':'Szenarioname',
    'Dashboard.SimulationTable.Column.ScheduledStartDate':'Geplantes Startdatum',
    'Dashboard.SimulationTable.Column.ScheduledStartTime':'Geplante Startzeit',
    'Dashboard.SimulationTable.Column.ScheduledEndTime':'Geplante Endzeit',
    'Dashboard.SimulationTable.Column.LearnersNames':'Namen des / der Lernenden',
    'Dashboard.SimulationTable.Column.LearnersEmails':'Mailadressen des / der Lernenden',
    'Dashboard.SimulationTable.Column.LearnersStatus':'Status des / der Lernenden',
    'Dashboard.SimulationTable.Column.LearnersTimeSpent':'Zeitaufwand des / der Lernenden',
    'Dashboard.SimulationTable.Column.SimSpecialistName':'Name des Sim - Spezialisten',
    'Dashboard.SimulationTable.Column.SimSpecialistStatus':'Status des Sim - Spezialisten',
    'Dashboard.SimulationTable.Column.SimSpecialistTimeSpent':'Zeitaufwand des Sim - Spezialisten',
    'Dashboard.SimulationTable.Column.ScheduledBy':'Geplant von',
    'Dashboard.SimulationTable.Column.ScheduledAt':'Geplant um',
    'Dashboard.SimulationTable.Column.Recorded':'Aufgezeichnet',
    'Dashboard.SimulationTable.Column.ClientId':'Kunden - ID',
    'Dashboard.SimulationTable.Column.LearnerName':'Name der / des Lernenden',
    'Dashboard.SimulationTable.Column.LearnerName.ButtonHoverTitle':'Mailadresse des Lernenden kopieren',
    'Dashboard.SimulationTable.Column.LearnerEmail':'Mailadresse des Lernenden',
    'Dashboard.SimulationTable.Column.LearnerStatus':'Status der / des Lernenden',
    'Dashboard.SimulationTable.Column.LearnerTimeSpent':'Zeitaufwand des Lernenden',
    'Dashboard.SimulationTable.ColumnTooltip.Provider':'Name des Anbieters / Lizenznehmers',
    'Dashboard.SimulationTable.ColumnTooltip.Client':'Name des Kunden',
    'Dashboard.SimulationTable.ColumnTooltip.Project':'Projektname',
    'Dashboard.SimulationTable.ColumnTooltip.ScenarioName':'Name des Szenarios',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartDate':'Datum, an dem diese Simulation planmäßig beginnen sollte',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartTime':'Uhrzeit, an dem diese Simulation planmäßig beginnen sollte',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledEndTime':'Datum, an dem diese Simulation planmäßig enden sollte',
    'Dashboard.SimulationTable.ColumnTooltip.SimulationStatus':'Endstatus für die Simulation',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersNames':'Name(n) der für zur Simulation angemeldeten und nicht angemeldeten Lernenden',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersEmails':'Mailadresse(n) der für zur Simulation angemeldeten und nicht angemeldeten Lernenden',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersStatus':'Endgültiger Status der / des Lernenden für die Simulation',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersTimeSpent':'Zeit, die die Lernenden, die an der Simulation teilgenommen haben, mit der Simulation verbunden verbracht haben',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistName':'Name des Sim - Spezialisten',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistStatus':'Endgültiger Status des SIM - Spezialisten für die Simulation',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistTimeSpent':'Tatsächliche Länge dieser Simulation in Minuten',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledBy':'Name des Benutzers, der die Simulation geplant hat',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledAt':'Datum und Uhrzeit, zu der diese Simulation geplant war',
    'Dashboard.SimulationTable.ColumnTooltip.Recorded':'Gibt an, ob die Simulation aufgezeichnet wurde oder ob die Aufzeichnung nicht erlaubt war.',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerName':'Name(n) der für zur Simulation angemeldeten und nicht angemeldeten Lernenden',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerStatus':'Endgültiger Status der / des Lernenden für die Simulation',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerTimeSpent':'Minuten, die die / der Lernende in der Simulation verbracht hat.Für Zoom - und Meet - Lernende wird dies anhand des SIM - Spezialisten geschätzt, wenn sie abgeschlossen ist',
    'MursionPortal.Label.VideoTimeLineSlice':'Video - Zeitabschnitte',
    'MursionPortal.Label.SpeakingMoreThanSixty':'Sprechen ist mehr als 60 % ',
    'MursionPortal.Label.SpeakingBetweenSixty':'Sprechen ist zwischen 40 % und 60 % ',
    'MursionPortal.Label.InterruptionMoreThanSixty':'Unterbrechungen sind mehr als 60 % ',
    'MursionPortal.Label.InterruptionBetweenSixty':'Unterbrechungen sind zwischen 40 % und 60 % ',
    'MursionPortal.Label.SpeakingLessThanFourty':'Sprechen ist weniger als 40 % ',
    'MursionPortal.Label.InterruptionLessThanFourty':'Unterbrechungen sind weniger als 40 % ',
    'MursionPortal.Label.Interruption.ByAvatar':'Vom Avatar',
    'MursionPortal.Label.Interruption.ByLearner':'Vom Lernenden',
    'MursionPortal.Projects.NoContractsFound':'Keine Verträge gefunden.',
    'MursionPortal.Projects.ProjectContracts':'Projektverträge',
    'MursionPortal.Tooltip.AvatarInterrupt':'Der Avatar versucht den Sprecher erfolgreich zu unterbrechen.',
    'MursionPortal.Tooltip.LearnerInterrupt':'Der Lernende versucht, den Sprecher erfolgreich zu unterbrechen.',
    'MursionPortal.Tooltip.Pauses':'Anhalten.Länger als 0, 2 Sek.und kürzer als 0, 75 Sek.',
    'MursionPortal.Tooltip.Silence':'Sprachpause länger als 10 Sek.',
    'MursionPortal.Tooltip.AvatarSpeaking':'Der Avatar versucht mit Erfolg zu sprechen.',
    'MursionPortal.Tooltip.LearnerSpeaking':'Der Lernende versucht mit Erfolg zu sprechen.',
    // 'MursionPortal.label.LearnerInterrupts':'Der Lernende unterbricht',
    // 'MursionPortal.label.AvatarInterrupts':'Der Avatar unterbricht',
    'MursionPortal.label.Pause':'Pause',
    'MursionPortal.Label.Silence':'Stille',
    'MursionPortal.label.AvatarSpeaking':'Der Avatar spricht',
    'MursionPortal.label.LearnerSpeaking':'Der Lernende spricht',
    'MursionPortal.Contract.DuplicateLineItem':'Für diese Simulationslänge und diesen Bereitstellungsmodus ist bereits ein Posten vorhanden',
    'MursionPortal.Contract.DeleteLineItemIcon':'Postensymbol löschen',
    'SessionReports.Label.ShareVideoLink':'Videolink teilen',
    'MursionPortal.Title.Silence':'Sprachpause länger als 10 Sek.',
    'Login.EditFile': 'Bearbeiten Sie {file} und speichern sie ihn, um ihn neu zu laden.',
    'Login.Hello': 'Hallo',
    'Login.Title': 'Willkommen im Mursion-Portal',
    'Login.Description': 'Entwerfen, planen und verwalten Sie Ihre Virtual-Reality-Simulationen.',
    'Login.PrivacyPolicy': 'Datenschutzerklärung',
    'Login.PrivacyDataInfo': 'Datenschutzhinweise',
    'Login.TechnicalSupport': 'Technischer Support',
    'Login.SignIn': 'Anmelden',
    'Login.Next': 'Weiter',
    'Login.Email': 'E-Mail-Adresse',
    'Login.Password': 'Passwort',
    'Login.ForgotPassword': 'Passwort vergessen?',
    'Login.Login': 'Anmeldung',
    'Login.EmailHint': 'geben sie ihre E-Mail Adresse ein',
    'Login.PasswordHint': 'gib dein Passwort ein',
    'Login.NewPasswordHint': 'neues Passwort eingeben',
    'Login.RepeatPasswordHint': 'neues Passwort erneut eingeben',
    'Login.Image': 'Mursion-Logo',
    'Filters.Heading': 'FILTER:',
    'Dashboard.LeftPaneHeader': 'DASHBOARD',
    'Dashboard.LeftPane.CategoryHeader.Users': 'BENUTZER',
    'Dashboard.LeftPane.CategoryHeader.Projects': 'PROJEKTE',
    'Dashboard.LeftPane.Project.ProjectActivity': 'Projektaktivität',
    'Dashboard.LeftPane.Project.ProjectUtilization': 'Projektnutzung',
    'Dashboard.LeftPane.CategoryHeader.Sessions': 'SITZUNGEN',
    'Dashboard.LeftPane.Session.SessionLearner': 'Schulungsteilnehmer in der Sitzung',
    'Dashboard.LeftPane.CategoryHeader.Legacy': 'ERBE',
    'Dashboard.VideosTable.HoverTitle': ' ', // TODO: add translations
    'Dashboard.Button.ExportToEmailSentMsg': 'Der Bericht wird in Kürze an {email} gesendet.',
    'Dashboard.Button.ExportToEmail.HoverTitle': 'Daten exportieren',
    'Dashboard.Button.ExportToEmail.HoverTitleNotAvailable': 'Der Export ist nicht verfügbar',
    'Dashboard.Next': 'Weiter',
    'Dashboard.Last': 'Zuletzt',
    'Dashboard.Help': 'Hilfe',
    'Dashboard.First': 'Zuerst',
    'Dashboard.Back': 'Zurück',
    'FiltersHeading': 'FILTER:',
    'Filters.Client': 'Kunde',
    'Filters.ClientPlaceHolderHint': 'Kunden durchsuchen …',
    'Filters.Industries': 'Branchen',
    'Filters.IndustriesPlaceHolderHint': 'Branchen durchsuchen …',
    'Filters.IndustriesDisabledTitle': 'Deaktiviert',
    'Filters.Project': 'Projekt',
    'Filters.ProjectPlaceHolderHint': 'Projekte durchsuchen …',
    'Filters.ProjectDisabledTitle': 'Wählen Sie zuerst Kunden aus',
    'Filters.RoleDisabledTitle': 'Die Rollenauswahl ist deaktiviert',
    'Filters.Scenario': 'Szenario',
    'Filters.ScenarioPlaceHolderHint': 'Szenarien durchsuchen …',
    'Filters.ScenarioDisabledTitle': 'Wählen Sie zuerst Kunden und Projekte aus',
    'Filters.Team': 'Team',
    'Filters.TeamPlaceHolderHint': 'Teams durchsuchen …',
    'Filters.TeamDisabledTitle': 'Wählen Sie zuerst Kunden und Projekte aus',
    'Filters.SessionStatus': 'Sitzungsstatus',
    'Filters.SessionStatusPlaceHolderHint': 'Sitzungsstatus durchsuchen …',
    'Filters.RegistrationStatus': 'Registrierungsstatus',
    'Filters.RegistrationStatus.ShowAllRegistration': 'Registrierungsstatus: alle',
    'Filters.RegistrationStatus.ShowAcceptedRegistration': 'Registrierung akzeptiert',
    'Filters.RegistrationStatus.ShowNotAcceptedRegistration': 'Registrierung nicht akzeptiert',
    'Filters.Date.LastMonth': 'Letzter Monat',
    'Filters.Date.Yesterday': 'Gestern',
    'Filters.Date.Today': 'Heute',
    'Filters.Date.Tomorrow': 'Morgen',
    'Filters.Date.NextMonth': 'Nächster Monat',
    'Filters.Date.From': 'Von',
    'Filters.Date.To': 'bis',
    'Dashboard.UserActivityTable.HoverTitle': 'Benutzerzusammenfassung anzeigen',
    'Dashboard.Export.ErrMsg': 'Die maximale Anzahl an Einträgen für den Export beträgt {limit}. Bitte ändern Sie Ihre Anfrage entsprechend und versuchen Sie es erneut.',
    'MursionPortal.Close': 'Schließen',
    'Dashboard.UserTable.Column.Provider': 'Provider',
    'Dashboard.UserTable.Column.Client': 'Kunde',
    'Dashboard.UserTable.Column.ProfileId': 'Profil-ID',
    'Dashboard.UserTable.Column.UserEmail': 'E-Mail-Adresse des Benutzers',
    'Dashboard.UserTable.Column.UserEmail.ButtonHoverTitle': 'E-Mail kopieren',
    'Dashboard.UserTable.Column.SSO': 'SSO',
    'Dashboard.UserTable.Column.DateRegistered.ButtonTitle': 'Einladung erneut senden',
    'Dashboard.UserTable.Column.Scenarios': 'Szenarien',
    'Dashboard.UserTable.Column.Teams': 'Teams',
    'Dashboard.UserTable.Column.Industry': 'Branche',
    'Dashboard.UserTable.Column.Accepted': 'Akzeptiert',
    'Session.Tab.Scheduled': 'Geplant',
    'Session.Tab.Active': 'Aktiv',
    'Session.Tab.Past': 'Vergangen',
    'Session.Tab.Cancelled': 'Abgebrochen',
    'Session.Search': 'Suche',
    'Session.Table.Column.Time': 'Zeit',
    'Session.Table.Column.Client': 'Kunde',
    'Session.Table.Column.Project': 'Projekt',
    'Session.Table.Column.SoftwareVersion': 'Softwareversion',
    'Session.Table.Column.Scenario': 'Szenario',
    'Session.Table.Column.Trainees': 'Auszubildende',
    'Session.Table.Column.Training': 'Schulung',
    'Session.Table.Column.Session': 'Sitzung',
    'Session.Token.Label.Clients': 'Kunden',
    'Session.Token.Label.Projects': 'Projekte',
    'Session.Token.Label.Scenarios': 'Szenarien',
    'Session.Token.Label.Teams': 'Teams',
    'Session.Token.Label.SessionStatuses': 'Sitzungsstatus',
    'Session.Button.More': 'Mehr',
    'Session.Time.NotRunning': 'Wird noch nicht ausgeführt',
    'Session.Time.Finished': 'Bereits abgeschlossen',
    'Session.Time.Running': 'Wird derzeit ausgeführt',
    'Session.Table.Column.Learners': 'Schulungsteilnehmer',
    'Session.Table.Column.Team': 'Team',
    'Session.Table.Column.Trainer': 'Trainer',
    'Session.Table.Column.SimSpecialist': 'Simulationsspezialist',
    'Session.Table.Column.TimeUntil': 'Zeit bis',
    'Session.Table.Column.Created': 'Erstellt',
    'Session.Table.Column.Cancelled': 'Abgebrochen',
    'Session.Table.Caption.SessionDetailsList': 'Liste mit Sitzungsdetails',
    'Session.Export': 'Exportieren',
    'Session.Export.Message': 'Inhalt gefiltert nach folgenden Kriterien exportieren:',
    'Session.CalendarDate.Start': 'Von:',
    'Session.CalendarDate.End': 'Bis:',
    'Session.Cancel': 'Abbrechen',
    'Session.Confirm': 'Bestätigen',
    'Session.Total': 'GESAMT',
    'Session.Filter.SessionNotFoundMessage': 'Es wurden keine Sitzungen gefunden.',
    'Session.Filter.LearnerUpcomingSessionNotFoundMessage': 'Sie haben keine bevorstehenden Sitzungen geplant.',
    'Session.Filter.UpcomingSessionNotFoundMessage': 'Keine bevorstehenden Sitzungen geplant.',
    'Session.Filter.LearnerActiveSessionNotFoundMessage': 'Sie haben keine aktiven Sitzungen.',
    'Session.Filter.ActiveSessionNotFoundMessage': 'Keine aktiven Sitzungen.',
    'Session.Filter.LearnerCompletedSessionNotFoundMessage': 'Sie haben noch keine Sitzungen abgeschlossen.',
    'Session.Filter.SessionReportNotFoundMessage': 'Kein Sitzungsbericht verfügbar.',
    'Session.Filter.CompletedSessionNotFoundMessage': 'Es wurden keine Sitzungen abgeschlossen.',
    'Session.Filter.CancelledSessionNotFoundMessage': 'Es wurden keine Sitzungen storniert.',
    'Session.Edit.Modal.Table.Column.DateTime': 'Datum/Uhrzeit',
    'Session.Edit.Modal.Table.Column.UserRole': 'Benutzer (Rolle)',
    'Session.Edit.Modal.Table.Column.Change': 'Ändern',
    'Session.Edit.Modal.Table.Column.SessionStatus': 'Sitzungsstatus',
    'Session.Edit.Modal.Table.Column.Reason': 'Grund',
    'Session.Edit.Modal.AssetIndustry': 'Asset-Branche',
    'Session.Edit.Modal.SimulationEnvironment': 'Simulationsumgebung(en)',
    'Session.Edit.Modal.AvatarInSimulation': 'Avatar(e) in Simulation(en)',
    'Session.Edit.Modal.Date': 'Datum',
    'Session.Edit.Modal.Time': 'Zeit',
    'Session.Edit.Modal.DeliveryMode': 'Erbringungsmodus',
    'Session.Edit.Modal.Duration': 'Dauer',
    'Session.Edit.Modal.Status': 'Status',
    'Session.Edit.Modal.LearnersAttended': 'Anwesende Schulungsteilnehmer',
    'Session.Edit.Modal.ReasonForCancellation': 'Grund der Stornierung',
    'Session.Edit.Modal.MoreInfo': 'Weitere Informationen …',
    'Session.Edit.Modal.HideAdditionalInfo': 'Zusätzliche Informationen ausblenden …',
    'Session.Edit.Modal.AddToCalendar': 'Zum Kalender hinzufügen',
    'Session.Edit.Modal.AttendSession': 'An Sitzung teilnehmen',
    'Session.Edit.Modal.PleaseWait': 'Bitte warten …',
    'Session.Edit.Modal.CopySessionLink': 'Sitzungslink kopieren',
    'Session.Edit.Modal.SessionLink': 'Sitzungslink',
    'Session.Edit.Modal.SubmitReview': 'Bewertung senden',
    'Session.Edit.Modal.SessionRequestPending': 'Ausstehende Sitzungsanfrage',
    'Session.Edit.Modal.Back': 'Zurück',
    'Session.Edit.Modal.Learners': 'Schulungsteilnehmer:',
    'Session.Edit.Modal.Learners.UnauthenticatedCheckbox': 'Nur nicht authentifizierte Benutzer können beitreten',
    'Session.Edit.Modal.Trainees': 'Auszubildende:',
    'Session.Edit.Modal.Trainer': 'Trainer:',
    'Session.Edit.Modal.SimSpecialist': 'Simulationsspezialist:',
    'Session.Edit.Modal.Notes': 'Hinweise:',
    'Session.Edit.Modal.ClientNotes': 'Kundenhinweise:',
    'Session.Edit.Modal.Notes.EmptyMessage': 'Es sind keine Hinweise verfügbar',
    'Session.Edit.Modal.Attendance': 'Teilnahme:',
    'Session.Edit.Modal.ShowHistory': 'Verlauf anzeigen …',
    'Session.Edit.Modal.HideHistory': 'Verlauf ausblenden …',
    'Session.Edit.Modal.LearnersAttendedLabel': 'Anwesende Schulungsteilnehmer:',
    'Session.Edit.Modal.SimulationContent': 'Simulationsinhalt',
    'Session.Edit.Modal.SimulationContent.Name': 'Name:',
    'Session.Edit.Modal.SimulationContent.Description': 'Beschreibung:',
    'Session.Edit.Modal.SimulationContent.Image': 'Abbildung:',
    'Session.Edit.Modal.RestoreSession.ConfirmationText': 'Möchten Sie diese Sitzung wirklich wiederherstellen?',
    'Session.Edit.Modal.CancelTraining.ConfirmationText': 'Möchten Sie diese Schulung wirklich abbrechen?',
    'Session.Edit.Modal.CancelSession.ConfirmationText': 'Möchten Sie diese Sitzung wirklich abbrechen?',
    'Session.Edit.Modal.SessionSwap.ConfirmationText': 'Möchten Sie diese Sitzung wirklich tauschen?',
    'Session.Edit.Modal.AcceptRequest.ConfirmationText': 'Möchten Sie diese Sitzungsanfrage wirklich annehmen?',
    'Session.Edit.Modal.AssignSS.ConfirmationText': 'Möchten Sie wirklich einen neuen Simulationsspezialisten zuweisen?',
    'Session.Edit.Modal.ChangeSS.ConfirmationText': 'Möchten Sie den Simulationsspezialisten wirklich wechseln?',
    'Session.Edit.Modal.Button.RestoreSession': 'Sitzung wiederherstellen',
    'Session.Edit.Modal.Button.CancelTraining': 'Schulung abbrechen',
    'Session.Edit.Modal.Button.CancelSession': 'Sitzung abbrechen',
    'Session.Edit.Modal.Button.AcceptSwap': 'Tausch annehmen',
    'Session.Edit.Modal.Button.Participate': 'Teilnehmen',
    'Session.Edit.Modal.Button.Swap': 'Tauschen',
    'Session.Edit.Modal.Button.UpdateSession': 'Sitzung aktualisieren',
    'Session.Edit.Modal.Button.UpdateTraining': 'Schulung aktualisieren',
    'Session.Edit.Modal.Button.AcceptRequest': 'Anfrage annehmen',
    'Session.Edit.Modal.Button.AssignSS': 'Simulationsspezialist zuweisen',
    'Session.Edit.Modal.Button.ChangeSS': 'Simulationsspezialist ändern',
    'Session.Attendance.Table.Column.UserName': 'Benutzername',
    'Session.Attendance.Table.Column.Time': 'Zeit',
    'Session.Attendance.Table.Column.Status': 'Status',
    'Session.ViewSessionReport': 'Sitzungsbericht ansehen',
    'Session.ViewEdit': 'Sitzung anzeigen/bearbeiten',
    'Session.Edit.Modal.Table.HistoryNotFoundMessage': 'Es liegt noch kein Verlauf vor',
    'Session.Export.ErrMsg': 'Die maximale Anzahl an Einträgen für den Export beträgt {limit}. Bitte ändern Sie Ihre Anfrage entsprechend und versuchen Sie es erneut.',
    'Session.Table.Column.Action': 'Aktion',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoTrainers': 'Keine Trainer zur Auswahl',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoSimSpecialists': 'Keine Simulationsspezialisten zur Auswahl',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoTrainers': 'Keine Trainer ausgewählt',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoSimSpecialists': 'Keine Simulationsspezialisten ausgewählt',
    'Session.Edit.Modal.SessionSimSpecialistField.EditIconLabel': 'bearbeiten',
    'Session.Edit.Modal.SessionSimSpecialistField.Search.Placeholder': 'Suche',
    'Session.Edit.Modal.SessionSimSpecialist.Button.Accept': 'Annehmen',
    'ConfirmationDialogue.AddReasonForChange': 'Bitte geben Sie einen Grund für die Änderung an',
    'ConfirmationDialogue.Button.Submit': 'Senden',
    'ConfirmationDialogue.Button.Cancel': 'Abbrechen',
    'ConfirmationDialogue.Button.Ok': 'OK',
    'ConfirmationDialogue.ReasonForChange': 'Änderungsgrund',
    'ConfirmButton.CancelSession.ConfirmCancellation': 'Stornierung bestätigen',
    'ConfirmButton.CancelSession': 'Sitzung abbrechen',
    'ConfirmButton.CancelSession.No': 'Nein',
    'Pagination.Button.First': 'Zuerst',
    'Pagination.Button.Back': 'Zurück',
    'MursionPortal.UserName': 'Benutzername',
    'MursionPortal.Email': 'E-Mail-Adresse',
    'ClientUserTable.Column.Roles': 'Rollen',
    'MursionPortal.Department': 'Abteilung',
    'MursionPortal.Location': 'Standort',
    'ClientUserTable.Column.Projects': 'Projekte',
    'ClientUserTable.Column.Teams': 'Teams',
    'ClientUserTable.Column.Accepted': 'Akzeptiert',
    'ClientUserTable.HoverTitle': 'Benutzer anzeigen/bearbeiten',
    'ClientUserTable.NoUserError': 'Es sind noch keine Benutzer vorhanden',
    'MursionPortal.FirstName': 'Vorname',
    'MursionPortal.LastName': 'Nachname',
    'MursionPortal.Phone': 'Telefonnummer',
    'Users.TableModal.EmailPlaceHolder': 'beispiel@domain.com',
    'Users.TableModal.Title': 'Titel',
    'Users.TableModal.LocationPlaceHolder': 'Stadt, Land',
    'Users.TableModal.DepartmentPlaceHolder': 'Name der Abteilung',
    'Users.TableModal.TitlePlaceHolder': 'Tätigkeitsbezeichnung',
    'Users.Table.Modal.SettingsHeader': 'Einstellungen für Moderatoren/Schulungsteilnehmer',
    'Users.Table.ModalHeader': 'Benutzerinformationen',
    'Users.Table.ModalButton.Cancel': 'Abbrechen',
    'Users.Table.ModalButton.Delete': 'Löschen',
    'Users.Table.ModalButton.Save': 'Speichern',
    'Users.Table.ModalButton.SendInvite': 'Einladung senden',
    'Users.Table.ModalButton.Restore': 'Wiederherstellen',
    'Users.Table.Modal.Teams.Text': 'Es wurden noch keine Teams hinzugefügt',
    'Users.Table.Modal.Teams': 'Teams',
    'Users.Table.Modal.EmployeeIdentification': 'Mitarbeiter-Identifikation',
    'Users.Table.Modal.Timezone': 'Zeitzone',
    'Users.Table.Modal.Roles': 'Rollen',
    'Users.Table.Modal.Settings.LearnerButton': 'Als Schulungsteilnehmer',
    'Users.Table.Modal.Settings.FacilitatorButton': 'Als Moderator',
    'Users.Button.CreateUserButton': 'Benutzer erstellen ',
    'Users.Button.Cancel': 'Abbrechen',
    'Users.Button.ImportUsers': 'Benutzer importieren',
    'Users.Button.Export': 'Exportieren',
    'Users.heading.ExportContentFilteredBy': 'Inhalt gefiltert nach folgenden Kriterien exportieren:',
    'Users.Button.Active': 'AKTIV',
    'Users.Button.Archived': 'ARCHIVIERT',
    'Users.Button.All': 'ALLE',
    'Users.Text.Total': 'GESAMT',
    'Users.Heading.NewUsers': 'Neue Benutzer',
    'Users.Placeholder': 'beispiel@domain.com',
    'Users.Button.AddUser': 'Benutzer hinzufügen ',
    'Users.Button.CreateUser': 'Benutzer erstellen',
    'Users.Button.CreateUsers': 'Benutzer erstellen',
    'Users.Heading.ImportNewUsers': 'Neue Benutzer importieren',
    'Users.Heading.ImportNewExternalUsers': 'Neue externe Benutzer importieren',
    'Users.Button.ImportUserList': 'Benutzerliste importieren',
    'Users.Text.InstructionMessage.UsersWithoutPredefined': 'Benutzer ohne vordefinierte Teams werden zugewiesen an',
    'Users.Text.AddCsv': 'Fügen Sie eine .csv- oder .xslx-Datei hinzu, die eine Liste von E-Mail-Adressen in einer Spalte enthält.',
    'Users.Text.ToImport': 'Folgen Sie diesen Richtlinien, um weitere Felder zu importieren:',
    'Users.Text.ListUser': 'Die E-Mail-Adressen der Benutzer müssen durch Komma, Strichpunkt, Verkettungszeichen oder Doppelpunkt von der Liste der Benutzerteams getrennt sein.',
    'Users.Text.ListTeams': 'Teams müssen durch ein Komma voneinander getrennt sein.',
    'Users.Text.ListFields': 'Felder und die Teams müssen in separaten Zellen angegeben werden.',
    'Users.Text.UserRole': 'Der Standard-Benutzerrollentyp ist Schulungsteilnehmer',
    'Users.Text.UnlessOtherwiseIndicated': ' wenn nicht anders angegeben',
    'Users.Text.SampleImport': 'Hier können Sie eine Beispiel-Importdatei herunterladen:',
    'Users.DownloadCsvTemplate': 'CSV-Vorlage herunterladen',
    'Users.DownloadExcelTemplate': 'Excel-Vorlage herunterladen',
    'Users.Button.Update': 'Aktualisieren',
    'Users.Labels.Clients': 'Kunden',
    'Users.Labels.Projects': 'Projekte',
    'Users.Labels.Scenarios': 'Szenarien',
    'Users.Labels.Teams': 'Teams',
    'Users.Labels.Roles': 'Rollen',
    'Users.Text.OnlyAccepted': 'Nur akzeptiert:',
    'Users.Button.Confirm': 'Bestätigen',
    'Users.Button.AccOwner': 'Benutzer akzeptieren',
    'Users.Button.Facilitator': 'Moderator',
    'Users.Button.Learner': 'Schulungsteilnehmer',
    'Users.Button.ProfServices': 'Profi-Services',
    'Users.Button.ProjectManager': 'Projektmanager',
    'Users.Button.SimSpecialist': 'Simulationsspezialist',
    'Users.Button.ITManager': 'IT-Manager',
    'Users.Button.BusinessAdmin': 'Betriebswirtschaftlicher Administrator',
    'Users.Button.SupportAdmin': 'Support-Administrator',
    'Users.Button.DevAdmin': 'Entwicklungsadministrator',
    'Users.Button.ImportExternalUsers': 'Externe Benutzer importieren',
    'Users.Button.AssignLearners': 'Schulungsteilnehmer zuweisen',
    'Users.CreateUser.Email.Placeholder': 'beispiel@domain.com',
    'Users.Text.Instructions': ' Fügen Sie eine .csv- oder .xslx-Datei hinzu, die eine Liste der Benutzer mit folgenden Feldern enthält: E-Mail-Adresse: SSID, Vorname, Nachname, Rolle, Team. Alle Felder mit Ausnahme des Teams sind Pflichtfelder.',
    'Users.Text.Guidelines': 'Bitte beachten Sie folgenden Richtlinien:',
    'Users.Button.CreateUser.PencilIcon': 'Benutzerdaten bearbeiten',
    'Users.Button.CreateUser.TrashIcon': 'Benutzer entfernen',
    'Users.ExportButton.TextYes': 'Ja',
    'Users.ExportButton.TextNo': 'Nein',
    'Users.Button.OK': 'OK',
    'Users.Button.AddProject': 'Projekt hinzufügen',
    'Users.CreateUser.Label.AddToProjects': 'Zu Projekt(en) hinzufügen',
    'Users.UserInfo.Label.AddTeams': 'Teams hinzufügen',
    'Users.UserInfo.Label. SelectATeam': ' Team auswählen',
    'Users.UserInfo.Label.AssignToTeam': 'Folgendem Team zuweisen:',
    'Users.CreateUser.Label.NoProjectsSpecified': 'Keine Projekte angegeben',
    'Users.Option.SelectClient': 'Kunde auswählen',
    'Users.Label.Client': 'Kunde:',
    'Users.Label.RemoveTeam': 'Team entfernen',
    'Users.Label.ChooseATeam': 'Team auswählen',
    'Users.Project.Warning': 'Dieser Kunde hat keine Projekte. Bitte wählen Sie einen anderen aus.',
    'Users.Project.Warning.UnknownProject': 'Unbekanntes Projekt',
    'Users.ProjectContainer.Warning': 'Es sollte mindestens ein Projekt hinzugefügt werden',
    'Users.CompanyUsers.Button.Ok': 'OK',
    'Users.CompanyUsers.Button.Accept': 'Annehmen',
    'Users.CreateUser.Label.Tier': 'Stufe',
    'Users.CreateUser.Label.HireDate': 'Einstellungsdatum',
    'Users.CreateUser.Label.StationType': 'Stationstyp',
    'Users.CreateUser.ToggleButton.Home': 'Zuhause',
    'Users.CreateUser.ToggleButton.Office': 'Büro',
    'Users.CreateUser.Label.Hours': 'Stunden',
    'Users.CreateUser.ToggleButton.FullTime': 'Vollzeit',
    'Users.CreateUser.ToggleButton.PartTime': 'Teilzeit',
    'Users.CreateUser.Label.ZoomID': 'Zoom-ID',
    'Users.CreateUser.ZoomID.Placeholder': 'Zoom-ID',
    'Users.CreateUser.ZoomID.Hover': 'Bitte geben Sie eine Zoom-ID an',
    'Users.CreateUser.Label.UserRoles': 'Benutzerrolle(n)',
    'Users.CreateUser.Label.ProjectManagerSettings': 'Projektmanager-Einstellungen',
    'Users.CreateUser.Label.SimSpecialistSettings': 'Simulationsspezialist-Einstellungen',
    'Users.CreateUser.Label.Certificates': 'Zertifikate',
    'Users.CreateUser.Label.TurnOnEditCertificates': '„Zertifikate bearbeiten“ aktivieren',
    'Users.CreateUser.Label.TurnOffEditCertificates': '„Zertifikate bearbeiten“ deaktivieren',
    'Users.CreateUser.Label.NoCertificatesHaveBeenAddedYet': 'Es wurden noch keine Zertifikate hinzugefügt',
    'Users.CreateUser.Label.AddCertificate': 'Zertifikat hinzufügen',
    'Users.CreateUser.Label.CertificateOwner': 'Zertifikatsinhaber',
    'Users.CreateUser.Button.RemoveCertificate': 'Zertifikat entfernen',
    'Users.TableHeading.Name': 'Name',
    'Users.TableHeading.Email': 'E-Mail-Adresse',
    'Users.TableHeading.Roles': 'Rollen',
    'Users.TableHeading.Location': 'Standort',
    'Users.TableHeading.Accepted': 'Akzeptiert',
    'Users.Table.Hover.ViewEditUser': 'Benutzer anzeigen/bearbeiten',
    'Users.Table.Hover.NoUsersYet': 'Es sind noch keine Benutzer vorhanden',
    'Users.CreateUser.Button.Client': 'Kunde',
    'Users.CreateUser.Label.ChooseAClient': 'Wählen Sie einen Kunden aus',
    'Users.CreateUser.Label.SelectAClient': 'Wählen Sie einen Kunden aus',
    'Users.CreateUser.Label.ChooseACertificate': 'Wählen Sie ein Zertifikat aus',
    'Users.CreateUser.Label.SelectACertificate': 'Wählen Sie ein Zertifikat aus',
    'Users.CreateUser.Label.NoCertificatesToSelect': 'Keine Zertifikate zur Auswahl',
    'Users.CreateUser.Button.AddCertificate': 'Zertifikat hinzufügen ',
    'Users.CreateUser.PlaceHolder.SelectTier': 'Level auswählen',
    'Users.RequiredField.Warning': 'Bitte überprüfen Sie, ob alle Felder korrekt ausgefüllt wurden',
    'Users.RequiredField.ApplyChanges': 'Änderungen übernehmen',
    'Users.Heading.NewUserInfo': 'Neue Benutzerinformationen',
    'Users.SearchPlaceHolderText': 'Benutzername eingeben',
    'Users.SearchNoUserFoundError': 'Es wurde keine Kunden-Benutzer gefunden.',
    'Users.Table.Modal.Settings.AddTeamHeader': 'Teams hinzufügen',
    'Users.Table.Modal.Settings.ChooseTeamHeader': 'Team auswählen',
    'Users.Table.Modal.Settings.AssignTeamHeader': 'Folgendem Team zuweisen:',
    'Users.Table.Modal.Settings.SelectTeamOptionHeader': 'Wählen Sie ein Team aus',
    'Users.Table.Modal.Settings.TeamLearner': '(Schulungsteilnehmer)',
    'Users.Table.Modal.Settings.TeamFacilitator': '(Moderator)',
    'Users.Table.Modal.Settings.RemoveTeamOption': 'Team entfernen',
    'Users.Table.Modal.Settings.TurnOnEditTeamsOption': '„Teams bearbeiten“ aktivieren',
    'Users.Table.Modal.Settings.TurnOffEditTeamsOption': '„Teams bearbeiten“ deaktivieren',
    'Users.Total': 'GESAMT',
    'Users.Table.Modal.ArchiveHeader.ConfirmArchiveUser': '„Benutzer archivieren“ bestätigen',
    'Users.Table.Modal.ArchiveText': 'Möchten Sie diesen Benutzer wirklich archivieren?',
    'Users.Table.Modal.ArchiveUserButton': 'Benutzer archivieren',
    'Users.Table.Modal.RestoreHeader.ConfirmRestoreUser': '„Benutzer wiederherstellen“ bestätigen',
    'Users.Table.Modal.RestoreText': 'Möchten Sie diesen Benutzer wirklich wiederherstellen?',
    'Users.Table.Modal.RestoreUserButton': 'Benutzer wiederherstellen',
    'Users.Table.Modal.CancelButton': 'Abbrechen',
    'Users.Table.Modal.ArchiveButton': 'Archivieren',
    'Users.Table.Modal.RestoreButton': 'Wiederherstellen',
    'Users.Table.Modal.SendInviteButton': 'Einladung senden',
    'Users.CreateUserButton.Modal.CreateUserButton': 'Benutzer erstellen',
    'Users.Table.Modal.UpdateUserButton': 'Benutzer aktualisieren',
    'Users.ViewUserOptions': 'Benutzer von {companyName} ansehen',
    'Users.NewUserOptions': 'Neuer Benutzer von {companyName}',
    'Users.EditUserOptions': 'Benutzer von {companyName} bearbeiten',
    'Reports.SessionListHeader.Completed': 'Abgeschlossen',
    'Reports.SessionListHeader.TotalSessionTime': ' Gesamte Sitzungszeit,',
    'Reports.SessionListHeader.TotalActualTime': ' Gesamte Ist-Zeit,',
    'Reports.SessionListHeader.SimulationCompletionTime': 'Zeit bis zum Abschluss der Simulation',
    'Reports.SessionListHeader.CantOpenReport': 'Bitte warten Sie, bis unsere Analyse-Engine Ihre Ergebnisse verarbeitet hat. Gegebenenfalls müssen Sie diese Seite nach einigen Minuten aktualisieren.',
    'Reports.SessionListHeader.NoReportsFound': 'Keine Berichte gefunden.',
    'Reports.ReportCard.Button.ReviewSimulation': 'SIMULATION ERNEUT ANSEHEN',
    'Reports.ReportCard.Strategies': 'Strategien',
    'Reports.ReportCard.NotChecked': 'Nicht überprüft',
    'Dashboard.SchedulingInfoTable.Modal.SortBy': 'Sortieren nach:',
    'Dashboard.SchedulingInfoTable.Modal.NoItemsFound': 'Es wurden keine Elemente gefunden.',
    'Dashboard.SchedulingInfoTable.Modal.Teams': 'Teams',
    'Dashboard.SchedulingInfoTable.Modal.Teams.Placeholder': 'Teamnamen eingeben',
    'Dashboard.SchedulingInfoTable.Modal.Teams.EmptyMessage': 'Es wurden keine Teams gefunden',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.TeamName': 'Teamname:',
    'Dashboard.SchedulingInfoTable.Modal.Learners': 'Schulungsteilnehmer:',
    'Dashboard.SchedulingInfoTable.Modal.Projects': 'Projekte:',
    'Dashboard.SchedulingInfo.Filter.SearchIndustries.PlaceholderHint': 'Branchen durchsuchen …',
    'Dashboard.SchedulingInfo.Filter.IndustryDisabledTitle': 'Deaktiviert',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion.PlaceholderHint': 'Verwendete Softwareversionen …',
    'Dashboard.SchedulingInfoTable.Modal.Total': 'GESAMT',
    'Dashboard.SchedulingInfoTable.Modal.ScenarioName': 'Name des Szenarios',
    'Dashboard.SchedulingInfoTable.Modal.SessionDate': 'Sitzungsdatum',
    'Dashboard.SchedulingInfoTable.Modal.ProjectName': 'Projektbezeichnung',
    'Dashboard.SchedulingInfoTable.Modal.SoftwareVersion': 'Softwareversion',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.DeliveryMode': 'Erbringungsmodus',
    'Dashboard.SchedulingInfoTable.Modal.SessionStatus': 'Sitzungsstatus',
    'Dashboard.SchedulingInfoTable.Modal.Name': 'Name',
    'Dashboard.SchedulingInfoTable.Modal.Status': 'Status',
    'Dashboard.SchedulingInfoTable.Modal.Recording': 'Aufnahmen gehen an',
    'Dashboard.SchedulingInfoTable.Modal.TeamName': 'Teamname',
    'Dashboard.SchedulingInfoTable.Modal.Learner': 'Schulungsteilnehmer',
    'Dashboard.SchedulingInfoTable.Modal.CompletedSessions': 'Abgeschlossene Sitzungen',
    'Dashboard.SchedulingInfoTable.Modal.DeliveryMode': 'Erbringungsmodus',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.ProjectName': 'Projektbezeichnung',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.SoftwareVersion': 'Softwareversion',
    'Dashboard.SchedulingInfoTable.Modal.Timeline': 'Zeitachse',
    'Dashboard.SchedulingInfoTable.Modal.SchedulesSessions': 'Plant Sitzungen',
    'Dashboard.SchedulingInfoTable.Modal.MissedSessions': 'Gemischte Sitzungen',
    'Dashboard.SchedulingInfoTable.Modal.LateCancellations': '\„Verspätete\“ Stornierungen',
    'Dashboard.SchedulingInfoTable.Modal.TimeSpent': 'Zeitaufwand',
    'Dashboard.SchedulingInfoTable.Modal.CancelledBy': 'Storniert von',
    'Dashboard.SchedulingInfoTable.Modal.CancellationReason': 'Stornierungsgrund',
    'Dashboard.SchedulingInfoTable.Modal.DateScheduled': 'Geplant am',
    'Dashboard.SchedulingInfoTable.Modal.DateCancelled': 'Storniert am',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Teams': 'Teams',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterTeamName': 'Teamnamen eingeben',
    'Dashboard.SchedulingInfoTable.Modal.TeamsEmptyMessage': 'Es wurden keine Teams gefunden',
    'Dashboard.SchedulingInfoTable.Modal.Filter.TeamName': 'Teambezeichnung',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Scenarios': 'Szenarien',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterScenarioName': 'Bezeichnung des Szenarios eingeben',
    'Dashboard.SchedulingInfoTable.Modal.ScenariosEmptyMessage': 'Es wurden keine Szenarien gefunden',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioName': 'Bezeichnung des Szenarios',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ProjectName': 'Projektbezeichnung',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SoftwareVersion': 'Softwareversion',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioStatus': 'Szenario-Status',
    'Dashboard.SchedulingInfoTable.Modal.Filter.DeliveryMode': 'Erbringungsmodus',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsScheduled': 'Geplante Sitzungen',
    'Dashboard.SchedulingInfoTable.Modal.SessionsEmptyMessage': 'Es wurden keine Sitzungen gefunden',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsDateTime': 'Datum/Uhrzeit der Sitzung',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsCompleted': 'Abgeschlossene Sitzungen',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsMissed': 'Verpasste Sitzungen',
    'Dashboard.SchedulingInfoTable.Modal.Filter.LateCancellations': '„Verspätete“ Stornierungen',
    'Dashboard.SchedulingInfoTable.Modal.Filter.CancelledBy': 'Storniert von',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsError': 'Sitzungsfehler',
    'RestorePassword.EnterEmailAddress': 'Geben Sie Ihre E-Mail-Adresse ein und klicken Sie auf die Schaltfläche „Wiederherstellen“. Sie erhalten dann eine E-Mail mit einem Link, über den Sie Ihr Passwort zurücksetzen können.',
    'RestorePassword.Email': 'E-Mail-Adresse',
    'RestorePassword.Button.Cancel': 'Abbrechen',
    'RestorePassword.Button.Restore': 'Wiederherstellen',
    'RestorePassword.Modal.IfValidEmailAddressEntered': 'Wenn Sie eine gültige E-Mail-Adresse eingegeben haben, wurde eine E-Mail mit einer Anleitung zum Zurücksetzen an die jeweilige Adresse gesendet. Wenden Sie sich an den technischen Support, wenn das Problem weiterhin besteht.',
    'RestorePassword.Modal.Button.GotIt': 'OK',
    'Trainings.Heading.AllTrainingSessions': 'Alle Schulungssitzungen',
    'Requests.Table.Swap': 'SWAP',
    'Requests.Table.SchedulingRequests': 'Planungsanfragen',
    'Requests.Total': 'GESAMT',
    'Requests.Table.NoSessionsFound': 'Es wurden keine Sitzungen gefunden.',
    'Requests.Table.NoUpcomingSwapSessions': 'Es gibt keine bevorstehenden SWAP-Sitzungen.',
    'Requests.Table.NoUpcomingRequests': 'Sie haben keine anstehenden Anfragen.',
    'GetTimeToEvents.Day': 'Tag',
    'GetTimeToEvents.Days': 'Tage',
    'GetTimeToEvents.Minute': 'Minute',
    'GetTimeToEvents.Minutes': 'Minuten',
    'GetTimeToEvents.Hour': 'Stunde',
    'GetTimeToEvents.Hours': 'Stunden',
    'GetTimeToEvents.LessThanOneMinute': 'Weniger als 1 Minute',
    'SessionReports.SessionInformation': 'Sitzungsinformationen',
    'SessionReports.SessionInformation.Hover.Information': 'Informationen',
    'SessionReports.SmartMetrics': 'Intelligente Metriken',
    'SessionReports.SendVideoLink.Modal.ShareLinkToVideo': 'Link zum Video teilen',
    'SessionReports.Button.BackToReports': 'Zurück zu den Berichten',
    'SessionReports.Button.BackToTrainings': 'Zurück zu den Schulungen',
    'SessionReports.Button.HideInfo': 'Informationen ausblenden',
    'SessionReports.Button.ShowInfo': 'Informationen anzeigen',
    'SessionReports.Button.SendVideoLink': 'Videolink senden',
    'SessionReports.TryAgain': 'Erneut versuchen',
    'SessionReports.Mode': 'Modus:',
    'SessionReports.SessionLength': 'Dauer der Sitzung:',
    'SessionReports.LearnersAttended': 'Anwesende Schulungsteilnehmer:',
    'SessionReports.ScenarioTimeline': 'Szenario-Zeitleiste:',
    'SessionReports.ActualSessionTime': 'Tatsächliche Sitzungszeit:',
    'SessionReports.ActualSimulationTime': 'Tatsächliche Simulationszeit:',
    'SessionReports.LastUserStatus': 'Letzter Benutzerstatus:',
    'SessionReports.SessionStatus': 'Sitzungsstatus:',
    'SessionReports.Delivery': 'Bereitstellung',
    'SessionReports.SendVideoLink.Modal.AddEmails': 'E-Mail-Adressen hinzufügen',
    'SessionReports.SendVideoLink.Modal.Button.AddEmail': 'E-Mail-Adresse hinzufügen ',
    'SessionReports.SendVideoLink.Modal.EmailPlaceHolder': 'beispiel@domain.com',
    'SessionReports.SendVideoLink.Modal.Button.Cancel': 'Abbrechen',
    'SessionReports.SendVideoLink.Modal.Button.Send': 'Senden',
    'SessionReports.FactSessionInfo': 'Sitzungsinfo',
    'SessionReports.Text.NoTraineeParticipatedInSession': 'Kein Auszubildender hat an dieser Sitzung teilgenommen',
    'SessionReports.Text.NoLearnerParticipatedInSession': 'Kein Schulungsteilnehmer hat an dieser Sitzung teilgenommen',
    'SessionReports.ToolTip.TrackingYours': 'Es ist von größter Wichtigkeit, dass Sie ein Ergebnis oder ein Strategieelement in ähnlicher Weise wahrnehmen wie der Avatar bzw. die Avatare. Dies lässt darauf schließen, dass Sie Ihre Auswirkung auf den Avatar bzw. die Avatare verfolgen und so die soziale Effektivität verbessern. Diese Informationen finden Sie in der folgenden Tabelle.',
    'SessionReports.ToolTip.TrackingLearners': 'Es ist von größter Wichtigkeit, dass der Lerner ein Ergebnis oder ein Strategieelement in ähnlicher Weise wahrnimmt wie der Avatar bzw. die Avatare. Dies lässt darauf schließen, dass der Schulungsteilnehmer seine Auswirkung auf die Avatare verfolgt und so die soziale Effektivität verbessert. Diese Informationen finden Sie in der folgenden Tabelle.',
    'SessionReports.ToolTip.AvatarsHeaderYours': 'Diese Spalte zeigt, wie der Avatar bzw. die Avatare Ihre Simulationsleistung wahrnehmen. Ein Häkchen zeigt an, dass die Avatare Sie beim Erreichen des Ergebnisses als erfolgreich empfinden, während ein Kreuz Verbesserungspotenzial verdeutlicht.',
    'SessionReports.ToolTip.AvatarsHeaderLearners': 'Diese Spalte zeigt, wie der Avatar bzw. die Avatare die Simulationsleistung des Schulungsteilnehmers wahrnehmen. Ein Häkchen zeigt an, dass die Avatare den Schulungsteilnehmer beim Erreichen des Ergebnisses als erfolgreich empfinden, während ein Kreuz Verbesserungspotenzial verdeutlicht.',
    'SessionReports.ToolTip.SelfHeaderYours': 'Diese Spalte zeigt Ihre eigene Einschätzung Ihrer Simulationsleistung. Ein Häkchen zeigt an, dass Sie sich als erfolgreich einschätzen, während ein Kreuz Verbesserungspotenzial verdeutlicht.',
    'SessionReports.ToolTip.SelfHeaderLearners': 'Diese Spalte zeigt die Einschätzung des Schulungsteilnehmers seiner eigenen Simulationsleistung. Ein Häkchen zeigt an, dass sich der Schulungsteilnehmer als erfolgreich einschätzt, während ein Kreuz Verbesserungspotenzial verdeutlicht.',
    'SessionReports.ToolTip.VideoLink': 'Sie können Ihren Kollegen einen Link zum Video Ihrer Simulationsleistung senden. Geben Sie dazu einfach deren E-Mail-Adressen in das Popup-Fenster ein. Bitte beachten Sie, dass Ihre Analysen nicht an Ihre Kollegen weitergegeben werden.',
    'SessionReports.ToolTip.SmartMetrics': 'Intelligente Metriken werden mithilfe umfassender Algorithmen generiert. Beobachten Sie diese computergenerierten Maßnahmen, um festzustellen, ob sich diese auf Ihre Gesamtverfolgung während verschiedener Simulationen auswirken.',
    'SessionReports.ToolTip.MiScore': 'Der Mursion-Index (MI) gibt Ihre soziale Effektivität in diesem Gespräch an. Er wird aus verschiedenen Daten wie etwa Ihrem Gesprächsmuster und dessen Einfluss auf den Avatar (die Avatare) errechnet. Versuchen Sie, Ihre Auswirkungen auf den Avatar bzw. die Avatare zu bewerten und Gespräche auf unterschiedliche Weise zu führen, um zu sehen, wie sich Ihr MI-Score positiv verändern kann.',
    'SessionReports.ToolTip.ListeningSpeakingTime': 'Dieser Indikator zeigt das Verhältnis zwischen der Zeit, die Sie in diesem Gespräch sprechend verbringen, zur Zeit an, die Sie zuhörend verbringen.',
    'SessionReports.ToolTip.Emotions': 'Emotionen (Beta): Wir arbeiten derzeit an Algorithmen, die Ihre Stimmmuster interpretieren und so die allgemeine Stimmung abschätzen können. Beobachten Sie diese Daten, um festzustellen, ob Ihr Stimmmuster eine positive, negative oder neutrale Konnotation zeigt. Beobachten Sie vor allem, wie Sie sich auf die Stimmmuster des Avatars bzw. der Avatare auswirken.',
    'SessionReports.ToolTip.TurnTracking': 'Der Faktor „Abwechselndes Gespräch“ bezeichnet eine Reihe von Messungen, die Einblicke in das Zusammenspiel und die soziale Effizienz zwischen Ihnen und den Avataren ermöglichen.',
    'SessionReports.ToolTip.Pauses': 'Pausen sind ein indirektes Maß für Ihren Redefluss während eines Gesprächs.',
    'SessionReports.ToolTip.ArticulationRate': 'Dies ist ein Maß für das Tempo, in dem Sie fortlaufend Informationen erbringen.',
    'SessionReports.TableHeader.Outcome': 'Ergebnis',
    'SessionReports.TableHeader.Self': 'Selbst',
    'SessionReports.TableHeader.Avatars': 'Avatar(e)',
    'SessionReports.TableHeader.Strategies': 'Strategien',
    'MursionPortal.Button.Cancel': 'Abbrechen',
    'Settings.Config.Heading.Industries': 'BRANCHEN',
    'Settings.Config.Button.New': 'Neu ',
    'Settings.Config.Note': 'HINWEIS:',
    'Settings.Config.Note.Text.ConfigSettingsGlobal': 'Die Konfigurationseinstellungen sind global. Änderungen werden für alle Kunden und Benutzer übernommen.',
    'Settings.Config.Heading.SimSpecialist': 'SIMULATIONSSPEZIALISTEN-LEVELS',
    'Settings.Config.Heading.Scheduling': 'PLANUNG',
    'Settings.Config.Heading.Scheduling.Description': '(Simulationsspezialist)',
    'Settings.Config.Scheduling.MaximumFullTimeSimSpecialistHours': 'Maximale Stundenanzahl von Simulationsspezialisten in Vollzeit',
    'Settings.Config.Scheduling.MaximumPartTimeSimSpecialistHours': 'Maximale Stundenanzahl von Simulationsspezialisten in Teilzeit',
    'Settings.Config.Scheduling.MaximumContinuousSimSpecialistHours': 'Maximale durchgehende Stundenanzahl von Simulationsspezialisten',
    'Settings.Config.Scheduling.LengthOfSimSpecialistRequiredBreak': 'Länge der erforderlichen Pause des Simulationsspezialisten',
    'Settings.Config.Scheduling.FutureAvailabilityForSimSpecialist': 'Zukünftiger Verfügbarkeitszeitraum für Simulationsspezialisten',
    'Settings.Config.Scheduling.SimSpecialistShiftTime': 'Schichtzeit von Simulationsspezialisten',
    'Settings.Config.Scheduling.AvailableTimeBlockMaximumPerDay': 'Geblockte maximale Verfügbarkeitszeit pro Tag',
    'Settings.Config.Scheduling.AvailableTimeBlockMinimumPerDay': 'Geblockte minimale Verfügbarkeitszeit pro Tag',
    'Settings.Config.Scheduling.AvailabilityTimeStep': 'Verfügbarkeitszeit-Schritt',
    'Settings.Config.Scheduling.AdvanceSSScheduleConfirmation': 'Wie weit im Voraus sollte eine Simulationsspezialisten-Planung bestätigt werden?',
    'Settings.Config.Scheduling.RequestUnfulfilledReminder': 'Wann soll eine Erinnerung an nicht beantwortete Anfragen gesendet werden?',
    'Settings.Config.Scheduling.FirstEmailReminder': 'Wann soll die erste Erinnerung per E-Mail versendet werden?',
    'Settings.Config.Scheduling.SecondEmailReminder': 'Wann soll die zweite Erinnerung per E-Mail versendet werden?',
    'Settings.Config.Scheduling.BookedSessionWithNoAssignedLearner': 'Wann soll eine Benachrichtigung über eine gebuchte Sitzung ohne zugewiesenen Schulungsteilnehmer erfolgen?',
    'Settings.Config.Scheduling.UnfulfilledSessionSwapRequestReminder': 'Wann soll eine Erinnerung über eine nicht beantwortete Anfrage zum Tausch einer Sitzung versendet werden?',
    'Settings.Config.Scheduling.NextSessionReminder': 'Wann soll eine Erinnerung an die nächste Sitzung, abhängig von der Zeit zwischen der Erstellung der Sitzung und dem geplanten Beginn der Sitzung, versendet werden?',
    'Settings.Config.Scheduling.ReservedAvailabilityExpired': 'Wenn die reservierte Verfügbarkeit abgelaufen ist',
    'Settings.Config.Scheduling.StartTimeForConsideringMissedSession': 'Zeitspanne nach dem Beginn, nach der eine Sitzung als \„verpasst\“ eingestuft wird',
    'Settings.Config.Scheduling.EndTimeForConsideringMissedSession': 'Zeitspanne nach dem Ende, nach der eine Sitzung als \„verpasst\“ eingestuft wird',
    'Settings.Config.Scheduling.RejoinSessionAfterError': 'Die Zeit, die die Sitzungsteilnehmer nach einem Fehler haben, um wieder der Sitzung beizutreten',
    'Settings.Config.Scheduling.TimeDisabledForSessionScheduling': 'Zeitspanne, die für die Sitzungsplanung deaktiviert ist',
    'Settings.Config.Scheduling.SessionTimeStep': 'Sitzungszeit-Schritt',
    'Settings.Config.Heading.ProjectAndScenario': 'PROJEKT UND SZENARIO',
    'Settings.Config.ProjectAndScenario.MaximumProjectTimelineDuration': 'Maximale Dauer der Projekt-Zeitleiste',
    'Settings.Config.ProjectAndScenario.MinimumProjectTimelineDuration': 'Minimale Dauer der Projekt-Zeitleiste',
    'Settings.Config.ProjectAndScenario.MaximumScenarioTimelineDuration': 'Maximale Dauer der Szenario-Zeitleiste',
    'Settings.Config.ProjectAndScenario.MinimumScenarioTimelineDuration': 'Minimale Dauer der Szenario-Zeitleiste',
    'Settings.Config.ProjectAndScenario.MinimumSessionLength': 'Minimale Sitzungsdauer',
    'Settings.Config.ProjectAndScenario.MaximumSessionLength': 'Maximale Sitzungsdauer',
    'Settings.Config.ProjectAndScenario.MinimumSimulationLength': 'Minimale Simulationsdauer',
    'Settings.Config.ProjectAndScenario.SessionLengthStep': 'Sitzungsdauer-Schritt',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInRemoteSession': 'Minimale Anzahl an Schulungsteilnehmern in Remote-Sitzungen',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInRemoteSession': 'Maximale Anzahl an Schulungsteilnehmern in Remote-Sitzungen',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInFishbowlSession': 'Minimale Anzahl an Schulungsteilnehmern in lokalen Fishbowl-Sitzungen',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInFishbowlSession': 'Maximale Anzahl an Schulungsteilnehmern in lokalen Fishbowl-Sitzungen',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInGroupSession': 'Minimale Anzahl an Schulungsteilnehmern in Gruppensitzungen',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInGroupSession': 'Maximale Anzahl an Schulungsteilnehmern in Gruppensitzungen',
    'Settings.Config.ProjectAndScenario.AllowedScenarioVersions': 'Zulässige Szenarioversionen',
    'Settings.Config.ProjectAndScenario.DefaultScenarioVersion': 'Standard-Szenarioversion',
    'Settings.Config.Heading.CompanyEvent': 'UNTERNEHMENSVERANSTALTUNG',
    'Settings.Config.CompanyEvent.MaximumEventDuration': 'Maximale Veranstaltungsdauer',
    'Settings.Config.CompanyEvent.MinimumEventDuration': 'Minimale Veranstaltungsdauer',
    'Settings.Config.CompanyEvent.EventTimeStep': 'Veranstaltungsdauer-Schritt',
    'Settings.Config.Heading.SessionReport': 'SITZUNGSBERICHT',
    'Settings.Config.SessionReport.ShowSessionReport': 'Sitzungsbericht anzeigen',
    'Settings.Config.Heading.FeedbackProvider': 'FEEDBACKGEBER',
    'Settings.Config.FeedbackProvider.ZenDeskSnippetKey': 'ZenDesk-Snippet-Schlüssel',
    'Settings.Config.Heading.SimulationContent': 'SIMULATIONSINHALT',
    'Settings.Config.SimulationContent.SimulationContentFolder': 'Simulationsinhalt S3-Ordner',
    'Settings.Config.Heading.Invitation': 'EINLADUNG',
    'Settings.Config.Invitation.ExpireUserRegistrationInvitation': 'Wann soll die Einladung zur Benutzerregistrierung ablaufen?',
    'Settings.Config.Invitation.ReminderToAcceptRegistrationInvitation': 'Wann sollen nicht registrierte Benutzer daran erinnert werden, die Registrierungseinladung anzunehmen?',
    'Settings.Config.Invitation.RegistrationInvitationExpiresAfter': 'Registrierungseinladung läuft ab nach',
    'Settings.Config.IndustriesButton.Modal.EditIndustry': 'Branche bearbeiten',
    'Settings.Config.NewButton.Modal.NewIndustry': 'Neue Branche',
    'Settings.Modal.Placeholder.IndustryName': 'Branchenbezeichnung',
    'Settings.Config.IndustriesButton.Modal.Button.UpdateIndustry': 'Branche aktualisieren',
    'Settings.Config.NewButton.Modal.Button.CreateIndustry': 'Branche erstellen',
    'Settings.Config.IndustriesButton.Modal.Button.RestoreIndustry': 'Branche wiederherstellen',
    'Settings.Config.IndustriesButton.Modal.Button.ArchiveIndustry': 'Branche archivieren',
    'MursionPortal.Modal.Heading.Name': 'Name',
    'Settings.Config.RestoreIndustry.Modal.ConfirmRestore': 'Möchten Sie diese Branche wirklich wiederherstellen?',
    'Settings.Config.ArchiveIndustry.Modal.ConfirmArchive': 'Möchten Sie diese Branche wirklich archivieren?',
    'Settings.Config.Description.PerWeek': '(pro Woche)',
    'Settings.Config.Description.PerDay': '(pro Tag)',
    'Settings.Config.Description.BeforeBreak': '(vor der Pause)',
    'Settings.Config.Description.Minutes': '(Minuten)',
    'Settings.Config.Description.Days': '(Tage)',
    'Settings.Config.Description.Hours': '(Stunden)',
    'Settings.Config.TierButton.Modal.Button.UpdateTier': 'Level aktualisieren',
    'Settings.Config.TierButton.Modal.Heading.EditTier': 'Level bearbeiten',
    'Settings.Config.TierButton.Modal.Placeholder.TierName': 'Levelbezeichnung',
    'MursionPortal.Button.Archive': 'Archivieren',
    'Settings.Config.ArchiveIndustry.Modal.Heading.ConfirmArchive': 'Archivieren bestätigen',
    'Settings.Config': 'Konfiguration',
    'Settings.ScenarioBank': 'Szenario Bank',
    'Settings.Environments': 'Umgebungen (ML2)',
    'Settings.Avatars': 'Avatare (ML2)',
    'Settings.SSO': 'SSO',
    'Settings.Error.NoSettingsAvailable': 'Keine Einstellungen verfügbar',
    'MursionPortal.Error.SomethingWentWrong': 'Etwas ist leider schief gegangen.',
    'MursionPortal.Error.ReloadingPage': 'Versuchen Sie, die Seite neu zu laden. Der Fehler wurde gemeldet.',
    'MursionPortal.Error.WorkingHard': 'Wir arbeiten daran, das Problem so schnell wie möglich zu beheben.',
    'MursionPortal.Error.SupportAvailable': 'Der Support ist von Montag bis Freitag für Sie da',
    'MursionPortal.Error.TechnicalAssistance': 'Wenden Sie sich an den Support von Mursion, wenn Sie technische Unterstützung benötigen',
    'MursionPortal.At': 'um',
    'MursionPortal.Or': 'oder',
    'Settings.Environment.Modal.PlaceHolder.EnvironmentName': 'Umgebungsbezeichnung',
    'Settings.Environment.Modal.Description': 'Beschreibung',
    'Settings.Environment.Modal.Industry': 'Branche',
    'Settings.Environment.Modal.Picture': 'Abbildung',
    'MursionPortal.Button.Delete': 'Löschen',
    'Settings.Environment.Modal.Button.UploadFile': 'Datei hochladen',
    'Settings.Environment.Button.CreateEnvironment': 'Umgebung erstellen ',
    'Settings.Environment.ModalHeader.NewEnvironment': 'Neue Umgebung',
    'Settings.Environment.ModalHeader.Environment': 'Umgebung',
    'Settings.Environment.ModalHeader.Archived': '(Archiviert)',
    'Settings.Environment.ModalHeader.EditEnvironment': 'Umgebung bearbeiten',
    'Settings.Environment.Modal.Button.CreateEnvironment': 'Umgebung erstellen',
    'Settings.Environment.Modal.Button.UpdateEnvironment': 'Umgebung aktualisieren',
    'Settings.Environment.Modal.ArchiveEnvironmentText': 'Möchten Sie diese Umgebung wirklich archivieren?',
    'Settings.Environment.Modal.RestoreEnvironmentText': 'Möchten Sie diese Umgebung wirklich wiederherstellen?',
    'MursionPortal.Button.Restore': 'Wiederherstellen',
    'MursionPortal.Total': 'GESAMT',
    'MursionPortal.Text.Total': 'GESAMT',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportData': 'Daten exportieren',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportNotAvailable': 'Der Export ist nicht verfügbar',
    'MursionPortal.Button.ExportToEmail': 'An E-Mail exportieren',
    'MursionPortal.WarningMessage.RequestNotImplemented': 'Die Datenanforderung wurde noch nicht implementiert',
    'MursionPortal.Table.ColumnHeader.Client': 'Kunde',
    'MursionPortal.Table.ColumnHeader.Provider': 'Provider',
    'MursionPortal.Table.ColumnHeader.Project': 'Projekt',
    'MursionPortal.Table.ColumnHeader.Scenario': 'Szenario',
    'MursionPortal.Table.ColumnHeader.SoftwareVersion': 'Softwareversion',
    'MursionPortal.Table.ColumnHeader.DeliveryMode': 'Erbringungsmodus',
    'MursionPortal.Table.ColumnHeader.SessionStart': 'Sitzungsbeginn',
    'MursionPortal.Table.ColumnHeader.SessionEnd': 'Sitzungsende',
    'MursionPortal.Table.ColumnHeader.LearnerStatus': 'Schulungsteilnehmer-Status',
    'MursionPortal.Table.ColumnHeader.SessionStatus': 'Sitzungsstatus',
    'MursionPortal.Table.ColumnHeader.LearnerName': 'Name des Schulungsteilnehmers',
    'MursionPortal.Table.ColumnHeader.LearnerEmail': 'E-Mail-Adresse des Schulungsteilnehmers',
    'MursionPortal.Table.LearnerEmail.HoverTitle.CopyEmail': 'E-Mail kopieren',
    'MursionPortal.Table.LearnerEmail.HoverTitle.UserEmail': 'E-Mail-Adresse des Benutzers',
    'MursionPortal.Table.ColumnHeader.SessionId': 'Sitzungs-ID',
    'MursionPortal.Table.SessionId.HoverTitle.CopySessionId': 'Sitzungs-ID kopieren',
    'MursionPortal.Table.ColumnHeader.SessionReport': 'Sitzungsbericht',
    'MursionPortal.Table.ColumnHeader.SimSpecialistName': 'Name des Simulationsspezialisten',
    'MursionPortal.Table.ColumnHeader.LearnerTimeSpent': 'Zeitaufwand des Schulungsteilnehmers',
    'MursionPortal.Table.HoverTitle.SessionSummary': 'Zusammenfassung der Sitzung',
    'MursionPortal.EmptyMessage.NoSessionsYet': 'Es liegen noch keine Sitzungen vor',
    'MursionPortal.Button.ViewReport': 'Bericht ansehen',
    'Mursion.Portal.Status.Requested': 'Angefordert',
    'Mursion.Portal.Status.Pending': 'Ausstehend',
    'Mursion.Portal.Status.PendingSwap': 'Ausstehend (Tausch)',
    'Mursion.Portal.Status.PreBooked': 'Vorbehaltlich gebucht',
    'Mursion.Portal.Status.Booked': 'Gebucht',
    'Mursion.Portal.Status.Active': 'Aktiv',
    'Mursion.Portal.Status.Complete': 'Vollständig',
    'Mursion.Portal.Status.Error': 'Fehler',
    'Mursion.Portal.Status.Missed': 'Verpasst',
    'Mursion.Portal.Status.NeedsReview': 'Prüfung erforderlich',
    'Mursion.Portal.Status.Cancelled': 'Abgebrochen',
    'Dashboard.UsersActivity.Filter.Roles.Placeholder': 'Suchen …',
    'Settings.ScenarioBank.Table.ColumnHeader.Name': 'Name',
    'MursionPortal.DeliveryMode': 'Erbringungsmodus',
    'Settings.ScenarioBank.Table.ColumnHeader.SessionSize': 'Sitzungsgröße',
    'MursionPortal.SessionLength': 'Dauer der Sitzung',
    'Settings.ScenarioBank.Table.ViewEditScenario': 'Szenario anzeigen/bearbeiten',
    'Settings.ScenarioBank.Table.ThereAreNoScenarios': 'Es liegen noch keine Szenarien vor',
    'MursionPortal.Capitalized.Total': 'GESAMT',
    'Settings.ScenarioBank.Form.NewScenario': 'Neues Szenario',
    'Settings.ScenarioBank.Form.Scenario': 'Szenario',
    'Settings.ScenarioBank.Form.EditScenario': 'Szenario bearbeiten',
    'Settings.ScenarioBank.Form.Save': 'Speichern',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenario': 'Möchten Sie dieses Szenario wirklich archivieren?',
    'Settings.ScenarioBank.Form.ConfirmRestoreScenario': 'Möchten Sie dieses Szenario wirklich wiederherstellen?',
    'Settings.ScenarioBank.Button.CreateScenario': 'Szenario erstellen ',
    'MursionPortal.ShortLabel.Minutes': 'Minute',
    'Settings.ScenarioBank.Modal.Scenarios': 'Szenario/Szenarien',
    'Settings.ScenarioBank.Modal.CompleteProjectInfoFirst': 'Vervollständigen Sie zuerst die Projektinformationen',
    'Settings.ScenarioBank.Modal.ScenarioName': 'Bezeichnung des Szenarios',
    'Settings.ScenarioBank.Modal.Placeholder.EnterScenarioName': 'Bezeichnung des Szenarios eingeben',
    'Settings.ScenarioBank.Modal.Outcome': 'Ergebnis',
    'Settings.ScenarioBank.Modal.Strategies': 'Strategien',
    'Settings.ScenarioBank.Modal.Attachments': 'Anhänge',
    'Settings.ScenarioBank.Modal.SoftwareVersion': 'Softwareversion',
    'Settings.ScenarioBank.Modal.WeAlreadyHaveSessions': 'Es liegen bereits Sitzungen für dieses Szenario vor',
    'Settings.ScenarioBank.Modal.SelectDeliveryMode': 'Wählen Sie einen Erbringungsmodus aus',
    'Settings.ScenarioBank.Modal.ShouldBeAtLeast': 'Sollte mindestens {minutes} Minuten betragen',
    'Settings.ScenarioBank.Modal.SelectSessionLength': 'Wählen Sie eine Sitzungsdauer aus',
    'Settings.ScenarioBank.Modal.SessionSize': 'Sitzungsgröße',
    'Settings.ScenarioBank.Modal.Learner': 'Schulungsteilnehmer',
    'Settings.ScenarioBank.Modal.Learners': 'Schulungsteilnehmer',
    'Settings.ScenarioBank.Modal.SelectSessionSize': 'Wählen Sie eine Sitzungsgröße',
    'MursionPortal.SimulationContent': 'Simulationsinhalt',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContent': 'Dies sind die in 3D gestalteten Umgebungen und Avatare, die in der Simulation eingesetzt werden. Der Inhalt dieses Ordners wird im unten stehenden Abschnitt „Inhaltspaket“ angezeigt.',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoSimulation': 'Diesem Szenario wurde kein Simulationsinhalt zugewiesen',
    'Settings.ScenarioBank.Modal.SelectSimulationContentForUse': 'Zu verwendenden Simulationsinhalt auswählen',
    'Settings.ScenarioBank.Modal.Label.ScenarioOutcome': 'Ergebnis des Szenarios',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnOutcome': 'Geben Sie ein Ergebnis ein',
    'Settings.ScenarioBank.Modal.Description': 'Beschreibung',
    'Settings.ScenarioBank.Modal.NoDescriptionSpecified': 'Keine Beschreibung angegeben',
    'Settings.ScenarioBank.Modal.ScenarioDescription': 'Beschreibung des Szenarios',
    'Settings.ScenarioBank.Modal.AllowedCharactersLimit': 'Die zulässige Länge der Beschreibung ({maxLength} Zeichen) wurde überschritten',
    'Settings.ScenarioBank.Modal.ScenarioStrategies': 'Szenario-Strategien',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnItem': 'Geben Sie einen Artikel ein',
    'Settings.ScenarioBank.Modal.Attachments.DragAndDrop': 'Ziehen Sie Dateien hierher oder klicken Sie auf sie, um sie auszuwählen',
    'Settings.ScenarioBank.Modal.Attachments.UploadedMaximumFiles': 'Sie haben die maximal zulässige Anzahl an Dateien {maxFiles} hochgeladen.',
    'Settings.ScenarioBank.Modal.Attachments.UploadedFiles': 'Hochgeladene Dateien,',
    'Settings.ScenarioBank.Modal.CertifiedSimSpecialists': 'Zertifizierte Simulationsspezialisten',
    'Settings.ScenarioBank.Modal.NoCertifiedSimSpecialist': 'Kein zertifizierter Simulationsspezialist',
    'Settings.ScenarioBank.Modal.Button.Add': 'Hinzufügen ',
    'Settings.ScenarioBank.Modal.Edit': 'bearbeiten',
    'Settings.ScenarioBank.Modal.AddAttachmentsToScenario': 'Anhang zum Szenario hinzufügen',
    'MursionPortal.Cancel': 'Abbrechen',
    'MursionPortal.OK': 'OK',
    'Settings.ScenarioBank.Modal.FileFormatsSupported': 'Folgende Dateiformate werden unterstützt:',
    'Settings.ScenarioBank.Modal.Images': 'Bilder',
    'Settings.ScenarioBank.Modal.Videos': 'Videos',
    'Settings.ScenarioBank.Modal.Audios': 'Audiodateien',
    'Settings.ScenarioBank.Modal.Documents': 'Dokumente',
    'Settings.ScenarioBank.Modal.New': '(neu)',
    'Settings.ScenarioBank.Modal.FileSizeMustNotExceed': 'Die Dateigröße darf {size} nicht überschreiten',
    'Settings.ScenarioBank.Modal.CertifySimSpecialists': 'Simulationsspezialisten zertifizieren',
    'MursionPortal.Confirm': 'Bestätigen',
    'MursionPortal.Delete': 'Löschen',
    'Settings.ScenarioBank.Modal.Archived': '(Archiviert)',
    'Settings.ScenarioBank.Modal.Restore': 'Wiederherstellen',
    'Settings.ScenarioBank.Modal.ConfirmArchive': 'Archivieren bestätigen',
    'Settings.ScenarioBank.Modal.Archive': 'Archivieren',
    'Settings.ScenarioBank.Modal.ConfirmRestore': 'Wiederherstellung bestätigen',
    'MursionPortal.PleaseWait': 'Bitte warten …',
    'MursionPortal.Loading': 'Wird geladen…',
    'Settings.ScenarioBank.Modal.Attachments.FileTypeNotSupported': 'Der Dateityp {extensions} wird nicht unterstützt',
    'Settings.ScenarioBank.Modal.Attachments.FileTypesNotSupported': 'Die Dateitypen {extensions} werden nicht unterstützt',
    'Settings.ScenarioBank.Modal.Attachments.FileExceedsSizeLimit': 'Die Datei {fileNames} ist größer als zulässig',
    'Settings.ScenarioBank.Modal.Attachments.FilesExceedSizeLimit': 'Die Dateien {fileNames} sind größer als zulässig',
    'MursionPortal.Label.Total': 'GESAMT',
    'MursionPortal.Button.HoverTitle.ExportData': 'Daten exportieren',
    'MursionPortal.Button.HoverTitle.ExportNotAvailable': 'Der Export ist nicht verfügbar',
    'MursionPortal.Heading.Filters': 'FILTER:',
    'Dashboard.ProjectsTable.Checkbox.OnlyBillable': ' Nur fakturierbar',
    'MursionPortal.Filters.SoftwareVersion': 'Softwareversion',
    'MursionPortal.Filters.PlaceHolder.SearchVersion': 'Version suchen …',
    'MursionPortal.Filters.Status': 'Status',
    'MursionPortal.Filters.PlaceHolder.SearchStatus': 'Status suchen …',
    'Dashboard.ProjectsTable.ColumnHeader.Provider': 'Provider',
    'Dashboard.ProjectsTable.ColumnHeader.Status': 'Status',
    'Dashboard.ProjectsTable.ColumnHeader.StarAt': 'Beginn',
    'Dashboard.ProjectsTable.ColumnHeader.EndAt': 'Ende',
    'Dashboard.ProjectsTable.ColumnHeader.Billable': 'Fakturierbar',
    'Dashboard.ProjectsTable.ColumnHeader.CancellationWindow': 'Stornierungsfenster',
    'MursionPortal.Table.ColumnHeader.Scenarios': 'Szenarien',
    'MursionPortal.Table.ColumnHeader.Teams': 'Teams',
    'MursionPortal.Table.ColumnHeader.SessionsScheduled': 'Geplante Sitzungen',
    'MursionPortal.Table.ColumnHeader.SessionsCompleted': 'Abgeschlossene Sitzungen',
    'MursionPortal.Table.ColumnHeader.SessionsMissed': 'Verpasste Sitzungen',
    'MursionPortal.Table.ColumnHeader.LateCancellations': 'Verspätete Stornierungen',
    'MursionPortal.Table.ColumnHeader.Learners': 'Schulungsteilnehmer',
    'Dashboard.ProjectsTable.ColumnHeader.SessionsRecorded': 'Aufgezeichnete Sitzungen',
    'MursionPortal.Table.ColumnHeader.SessionsError': 'Sitzungsfehler',
    'Dashboard.ProjectsTable.ColumnHeader.ContractedHours': 'Vereinbarte Stunden',
    'Dashboard.ProjectsTable.ColumnHeader.UtilizedHours': 'Genutzte Stunden',
    'Dashboard.ProjectsTable.ColumnHeader.RemainingHours': 'Verbleibende Stunden',
    'Dashboard.ProjectsTable.ColumnHeader.CompletedHours': 'Abgeschlossene Stunden',
    'Dashboard.ProjectsTable.ColumnHeader.MissedHours': 'Verpasste Stunden',
    'Dashboard.ProjectsTable.HoverTitle.ViewEditProject': 'Projekt anzeigen/bearbeiten',
    'Dashboard.ProjectsTable.EmptyMessage.NoProjectsYet': 'Es liegen noch keine Projekte vor',
    'MursionPortal.Button.Clear': 'Bereinigen',
    'Dashboard.Projects.AlertMessage.DataRequestNotImplemented': 'Die Datenanforderung wurde noch nicht implementiert',
    'MursionPortal.SelectorMenu.SelectedCountOfTotal': '{formattedSelectedCount}von {formattedTotal} ausgewählt',
    'MursionPortal.Dashboard.Table.Modal.Filter.Teams': 'Teams',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterTeamName': 'Teamnamen eingeben',
    'MursionPortal.Dashboard.Table.Modal.TeamsEmptyMessage': 'Es wurden keine Teams gefunden',
    'MursionPortal.Dashboard.Table.Modal.Filter.NumberOfLearners': 'Anzahl der Schulungsteilnehmer',
    'MursionPortal.Dashboard.Table.Modal.Filter.Scenarios': 'Szenarien',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterScenarioName': 'Bezeichnung des Szenarios eingeben',
    'MursionPortal.Dashboard.Table.Modal.ScenariosEmptyMessage': 'Es wurden keine Szenarien gefunden',
    'MursionPortal.Dashboard.Table.Modal.Filter.Name': 'Name',
    'MursionPortal.Dashboard.Table.Modal.Filter.Status': 'Status',
    'MursionPortal.Dashboard.Table.Modal.Filter.DeliveryMode': 'Erbringungsmodus',
    'MursionPortal.Dashboard.Table.Modal.Filter.Learners': 'Schulungsteilnehmer',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterLearnerName': 'Namen des Schulungsteilnehmers eingeben',
    'MursionPortal.Dashboard.Table.Modal.LearnersEmptyMessage': 'Es wurden keine Schulungsteilnehmer gefunden',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsScheduled': 'Geplante Sitzungen',
    'MursionPortal.Dashboard.Table.Modal.SessionsEmptyMessage': 'Es wurden keine Sitzungen gefunden',
    'MursionPortal.Dashboard.Table.Modal.Filter.ScenarioName': 'Bezeichnung des Szenarios',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionDateTime': 'Datum/Uhrzeit der Sitzung',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsCompleted': 'Abgeschlossene Sitzungen',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsError': 'Sitzungsfehler',
    'Dashboard.ProjectsActivityTable.Modal.Filter.SessionsRecorded': 'Aufgezeichnete Sitzungen',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsMissed': 'Verpasste Sitzungen',
    'MursionPortal.Dashboard.Table.Modal.Filter.LateCancellations': '„Verspätete“ Stornierungen',
    'MursionPortal.Dashboard.Table.Modal.Filter.CancelledBy': 'Storniert von',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateScheduled': 'Geplant am',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateCancelled': 'Storniert am',
    'MursionPortal.ProjectStatus.Active': 'Aktiv',
    'MursionPortal.ProjectStatus.Completed': 'Abgeschlossen',
    'MursionPortal.ProjectStatus.Archived': 'Archiviert',
    'Settings.Avatars.Modal.Name': 'Name',
    'Settings.Avatars.Modal.PlaceHolder.AvatarName': 'Avatar-Name',
    'Settings.Avatars.Modal.Description': 'Beschreibung',
    'MursionPortal.Name': 'Name',
    'MursionPortal.Description': 'Beschreibung',
    'MursionPortal.Environments': 'Umgebungen',
    'Settings.Avatars.TableHover.ViewEditAvatar': 'Avatar anzeigen/bearbeiten',
    'Settings.Avatars.NoAvatarText': 'Es liegen noch keine Avatare vor',
    'Settings.Avatars.ModalHeader.NewAvatar': 'Neuer Avatar',
    'Settings.Avatars.ModalHeader.Avatar': 'Avatar',
    'Settings.Avatars.ModalHeader.EditAvatar': 'Avatar bearbeiten',
    'Settings.Avatars.Modal.Button.CreateAvatar': 'Avatar erstellen',
    'Settings.Avatars.Modal.Button.UpdateAvatar': 'Avatar aktualisieren',
    'Settings.Avatars.Modal.ArchiveAvatarText': 'Möchten Sie diesen Avatar wirklich archivieren?',
    'Settings.Avatars.Modal.RestoreAvatarText': 'Möchten Sie diesen Avatar wirklich wiederherstellen?',
    'Settings.Avatars.Modal.Button.Edit': 'bearbeiten',
    'Settings.Avatars.Modal.EditButton.Accept': 'Annehmen',
    'Settings.Avatars.Modal.NoSelectedEnvironments': 'Keine ausgewählten Umgebungen',
    'Settings.Avatars.Modal.NoEnvironmentsSetForSelectedIndustry': 'Für die ausgewählte Branche wurden keine Umgebungen festgelegt.',
    'Settings.Avatars.Button.CreateAvatar': 'Avatar erstellen ',
    'MursionPortal.Dashboard': 'Dashboard',
    'MursionPortal.Tutorial': 'Tutorial',
    'MursionPortal.Eula': 'Endbenutzer-Lizenzvertrag',
    'MursionPortal.Companies': 'Unternehmen',
    'MursionPortal.SessionFlow': 'Sitzungsablauf',
    'MursionPortal.SessionReport': 'Sitzungsbericht',
    'MursionPortal.AssetSync': 'Asset-Synchronisierung',
    'MursionPortal.Users': 'Benutzer',
    'MursionPortal.AppDownload': 'App herunterladen',
    'MursionPortal.Calendar': 'Kalender',
    'MursionPortal.Clients': 'Kunden',
    'MursionPortal.Sessions': 'Sitzungen',
    'MursionPortal.Trainings': 'Schulungen',
    'MursionPortal.Requests': 'Anfragen',
    'MursionPortal.Settings': 'Einstellungen',
    'MursionPortal.ArtDesigner': 'Art Designer',
    'MursionPortal.ArtDesigner.BodyContents': 'We\'ve opened a new browser tab with the Mursion Designer application.',
    'MursionPortal.Projects': 'Projekte',
    'MursionPortal.Teams': 'Teams',
    'MursionPortal.Reports': 'Berichte',
    'MursionPortal.Downloads': 'Downloads',
    'MursionPortal.Upcoming': 'bevorstehend',
    'MursionPortal.Auth': 'OAuth',
    'Settings.Avatars.Modal.ErrorMessage.ItemsNotAvailable': 'Es stehen maximal {max} Elemente zur Auswahl',
    'Settings.Avatars.Modal.ErrorMessage.ItemNotAvailable': 'Es steht maximal {max} Element zur Auswahl',
    'MursionPortal.Industry': 'Branche',
    'Settings.Environment.TableHover.ViewEditEnvironment': 'Umgebung anzeigen/bearbeiten',
    'Settings.Environment.NoEnvironmentText': 'Es liegen noch keine Umgebungen vor',
    'Projects.Button.CreateProject': 'Projekt erstellen ',
    'Projects.ListHeader.CreationDate': 'Erstellungsdatum',
    'Projects.ListHeader.ProjectManagers': 'Projektmanager',
    'MursionPortal.Capitalized.Active': 'AKTIV',
    'MursionPortal.Capitalized.Completed': 'ABGESCHLOSSEN',
    'MursionPortal.Capitalized.Archived': 'ARCHIVIERT',
    'MursionPortal.Capitalized.All': 'ALLE',
    'Projects.ProjectCard.BackToClient': 'Zurück zum Kunden',
    'Projects.ProjectCard.NoProjectsFound': 'Keine Projekte gefunden',
    'Projects.ProjectCard.PlaceHolder.EnterProjectName': 'Projektbezeichnung eingeben',
    'Projects.ProjectCard.ProjectsList': 'PROJEKTLISTE',
    'MursionPortal.Capitalized.Project': 'PROJEKT',
    'MursionPortal.Scenarios': 'Szenarien',
    'Projects.ScenarioList.ThereAreNoScenarios': 'Es liegen noch keine Szenarien vor.',
    'Projects.ScenarioList.NoScenariosFound': 'Es wurden keine Szenarien gefunden.',
    'Projects.ScenarioList.Button.Draft': 'Entwurf',
    'MursionPortal.Finalized': 'Abgeschlossen',
    'MursionPortal.Started': 'Begonnen',
    'MursionPortal.Finished': 'Fertiggestellt',
    'MursionPortal.Archived': 'Archiviert',
    'MursionPortal.EnterScenarioName': 'Bezeichnung des Szenarios eingeben',
    'Projects.AllProjects': 'alle Projekte',
    'MursionPortal.Label.LowerCase.Archived': 'archiviert',
    'MursionPortal.Label.Active': 'Aktiv',
    'MursionPortal.Label.Completed': 'Abgeschlossen',
    'Projects.Tab.Basic': 'Grundlegend',
    'MursionPortal.Heading.ProjectName': 'Projektbezeichnung',
    'MursionPortal.Label.Created': 'Erstellt',
    'MursionPortal.Label.Status': 'Status',
    'MursionPortal.Label.Learners': 'Schulungsteilnehmer',
    'MursionPortal.Label.LearnersCount': '{count} Schulungsteilnehmer',
    'Projects.Tab.NoLearners': 'Keine Schulungsteilnehmer',
    'MursionPortal.Label.SoftwareVersion': 'Softwareversion',
    'Projects.ProjectInfo.Label.Non-Billable': 'Nicht fakturierbar',
    'Projects.ProjectInfo.Label.Contracted': 'Vereinbart',
    'Projects.ProjectInfo.Label.CancellationWindow': 'Stornierungsfenster',
    'MursionPortal.Header.Recording': 'Aufzeichnung',
    'Projects.ProjectInfo.Checkbox.AllowRecording': 'Aufzeichnung zulassen',
    'MursionPortal.Label.VideoRecording': 'Videoaufzeichnung',
    'MursionPortal.Label.Allowed': 'Erlaubt',
    'MursionPortal.Label.Disallowed': 'Nicht erlaubt',
    'MursionPortal.Label.RecordedVideosGoBackTo': 'Aufgezeichnete Videos gehen zurück an:',
    'MursionPortal.Checkbox.RecordingPermissionLiesWithLearner': 'Die Erlaubnis zur Aufzeichnung wird vom Schulungsteilnehmer erteilt',
    'MursionPortal.Label.LiesWithLearner': 'Vom Schulungsteilnehmer erteilt',
    'Projects.ProjectInfo.Label.Enabled': 'Aktiviert',
    'Projects.ProjectInfo.Label.Disabled': 'Deaktiviert',
    'MursionPortal.Heading.ProjectManagers': 'Projektmanager',
    'MursionPortal.Heading.Tags': 'Schlagwörter',
    'MursionPortal.Button.AreYouSureEditProject': 'Möchten Sie dieses Projekt wirklich bearbeiten?',
    'MursionPortal.Button.SaveProjectInfo': 'Projektinformationen speichern',
    'Projects.Tab.Utilization': 'Nutzung',
    'MursionPortal.Label.Loading': 'Wird geladen…',
    'Projects.ProjectInfo.Label.NoProjectManagers': 'Keine Projektmanager',
    'Projects.ProjectInfo.Label.NoSelectedProjectManagers': 'Keine ausgewählten Projektmanager',
    'MursionPortal.Label.ProjectManagers': 'Projektmanager',
    'MursionPortal.Label.RecordingPermission': 'Erlaubnis zur Aufzeichnung:',
    'Projects.Utilization.Label.UtilizedSessions': 'Genutzte Sitzungen:',
    'Projects.Utilization.Label.UtilizedMissedSessions': 'Genutzte verpasste Sitzungen:',
    'Projects.Utilization.Label.UtilizedCancelledSessions': 'Genutzte stornierte Sitzungen:',
    'Projects.Utilization.Label.UtilizedDuration': 'Genutzte Dauer:',
    'Projects.Utilization.Label.RemainingDuration': 'Verbleibende Dauer:',
    'MursionPortal.ScenarioListItem.Label.Timeline': 'Zeitleiste',
    'MursionPortal.ScenarioListItem.Label.SessionLength': 'Sitzungsdauer',
    'MursionPortal.ScenarioListItem.Label.SessionSize': 'Sitzungsgröße',
    'MursionPortal.ScenarioListItem.Label.DeliveryMode': 'Erbringungsmodus',
    'MursionPortal.ScenarioCard.BackToProject': 'Zurück zum Projekt',
    'MursionPortal.ScenarioCard.NoScenariosFound': 'Keine Szenarien gefunden',
    'MursionPortal.Placeholder.EnterScenarioName': 'Bezeichnung des Szenarios eingeben',
    'MursionPortal.ScenarioCard.Label.ScenariosList': 'SZENARIENLISTE',
    'MursionPortal.ScenarioCard.Header.Scenario': 'SZENARIO',
    'MursionPortal.ScenarioCard.Header.ChooseTypeOfEvent': 'Wählen Sie die Art der zu planenden Veranstaltung aus',
    'MursionPortal.Label.Session': 'Sitzung',
    'MursionPortal.Label.Training': 'Schulung',
    'MursionPortal.Button.Confirm': 'Bestätigen',
    'MursionPortal.Button.Continue': 'Weiter',
    'MursionPortal.Button.Schedule': 'Planen',
    'MursionPortal.Label.Draft': 'Entwurf',
    'MursionPortal.Label.Finalized': 'Abgeschlossen',
    'MursionPortal.Label.Started': 'Begonnen',
    'MursionPortal.Label.Finished': 'Fertiggestellt',
    'MursionPortal.Label.Archived': 'Archiviert',
    'MursionPortal.Label.Cancel': 'abbrechen',
    'MursionPortal.Label.Edit': 'bearbeiten',
    'MursionPortal.Label.CancelEdit': 'Bearbeiten abbrechen',
    'MursionPortal.Label.EditScenario': 'Szenario bearbeiten',
    'MursionPortal.Label.EditScenarioName': 'Bezeichnung des Szenarios bearbeiten',
    'MursionPortal.ScenarioCard.Label.ApplyNameChange': 'Namensänderung übernehmen',
    'MursionPortal.ScenarioCard.Label.Apply': 'übernehmen',
    'MursionPortal.ScenarioCard.Tab.Info': 'INFO',
    'MursionPortal.ScenarioCard.Tab.Learners': 'SCHULUNGSTEILNEHMER',
    'MursionPortal.ScenarioCard.Tab.Sessions': 'SITZUNGEN',
    'MursionPortal.ScenarioCard.ConfirmButton.AreYouSureArchiveScenario': 'Möchten Sie dieses Szenario wirklich archivieren? Alle zugehörigen Daten werden ebenfalls archiviert.',
    'MursionPortal.ScenarioCard.ConfirmButton.ArchiveScenario': 'Szenario archivieren',
    'MursionPortal.Label.Delivery': 'Bereitstellung',
    'MursionPortal.ScenarioInfo.Header.ArchivedScenario': 'ARCHIVIERTES SZENARIO',
    'MursionPortal.ScenarioInfo.Button.YouMayOnlyEditTeams': 'Sie dürfen Teams und Zeitleiste nur bearbeiten, wenn Sie ein Szenario abschließen',
    'MursionPortal.ScenarioInfo.Button.FinalizeScenario': 'Szenario abschließen',
    'MursionPortal.ScenarioInfo.Button.SaveScenario': 'Szenario speichern',
    'MursionPortal.ScenarioInfo.Button.CreatePlanning': 'Planung erstellen ',
    'MursionPortal.ScenarioInfo.Label.SimLength': 'Simulationsdauer',
    'MursionPortal.Header.Outcome': 'Ergebnis',
    'MursionPortal.Header.Strategies': 'Strategien',
    'MursionPortal.Header.Attachments': 'Anhänge',
    'MursionPortal.ScenarioInfo.Button.SaveScenarioInfo': 'Informationen zum Szenario speichern',
    'MursionPortal.Label.ContentBundle': 'Inhaltspaket',
    'MursionPortal.ScenarioInfo.Button.ShowLess': 'Weniger anzeigen',
    'MursionPortal.ScenarioInfo.Button.ShowMore': 'Mehr anzeigen',
    'MursionPortal.AssetSettings.Label.CurrentScenarioNoAssetSettings': 'Für das aktuelle Szenario liegen keine Asset-Einstellungen vor.',
    'MursionPortal.Teams.Label.NoSelectedTeams': 'Keine ausgewählten Teams',
    'MursionPortal.Teams.Label.ClientHasNoAssignedTeams': 'Dem Kunden wurden keine Teams zugeordnet',
    'MursionPortal.Label.Teams': 'Teams',
    'MursionPortal.Label.NoTeamsSpecified': 'Keine Teams angegeben',
    'MursionPortal.Label.Capitalized.Draft': 'ENTWURF',
    'MursionPortal.Label.Capitalized.Finalized': 'ABGESCHLOSSEN',
    'MursionPortal.Label.Capitalized.Started': 'GESTARTET',
    'MursionPortal.Label.Capitalized.Finished': 'FERTIG',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioStillInDevelopmentStage': 'Das Szenario befindet sich noch in der Entwicklungsphase',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioDeployedTimelineUpcoming': 'Das Szenario wird bereitgestellt, die Zeitleiste steht jedoch noch aus',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioActiveLearnersCanSchedule': 'Das Szenario ist aktiv und die Schulungsteilnehmer können Sitzungen planen',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioIsCompleted': 'Das Szenario ist abgeschlossen',
    'MursionPortal.Button.Add': 'Hinzufügen ',
    'MursionPortal.Label.Warning': 'Warnung',
    'MursionPortal.SessionWizard.Label.UnableToCreateSession': 'Die Sitzung für die ausgewählten {failedParam} konnte nicht erstellt werden.',
    'MursionPortal.SessionWizard.Label.PleaseContinueToFindAnother': 'Klicken Sie auf „Weiter“, um ein anderes <code>{failedParam}</code> zu finden.',
    'MursionPortal.SessionWizard.Label.ScheduleSession': 'Sitzung planen',
    'MursionPortal.SessionWizard.Label.ScheduleTraining': 'Schulung planen',
    'MursionPortal.SessionWizard.Label.AddNotes': 'Notizen hinzufügen',
    'MursionPortal.SessionWizard.Label.SelectSimulationContentForUse': 'Zu verwendenden Simulationsinhalt auswählen',
    'MursionPortal.SessionWizard.Label.SelectStep': '{step} auswählen',
    'MursionPortal.SessionWizard.Label.Expired': 'abgelaufen',
    'MursionPortal.SessionWizard.ConfirmButton.AreYouSureCreateEmergencySession': 'Möchten Sie wirklich eine Notfallsitzung erstellen?',
    'MursionPortal.SessionWizard.Button.Create': 'Erstellen',
    'MursionPortal.Button.Next': 'Weiter',
    'MursionPortal.SessionWizard.Label.RemainingTimeToBook': 'verbleibend, um diesen Zeitblock zu buchen, bevor die Reservierung abläuft.',
    'MursionPortal.SessionWizard.Label.TimeBlockHoldExpired': 'Die Reservierung des Zeitblocks ist abgelaufen.',
    'MursionPortal.Label.Date': 'Datum',
    'MursionPortal.Label.Time': 'Zeit',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailableClickSubmit': 'Diese Zeit ist nicht mehr verfügbar. Möchten Sie eine andere Option auswählen? Gehen Sie zurück, um eine verfügbare Option auszuwählen oder klicken Sie auf „Senden“, um eine Anfrage für die bestehende Option zu senden.',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailable': 'Diese Zeit ist nicht mehr verfügbar. Möchten Sie eine andere Option auswählen? Gehen Sie zurück, um eine verfügbare Option auszuwählen.',
    'MursionPortal.SessionWizard.Button.GoBack': 'Zurück',
    'MursionPortal.Button.Submit': 'Senden',
    'MursionPortal.SessionWizard.Label.NoAvailableClients': 'Keine verfügbaren Kunden',
    'MursionPortal.SessionWizard.Label.NoAvailableProjects': 'Keine verfügbaren Projekte',
    'MursionPortal.SessionWizard.Label.NoAvailableScenarios': 'Keine verfügbaren Szenarien',
    'MursionPortal.SessionWizard.Label.PleaseSelectScenario': 'Bitte wählen Sie zuerst das Szenario aus',
    'MursionPortal.SessionWizard.Label.NoImage': 'Kein Bild',
    'MursionPortal.SessionWizard.EmptyMessage.NoAvailableSImSpecialists': 'Keine verfügbaren Simulationsspezialisten',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoContentBundles': 'Dem Szenario wurden keine Inhaltspakete zugewiesen.',
    'MursionPortal.SessionWizard.Option.SelectAnAsset': 'Wählen Sie ein Asset aus',
    'MursionPortal.SimSpecialistStep.Label.NoSimSpecialistsAvailable': 'Für die ausgewählte Zeit sind keine Simulationsspezialisten verfügbar. Die Sitzung wird auf Anfrage zugewiesen.',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainees': 'Sie können für diese Art von Sitzung nur maximal {maxLearners} Auszubildende hinzufügen.',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainee': 'Sie können für diese Art von Sitzung nur maximal {maxLearners} Auszubildenden hinzufügen.',
    'MursionPortal.EmptyMessage.NoUsersSelected': 'Keine Benutzer ausgewählt',
    'MursionPortal.Placeholder.Search': 'Suche',
    'MursionPortal.SsLearnersStep.Label.NoTraineeToSelect': 'Kein Auszubildender zur Auswahl',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearners': 'Sie können für diese Art von Sitzung nur maximal {maxLearners} Schulungsteilnehmer hinzufügen.',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearner': 'Sie können für diese Art von Sitzung nur maximal {maxLearners} Schulungsteilnehmer hinzufügen.',
    'MursionPortal.LearnersStep.Label.NoLearnersToSelect': 'Keine Schulungsteilnehmer zur Auswahl',
    'MursionPortal.LearnersStep.Label.NoUsersSelected': 'Keine Benutzer ausgewählt',
    'MursionPortal.LearnersStep.Option.AllTeams': 'Alle Teams',
    'MursionPortal.LearnersStep.Button.AddEntireTeam': 'Gesamtes Team hinzufügen',
    'MursionPortal.LearnersStep.Note.AllowedSessionSIze': 'Die zulässige Sitzungsgröße beträgt {maxLearners}. Bestimmte Schulungsteilnehmer auswählen',
    'MursionPortal.LearnersStep.Checkbox.OnlyUnauthorizedUsers': 'Nur nicht authentifizierte Benutzer können beitreten',
    'MursionPortal.NotesStep.SessionNotes': 'Sitzungsnotizen',
    'MursionPortal.NotesStep.ErrorMessage.AllowedLimitExceeded': 'Die zulässige Zeichenanzahl ({limit} Zeichen) wurde überschritten',
    'MursionPortal.Button.LoadMore': 'Mehr laden',
    'MursionPortal.Label.Trainer': 'Trainer',
    'MursionPortal.Label.Description': 'Beschreibung',
    'MursionPortal.Label.Outcome': 'Ergebnis',
    'MursionPortal.Label.Strategies': 'Strategien',
    'MursionPortal.Label.DeliveryMode': 'Erbringungsmodus',
    'MursionPortal.Label.SessionLength': 'Dauer der Sitzung',
    'MursionPortal.Label.NoDescriptionProvided': 'Keine Beschreibung verfügbar',
    'MursionPortal.Label.NoOutcomeProvided': 'Kein Ergebnis verfügbar',
    'MursionPortal.Label.NoStrategiesProvided': 'Keine Strategien verfügbar',
    'MursionPortal.Placeholder.Dotted.Search': 'Suchen …',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocks': 'Keine verfügbaren Zeitblöcke',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocksInRange': 'Keine verfügbaren Zeitblöcke im ausgewählten Zeitraum',
    'MursionPortal.TimeStep.Footer.BySelectingThisTimeBlock': 'Wenn Sie diesen Zeitblock auswählen, fordern Sie eine derzeit nicht verfügbare Sitzungszeit an. Wir tun unser Bestes, um Ihren Wunschtermin zu ermöglichen. Schieben Sie den folgenden Schalter auf „Aus“, um nur aus Zeitblöcken zu wählen, deren Verfügbarkeit bestätigt ist.',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOff': 'Schieben Sie den Schalter auf „Aus“, um nur aus verfügbaren Zeitblöcken auszuwählen.',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOn': 'Schieben Sie den Schalter auf „Ein“, um einen zusätzlichen Zeitblock anzufordern.',
    'MursionPortal.Label.LowerCase.Client': 'Kunde',
    'MursionPortal.Label.Project': 'Projekt',
    'MursionPortal.Label.Scenario': 'Szenario',
    'MursionPortal.Projects.NoProjectsFound': 'Es wurden keine Projekte gefunden.',
    'MursionPortal.Projects.YourTeamsNotAssigned': 'Ihr Team wurde noch keinen Projekten zugewiesen.',
    'MursionPortal.Projects.NoProjectsCreated': 'Es wurden noch keine Projekte erstellt.',
    'MursionPortal.Teams.NoTeamsFound': 'Es wurden keine Teams gefunden.',
    'MursionPortal.Teams.YouHaveNotBeen': 'Sie wurden noch zu keinem Team hinzugefügt.',
    'MursionPortal.Teams.YouHaveNoTeams': 'Sie haben noch keine Teams.',
    'MursionPortal.Teams.NoTeamsCreated': 'Es wurden noch keine Teams erstellt.',
    'Settings.SSO.Modal.ConfigurationName': 'Bezeichnung der Konfiguration',
    'Settings.SSO.Modal.AuthorizationEndpoint': 'Autorisierungsendpunkt',
    'Settings.SSO.Modal.LogoutEndpoint': 'Abmeldeendpunkt',
    'Settings.SSO.Modal.ClientID': 'Kunden-ID',
    'Settings.SSO.Modal.ClientSecret': 'Geheimer Kundenschlüssel',
    'Settings.SSO.Modal.Scope': 'Geltungsbereich',
    'Settings.SSO.Modal.UserInfoEndpoint': 'Benutzerinfo-Endpunkt',
    'Settings.SSO.Modal.TokenEndpoint': 'Token-Endpunkt',
    'Settings.SSO.Modal.TokenHTTPMethod': 'Token-HTTP-Methode',
    'Settings.SSO.Modal.UserIDClaimName': 'Benutzer-ID Anspruchsbezeichnung',
    'Settings.SSO.Modal.EmailClaimName': 'E-Mail-Adresse Anspruchsbezeichnung',
    'Settings.SSO.Modal.FirstNameClaimName': 'Vorname Anspruchsbezeichnung',
    'Settings.SSO.Modal.LastNameClaimName': 'Nachname Anspruchsbezeichnung',
    'Settings.SSO.Modal.LinkAllowed': 'Verknüpfung zulässig',
    'Settings.SSO.Modal.UnlinkAllowed': 'Aufheben der Verknüpfung zulässig',
    'Settings.SSO.Modal.SingleLogoutEnabled': 'Einzel-Abmeldung aktiviert',
    'Settings.SSO.Modal.SingleSignOnServiceEndpoint': 'Einmalanmeldung am Service-Endpunkt',
    'Settings.SSO.Modal.EntityID': 'ID der Entität',
    'Settings.SSO.Modal.X509Certificate': 'X.509-Zertifikat',
    'Settings.SSO.Button.CreateSsoConfiguration': 'SSO-Konfiguration erstellen ',
    'Settings.SSO.Modal.Protocol': 'Protokoll :',
    'Settings.SSO.Modal.Type': 'Art :',
    'Settings.SSO.Modal.ID': 'ID :',
    'Settings.SSO.Modal.AssignedClients': 'Zugewiesene Kunden',
    'Settings.SSO.Modal.Button.DeleteSso': 'SSO löschen',
    'Settings.SSO.ModalHeader.EditSsoConfiguration': 'SSO-Konfiguration bearbeiten',
    'Settings.SSO.ModalHeader.NewSsoConfiguration': 'Neue SSO-Konfiguration',
    'Settings.SSO.Modal.Button.CreateSsoConfiguration': 'SSO-Konfiguration erstellen',
    'Settings.SSO.Modal.Button.SaveSsoConfiguration': 'SSO-Konfiguration speichern',
    'Settings.SSO.Modal.DeletionConfirmationText': 'Möchten Sie diese SSO-Konfiguration wirklich löschen?',
    'Settings.SSO.Modal.AddButton.GroupClaimName': 'Gruppenanspruchsname',
    'Settings.SSO.Modal.AddButton.Team': 'Team',
    'Settings.SSO.Modal.AddButton.PlaceHolder.GroupClaimValue': 'Gruppenanspruchsname',
    'Settings.SSO.Modal.SPInitiatedSsoUrl': 'SP-initiierte SSO-URL :',
    'Settings.SSO.Modal.Button.DownloadSPMetadata': 'SP-Metadaten herunterladen',
    'Settings.SSO.Modal.Button.Public': 'Öffentlich',
    'Settings.SSO.Modal.Button.Private': 'Privat',
    'Settings.SSO.Modal.ConfigurationIcon': 'Konfigurationssymbol :',
    'Settings.SSO.Modal.PlaceHolder.EnterTeamClaimName': 'Team-Anspruchsbezeichnung eingeben',
    'Settings.SSO.Modal.TeamClaimName': 'Team-Anspruchsbezeichnung',
    'Settings.SSO.Modal.TeamMapping': 'Team-Mapping :',
    'Settings.SSO.Modal.Button.Change': 'Ändern',
    'MursionPortal.PlaceHolder.SelectAClient': 'Wählen Sie einen Kunden aus',
    'Settings.SSO.Modal.AddButton.PlaceHolder.SelectATeam': 'Wählen Sie ein Team aus',
    'MursionPortal.Label.Client': 'Kunde',
    'Settings.SSO.Modal.ConfirmDelete': 'Löschen bestätigen',
    'Settings.SSO.Table.ColumnHeader.ConfigurationName': 'Bezeichnung der Konfiguration',
    'Settings.SSO.Table.ColumnHeader.Type': 'Art',
    'Settings.SSO.Table.ColumnHeader.Protocol': 'Protokoll',
    'Settings.SSO.Table.ColumnHeader.ClientsCount': 'Kundenanzahl',
    'Settings.SSO.PlaceHolder.EnterSsoName': 'SSO-Namen eingeben',
    'Settings.SSO.Table.NoSsoSchemasFound': 'Es wurden keine SSO-Schemata gefunden.',
    'Settings.SSO.Table.HoverTitle.ViewEditSso': 'SSO anzeigen/bearbeiten',
    'Settings.SSO.Modal.HoverTitle.CopySPInitiatedSsoUrl': 'SP-initiierte SSO-URL kopieren',
    'Settings.SSO.Modal.CopyButton.ModalHeader.SPInitiatedSsoUrl': 'SP-initiierte SSO-URL',
    'MursionPortal.CopyText.CopiedToClipboard': 'wurde in die Zwischenablage kopiert!',
    'Settings.SSO.Text.Public': 'Öffentlich',
    'Settings.SSO.Text.Private': 'Privat',
    'Settings.SSO.Modal.NoClientsAssigned': 'Keine Kunden zugewiesen',
    'MursionPortal.Label.NotAvailable': 'n/a',
    'Teams.Modal.Header.NewTeam': 'Neues Team',
    'MursionPortal.Button.More': 'Mehr',
    'Teams.Modal.Header.AllTeams': 'alle Teams',
    'MursionPortal.Label.Name': 'Name:',
    'MursionPortal.Label.Location': 'Standort:',
    'MursionPortal.Label.Tags': 'Schlagwörter:',
    'MursionPortal.Header.Facilitators': 'Moderatoren:',
    'MursionPortal.Header.Scenarios': 'Szenarien:',
    'MursionPortal.Button.SaveTeamInfo': 'Teaminformationen speichern',
    'MursionPortal.IconButton.Cancel': 'abbrechen',
    'MursionPortal.IconButton.Edit': 'bearbeiten',
    'MursionPortal.ButtonTitle.CancelEdit': 'Bearbeiten abbrechen',
    'MursionPortal.ButtonTitle.EditTeam': 'Team bearbeiten',
    'MursionPortal.ConfirmationText.RestoreTeam': 'Möchten Sie dieses Team wirklich wiederherstellen?',
    'MursionPortal.ButtonTitle.RestoreTeam': 'Team wiederherstellen',
    'MursionPortal.ConfirmationText.ArchiveTeam': 'Möchten Sie dieses Team wirklich archivieren?',
    'MursionPortal.ButtonTitle.ArchiveTeam': 'Team archivieren',
    'MursionPortal.EmptyMessage.NoFacilitators': 'Keine Moderatoren',
    'MursionPortal.EmptyMessage.NoSelectedFacilitators': 'Keine ausgewählten Moderatoren',
    'MursionPortal.Title.Facilitators': 'Moderatoren',
    'MursionPortal.Heading.Archived': '(Archiviert)',
    'Teams.Modal.Header.AllLearners': 'Alle Schulungsteilnehmer',
    'Teams.Modal.Placeholder.SearchLearners': 'Schulungsteilnehmer suchen …',
    'Teams.Modal.EmptyMessage.NoLearnersToSelect': 'Keine Schulungsteilnehmer zur Auswahl',
    'Teams.Modal.Header.TeamLearners': 'Schulungsteilnehmer-Team',
    'Teams.Modal.Placeholder.SearchTeamLearners': 'Schulungsteilnehmer-Team suchen …',
    'Teams.Modal.EmptyMessage.NoTeamLearnersToSelect': 'Keine Schulungsteilnehmer-Teams zur Auswahl',
    'Teams.Modal.Header.AssignLearners': 'Schulungsteilnehmer zum Team „{teamName}“ zuweisen',
    'Teams.AssignLearners.Modal.Button.SelectAll': 'Alle auswählen',
    'Teams.AssignLearners.Modal.EmptyMessage.NoItems': 'Keine Elemente zur Auswahl',
    'MursionPortal.Header.Teams': 'Teams',
    'Teams.Modal.AlertMessage.Timezone': ' Bei einer Änderung der Zeitzone überprüfen Sie bitte bestehende Veranstaltungen und Verfügbarkeiten.',
    'Teams.SelectorMenu.SelectedOf': '{selected} von {totalNumber} ausgewählt',
    'Teams.Modal.AlertMessage.ProfileTimezone': 'Die aktuelle Zeitzonenauswahl Ihres Profils stimmt nicht mit den Datums- und Uhrzeiteinstellungen Ihres Computers überein ({updatedTimeZone}).Klicken Sie auf „Änderungen speichern“, um dennoch fortzufahren oder wählen Sie eine andere Zeitzonenoption für Ihr Profil aus.',
    'MursionPortal.Label.Learner': 'Schulungsteilnehmer',
    'Teams.Modal.Button.More': '{overlappedAmount} weitere …',
    'MursionPortal.IconButton.Archive': 'archivieren',
    'Teams.ToggleButton.Learners': 'Schulungsteilnehmer',
    'Teams.ToggleButton.Sessions': 'Sitzungen',
    'MursionPortal.Status.RegistrationStatus': 'Registrierungsstatus',
    'MursionPortal.Status.RegistrationAccepted': 'Registrierung akzeptiert',
    'MursionPortal.Status.RegistrationNotAccepted': 'Registrierung nicht akzeptiert',
    'MursionPortal.SortBy': 'Sortieren nach',
    'MursionPortal.Placeholder.SortBy': 'Sortieren nach',
    'Clients.Button.CreateClient': ' Kunde erstellen ',
    'Clients.CreateClient.Header.NewClient': 'Neuer Kunde',
    'Clients.CreateClient.Button.CreateClient': 'Kunde erstellen',
    'Clients.CreateClient.Label.ClientName': 'Kundenname',
    'Clients.CreateClient.Placeholder.CityCountry': 'Stadt, Land',
    'MursionPortal.Timezone': 'Zeitzone',
    'Clients.CreateClient.Label.Logo': 'Logo',
    'Clients.Text.NoClientsFound': 'Es wurden keine Kunden gefunden.',
    'MursionPortal.Text.Active': 'Aktiv',
    'MursionPortal.Text.Archived': 'Archiviert',
    'Clients.Modal.Button.BackToClientList': 'Zurück zur Kundenliste',
    'Clients.Modal.EmptyMessage.NoClientsFound': 'Keine Kunden gefunden',
    'Clients.Modal.Placeholder.EnterClientName': 'Kundenname eingeben',
    'Clients.Modal.Header.ClientsList': 'KUNDENLISTE',
    'Clients.Modal.Header.Client': 'KUNDE',
    'Clients.Modal.Header.AllClients': 'alle Kunden',
    'MursionPortal.Search': 'Suche',
    'Clients.Modal.RestoreConfirmText': 'Möchten Sie diesen Kunden wirklich wiederherstellen?',
    'Clients.Modal.DeleteConfirmText': 'Möchten Sie diesen Kunden wirklich archivieren?',
    'Clients.Modal.RelatedDataText': ' Alle zugehörigen Daten werden ebenfalls archiviert.',
    'Clients.Modal.DeleteErrorText': 'Für den aktuellen Kunden liegen ein oder mehrere Szenarien vor. Archivieren Sie die Projektszenarien, bevor Sie diesen Kunden archivieren.',
    'Clients.Button.RestoreClient': 'Kunden wiederherstellen',
    'Clients.IconButton.Cancel': 'abbrechen',
    'Clients.IconButton.Edit': 'bearbeiten',
    'Clients.IconButton.CancelEdit': 'Bearbeiten abbrechen',
    'Cleints.IconButton.EditScenarioName': 'Bezeichnung des Szenarios bearbeiten',
    'Cleints.IconButton.ApplyNameChange': 'Namensänderung übernehmen',
    'Clients.IconButton.Apply': 'übernehmen',
    'Clients.IconButton.EditClient': 'Kunde bearbeiten',
    'Clients.Button.ArchiveClient': 'Kunde archivieren',
    'Clients.Modal.Heading.Archived': '(Archiviert)',
    'Clients.Modal.Label.Name': 'Name:',
    'Clients.Modal.Placeholder.ClientName': 'Kundenname',
    'Clients.Modal.Label.Location': 'Standort:',
    'Clients.Modal.Label.Industry': 'Branche:',
    'Clients.Modal.Placeholder.SelectIndustry': 'Branche auswählen',
    'Clients.Modal.Label.Timezone': 'Zeitzone:',
    'Clients.Modal.Label.SchedulingByRequest': 'Planung auf Anfrage:',
    'MursionPortal.Enabled': 'Aktiviert',
    'MursionPortal.Disabled': 'Deaktiviert',
    'Clients.Modal.Label.SchedulingWindow': 'Planungsfenster:',
    'Clients.Modal.Label.HowFarInAdvance': 'Wie weit im Voraus sollten Kunden planen?',
    'MursionPortal.Days': 'Tage',
    'MursionPortal.Day': 'Tag',
    'Clients.Modal.Label.AvailableSharedSSOTitle': 'Die Liste der verfügbaren freigegebenen SSOs, die für die Anmeldung verwendet werden können',
    'Clients.Modal.Label.AvailableSharedSSOLabel': 'Verfügbare freigegebene SSOs:',
    'Clients.Modal.EmptySelectedMessage': 'Kein SSO ausgewählt',
    'Clients.Modal.EmptyListMessage': 'Kein SSO verfügbar',
    'Clients.Modal.SSOTitle': 'Verfügbares SSO auswählen',
    'Cleints.Modal.Label.AvailablePrivateSSOTitle': 'Die Liste der verfügbaren privaten SSOs, die für die Anmeldung verwendet werden können',
    'Clients.Modal.Label.AccountOwners': 'Kontoinhaber:',
    'Clients.Modal.AccountOwner.EmptyListMessage': 'Keine Kontoinhaber',
    'Clients.Modal.AccountOwner.EmptySelectedMessage': 'Kein ausgewählter Kontoinhaber',
    'Clients.Modal.AccountOwnersTitle': 'Kontoinhaber',
    'MursionPortal.Message.Updating': 'Wird aktualisiert …',
    'MursionPortal.Button.SaveClientInfo': 'Kundeninformationen speichern',
    'MursionPortal.Add': 'Hinzufügen ',
    'Clients.Modal.Label.EditSSO': 'SSO bearbeiten',
    'Clients.Modal.Label.Edit': 'bearbeiten',
    'MursionPortal.NumberOfFacilitators': 'Anzahl der Moderatoren',
    'MursionPortal.NumberOfLearners': 'Anzahl der Schulungsteilnehmer',
    'Clients.Modal.Button.CreateTeam': 'Team erstellen ',
    'MursionPortal.NewTeam': 'Neues Team',
    'MursionPortal.NoTags': 'Keine Schlagwörter',
    'MursionPortal.Header.Tags': 'Schlagwörter',
    'MursionPortal.AddTag': 'Schlagwort hinzufügen',
    'Clients.Modal.Teams.FacilitatorsEmptyListMessage': 'Keine ausgewählten Moderatoren',
    'Clients.Modal.Teams.LearnersEmptyListMessage': 'Keine ausgewählten Schulungsteilnehmer',
    'MursionPortal.Learners': 'Schulungsteilnehmer',
    'MursionPortal.Facilitator': 'Moderator',
    'MursionPortal.CreateTeam': 'Team erstellen',
    'Clients.Modal.Teams.ConfirmationText.RestoreTeam': 'Möchten Sie dieses Team wirklich wiederherstellen?',
    'Clients.Modal.Teams.ButtonTitle.RestoreTeam': 'Team wiederherstellen',
    'Clients.Modal.Teams.ConfirmationText.ArchiveTeam': 'Möchten Sie dieses Team wirklich archivieren?',
    'Clients.Modal.Teams.ButtonTitle.ArchiveTeam': 'Team archivieren',
    'Clients.Modal.Projects.Header.NewProject': 'Neues Projekt',
    'MursionPortal.ProjectName': 'Projektbezeichnung',
    'MursionPortal.Contracted': 'Vereinbart',
    'MursionPortal.CancellationWindow': 'Stornierungsfenster',
    'MursionPortal.SoftwareVersion': 'Softwareversion',
    'MursionPortal.Billing': 'Verrechnung',
    'Clients.Modal.Projects.Checkbox.NonBillable': 'Nicht fakturierbar',
    'MursionPortal.Recording': 'Aufzeichnung',
    'Clients.Modal.Projects.Checkbox.AllowRecording': 'Aufzeichnung zulassen',
    'Clients.Modal.Projects.Label.RecordedVideos': 'Aufgezeichnete Videos gehen zurück an:',
    'Clients.Modal.Projects.Checkbox.RecordingPermission': 'Die Erlaubnis zur Aufzeichnung wird vom Schulungsteilnehmer erteilt',
    'Clients.Modal.Projects.ButtonTitle.SaveProjectInfo': 'Projektinformationen speichern',
    'Clients.Modal.Projects.Header.ProjectManager': 'Projektmanager',
    'Clients.Modal.Projects.ProjectsEmptyListMessage': 'Keine ausgewählten Projektmanager',
    'MursionPortal.Header.Bracketed.Scenarios': 'Szenario/Szenarien',
    'Clients.Modal.Projects.Text.CompleteProjectInfoFirst': 'Vervollständigen Sie zuerst die Projektinformationen',
    'MursionPortal.Header.CreateScenario': 'Szenario erstellen',
    'Clients.Modal.Projects.ConfirmationText.RestoreProject': 'Möchten Sie dieses Projekt wirklich wiederherstellen?',
    'Clients.Modal.Projects.ButtonTitle.RestoreProject': 'Projekt wiederherstellen',
    'Clients.Modal.Projects.ButtonTitle.Edit': 'Projekt bearbeiten',
    'Clients.Modal.Projects.ButtonTitle.CancelEdit': 'Bearbeiten abbrechen',
    'Clients.Modal.Label.Cancel': 'abbrechen',
    'Clients.Modal.Projects.ConfirmationText.ArchiveProject': 'Möchten Sie dieses Projekt wirklich archivieren?',
    'Clients.Modal.Projects.ButtonTitle.ArchiveProject': 'Projekt archivieren',
    'Clients.Modal.Projects.ArchiveErrorMessage': 'Für das aktuelle Projekt liegen ein oder mehrere aktive Szenarien vor. Archivieren Sie die Projektszenarien, bevor Sie dieses Projekt archivieren.',
    'Clients.Modal.Projects.ButtonTitle.EditTags': 'Schlagwörter bearbeiten',
    'Clients.Modal.Projects.Label.UtilizedMissedSessions': 'Genutzte verpasste Sitzungen,',
    'Clients.Modal.Projects.Button.CreateNew': 'Neu erstellen ',
    'Clients.Modal.Projects.Button.RepeatClient': 'Kunde wiederholen',
    'Clients.Modal.Projects.Button.RepeatBank': 'Bank wiederholen',
    'Clients.Modal.Projects.ButtonTitle.CreateNewScenario': 'Neues Szenario erstellen',
    'Clients.Modal.Projects.ButtonTitle.RepeatClientScenario': 'Kundenszenario wiederholen',
    'Clients.Modal.Projects.ButtonTitle.ScenarioBank': 'Szenario aus Szenariobank erstellen',
    'Clients.Modal.Button.Schedule': 'Planen',
    'MursionPortal.Button.ActivateScenario': 'Szenario aktivieren',
    'MursionPortal.Button.ChangeScenario': 'Szenario ändern',
    'Clients.IconButton.Confirm': 'bestätigen',
    'Clients.IconButton.ConfirmEdit': 'Bearbeiten bestätigen',
    'Clients.Modal.Projects.HeaderText.ChooseScenario': 'Zu wiederholendes Szenario auswählen',
    'Clients.Modal.Projects.HeaderText.ChooseScenarioBank': 'Szenario aus Szenariobank auswählen',
    'MursionPortal.Placeholder.LowerCase.EnterScenarioName': 'Bezeichnung des Szenarios eingeben',
    'MursionPortal.IconButton.ResetToDefault': 'Auf Standardbezeichnung zurücksetzen',
    'MursionPortal.IconButton.Reset': 'zurücksetzen',
    'Clients.Modal.Header.CreatePlanning': 'Planung für das neue Szenario erstellen',
    'Clients.Modal.EmptyMessage.NoClientScenariosFound': 'Es wurden keine Kundenszenarien gefunden.',
    'Clients.Modal.EmptyMessage.NoBankScenariosFound': 'Es wurden keine Bankszenarien gefunden.',
    'Clients.Modal.Message.NoTeamsSpecified': 'Keine Teams angegeben',
    'Clients.Modal.EmptyMessage.NoSelectedTeams': 'Keine ausgewählten Teams',
    'Clients.Modal.Message.NoAssignedTeams': 'Dem Kunden wurden keine Teams zugeordnet',
    'Clients.Modal.Label.Certificate': 'Zertifikat',
    'Clients.Modal.Label.DeliveryMode': 'Erbringungsmodus',
    'Clients.Modal.Label.SessionSize': 'Sitzungsgröße',
    'Clients.Modal.Label.SessionLength': 'Dauer der Sitzung',
    'Clients.Modal.Label.Outcome': 'Ergebnis',
    'Clients.Modal.Label.Strategies': 'Strategien',
    'Clients.Modal.Label.Description': 'Beschreibung',
    'MursionPortal.Button.MoreInfo': 'Weitere Informationen …',
    'MursionPortal.Button.HideAdditionalInfo': 'Zusätzliche Informationen ausblenden …',
    'MursionPortal.Header.Timeline': 'Zeitachse',
    'MursionPortal.Header.Projects': 'Projekte ',
    'MursionPortal.Header.Sessions': 'Sitzungen',
    'MursionPortal.Header.Users': 'Benutzer',
    'MursionPortal.Text.Yes': 'ja',
    'MursionPortal.Text.No': 'nein',
    'MursionPortal.Button.Google': 'Google',
    'MursionPortal.Button.Outlook': 'Outlook, iCal, sonstige',
    'MursionPortal.Heading.GoogleCalendar': ' Google Kalender ',
    'MursionPortal.Heading.AddToCalendar': ' Zum Kalender hinzufügen ',
    'Dashboard.Legacy.GoogleCalendarList.Authorized': ' Falls Sie dem <code>{companyName}-Portal</code> noch keine <code>Berechtigung</code> zum  Hinzufügen von Veranstaltungen zu Ihrem Kalender erteilt haben, werden Sie nun dazu aufgefordert.',
    'Dashboard.Legacy.GoogleCalendarList.EventAdd': 'Die Veranstaltung wird nun <code>zu Ihrem Kalender hinzugefügt</code>. Sie können über das {companyName}-Portal dennoch darauf zugreifen.',
    'MursionPortal.Heading.OtherCalendars': 'Weitere Kalender',
    'Dashboard.Legacy.OtherCalendarList.Downloaded': 'Ihre Veranstaltung wird als <code>{companyName}</code> <code>heruntergeladen</code>',
    'Dashboard.Legacy.OtherCalendarList.SelectCalendar': '<code>Klicken Sie mit der rechten Maustaste darauf</code>, gehen Sie auf <code>„Öffnen mit“</code> und wählen Sie Ihre Kalenderanwendung aus.',
    'Dashboard.Legacy.OtherCalendarList.ImportWithinCalendar': '(Sie können auch in Ihrem Kalender den Befehl „Importieren“ nutzen und die heruntergeladene Datei importieren)',
    'Dashboard.Legacy.OtherCalendarList.AddedToCalendar': 'Die Veranstaltung wird nun <code>zu Ihrem Kalender hinzugefügt</code>. Sie können über das {companyName}-Portal dennoch darauf zugreifen',
    'MursionPortal.Text.NoLearnersAddedYet': 'Es wurden noch keine Schulungsteilnehmer hinzugefügt',
    'MursionPortal.Heading.Schedule': 'Planen',
    'MursionPortal.Heading.Users': 'BENUTZER',
    'MursionPortal.Heading.ProfServices': 'Profi-Services',
    'MursionPortal.Heading.SimSpecialist': 'Simulationsspezialisten',
    'MursionPortal.Heading.Clients': 'Kunden',
    'MursionPortal.Heading.Sessions': 'Sitzungen',
    'MursionPortal.Heading.Projects': 'Projekte',
    'MursionPortal.Heading.Teams': 'Teams',
    'MyMessages.Button.ArchiveAll': 'Alle archivieren',
    'MyMessages.Button.ArchivedMessages': 'Archivierte Nachrichten',
    'MyMessages.Button.ShowMore': 'Mehr anzeigen …',
    'MyMessages.Label.NewMessages': '<code>{newMessagesCount}</code> neue Nachrichten',
    'MyMessages.Label.NewMessage': '<code>{newMessagesCount}</code> neue Nachricht',
    'MyMessages.HoverTitle.InboxButton': 'Posteingang-Schaltfläche',
    'MyMessages.Text.InboxButton': 'Posteingang-Schaltfläche',
    'MyMessages.Text.HaveNewMessages': 'Sie haben {total} neue Nachrichten',
    'MyMessages.Text.HaveNewMessage': 'Sie haben eine neue Nachricht',
    'MursionPortal.CookiePolicy.Header.CookiePolicy': 'Cookie-Richtlinie',
    'MursionPortal.CookiePolicy.Text.MursionUsesEssentialCookiesOnly': 'Mursion verwendet nur essentielle Cookies, die für Ihre Sicherheit und den Betrieb unserer Webanwendung erforderlich sind. Wir verwenden keine Cookies, um personenbezogene Daten oder andere Informationen wie IP-Adressen oder Nutzungsdaten der Website zu sammeln.',
    'MursionPortal.CookiePolicy.Text.ForMoreInformation': ' Weitere Informationen finden Sie in unserer <code>Datenschutzerklärung</code>.',
    'MursionPortal.Button.Ok': 'OK',
    'MyProfile.Button.SaveChanges': 'Änderungen speichern',
    'MyProfile.Label.ExternalProfiles': 'Externe Profile',
    'MyProfile.Label.UserRoles': 'Benutzerrollen',
    'MyProfile.Label.UserRole': 'Benutzerrolle',
    'MyProfile.Heading.ReviewAccountInformation': 'Bitte überprüfen Sie Ihre Kontoinformationen',
    'MyProfile.HoverTitle.UnlinkProfile': 'Verknüpfung mit Ihrem {linkName}-Profil aufheben',
    'MyProfile.HoverTitle.LinkProfile': 'Verknüpfung mit Ihrem {linkName}-Profil erstellen',
    'MyProfile.Button.Unlink': 'Verknüpfung aufheben',
    'MyProfile.Text.UnlinkConfirmationText': 'Möchten Sie die Verknüpfung mit Ihrem {name}-Profil wirklich aufheben? Bitte beachten Sie, dass Sie dabei auch von {name} abgemeldet werden.',
    'MyProfile.Title.ProfileUnlink': 'Verknüpfung mit {name}-Profil aufheben',
    'MyProfile.Text.SuccessfullyUnlinkedProfile': 'Die Verknüpfung mit Ihrem {name}-Profil wurde erfolgreich aufgehoben',
    'MyProfile.Text.TimeZoneIsSetAutomatically': 'Ihre Zeitzone wird automatisch auf {getTimeZone} eingestellt. Klicken Sie auf „Bestätigen“, um diese Auswahl zu übernehmen, oder auf „Abbrechen“, um etwas anderes auszuwählen.',
    'MyProfile.Text.TimeZoneChange': 'Bei einer Änderung der Zeitzone überprüfen Sie bitte bestehende Veranstaltungen und Verfügbarkeiten.',
    'MyProfile.Text.ProfileTimeZoneNotMatchComputer': 'Die aktuelle Zeitzonenauswahl Ihres Profils stimmt nicht mit den Datums- und Uhrzeiteinstellungen Ihres Computers überein ({timeZone}).Klicken Sie auf „Änderungen speichern“, um dennoch fortzufahren oder wählen Sie eine andere Zeitzonenoption für Ihr Profil aus.',
    'MursionPortal.Button.MyProfile': 'Mein Profil',
    'MursionPortal.Button.LogOut': 'Abmelden',
    'MyProfile.Label.Language': 'Sprache',
    'MursionPortal.Label.Roles': 'Rollen',
    'MursionPortal.Label.Role': 'Rolle',
    'MursionPortal.Projects.Header.ScenarioType': 'Szenarioart',
    'MursionPortal.Projects.Header.Client': 'Kunde',
    'MursionPortal.Projects.Header.Project': 'Projekt',
    'MursionPortal.Projects.Header.Scenario': 'Szenario',
    'MursionPortal.Projects.Header.SimulationContent': 'Simulationsinhalt',
    'MursionPortal.Projects.Header.Date': 'Datum',
    'MursionPortal.Projects.Header.Time': 'Zeit',
    'MursionPortal.Projects.Header.Notes': 'Bemerkungen',
    'Filters.Date.Week': 'Woche',
    'Users.Table.Modal.SignLanguageInterpreter': 'Gebärdensprachdolmetscher',
    'Calendar.Button.Schedule': 'Planen',
    'Calendar.Button.Today': 'Heute',
    'MursionPortal.Availability': 'Verfügbarkeit',
    'Calendar.Button.Schedule.QualityAssurance': 'Qualitätssicherung',
    'MursionPortal.Training': 'Schulung',
    'Calendar.Button.Schedule.EmergencySession': 'Notfallsitzung',
    'Calendar.Button.Schedule.Session': 'Sitzung',
    'Calendar.Button.Schedule.BreakPrep': 'Pause/Vorbereitung',
    'MursionPortal.Month': 'Monat',
    'MursionPortal.Week': 'Woche',
    'Calendar.Heading.Calendars': 'Kalender',
    'Calendar.Tip.SelectACalendar': 'Wählen Sie einen Kalender aus, um die Verfügbarkeit anzuzeigen.',
    'Calendar.ScheduledHours.SumOfAllScheduledSessions': 'Summe aller geplanten Sitzungen in der aktuellen Ansicht',
    'MursionPortal.ShortLabel.Hours': 'Std.',
    'Calendar.ScheduledHours.SumOfAllTrainingSessions': 'Summe aller geplanten Schulungen in der aktuellen Ansicht',
    'Calendar.ScheduledHours.Trainings': 'Schulungen',
    'Calendar.ScheduledHours.SumOfRemainingAvailabilityHours': 'Summe aller verbleibenden SS-Verfügbarkeitsstunden in der aktuellen Ansicht',
    'Calendar.ScheduledHours.Available': 'Verfügbar',
    'MursionPortal.Scheduled': 'Geplant',
    'Calendar.Label.Display': 'Anzeige',
    'MursionPortal.Client': 'Kunde',
    'Calendar.Filter.Company': 'Unternehmen',
    'Calendar.Filter.SwapRequests': 'Tauschanfragen',
    'MursionPortal.QA': 'QS',
    'Calendar.SearchFilter.FilterProjects': 'Projektfilter',
    'Calendar.SearchFilter.FilterClients': 'Kunden filtern',
    'Calendar.SearchFilter.FilterSimSpecialists': 'Nach Simulationsspezialisten filtern',
    'Calendar.IndustrySelector.Options.SelectAnIndustry': 'Wählen Sie eine Branche aus',
    'Calendar.IndustrySelector.Options.AllIndustries': 'Alle Branchen',
    'Calendar.IndustrySelector.Options.NoIndustriesToSelect': 'Keine Branchen zur Auswahl',
    'MursionPortal.SelectAClient': 'Wählen Sie ein Zertifikat aus',
    'Calendar.ClientSelector.Options.AllClients': 'Alle Kunden',
    'Calendar.ClientSelector.Options.NoClientToSelect': 'Kein Client zur Auswahl',
    'MursionPortal.SelectACertificate': 'Wählen Sie ein Zertifikat aus',
    'Calendar.CertificateSelector.Options.AllCertificates': 'Alle Zertifikate anzeigen',
    'Calendar.CertificateSelector.Options.NoCertificatesToSelect': 'Keine Zertifikate zur Auswahl',
    'Calendar.ClientsList.NoClientsFound': 'Es wurden keine Kunden gefunden.',
    'MursionPortal.UncheckAll': 'Auswahl aufheben',
    'MursionPortal.CheckAll': 'Alle markieren',
    'Calendar.SimSpecialistList.NoSimSpecialistsFound': 'Es wurden keine Simulationsspezialisten gefunden.',
    'Calendar.ProjectsList.CloseProjectOverview': 'Projektübersicht schließen',
    'Calendar.ProjectsList.AllProjects': 'ALLE PROJEKTE',
    'MursionPortal.Bracketed.Scenarios': 'Szenario/Szenarien',
    'Calendar.ProjectsList.Overview': 'Übersicht',
    'Calendar.ProjectsList.CloseClientOverview': 'Kundenübersicht schließen',
    'MursionPortal.Scenario': 'Szenario',
    'Calendar.ScenariosList.NoScenarios': 'Keine Szenarien',
    'MursionPortal.Delivery': 'Durchführung',
    'MursionPortal.Mode': 'Modus',
    'MursionPortal.Timeline': 'Zeitrahmen',
    'Calendar.ScenariosList.Description': 'Beschreibung',
    'MursionPortal.Outcome': 'Ergebnis',
    'MursionPortal.Strategies': 'Strategien',
    'MursionPortal.ContentBundle': 'Inhaltspaket',
    'MursionPortal.SimulationEnvironments': 'Simulationsumgebung(en)',
    'MursionPortal.AvatarsInSimulations': 'Avatar(e) in Simulation(en)',
    'Calendar.LearnersList.SessionNotScheduled': 'Sitzung nicht geplant',
    'Calendar.LearnersList.SessionScheduled': 'Sitzung geplant',
    'Calendar.LearnersList.SessionComplete': 'Sitzung abgeschlossen',
    'Calendar.LearnersList.Learner': '{learnersCount} Schulungsteilnehmer',
    'Calendar.LearnersList.Learners': '{learnersCount} Schulungsteilnehmer',
    'Calendar.Button.CantSchedule': 'Kann nicht planen',
    'Calendar.Button.ScheduleEvent': 'Veranstaltungs-Zeitplan',
    'Calendar.MainPanel.ConfirmText.AreYouSure': 'Möchten Sie diese Veranstaltung wirklich absagen?',
    'Calendar.MainPanel.CancelEvent': 'Veranstaltung absagen',
    'Calendar.MainPanel.UpdateEvent': 'Veranstaltung aktualisieren',
    'MursionPortal.Submit': 'Absenden',
    'Calendar.MainPanel.NewEvent': 'Neue Veranstaltung',
    'MursionPortal.Project': 'Projekt',
    'MursionPortal.Button.Reload': 'Erneut laden',
    'Clients.Modal.Label.FacilitatorVisibility': 'Moderatoransicht:',
    'Clients.Modal.Placeholder.FacilitatorVisibility': 'Moderatoransicht wählen',
    'Clients.Modal.Projects.ConfirmationText.EditProject': 'Möchten Sie dieses Projekt wirklich bearbeiten?',
    'Projects.ScenarioList.Legend.ScenariosStatusTab': 'Registerkarten für den Status von Szenarien',
    'Projects.ScenarioList.Legend.CreateNew': 'Szenarien-Schaltflächen für „neu erstellen“, „Kunden wiederholen“, „Bank wiederholen“',
    'MursionPortal.ScenarioCard.Tab.Legend.ViewModeTab': 'Anzeigemodus anzeigen',
    'MursionPortal.SearchPlaceHolder.SearchClient': 'Kunden suchen …',
    'Settings.SSO.Modal.AddButton.SearchPlaceHolder.SearchTeam': 'Team auswählen …',
    'Settings.SSO.Modal.Button.ChangeLabel': '{label} ändern',
    'MursionPortal.Label.Yes': 'Ja',
    'MursionPortal.Label.No': 'Nein',
    'Settings.SSO.Modal.AddButton.PlaceHolder.NoOptions': 'Keine Optionen',
    'Settings.SSO.Modal.ClientName': 'Kundenname',
    'MursionPortal.Modal.Button.Add': 'Hinzufügen',
    'MursionPortal.Modal.Button.Remove': 'Entfernen',
    'Users.Table.Hover.ViewEditAcceptedUsers': 'Akzeptierte Benutzer anzeigen/bearbeiten',
    'Users.Table.Hover.ViewEditUnacceptedUsers': 'Nicht akzeptierte Benutzer anzeigen/bearbeiten',
    'MursionPortal.Label.Industry': 'Branche',
    'MursionPortal.Label.Timezone': 'Zeitzone',
    'MursionPortal.Header.Client': 'Kunde',
    'MursionPortal.FilterByCertificate': 'Nach Zertifikat filtern',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Header.CreateQAEvent': 'QA-Ereignis erstellen',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.TimeBlock': 'Zeitblock/Zeitblöcke',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Participants': 'Teilnehmer',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Button.SubmitNewEvent': 'Neue Veranstaltung einreichen',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockOutsideRange': 'Der Zeitblock liegt außerhalb des verfügbaren Bereichs',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockBeforeCurrentTime': 'Der Zeitblock liegt vor der aktuellen Zeit',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Start': 'Start',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.End': 'Ende',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Length': 'Länge',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.NoAvailableTimeBlock': 'Für den ausgewählten Tag ist kein Zeitblock verfügbar.',
    'Calendar.Modal.Button.Client': 'Kunde',
    'Calendar.Modal.Button.Project': 'Projekt',
    'Calendar.Modal.Button.Scenario': 'Szenario',
    'Calendar.Modal.Header.CompanyEvents': 'Unternehmensveranstaltungen',
    'MursionPortal.Header.Session': 'Sitzung',
    'MursionPortal.Header.Events': 'Veranstaltungen',
    'MursionPortal.Header.Event': 'Veranstaltung',
    'Calendar.Modal.Label.Time': 'Zeit',
    'Calendar.Label.SelectTimeZone': 'Zeitzone auswählen',
    'Calendar.Text.Training': 'Schulung',
    'Calendar.Text.QA': 'QS',
    'Calendar.Modal.Text.ConfirmDeleteTime': 'Bestätigen Sie das Löschen des Zeitblocks',
    'Calendar.Button.NewTimeBlock': 'Neuer Zeitblock ',
    'Calendar.Modal.Label.Capitalized.Time': 'ZEIT',
    'Calendar.Modal.Label.Participants': 'TEILNEHMER',
    'Calendar.Modal.Text.NoParticipants': 'Keine Teilnehmer',
    'Calendar.Modal.Label.SimulationSpecialist': 'SIMULATIONSSPEZIALIST',
    'Calendar.Modal.Label.MeetingOrganizer': 'MEETING-VERANSTALTER',
    'Calendar.Error.ErrorPrefixMustBeBetween': '{errorPrefix} muss zwischen {duration1} und {duration2} liegen.',
    'Calendar.Error.SelectedTimeBlockOverlapsExistingTime': 'Der ausgewählte Zeitblock überschneidet sich mit dem vorhandenen Zeitblock.',
    'Calendar.Error.SelectedTimeBlockOverlapsUnavailableTime': 'Der ausgewählte Zeitblock überschneidet sich mit dem nicht verfügbaren Zeitintervall.',
    'Calendar.Error.SelectedTimeBlockIsNotMultipleOfEventGap': 'Der ausgewählte Zeitblock ist kein Multiplikator der Veranstaltungslücke ({duration})',
    'Calendar.Text.AvailabilityUser': 'Verfügbarkeit von {user}',
    'Calendar.Text.AvailabilityTime': 'Verfügbarkeit von {time}',
    'Calendar.Text.AvailabilityDurationAvailabilityTime': '{availabilityDuration} Verfügbarkeit {time}',
    'Calendar.Text.TrainingsTime': '{eventsCount} Schulungen {time}',
    'Calendar.Text.SessionsTime': '{eventsCount} Sitzungen {time}',
    'Calendar.Text.AvailabilityName': 'Verfügbarkeit von {name}',
    'Calendar.Label.Learners': 'Schulungsteilnehmer',
    'Calendar.Text.FullAvailability': 'Volle Verfügbarkeit',
    'Calendar.Text.YourAvailability': 'Ihre Verfügbarkeit',
    'Calendar.Text.Event': 'Veranstaltung',
    'Calendar.Header.Trainings': 'Schulungen',
    'Calendar.Header.CompanyEvents': 'Unternehmensveranstaltungen',
    'Calendar.Header.Session': 'Sitzung',
    'Calendar.Error.TimeBlockIsOutsideAvailableRange': 'Der Zeitblock liegt außerhalb des verfügbaren Bereichs',
    'Calendar.Error.TimeBlockIsBeforeCurrentTime': 'Der Zeitblock liegt vor der aktuellen Zeit',
    'Calendar.Label.Break': 'Pause',
    'Calendar.Label.Prep': 'Vorbereitung',
    'Calendar.Modal.Header.CreateBreakPrepEvent': 'Ereignis für Pause/Vorbereitung erstellen',
    'Calendar.Modal.Label.EventType': 'Veranstaltungsart',
    'Calendar.Modal.Label.TimeBlocks': 'Zeitblock/Zeitblöcke',
    'Calendar.Text.NoTimeBlocksInSelectedRange': 'Keine verfügbaren Zeitblöcke für den ausgewählten Zeitraum',
    'Calendar.Text.NoAvailableTimeBlocks': 'Keine verfügbaren Zeitblöcke',
    'Calendar.Text.SelectingThisTimeBlock': 'Wenn Sie diesen Zeitblock auswählen, fordern Sie eine derzeit nicht verfügbare Sitzungszeit an. Wir tun unser Bestes, um Ihren Wunschtermin zu ermöglichen. Schieben Sie den folgenden Schalter auf „Aus“, um nur aus Zeitblöcken zu wählen, deren Verfügbarkeit bestätigt ist.',
    'Calendar.ToolTip.SlideToggleOff': 'Schieben Sie den Schalter auf „Aus“, um nur aus verfügbaren Zeitblöcken auszuwählen.',
    'Calendar.ToolTip.SlideToggleOn': 'Schieben Sie den Schalter auf „Ein“, um einen zusätzlichen Zeitblock anzufordern.',
    'Calendar.Label.SelectAnyTimeBlock': 'Wählen Sie einen beliebigen Zeitblock',
    'Users.CreateUser.Label.GoogleMeetID': 'Google-Meet-ID',
    'Users.CreateUser.GoogleMeetId.Placeholder.GoogleMeetID': 'Google-Meet-ID',
    'Users.CreateUser.GoogleMeetID.Hover': 'Bitte geben Sie eine Google-Meet-ID ein',
    'Setting.Edit.Modal.Error.UnableToCreateSession': 'Sitzung für die ausgewählte Zeit {formattedStartTime} – {formattedEndTime} kann nicht erstellt werden',
    'Session.Header.Session': 'Sitzung',
    'Session.Header.AcceptSessionRequest': 'Bitte bestätigen Sie, ob Sie diese Sitzungsanfrage annehmen möchten, und fügen Sie sie Ihrem Zeitplan hinzu.',
    'CommonComponent.RecurrenceForm.Label.UntilDate': 'Bis Datum',
    'CommonComponent.RecurrenceForm.Label.Period': 'Zeitraum',
    'CommonComponent.RecurrenceForm.Option.Daily': 'Täglich',
    'CommonComponent.RecurrenceForm.Option.Weekly': 'Wöchentlich',
    'CommonComponent.RecurrenceForm.Label.Weekday': 'Wochentag',
    'CommonComponent.RecurrenceForm.Text.NoSelectedWeekdays': 'Keine ausgewählten Wochentage',
    'CommonComponent.RecurrenceForm.Error.PleaseSelectWeekdays': 'Bitte wählen Sie den/die Wochentag(e)',
    'Calendar.EventType.Availability': 'Verfügbarkeit',
    'Calendar.EventType.Session': 'Sitzung',
    'Calendar.EventType.Training': 'Schulung',
    'Calendar.EventType.CompanyEvent': 'Unternehmensveranstaltung',
    'Calendar.EventType.NewEvent': 'Neue Veranstaltung',
    'Calendar.Text.NewEventTime': 'Neue Veranstaltung {time}',
    'Calendar.Header.Training': 'Schulung',
    'Calendar.Modal.Text.Sessions': 'Sitzungen',
    'Calendar.Modal.Text.Trainings': 'Schulungen',
    'Calendar.Modal.Text.Events': 'Veranstaltungen',
    'Calendar.Modal.Text.PendingNoSS': '– Ausstehend (keine SS).',
    'Calendar.Modal.Text.PendingSwap': '– Ausstehend (Tausch).',
    'Calendar.Modal.Text.PreBooked': '– Vorgebucht.',
    'Calendar.Modal.Text.NeedsReview': '– Prüfung erforderlich.',
    'Calendar.Modal.Text.JoinSession': '– Sie können an der Sitzung teilnehmen.',
    'Calendar.Modal.Text.NoLearnersInSession': '– Es sind keine Schulungsteilnehmer in der Sitzung.',
    'Calendar.Modal.Text.NoLearnersInSessionAfterBookedTime': '– Nach der gebuchten Zeit sind keine Schulungsteilnehmer in der Sitzung.',
    'Calendar.Modal.Header.Events': 'Veranstaltungen',
    'Calendar.Modal.Header.Session': 'Sitzung',
    'Calendar.Modal.Header.Training': 'Schulung',
    'Calendar.Modal.Header.Availability': 'Verfügbarkeit',
    'Calendar.Modal.Filter.Client': 'Kunde',
    'Calendar.Modal.Filter.Project': 'Projekt',
    'Calendar.Modal.Filter.Time': 'Zeit',
    'Calendar.Modal.Label.Recurrence': 'Wiederholung',
    'Calendar.Modal.Header.CreateAvailability': 'Verfügbarkeit erstellen',
    'Calendar.Modal.Text.Recurring': 'Wiederkehrend',
    'Calendar.SimSpecialistButton.Modal.Header.Scheduled': 'Geplant',
    'Calendar.SimSpecialistButton.Modal.Header.ThisPeriod': '(dieser Zeitraum)',
    'Calendar.SimSpecialistButton.Modal.Label.Sessions': 'Sitzungen',
    'Calendar.SimSpecialistButton.Modal.Label.Hours': 'Stunden',
    'Calendar.SimSpecialistButton.Modal.Label.Trainings': 'Schulungen',
    'Calendar.SimSpecialistButton.Modal.Label.TrainingsHours': 'Schulungsstunden',
    'Calendar.SimSpecialistButton.Modal.Label.Available': 'Verfügbar',
    'Calendar.SimSpecialistButton.Modal.Header.Certification': 'Zertifizierung',
    'Calendar.SimSpecialistButton.Modal.Text.NoCertificates': 'Keine Zertifikate',
    'Calendar.SimSpecialistButton.Modal.Header.SimSpecialistOverviewPanel': 'Simulationsspezialisten-Übersicht',
    'Calendar.SimSpecialistButton.Modal.HoverTitle.CloseSimSpecialistOverview': 'Simulationsspezialisten-Übersicht schließen',
    'MursionPortal.DeliveryMode.OneToOne': '1:1',
    'MursionPortal.DeliveryMode.OneToManyRemote': 'Remote',
    'MursionPortal.DeliveryMode.OneToManyLocal': 'Lokal (Fishbowl)',
    'MursionPortal.DeliveryMode.Group': 'Gruppendurchführung',
    'MursionPortal.Projects.Header.Trainees': 'Trainees',
    'Projects.Placeholder.SimContentName': 'Namen des Simulationsinhalts eingeben',
    'Projects.Message.NoSimulationContentFound': 'Kein Simulationsinhalt gefunden',
    'MursionPortal.Button.UncheckAll': 'Auswahl aufheben',
    'MursionPortal.Button.CheckAll': 'Alle markieren',
    'Projects.Header.NewScenario': 'Projekt {projectName}',
    'MursionPortal.Button.SaveAsDraft': 'Als Entwurf speichern',
    'MursionPortal.Button.Save': 'Speichern',
    'Projects.Message.SelectEnvironmentFirst': 'Wählen Sie zuerst eine Umgebung aus',
    'Projects.Message.LoadingAvatarsList': 'Avatar-Liste wird geladen …',
    'Projects.Message.NoAssignedAvatars': 'Der ausgewählten Umgebung sind keine Avatare zugewiesen',
    'Projetcs.EmptyMessage.NoSelectedAvatars': 'Keine ausgewählten Avatare',
    'Projects.Avatars.NoAvatarsSpecified': 'Keine Avatare angegeben',
    'Projects.Message.SelectIndustryFirst': 'Wählen Sie zuerst eine Branche aus',
    'Projects.Message.LoadingEnvironmentsList': 'Liste der Umgebungen beim Ladevorgang …',
    'Projects.Message.NoAssignedEnvironments': 'Die ausgewählte Branche hat keine zugewiesenen Umgebungen',
    'Projects.Message.ScenarioHasNoAssignedEnvironment': 'Dem Szenario ist keine Umgebung zugewiesen',
    'Projects.AltText.EnvironmentImage': 'Umgebungs-Bild',
    'Projects.Placeholder.SelectAnEnviroment': 'Umgebungs-ID auswählen',
    'Projects.Message.UnnamedIndustry': 'Unbenannte Industrie',
    'Projects.Placeholder.SelectAnIndustry': 'Wählen Sie eine Branche aus',
    'Projects.Header.NewProject': 'Neues Projekt',
    'Projects.AltText.ClientLogo': 'Kundenlogo',
    'Projects.Message.SelectAnyTimeBlock': 'Wählen Sie einen beliebigen Zeitblock',
    'Projects.Message.SelectScenarioFirst': 'Bitte wählen Sie zuerst das Szenario aus',
    'MursionPortal.Text.Asset': 'Asset V2',
    'MursionPortal.Button.Accept': 'Annehmen',
    'Projects.Modal.EmptyMessage.NoLearner': 'Kein Schulungsteilnehmer',
    'MursionPortal.Label.SelectAll': 'Alle auswählen',
    'Projects.Modal.Placeholder.ChooseATeam': 'Team auswählen',
    'MursionPortal.Placeholder.SearchTeams': 'Teams suchen …',
    'Projects.Modal.Text.SessionSize': 'Die zulässige Sitzungsgröße beträgt {maxLearners}. Bestimmte Schulungsteilnehmer auswählen',
    'Projects.Modal.Placeholder.SearchLearners': 'Schulungsteilnehmer suchen',
    'MursionPortal.ErrorMessage.MaxLearners': 'Sie können für diese Art von Sitzung nur maximal {maxLearners} Schulungsteilnehmer hinzufügen',
    'MursionPortal.ErrorMessage.MaxLearner': 'Sie können für diese Art von Sitzung nur maximal {maxLearners} Schulungsteilnehmer hinzufügen.',
    'MursionPortal.Label.ChooseMembers': 'Wählen Sie Mitglieder',
    'MursionPortal.Message.NoMembersToSelect': 'Keine Mitglieder zur Auswahl',
    'MursionPortal.Message.PleaseSelectATeamFirst': 'Bitte wählen Sie zuerst ein Team aus',
    'AssetSync.Header.AssetSync': 'Asset-Synchronisierung',
    'AssetSync.Text.AssetsSyncedSuccessfully': 'Assets wurden erfolgreich synchronisiert',
    'AssetSync.Button.Sync': 'Synchronisieren',
    'AssetSync.Button.TryAgain': 'Erneut versuchen',
    'AssetSync.SyncButton.Text.Syncing': 'Synchronisierung läuft',
    'SessionFlow.Label.SessionExport': 'Sitzungsexport',
    'MursionPortal.Button.Export': 'Exportieren',
    'SessionFlow.Label.SessionFlowData': 'Sitzungsablaufdaten',
    'SessionFlow.Button.LoadSessionData': 'Sitzungsdaten laden',
    'SessionFlow.Button.StartSessionAnalytics': 'Sitzungsanalyse starten',
    'SessionFlow.Label.Checklist': 'Checkliste',
    'SessionFlow.Label.RecordingSettings': 'Aufnahmeeinstellungen',
    'SessionFlow.Label.SessionVideoFact': 'Sitzungsvideo-Fakten',
    'SessionFlow.Label.UserStatus': 'Teilnehmer Status',
    'SessionFlow.SessionVideoFact.ColumnHeader.VideoAllowed': 'Video erlaubt',
    'MursionPortal.Table.ColumnHeader.UserName': 'Benutzername',
    'MursionPortal.Table.ColumnHeader.Email': 'E-Mail-Adresse',
    'MursionPortal.Table.ColumnHeader.Status': 'Status',
    'SessionFlow.StartSessionAnalyticsButton.Text.EventStartingAnalytics': 'Die Veranstaltung zum Starten des Analyseprozesses wurde erfolgreich veröffentlicht',
    'SessionFlow.RecordingSettings.Label.VideoRecording': 'Videoaufzeichnung',
    'SessionFlow.RecordingSettings.Text.Allowed': 'Erlaubt',
    'SessionFlow.RecordingSettings.Text.Disallowed': 'Nicht erlaubt',
    'SessionFlow.RecordingSettings.Label.RecordingPermission': 'Erlaubnis zur Aufzeichnung',
    'SessionFlow.RecordingSettings.Text.LiesWithLearner': 'Vom Schulungsteilnehmer erteilt',
    'SessionFlow.RecordingSettings.Label.RecordedVideosGoBackTo': 'Aufgezeichnete Videos gehen zurück an',
    'SessionFlow.Error.CannotFindSession': 'Kann keine Sitzung mit der angegebenen ID finden',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Type': 'Typ',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Date': 'Datum',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Error': 'Fehler',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Payload': 'Payload',
    'SessionFlow.SessionFlowData.PlaceHolder.SessionID': 'Sitzungs-ID',
    'SessionFlow.Button.HideDetailedEventLog': 'Detailliertes Ereignisprotokoll ausblenden …',
    'SessionFlow.Button.ShowDetailedEventLog': 'Detailliertes Ereignisprotokoll anzeigen …',
    'SessionFlow.Button.HideSessionHistory': 'Sitzungsverlauf ausblenden …',
    'SessionFlow.Button.ShowSessionHistory': 'Sitzungsverlauf anzeigen …',
    'SessionFlow.Text.NoEvents': 'Keine Veranstaltungen',
    'SessionFlow.SessionVideoFact.Label.VideoExists': 'Video existiert',
    'SessionFlow.Text.NoLearners': 'Keine Schulungsteilnehmer',
    'SessionFlow.Checklist.DataRecording': 'Datenspeicherung',
    'SessionFlow.Checklist.DataUploading': 'Daten hochladen',
    'SessionFlow.Checklist.Analytics': 'Analytik',
    'Companies.ArchiveIconButton.Modal.ConfirmationText.ArchiveCompany': 'Sind Sie sicher, dass Sie dieses Unternehmen archivieren möchten?',
    'Companies.ArchiveIconButton.Modal.Header.ArchiveCompany': 'Unternehmen archivieren?',
    'Companies.Label.CompanyInfo': 'Information über das Unternehmen',
    'Companies.CompanyInfo.Archived': '(Archiviert)',
    'Companies.CancelIconButton.HoverTitle.CancelEdit': 'Bearbeiten abbrechen',
    'Companies.EditIconButton.HoverTitle.EditCompany': 'Unternehmung bearbeiten',
    'Companies.Button.InviteProfService': 'Prof-Service einladen',
    'Companies.InviteProfServiceButton.Modal.Header.NewProfService': 'Neuer Prof-Service',
    'Companies.Label.Config': 'KONFIGURATION',
    'Companies.EditIconButton.HoverTitle.EditConfig': 'Konfiguration bearbeiten',
    'Companies.Config.Button.Test': 'Test',
    'Companies.Config.Button.SaveCompanyConfig': 'Unternehmenskonfiguration speichern',
    'Companies.CompanyInfo.Button.SaveCompanyInfo': 'Unternehmensinformationen speichern',
    'Companies.ValidationError': 'Es gibt Prüffehler',
    'Companies.Config.SaveCompanyConfigButton.FailedToSaveConfig': 'Fehler beim Speichern einer Konfiguration Bitte beheben Sie die Fehler',
    'Companies.Config.Text.TestPassedSuccessfully': 'Test erfolgreich bestanden',
    'Companies.CreateUserButton.PlaceHolder.CompanyName': 'Unternehmensname',
    'Companies.Button.CreateCompany': 'Unternehmen erstellen ',
    'Companies.Error.NoCompanies': 'Keine Unternehmen',
    'Companies.InviteProfServiceButton.Modal.Label.ProfServices': 'Profi-Services',
    'Companies.InviteProfServiceButton.Modal.Button.AddUser': 'Benutzer hinzufügen ',
    'Companies.CreateUserButton.Modal.Header.NewCompany': 'Neues Unternehmen',
    'Companies.Header.AllCompanies': 'alle Unternehmen',
    'MursionPortal.Roles': 'Rollen',
    'Users.ArchiveButton.Modal.Header.ArchivedGlobalUser': 'Archivierter globaler Benutzer',
    'Users.ActiveButton.Modal.Header.EditGlobalUser': 'Globalen Benutzer bearbeiten',
    'Users.CreateUserButton.Modal.Header.NewGlobalUser': 'Neuer globaler Benutzer',
    'Users.NoGlobalUserFoundError': 'Es wurden keine globalen Benutzer gefunden.',
    'Users.Table.ColumnHeader.Department': 'Abteilung',
    'MursionPortal.TechSupport.HoverTitle.ShowFeedBack': 'Feedback anzeigen',
    'MursionPortal.TechSupport.HoverTitle.HideFeedBack': 'Feedback verbergen',
    'MursionPortal.TechSupport': 'Technischer Support',
    'MursionPortal.TechSupport.Header.ContactSupport': 'Kontakt zum Support aufnehmen',
    'MursionPortal.TechSupport.Label.Phone': 'Telefonnummer',
    'MursionPortal.TechSupport.Label.Email': 'E-Mail-Adresse',
    'MursionPortal.TechSupport.Label.Hours': 'Stunden',
    'MursionPortal.TechSupport.Link.MursionSupport': 'Mursion Support',
    'Downloads.Text.ClickDownloadButton': 'Klicken Sie auf den Download-Button unten, um die neueste Version des Installationsprogramms zu erhalten. Diese ausführbare Datei wird in Ihrem Standardspeicherort für Browser-Downloads (z. B. Download-Ordner) gespeichert.',
    'Downloads.Text.OnceDownloadIsComplete': 'Klicken Sie nach Abschluss des Downloads auf die heruntergeladene ausführbare Datei und befolgen Sie die Anweisungen auf dem Bildschirm, um die Installation abzuschließen.',
    'Downloads.Text.AppInstalled': 'Wenn die App installiert ist, können Sie beim nächsten Start auf das Mursion-Symbol doppelklicken.',
    'Downloads.Button.Download': 'Herunterladen',
    'Downloads.Text.PlatformNotCurrentlySupported': 'Es tut uns leid, aber Ihre Plattform wird derzeit nicht unterstützt.',
    'Downloads.Text.FollowOnScreenInstructions': 'Klicken Sie auf {info} und folgen Sie den Anweisungen auf dem Bildschirm, um die Anwendung zu installieren',
    'Downloads.Text.ShortcutCreatedOnInstallationComplete': 'Nach Abschluss der Installation wird auf Ihrem Desktop eine Verknüpfung zu Mursion erstellt. {lineBreak} Sie können auf die Verknüpfung doppelklicken, {image} wenn Sie die Anwendung starten müssen, um eine geplante Simulation durchzuführen.',
    'Filters.Date.LastWeek': 'Letzte Woche',
    'MursionPortal.Text.Mins': 'Min.',
    'MursionPortal.Text.Min': 'Min.',
    'Tutorial.Header.ManageYourTeams': 'Verwalten Sie Ihre Teams',
    'MursionPortal.Button.Awesome': 'Großartig',
    'Tutorial.Header.ReviewYourProjects': 'Überprüfen Sie Ihre Projekte',
    'MursionPortal.Button.LooksGood': 'Sieht gut aus',
    'Tutorial.Header.LearnerScheduling': 'Schulungsteilnehmer-Planung',
    'MursionPortal.Button.AllDone': 'Alles erledigt!',
    'Tutorial.Header.InviteYourUsers': 'Laden Sie Ihre Benutzer ein',
    'MursionPortal.Button.GotIt': 'Verstanden',
    'Tutorial.Header.CreateATeam': 'Team erstellen',
    'Tutorial.Header.ManageYourProjects': 'Ihre Projekte verwalten',
    'Tutorial.Header.YourTeams': 'Ihre Teams',
    'Tutorial.Header.YourProjects': 'Ihre Projekte',
    'Tutorial.Header.SessionCalendar': 'Sitzungskalender',
    'Tutorial.Tooltip.TeamsOrganize': 'Teams dienen zur Organisation Ihrer Schulungsteilnehmer und werden zu Szenarien hinzugefügt',
    'Tutorial.Text.ViewSessions': 'Zeigen Sie die <code>bevorstehenden und abgeschlossenen</code> Sitzungen des Teams an',
    'Tutorial.Tooltip.ContactManager': 'oder wenden Sie sich an Ihren {companyName} Projektmanager',
    'Tutorial.Tooltip.YourPointOfContact': 'Ihr Ansprechpartner für alles zum Thema Schulung',
    'Tutorial.Text.TeamAdded': 'Einem Team wird <code>zu einem Szenario hinzugefügt,</code> um mit der Planung zu beginnen.',
    'Tutorial.Text.ViewLearners': 'Anzeigen von Schulungsteilnehmern\' <code>Planen von Aktivitäten</code> und <code>Status</code>',
    'Tutorial.Text.EnterEmail': 'Geben Sie eine <code>E-Mail-Adresse</code> für jeden neuen Portal-Benutzer von {companyName} ein',
    'Tutorial.Text.RegistrationEmail': 'Eine <code>Registrierungs-E-Mail</code> wird an den Posteingang gesendet',
    'Tutorial.Text.TeamsOrganize': 'Teams <code>organisieren Ihre Schulungsteilnehmer</code> und werden von einem Moderator verwaltet',
    'Tutorial.Text.UniqueName': 'Geben Sie Ihrem Team einen <code>eindeutigen Namen</code> und geben Sie einen Ort ein’,',
    'Tutorial.Text.SessionDelivery': 'Später wird ein Team für <code>die Sitzungsdurchführung</code> zu einem <code>Szenario hinzugefügt</code>',
    'Tutorial.Tooltip.Description': 'Beschreibung, Ergebnis und Strategien',
    'Tutorial.Text.BeginScheduling': 'Ein Team muss <code>zu einem Szenario hinzugefügt werden,</code> um mit der Planung zu beginnen.',
    'Tutorial.Text.MonitorSessions': 'Überwachen Sie die <code>bevorstehenden Sitzungen</code> und die <code> Verfügbarkeit</code> von jedem Projekt',
    'Tutorial.Text.LearnersWithoutSessions': 'Schulungsteilnehmer anzeigen, für die <code>noch keine Sitzung geplant wurde</code>',
    'Tutorial.Tooltip.LearnersSchedule': 'Die Schulungsteilnehmer können auch selbst Sitzungen für sich planen',
    'Tutorial.Text.AddedToLearners': 'Sie werden zu <code>einem oder mehreren</code> Lernteams hinzugefügt',
    'Tutorial.Tooltip.TeamAdmin': 'Ihr Teamadministrator und Ansprechpartner',
    'Tutorial.Text.TeamAddedBeforeProject': 'Ihr Team wird <code>zu einem Szenario hinzugefügt,</code> bevor das Projekt beginnt',
    'Tutorial.Text.AddRemoveLearners': '<code>Hinzufügen oder Entfernen</code> von Schulungsteilnehmern von Ihren <tooltip>Teams</tooltip>',
    'Tutorial.Text.AddedByCompany': 'Sie werden von Ihrem <tooltip>Kontoinhaber</tooltip> zu einem Szenario/Projekt hinzugefügt',
    'Tutorial.Text.ViewYourHours': 'Zeigen Sie Ihre Stunden, Szenarien an oder wenden Sie sich an Ihren <tooltip><code>Projektmanager</code></tooltip>',
    'Tutorial.Text.ScenariosDetail': 'Szenarien enthalten die <tooltip>Details</tooltip>, die Ihre <code>{companyName} Erfahrung bestimmen</code>',
    'Tutorial.Text.SelectTimeSlot': 'Wählen Sie ein verfügbares Zeitfenster aus, um <tooltip><code>einen Schulungsteilnehmer für eine Sitzung einzuplanen</code></tooltip>',
    'Tutorial.Text.ChooseRole': 'Wählen Sie eine Rolle (<tooltip>Kontoinhaber</tooltip> <secondTooltip>Veranstalter</secondTooltip> oder <thirdTooltip>Schulungsteilnehmer</thirdTooltip>) für jeden neuen Nutzer aus',
    'Tutorial.Tooltip.AdminManages': 'Administrator, der Nutzer und Projekte verwaltet',
    'Tutorial.Tooltip.ManagesScheduling': 'Verwaltet die Planung und Sitzungsdurchführung',
    'Tutorial.Tooltip.SchedulesSessions': 'Plant und nimmt an Sitzungen teil',
    'Tutorial.Text.ScenariosContain': 'Szenarien enthalten die <tooltip>Details</tooltip>, die Ihre <code>{companyName} Erfahrung bestimmen</code>',
    'Tutorial.Text.SelectOpenTimeSlot': 'Wählen Sie ein offenes Zeitfenster aus, um <tooltip><code>einen Schulungsteilnehmer einzuplanen</code></tooltip>',
    'Tutorial.Text.AdminOfTeam': 'Der <tooltip>Moderator</tooltip> ist der Administrator und Ansprechpartner für Ihr Team',
    'Tutorial.Text.ProjectsContain': 'Projekte enthalten <code>Szenarien</code> mit <tooltip>Details</tooltip>, die Ihre <code>Lernerfahrung</code>bestimmen',
    'Tutorial.Text.ViewAvailability': 'Verfügbarkeit anzeigen und <code>Sitzungen planen</code>',
    'Tutorial.Text.TrackSessions': 'Verfolgen Sie <code>anstehende Sitzungen</code> für jedes Projekt. Bei Bedarf absagen/neu planen',
    'Tutorial.Text.ReviewPerformance': 'Überprüfen Sie Ihre <code>Leistung</code> und <code>die Erfüllung der Strategien</code> nach Ihrer Sitzung',
    'Tutorial.Header.Complete': 'Abgeschlossen',
    'Tutorial.Text.RevisitThisGuide': 'Sie können dieses Handbuch und andere hilfreiche Informationen {lineBreak} <code>jederzeit</code> in der <code>Wissensbank erneut aufrufen. </code>',
    'Tutorial.Button.TakeMeToThePortal': 'Zurück zum Portal',
    'Tutorial.Header.DownloadInstallMursionSimulator': 'Den Mursion-Simulator {lineBreak} herunterladen und installieren',
    'Tutorial.Text.PlanningToUseMursionOnComputer': 'Wenn Sie diesen Computer zum Ausführen von Mursion-Simulationen verwenden möchten, empfehlen wir dringend, die erforderliche Software jetzt zu installieren, indem Sie unten auf die Schaltfläche „Mursion installieren“ klicken.',
    'Tutorial.Text.InstallSoftwareFromPortal': 'Sie können die Software später direkt über das Portal installieren. Bitte beachten Sie, dass die Installation des immersiven Simulators von Mursion bei der erstmaligen Verwendung einige Minuten dauern kann. Bitte planen Sie für die Installation der Software mindestens 30 Minuten vor Beginn Ihrer Simulation ein, damit genügend Zeit bleibt, um das Mursion-Erlebnis in vollen Zügen genießen zu können.',
    'Tutorial.Text.ProvideInstructionsForInstallation': 'Von Zeit zu Zeit werden wir weiterhin Anweisungen für die Installation und andere Workflows per E-Mail und Tutorials im Portal und im Simulator von Mursion bereitstellen. Wir hoffen, Sie genießen Ihre Simulationen genauso gerne, wie wir uns freuen sie zu erstellen!',
    'Tutorial.Text.TheMursionTeam': 'Das Team von Mursion.',
    'Tutorial.Button.InstallLater': 'Später installieren',
    'Tutorial.Button.InstallMursion': 'Mursion installieren',
    'Tutorial.Header.LetsGetStarted': 'Los geht\’s!',
    'Tutorial.Text.ShortIntro': 'Hier ist zunächst eine <code>kurze Einführung</code>, um Ihnen einige der {lineBreak} allgemeinen Funktionen <code>Ihrer Benutzerrolle</code> vorzustellen',
    'Tutorial.Button.Proceed': 'Fortfahren',
    'Tutorial.Label.Step': 'SCHRITT',
    'Tutorial.Label.NoSound': 'Dieses Video hat keinen Ton.',
    'Tutorial.Button.SkipTutorial': 'Tutorial überspringen',
    'Tutorial.Button.CloseTutorial': 'Tutorial schließen',
    'MursionPortal.Text.On': 'An',
    'MursionPortal.Text.Off': 'Aus',
    'MursionPortal.Text.MursionIcon': 'Mursion-Logo',
    'MursionPortal.AriaLabel.CreateNew': 'neu erstellen',
    'MursionPortal.Legend.VisibilityHidden.Buttons': 'Szenarien-Schaltflächen für „neu erstellen“, „Kunden wiederholen“, „Bank wiederholen“',
    'MursionPortal.Label.VisibilityHidden.EnterEmailId': 'E-Mail-ID eingeben',
    'MursionPortal.Label.VisibilityHidden.SelectRegistrationStatus': 'Registrierungsstatus auswählen',
    'MursionPortal.Label.VisibilityHidden.ClientLogoUpload': 'Kundenlogo hochladen',
    'MursionPortal.Header.VisibilityHidden.CompanyUsers': 'Unternehmensbenutzer',
    'MursionPortal.Legend.VisibilityHidden.StationType': 'Stationstyp',
    'MursionPortal.Legend.VisibilityHidden.Hours': 'Stunden',
    'MursionPortal.Caption.VisibilityHidden.CompanyUsersList': 'Unternehmensbenutzer-Liste',
    'MursionPortal.AriaLabel.Search': 'Suche',
    'MursionPortal.AriaLabel.From': 'Von',
    'MursionPortal.AriaLabel.To': 'Bis',
    'MursionPortal.AriaLabel.RemoveAccountOwner': 'Kontoinhaber entfernen',
    'MursionPortal.AriaLabel.InboxMessagePanel': 'Posteingangs-Nachrichtenbereich',
    'MursionPortal.AriaLabel.Breadcrumb': 'Breadcrumb',
    'MursionPortal.Message.NoItemsHaveBeenFound': 'Es wurden keine Elemente gefunden.',
    'MursionPortal.AriaLabel.Add': 'hinzufügen',
    'MursionPortal.AriaLabel.SortByDescendingOrder': 'sortieren nach absteigender Reihenfolge',
    'MursionPortal.AriaLabel.SortByAscendingOrder': 'sortieren nach aufsteigender Reihenfolge',
    'MursionPortal.AriaLabel.AddButton': 'Schaltfläche hinzufügen',
    'MursionPortal.AriaLabel.Timezone': 'Zeitzone',
    'MursionPortal.AriaLabel.Combobox': 'Kombinationsfeld',
    'MursionPortal.AriaLabel.PreviousMonth': 'vorheriger Monat',
    'MursionPortal.AriaLabel.NextMonth': 'nächster Monat',
    'MursionPortal.AriaLabel.SearchInput': 'Sucheingabe',
    'MursionPortal.AriaLabel.Informational': 'Informativ',
    'MursionPortal.Text.RedirectToCalendarPage': 'zur Kalenderseite zurückleiten',
    'MursionPortal.AriaLabel.StartDate': 'Anfangsdatum {startFormattedDate}',
    'MursionPortal.AriaLabel.EndDate': 'Enddatum {endFormattedDate}',
    'MursionPortal.AriaLabel.CreateProject': 'Projekt erstellen',
    'MursionPortal.AriaLabel.ContentInfo': 'Inhaltsinfo',
    'MursionPortal.AriaLabel.DraftSuperScript': 'Entwurf hochgestellt {statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedSuperScript': 'Abgeschlossen hochgestellt {statusConfiguration}',
    'MursionPortal.AriaLabel.StartedSuperScript': 'Begonnen hochgestellt {statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedSuperScript': 'Fertiggestellt hochgestellt {statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedSuperScript': 'Archiviert hochgestellt {statusConfiguration}',
    'MursionPortal.AriaLabel.EnterScenarioName': 'Geben Sie einen Szenario-Namen ein',
    'MursionPortal.AriaLabel.Capitalized.Search': 'Suche',
    'MursionPortal.AriaLabel.Close': 'Schließen',
    'MursionPortal.AriaLabel.CreateTeam': 'Team erstellen',
    'MursionPortal.AriaLabel.CreateClient': 'Kunde erstellen',
    'MursionPortal.AriaLabel.CreateUser': 'Benutzer erstellen',
    'MursionPortal.AriaLabel.ZoomId': 'Zoom-ID',
    'MursionPortal.AriaLabel.PrimaryContent': 'Hauptinhalt',
    'MursionPortal.AriaLabel.RegistrationStatus': 'Registrierungsstatus',
    'MursionPortal.AriaLabel.EnterTagName': 'Tag-Namen eingeben',
    'MursionPortal.AriaLabel.EnterUserName': 'Benutzername eingeben',
    'MursionPortal.AriaLabel.SearchProjectManagers': 'Projektmanager suchen',
    'MursionPortal.AriaLabel.SearchSSO': 'SSO suchen',
    'MursionPortal.AriaLabel.SearchAccountOwner': 'Kontoinhaber suchen',
    'MursionPortal.WarningMessage.AllowedCharacterLimit': 'Zulässiges Zeichenlimit ({maximumLength} Zeichen) wurde überschritten',
    'MursionPortal.WarningMessage.InvalidCharactersDetected': 'Ungültige Zeichen erkannt',
    'MursionPortal.Days.Sunday': 'Sonntag',
    'MursionPortal.Days.Monday': 'Montag',
    'MursionPortal.Days.Tuesday': 'Dienstag',
    'MursionPortal.Days.Wednesday': 'Mittwoch',
    'MursionPortal.Days.Thursday': 'Donnerstag',
    'MursionPortal.Days.Friday': 'Freitag',
    'MursionPortal.Days.Saturday': 'Samstag',
    'MursionPortal.Button.AddNew': 'Neu hinzufügen',
    'MursionPortal.Title.EditNotes': 'Bemerkungen bearbeiten',
    'MursionPortal.IconButton.Remove': 'entfernen',
    'MursionPortal.Filter.ProviderAll': 'Anbieter',
    'MursionPortal.Placeholder.SearchProviders': 'Suchanbieter …',
    'MursionPortal.AriaLabel.InboxButton': 'Posteingangs-Taste Sie haben {messageCount} neue Nachrichten',
    'MursionPortal.AriaLabel.Event': 'Veranstaltung',
    'MursionPortal.Title.SelectedDate': 'Ausgewähltes Datum',
    'MursionPortal.Title.CurrentDate': 'Aktuelles Datum',
    'MursionPortal.Title.OffRangeDate': 'Datum außerhalb des Bereichs',
    'MursionPortal.Label.ClientLevel': 'Kundenebene',
    'MursionPortal.Label.TeamLevel': 'Teamebene',
    'MursionPortal.AltText.UserImage': 'Benutzerbild',
    'MursionPortal.AltText.KbMenu': 'Kb-Menü',
    'MursionPortal.AltText.AvatarImage': 'Avatar-Bild',
    'MursionPortal.AltText.EnvironmentImage': 'Umgebungs-Bild',
    'MursionPortal.AriaLabel.Cancel': 'abbrechen',
    'MursionPortal.AriaLabel.Edit': 'bearbeiten',
    'MursionPortal.AriaLabel.EditAccountOwner': 'Kontoinhaber bearbeiten',
    'MursionPortal.AriaLabel.Remove': 'entfernen',
    'MursionPortal.AriaLabel.SearchFilter': 'Suchfilter',
    'MursionPortal.AriaLabel.TierCombobox': 'Kombinationsfeld',
    'MursionPortal.AriaLabel.SortByAscending': 'Aufsteigend sortieren',
    'MursionPortal.AriaLabel.SortByDescending': 'Absteigend sortieren',
    'MursionPortal.Message.VisibilityHidden.SelectingDeliveryMode': 'Mit der Auswahl eines Durchführungsmodus können Sie die Sitzungslänge und die Sitzungsgröße festlegen',
    'MursionPortal.AltText.DownloadComputerIcon': 'Mursion-Download-Arbeitsplatz-Symbol',
    'MursionPortal.AltText.DownloadIcon': 'Mursion-Download-Symbol',
    'MursionPortal.AltText.MursionLogo': 'Mursion-Logo',
    'MursionPortal.AriaLabel.MursionSupport': 'Mursion Support',
    'MursionPortal.AltText.MursionDashboard': 'Mursion-Dashboard',
    'MursionPortal.AltText.BackgroundMaleUser': 'Mursion-Hintergrund für männlichen Teilnehmer',
    'MursionPortal.AltText.BackgroundGroupUser': 'Mursion-Hintergrund für Gruppenteilnehmer',
    'MursionPortal.AltText.BackgroundFemaleUser': 'Mursion-Hintergrund für weibliche Teilnehmerin',
    'MursionPortal.ErrorMessage.CharacterLimitExceeded': 'Zulässiges Zeichenlimit ({maximumLength} Zeichen) wurde überschritten',
    'MursionPortal.ErrorMessage.InvalidCharactersDetected': 'Ungültige Zeichen erkannt',
    'MursionPortal.ErrorMessage.EnterValidPhoneNumber': 'Sie müssen eine gültige Telefonnummer eingeben.',
    'MursionPortal.Message.InvitationLinkInvalid': 'Ihr Einladungslink ist ungültig oder möglicherweise abgelaufen. {lineBreak} Wenn Sie die Registrierung noch nicht abgeschlossen haben, {lineBreak} wenden Sie sich bitte an Ihren Manager für eine erneute Einladung.',
    'MursionPortal.Header.SetYourPassword': 'Lege ein Passwort fest',
    'MursionPortal.Message.PasswordMessage': 'Ihr Passwort muss Folgendes enthalten:',
    'MursionPortal.Label.NewPassword': 'Neues Passwort',
    'MursionPortal.Label.RepeatPassword': 'Passwort wiederholen',
    'MursionPortal.Button.SetPassword': 'Passwort festlegen',
    'MursionPortal.Header.ErrorMessage.SomethingWentWrong': 'Es ist ein {lineBreak} Fehler aufgetreten. Bitte {lineBreak} wenden Sie sich an den Support.',
    'MursionPortal.Header.ContactSupport': 'SUPPORT KONTAKTIEREN',
    'MursionPortal.Label.Phone': 'Telefonnummer',
    'MursionPortal.Label.Email': 'E-Mail-Adresse',
    'MursionPortal.Label.Hours': 'Stunden',
    'MursionPortal.Message.EnterPasswordToConfirm': 'Bitte geben Sie Ihr Passwort ein, um diese Änderung zu bestätigen',
    'MursionPortal.Label.Password': 'Passwort',
    'MursionPortal.Message.BrowserDialog': 'Wenn kein Browser-Dialogfeld angezeigt wird, klicken Sie bitte unten auf <code>Mursion öffnen</code>{lineBreak} oder verwenden Sie die Mursion-Desktop-Verknüpfung, um die Mursion-Anwendung zu öffnen.’,',
    'MursionPortal.Button.OpenMursion': 'ML3 Desktop App öffnen',
    'MursionPortal.Message.FollowInstructions': 'Klicken Sie auf {appName} und folgen Sie den Anweisungen auf dem Bildschirm, um die Anwendung zu installieren',
    'MursionPortal.Text.PositiveImpact': 'Wenn Sie das Gefühl haben, eine positive Einflussnahme zu haben, klicken Sie auf die Schaltfläche <code>{icon}</code>. ',
    'MursionPortal.Text.NegativeImpact': 'Wenn Sie das Gefühl haben, eine negative Einflussnahme zu haben, klicken Sie auf die Schaltfläche <code>{icon}</code>.',
    'MursionPortal.Text.NeutralImpact': 'Wenn Sie der Meinung sind, dass Sie weder einen positiven noch negativen Effekt haben, klicken Sie auf die Schaltfläche <code>{icon}</code>.',
    'MursionPortal.Title.Next': 'Weiter',
    'MursionPortal.Title.Start': 'Start',
    'MursionPortal.Text.UpdateYourRating': 'Während Sie sich das Video ansehen, können Sie Ihre Bewertung jederzeit aktualisieren, wenn Sie glauben, dass Sie einen anderen Einfluss auf den Avatar haben.',
    'MursionPortal.Text.SeeCurrentRating': 'Die Grafik unter dem Video wird aktualisiert, sodass Sie Ihre aktuelle Bewertung sehen und ändern können, wenn Sie der Meinung sind, dass sich Ihre Einflussnahme geändert hat.',
    'MursionPortal.Text.ClickStartToBegin': 'Klicken Sie auf „Start“ um zu beginnen. Sie können auf „Vollständig“ klicken, nachdem Sie die gesamte Simulation bewertet haben. Bitte beachten Sie, dass die Videosuchfunktion in Teilen dieses Videos möglicherweise deaktiviert ist, um die Datenintegrität zu gewährleisten.',
    'MursionPortal.Header.HowWouldYouProceed': 'Wie möchten Sie fortfahren?',
    'MursionPoral.Button.SwitchUser': 'Benutzer wechseln',
    'MursionPortal.Button.ContinueAs': 'Als <code>{userName}</code> weitermachen ',
    'MursionPortal.Text.ToContinue': 'Um fortzufahren, <code>{loginData}</code> klicken Sie bitte auf <code>Benutzer wechseln</code>.',
    'MursionPortal.Message.YouAreSignedIn': 'Sie sind derzeit als <code>{userName}</code> ({userProfile}) angemeldet.',
    'MursionPortal.Title.ContinueAs': 'Als {userName} weitermachen',
    'MursionPortal.Message.OpenLink': 'Link öffnen',
    'MursionPortal.Message.Allow': 'Erlauben',
    'MursionPortal.Message.Open': 'Öffnen',
    'MursionPortal.Message.Yes': 'Ja',
    'MursionPortal.Message.OpenMursionApp': 'MursionApp öffnen',
    'MursionPortal.PasswordRequirementMessage.MinimumCharacters': 'Mindestens {PASSWORD_MIN_LENGTH} Zeichen',
    'MursionPortal.Message.PortalHeading': 'Entwerfen, planen und verwalten Sie Ihre',
    'MursionPortal.PasswordRequirementMessage.UppercaseLetters': 'Großbuchstaben + Kleinbuchstaben',
    'MursionPortal.PasswordRequirementMessage.Numbers': 'eine beliebige Zahlenkombination',
    'MursionPortal.ErrorMessage.InvalidEmailFormat': 'Ungültiges E-Mail-Format.',
    'MursionPortal.ErrorMessage.EmailCanNotBeEmpty': 'E-Mail darf nicht leer sein.',
    'MursionPortal.ErrorMessage.EnterValidEmailAddress': 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
    'MursionPortal.ErrorMessage.PasswordCannotBeEmpty': 'Passwort darf nicht leer sein',
    'MursionPortal.ErrorMessage.UrlCannotBeEmpty': 'URL darf nicht leer sein',
    'MursionPortal.ErrorMessage.UrlIsNotValid': 'Die URL ist nicht gültig',
    'MursionPortal.ErrorMessage.NoMeetingId': 'Enthält keine Meetings-ID',
    'MursionPortal.ValidationErrorMessage.FileRequestFailed': 'Dateianforderung fehlgeschlagen',
    'MursionPortal.ValidationErrorMessage.FileExceedsLimit': 'Datei überschreitet Limit von 5 MB',
    'MursionPortal.ValidationErrorMessage.EnterMessage': 'Bitte geben Sie Ihre Nachricht vor dem Absenden ein',
    'MursionPortal.ValidationErrorMessage.MessageTooLong': 'Nachricht zu lang',
    'MursionPortal.ValidationErrorMessage.InvalidUrl': 'Die URL ist nicht gültig',
    'MursionPortal.Button.SubmitFeedback': 'Feedback absenden',
    'MursionPortal.Header.Feedback': 'Feedback',
    'MursionPortal.Text.AdditionalComments': 'Zusätzliche Kommentare oder Vorschläge',
    'MursionPortal.Header.ThanksForFeedback': 'Vielen Dank für Ihr Feedback!',
    'MursionPortal.Button.Close': 'Schließen',
    'MursionPortal.Title.ShowFeedback': 'Feedback anzeigen',
    'MursionPortal.Title.HideFeedback': 'Feedback verbergen',
    'MursionPortal.ErrorMessage.FileTypeNotSupported': 'Dateityp {type} wird nicht unterstützt',
    'MursionPortal.ErrorMessage.ScenarioDuration': 'Die Dauer des Szenarios muss zwischen {durationMinimum} und {durationMaximum} liegen.',
    'MursionPortal.ErrorMessage.ScenarioScheduledSessions': 'Für dieses Szenario sind derzeit {sessionsCount} Sitzungen geplant. Sagen Sie diese Sitzungen ab, bevor Sie dieses Szenario archivieren.',
    'MursionPortal.ErrorMessage.ScenarioScheduledSession': 'Für dieses Szenario ist derzeit {sessionsCount} Sitzung geplant. Sagen Sie diese Sitzung ab, bevor Sie dieses Szenario archivieren.',
    'MursionPortal.ErrorMessage.ClientScheduledSessions': 'Für diesen Auftraggeber sind derzeit {sessionsCount} Sitzungen geplant. Sagen Sie diese Sitzungen ab, bevor Sie den Auftraggeber archivieren.',
    'MursionPortal.ErrorMessage.ClientScheduledSession': 'Für diesen Auftraggeber ist derzeit {sessionsCount} Sitzung geplant. Sagen Sie diese Sitzung ab, bevor Sie den Auftraggeber archivieren.',
    'MursionPortal.EulaAgreement.EndUserAgreement': 'Diese Endbenutzervereinbarung („Vereinbarung“) ist eine rechtliche Vereinbarung zwischen Ihnen, dem Benutzer, und Mursion, Inc. („Mursion“) und unterliegt den Bedingungen der Mursion-Software als Servicevereinbarung zwischen Mursion und der Mursion-Dienstleister, über die Sie die Mursion-Software verwenden. Zur Klarstellung, Sie erkennen an und stimmen zu, dass Sie sowie das Unternehmen, das Ihnen Zugriff auf die Mursion-Software gewährt, für die Nutzung und den Zugriff auf die Mursion-Software („Mursion-Software“) den Nutzungsbedingungen dieser Vereinbarung unterliegen und an sie gebunden sind.',
    'MursionPortal.EulaAgreement.TermsAndConditions': 'Vorbehaltlich der Bestimmungen dieser Vereinbarung und der Mursion-Software als Servicevereinbarung gewährt Mursion Ihnen hiermit eingeschränkte, nicht exklusive, nicht übertragbare, nicht unterlizenzierbare Rechte für den Zugriff auf und die Nutzung der Mursion-Software.',
    'MursionPortal.EulaAgreement.AgreeAndAcknowledge': 'Sie erklären sich damit einverstanden und erkennen an, dass Sie nicht das Recht haben, und davon absehen werden, die Mursion-Software oder von ihr abgeleitete Materialien zu kopieren, zu modifizieren, nachzukonstruieren, zu dekompilieren, zu disassemblieren oder abgeleitete Werke auf Grundlage der Mursion-Software oder von ihr abgeleiteter Materialien zu erstellen. Sie erklären sich damit einverstanden, keinen Sicherungsmechanismus im Zusammenhang mit der Mursion-Software zu überwinden, zu deaktivieren oder zu umgehen. Sobald Sie von einem unbefugten Besitz oder einer unbefugten Nutzung oder einem unbefugten Zugriff auf die Mursion-Software erfahren, werden Sie Mursion unverzüglich benachrichtigen. Sie erklären sich ferner damit einverstanden, umgehend Einzelheiten zu jedem solchen Ereignis anzugeben, um eine Wiederholung dessen angemessen zu verhindern (soweit dies möglich ist) und bei allen Verfahren zum Schutz der Rechte von Mursion uneingeschränkt zu kooperieren.',
    'MursionPortal.EulaAgreement.ConstruedAsGranting': 'Anders als hierin ausdrücklich angegeben, ist nichts in dieser Vereinbarung so auszulegen, dass implizit oder auf andere Weise zusätzliche Rechte am geistigen Eigentum von Mursion ohne die vorherige schriftliche Genehmigung von Mursion gewährt werden.',
    'MursionPortal.EulaAgreement.NonDisclosure': 'Sie erklären sich damit einverstanden, Mursion Software oder Teile davon zu keinem anderen Zweck als dem hier ausdrücklich gestatteten offenzulegen oder an Dritte weiterzugeben.',
    'MursionPortal.EulaAgreement.AgreeAndRelease': 'Sie erklären sich damit einverstanden, Mursion, seine Mitarbeiter, leitenden Angestellten, Vertreter, verbundenen Unternehmen und Direktoren von sämtlichen bekannten und unbekannten Ansprüchen zu entlasten, zu entbinden und anderweitig schadlos zu halten, wann immer sie entstehen, die sich aus oder durch oder anderweitig aus Ihrer Nutzung der Mursion-Software ergeben.',
    'MursionPortal.EulaAgreement.UnderstandAndAcknowledge': 'Sie verstehen und erkennen an, dass Mursion Informationen und Daten, die aus Ihrer Nutzung der Mursion-Software stammen, gemäß den Bestimmungen der Datenschutzrichtlinie von Mursion, die hier zu finden sind, sammeln, pflegen, verwenden, speichern und analysieren kann',
    'MursionPortal.EulaAgreement.AccessRights': 'Die Ihnen von Mursion gewährten Nutzungs- und Zugriffsrechte erlöschen sofort, wenn die Mursion-Software als Servicevertrag, durch die Ihnen der Zugriff auf die Mursion-Software gewährt wird, abläuft oder beendet wird oder wenn Sie gegen eine Bestimmung dieser Vereinbarung verstoßen.',
    'MursionPortal.EulaAgreement.AgreementGoverned': 'Diese Vereinbarung unterliegt den Gesetzen des US-Bundesstaates Delaware und wird in Übereinstimmung mit diesen ausgelegt, ohne Rücksicht auf die darin enthaltenen Kollisionsnormen. Alle Streitigkeiten, die sich aus oder im Zusammenhang mit Ihrer Nutzung der Mursion-Software oder dieser Vereinbarung ergeben, werden vor ein zuständiges Bundes- oder Bundesgericht im Bundesstaat Delaware gebracht, und Sie stimmen hiermit der persönlichen Gerichtsbarkeit vor diesen Gerichten zu.',
    'MursionPortal.EulaAgreement.ProvisionsDeclaredInvalid': 'Sollten Bestimmungen dieser Vereinbarung für ungültig erklärt werden, werden diese Bestimmungen von dieser Vereinbarung getrennt, aber jede andere Bestimmungen dieser Vereinbarung bleibt in vollem Umfang in Kraft und wirksam.',
    'MursionPortal.EulaAgreement.AgreementConflict': 'Für den Fall, dass die Bestimmungen dieser Vereinbarung im Widerspruch zu den Bestimmungen der Mursion-Software als Servicevereinbarung stehen, gelten die hierin enthaltenen Bestimmungen.',
    'MursionPortal.EulaAgreement.AccessToMursionSofware': 'Ihre Nutzung oder Ihr Zugriff auf die Mursion-Software stellt eine Zustimmung zu dieser Vereinbarung und den hierin angegebenen Bedingungen dar.',
    'MursionPortal.Header.MursionUserAgreement': 'Mursion & Nutzungsbedingungen',
    'MursionPortal.Button.Back': 'Zurück',
    'MursionPortal.Button.Skip': 'Überspringen',
    'MursionPortal.Title.BackToSessions': 'Zurück zu Sitzungen',
    'MursionPortal.Title.Proceed': 'Fortfahren',
    'MursionPortal.Message.SkipActivity': 'Wenn Sie sich dafür entscheiden diesen Teil zu überspringen, gehen Ihnen Erkenntnisse über die Leistung, die möglicherweise von unserer Verhaltensanalyse-Engine bereitgestellt werden, verloren. Wir empfehlen daher, diese Aktivität nicht zu überspringen.',
    'MursionPortal.HeaderTitle.ConfirmCancellation': 'Abbrechen bestätigen',
    'MursionPortal.Label.Delete': 'löschen',
    'MursionPortal.Label.Restore': 'wiederherstellen',
    'MursionPortal.HeaderTitle.ConfirmRestore': 'Wiederherstellung bestätigen',
    'MursionPortal.Label.Archive': 'archivieren',
    'MursionPortal.Button.CancelTraining': 'Schulung abbrechen',
    'MursionPortal.Button.No': 'Nein',
    'MursionPortal.Button.CancelEvent': 'Veranstaltung absagen',
    'MursionPortal.Label.Update': 'aktualisieren',
    'MursionPortal.HeaderTitle.ConfirmUpdate': 'Aktualisierung bestätigen',
    'MursionPortal.Button.UpdateSession': 'Sitzung aktualisieren',
    'MursionPortal.Label.Swap': 'tauschen',
    'MursionPortal.HeaderTitle.ConfirmAcceptedSwap': 'Bestätigen Sie den angenommen Tausch',
    'MursionPortal.Button.AcceptSwap': 'Tausch annehmen',
    'MursionPortal.Label.Reason': 'Grund',
    'MursionPortal.Placeholder.ReasonForSwapping': 'Bitte geben Sie einen Grund für den Tausch an.',
    'MursionPortal.Label.Request': 'anfordern',
    'MursionPortal.HeaderTitle.ConfirmAcceptedRequest': 'Akzeptierte Anfrage bestätigen',
    'MursionPortal.Button.AcceptRequest': 'Anfrage bestätigen',
    'MursionPortal.Placeholder.ReasonForAccepting': 'Bitte geben Sie einen Grund für die Annahme an.',
    'MursionPortal.Button.ConfirmProjectChange': 'Projektänderung bestätigen',
    'MursionPortal.HeaderTitle.Confirm': 'Bestätigen',
    'MursionPortal.Button.ConfirmCreateSession': '„Sitzung erstellen“ bestätigen',
    'MursionPortal.Placeholder.ReasonForCreation': 'Bitte geben Sie einen Grund für die Erstellung an.',
    'MursionPortal.Button.ConfirmAssigningSimSpecialist': 'Bestätigen Sie die Zuweisung eines Simulationsspezialisten',
    'MursionPortal.Placeholder.ReasonForAssigning': 'Bitte geben Sie einen Grund für die Zuweisung an.',
    'MursionPortal.Button.ConfirmChangingSimSpecialist': 'Bestätigen Sie die Änderung des Simulationsspezialisten',
    'MursionPortal.Placeholder.ReasonForChanging': 'Bitte geben Sie einen Grund für die Änderung an.',
    'MursionPortal.HeaderTitle.ConfirmTimeZone': 'Zeitzone bestätigen',
    'MursionPortal.Message.DontHaveMursionInstalled': 'Haben Sie ML3 Desktop App nicht installiert? Klicken Sie zum installieren auf <code>herunterladen</code>.',
    'MursionPortal.ErrorMessage.CannotContainCharacter': 'Der Szenarioname sollte kein "_" -Zeichen enthalten.',
    'MursionPortal.ErrorMessage.EnterDifferentName': 'Benutzer-E-Mail existiert bereits. Bitte geben Sie eine andere E-Mail-Adresse ein.',
    'MursionPortal.ErrorMessage.EnterDifferentEmail': 'Benutzer-E-Mail existiert bereits. Bitte geben Sie eine andere E-Mail-Adresse ein.',
    'MursionPortal.ErrorMessage.NameExists': 'Ein Benutzer mit demselben {fieldName} existiert bereits. Bitte geben Sie einen anderen {fieldName} ein.',
    'MursionPortal.ErrorMessage.UnableToRemove': '{entity} kann nicht entfernt werden. {entity} wird derzeit in einem aktiven {depEntity} verwendet.',
    'MursionPortal.ErrorMessage.OverlapsExistingTimeBlock': 'Der ausgewählte Zeitblock überschneidet sich mit einem bereits vorhandenen Zeitblock.',
    'MursionPortal.ErrorMessage.AccessIsDenied': 'Der Zugriff wurde verweigert.',
    'MursionPortal.ErrorMessage.SomethingMustBeBroken': 'Etwas muss kaputt sein. Bitte versuchen Sie es später erneut.',
    'MursionPortal.ErrorMessage.Unauthorized': 'Nicht berechtigt',
    'MursionPortal.ErrorMessage.RefreshTokenNotFound': 'Aktualisierungstoken nicht gefunden',
    'MursionPortal.ErrorMessage.CannotRefreshToken': 'Token kann nicht aktualisiert werden',
    'MursionPortal.QADialog.NoParticipants': 'Keine Teilnehmer',
    'MursionPortal.ErrorMessage.UsersUnavailable': 'Einige Ihrer Benutzer sind zum ausgewählten Zeitpunkt nicht verfügbar.',
    'Filters.SsoProtocolType': 'Protokoll: Alle',
    'Filters.SsoProtocolTypePlaceHolderHint': 'Suche SSO-Protokoll',
    'Filters.SSO.PrivacyAll': 'Typ Alle',
    'Filters.SSO.PrivacyPublic': 'Typ Öffentlich',
    'Filters.SSO.PrivacyPrivate': 'Typ Privat',
    'Filters.SSO.PrivacyAriaLabel': 'SSO-Datenschutztyp-Filter',
    'Cleints.Modal.Label.DomainsTitle': 'Die Liste der Domains, die für die Anmeldung verwendet werden können',
    'MursionPortal.NoDomains': 'Keine Domains',
    'MursionPortal.Header.Domains': 'Domains',
    'MursionPortal.AddDomain': 'Domain hinzufügen',
    'Clients.Modal.Projects.ButtonTitle.EditDomains': 'Domains bearbeiten',
    'Clients.Modal.Projects.DomainField.EnterDomain': 'Geben Sie einen Domainnamen ein (z. B. example.com)',
    'Clients.Modal.Projects.DomainField.InvalidDomain': 'Domainformat ist ungültig',
    'MursionPortal.ErrorMessage.SomethingWrongWithResponse': 'Etwas stimmt nicht mit der Antwort.',
    'MursionPortal.ErrorMessage.AuthorizationHeaderIsEmpty': 'Autorisierungsheader ist leer',
    'MursionPortal.ErrorMessage.CannotRefreshAccessToken': 'Token kann nicht aktualisiert werden',
    'MursionPortal.ErrorMessage.AuthorizationTokenIsEmpty': 'Autorisierungstoken ist leer',
    'MursionPortal.ErrorMessage.AuthenticationFailed': 'Authentifizierung ist fehlgeschlagen.',
    'MursionPortal.ErrorMessage.UnsupportedResponseFormat': 'Nicht unterstütztes Antwortformat.',
    'MursionPortal.ErrorMessage.AccountBlocked': 'Ihr Konto wurde bis {blockedDate} gesperrt. Versuchen Sie sich danach anzumelden.',
    'MursionPortal.ErrorMessage.AttemptsRemaining': '{attempts} Versuche, die vor einer 24-Stunden-Sperrung verbleiben. Wenn Sie Hilfe benötigen, setzen Sie bitte Ihr Passwort zurück.',
    'MursionPortal.ErrorMessage.ClientIdMustBeSpecified': 'Kunden-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.ProjectIdMustBeSpecified': 'Projekt-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.SessionIdMustBeSpecified': 'Sitzungs-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.SSOIdMustBeSpecified': 'SSO-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.LearnerIdMustBeSpecified': 'Die Schulungsteilnehmer-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.UserIdMustBeSpecified': 'Benutzer-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.TeamIdMustBeSpecified': 'Team-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.AssetProjectIdMustBeSpecified': 'Asset-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.AssetSettingsIdMustBeSpecified': 'Die Asset-Einstellungs-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.ScenarioIdMustBeSpecified': 'Szenario-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.ScenarioTemplateIdMustBeSpecified': 'Die ID der Szenariovorlage muss angegeben werden',
    'MursionPortal.ErrorMessage.LicenseIdMustBeSpecified': 'fetchUsersList: Lizenznehmer-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.LicenseCertificateIdMustBeSpecified': 'fetchLicenseeCertificates: Lizenznehmer-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.LicenseConfigIdMustBeSpecified': 'fetchConfig: Lizenznehmer-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.LicenseUpdateConfigIdMustBeSpecified': 'updateConfig: Lizenznehmer-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.LicenseTestConfigIdMustBeSpecified': 'testConfig: Lizenznehmer-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.LicenseFetchTiersIdMustBeSpecified': 'fetchTiers: Lizenznehmer-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.LicenseUpdateTiersIdMustBeSpecified': 'updateTiers: Lizenznehmer-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.GlobalUserIdMustBeSpecified': 'Globaler-Benutzer-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.ScenarioTemplateListLicenseIdMustBeSpecified': 'fetchScenarioTemplatesList: Lizenznehmer-ID muss angegeben werden',
    'MursionPortal.ErrorMessage.ScenarioAttachmentListIdMustBeSpecified': 'fetchScenarioAttachmentLink: Szenario-ID und Anhangs-ID müssen angegeben werden',
    'MursionPortal.ErrorMessage.FetchScenarioIdMustBeSpecified': 'fetchScenario: Szenario-ID muss angegeben werden',
    'Projects.ProjectInfo.Checkbox.AllowRecordingInfo': 'Dadurch werden keine aktuell gespeicherten Aufzeichnungen gelöscht.',
    'MursionPortal.AriaLabel.ToggleMenu': 'Menü umschalten',
    'MursionPortal.ClientListItem.RedirectToUserDetails': 'Weiterleitung zu Details von {name}',
    'MursionPortal.AriaLabel.PreviousWeek': 'vorherige Woche',
    'MursionPortal.AriaLabel.PreviousDay': 'vorheriger Tag',
    'MursionPortal.AriaLabel.NextWeek': 'nächste Woche',
    'MursionPortal.AriaLabel.NextDay': 'vorheriger Tag',
    'Dashboard.LeftPane.CategoryHeader.SessionsDashboard': 'Sitzungs-Dashboard',
    'MursionPortal.Title.SessionsPage': 'Sitzungsseite',
    'Clients.heading.Clients': 'Kunden',
    'MursionPortal.AriaLabel.Client': 'Kunde',
    'MursionPortal.AriaLabel.SimSpecialist': 'SimSpecialist',
    'MursionPortal.Project.TimeZoneStartDate': 'Startdatum für die Zeitzone',
    'MursionPortal.Project.TimeZoneEndDate': 'Enddatum für die Zeitzone',
    'MursionPortal.AriaLabel.SelectTimezoneCombobox': 'Kombinationsfeld Zeitzone auswählen',
    'MursionPortal.Support.PhoneNumber': '1-855-999-5818',
    'MursionPortal.Message.LearnerEmpty': 'Bitte wählen Sie die Lernenden aus, die an der Sitzung teilgenommen haben',
    'Dashboard.ProjectsTable.StatusValue.Active': 'AKTIV',
    'Dashboard.ProjectsTable.StatusValue.Completed': 'ABGESCHLOSSEN',
    'Dashboard.ProjectsTable.StatusValue.Archived': 'ARCHIVIERT',
    'MursionPortal.Button.Refresh': 'Aktualisieren',
    'MursionPortal.Prompt.CalendarUpdated': 'Der Kalender wurde aktualisiert.',
    'MursionPortal.Prompt.ClickRefresh': 'Klicken Sie auf „Aktualisieren“, um die Änderungen anzuzeigen.',
    'MursionPortal.Label.SessionAttendance': 'Teilnahme an der Sitzung',
    'MursionPortal.Label.SessionVideoUrl': 'URL des Sitzungsvideos',
    'MursionPortal.Label.LearnerAttendance': 'Teilnahme des Lernenden',
    'MursionPortal.Text.UserProfileUpdated': 'Ein Benutzerprofil wurde aktualisiert!',
    'MursionPortal.Text.ClientUpdated': 'Ein Kunde wurde aktualisiert!',
    'MursionPortal.Text.ProjectUpdated': 'Ein Projekt wurde aktualisiert!',
    'MursionPortal.Text.ScenarioUpdated': 'Ein Szenario wurde aktualisiert!',
    'MursionPortal.Text.LicenseeConfigUpdated': 'Die Konfiguration des Lizenznehmers wurde aktualisiert!',
    'MursionPortal.Prompt.TrainingScheduled': '<code>Schulung</code> wurde geplant!',
    'Settings.SSO.Table.ColumnHeader.ClientName': 'Kundenname',
    'MursionPortal.Text.UserCreatedSuccessfully': 'Benutzer wurde erfolgreich erstellt',
    'MursionPortal.Text.UserNotCreated': 'Benutzer wurde nicht erstellt',
    'MursionPortal.Text.AssignedTeams': 'Zugeordnete Teams',
    'MursionPortal.Client.EmailSettings.Invitation': 'Einladung',
    'MursionPortal.Client.EmailSettings.InvitationReminder': 'Einladungserinnerung',
    'MursionPortal.Client.EmailSettings.Confirmation': 'Bestätigung',
    'MursionPortal.Client.EmailSettings.Cancellation': 'Annullierung',
    'MursionPortal.Client.EmailSettings.FirstReminder': 'Erste Erinnerung',
    'MursionPortal.Client.EmailSettings.SecondReminder': 'Zweite Erinnerung',
    'MursionPortal.Client.EmailSettings.SessionRecording': 'Die Sitzungsaufzeichnung ist bereit für den Kontoinhaber/Moderator',
    'MursionPortal.Client.EmailSettings.LearnerSession': 'Die Aufzeichnung der Lernersitzung ist bereit',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejected': 'Videoaufzeichnung der Simulationssitzung abgelehnt',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoDisallowed': 'Videoaufzeichnung der Simulationssitzung nicht erlaubt',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejectedUser': 'Videoaufzeichnung der Simulationssitzung von einem Benutzer abgelehnt',
    'MursionPortal.Client.EmailSettings.RequestRecieved': 'Bestätigung der Erhalt der Anforderung',
    'MursionPortal.Client.EmailSettings.RequestNotFullfilled': 'Anforderung nicht erfüllt',
    'MursionPortal.Client.EmailSettings.RequestDateTime': 'Anforderung zur Änderung von Datum oder Uhrzeit erforderlich',
    'MursionPortal.Client.EmailSettings.Title.SelectEmails': 'Wählen Sie die E-Mails aus, die Sie an die Lernenden senden möchten. Mursion empfiehlt, alle E-Mails aktiviert zu lassen.',
    'MursionPortal.Client.EmailSettings.Header.Registration': 'Registrierung',
    'MursionPortal.Client.EmailSettings.Header.TrainingSession': 'Schulungssitzung',
    'MursionPortal.Client.EmailSettings.Header.Videos': 'Videos',
    'MursionPortal.Client.EmailSettings.Header.Requests': 'Anforderungen',
    'MursionPortal.AriaLabel.ToggleButton': 'Umschalt-Button',
    'MursionPortal.Client.EmailSettings.Hint.RecordingEnabled': 'nur wenn Aufzeichnungen aktiviert sind ',
    'MursionPortal.Client.EmailSettings.Hint.RequestFeature': 'nur wenn Anforderungsfunktion aktiviert ist',
    'MursionPortal.SuccessDialog.Title': 'Dialog Erfolg',
    'MursionPortal.Dialog.CreateSSOConfiguartion.SuccessMessage': 'Die Client-SSO-Konfiguration wurde erfolgreich erstellt.',
    'ITManager.TechRestriction.Table.EmptyMessage': 'Es wurden keine banktechnischen Einschränkungen gefunden.',
    'ITManager.TechRestriction.Table.Tag': 'Tag',
    'ITManager.TechRestriction.Table.StartDate': 'Startdatum',
    'ITManager.TechRestriction.Table.EndDate': 'Enddatum',
    'ITManager.TechRestriction.EditDialog.EnterTag': 'Tag-Namen eingeben',
    'ITManager.TechRestriction.Table.HoverTitle': 'Technische Einschränkung anzeigen/bearbeiten',
    'ITManager.TechRestriction.Table.NoTechRestrictionError': 'Es gibt noch keine technischen Einschränkungen',
    'MursionPortal.DateTimePicker.StartTime': 'Startzeit',
    'MursionPortal.DateTimePicker.EndTime': 'Endzeit',
    'MursionPortal.TechRestrictions': 'Technische Einschränkungen',
    'MursionPortal.Caption.ScenarioBankUsersList': 'Szenario-Bank-Benutzerliste',
    'MursionPortal.Caption.SsoConfigurationList': 'SSO-Konfigurationsliste',
    'MursionPortal.AriaLabel.DateRangeCalendarButton': 'Drücken Sie die Eingabetaste, um mit dem Kalender zu arbeiten und das Start- und Enddatum auszuwählen',
    'MursionPortal.Modal.Header.SessionTimeout': 'Zeitüberschreitung der Sitzung',
    'MursionPortal.Modal.Body.SessionTimeout': 'Your session will timeout in 1 minute, please continue to extend your session', // TODO
    'MursionPortal.Status.PendingNoSS': 'Ausstehend (keine SS)',
    'MursionPortal.Status.PendingNoL': 'Ausstehend (Keine L)',
    'MursionPortal.Status.PendingNoLearners': 'Ausstehend (keine Lernenden)',
    'MursionPortal.Status.PendingNoLSS': 'Ausstehend (Keine L/SS)',
    'Dashboard.LeftPane.SupplyManagement.SimView': 'Sim-Ansicht',
    'Dashboard.LeftPane.SupplyManagement.SchedulingView': 'Planungsansicht',
    'MursionPortal.Table.ColumnSelection.Button.Label': 'Spalten',
    'Session.Table.Column.SubmitRequest': 'Anfrage einreichen',
    'Filters.SimSpecialistStatus': 'Sim Specialist-Status',
    'Filters.SimSpecialistStatusPlaceHolderHint': 'Nach Sim Specialist-Status suchen...',
    'Users.TableModal.SSOUserID': 'SSO Benutzer-ID',
    'MursionPortal.UnavailableSlotsError.SorryText': 'Hallo! Es tut mir so leid!',
    'MursionPortal.UnavailableSlotsError.BusyText': 'Es sieht so aus, als ob wir damit beschäftigt sind, mit anderen Lernenden zu simulieren.',
    'MursionPortal.UnavailableSlotsError.TryAgainText': 'Bitte versuche es morgen oder an einem anderen Tag erneut!',
    'MursionPortal.Session.Survey.ClickHere': 'Hier klicken',
    'MursionPortal.Session.SurveyNotLoading': 'Die Umfrage lädt nicht?',
    'Users.Table.Heading.Viewonly': '(Nur Ansicht)',
    'MursionPortal.BookItUI.BusyText': 'Es sieht so aus, als wären wir gerade damit beschäftigt, mit anderen Lernenden zu simulieren.',
    'MursionPortal.BookItUI.TryLaterText': 'Weitere Simulationssitzungen sollten bald eröffnet werden. Bitte versuche es später noch einmal.',
    'MursionPortal.BookItUI.MoreSimulationsText': 'Weitere Simulationssitzungen sollten bald eröffnet werden.',
    'MursionPortal.BookItUI.ComeBackText': 'Bitte komm wieder, wenn Du heute an deiner Simulation teilnehmen möchtest.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Archived': 'Archivierter Benutzer',
    'ClientUserTable.AddNewUsers.UpdateArchivedUserWarning': '{archivedUsers} Benutzer sind derzeit archiviert. Wenn Du fortfährst, werden diese Benutzer zu den ausgewählten Teams hinzugefügt und die Archivierung der Benutzer wird rückgängig gemacht.',
    'ClientUserTable.AddNewUsers.UpdateExistingArchivedUserWarning': 'Diese {existingUsers} Benutzer existieren bereits und {archivedUsers} Benutzer sind derzeit archiviert. Wenn Du fortfährst, werden diese Benutzer den ausgewählten Teams hinzugefügt und die Archivierung der archivierten Benutzer wird ebenfalls aufgehoben.',
    'MursionPortal.ProjectCreateStep.UpperLimitValidation.Hours': 'Darf nicht länger als {code} Stunden sein',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability': 'Bevorstehende Verfügbarkeit',
    'MursionPortal.Table.ColumnHeader.TotalSession': 'Gesamtzahl der Sitzung',
    'MursionPortal.Table.ColumnHeader.ActiveCertifications': 'Aktive Zertifizierungen',
    'MursionPortal.Button.Decline': 'Ablehnen',
    'MursionPortal.Having.Issues.Logging.In': 'Probleme beim Einloggen?',
    'MursionPortal.Label.Call': 'Anruf:',
    'MursionPortal.Project.Hours': 'Stunde(n)',
    'Users.Button.ProfService': 'Professioneller Service',
    'Users.Button.Operations': 'Servicebetrieb',
    'Users.Button.SimDesigner': 'Sim Designer',
    'MursionPortal.ArtProject.Placeholder.EnterOrSelect': 'Auswählen oder Eingabe',
    'MursionPortal.Setting.LearnerCommunicationPersonalization': 'Personalisierung Lernenden-Kommunikation',
    'MursionPortal.LearnerCommunicationPersonalization.Heading': 'Hier kannst Du spezielle Nachrichten für deine Lernenden anpassen.',
    'MursionPortal.SupplyBasedScheduling.SubmitPage.SimulationConfirmation': 'Deine Teilnahme an der Simulation ist bestätigt! Wir empfehlen dir, ein paar Minuten vor der Startzeit da zu sein.',
    'MursionPortal.DemandBasedScheduling.SubmitPage.LearnerConfirmed': '{learners} für die Simulation bestätigt!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.DateAndTime': 'Datum und Uhrzeit',
    'MursionPortal.Personalized.LearnerDashboard.Section': 'Nachricht, die alle deine Lernenden im Lernenden-Dashboard sehen sollen',
    'MursionPortal.Personalized.SchedulingPage.Section': 'Nachricht, die alle deine Lernenden auf der Planungsseite sehen sollen',
    'MursionPortal.Personalized.Section.Tooltip': 'Die Nachricht wird an allen Stellen aktualisiert',
    'MursionPortal.CheckboxLabel.JoinNow': 'Jetzt beitreten',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability.Tooltip': 'Für Kunden-Rollen werden die Slots für die angegebene \'Bevorstehende Verfügbarkeit\' Stunden nach der \'Sim-Verfügbarkeit\' als deaktiviert angezeigt. Wenn die \'Bevorstehende Verfügbarkeit\' 0 ist, werden keine deaktivierten Slots und nur \'Sim-Verfügbarkeit\'-Slots angezeigt. Wenn die \'Bevorstehende Verfügbarkeit\' 24 Stunden beträgt, werden alle verfügbaren Slots nach den Stunden der \'Sim-Verfügbarkeit\' für einen Tag als deaktivierte Slots angezeigt.',
    'MursionPortal.LearnerDashboard.JoinNow.OutsideOfficeHoursText': 'Bitte komme später während der Bürozeiten deiner Organisation noch einmal vorbei, um an diesem Szenario teilzunehmen.',
    'MursionPortal.Setting.Personalization.LastUpdated': 'Letzte Aktualisierung:',
    'MursionPortal.CheckboxLabel.JoinNowRequests': 'Jetzt beitreten Anfragen',
    'MursionPortal.LearnerDashboard.JoinNow.HeaderText': 'Wir verbinden dich mit einem Avatar <code>für eine Live-Simulation</code>. Bitte lese dir zur Vorbereitung die folgenden Materialien durch.',
    'MursionPortal.LearnerDashboard.JoinNow.SorryText': 'Es tut uns leid!',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsBusyText': 'Alle unsere Avatare sind mit anderen Lernenden beschäftigt. Bitte komme später noch einmal vorbei oder versuche es in ein paar Minuten erneut.',
    'MursionPortal.LearnerDashboard.JoinNow.CongratsText': 'Herzlichen Glückwunsch! Du wurdest mit einem Avatar verbunden!',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsMatchText': 'Der Avatar wartet auf dich! Klicke auf „An Live-Simulation teilnehmen“, wenn Du bereit bist. Andernfalls beginnt dein Erlebnis automatisch nach dem Countdown.',
    'MursionPortal.LearnerDashboard.JoinNow.StartLearning': 'If you are not able to get connected, click below to start learning.',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.MyLearning': 'Starbucks MyLearning',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.LiveSimulationExp': 'This session is a live simulation experience. Partners can now choose to take an eLearning on de-escalating conflict in MyLearning, as an alternative to the live safety simulation.',
    'MursionPortal.Button.Leave': 'Verlassen',
    'MursionPortal.Personalized.ScenarioPage.Section': 'Nachricht, die den Lernenden auf der Szenarioseite angezeigt werden soll',
    'MursionPortal.Personalized.CreateNewScenario.Button.Label': 'Neues für ein bestimmtes Szenario erstellen',
    'MursionPortal.Personalized.Scenario.Search.Placeholder': 'Szenario(s) suchen oder auswählen:',
    'MursionPortal.Personalized.Scenario.Selection.Title': 'Neues spezifisches Szenario hinzufügen',
    'MursionPortal.Personalized.NoMessage': 'Derzeit ist keine Nachricht festgelegt',
    'MursionPortal.Personalized.MetaData.NoHistory': 'Noch kein Verlauf vorhanden.',
    'MursionPortal.JoinNow.AlreadyBooked.Message': '{code} ist derzeit für eine Sitzung zu diesem Zeitpunkt gebucht.',
    'Users.Table.SendInvite.TooltipText': 'Die Deaktivierung von Registrierungs-E-Mails deaktiviert auch die ‘Klicken-zum-Einladen’-Schaltflächen im Portal',
    'MursionPortal.JoinNow.AlreadyBooked.JoinSession.Message': 'Klicke auf „An Live-Simulation teilnehmen“, um der Sitzung beizutreten oder sie fortzusetzen.',
    'Dashboard.LeftPane.CategoryHeader.Scenarios': 'Szenarien',
    'Dashboard.LeftPane.Learners.TeamAndProjectActivity': 'Team- und Projektaktivität',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetScheduled': 'Noch nicht geplant',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.Scheduled': 'Noch nicht abgeschlossen',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': 'Abgeschlossen',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetAssigned': 'Noch nicht zugewiesen',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetScheduled': 'Eindeutige Anzahl von Lernenden, die mindestens einem Team zugeordnet sind, das mindestens einem Szenario zugewiesen ist, der Lernende jedoch noch nie eine Sitzung geplant hat.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.Scheduled': 'Eindeutige Anzahl von Lernenden, die mindestens eine Sitzung geplant, aber noch nicht teilgenommen und mindestens eine Sitzung abgeschlossen haben.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.CompletedAssigned': 'Eindeutige Anzahl von Lernenden, die mindestens eine Sitzung abgeschlossen haben.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetAssigned': 'Eindeutige Anzahl von Lernenden, die keinem Team zugeordnet sind, oder Lernenden, die Teil eines Teams sind, das keinem Szenario zugeordnet ist.',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerStatus': 'Abgeschlossen durch den Lernenden',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerSequence': 'Einzigartige Lernende',
    'MursionPortal.Dashboard.ScenarioLearner.Header.SequenceCount': '(Maximal {sessionCount} Sitzungen)',
    'Dashboard.SequenceOrder.Header.Title': 'Verpasste, verspätete Absage und verspätete Neuverschiebung der Aktivität',
    'MursionPortal.Integrations': 'Integrationen',
    'MursionPortal.Personalized.Message.Delete.Title': 'Bist Du sicher, dass Du die Nachricht löschen möchtest?',
    'MursionPortal.Personalized.Message.Delete.Tooltip': 'Nachricht löschen',
    'MursionPortal.Personalized.Scenario.Message.OnlyOneAtTime': 'Änderungen können jeweils nur an einer Nachricht vorgenommen werden. Bitte speichern oder breche den bereits im Bearbeitungsmodus befindlichen Vorgang ab.',
    'MursionPortal.Error.Time': '6:00 - 22:00 Uhr ET',
    'MursionPortal.CheckboxLabel.JoinNow.Tooltip.Text': 'Die Planung durch BookIt ist immer aktiviert, wenn „Jetzt beitreten“ aktiviert ist.',
    'MursionPortal.JoinNow.FinalizedScenario.Message': 'Das Szenario ist abgeschlossen. Bitte komme zurück, sobald das Szenario aktiv ist.',
    'MursionPortal.CheckboxLabel.sessionMissedTime': 'Verpasste Sitzungszeit',
    'MursionPortal.Title.Seconds': 'Sekunde(n)',
    'MursionPortal.RequestTable.Accept.ErrorMessage': 'Leider wurde die Sitzung abgesagt.',
    'MursionPortal.Client.EmailTypeFilter.SchedulingLocked': 'Terminplanung gesperrt',
    'MursionPortal.Client.EmailTypeFilter.SchedulingUnlocked': 'Terminplanung freigeschaltet',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time': 'Sperrung planen',
    'MursionPortal.Table.ColumnHeader.SimAvailable': 'Sim verfügbar',
    'MursionPortal.CheckboxLabel.sessionMissedTime.Tooltip.Text': 'Zeit, nach der die Sitzung als verpasst markiert wird, wenn kein Lernender oder SIM beitritt.',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetCompleted': 'Noch nicht abgeschlossen',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedOneSession': '1 Sitzung abgeschlossen',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedTwoPlusSession': '2+ Sitzungen abgeschlossen',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.OneSession': '1 Sitzung',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.TwoSession': '2 Sitzungen',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ThreeSession': '3 Sitzungen',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.FourPlusSession': '4+ Sitzungen',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetCompleted': 'Anzahl der einzelnen Lernenden, die diesem Szenario zugewiesen sind, es aber noch nicht abgeschlossen haben.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedOneSession': 'Anzahl der eindeutigen Lernenden, die diesem Szenario zugewiesen sind und es einmal abgeschlossen haben.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedTwoPlusSession': 'Anzahl der einzelnen Lernenden, die diesem Szenario zugeordnet sind und es zweimal oder öfter abgeschlossen haben.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.OneSession': 'Anzahl der einzelnen Lernenden, die eine Sitzung verpasst, zu spät abgesagt oder zu spät verschoben haben.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.TwoSession': 'Anzahl der einzelnen Lernenden, die zwei Sitzungen verpasst, zu spät abgesagt und/oder zu spät verschoben haben.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ThreeSession': 'Anzahl der einzelnen Lernenden, die drei Sitzungen verpasst, zu spät abgesagt und/oder zu spät verschoben haben.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.FourPlusSession': 'Anzahl der einzelnen Lernenden, die vier oder mehr Sitzungen verpasst, zu spät abgesagt und/oder zu spät verschoben haben.',
    'MursionPortal.ScenarioBank.ItemBank.Label': 'Bausteine',
    'MursionPortal.ScenarioBank.ItemBank.Skills': 'Fähigkeiten',
    'MursionPortal.ScenarioBank.ItemBank.Domains': 'Domänen',
    'MursionPortal.ScenarioBank.ItemBank.Events': 'Veranstaltungen',
    'MursionPortal.ScenarioBank.ItemBank.Pathways': 'Pfade',
    'MursionPortal.ScenarioBank.ItemBank.Resources': 'Ressourcen',
    'MursionPortal.ScenarioBank.ItemBank.Outcomes': 'Ergebnisse',
    'MursionPortal.ScenarioBank.ItemBank.Mindsets': 'Denkweisen',
    'MursionPortal.ScenarioBank.ItemBank.Avatars': 'Avatare',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries': 'Forschungszusammenfassungen',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstructions': 'Anweisungen für Lernende',
    'MursionPortal.ScenarioBank.ItemBank.Column.Level': 'Level',
    'MursionPortal.ScenarioBank.ItemBank.Column.Domain': 'Domain',
    'MursionPortal.ScenarioBank.ItemBank.Column.Translations': 'Übersetzungen',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Skill': 'Fertigkeit erstellen',
    'MursionPortal.Dashboard.Label.ScenarioActivity.ErrorMessage': 'Bitte wähle mindestens ein Projekt und Szenario aus den Filtern aus',
    'MursionPortal.Learner.Onboarding': 'Onboarding von Lernenden',
    'MursionPortal.Tab.Onboarding': 'ONBOARDING',
    'MursionPortal.OrganizationName.Placeholder': 'Gib den Namen deiner Organisation ein',
    'Dashboard.LeftPane.Learners.LearnerLockedOut': 'Lernender ausgesperrt',
    'Dashboard.Learners.LearnerLockedOut.TableHeader': 'Lernende, die aufgrund der Versäumnis-/Absagen-Rate von der Terminplanung ausgeschlossen wurden',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn': 'Datum/Uhrzeit für die Planung gesperrt',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn': 'Datum/Uhrzeit bis zur automatisierten Veröffentlichung',
    'MursionPortal.DontHaveEmail.Text': 'Du hast keine geschäftliche E-Mail-Adresse bei deiner Organisation?',
    'MursionPortal.InvalidCompanyName.Error': 'In deiner Organisation müssen sich Benutzer mit ihrer E-Mail-Adresse anmelden. Bitte gib diese oben ein.',
    'MursionPortal.LearnerOnboardingPage.Text.Welcome': 'Willkommen',
    'MursionPortal.LearnerOnboardingPage.Text.MursionVideoOverview': 'Mursion-Videoübersicht',
    'MursionPortal.LearnerOnboardingPage.Text.KeyThingsToKnow': 'Wichtige Dinge, die Du wissen solltest',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Text': 'Wir freuen uns, dich zu deinem Mursion-Lernprogramm begrüßen zu dürfen! Mursion nutzt immersive Simulationen, die durch Human-in-the-Loop-Technologie gesteuert werden, um ein umfassendes Lernerlebnis zu bieten, das dich mit den notwendigen Werkzeugen und Techniken ausstattet, um in der sich ständig weiterentwickelnden Welt erfolgreich zu sein.',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Beginner.Text': 'Egal, ob Du Anfänger oder erfahrener Profi bist, unser Programm hat für jeden etwas zu bieten. Wir freuen uns sehr, dass Du nun loslegen kannst.',
    'MursionPortal.LearnerOnboardingPage.Video.Section.Text': 'Schau dir zunächst diesen Videoüberblick <strong>über Mursion</strong> an!',
    'MursionPortal.LearnerOnboardingPage.Scheduled.Section.Text': '<strong>Ab jetzt kannst Du:</strong> Deine erste Simulation planen!',
    'MursionPortal.LearnerOnboardingPage.keysThingsToKnow.Section.Heading': 'Als nächstes gehen wir einige wichtige Dinge durch, über die Du Bescheid wissen solltest!',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology': 'Mursion-Methodik',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology.Summary': 'Mursion basiert auf Forschung und fördert nachweislich Verhaltensänderungen. Mit unseren Avatar-basierten Simulationen lernst Du, in emotional aufgeladenen Situationen angemessen zu reagieren.',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Text': 'Warum Avatare',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Summary': 'Mursion ist ein effektiveres Werkzeug als herkömmliche Methoden oder Rollenspielmethoden. Untersuchungen zeigen, dass Menschen bei der Interaktion mit Avataren vertrauensvoller und neugieriger sind als bei der Interaktion mit ihren Kollegen oder Managern.',
    'MursionPortal.LearnerOnboardingPage.Human.Text': 'Human-in-the-Loop',
    'MursionPortal.LearnerOnboardingPage.Human.Summary': 'In jeder Mursion-Simulation gibt es einen Live-Simulation Specialist, der in jeder Simulation den gesprochenen Dialog und die Bewegungen für bis zu fünf virtuelle Avatare bereitstellt.',
    'MursionPortal.LearnerOnboardingPage.Practice.Text': 'Warum Übung wichtig ist',
    'MursionPortal.LearnerOnboardingPage.Practice.Text.Summary': 'Die Verbesserung der Führungsqualitäten ist keine leichte Aufgabe. Untersuchungen zeigen jedoch, dass Lernen am effektivsten ist, wenn es Übungen beinhaltet. Mursion legt Wert auf die Praxis, indem es Räume schafft, die sicher, dynamisch und zugänglich sind.',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Domain': 'Domäne erstellen',
    'MursionPortal.ScenarioBank.ItemBank.Level.L1': 'L1',
    'MursionPortal.ScenarioBank.ItemBank.Level.L2': 'L2',
    'MursionPortal.ScenarioBank.ItemBank.Level.L3': 'L3',
    'MursionPortal.Label.OrganizationName': 'Name der Organisation',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Pathway': 'Pfad erstellen',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Event': 'Ereignis erstellen',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Resource': 'Ressource erstellen',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Outcome': 'Ergebnis erstellen',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Mindset': 'Denkweise erstellen',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Avatar': 'Erstelle einen Avatar-Namen',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.ResearchSummary': 'Forschungszusammenfassung erstellen',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.LearnerInstruction': 'Lernanweisung erstellen',
    'MursionPortal.ScenarioBank.ItemBank.BackButton.Label': 'Zurück zur Szenariobank',
    'MursionPortal.ScenarioBank.ItemBank.Title.Placeholder': 'Hier tippen',
    'MursionPortal.Dashboard.NextOnProject.Footer.MissOrCancelationRateFulfilled.Text': 'You cannot schedule this simulation as you have reached the missed or cancellation limit set by your organization. Please reach out to your program admin to allow scheduling.',// TODO add translations
    'MursionPortal.ScenarioInfo.Show': 'Anzeigen',
    'MursionPortal.ScenarioInfo.CertificationHistory': 'Zertifizierungsverlauf',
    'MursionPortal.Session.AlreadyBooked': 'Leider wurde die Sitzung bereits von einem anderen SIM akzeptiert.',
    'MursionPortal.CustomizeInvitation.Title': 'Anpassen deiner Einladungen',
    'MursionPortal.CustomizeInvitation.SubTitle': 'Überschreibe zum Anpassen den Text für jedes Feld unten oder behalte den Standardtext bei.',
    'MursionPortal.CustomizeInvitation.IntroMessage': 'Einleitungsnachricht an die Lernenden (die verfügbaren Szenarien werden automatisch mit der untenstehenden Nachricht ausgefüllt):',
    'MursionPortal.CustomizeInvitation.Label.SenderName': 'Name des Absenders (E-Mail-Adresse stammt von noreply@mursion.com)',
    'MursionPortal.CustomizeInvitation.Label.Subject': 'Thema',
    'MursionPortal.InviteLearnersTeam.Section.Title': 'Welche(s) Team(s) möchtest Du zur Terminvereinbarung einladen?',
    'MursionPortal.InviteLearnersTeam.Section.SubTitle': 'Die Lernenden werden zu allen Szenarios dieses Projekts eingeladen, zu deren Planung sie berechtigt sind',
    'MursionPortal.InviteLearnersTeam.ScenariosAssigned.Text': 'zugewiesene Szenarien',
    'MursionPortal.Confirmation.Modal.Invitations.Text': 'Deine Einladungen wurden geplant!',
    'MursionPortal.ScenarioSetting.ValidationMessage': 'muss kleiner oder gleich der gleichen Einstellung auf Kunden- und/oder Projektebene sein.',
    'MursionPortal.ScenarioBank.EventDetail.Details': 'Details',
    'MursionPortal.ScenarioBank.CharacterLimit.TitleMessage': 'Zeichenbeschränkung: {titleMaxLength}',
    'MursionPortal.ScenarioBank.CharacterLimit.DescriptionMessage': 'Zeichenbeschränkung: {descriptionMaxLength}',
    'MursionPortal.ScenarioBank': 'Veranstaltungsvorlage',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header': 'Wecke in anderen Selbstvertrauen und Vertrauen, damit sie Informationen über ihre Arbeit teilen',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title': 'Domaindetails',
    'MursionPortal.ScenarioBank.ItemBank.Edit.DescriptionOfUpdate.Label': 'Durch das Speichern dieser Änderungen wird dieser {type} in allen Szenarios aktualisiert. Bitte füge eine kurze Beschreibung der Änderungen hinzu:',
    'Dashboard.Learners.LearnerLockedOut.LearnerColumn.Tooltip': 'Name oder E-Mail des Benutzers',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Certified': 'Zertifiziert',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Decertified': 'Dezertifiziert',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Inactive': 'Inaktiv',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease': 'Lernenden für die Planung freigeben',
    'MursionPortal.CustomizeInvitation.Sender.Name': 'Mursion',
    'MursionPortal.CustomizeInvitation.Subject.Title': 'Mach dich bereit, deine Fähigkeiten zu üben! Deine Mursion-Sitzungen können jetzt geplant werden.',
    'MursionPortal.CustomizeInvitation.Intro.Message': 'Du wurdest eingeladen, an einer Simulation bei Mursion teilzunehmen. Klicke unten, um eine Sitzung zu vereinbaren, oder besuche dein Dashboard und erfahre mehr.',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.ConfirmationText': 'Bist Du sicher, dass Du {code} Lernende für die Planung freigeben möchtest?',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.SuccessText': '{code} Lernende wurden für die Terminplanung freigegeben.',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn.Tooltip': 'Datum/Uhrzeit, als der Lernende für die Planung gesperrt wurde',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn.Tooltip': 'Datum/Uhrzeit, wann der Lernende automatisch freigeschaltet wird',
    'MursionPortal.ScenarioBank.ItemBank.NextGenScenario': 'NextGen-Szenario',
    'MursionPortal.ScenarioBank.ResourceFilter.Book': 'Buch',
    'MursionPortal.ScenarioBank.ResourceFilter.Article': 'Artikel',
    'MursionPortal.ScenarioBank.Filter.Label.Publisher': 'Herausgeber',
    'MursionPortal.ScenarioBank.ItemBank.Level.Placeholder': 'Level auswählen',
    'MursionPortal.ScenarioBank.ItemBank.Domain.Placeholder': 'Domain suchen oder auswählen:',
    'MursionPortal.Label.CompletionRate.Tooltip': 'Die Fertigstellungsrate gibt an, wie oft ein Lernender eine Simulation abschließen kann, bevor die Fähigkeit zur Terminplanung entzogen wird (Nutzersperrung).',
    'MursionPortal.Label.SchedulingRate.Tooltip': 'Die Planungsrate gibt an, wie oft ein Lernender eine Simulation planen kann, bevor die Fähigkeit zur Terminplanung entzogen wird (Nutzersperrung).',
    'MursionPortal.Label.MissCancellationRate.Tooltip': 'Die Versäumnis-/Abbruchrate gibt an, wie oft ein Lernender eine Simulation verpassen oder abbrechen kann, bevor die Fähigkeit zur Terminplanung entzogen wird (Nutzersperrung).',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time.Tooltip': '„Planungssperrstunden“ gibt an, wie viele Stunden die geplante Sperrung dauern wird',
    'MursionPortal.ScenarioBank.ItemBank.Mindset.Type.Derailing': 'Entgleisung',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Skill': 'Fertigkeitsdetails',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Mindset': 'Details zur Denkweise',
    'MursionPortal.ProjectCreate.DeliveryHours': 'Lieferzeiten',
    'MursionPortal.ProjectCreate.DeliveryHours.Tooltip': 'Zeitrahmen, für den den Lernenden Sofort-Slots angezeigt werden',
    'MursionPortal.ProjectCreate.DeliveryHours.Start': 'Lieferzeiten Start',
    'MursionPortal.ProjectCreate.DeliveryHours.End': 'Lieferzeiten Ende',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.LearnerInstruction': 'Lernanweisung Details',
    'MursionPortal.ScenarioBank.ItemBank.Avatar.Edit.Header.Title': 'Avatar-Details',
    'MursionPortal.ScenarioBank.Label.AddLink': 'Link hinzufügen',
    'MursionPortal.ScenarioBank.Year.placeholderText': 'Jahr',
    'MursionPortal.ScenarioBank.ItemBank.Resource.Edit.Header.Title': 'Ressourcendetails',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Edit.Header.Title': 'Ergebnisdetails',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Advanced': 'Fortgeschritten',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Intermediate': 'Mittleres Niveau',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Foundational': 'Grundkenntnisse',
    'MursionPortal.ScenarioBank.ItemBank.Skill': 'Fähigkeit',
    'MursionPortal.Resources.Search.Text': 'Ressource(n) suchen oder auswählen',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries.Details': 'Details zu Forschungszusammenfassungen',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary': 'Forschungszusammenfassung',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Pathway': 'Pfad-Details',
    'MursionPortal.ScenarioBank.ItemBank.Pathway': 'Pfad',
    'MursionPortal.ScenarioBank.ConfirmationText.Archive': 'Bist Du sicher, dass Du diesen {block} archivieren möchtest?',
    'MursionPortal.ScenarioBank.ConfirmationText.Unarchived': 'Bist Du sicher, dass Du die Archivierung dieses {block} aufheben möchtest?',
    'MursionPortal.ScenarioBank.Unarchived': 'Archivierung aufheben',
    'MursionPortal.ScenarioBank.ItemBank.Resource': 'Ressource',
    'MursionPortal.ScenarioBank.ItemBank.Mindset': 'Denkweise',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction': 'Lernanweisung',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Event': 'Veranstaltungsdetails',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives': 'Avatar-Perspektiven',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.Intro': 'Einführung',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.DiscProfile': 'Disc-Profil',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.VerbalStyle': 'Verbaler Stil',
    'MursionPortal.ScenarioBank.Content': 'Inhalt',
    'MursionPortal.ScenarioBank.Content.ScenarioTitle': 'Szenario-Titel',
    'MursionPortal.ScenarioBank.Content.ScenarioCoverStory': 'Szenario-Titelgeschichte',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Placeholder': 'Wähle ein Bankergebnis aus',
    'MursionPortal.ScenarioBank.Content.WhyThisMatters': 'Warum das wichtig ist',
    'MursionPortal.ScenarioBank.NextGen.AvatarGuide': 'Avatar-Leitfaden',
    'MursionPortal.ScenarioBank.NextGen.ViewScenario': 'Szenario anzeigen',
    'MursionPortal.ScenarioBank.NextGen.Event': 'Ereignissymbol',
    'MursionPortal.NextOnProject.Card.JoinNow': 'Nimm an der Live-Simulation teil',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Title': 'Wie es funktioniert',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Intro': 'Hallo! Du wirst in Kürze mit einem Avatar verbunden werden, um <code>jetzt an einer Live-Simulation teilzunehmen</code>.',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Matched': 'Sobald dir ein Avatar zugewiesen wurde, klicke auf <code>’An der Live- Simulation teilnehmen’</code>',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Allow': 'Klicke auf <code>’Zulassen’</code> für den Zugriff auf Mikrofon/Kamera, wenn Du dazu aufgefordert wirst',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.StartSession': 'Klicke auf <code>’Sitzung starten’</code> und warte darauf, dass der Avatar deine Simulation beginnt.',
    'MursionPortal.ScenarioBank.NextGen.OutOfSite.Label': 'Aus den Augen, aus dem Sinn',
    'MursionPortal.FirstGen.Scenario.Button.Text': '1. Gen',
    'MursionPortal.NextGen.Scenario.Button.Text': '2. Gen',
    'MursionPortal.NextGen.Scenario.Assessment': 'Bewertung',
    'MursionPortal.NextGen.Scenario.OutOfSite': 'Aus den Augen, aus dem Sinn',
    'MursionPortal.NextGen.Scenario.Synopsis': 'Zusammenfassung',
    'MursionPortal.NextGen.Scenario.MinimumPoints': 'Erforderliche Mindestpunktzahl für ein erfolgreiches Ergebnis',
    'MursionPortal.NextGen.Scenario.Successful': 'Erfolgreich',
    'MursionPortal.NextGen.Scenario.OpportunityDevelop': 'Chance zur Weiterentwicklung',
    'MursionPortal.NextGen.Scenario.LibraryInfo': 'Bibliotheksinformationen',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DifficultyLevel': 'Schwierigkeitsgrad',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioCoverImage': 'Szenario-Titelbild',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioThumbnailImage': 'Szenario-Miniaturbild',
    'MursionPortal.NextGen.Scenario.LibraryInfo.CoverImageDescription': 'Titelbildbeschreibung',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ThumbnailImageDescription': 'Miniaturbildbeschreibung',
    'MursionPortal.ScenarioBank.CharacterLimit.ResponseMessage': 'Zeichenbeschränkung: {responseMaxLength}',
    'MursionPortal.ScenarioBank.EventDetail.AvatarResponseTitle': 'Mögliche Antwort(en) des Avatars',
    'MursionPortal.NextGen.Scenario.ReflectionGuide': 'Reflexionsleitfaden',
    'MursionPortal.NextGen.Scenario.Scoring': 'Wertung',
    'MursionPortal.ErrorMessage.buildingBlock': 'Die Baustein-ID muss angegeben werden',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DragAndDropText': 'Ziehen deine Datei per Drag-and-Drop hierher oder klicke hier, um Dateien auszuwählen',
    'MursionPortal.NextGen.Scenario.Publish': 'Veröffentlichen',
    'MursionPortal.NextGen.Scenario.Publish.Label': 'Bitte fülle zur Veröffentlichung die Pflichtfelder (*) aus.',
    'MursionPortal.NextGen.Scenario.Last.Publish.Label': 'Zuletzt veröffentlicht am {publishDate} von {publishedBy}',
    'MursionPortal.EulaAgreement.CodeOfConductCheckBox.Text': 'Ich stimme dem Simulationsdurchführungsmodell von Mursion zu und bestätige, den Mursion-Verhaltenskodex in allen Simulationen, an denen ich teilnehme, einzuhalten und zu respektieren. Erfahre <code>hier</code> mehr über Mursion-Simulationen und unseren Verhaltenskodex.',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Text': 'Ich habe im Moment keine Zeit dafür',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Text': 'Ich weiß nicht, was das ist',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Text': 'Ich werde später noch einmal darauf zurückkommen',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Description.Text': 'Die meisten Leute, die diese Simulation durchgeführt haben, empfanden sie als wertvolle Nutzung ihrer Zeit. Dies nimmt maximal 30 Minuten in Anspruch.',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Description.Text': 'Anstelle eines Videos hilft dir diese interaktive Übung dabei, Kompetenzen und Fertigkeiten in einem sicheren und dynamischen Raum zu entwickeln.',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Description.Text': 'Sicher! Du kannst jederzeit während der von der Organisation festgelegten Zeiten zurückkommen und an der Live-Simulation teilnehmen, um deine Fähigkeiten zu üben!',
    'MursionPortal.JoinNow.CancellationText.Heading': 'Warum willst Du den Vorgang abbrechen?',
    'MursionPortal.JoinNow.CancellationPage.Title': 'Übungsgelegenheit mit dem Avatar!',
    'MursionPortal.JoinNow.CancellationPage.Text': 'Identifiziere positive Wege zur Lösung der Situation im Rahmen der Starbucks-Richtlinien und -Leitfäden und suche nach Möglichkeiten, Best Moments für alle Kunden zu schaffen.',
    'MursionPortal.JoinNow.JoinSimulation.Button.Text': 'Weiter und an der Simulation teilnehmen',
    'MursionPortal.LearnerDashboard.JoinNow.SearchingAvatar': 'Wir suchen einen Avatar <code>für deine Live-Simulation!</code>',
    'MursionPortal.LearnerDashboard.JoinNow.SuccessText': '<code>Herzlichen Glückwunsch</code>, dir wurde ein Avatar für deine Live-Simulation zugewiesen!',
    'MursionPortal.LearnerDashboard.JoinNow.ConnectingText': 'Wir <code>verbinden dich mit deinem Avatar</code> und deine Live-Simulation beginnt in Kürze!',
    'MursionPortal.LearnerDashboard.JoinNow.DoNotCloseText': 'Bitte schließe dieses Browserfenster nicht!',
    'MursionPortal.LearnerDashboard.JoinNow.StrategiesToUse': 'Zu verwendende Strategien',
    'MursionPortal.LearnerDashboard.JoinNow.PracticeOpportunityText': 'Übungsgelegenheit mit dem Avatar!',
    'MursionPortal.Text.Sorry': 'Sorry',
    'MursionPortal.Text.Matching': 'Zuweisung',
    'MursionPortal.Text.Connecting': 'Verbinden',
    'MursionPortal.JoinNowScenarioInfoCard.Text': 'Bitte komme später noch einmal vorbei oder versuche es in ein paar Minuten noch einmal!',
    'MursionPortal.JoinNowScenarioInfoCard.HeaderText': 'Alle unsere Avatare sind mit anderen Lernenden beschäftigt!',
    'MursionPortal.JoinNow.CancellationPage.GoBackButton.Text': 'Abbrechen und zum Dashboard zurückkehren',
    'MursionPortal.NextGen.Scenario.Event.AddMessage': 'Füge mindestens 2 hinzu',
    'MursionPortal.ScenarioBank.ItemBank.Events.AddButton': '+ Hinzufügen',// chnaged
    'MursionPortal.NextGen.ScenarioBank.Event.Select.Placeholder': 'Wähle ein Ereignis aus',
    'MursionPortal.NextGen.ScenarioBank.Skill.Select.Placeholder': 'Wähle eine Fähigkeit aus',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarBehavior.Label': 'Avatar-Verhalten*',
    'MursionPortal.NextGen.ScenarioBank.Event.LearnerResponse.Label': 'Erwartete Antwort des Lernenden*',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarAdd.Button': 'Antwort hinzufügen',
    'MursionPortal.NextGen.ScenarioBank.AvatarResponseName.Select.Placeholder': 'Wähle einen Avatar aus',
    'MursionPortal.NextGen.ScenarioBank.Label.AvatarPossibleResponse': 'Mögliche Antwort(en) des Avatars',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentObservedAction.Label': 'Beobachtete Aktion beschreiben',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentImapct.Label': 'Erkläre die Auswirkungen',
    'MursionPortal.NextGen.ScenarioBank.StrengthAssessment': 'Stärkenbewertung(en)*',
    'MursionPortal.NextGen.ScenarioBank.Button.StrengthAssessment': '+ Stärkenbewertung hinzufügen‘',
    'MursionPortal.NextGen.ScenarioBank.Label.Strength': 'Stärke',
    'MursionPortal.NextGen.ScenarioBank.OpportunityAssessment': 'Chancenbewertung(en)*',
    'MursionPortal.NextGen.ScenarioBank.Button.OpportunityAssessment': '+ Chancenbewertung hinzufügen‘',
    'MursionPortal.NextGen.ScenarioBank.Label.Opportunity': 'Gelegenheit',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Select.Placeholder': '„Positive Denkweise“ auswählen',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Select.Placeholder': 'Entgleisende Denkweise auswählen',
    'MursionPortal.NextGen.Scenario.Event.Scoring.Title': 'Ereignisbewertung',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Title': 'Positive und Entgleisende Denkweisen hinzufügen*',
    'MursionPortal.NextGen.Scenario.Event.Positive.Button.Text': 'Zusätzliche Positive Einstellung hinzufügen',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Button.Text': 'Zusätzliche Entgleisende Denkweise hinzufügen',
    'MursionPortal.Text.Searching': 'Suche läuft',
    'MursionPortal.NextGen.Scenario.Event.MindsetsNavigated': 'Navigierte Denkweisen',
    'Dashboard.ContractProgress.Column.RemainingUtilization': 'Verbleibende Nutzung',
    'Dashboard.ContractProgress.Column.EstimatedRemaining': 'Geschätzte Restmenge',
    'MursionPortal.Label.Completion': 'Fertigstellungsrate',
    'Dashboard.Report.ReportSessionOption.AllDays': 'Alle Sitzungen',
    'MursionPortal.Status.Abbreviation.EarlyCancelled': 'EC',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EstimatedRemaining': 'Geschätzte Restmenge',
    'Dashboard.ContractProgress.ColumnTooltip.RemainingUtilization': 'Verbleibende Simulationen und geplant als Anzahl der gekauften Simulationen',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdownQuickView': 'Sitzungsaufschlüsselung – Schnellansicht',
    'MursionPortal.NextGen.SelectorText.Race': 'Rasse',
    'MursionPortal.NextGen.SelectorText.Ethnicity': 'Ethnische Herkunft',
    'MursionPortal.NextGen.SelectorText.None': 'Keine',
    'MursionPortal.NextGen.SelectorText.Woman': 'Man muss eine Frau sein',
    'MursionPortal.NextGen.SelectorText.Man': 'Man muss ein Mann sein',
    'MursionPortal.NextGen.SelectorText.Wheelchair': 'Man muss im Rollstuhl sitzen',
    'MursionPortal.NextGen.SelectorText.agedText': 'Man muss mindestens 55 Jahre alt sein',
    'MursionPortal.NextGen.Avatar.Restriction': 'Avatar-Einschränkung',
    'MursionPortal.NextGen.AvatarOrScenarioComposition': 'Avatar-/Szenario-Komposition',
    'MursionPortal.Label.Opening': 'Einleitung',
    'MursionPortal.Label.Closing': 'Abschluss',
    'MursionPortal.Label.Introduction': 'Einführung',
    'MursionPortal.ReflectionGuide.LearnerAssessment': 'Lernende Beurteilung',
    'MursionPortal.ReflectionGuide.HostAssessment': 'Gastgeberbewertung',
    'MursionPortal.ReflectionGuide.HostOpportunity': 'Gastgebergelegenheit',
    'MursionPortal.ReflectionGuide.Introduction.Text': 'Willkommen zurück! Fühlst Du dich bereit, über deine Erfahrungen nachzudenken? (Erlaube dem Lernenden, zuzustimmen, bevor Du fortfährst.)',
    'MursionPortal.Label.Say': 'SAGEN',
    'MursionPortal.Label.Ask': 'FRAGEN',
    'MursionPortal.Label.Bullet': 'Stichpunkt',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FirstQuestion': 'Wie verlief das Gespräch?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.SecondQuestion': 'Wie passte die Realität des Gesprächs zu deinem Gesprächsplan?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.ThirdQuestion': 'Was ist passiert?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FourthQuestion': 'Gab es irgendwelche Hinweise (verbal und nonverbal) vom Avatar, die dir einen Hinweis darauf gaben, wie das Gespräch verlaufen würde?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FifthQuestion': 'Gab es irgendwelche Hinweise (verbal und nonverbal) vom Avatar, die dir einen Hinweis darauf gaben, wie das Gespräch verlaufen würde?',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FirstSay': 'Bei diesem Gespräch musstest Du dich mit einigen herausfordernden Dynamiken auseinandersetzen – Mickey war frustriert.',
    'MursionPortal.ReflectionGuide.LearnerAssessment.SecondSay': 'Obwohl es gut gemeint ist, sehe ich oft, dass Führungskräfte nicht so produktiv reagieren, indem sie:',
    'MursionPortal.ReflectionGuide.LearnerAssessment.ThirdSay': 'Ich habe auch gesehen, dass Führungskräfte produktiver reagierten, indem sie:',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FourthSay': 'Dieses Gespräch hat dich dazu gebracht, einige herausfordernde Dynamiken zu meistern',
    'MursionPortal.ReflectionGuide.LearnerAssessment.Ask': 'Was glaubst du, wo du angelangt bist? (Nachfragen: Warum/ Wie? Woher weißt Du das?): Was glaubst du, wo du angelangt bist? (Folgefrage: Warum/ Woher/ Woher weißt Du das?)',
    'MursionPortal.ReflectionGuide.HostAssessment.Opening': 'Wenn ich eine der Stärken hervorheben könnte, die Du während des Treffens gezeigt hast (wähle eine Option aus):',
    'MursionPortal.ReflectionGuide.HostAssessment.Closing': 'Stimmen meine Überlegungen mit deinen Überlegungen überein? Warum oder warum nicht?',
    'MursionPortal.ReflectionGuide.HostAssessment.Opportunity.Opening': 'Wenn ich eine der Entwicklungsmöglichkeiten, die ich beobachtet habe, hervorheben könnte (wähle eine Option aus):',
    'MursionPortal.ReflectionGuide.Closing.Say': 'Das IST eine schwierige Sache und ich danke dir, dass Du diese wichtigen Fähigkeiten heute mit Mursion geübt hast!',
    'MursionPortal.ReflectionGuide.Closing.Ask': 'Was nimmst du aus dieser Erfahrung mit, wenn du das nächste Mal in eine ähnliche Situation im wirklichen Leben kommst?',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary.Placeholder': 'Wähle eine Forschungszusammenfassung aus',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction.Placeholder': 'Eine Lernanweisung auswählen',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ClinkHereToReplaceThisFile': 'Klicke hier, um diese Datei zu ersetzen.',
    'MursionPortal.Status.Empty': ' ',
    'MursionPortal.ScenarioContent.Label': 'Fügen Sie ein Minimum von {min} und ein Maximum von {max} hinzu.',
    'MursionPortal.Search.Text.Skills': 'Fähigkeiten(n) suchen oder auswählen',
    'MursionPortal.Search.Text.Avatar': 'Avatar(e) suchen oder auswählen',
    'MursionPortal.NextGen.Scenario.TimelineAndScheduling': 'Zeitleiste und Terminplanung',
    'MursionPortal.NextGen.Session.Replay': 'Sitzungswiederholung',
    'MursionPortal.NextGen.StrengthsAndOpportunities': 'Stärken und Chancen',
    'MursionPortal.NextGen.Recommendations': 'Empfehlungen',
    'MursionPortal.NextGen.MoreToDiscover': 'Mehr zu entdecken',
    'MursionPortal.NextGen.MissionAccomplished': 'Mission erfüllt!',
    'MursionPortal.NextGen.Learner.ScenarioMaterials': 'Szenariomaterialien',
    'MursionPortal.NextGen.Learner.PathwayAffiliation': 'Pathway-Zugehörigkeit',
    'MursionPortal.NextGen.Learner.SkillLevel': 'Fähigkeitslevel',
    'MursionPortal.NextGen.Learner.YourMission': 'Ihre Mission',
    'MursionPortal.ScenarioBank.ShowOnlySecondGenscenarios': 'Nur 2. Gen Szenarios anzeigen',
    'MursionPortal.NextGen.CreateNewSkill.Header.Text': 'Eine neue Bankfähigkeit erstellen',
    'MursionPortal.NextGen.Learner.StartSimulation': 'Simulation starten',
    'MursionPortal.SimMaterials.Label.CoverStory': 'Titelstory',
    'MursionPortal.SimMaterials.Label.LearnerMission': 'Mission des Lernenden',
    'MursionPortal.SimMaterials.Label.Background': 'Hintergrund',
    'MursionPortal.SimMaterials.Label.PerspectivesAndIdentities': 'Perspektiven & Identitäten',
    'MursionPortal.SimMaterials.HeaderText.SimDashboard': 'SIM-Dashboard',
    'MursionPortal.NextGen.Learner.ToStart': 'zum Starten',
    'MursionPortal.Project.DeliveryHours.Standard': 'Standardlieferzeiten',
    'MursionPortal.Project.DeliveryHours.Global': 'Globale Lieferzeiten',
    'MursionPortal.Label.Monday.ShortForm': 'Mo',
    'MursionPortal.Label.Tuesday.ShortForm': 'Di',
    'MursionPortal.Label.Wednesday.ShortForm': 'Mi',
    'MursionPortal.Label.Thursday.ShortForm': 'Do',
    'MursionPortal.Label.Friday.ShortForm': 'Fr',
    'MursionPortal.Label.Saturday.ShortForm': 'Sa',
    'MursionPortal.Label.Sunday.ShortForm': 'So',
    'MursionPortal.NextGen.CreateFromBank.Customized.Label': 'Angepasst an das Bankszenario',
    'MursionPortal.NextGen.CreateFromBank.CustomizedFromBank.Tooltip': 'Dieses Szenario wurde vom ursprünglichen Bankszenario angepasst. Alle am Bankszenario vorgenommenen Aktualisierungen werden nicht auf dieses Szenario übertragen.',
    'MursionPortal.BuildingBlock.Label.PublishedDate': 'Veröffentlichungsdatum',
    'MursionPortal.Label.CopyTeamInviteLink': 'Team-Einladungslink kopieren',
    'MursionPortal.NextGen.SessionReplayText': 'Dieses Gespräch war für Sie eine Übungsgelegenheit, um ein Gespräch zu ermöglichen, das einem Teammitglied in einer schwierigen Zeit Unterstützung gab. Ihr Erfolg hier hing von Ihrer Fähigkeit ab, Mitgefühl für Jo zu entwickeln.',
    'MursionPortal.NextGen.TheMission': 'Die Mission:',
    'MursionPortal.NextGen.DemonstratedStrength': 'Bewiesene Stärke',
    'MursionPortal.NextGen.OpportunityForFutureGrowth': 'Chance für zukünftiges Wachstum',
    'MursionPortal.NextGen.CompetenciesFocus': 'Im Fokus standen folgende Kompetenzen:',
    'MursionPortal.NextGen.StrengthAndOpportunitiesText': 'Während der Nachbesprechung des Gesprächs wurden eine beobachtete Stärke und eine Wachstumschance identifiziert:',
    'MursionPortal.NextGen.WeObserved': 'Wir beobachteten....',
    'MursionPortal.NextGen.WhyThisMatters': 'Warum das wichtig ist',
    'MursionPortal.ScenarioSetting.MissOrCancelationRate.ValidationMessage': 'Die geplante Sperrzeit kann nicht null sein, wenn die Fehl-/Stornierungsrate nicht null ist.',
    'MursionPortal.NextGen.CreateFromBank.Customized.Modal.Text': 'Sie haben ein Bankszenario angepasst und zukünftige Änderungen am Bankszenario werden in diesem Szenario nicht berücksichtigt. Möchten Sie fortfahren?',
    'MursionPortal.SimMaterials.Event.SkillsInFocus.Label': 'Fähigkeiten im Fokus',
    'MursionPortal.SimMaterials.Label.SimulationEvents': 'Simulationsereignisse',
    'MursionPortal.SimMaterials.Event.AvatarBehavior.Label': 'Avatar-Verhalten',
    'MursionPortal.SimMaterials.Event.LearnerResponse.Label': 'Erwartete Antwort des Lernenden',
    'MursionPortal.Label.NoTimeSelected': 'Keine Zeit ausgewählt',
    'MursionPortal.Label.StartTime.EndTime.Selected': 'Sowohl Startzeit als auch Endzeit müssen ausgewählt werden',
    'MursionPortal.SimMaterials.Label.ReflectionAndDebrief': 'Reflexion und Nachbesprechung',
    'MursionPortal.NextGen.Scenario.Planning': 'Planung',
    'MursionPortal.HowItWorks.Experience.Text': 'Ihr Erlebnis beginnt automatisch in wenigen Sekunden!',
    'MursionPortal.HowItWorks.MicCamera': 'Mikrofon/Kamera',
    'MursionPortal.HowItWorks.Prompt.Text': 'Wenn Sie dazu aufgefordert werden, klicken Sie auf <strong>’Zulassen’</strong>, um Zugriff auf Mikrofon/Kamera zu erhalten, damit der Avatar Sie sehen und hören kann!',
    'MursionPortal.HowItWorks.StartSession.Text': 'Klicken Sie auf <strong>’Sitzung starten’</strong> und lesen Sie zur Vorbereitung die Szenariobeschreibung durch!',
    'MursionPortal.LearnerMaterials.KeyCompetencies': 'Schlüsselkompetenzen',
    'MursionPortal.LearnerMaterials.ResearchMaterials': 'Forschungsmaterialien',
    'MursionPortal.LearnerMaterials.PracticeOpportunity': 'Übungsmöglichkeit',
    'MursionPortal.ScenarioInfo.Label.ScenarioBank': 'Szenario wird aus der Bank erstellt',
    'MursionPortal.DemandBasedScheduling.SlotSelectionMessage': 'Bitte wählen Sie Zeitfenster aus, die für Sie in Frage kommen. Wir planen Ihr Szenario in einem von Ihnen gewählten Zeitfenster.',
    'MursionPortal.NextGen.ScenarioBank.SubStrengthAssessment': 'Stärke',
    'MursionPortal.NextGen.ScenarioBank.SubOpportunityAssessment': 'Gelegenheit',
    // needs translations
    'MursionPortal.EngagementHub.EmailLearners': 'Email Learners', // TODO add translations
    'MursionPortal.EngagementHub.InviteToTeam': 'Invite to Team', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleTitle': 'Invite your learners to start or continue their practice', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleSummary': 'Engage your learners via email or by embedding shared links in your own communications.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations': 'View Invitations', // TODO add translations
    'MursionPortal.EngagementHub.CreateInvite': 'Create Invite', // TODO add translations
    'MursionPortal.NextGen.replayConversation':'Replay the Conversation',// TODO add translations
    'MursionPortal.EngagementHub.BackTo': 'Back to {clientName}', // TODO add translations
    'MursionPortal.EngagementHub.LearningPotential': 'At Mursion, we believe in maximizing every learner\'s potential.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationLearnMore': 'Communication Is Key! Learn more', // TODO add translations
    'MursionPortal.EngagementHub.UnlockTeamPotential': 'Unlock Your Team’s Potential', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Title': 'Invite your learners to schedule their simulations', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Summary': '58% of users are more likely to sign up for their simulation if you send a custom invitation to schedule.', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Label': 'Your Email, Your Way!', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Title': 'Manage email settings and gain insights', // TODO add translations
    'MursionPortal.EngagementHub.Customize': 'Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Title': 'Email Configurations', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Summary': 'Customize email messages that resonate with your learners and drive adoption and engagement.', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Title': 'Powerful Email Analytics', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Summary': 'Measure email open, click, and unread rates to discover insights and make data-driven decisions.', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Label': 'Add Personal Touch', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Title': 'Personalize messaging in the Mursion Portal', // TODO add translations
    'MursionPortal.EngagementHub.LearnerDashboard': 'Learner Dashboard', // TODO add translations
    'MursionPortal.EngagementHub.ScenarioPage': 'Scenario Pages', // TODO add translations
    'MursionPortal.EngagementHub.SchedulingPage': 'Scheduling Page', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackTooltip': 'This email will only go out if feedback report is enabled at project level. Please reach out to your Mursion representative to learn more.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Title': 'Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Summary': 'Use these tools to help customize different emails.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailName': 'Email Name', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SenderName': 'Sender Name (email will be no-reply@mursion.com)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ViewCustomize': 'View/Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EnableDisable': 'Enable/Disable', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockout': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.VideoFeedback': 'Video & Feedback', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ModifySettings': 'Click edit to modify the settings', // TODO add translations
    'MursionPortal.EngagementHub.BackTitle': 'Back to Engagement Hub', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey': 'Communication Is Key!', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.summary': 'A great Mursion rollout starts with effective communication to your learners. It\'s important to ensure they are prepared and understand the connection between Mursion and your learning and development program goals.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.TItle': 'Elements of Effective Learner Communication', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Title': 'Lead with Learner Benefits', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Summary': 'Lead with learner benefits, not product features. Share learner testimonials and tie the skills they are developing to business objectives.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Title': 'Practice makes Progress', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Summary': 'Practice is essential to developing new skills. Ensure your message highlights The Importance of Practice - And Our Reluctance To Do It to acknowledge challenges that learners face.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Title': 'Message from Leaders', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Summary': 'Ask senior leaders to communicate (meetings, written, video, etc) why Mursion sessions are valuable and how developing the target skills is tied to business priorities and growth opportunities.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Title': 'Set Expectations', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Summary': 'Mursion is a new technology that uses avatars powered by humans. Prepare learners for these conversations by sharing why and how avatars create psychology safety and is an effective way to practice.', // TODO add translations
    'MursionPortal.Tab.Engagement': 'Engagement', // TODO add translations
    'MursionPortal.Tab.EngagementHub': 'Engagement Hub', // TODO add translations
    'MursionPortal.Tab.EmailManagement': 'Email Management', // TODO add translations
    'MursionPortal.Tab.PortalMessaging': 'Portal Messaging', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.BackButton.Label': 'Back to Invite to Schedule', // TODO add translations
    'MursionPortal.Portal.Messaging.Heading.Text': 'Inform, engage, and delight learners using your organization’s unique brand voice.',  // TODO add translations
    'MursionPortal.Portal.Messaging.SubHeading.Text': 'Add custom messaging throughout the Portal to engage your learners.',  // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.InvitationDate': 'Invitation Date', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.MessageToLearners': 'Message to Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.ExcludedLearners': 'Excluded Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.CreatedBy': 'Created By', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text': '{scenariosCount} scenarios assigned to {learnerCount} learners', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text.AddLearners': 'Add Learners', // TODO add translations
    'MursionPortal.Tab.EmailManagement.Analytics': 'Analytics', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Teams.Invitation.Message': 'The following team(s) already have scheduled an invitation on this day:',  // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.New.Invitation.Message': 'Please make a note and start a new invitation',  // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Learners.Invitation.Text': 'Learners will receive the invitation in the early morning hours (Pacific time) on this day.',  // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.SendDate.Label': 'Send Date',  // TODO add translations
    'MursionPortal.Scenario.Generation.FilterType.Label' : 'Generation', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.CancelInvitation.Modal.Text': 'Are you sure you want to cancel the invitation created by {createdBy}?', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackReport': 'Learner Feedback Report', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByLearner': 'Cancellation by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.MissedSessionByLearner': 'Missed by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerReleased': 'Learner Released', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockOut': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SessionConfirmation': 'Session Confirmation', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Invitation': 'Welcome to Mursion - a platform for you to practice your skills', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.InvitationReminder': `Reminder:You're invited to {licenseeName}`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Confirmation': 'Thanks for signing up! Your Simulation is confirmed.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.FirstReminder': `Don't forget! Your Mursion simulation is soon!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.SecondReminder': `Get excited! Your Mursion simulation starts in {upcomingBeforeStart} minutes.`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CancellationByByLearner': 'Your simulation was canceled', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.MissedSessionByLearner': 'We missed you today!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerLockedOut': 'You missed/cancelled this scenario too many times', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerReleased': 'Your session is available to schedule', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerSessionRecordingIsReady': 'Want to see how you did? Visit your Mursion Dashboard.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerFeedbackReport': 'Want to see how you did? Review your session report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerSessionRecordingReady': 'Learner Session Recording Ready', // TODO add translations
    'MursionPortal.EngagementHub.BackToEmailSettings': 'Back to Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.CustomizeEmail.Text': 'Learners will start receiving this email after you save.', // TODO add translations
    'MursionPortal.NextGen.Learner.FeedbackFrom': 'Feedback from', // TODO add translations
    'MursionPortal.NextGen.Scenario.SimMaterials': 'SIM Materials', // TODO add translations
    'MursionPortal.NextGen.Scenario.LearnerMaterials': 'Learner Materials', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Modal.BodyText': 'Are you sure you want to save the Enable/Disable settings', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByPS': 'Session Rescheduled by PS', // TODO add translations
    'MursionPortal.EngagementHub.EmailPreview.Sender': 'Sender', // TODO add translations
    'MursionPortal.Scenario.CoverStory.Tooltip.Text': 'Cover Story will be visible to clients in the scenario library as well as learners and SIMs in their materials',// TODO add translations
    'MursionPortal.Scenario.Summary.Tooltip.Text':'Summary will be visible to clients in the scenario library',// TODO add translations
    'MursionPortal.NextGen.Event.positiveMindset': 'There must be at least ({startCount}) positive mindset defined. You can specify a maximum of ({endCount}) positive mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.derailingMindset': 'There must be at least ({startCount}) derailing mindset defined. You can specify a maximum of ({endCount}) derailing mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment': 'There must be at least ({startCount}) strength assessment defined. You can specify a maximum of ({endCount}) strength assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.OpportunityAssessment': 'There must be at least ({startCount}) opportunity assessment defined. You can specify a maximum of ({endCount}) opportunity assessments.', // TODO add translations 
    'MursionPortal.NextGen.Event.StrengthAssessment.AvatarResponse': 'You have added {addedCount} avatar response for strength assessment(s), you must add at least {totalCount} avatar response for strength assessment(s).', // TODO add translations 
    'MursionPortal.NextGen.Event.OpportunityAssessment.AvatarResponse': 'You have added {addedCount} avatar response for opportunity assessment(s), you must add at least {totalCount} avatar response for opportunity assessment(s).', // TODO add translations 
    'MursionPortal.Label.ClientLevelMissCancelSetting': 'Client Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ProjectLevelMissCancelSetting':'Project Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ScenarioLevelMissCancelSetting':'Scenario Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.MissedCancelledSessions':'Missed/Cancelled Sessions', // TODO add translations 
    'MursionPortal.EngagementHub.ViewInvitations.Column.SenderName': 'Sender Name', // TODO add translations,
    'MursionPortal.NextGen.Event.Delete.ConfirmBox': 'Are you sure you want to delete this {message}?', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.PositiveMindset': 'Positive Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.DerailingMindset': 'Derailing Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.StrengthAssessment': 'Strength assessment', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.OpportunityAssessment': 'Opportunity assessment', // TODO add translations,
    'MursionPortal.PersonalizedMessageTitle': 'Personalized message header. This will appear before every personalized message in Portal.', // TODO add translations
    'MursionPortal.NextGen.ScenarioReferences': 'Scenario References', // TODO add translations 
    'MursionPortal.NextGen.ChildScenarios': 'Child Scenarios', // TODO add translations 
    'MursionPortal.NextGen.Label.Customized': 'Customized', // TODO add translations 
    'MursionPortal.EngagementHub.EmailSettings.InvitationPasswordUsers': 'Invitation (Password Users)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InvitationReminderPasswordUsers': 'Invitation Reminder (Password Users)', // TODO add translations
    'MursionPortal.Label.Clicked': 'Clicked', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByLearner': 'Session Rescheduled by Learner', // TODO add translations
    'MursionPortal.Client.Team.CopyTeamInviteLink.Tooltip':'Users who have not yet registered or have not yet been added to the Portal will not be able to access this link. Please click the "Add Learners" button.', // TODO add translations
    'Settings.Config.LearnerSessionReportDelay': 'Learner Session Report Delay', // TODO add translations
    'MursionPortal.SkillsReferences.Label': 'Skills References', // TODO add translations
    'MursionPortal.ResearchSummariesReferences.Label': 'Research Summaries References', // TODO add translations
    'MursionPortal.Search.Text': 'Search or select', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Event': 'Please select Event', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Skill': 'Please select Skill', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarBehavior': 'Avatar Behavior is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExpectedLearnerResponse': 'Expected Learner Response is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindset': 'Please Select Positive Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindsetDescription': 'Positive mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindset': 'Please Select Derailing Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindsetDescription': 'Derailing mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DescribeObservedAction': 'Describe Observed Action is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExplainImpact': 'Explain Impact is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarName': 'Please select avatar name', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PossibleAvatarResponse':'Possible Avatar Response is required', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate': 'Scheduling Lockout Date', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate.Tooltip': 'Lockout effective date for all learners who miss and/or late cancel', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.MinimumScore': 'You’ve changed the number of events. Please update the minimum score.', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SynopsisDescription': 'Synopsis description is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SuccessfulAssessment': 'Successful Assessment is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.OpportunityAssessment': 'Opportunity Assessment is required', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.PathwaysAffiliation': 'Pathway Affiliation(s)', // TODO add translations
    'MursionPortal.NextGen.BankScenarioReferences': 'Bank Scenario References', // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerActivationStatus':'Learner Activation',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteTitle':'Unlock Your Team’s Potential',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteMessage':'Target invitations to your learners with custom messaging',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInvite':'Invite to Schedule',  // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetRegistered':'Not Yet Registered', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetScheduled':'Not Yet Scheduled', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ScheduledNotYetCompleted':'Scheduled - Not Yet Completed', // TODO add translations
    'MursionPortal.NextGen.ViewAndInviteLearners': 'View & Invite Learners', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.SchedulingEmail': 'Session Specific Emails and other Join Session Emails', // TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.InviteToScheduleEmail': 'Invite-to-Schedule Emails', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.CallToActionEmail': 'Emails sent to learners inviting them to schedule scenario(s)', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations': 'Client Customizations', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InviteToSchedule.Tooltip': 'Invite to Schedule emails are able to be customized each time you send them.', // TODO add translations
    'MursionPortal.Contract.ContractTimezone': 'Contract Timezone', // TODO add translations
    'Dashboard.LeftPane.Simulations.SessionSearch':'Search for Session', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle': 'Client Scenario Title', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle.Tooltip': 'Client learners and program admins will see this title only. SIMs will see the bank scenario title and client scenario title in their SIM materials.', // TODO add translations
    'MursionPortal.NextGen.Event.DeleteMessage': 'Minimum 2 Events are mandatory for a Scenario.', // TODO add translations
    'Settings.Config.ProjectAndScenario.AllowSimPracticeSession': 'SIM Practice Session', // TODO add translations
    'MursionPortal.Calendar.Button.Practice.Session': 'Practice Session', // TODO add translations
    'MursionPortal.Scenario.InTrainingSimSpecialist': 'In Training SIM Specialists', // TODO add translations
    'MursionPortal.Scenario.NoInTrainingSimSpecialist': 'No In Training Sim Specialist', // TODO add translations
    'MursionPortal.Simspecialist.Thankyou.summary': 'You are scheduled for your practice simulation', // TODO add translations
    'MursionPortal.Button.DoNotSaveMyWork': 'Don’t Save my Work', // TODO add translations
    'MursionPortal.NextGen.Confirm.BrowserBack': 'You have not saved your changes, and navigating away will cause you to lose your work. Please complete required fields.', // TODO add translations
    'MursionPortal.UserConsent.Learner.Description': 'Wenn Sie „Ich stimme zu“ sagen, verarbeitet die Mursion-Software Ihre Live-Simulation und erstellt eine Videoaufzeichnung Ihrer Simulationssitzung für Ihre Überprüfung im Mursion-Softwareportal. Sofern dies nicht durch geltendes Recht oder zu Qualitätssicherungszwecken vorgeschrieben ist, wird Mursion Ihre Videoaufzeichnung nicht ohne Ihre Erlaubnis verbreiten oder offenlegen. Mursion wird anonymisierte Daten aus Ihrer Nutzung seiner Software verwenden, um seine Simulationstechnologie zu verbessern.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.UserConsent.LearnerAndAccountOwnerOrFacilitator.Description': 'Wenn Sie „Ich stimme zu“ sagen, verarbeitet die Mursion-Software Ihre Live-Simulation und erstellt eine Videoaufzeichnung Ihrer Simulationssitzung für Ihre Überprüfung im Mursion-Softwareportal. Bitte beachten Sie, dass auf Wunsch des Kunden, der die Mursion-Software in Ihrem Namen lizenziert hat, eine Kopie Ihrer aufgezeichneten Simulationssitzung(en) an einen vom Kunden ausgewählten Coach, Mentor oder Gutachter weitergegeben wird. Alle Rezensenten sind vertraglich dazu verpflichtet, <code>Mursions Datenschutzerklärung</code> zu befolgen, es sei denn, Sie haben anderen Datenschutzbestimmungen zugestimmt. Sofern dies nicht durch geltendes Recht oder zu Qualitätssicherungszwecken vorgeschrieben ist, wird Mursion Ihre Videoaufzeichnung nicht ohne Ihre Erlaubnis verbreiten oder offenlegen. Mursion wird anonymisierte Daten aus Ihrer Nutzung seiner Software verwenden, um seine Simulationstechnologie zu verbessern.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.UserConsent.AccountOwnerOrFacilitator.Description': 'Ihre Simulationssitzung(en) werden auf Wunsch des Kunden, der die Mursion-Software in Ihrem Namen lizenziert hat, sowohl in Audio als auch in Video aufgezeichnet. Wenn Sie dem Fortfahren zustimmen, wird eine Kopie Ihrer aufgezeichneten Simulationssitzung(en) an einen vom Kunden ausgewählten Dritten weitergegeben, um die Aufzeichnung(en) zu analysieren und zu bewerten. Diese Personen sind vertraglich verpflichtet, die Datenschutzerklärung von Mursion zu befolgen, es sei denn, Sie haben anderen Datenschutzbestimmungen zugestimmt. Sie erhalten jedoch keine Kopie Ihrer aufgezeichneten Simulationssitzung(en) gemäß den Bedingungen der Aufgabe oder Bewertung, zu deren Durchführung Sie sich bereit erklärt haben, und haben auch keinen Zugriff darauf. Mursion kann Ihre Videoaufzeichnung zur Qualitätssicherung verwenden und anonymisierte Daten aus Ihrer Nutzung seiner Software verwenden, um seine Simulationstechnologie zu verbessern.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.ProjectCreate.DeliveryHours.Validation': 'Delivery Hours Start time should be less than End time.', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetRegistered':'Unique number of learners who have been added to the Portal but have not yet completed registration', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetScheduled':'Unique number of learners who have registered but have not yet scheduled a session', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ScheduledNotYetCompleted':'Unique number of learners who have registered and scheduled at least one session but have not yet completed a session', // TODO add translations
    'MursionPortal.Restriction': 'Restriction', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Placeholder' : 'Paste a session ID and hit return',  // TODO add translations
    'Operations.TechRestriction.CreateDialog.Title': 'New Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.EditDialog.Title': 'Edit Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.ViewDialog.Title': 'View Holiday Tech Restriction', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerHours': 'Trigger (Hours)', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerMin': 'Trigger (Minutes)', // TODO add translations
    'MursionPortal.Duplicate.Scenario.Confirmation.Text': 'Are you sure you want to duplicate this scenario?',  // TODO add translations
    'MursionPortal.Contract.LineItem.ToolTip': 'A contract can only contain one type of line item. If your contract has both sessions and hours, please convert the sessions into hours and add it as an hours line item.', // TODO add translations
    'MursionPortal.ReflectionGuide.LearnerPerspective': 'Learner Perspective', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Table.Placeholder': 'Search for Session will load once you search session ID', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.EmptySession': 'No sessions match that ID', // TODO add translations
    'MursionPortal.SimMaterials.Label.IntroAndPreReflection': 'Intro & Pre-Reflection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown': 'Learner Breakdown', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown.Activated': 'Activated', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionNumber': 'Version Number', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionDetails': 'Version Details', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderHours': 'Trigger {tiggerValue} hours before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderMinutes': 'Trigger {tiggerValue} minutes before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.FirstEmailReminder.Tooltip': 'Time before Session when First Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SecondEmailReminder.Tooltip': 'Time before Session when Second Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.SimAttendance.MissedSimulation.Message': 'You missed this simulation so you are unable to edit attendance. Please contact Support if you did not miss the simulation or had a technical issue that caused you to miss the simulation.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Disclaimer': 'Invites are visible for last 30 days only', // TODO add translations
    'MursionPortal.PortalVersion': 'Portal Version', // TODO add translations
    'MursionPortal.ScenarioInfo.Label.AutoSIM': 'AutoSIM', // TODO add translations
    'MursionPortal.ProjectContract.AddConfirmation': 'Are you sure you want to add the contract to this project?', // TODO add translations
    'MursionPortal.ProjectContract.CompletedContract.AddConfirmation': 'Are you sure you want to add the completed contract to this project?', // TODO add translations
    'MursionPortal.Label.NewPortal' : 'New Portal', // TODO add translations
    'MursionPortal.Label.OldPortal' : 'Old Portal', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ReminderValidation': 'Second Reminder should be less than First Reminder', // TODO add translations
    'MursionPortal.Project.InviteToSchedule.EmailDisabled.Tooltip' :'Invite to Schedule is disabled as email settings are disabled. Enable them to send this invite', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimReportedError' :'SIM Reported Error (Does not impact session status)', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimLateOrNoShow' :'SIM late or no show', // TODO add translations
    'MursionPortal.SimAttendance.Error.SessionStartedTooEarly' :'Session started too early', // TODO add translations
    'MursionPortal.SimAttendance.Error.AvatarIssue' :'Avatar Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.MouthlipSyncIssueDuringSession' :'Mouth/lip sync issue during session', // TODO add translations
    'MursionPortal.SimAttendance.Error.Hardware' :'Hardware', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForSim' :'Audio/video issue for SIM', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForLearner' :'Audio/video issue for Learner', // TODO add translations
    'MursionPortal.SimAttendance.Error.OtherSimHardwareIssues' :'Other SIM hardware issues (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerAndSimBothJoined' :'Learner and SIM both joined, but no connection established', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimMomentIssue' :'SIM Moment issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.WifiIssue' :'Wifi Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerWifi' :'Learner Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimWifi' :'SIM Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.AdditionalDetails' :'Additional Details', // TODO add translations
    'MursionPortal.SimAttendance.Error.DescribeYourError' :'Describe your error...', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByProfessionalServiceOrAccountOwner': 'Cancellation by PS or AO', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CompleteLearnerSurvey': 'Complete Learner Survey', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CompleteLearnerSurvey': 'How\'d it go?', // TODO add translations
    'MursionPortal.CustomizeInvitation.ButtonText.Label': 'Button Text', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerLockedOut': 'You are locked out!', // TODO add translations
    'MursionPortal.Filter.ReleaseLevel.Placeholder': 'Release Level', // TODO add translations
    'MursionPortal.TOTP.Page.Heading': 'We just sent a security code to your {email}. Enter the code to sign in.', // TODO add translations
    'MursionPortal.TOTP.ResendOTP.Text': 'Resend Code', // TODO add translations
    'MursionPortal.TOTP.Code.Label': 'Code', // TODO add translations
    'MursionPortal.TOTP.IncorrectCode.ErrorMsg': 'The code is incorrect. Enter the correct code.', // TODO add translations
    'MursionPortal.TOTP.ResendNewCode.ErrorMsg': 'The code has expired, click "Resend" to receive a new code.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerReleased': 'You are ready to schedule again.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.FirstReminder': `You’ve an upcoming Mursion Session in {firstReminderTime} hours!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.SecondReminder': `Your Mursion Session is starting in {upcomingBeforeStart} minutes!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerFeedbackReport': 'Mursion - Review Your Session Performance Report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerSessionRecordingIsReady': 'Your Session Recording is now up! Review how you did', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.Confirmation': 'Your Mursion Session is Confirmed!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.CancellationByLearnerOrPSOrAO': 'Your {licenseeName} session was canceled', // TODO add translations
    'MursionPortal.Personalized.PathwayPage.Section': 'Message you want learners to see on Pathway Page', // TODO add translations
    'MursionPortal.Personalized.CreateNewPathway.Button.Label': 'Create new for specific pathway', // TODO add translations
    'Projects.ScenarioList.NoPathwaysFound': 'No Pathways have been found.',// TODO add translations
    'MursionPortal.Personalized.Pathway.Search.Placeholder': 'Search or select Pathway(s):',// TODO add translations
    'Mursion.Portal.Status.SimTechnicalError': 'Sim Technical Error',// TODO add translations
    'MursionPortal.SimAttendance.Sim.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Sim’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'Session.Edit.Modal.Notes.BlankNoteValidation': 'Either add a valid note or remove this block', // TODO add translations
    'MursionPortal.Scenario.Error.Msg': 'Please complete required field.', // TODO add translations
    'MursionPortal.Scenario.Skills.Error.Msg': 'The number of skills must be in the range of {minSkills} and {maxSkills}.', // TODO add translations
    'MursionPortal.Capitalized.Incomplete': 'INCOMPLETE', // TODO add translations
    'MursionPortal.Dashboard.Chart.LicenseBreakdown.Title': 'License Breakdown – Contract Item',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnerLicenses': 'Learner Licenses',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersRegistered': 'Learners Registered',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersScheduled': 'Learners Scheduled',  // TODO add translations
    'MursionPortal.SessionAttendance.AddAttendees': 'Add attendees',  // TODO add translations
    'MursionPortal.SessionAttendance.SimulationSpecialist': 'Simulation Specialist',  // TODO add translations
    'MursionPortal.Dashboard.SimulationSpecialist.Status': 'Simulation Specialist Status',  // TODO add translations
    'MursionPortal.Dashboard.Technical.Issue.Summary': 'If a technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.Dashboard.Provide.Details': 'Please provide more details on what happened',  // TODO add translations
    'MursionPortal.SessionAttendance.SwitchToSimRole.Message': 'Please switch role to Sim Specialist to view the page!', // TODO add translations
    'MursionPortal.SessionAttendance.Heading': '<code>Instructions:</code> Review the Session, Simulation Specialist, and Learner Status\' below and update as required. This form may be edited for 24 hours after the end of the session', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Label': 'This is automatically determined by the Learner and Simulation Specialist status. No action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Heading': '(selections below do not impact session status)', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Instructions': '<code>Instructions:</code> Session details <code>must</code> be provided for any session that has a Learner Status other than ‘Complete’ or ‘No Show’. To complete, select one or more issue type(s) and describe details in the text box. Session detail information does not impact the Learner, Simulation Specialist, or Session status.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Text': `Mursion Simulation Specialists only: If you filled out a UTS ticket for this session, please include the link to the Slack post in the text box (click the 3 vertical dots to the right of the post and select ‘Copy link').`, // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Simspecialist.Label': 'This is automatically determined by the system. If you encountered an error that prevented the session from being completed and the indicated status is ‘Left’, select ‘Technical Error (SIMS)’. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Learner.Label': 'This is automatically determined by the system. If the indicated status is inaccurate, select the correct status and explain the reason for the revision in Session Details. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.SimStatus.Label': 'Technical Error (SIMs)', // TODO add translations
    'Mursion.Portal.Status.UnableToComplete': 'Unable to Complete', // TODO add translations
    'Mursion.Portal.Status.ElectedToLeave': 'Elected to Leave', // TODO add translations
    'Mursion.Portal.Status.TechnicalErrorLearner': 'Technical Error (Learner)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.Audio': 'Audio', // TODO add translations
    'MursionPortal.SessionAttendance.Error.ConnectionInternet': 'Connection / Internet', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerTechnicalIssues': 'Learner technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MursionTechnicalIssues': 'Mursion technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherTechnicalIssues': 'Other technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MomentSoftware': 'Moment Software', // TODO add translations
    'MursionPortal.SessionAttendance.Error.AvatarOrEnvironmentLipSyncMovementEtc': 'Avatar or Environment (lip sync, movement, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.HardwareControllerLaptopEtc': 'Hardware (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerAndSimulationSpecialistBothJoined': 'Learner and Simulation Specialist both joined, no connection established', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherPleaseExplainBelow': 'Other (please explain below)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.NonTechnicalIssues': 'Non-technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerMaterialsOrPreparation': 'Learner materials or preparation ', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerLanguageFluency': 'Learner language fluency', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerElectedToLeave': 'Learner elected to leave', // TODO add translations
    'MursionPortal.Dashboard.Overview.Empty.Body.Message': 'Contract report will load once you make a selection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Simulation':'Session Breakdown – Simulation Contract Item', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Hour':'Session Breakdown – Hour Contract Item', // TODO add translations
    'MursionPortal.Copy.Tooltip.Text': 'Copy (plain text only, no formatting)', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Green': 'Green', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Thick': 'Thick', // TODO add translations
    'MursionPortal.Status.Connected.Label': 'Connected',  // TODO add translations
    'MursionPortal.Status.Immersing.Label': 'Immersing',  // TODO add translations
    'MursionPortal.Dashboard.NonTechnical.Issue.Summary': 'If a non-technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.AutoSim.Scenario.TooltipLabel': 'AutoSim scenario can’t be scheduled.', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam': 'Auto-Assign Team', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam.Tooltip': 'Automatically assign this scenario to the selected teams when learners access the scenario page via the provided link.', // TODO add translations
    'MursionPortal.Dashboard.Overview.NoAssociatedDataForSelectedContract': 'No associated data for the selected contract(s)', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelOne': 'Level 1', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelTwo': 'Level 2', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelThree': 'Level 3', // TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Title': 'Make sure you’re ready to take the session by testing your connection before joining.',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Button.Label': 'Test Connection',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link.Title': 'If any test fails, copy results at the end of the test and share them with our support team by',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link': 'Submitting a Support ticket.',// TODO add translations
    'MursionPortal.Dashboard.OnDemand.Text':'Ready, set, practice on-the-go',
    'MursionPortal.Dashboard.OnDemand.Title':'Try On-Demand Simulations',
    'MursionPortal.Dashboard.OnDemand.SubTitle':'Get a first look at the future of skill-building at work - dynamic GenAI simulations available instantly, no scheduling required. Practice anytime and receive actionable feedback that helps you grow and master new skills.',
    'MursionPortal.Dashboard.OnDemand.SmallTitle':'Early access, real results—get ahead now.',
    'MursionPortal.Dashboard.OnDemand.Button.Label':'Go to Mursion On-Demand',
    'MursionPortal.Dashboard.OnDemand.Button.Hide.Label':'Hide this',
    'MursionPortal.Dashboard.OnDemand.Preview':'PREVIEW',
    'MursionPortal.Dashboard.OnDemand.Text1':'✓ Scalable, focused practice',
    'MursionPortal.Dashboard.OnDemand.Text2':'✓ Flexible access',
    'MursionPortal.Dashboard.OnDemand.Text3':'✓ Targeted practice for mastery',
    'MursionPortal.Dashboard.OnDemand.Text4':'✓ Guided self-reflection',
    'MursionPortal.Dashboard.OnDemand.Mursion':'Mursion',
  },
};
export default germanLangTranslations;
