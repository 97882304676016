import { LOCALES } from 'src/i18n/locales';
import { TLocaleId } from 'src/i18n';

const spanishLangTranslations: { [key: string]: { [idKey in TLocaleId]: string } } = {
  [LOCALES['Español (Spanish)']]: {
    'MursionPortal.SimAttendance.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Learner’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'SessionReports.ErrorOnLoadingVideo': 'Utilzar un ordenador portátil o de sobremesa para ver la grabación.',
    'MursionPortal.ConfirmationModal.Project.InvitationsConfirmationText': '¿Está seguro de que desea invitar a <strong>todos los alumnos asignados</strong> que aún no han completado escenarios en el proyecto? Recibirán una invitación por correo electrónico para programar una noche.',
    'MursionPortal.ConfirmationModal.Scenario.InvitationsConfirmationText': '¿Está seguro de que desea invitar a <strong>todos los alumnos asignados</strong> que aún no han completado este escenario? Recibirán una invitación por correo electrónico para programar una noche.',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays.Tooltip': 'Número de días que el cliente desea conservar sus datos de grabación de vídeo. Una vez transcurridos esos días, las grabaciones de vídeo se eliminarán.',
    'Settings.SSO.CopyScenarioLink': 'Copiar el enlace de invitación al escenario',
    'MursionPortal.Label.VideoRecordingSharing': 'Los usuarios comparten o descargan los vídeos de sus sesiones',
    'MursionPortal.Status.Capitalized.LateCancelled': 'CANCELADO_TARDE',
    'MursionPortal.Status.Capitalized.EarlyCancelled': 'CANCELADO_PRONTO',
    'MursionPortal.Status.Abbreviation.LateCancelled': 'LC',
    'MursionPortal.Status.Abbreviation.Cancelled': 'C',
    'MursionPortal.Text.Congratulation': 'Enhorabuena.',
    'MursionPortal.Text.PasswordChanged': 'Su contraseña ha sido modificada',
    'MursionPortal.Text.PasswordShouldBeSame': 'Sus nuevas contraseñas deben coincidir.',
    'Filters.LateRescheduled': 'Reprogramado tarde',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Scheduled': 'Próximas sesiones programadas que aún no se han completado (incluye las que están en curso)',
    'MursionPortal.Checkbox.EnableSmartMetrics': 'Activar el análisis de voz del alumno por sesión',
    'Dashboard.SimulationTable.Column.TimesLateRescheduled': 'Veces de reprogramación tarde',
    'Dashboard.SimulationTable.ColumnTooltip.TimesLateRescheduled': 'Veces que esta simulación se reprogramó con retraso',
    'SessionReports.Label.CopyVideoLink': 'Copiar enlace de vídeo',
    'Login.LoginAgain': 'Iniciar sesión de nuevo',
    'Dashboard.VideosTable.Column.SessionStart': 'Inicio de sesión',
    'Filters.Date.LastSevenDays': 'Última semana',
    'Filters.Date.NextSevenDays': 'Próxima semana',
    'Filters.Date.ThisYear': 'Este año',
    'Mursion.Portal.Status.LearnerMissed': 'Alumno perdido',
    'Mursion.Portal.Status.SystemCancelled': 'Cancelado',
    'Mursion.Portal.Status.LateCancelled': 'Cancelado con retraso',
    'Mursion.Portal.Status.EarlierCancelled': 'Cancelado anticipadamente',
    // 'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': 'Completado',
    'MursionPortal.Dashboard.UpcomingSessions': 'Próximas sesiones',
    'MursionPortal.Label.Opened': 'Abierto',
    'MursionPortal.Label.ActedOn': 'Actuado',
    'MursionPortal.Label.Unread': 'Sin leer',
    'MursionPortal.Label.Timeline': 'Cronograma del proyecto',
    'Projects.ProjectInfo.Label.TimelineUtc': 'Cronograma del proyecto (UTC)',
    'MursionPortal.ScenarioInfo.Header.Description': 'Descripción',
    'MursionPortal.TimelineUTC': 'Cronograma del proyecto<code>{word}</code> (UTC)',
    'MursionPortal.Label.ChangePassword': 'Cambiar contraseña',
    'MursionPortal.Label.ChangeYourPassword': 'Cambiar la contraseña',
    'MursionPortal.Label.NewChangePassword': 'Escriba su nueva contraseña',
    'MursionPortal.Label.OldPassword': 'Escriba su contraseña actual',
    'MursionPortal.Label.RepeatChangePassword': 'Vuelva a escribir su nueva contraseña',
    'MursionPortal.Prompt.SessionScheduled': 'La <code>Sesión</code> ha sido cancelada.',
    'MursionPortal.Status.Booked': 'Reservado',
    'MursionPortal.Status.Pending': 'Pendiente',
    'MursionPortal.Completed': 'Completado',
    'MursionPortal.Status.Missed': 'Perdido',
    'MursionPortal.Status.Cancelled': 'Cancelado',
    'MursionPortal.Status.LateCancelled': 'Cancelado con retraso',
    'MursionPortal.Status.EarlyCancelled': 'Cancelado anticipadamente',
    'MursionPortal.Status.LicenseCancelled': 'Cancelado',
    'MursionPortal.Status.Error': 'Error',
    'MursionPortal.ConversationalFlow.label.zoom.all': 'Todos',
    'Clients.ImportUsers.DownloadErrorsButton': 'Descargar errores',
    'Mursion.Portal.Edit.Team.DownloadErrorButton': 'Descargar errores',
    'MursionPortal.Dashboard.NextOnProject': 'Escenarios para programar',
    'MursionPortal.Dashboard.NextOnProject.Footer.EndDate': 'Hay hasta el {endDate} para completar este escenario.',
    'MursionPortal.Dashboard.NextOnProject.Footer.CompletionRate': 'Hay hasta el {endDate} para completar hasta {completionRate} veces.',
    'MursionPortal.Dashboard.NothingPlanned.text1': '¡Ya está al día! Cuando tenga',
    'MursionPortal.Dashboard.NothingPlanned.text2': 'algo para programar, aparecerá aquí.',
    'MursionPortal.Learner.Missed': 'ALUMNO PERDIDO',
    'MursionPortal.AriaImage.Attribute.Next': 'Siguiente:',
    'MursionPortal.AriaImage.Attribute.Close': 'Cerrar:',
    'MursionPortal.AriaImage.Attribute.Back': 'Atrás:',
    'MursionPortal.AriaImage.Attribute.Delete': 'Eliminar:',
    'MursionPortal.AriaImage.Attribute.Remove': 'Eliminar',
    'MursionPortal.AriaImage.Attribute.Document': 'Documento',
    'MursionPortal.AriaImage.Attribute.Video': 'Vídeo',
    'MursionPortal.AriaImage.Attribute.DeleteDocument': 'Eliminar documento',
    'MursionPortal.ScenarioInfo.CompletionRate': '{completionRate} Veces',
    'MursionPortal.Button.RefreshPage': 'Actualizar página',
    'MursionPortal.Prompt.CalendarUpdatedOutdatedNotification': '¿Está seguro de que desea cerrar, su página de calendario quedaría obsoleta.',
    'Reports.SessionListHeader.Label.AverageTime': 'Tiempo medio <code>en cada sesión</code>',
    'MursionPortal.Users.Archive.HasSessions': 'No se puede archivar el Simulation Specialist ya que hay una o más sesiones programadas para este Simulation Specialist. Por favor, intercambie o cambie el Simulation Specialist.',
    'MursionPortal.Label.InactiveSIMCertification.Tooltip': 'Utilizar si hay un Sim que está certificado en esta simulación, pero que no está disponible para nuevas simulaciones.',
    'MursionPortal.Label.InactiveSIMCertification': 'Certificación Sim inactiva',
    'Dashboard.Filters.FilterBySessionDate': 'FILTRAR POR FECHAS DE SESIÓN',
    'Dashboard.LeftPane.CategoryHeader.Emails': 'CORREOS ELECTRÓNICOS',
    'Projects.ProjectInfo.Label.CancellationDeadline.Tooltip': 'Este es el número de días/horas/minutos que un alumno tendría para cancelar, una vez registrado en una Simulación dentro del proyecto. Si los alumnos cancelan dentro del plazo, la sesión se considerará completa a efectos de facturación.',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording': 'Permitir la grabación de sesiones',
    'MursionPortal.Label.SendRecordedVideosTo': 'Enviar los vídeos grabados a:',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording': 'Permitir al alumno unirse a la sesión si no da su consentimiento para la grabación',
    'Projects.ProjectInfo.SchedulingInterval': 'Intervalo de programación',
    'Projects.ProjectInfo.SchedulingInterval.Tooltip': 'Permite que la programación se realice con una cadencia distinta a la duración de la sesión.',
    'Projects.ProjectInfo.SchedulingIntervalLength': 'Duración del intervalo de programación',
    'Projects.ProjectInfo.SchedulingIntervalLength.tooltip': 'Determina la frecuencia de los bloques de programación que ve un alumno. Si se establece en 15, los alumnos verán bloques de programación en intervalos de 15 minutos. No establece la duración de la sesión, solamente la frecuencia con la que se ejecutan las sesiones.',
    'Projects.ProjectInfo.DeliveryMode.tooltip': 'La plataforma utilizada por sus alumnos. Seleccione ML3 si desea activar Mursion Magic y/o el software de conexión directa ML3. ML3z = Zoom. Mursion Meet = Google Meet.',
    'MursionPortal.Label.WebOnly': 'Solo Web',
    'MursionPortal.Label.DesktopOnly': 'Solo escritorio',
    'MursionPortal.Label.DesktopAndWeb': 'Escritorio y Web',
    'MursionPortal.EmailCategory.CallsToAction': 'Llamadas a la acción',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Sent': 'Enviado',
    'MursionPortal.Dashboard.EmailAnalytics.Label.AllEmails': 'Todos los correos electrónicos',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Trends': 'Tendencias',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CategorySummary': 'Resúmenes de categorías',
    'MursionPortal.Dashboard.EmailAnalytics.Label.RegistrationEmail': 'Correos electrónicos de registro',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CallToActionEmail': 'Correos electrónicos de llamadas a la acción',
    'MursionPortal.Dashboard.EmailAnalytics.Label.SchedulingEmail': 'Correos electrónicos de programación',
    'MursionPortal.Dashboard.EmailAnalytics.Label.ReSchedulingEmail': 'Correos electrónicos de reprogramación',
    'MursionPortal.Dashboard.EmailAnalytics.Label.VideoEmail': 'Correos electrónicos de vídeos',
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailSent': 'Correo electrónico enviado',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailAnalytics': 'Los análisis de correo electrónico le proporcionan una visión general de cómo se implican los alumnos con <link>las comunicaciones por correo electrónico de Mursion</link>. Los correos electrónicos pueden clasificarse en una de las tres categorías siguientes',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailOpened': 'Un alumno ha abierto uno o varios de los correos electrónicos que se le han enviado.',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailActedOn': 'Un alumno ha abierto el correo electrónico y ha hecho clic en uno o varios enlaces, como los enlaces <strong>Programar</strong> o <strong>Unirse a la simulación</strong>.',
    'MursionPortal.Dashboard.EmailAnalytics.Info.Unread': 'Un alumno no ha abierto el correo electrónico. Si hay un gran número de correos electrónicos sin leer, confirme que sus alumnos los están recibiendo. Compruebe con su equipo IT que <color>no-reply@mursion.com</color> no está bloqueado por los filtros de spam de su organización.',
    'MursionPortal.Dashboard.Tooltip.RegisterEmail': 'Correos electrónicos a un usuario invitándole a crear una cuenta en Mursion',
    'MursionPortal.Dashboard.Tooltip.CallToActionEmail': 'Correos electrónicos recordatorios enviados por un Account Owner desde la página de proyecto o escenario',
    'MursionPortal.Dashboard.Tooltip.SchedulingEmail': 'Correos electrónicos específicos de sesión. Invitan a programar y otros correos electrónicos de participación en la sesión',
    'MursionPortal.Dashboard.Tooltip.ReschedulingEmail': 'Mensajes de correo electrónico en los que se solicita a un alumno que reprograme una simulación perdida o cancelada.',
    'MursionPortal.Dashboard.Tooltip.VideoEmail': 'Correos electrónicos que alertan al alumno de que hay disponible para ver un vídeo de su simulación',
    'MursionPortal.Dashboard.EmailCategoryFilter': 'Categoría de correo electrónico',
    'MursionPortal.Dashboard.EmailCategoryFilter.Search': 'Buscar categorías de correo electrónico...',
    'MursionPortal.Dashboard.EmailTypeFilter.SessionRescheduled': 'Sesión reprogramada',
    'MursionPortal.Dashboard.EmailTypeFilter.Search': 'Buscar tipos de correo electrónico...',
    'MursionPortal.Text.NoImageAvailable': 'No hay imagen disponible',
    'Mursion.Portal.Edit.Team.Instruction.ExportFile': 'Haga clic en <strong>Descargar membresías actuales</strong> a continuación para actualizar las asignaciones de equipo de los alumnos (<strong>edit_learner_team_memberships.xlsx</strong>).',
    'Mursion.Portal.Edit.Team.DownloadExcelTemplate': 'Descargar las membresías actuales',
    'MursionPortal.Project.SameDay': 'El mismo día',
    'MursionPortal.Project.CancelationDeadline.Validation': 'El plazo de cancelación debe ser superior a 0.',
    'ClientUserTable.AddNewUsers.RolesHeading': 'Solo se puede añadir un tipo de usuario a la vez. Seleccione el tipo de usuario que desea añadir.',
    'ClientUserTable.AddNewUsers.Roles.AccountOwner.Tooltip': 'Un Account Owner es el administrador principal de toda su organización.',
    'ClientUserTable.AddNewUsers.Roles.Facilitator.Tooltip': 'Un Facilitador es un administrador a nivel de equipo que gestiona uno o varios equipos específicos.',
    'ClientUserTable.AddNewUsers.Roles.Learner.Tooltip': 'Un alumno es cualquier persona que asiste a una simulación de Mursion. Este será el grueso de su base de usuarios.',
    'ClientUserTable.AddNewUsers.Heading': 'Añadir nuevo(s) usuario(s)',
    'ClientUserTable.AddNewUsers.Roles.Heading': 'Asignar un rol a los usuarios',
    'ClientUserTable.AddNewUsers.ImportUsers': 'Siga los pasos que se indican a continuación para importar hasta 500 usuarios. Si importa más de 500, utilice nuestra función "<code>Importar usuarios</code>".',
    'ClientUserTable.AddNewAttributes.label': 'Añadir atributos de usuario',
    'ClientUserTable.AddNewAttributes.Header.Text': 'Tenga en cuenta que estos atributos se aplicarán a todos los usuarios de esta lista.',
    'ClientUserTable.UserAuthentication.label': 'Autenticación de usuarios',
    'ClientUserTable.NewUsersAdded.Heading': '¡Nuevo(s) usuario(s) añadido(s)!',
    'ClientUserTable.AddNewUsers.AssignTeams.Heading': 'Asignar equipo(s) a los usuarios',
    'ClientUserTable.AddNewUsers.AssignTeams.CreateTeam': 'Los estudiantes y facilitadores deben estar asignados a un equipo. Si aún no ha creado un equipo, <code>haga clic aquí.</code>.',
    'ClientUserTable.AddNewUsers.AssignTeams.SelectTeams': 'Seleccionar equipo(s)',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Validation': 'Por favor, añada 500 usuarios o menos. Para importar más de 500 usuarios puede utilizar nuestra función "Importar usuarios".',
    'ClientUserTable.AddNewUsers.AssignTeams.Validation': 'Debe seleccionar al menos un equipo.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Heading': 'Añadir direcciones de correo electrónico de usuarios',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.SubHeading': 'Puede añadir un correo electrónico cada vez o pegar una lista. Los correos electrónicos deben ir separados por un espacio, una coma o un punto y coma.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Placeholder': 'Escriba o pegue aquí las direcciones de correo electrónico.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.MaximumUsersAllowed': '{maximumUsersAllowed} usuarios a la vez',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Total': 'Total',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Duplicate': 'Duplicar',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.AlreadyExists': 'Ya existe',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Invalid': 'No válido',
    'ClientUserTable.AddNewUsers.AddNewAttributes.SignLanguageInterpreter': 'Intérprete de lengua de signos',
    'ClientUserTable.AssignTeams.TotalUsers': 'Está a punto de añadir <code>nuevo {role} de {users}</code>',
    'ClientUserTable.AssignTeams.AddAndUpdateUsers': 'Está a punto de añadir <code>nuevo {role} de {users}</code> y actualizar <code>{existingUsers} {role} existentes</code>.',
    'ClientUserTable.AssignTeams.UpdateUsers': 'Está a punto de actualizar <code>{role} existente de {existingUsers}</code>',
    'ClientUserTable.AssignTeams.NumberOfTeams': 'Se asignará a <code>{numberOfTeams} equipo(s)</code>.',
    'ClientUserTable.Confirmation.Heading': 'Por favor, confirme antes de añadir',
    'MursionPortal.InviteToSchedule.Confirmation.Text': 'Más información aquí:<code>Invitar a programar</code>',
    'MursionPortal.AccountOwnerGuides.Confirmation.Text': 'Guías de Account Owner',
    'MursionPortal.FacilitatorGuides.Confirmation.Text': 'Guías para facilitator',
    'MursionPortal.Users.Confirmation.Text': 'Su(s) usuario(s) ha(n) sido añadido(s) al portal de Mursion.',
    'MursionPortal.Confirmation.Header.Text.BackToUsers': 'Volver a Usuarios',
    'MursionPortal.Users.Next.Steps.Text': 'Próximos pasos',
    'MursionPortal.Users.StepOne.Text': 'Paso 1:',
    'MursionPortal.Users.StepTwo.Text': 'Paso 2:',
    'MursionPortal.Users.StepThree.Text': 'Paso 3:',
    'MursionPortal.Users.ThatIt.Text': '¡Eso es!',
    'MursionPortal.Users.InviteToScheduleLearner.Text': 'Invite a sus alumnos a programar',
    'MursionPortal.Users.LearnersLink.Text': 'Puede enviar este enlace a sus alumnos',
    'MursionPortal.Users.InviteToSchedule.ProjectorScenario.Text': 'Utilice el botón <strong>Invitar a programar</strong> en cualquier proyecto o escenario.',
    'MursionPortal.Users.CopyInviteLink.Text': 'Copiar enlace de invitación',
    'MursionPortal.Users.ShareContent.Text': 'Compartir contenidos de apoyo con sus administradores',
    'MursionPortal.Users.AOAndFacilitators.Responsibilities.Text': 'Los Account Owners y los Facilitators tienen diferentes responsabilidades y pueden necesitar más información sobre el portal. Comparta estos enlaces con sus Account Owners y Facilitators para que puedan encontrar respuestas a cualquier pregunta que puedan tener a lo largo de su viaje por Mursion.',
    'MursionPortal.Users.Administrators.Text': 'Sus Administradores tendrán ahora acceso al portal de Mursion.',
    'MursionPortal.Users.Progress.Text': 'Sus alumnos comenzarán a programar sus simulaciones. Puede seguir su progreso en el panel de control.',
    'MursionPortal.Users.Access.Text': 'Asegúrese de que su departamento IT ha dado acceso a estos usuarios a la aplicación Mursion.',
    'MursionPortal.Users.Registrationemail.Text': 'Sus usuarios deberían haber recibido su correo electrónico de registro de no-reply@mursion.com.',
    'MursionPortal.AddMoreUsers.Btn.Text': 'Añadir más usuarios',
    'MursionPortal.Users.Ok.Btn.Text': 'Vale, ¡lo tengo!',
    'Users.Button.AddNewUsers': 'Añadir nuevos usuarios',
    'MursionPortal.Project.CancelationDeadline.UpperLimit.Validation': 'El plazo de cancelación no puede ser superior a 365 días.',
    'MursionPortal.Dashboard.EmailTypeFilter.PasswordReset': 'Restablecer contraseña',
    'MursionPortal.Dashboard.EmailTypeFilter.Verification': 'Verificación',
    'MursionPortal.Dashboard.EmailTypeFilter.InviteToSchedule': 'Invitar a programar',
    'MursionPortal.Dashboard.EmailTypeFilter.Summary': 'Resumen',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleMissed': 'Reprogramación Perdida',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleCancelled': 'Reprogramación Cancelada',
    'MursionPortal.Dashboard.EmailTypeFilter.PSRescheule': 'Reprogramación PS',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingCorrupted': 'Grabaciones de vídeo dañadas',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingNotUploaded': 'Grabaciones de vídeo no cargadas',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingAvailable': 'Grabación de vídeo disponible',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingShared': 'Grabación de vídeo compartida',
    'MursionPortal.Dashboard.EmailTypeFilter.Booking': 'Reservas',
    'MursionPortal.Dashboard.EmailTypeFilter.CodeOfConuct': 'Código de conducta',
    'MursionPortal.Dashboard.EmailTypeFilter.UserRemoved': 'Usuario eliminado',
    'MursionPortal.Dashboard.EmailTypeFilter.AttendanceReminer': 'Recordatorio de asistencia',
    'Users.Button.AddNewLearners': 'Añadir nuevos alumnos',
    'MursionPortal.Scenario.Label.LastDelivered': 'Última entrega',
    'MursionPortal.Scenario.Label.TotalDeliveries': 'Entregas totales',
    'MursionPortal.Scenario.Text.NotYetDelivered': 'Aún no entregado',
    'ClientUserTable.AddNewUsers.UpdateUserWarning': 'Estos {existingUsers} usuarios ya existen. Al continuar, estos usuarios se añadirán a los equipos seleccionados (además de a los equipos que ya tengan asignados).',
    'MursionPortal.AddNewUsers.Facilitators': 'Facilitator(s)',
    'ClientUserTable.AddNewUsers.Learners': 'Alumno(s)',
    'MursionPortal.Scheduling.TimeSlotsUnavailableMessage': 'Las franjas horarias seleccionadas ya no están disponibles. Actualice para ver las franjas horarias disponibles.',
    'MursionPortal.Client.EmailTypeFilter.SessionFirstReminder': 'Primer recordatorio de sesión',
    'MursionPortal.Client.EmailTypeFilter.SessionSecondReminder': 'Segundo recordatorio de sesión',
    'Projects.ProjectInfo.Label.ShowSIMAvailaiblity': 'Mostrar disponibilidad de SIM',
    'Projects.ProjectInfo.Label.ShowAvailaiblity.Tooltip': 'Cuando un alumno programe una cita, verá la disponibilidad del SIM Specialist para el intervalo que usted seleccione.',
    'Projects.InputValidation.GreaterThanZero': 'Debe ser mayor que 0.',
    'MursionPortal.Weeks': 'Semana(s)',
    'MursionPortal.Scenario.CompletionRateFulfilled': 'You cannot schedule this simulation as you have completed the maximum number of simulations for this scenario or engagement as set by your organization.',// TODO add translations
    'MursionPortal.Dashboard.EmailTypeFilter.TimeSlotRequest': 'Solicitud de franja horaria',
    'MursionPortal.Dashboard.EmailAnalytics.OpenAndActed.Label': 'Los datos de Abierto y Actuado son correctos a fecha de 24 de noviembre de 2022.',
    'MursionPortal.Dashboard.EmailAnalytics.Prior.Label': 'Antes de esta fecha, todos los correos electrónicos se categorizan como No leídos.',
    'MursionPortal.Learners.Filters.LearnerCompletion': 'Finalización de alumno',
    'MursionPortal.Learners.Filters.LearnerCompletion.NotYetCompleted': 'Aún no se ha completado',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedOneSession': '1 sesión completada',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedTwoPlusSessions': 'Más de 2 sesiones completadas',
    'MursionPortal.Learners.Filters.MissedLateActivity': 'Actividad perdida/retrasada',
    'MursionPortal.Learners.Filters.MissedLateActivity.1Session': '1 sesión',
    'MursionPortal.Learners.Filters.MissedLateActivity.2Sessions': '2 sesiones',
    'MursionPortal.Learners.Filters.MissedLateActivity.3Sessions': '3 sesiones',
    'MursionPortal.Learners.Filters.MissedLateActivity.4PlusSessions': '4+ sesiones',
    'MursionPortal.BackToCompletedSessions': 'Volver a Sesiones Completadas',
    'MursionPortal.Scenario.InviteToSchedule.Modal.Text': '¿Desea enviar invitaciones a <strong>todos</strong> los alumnos asignados que aún no han completado este escenario o <strong>seleccionar alumnos</strong> para invitarlos?',
    'MursionPortal.BackToSessionSummaries': 'Volver a Resúmenes de sesiones',
    'MursionPortal.BackToLearnerSessions': 'Volver a las sesiones de alumno',
    'MursionPortal.LearnerSessionReport.SessionObjective': 'Objetivo de la sesión',
    'MursionPortal.LearnerSessionReport.SessionStrategy': '¿Qué tan bien ha aplicado usted las estrategias?',
    'MursionPortal.LearnerSessionReport.SessionStrategyIcon': 'Estrategia de sesión Objetivo de Diana',
    'MursionPortal.LearnerSessionReport.SessionPerspective.Title': 'Sigue así.',
    'MursionPortal.LearnerSessionReport.SessionDetail': 'Descripción de escenario',
    'MursionPortal.LearnerSessionReport.SessionDate': 'Fecha de sesión',
    'ClientUserTable.AddNewUsers.Procced.Button.Tooltip': 'Elimine los correos electrónicos duplicados o no válidos antes de continuar.',
    'MursionPortal.BackToSessionDetail': 'Volver al detalle de la sesión',
    'MursionPortal.SimAttendance.Instructions.Label': 'Mursion Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText': 'Select an attendance status for each attendee (mouse over the status to learn more). Your status is gathered automatically – these statuses are for the learner ONLY.',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.Label1': 'Software License Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText1': 'Select an attendance status for each attendee (mouse over the status to learn more). If you experienced an error in the session, please also provide the error details. To do this, select the type of error under the “SIM Reported Error” section and add context in the Additional Details text box. Additional details about attendance--even if it’s not an Error--are encouraged, but are optional. If you have any further questions about attendance, please reach out to your Simulation Program Admin.',// TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailAnalytics': 'Análisis de correos electrónicos',
    'MursionPortal.Session.LearnerSession.Report': 'Informe',
    'MursionPortal.Session.LearnerSession.Recording': 'Grabación',
    'MursionPortal.Session.LearnerSession.SessionDetails': 'Detalles de sesión',
    'MursionPortal.Session.LearnerSessionReport': 'Informe de sesión de alumno',
    'MursionPortal.Scenario.InviteToScedule.Modal.SelectLearners': 'Seleccionar alumnos',
    'MursionPortal.Scenario.Button.Label': 'Copiar correos electrónicos de alumnos',
    'MursionPortal.VideoStatusFilter.Label': 'Estado del vídeo',
    'MursionPortal.TooltipText.VideoNotUploaded': 'Si este vídeo no se ha cargado transcurridas 48 horas, póngase en contacto con el servicio de asistencia.',
    'MursionPortal.VideoStatusFilter.SearchText': 'Buscar estado del vídeo...',
    'MursionPortal.VideoStatusFilter.Uploaded': 'Cargado',
    'MursionPortal.VideoStatusFilter.NotUploaded': 'No cargado',
    'MursionPortal.Scenario.Invite.Modal.Button.Label': 'Invitar',
    'MursionPortal.Scenario.CopyEmails.Modal.Button.Label': 'Copiar correos electrónicos',
    'MursionPortal.Scenario.AllLearners.InvitationsSentOvernight': 'Sus invitaciones se enviarán por la noche si el alumno aún no ha programado o recibido una invitación en los últimos 2 días.',
    'MursionPortal.Scenario.SelectedLearners.InvitationsSentOvernight': 'Los <code>alumnos seleccionados</code> recibirán un correo electrónico por la noche invitándoles a programar, si aún no han programado o recibido una invitación en los últimos 2 días.',
    'MursionPortal.Scenario.SelectedLearners.Confirm.Text': '¿Está seguro de que desea invitar a <code>todos los alumnos seleccionados</code>?',
    'ClientUserTable.Column.LastInvited': 'Último invitado',
    'ClientUserTable.ColumnTooltip.LastInvited': 'La fecha en que se envió la última invitación por correo electrónico para programar. Puede ser mañana si acaba de recibir una invitación.',
    'MursionPortal.Scenario.SelectedLearners.EmailsCopied': 'Las direcciones de correo electrónico de los alumnos seleccionados se han copiado en el portapapeles.',
    'MursionPortal.ScenarioInfo.MarkAllInactive': 'Marcar todos inactivos',
    'MursionPortal.ScenarioInfo.DecertifyAll': 'Descertificar todo',
    'MursionPortal.ScenarioInfo.MarkAllInactive.Confirmation.Text': '¿Está seguro de querer desactivar todos los SIM Specialists?',
    'MursionPortal.ScenarioInfo.DecertifyAll.Confirmation.Text': '¿Está seguro de querer descertificar a todos los SIM Specialists?',
    'Dashboard.Button.ViewSession': 'Ver sesión',
    'MursionPortal.SessionReport.NotAvailable': 'No disponible',
    'Projects.ProjectInfo.Checkbox.EnableReportTabSurvey.OneToOne': 'Activar la pestaña Informe para las entregas 1:1',
    'MursionPortal.Learner.Session.Report': 'Informe de sesión de alumno',
    'MursionPortal.Scenario.Sim.InactivedAll': '{effectedSims} de {totalSims} SIM desactivadas.',
    'MursionPortal.Scenario.Sim.DecertifiedAll': '{effectedSims} de {totalSims} SIM descertificadas.',
    'MursionPortal.Scenario.Sim.DecertifiedFew': '{remainingSims} SIM no pueden ser descertificadas porque tienen sesiones programadas. Márquelas como inactivas para respetar sus sesiones y evitar futuras asignaciones de sesiones.',
    'MursionPortal.AriaLabel.ViewMore': 'Ver más',
    'MursionPortal.SimAttendance.SimulationAttendance': 'Asistencia de alumnos a simulación',
    'Mursion.Portal.Status.LearnerTechnicalError': 'Learner Technical Error',// TODO add translations
    'Mursion.Portal.Status.TooLateToComplete': 'Demasiado tarde para completar',
    'Mursion.Portal.Status.LearnerElectedToLeave': 'Learner Elected to Leave',// TODO add translations
    'Mursion.Portal.Status.Unprepared.Tooltip': 'El alumno se unió pero no completó la simulación por no estar preparado o por otros motivos.',
    'MursionPortal.Label.SchedulingRate': 'Tasa de programación',
    'Mursion.Portal.Status.No.Show': 'No mostrado',
    'MursionPortal.ScenarioInfo.SchedulingRate.Text': '{code} veces',
    'MursionPortal.Scenario.Modal.Search.Placeholder': 'Buscar dirección de correo electrónico, nombre, apellidos',
    'MursionPortal.Scenario.SchedulingRateFulfilled': 'Primero hay que completar la sesión programada.',
    'MursionPortal.Scenario.InviteToScedule.Modal.Button.Tooltip': 'Seleccionar el alumno al que se desea invitar',
    'MursionPortal.Learner.Session.Report.Report.SimulationRecording': 'Reflexionar sobre la conversación reproduciendo la grabación de la simulación',
    'Dashboard.SimulationTable.ColumnTooltip.SoftwareVersion': 'Indica el software de sesión utilizado',
    'Dashboard.SimulationTable.Column.SoftwareVersion': 'Software',
    'MursionPortal.LearnerSessionReport.YourPerspective': 'Su perspectiva',
    'MursionPortal.LearnerSessionReport.YourPerspectiveDescription': 'Su confianza al entrar en la simulación era <strong>{preConfidenceLevel}</strong> y después de la simulación era <strong>{postConfidenceLevel}.</strong>',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryLow': 'muy baja',
    'MursionPortal.LearnerSessionReport.YourPerspective.Low': 'baja',
    'MursionPortal.LearnerSessionReport.YourPerspective.Moderate': 'moderada',
    'MursionPortal.LearnerSessionReport.YourPerspective.High': 'alta',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryHigh': 'muy alta',
    'MursionPortal.LearnerSessionReport.Strategies.SuccessfullyStrategy': 'Usted ha demostrado <strong>con éxito</strong> esta estrategia',
    'MursionPortal.LearnerSessionReport.Strategies.BenefitStrategy': 'Le vendría bien <strong>practicar un poco más</strong> la implementación de esta estrategia',
    'MursionPortal.SchedulingRate.ValidationMessage': 'La tasa de programación debe ser inferior o igual a la tasa de finalización.',
    'MursionPortal.LearnerSessionReport.Footer.FooterTitle': 'Siguientes pasos: ¡La práctica hace al maestro!',
    'MursionPortal.LearnerSessionReport.Footer.FooterSubTitle': 'La repetición es la clave para que estas habilidades se conviertan en algo natural.',
    'MursionPortal.LearnerSessionReport.SessionObjective.Body': 'Su informe estará listo en breve',
    'MursionPortal.Dashboard.NextOnProject.Footer.schedulingRateFulfilled.Text': 'You cannot schedule this simulation at this time as you have currently reached the scheduling limit for this scenario or engagement set by your organization.',// TODO add translations
    'MursionPortal.Status.Abbreviation.LicenseCancelled': 'C',
    'MursionPortal.Scenario.CharacterLimitExceeded.Message': 'Ha excedido el límite de caracteres en {excededCharLength} caracteres. Por favor, revise.',
    'MursionPortal.Scenario.AddedCharacter.Message': '{added} of {totalChar}',
    'MursionPortal.Scenario.CharacterLimit.Message': 'Límite de caracteres: {maxLength}',
    'MursionPortal.Scenario.Description.block.default.text': 'Añadir aquí la descripción',
    'MursionPortal.Scenario.AttachmentField.text': 'Se pueden añadir más archivos',
    'MursionPortal.sessionTable.Column.OriginalSim': 'SIM original',
    'MursionPortal.sessionTable.Column.NewSim': 'Nueva SIM',
    'MursionPortal.Users.EditUser.InactivatedAll.Message': '{code} Certificado(s) desactivado(s).',
    'MursionPortal.Users.EditUser.InactivateAll.Confirmation': '¿Está seguro de que desea desactivar todos los certificados para este SIM Specialist?',
    'MursionPortal.Users.Modal.ActiveCerts.EmptyMessage': 'No hay certificados activos',
    'MursionPortal.Users.Modal.InactiveCerts.EmptyMessage': 'No hay certificados inactivos',
    'MursionPortal.Users.Modal.ActiveCerts': 'Certificados activos',
    'MursionPortal.Users.Modal.InactiveCerts': 'Certificados inactivos',
    'MursionPortal.Users.ActiveCerts.MarkAllInactive.Button': 'Marcar todos inactivos',
    'MursionPortal.SurveyFilter.Incomplete': 'Incompleto',
    'MursionPortal.SurveyFilter.SearchText': 'Buscar encuesta...',
    'MursionPortal.Dashboard.SurveyReminder.Text': 'Hay {surveyCount} encuestas para completar',
    'MursionPortal.Dashboard.RemindMeLater': 'Recordármelo más tarde',
    'MursionPortal.Dashboard.SurveysIncomplete': 'Encuestas incompletas',
    'MursionPortal.AriaLabel.NormalFont': 'Tipo de letra normal',
    'MursionPortal.AriaLabel.MediumFont': 'Tipo de letra mediana',
    'MursionPortal.AriaLabel.LargeFont': 'Tipo de letra grande',
    'MursionPortal.AriaLabel.Font': 'Tipo de letra',
    'Dashboard.LeftPane.CategoryHeader.RescheduledAndCancelled': 'Reprogramado y cancelado',
    'MursionPortal.Dashboard.Summary.Certifications': 'Certificaciones',
    'MursionPortal.SessionsTable.ActionTitle.Pickup': 'recoger',
    'MursionPortal.Label.MissCancellationRate': 'Tasa de pérdidas/cancelaciones',
    'MursionPortal.Label.NotAllowed': 'No permitido',
    'Dashboard.LeftPane.CategoryHeader.Simulations': 'SESIONES',
    'Dashboard.LeftPane.Simulations.SimulationSummaries': 'Resúmenes de sesiones',
    'Dashboard.LeftPane.Simulations.SimulationDetail': 'Detalle de la sesión',
    'MursionPortal.Message.NotEnoughDataToDisplay': 'No ha habido suficientes datos de esta sesión.',
    'MursionPortal.Message.Notdata.SessionMatrix': 'No había suficientes datos de esta sesión para proporcionar análisis de sesión.',
    'MursionPortal.Tooltip.SchedulingWindow': 'Los usuarios finales pueden establecer este valor en días, horas, minutos, después de lo cual solo el usuario podría programar una sesión.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableSelectDate': 'No tenemos franjas horarias disponibles en este momento. Inténtelo de nuevo más tarde. <code1></code1>Póngase en contacto con <code>el servicio de asistencia</code> si necesita ayuda inmediata.',
    'Session.Edit.Modal.JoinSession': 'Iniciar sesión',
    'MursionPortal.SimAttendance.Tooltip.Completed': 'El alumno completó la parte de simulación de la sesión (sin incluir el informe al final de la sesión).',
    'MursionPortal.SimAttendance.Tooltip.Late': 'El alumno se conectó demasiado tarde para completar la simulación.',
    'MursionPortal.SimAttendance.Tooltip.Left': 'El alumno se desconectó intencionalmente antes de completar la simulación. Esto podría deberse a que un alumno no estaba preparado, o un alumno que pudo irse por una emergencia.',
    'Session.Edit.Modal.JoinSessionBtnTooltip': 'El botón Iniciar sesión se habilitaría antes de <code>{minutesBeforeJoin}</code> minutos del inicio de la sesión.',
    'Dashboard.Simulations.SimulationSummaries.TableHeader': 'Datos de resúmenes de sesión',
    'Dashboard.Learners.LearnerActivity.TableHeader': 'Datos de actividad de alumno',
    'Dashboard.Learners.LearnerSimulation.TableHeader': 'Datos de sesiones de alumno',
    'Dashboard.ContractProgress.Column.Missed': 'Alumno ausente',
    'Dashboard.ContractProgress.Column.TotalRescheduled': 'Total reprogramado',
    'Dashboard.ContractProgress.Column.RescheduledMakeup': 'Composición reprogramada',
    'Dashboard.ContractProgress.Column.LateRescheduledSimulations': 'Reprogramado tarde',
    'Dashboard.ContractProgress.Column.EarlyRescheduledSimulations': 'Reprogrado anticipadamente',
    'Dashboard.ContractProgress.Column.CompletedUtilization': 'Finalización de sesión',
    'Dashboard.ContractProgress.Column.ScheduledUtilization': 'Finalización estimada',
    'Dashboard.ContractProgress.ColumnTooltip.Remaining': 'Sesiones compradas menos las sesiones completadas.',
    'Dashboard.ContractProgress.ColumnTooltip.TotalRescheduled': 'Total de simulaciones programadas',
    'Dashboard.ContractProgress.ColumnTooltip.RescheduledMakeup': 'Simulaciones totales marcadas como composición reprogramada',
    'Dashboard.ContractProgress.ColumnTooltip.LateRescheduledSimulations': 'simulaciones que se reprograman tarde.',
    'Dashboard.ContractProgress.ColumnTooltip.EarlyRescheduledSimulations': 'simulaciones que se reprograman anticipadamente.',
    'Dashboard.Learners.Column.SimulationId': 'Sesión - ID',
    'Dashboard.Learners.Column.SimulationId.CopyHover': 'Copiar ID de sesión',
    'Dashboard.Learners.Column.SSO_ID': 'SSO ID',
    'Dashboard.Learners.Column.Recorded': 'Grabado',
    'Dashboard.Learners.Column.LearnerRecordingConsent': 'Consentimiento de grabación del alumno',
    'Dashboard.Learners.Column.RecordingRecipients': 'Destinatarios de la grabación',
    'Dashboard.Learners.Column.RecordingUploaded': 'Grabación cargada',
    'Dashboard.Learners.ColumnTooltip.SSOID': 'Usuarios que han sido aprovisionados',
    'Dashboard.Learners.ColumnTooltip.Recorded': 'Denota si la simulación se grabó o si no se permitió.',
    'Dashboard.Learners.ColumnTooltip.LearnerRecordingConsent': 'Denota si el alumno dio su consentimiento para que se grabara la simulación.',
    'Dashboard.Learners.ColumnTooltip.RecordingRecipients': 'Roles a los que se envía la grabación de simulación.',
    'Dashboard.Learners.ColumnTooltip.RecordingUploaded': 'Indica si se cargó la grabación de simulación.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateReschedule': 'Sesiones que han sido reprogramadas tarde por el alumno.',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateReschedule': 'Reprogramación tardía',
    'MursionPortal.Dashboard.Chart.Header.LearnerStatus': 'Estado de alumno (todos los contratos)',
    'Dashboard.SimulationTable.Column.SimulationStatus': 'Estado de sesión',
    'MursionPortal.Placeholder.ContractValue': 'Seleccione un contrato',
    'MursionPortal.Placeholder.NoContractValue': 'No hay contrato disponible',
    'Dashboard.Learners.Column.AssignedTeams': 'Equipos asignados',
    'SessionReports.VideoRecordingDeletedWithSupportCase': 'Desafortunadamente, este vídeo no se puede ver debido a un #{supportCase} pendiente.',
    'MursionPortal.Dashboard.CompletedSessions': 'Sesiones completadas',
    'MursionPortal.Dashboard.ViewAll': 'Ver todo',
    'MursionPortal.Dashboard.DateOfSimulation': 'Fecha de la sesión',
    'Dashboard.LeftPane.CategoryHeader.Videos': 'VÍDEOS',
    'Dashboard.LeftPane.Videos.NotUploaded': 'Grabaciones no cargadas',
    'Dashboard.NotUploaded.TableHeader': 'Datos de grabaciones no cargadas',
    'Dashboard.VideosTable.Column.SessionId': 'IDSesión',
    'Dashboard.VideosTable.Column.SimspecialistId': 'SIMSpecialistID',
    'Dashboard.VideosTable.Column.ClientId': 'ClientID',
    'Dashboard.VideosTable.Column.SessionEnd': 'Fin de sesión',
    'Dashboard.VideosTable.Column.SoftwareVersion': 'Versión de escenario',
    'Dashboard.VideosTable.NoVideosError': 'No se han encontrado vídeos.',
    'Filters.SimSpecialist': 'Nombre de SIM',
    'Filters.SimSpecialistPlaceHolderHint': 'Buscar SimSpecialists',
    'MursionPortal.Placeholder.SimSpecialistValue': 'Seleccione un SimSpecialist',
    'Users.Table.ErrorOnLoadUserMessage': '¡Se ha producido un error al cargar el usuario!',
    'Dashboard.ContractProgress.InfoBadge.ContractDates': 'Fechas de contrato',
    'Dashboard.ContractProgress.InfoBadge.ContractID': 'ID de contrato',
    'Dashboard.Learners.Column.EarlyCancelled': 'Cancelado anticipadamente',
    'Dashboard.Learners.Column.LateReschedules': 'Reprogramaciones tardías',
    'Dashboard.Learners.Column.EarlyReschedules': 'Reprogramaciones anticipadas',
    'Dashboard.Learners.Column.RescheduledMakeUp': 'Maquillaje reprogramado',
    'Dashboard.Learners.Column.ErrorSim': 'Error (SIM)',
    'Dashboard.Learners.Column.CancelledOps': 'Cancelado (Ops)',
    'Dashboard.Learners.ColumnTooltip.AssignedProjects': 'Proyectos a los que este alumno está asignado actualmente',
    'Dashboard.Learners.ColumnTooltip.AssignedTeams': 'Equipos a los que este alumno está asignado actualmente',
    'Dashboard.Learners.ColumnTooltip.AssignedScenarios': 'Escenarios a los que este alumno está asignado actualmente',
    'Dashboard.Learners.ColumnTooltip.ScheduledScenarios': 'Escenarios que este alumno ha programado pero aún no ha completado',
    'Dashboard.Learners.ColumnTooltip.CompletedScenarios': 'Escenarios que este alumno ha completado',
    'Dashboard.Learners.ColumnTooltip.EarlyCancelled': 'Simulaciones que fueron canceladas anticipadamente (antes de la fecha límite de cancelación) por el alumno, el facilitator o el account owner o simulaciones',
    'Dashboard.Learners.ColumnTooltip.LateReschedules': 'Simulaciones que fueron reprogramadas tarde (después de la fecha límite de cancelación) por el alumno, el facilitator o el account owner',
    'Dashboard.Learners.ColumnTooltip.RescheduledMakeUp': 'Simulaciones a las que ha faltado anteriormente el alumno, el facilitator, el account owner o las simulaciones',
    'Dashboard.Learners.ColumnTooltip.EarlyReschedules': 'Horas de simulación que fueron reprogramadas anticipadamente (antes de la fecha límite de cancelación) por el alumno, el facilitator o el account owner.',
    'Dashboard.Learners.ColumnTooltip.ErrorSim': 'Simulaciones que terminaron en error en el lado SIM de la conexión',
    'Dashboard.Learners.ColumnTooltip.CancelledOps': 'Simulaciones que fueron canceladas por Mursion',
    'SessionReports.VideoRecordingPermissionNotGranted': 'Este vídeo no está disponible porque un participante no quería ser grabado.',
    'SessionReports.NoVideoAvailableForSession': 'No hay vídeo para esta sesión.',
    'MursionPortal.NotAvailable': 'N/A',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.OneToOne': 'Habilite la encuesta de comentarios de SIM posterior a la simulación para 1',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.Workshop': 'Habilite la encuesta de comentarios de SIM posterior a la simulación para las entregas de talleres',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.OneToOne': 'Encuesta de comentarios de SIM posterior a la simulación 1',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.Workshop': 'Entregas de talleres de encuestas de comentarios SIM posteriores a la simulación',
    'Clients.Portal.Button.EditMemberships': 'Editar membresías',
    'Calendar.ProjectsList.ShowOnlyActiveProjects': 'Mostrar solo proyectos activos',
    'MursionPortal.Status.Undefined': 'Indefinido',
    'MursionPortal.Status.Orphan': 'Huérfano',
    'MursionPortal.Status.Reserved': 'Reservado',
    'MursionPortal.Status.Waif': 'Abandonado',
    'MursionPortal.Status.Upcoming': 'Próximamente',
    'MursionPortal.Status.Running': 'En marcha',
    'MursionPortal.Status.NeedsReview': 'Revisión de necesidades',
    'MursionPortal.Status.Reviewed': 'Revisado',
    'MursionPortal.ErrorMessage.NoDataAvailable': 'No había suficientes datos de esta sesión para proporcionar análisis de sesión.',
    'Dashboard.NotUploaded.MainHeader': 'Grabaciones no cargadas',
    'MursionPortal.Message.NotEnoughDataToDisplay.ConversationalFlow': 'No había suficientes datos de esta sesión para crear este gráfico',
    'MursionPortal.Label.SIMSpecialist': 'SIM Specialist',
    'Dashboard.SimulationTable.Column.LearnerRecordingConsent': 'Consentimiento de grabación del alumno',
    'Dashboard.SimulationTable.Column.RecordingRecipients': 'Grabación de destinatarios',
    'Dashboard.SimulationTable.Column.RecordingUploaded': 'Grabación cargada',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerRecordingConsent': 'Denota si el alumno dio su consentimiento para que se grabara la simulación',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingRecipients': 'Roles a los que se envía la grabación de simulación',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingUploaded': 'Denota si se ha cargado la grabación de simulación',
    'MursionPortal.ConversationalFlow.label.zoom': 'Zoom',
    'MursionPortal.label.LearnerInterjects': 'Un alumno interrumpe',
    'MursionPortal.label.AvatarInterjects': 'Un avatar interrumpe',
    'MursionPortal.label.LearnerInterrupted': 'Alumno interrumpido',
    'MursionPortal.label.AvatarInterrupted': 'Avatar interrumpido',
    'MursionPortal.label.Silence': 'Silencio',
    'Clients.Modal.Label.UserRecordingConsent': 'Consentimiento de grabación del usuario',
    'Clients.Modal.Label.UserRecordingConsent.ToolTip': 'Solicitar el consentimiento de grabación del usuario',
    'MursionPortal.Notset': 'No establecido',
    'Clients.Modal.Label.UserML3SocialRecordingConsent': 'Consentimiento de grabación del usuario social de Mursion',
    'Clients.Modal.Label.UserML3SocialRecordingConsent.ToolTip': 'Solicite al usuario su consentimiento para ser grabado. Si esto no está habilitado, el SIM Specialist lo pedirá verbalmente.',
    'MursionPortal.LearnerSimSelector.Sim.Available.ToolTip': 'Las SIM certificadas que no están reservadas para una sesión y tienen disponibilidad para el tiempo seleccionado',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable.ToolTip': 'Las SIM certificadas que no tienen disponibilidad en el sistema y no se reservan para otra sesión en el momento seleccionado',
    'Settings.Config.Client.VideoRecordingDataRetentionPolicy': 'Mostrar la configuración de la política de retención de datos de grabación de vídeo',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToScenario': 'Volver al escenario',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToCalendar': 'Volver al calendario',
    'SessionReports.VideoRecordingDeletedDueToDataRetentionPolicy': 'Este vídeo no está disponible debido a la política de retención de datos de su empresa.',
    'SessionReports.VideoRecordingAvailabilityMessage': 'Este vídeo estará disponible para su visualización hasta el {videoAvailabilityDate}',
    'SessionReports.SessionOrLearnerStatusError': 'No se ha grabado ningún vídeo para esta sesión debido a un error técnico.',
    'Clients.ImportUsers.NextStepButton': 'Siguiente paso',
    'Clients.ImportUsers.CancelButton': 'Cancelar',
    'Clients.ImportUsers.UploadValidateButton': 'Cargar + Validar',
    'Session.Table.Column.Attendance': 'Asistencia',
    'Session.Table.Column.Value.CompleteAttendance': 'Completar asistencia',
    'Session.Table.Column.Value.AttendanceComplete': 'Asistencia completa',
    'Session.Table.Column.Value.NotApplicable': '(No aplicable)',
    'Session.Table.Column.Survey': 'Encuesta',
    'Session.Table.Column.Value.CompleteGoogleForm': 'Completar el formulario de Google',
    'Session.Table.Column.Value.CompleteSurvey': 'Completar encuesta',
    'Session.Table.Column.Value.SurveyComplete': 'Encuesta completa',
    'Projects.Modal.Text.LearnerSelected': 'Debe haber seleccionados al menos {maxLearners} alumnos',
    'Session.Table.Column.Value.SessionNotCompleted': 'La sesión no se ha completado',
    'MursionPortal.Project.InviteToSchedule': 'Invitar a programar',
    'MursionPortal.Project.InvitedToday': 'Invitado hoy',
    'MursionPortal.Project.YourInvitationsText': 'Sus invitaciones se enviarán por la noche.',
    'MursionPortal.Project.InviteToSchedule.Tooltip': 'Ya se están enviando invitaciones para el escenario. Mañana podrá volver a enviar invitaciones.',
    'MursionPortal.Project.InvitedToday.Tooltip': 'Los alumnos que no hayan programado o cancelado/desaprovechado este escenario recibirán una invitación por correo electrónico para programar.',
    'MursionPortal.Project.InviteMessage.SentDate': 'Último envío {sentDate}',
    'ITManager.TechRestriction.EditDialog.ProviderErrorMsg': 'Seleccione el proveedor',
    'ITManager.TechRestriction.EditDialog.GlobalRestriction': 'Restricción global',
    'ITManager.TechRestriction.EditDialog.HolidayRestriction': 'Restricción de vacaciones',
    'ITManager.TechRestriction.Table.Provider': 'Proveedores',
    'Session.Table.Column.Survey.HoverText': 'Si está habilitado, las SIM recibirán un enlace para completar los comentarios sobre el alumno',
    'MursionPortal.Project.InviteMessage.InvitedBy': 'Invitado por {user} - {role}',
    'MursionPortal.Project.LearnersNotYetScheduled': '{learners} alumnos aún tienen que programar',
    'Session.Table.Column.Value.AttendanceRequired': 'Asistencia requerida',
    'MursionPortal.Button.Schedule.Another': 'Programar otro',
    'MursionPortal.EulaAgreement.CodeOfConduct': 'Cada simulación de Mursion involucra a un solo Simulation Specialist en vivo que proporciona el diálogo hablado para hasta cinco avatares virtuales en una sesión de aprendizaje. El trabajo del Simulation Specialist es presentar desafíos conversacionales para permitir que los alumnos, como usted, practiquen las complejas habilidades humanas en cada escenario. En Mursion reconocemos la importancia de crear un espacio seguro en el que las personas puedan practicar valientemente conversaciones potencialmente estresantes, matizadas y complejas. Para garantizar que tanto los alumnos como el Simulation Specialist puedan participar con confianza, pedimos a las personas de ambos grupos que se comprometan con un Código de Conducta básico.',
    'MursionPortal.EulaAgreement.CodeOfConduct.Header': 'Descripción general de la metodología y código de conducta',
    'MursionPortal.EulaAgreement.UserAgreement.Header': 'Acuerdo de usuario',
    'MursionPortal.Import.BackToUser': 'Volver al usuario',
    'MursionPortal.Import.BackToTeam': 'Volver al equipo',
    'Mursion.Portal.Edit.Team.Upload.AccurateUploading': 'Asegúrese de que sus datos sean precisos antes de cargarlos.',
    'Mursion.Portal.Edit.Team.Upload.AskedReUpload': 'Si hay algún error en su archivo, se mostrará en una hoja de cálculo resultante en el Paso 4. Siga las instrucciones del paso 4 para corregir los errores en la plantilla original y vuelva a cargar el archivo.',
    'Mursion.Portal.Edit.Team.Upload.IncludeNewUser': 'También puede incluir nuevos usuarios a importar junto con los usuarios fallidos mientras se vuelve a cargar.',
    'Mursion.Portal.Edit.Team.ReadyToUpload': 'Cuando su archivo esté listo, haga clic en Cargar + Validar',
    'Mursion.Portal.Edit.Team.UploadAndValidationFile': 'Cargar + Validar Archivo',
    'Mursion.Portal.Edit.Team.TeamAssignmentsUpdateSuccessfully': 'Asignaciones de equipo actualizadas correctamente',
    'Mursion.Portal.Edit.Team.UploadSuccessTitle': '¡{userCount} usuarios importados!',
    'Mursion.Portal.Edit.Team.UploadFailureTitle': '¡Error!',
    'Mursion.Portal.Edit.Team.UploadSuccess': 'Todos los usuarios se han añadido con éxito al portal.',
    'Mursion.Portal.Edit.Team.DoneButton': 'Hecho',
    'Mursion.Portal.Edit.Team.DownloadSuccessful': 'Descarga exitosa - Continúe con el siguiente paso',
    'Mursion.Portal.Edit.Team.DownloadError': 'Error de descarga- Inténtelo de nuevo',
    'Mursion.Portal.Edit.Team.UploadFileAgain': 'Cargar archivo de nuevo',
    'Mursion.Portal.Edit.Team.ContinueWithoutCorrecting': 'Continuar sin corregir errores',
    'Mursion.Portal.Edit.Team.SuccessfulImportTitle': 'Importación exitosa',
    'Mursion.Portal.Edit.Team.UnSuccessfulImportTitle': 'Importación sin éxito',
    'Mursion.Portal.Import.User.HeaderTitle': 'Importar usuarios',
    'Mursion.Portal.Import.User.Upload.AskedReUpload': 'Si hay algún error en su archivo, se mostrará en una hoja de cálculo resultante en el Paso 4. Siga las instrucciones del paso 4 para corregir los errores en la <strong>plantilla original</strong> y vuelva a cargar el archivo.',
    'Mursion.Portal.Import.User.ReadyToUpload': 'Cuando su archivo esté listo y formateado correctamente, cargue el archivo para su validación',
    'Mursion.Portal.Import.User.ValidatingYourDataUntilTheUpload': 'Estamos validando los datos e importando usuarios. Por favor, vuelva a comprobarlo en 15 minutos o cuando reciba una alerta de que este proceso se ha completado.',
    'Mursion.Portal.Import.User.UploadSuccessTitle': '¡{successCount} usuarios importados!',
    'Mursion.Portal.Import.User.ErrorContinueTitle': '¡Continuar sin corregir errores!',
    'Mursion.Portal.Import.User.ErrorContinue': '{failedCount} users failed to import as the errors were not fixed.', // TODO add translations
    'Settings.ScenarioBank.Form.ArtBundlesWarning': 'Cambie el nombre de los paquetes de arte a partir de los nombres predeterminados',
    'Mursion.Portal.Import.User.Instruction.Upload': 'Haga clic en <strong>Descargar plantilla de Excel</strong> abajo y abra el archivo descargado (ya sea <strong>client_internal_users.xlsx</strong> o <strong>client_external_users.xlsx</strong>).',
    'Mursion.Portal.Import.User.Instruction.Format': '<strong>En la plantilla, los campos correo electrónico, rol y SSO ID son obligatorios: no los deje en blanco. Añada correctamente la información de usuario debajo de cada encabezado.</strong> NO cambie los encabezados, la estructura de la plantilla o el nombre de archivo, ya que esto podría provocar una importación fallida.',
    'Mursion.Portal.Import.User.Instruction.NewUser': 'En <strong>las columnas J-S o F-O</strong>, añada los nombres de los equipos a los que se deba asignar el usuario (asegúrese de que su ortografía coincida exactamente con el nombre).',
    'Mursion.Portal.Import.User.Instruction.Error': 'Una vez que introduzca todos sus usuarios, guarde el archivo en su escritorio o en algún lugar que recuerde y haga clic en <strong>Continuar</strong>.',
    'Mursion.Portal.Import.User.Instruction.OriginalTemplate': 'Si hay algún error en su archivo, se mostrará en una hoja de cálculo resultante en el Paso 4. Siga las instrucciones para corregir los errores en la <strong>plantilla original</strong> y vuelva a cargar el archivo.',
    'Mursion.Portal.Import.User.Instruction.UploadList': 'Si elige continuar sin corregir los errores, el archivo <strong>Descargar lista de carga fallida</strong> estará disponible solo durante 7 días.',
    'Mursion.Portal.Import.User.DownloadExcelTemplate': 'Descargar plantilla de Excel',
    'Mursion.Portal.Import.User.UploadFileStatus': 'Cargar archivo',
    'Mursion.Portal.Import.User.DoNotCloseUntilUpload': 'Estamos validando los datos e importando usuarios. Este proceso continuará en segundo plano, por lo que puede navegar a otra pestaña o ventana y volver a esta pantalla en cualquier momento. Este proceso de importación debería tardar menos de 5 minutos.',
    'Mursion.Portal.Import.User.FileStatus.validation': 'Importando usuarios',
    'Mursion.Portal.Import.User.CorrectErrorSubHeading': 'Paso final: Corregir errores',
    'Mursion.Portal.Import.User.SuccessCount': '{successCount} users were successfully imported; {failedCount} users failed to import – please review.', // TODO add translations
    'Mursion.Portal.Import.User.DownloadFile': 'Click the <strong>Download Errors</strong> button and open the file to view the errors.', // TODO add translations
    'Mursion.Portal.Import.User.FileTabs': '<strong>En la plantilla, los campos correo electrónico y rol son obligatorios: no los deje en blanco</strong>. NO cambie los encabezados, la estructura de la plantilla o el nombre de archivo, ya que esto podría provocar una importación fallida.',
    'Mursion.Portal.Import.User.ReviewCorrect': 'Corrija los errores en la <strong>plantilla original</strong> (ya sea <strong>client_internal_users.xlsx</strong> o <strong>client_external_users.xlsx</strong>) y vuelva a cargar el archivo.',
    'Mursion.Portal.Import.User.DownloadErrorButton': 'Descargar errores',
    'Mursion.Portal.Edit.Team.EditTeamTitle': 'Editar membresías de equipo',
    'Mursion.Portal.Edit.Team.Instruction.UpdateFile': 'Añada correctamente la información de usuario debajo de cada encabezado. En las columnas L a U, añada los nombres de los equipos a los que se debe asignar al alumno (es decir, equipos a los que se añadirá o actualizará), una columna por equipo. Está bien dejar algunas columnas del equipo en blanco si es necesario. Por favor, asegúrese de que esto se haga correctamente, ya que esto sobrescribirá las asignaciones de equipo existentes.',
    'Mursion.Portal.Edit.Team.Instruction.Process': 'Cargue un archivo cada vez: límite de 5.000 usuarios por archivo.',
    'Mursion.Portal.Edit.Team.Instruction.ProfileFields': '<strong>Los campos correo electrónico y rol son obligatorios: no los deje en blanco.</strong> No cambie los encabezados, la estructura de la plantilla o el nombre de archivo, ya que esto podría provocar una importación fallida.',
    'Mursion.Portal.Edit.Team.Instruction.ErrorOccur': 'Los formatos de archivo compatibles solo son .xlsx.',
    'Mursion.Portal.Edit.Team.DoNotCloseUntilUpload': 'Estamos validando los datos y actualizando las asignaciones de equipo. Este proceso continuará en segundo plano, por lo que puede navegar a otra pestaña o ventana y volver a esta pantalla en cualquier momento. Este proceso debería tardar menos de 5 minutos.',
    'Mursion.Portal.Edit.Team.FileStatus.validation': 'Actualización de las membresías de equipos',
    'Mursion.Portal.Edit.Team.UploadFailureNotification': 'El archivo contiene más de 5.000 usuarios. Póngase en contacto con el soporte técnico o cargue varios archivos con menos de 5.000 usuarios',
    'Mursion.Portal.Edit.Team.ReviewCorrect': '<strong>En la plantilla, los campos correo electrónico y rol son obligatorios: no los deje en blanco.</strong> NO cambie los encabezados, la estructura de la plantilla o el nombre de archivo, ya que esto podría provocar una importación fallida.',
    'Mursion.Portal.Edit.Team.DownloadFile': 'Corrija los errores en el archivo de plantilla original (<strong>edit_learner_team_memberships.xlsx</strong>) -- NO cambie los encabezados, la estructura de la plantilla o el nombre de archivo, ya que esto podría provocar una importación fallida.',
    'Mursion.Settings.ScenarioBank.Modal.ChecklistURL': 'URL de la lista de comprobación de SIM',
    'Mursion.Settings.ScenarioBank.Modal.MustBeAValidURL': 'Debe ser una URL válida',
    'MursionPortal.Label.SpeakingIsZero': 'es 0%',
    'MursionPortal.Label.InterruptionIsZero': 'que es 0%',
    'MursionPortal.CodeOfConduct.SchedulingFlow.Text': 'Al elegir programar su sesión de Mursion, usted acepta el modelo de entrega de simulación de Mursion y confirma que mantendrá y respetará el Código de Conducta de Mursion. Lea más <code>aquí</code>.',
    'Mursion.Portal.Import.User.InvalidFile': 'Solo se cargará la plantilla csv o excel.',
    'Mursion.Portal.Import.User.MaxFileSize': 'El tamaño de archivo no debe exceder los 5 MB',
    'Mursion.Portal.Import.User.Tooltip': 'Haga clic aquí para importar nuevos usuarios o actualizar los usuarios existentes desde un archivo de exportación.',
    'Mursion.Portal.Edit.Team.Tooltip': 'Haga clic aquí para asignar o reasignar la membresía de equipo de forma masiva.',
    'MursionPortal.Project.InviteLearnersToSchedule': 'Invitar a los alumnos a programar',
    'MursionPortal.CheckboxLabel.SchedulingByBookIt': 'Programación por BookIt',
    'Dashboard.Report.SendReport': 'Enviar informe',
    'Dashboard.Report.ScheduleReport': 'Programar informe',
    'Dashboard.Report.EditSchedule': 'Editar programación',
    'Dashboard.Report.ViewSchedule': 'Ver horario',
    'Dashboard.Report.DeleteSchedule': 'Eliminar programación',
    'Dashboard.Report.Filter.Title': 'Filtros seleccionados',
    'Dashboard.Report.SelectReportFrequency.Label': 'Seleccione la frecuencia de informes',
    'Dashboard.Report.SelectSessions.Label': 'Seleccione las sesiones que desea incluir',
    'Dashboard.Report.SelectReportStartDate.Label': 'Seleccione la fecha de inicio del informe',
    'Dashboard.Report.SelectReportStartDate.Tooltip': 'Si ha elegido recibir informes semanalmente, su informe se publicará todas las semanas el día que haya seleccionado. Si ha seleccionado mensualmente, llegará en esa fecha todos los meses.',
    'Dashboard.Report.SelectAccountOwner.Label': 'Seleccione account owner o facilitator',
    'Dashboard.Report.Dropdown.Select.Placeholder': 'Seleccionar',
    'Dashboard.Report.ManuallyAddRecipients.Label': 'Añadir manualmente destinatarios',
    'Dashboard.Report.ManuallyAddRecipients.Label.Placeholder': 'Añadir el correo electrónico aquí',
    'Dashboard.Report.RecipientsBox.Label': 'Destinatarios',
    'Dashboard.Report.RecipientsBox.RecipientMaxLimit': 'No puede haber más de 30 destinatarios',
    'Dashboard.Report.SendReport.ConfirmationModal.Text': 'El informe se ha enviado a los destinatarios.',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.HeadingText': '¡Su informe ha sido programado!',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.Text': 'Deténgase o edite en cualquier momento haciendo clic en <strong>Editar programación.</strong>',
    'Dashboard.Report.EditSchedule.ConfirmationModal.HeadingText': '¡Se han guardado los cambios!',
    'Dashboard.Report.EditSchedule.ConfirmationModal.Text': 'Detenga los informes en cualquier momento haciendo clic en <strong>Editar programación.</strong>',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.Text': 'Se ha suprimido el calendario',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.ConfirmationText': '¿Está seguro de que desea eliminar el calendario de este informe?',
    'Dashboard.Report.ConfirmationModal.Button': '¡Lo tengo!',
    'Dashboard.Report.DeleteSchedule.ConfirmationButton': 'Sí, eliminar',
    'Dashboard.Report.EditSchedule.EditButton': 'Guardar ediciones de programación',
    'Dashboard.Report.ReportFrequencyOption.Daily': 'DIARIO',
    'Dashboard.Report.ReportFrequencyOption.Weekly': 'SEMANAL',
    'Dashboard.Report.ReportFrequencyOption.Monthly': 'MENSUAL',
    'Dashboard.Report.ReportSessionOption.PreviousDay': 'Sesiones Día Anterior',
    'Dashboard.Report.ReportSessionOption.Previous7Days': 'Sesiones 7 Días Anteriores',
    'Dashboard.Report.ReportSessionOption.CurrentSessions': 'Sesiones del día actual',
    'Dashboard.Report.ReportSessionOption.Next7Days': 'Sesiones Próximos 7 Días',
    'Dashboard.Report.ReportSessionOption.MonthToDate': 'Sesiones Mes hasta la fecha',
    'MursionPortal.Dashboard.UpcomingSession': 'Próxima sesión',
    'Dashboard.Report.ReportSessionOption.PreviousFullMonth': 'Sesiones Mes Completo Anterior',
    'Dashboard.Report.ReportSessionOption.Previous30Days': 'Sesiones 30 Días Anteriores',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing': 'Secuenciación de escenarios',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing.Text': 'Haga clic en el botón para ver y configure el orden de los escenarios en este proyecto.',
    'Projects.ProjectInfo.Button.ScenarioSequence': 'Secuencia de escenarios',
    'MursionPortal.Projects.DragAndDropModal.Text': 'Arrastre y suelte escenarios en orden de preferencia',
    'MursionPortal.Projects.DragAndDropModal.DragHandlerImage': 'Arrastre imagen de mango',
    'MursionPortal.Projects.DragAndDropModal.ModalCloseIcon': 'Icono de cierre',
    'MursionPortal.Project.Scheduled.NextDate': 'Programado {nextDate}',
    'MursionPortal.Project.Sequencing': 'Secuenciación',
    'MursionPortal.Project.Checkbox.Sequencing': 'Habilitar secuenciación',
    'MursionPortal.Dashboard.Join.Tooltip': 'Una vez que esta sesión esté disponible, podrá unirse a ella',
    'MursionPortal.Project.InviteToScheduleProject.Tooltip': 'Los alumnos que no hayan programado o cancelado/desaprovechado escenarios en este proyecto recibirán una invitación por correo electrónico para programar.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts': 'Atajos de teclado',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar': 'Tecla de barra espaciadora',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar.Text': 'Seleccione o suelte el escenario.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys': 'Teclas de flecha derecha e izquierda o teclas de flecha arriba y abajo',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys.Text': 'Arrastre el escenario a izquierda y derecha.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc': 'Tecla Esc',
    'MursionPortal.Esc': 'Esc',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc.Text': 'Cerrar secuencia de escenario modal',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter': 'Tecla Enter',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter.Text': 'Cerrar descripción emergente de métodos atajos de teclado',
    'MursionPortal.Projects.DragAndDropModal.Tooltip': 'Abrir la descripción emergente de atajos de teclado',
    'Dashboard.Report.DuplicateEntry.ErrorMessage': 'No se permite una entrada duplicada',
    'Projects.ProjectInfo.EnableSessionTimeStep': 'Activar paso de tiempo de sesión',
    'Projects.ProjectInfo.SessionTimeStepInfo': 'Se utilizará el paso de tiempo de sesión de nivel de licenciatario si el valor de paso de tiempo de sesión no se establece en nivel de proyecto',
    'Session.Edit.Modal.JoinSimulation': 'Unirse a la sesión',
    'MursionPortal.Selector.RequestStatus': 'Estado de la solicitud',
    'MursionPortal.Dashboard.RetakeSimulation': 'Simulación de repetición',
    'MursionPortal.Dashboard.NextSimulationAssigned': 'Siguiente simulación asignada',
    'MursionPortal.AriaLabel.SelectTime': 'Seleccione hora',
    'Dashboard.Report.Frequency.Date.Select.Placeholder': 'Seleccione fecha de inicio',
    'MursionPortal.InstructoScenarioTeamMapping.Header': 'Seleccione el escenario y el equipo que desea adjudicar',
    'MursionPortal.InstructoScenarioTeamMapping.MappingAlreadyExistMessage': 'Este escenario y este equipo ya han sido adjudicados a un contexto diferente.',
    'MursionPortal.DataSummaryPoints.manualFulFilled': 'Rellenado manualmente',
    'MursionPortal.DataSummaryPoints.pendingRequest': 'Basado en la demanda pendiente',
    'MursionPortal.DataSummaryPoints.declined': 'Solicitudes rechazadas',
    'MursionPortal.DataSummaryPoints.avgFulfilled': 'Cumplimiento promedio para manual',
    'MursionPortal.DataSummaryPoints.totalSwap': 'Intercambios totales',
    'MursionPortal.DataSummaryPoints.swapAccepted': 'Intercambios aceptados',
    'MursionPortal.Dashboard.MissedSimulations': 'Simulaciones desaprovechadas',
    'MursionPortal.Dashboard.RescheduledSimulations': 'Simulaciones reprogramadas',
    'MursionPortal.Dashboard.BookedSimulations': 'Simulaciones reservadas',
    'MursionPortal.Dashboard.CancelledSimulations': 'Simulaciones canceladas',
    'Dashboard.UserTable.Column.In': 'En',
    'Projects.ProjectInfo.ConfirmationDialog.ScenarioSequencing.Text': '¿Está seguro de que desea deshabilitar la secuenciación? Esto también eliminaría la numeración de secuencia en cada escenario.',
    'Projects.ProjectInfo.Disable.Sequencing': 'Deshabilitar secuenciación',
    'MursionPortal.ErrorMessage.TechnicalIssueMsg': 'Inténtelo de nuevo. Nos hemos encontrado con un problema técnico.',
    'SessionFlow.Text.Event.DeletedByUsername': 'Eliminado por {usuario}',
    'Settings.ScenarioBank.Modal.timestepShouldBeAtLeast': 'Desmarque "Habilitar paso de tiempo de sesión" o introduzca un valor de paso de tiempo de al menos {minutos} minutos',
    'MursionPortal.AriaImage.Attribute.Previous': 'Anterior',
    'MursionPortal.AriaImage.Attribute.Notify': 'Notificar',
    'MursionPortal.AriaImage.Attribute.Upload': 'Cargar',
    'MursionPortal.Report.FilterHeading.SoftwareVersions': 'Versiones de software',
    'MursionPortal.Report.FilterHeading.DeliveryModes': 'Modos de entrega',
    'MursionPortal.Report.FilterHeading.LearnerSessionStatuses': 'Estados de sesión de alumno',
    'MursionPortal.Report.FilterHeading.LearnerStatuses': 'Estados de alumno',
    'MursionPortal.Report.FilterHeading.SimulationStatus': 'Estado de simulación',
    'MursionPortal.Project.DuplicateProjectBtnTooltip': 'Esto duplicaría todos los ajustes y la configuración de este proyecto en un nuevo proyecto',
    'MursionPortal.Project.DuplicateProjectBtnText': 'Duplicar a Magic Project',
    'MursionPortal.ScenarioInfo.Label.Until': 'Tiene hasta el {date} para comenzarlo {completionRate} veces.',
    'Clients.Modal.Button.Schedule.Tooltip.Text': 'Primero debe programar el escenario anterior',
    'MursionPortal.Scenario.Name': 'Nombre de escenario',
    'MursionPortal.ScenarioCard.BackToProjects.Button': 'Volver a Proyectos',
    'MursionPortal.ScenarioCard.ViewMore.Button': 'Ver más',
    'MursionPortal.ScenarioInfo.Documents': 'Documentos de escenario',
    'MursionPortal.ScenarioInfo.Documents.File': '{extensión} Archivo',
    'MursionPortal.ScenarioCard.ViewSessions': 'Ver sesiones',
    'MursionPortal.Label.ScenarioPreview': 'Vista previa del escenario',
    'MursionPortal.ScenarioInfo.AssignedTeams': 'Buscar o seleccionar Equipo(s)',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialists': 'SIM Specialists certificados',
    'MursionPortal.ScenarioInfo.AddSimSpecialist': 'Añadir Sim Specialist',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.PencilIcon': 'Icono de edición',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.DeleteButton': 'Eliminar SIM',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.SearchText': 'Buscar o seleccionar SIM Specialist(s)',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.AddSim': 'Añadir Sim',
    'MursionPortal.Label.InactiveSIMSpecialists': 'SIM Specialists Inactivos',
    'MursionPortal.Label.Search.InactiveSIMSpecialists': 'Busque o seleccione SIM Specialist(s) inactivo(s)',
    'MursionPortal.Project.InviteToSchedule.Sequencing.Tooltip': 'La secuenciación está establecida para este escenario y ningún alumno ha programado aún el escenario anterior.',
    'Clients.Modal.Label.FacilitatorView.Tooltip': 'Si se establece en Nivel de cliente, los facilitators podrán ver los detalles de nivel de Account Owner. Si se establece a nivel de equipo, el facilitator solo puede ver información sobre los equipos a los que están asignados.',
    'Clients.Modal.Label.VoiceAnalytics.Tooltip': 'Proporciona a los alumnos un análisis automatizado de su sesión.',
    'Clients.Modal.Label.Show.Utilization.Reporting.Tooltip': 'Añade informes de utilización al tablero del Account Owner.',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy.Tooltip': 'Habilite si el cliente tiene una política de retención de datos personalizada.',
    'MursionPortal.Label.SoftwareType.Tooltip': '"Solo web" indica que los alumnos solo pueden acceder a sus sesiones a través de Mursion Magic, la plataforma basada en navegador.',
    'Projects.ProjectInfo.Label.Timeline.Tooltip': 'Todas las sesiones de este proyecto deben completarse dentro del cronograma de proyecto.',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording.Tooltip': 'Desmarque para evitar grabaciones.',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording.Tooltip': 'Los alumnos pueden optar por no grabar. Si no se marca y un alumno rechaza el permiso de grabación, no podrá asistir a la sesión.',
    'Projects.ProjectInfo.label.Tags.Tooltip': 'Las etiquetas son agrupaciones organizativas para encontrar rápidamente proyectos similares a nivel de cliente.',
    'Projects.ProjectInfo.label.DemandTime.Tooltip': 'Periodo de tiempo en la que los alumnos pueden solicitar simulaciones para las franjas horarias de demanda',
    'Projects.ProjectInfo.label.DemandSlots.Tooltip': 'Establece el número de opciones de programación que ve un alumno al programar. Si se establece en cero, el alumno podrá reservar instantáneamente una franja horaria abierta.',
    'Projects.ProjectInfo.label.Rescheduling.Tooltip': 'Permite a los alumnos reprogramar sesiones. Tenga en cuenta que el alumno no puede reservar una sesión el mismo día a menos que se marque La opción "El mismo día".',
    'MursionPortal.Project.SameDay.Tooltip': 'Permite a los alumnos reprogramar para más tarde en el mismo día calendario.',
    'Cleints.Modal.Label.AvailablePrivateSSOLabel': 'Habilitar SSO privado:',
    'Cleints.Modal.Label.DomainsLabel': 'Dominio web de cliente:',
    'Clients.Modal.Label.Logo': 'Logotipo de cliente:',
    'Projects.ProjectInfo.Label.CancellationDeadline': 'Fecha límite de cancelación',
    'Clients.Modal.Label.MursionSocialAttendance.ToolTip': 'Recopile la asistencia de alumnos durante las sesiones de Mursion Social.',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays': 'Retención de datos de grabación de vídeo (en días):',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy': 'Política de retención de datos de grabación de vídeo:',
    'MursionPortal.PasswordRequirementMessage.SpecialCharaters': 'al menos un carácter especial',
    'MursionPortal.ErrorMessage.InvalidCaptcha': 'Captcha no válido. Inténtelo de nuevo.',
    'MursionPortal.Project.MissedAlert.TooLateJoin': 'Usted ha llegado un poco tarde para unirse',
    'MursionPortal.Project.MissedAlert.DontWorry': '¡No se preocupe! Sucede.',
    'MursionPortal.Project.MissedAlert.ClickReschedule': 'Haga clic en reprogramar la simulación.',
    'MursionPortal.Project.EmailMissedLink.TooLateJoin.ClickRescheduleTxt': 'Haga clic a continuación para reprogramar su simulación.',
    'MursionPortal.Password.Strength.Tooweak': 'La contraseña es demasiado débil',
    'MursionPortal.Password.Strength.Weak': 'La contraseña es débil',
    'MursionPortal.Password.Strength.Medium': 'La contraseña es media',
    'MursionPortal.Password.Strength.Strong': 'La contraseña es fuerte',
    'Dashboard.LeftPane.User.SchedulingInfo' : 'Información de programación',
    'Dashboard.Button.ExportToEmail' : 'Exportar datos',
    'Filters.All' : 'Todo',
    'Filters.More' : ' más',
    'Filters.AllExcept' : 'Todo, excepto',
    'Filters.MoreClients' : ' más clientes',
    'Filters.AllClients' : 'Todos los clientes',
    'Filters.Role' : 'Rol',
    'Filters.TimeZone' : 'Zona horaria',
    'Dashboard.UserActivity.TableHeader' : 'Datos de actividad de usuario',
    'MursionPortal.GenerateLearnerSurveyLink' : 'Copiar enlace de encuesta',
    'MursionPortal.SurveyLinkLabel' : 'El enlace de encuesta se ha copiado en su portapapeles',
    'MursionPortal.Attention' : '¡Atención!',
    'MursionPortal.LinkCannotBeGenerated' : 'No se puede generar el enlace porque en este proyecto de escenarios falta el campo ID de Contrato.',
    'Dashboard.UserTable.Column.UserName' : 'Nombre',
    'Dashboard.UserTable.Column.DateInvited' : 'Invitado',
    'Dashboard.UserTable.Column.DateRegistered' : 'Registrado',
    'Dashboard.UserTable.Column.DateArchived' : 'Archivado',
    'Dashboard.UserTable.Column.DateLastLogin' : 'Último inicio de sesión',
    'Dashboard.UserTable.Column.UsedSoftwareVersions' : 'Software',
    'Dashboard.UserTable.Column.SessionsScheduled' : 'Simulaciones programadas',
    'Dashboard.UserTable.Column.SessionsCompleted' : 'Simulaciones completadas',
    'Dashboard.UserTable.Column.SessionsMissed' : 'Simulaciones perdidas',
    'Dashboard.UserTable.Column.LateCancellations' : 'Simulaciones canceladas',
    'Dashboard.UserTable.Column.SessionsError' : 'Errores de simulaciones',
    'Session.Table.Column.ProjectId' : 'ID de Contrato',
    'Session.Edit.Modal.Button.Leave' : 'Cancelar mi sesión',
    'Users.TableModal.SSOIDPlaceHolder' : 'ID DEL SSO',
    'Users.Table.Modal.SSOID' : 'ID DEL SSO',
    'Dashboard.SchedulingInfo.Filter.IndustriesAll' : 'Industria',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion' : 'Software',
    'Dashboard.SchedulingInfo.MainHeader' : 'Información de programación',
    'Dashboard.SchedulingInfo.TableHeader' : 'Datos de información de programación',
    'RestorePassword.ResetPassword' : 'Restablecer contraseña',
    'RestorePassword.EmailHint' : 'introduzca su correo electrónico',
    'Dashboard.ProjectUtilization.MainHeader' : 'Utilización de proyectos',
    'Settings.Config.ProjectAndScenario.AllowMursionMagic' : 'Tipo Mursion Magic',
    'Dashboard.SessionLearners.MainHeader' : 'Alumnos de la sesión',
    'MursionPortal.Table.ScrollMessage' : 'desplazar a la izquierda para ver columnas adicionales',
    'Dashboard.ProjectsActivity.MainHeader' : 'Actividad de proyecto',
    'MursionPortal.Table.ColumnHeader.ProjectId' : 'ID de Contrato',
    'MursionPortal.Button.ClearAll' : 'Borrar todo',
    'MursionPortal.Dashboard.Chart.Registration' : 'Registro de usuario',
    'MursionPortal.Dashboard.Chart.InviteAll' : 'Invitar a todos',
    'MursionPortal.Dashboard.Chart.Unregistered' : 'No resgistrado',
    'MursionPortal.Dashboard.Chart.Registered' : 'Registrado',
    'MursionPortal.Dashboard.Chart.Login' : 'Inicio de sesión',
    'MursionPortal.Dashboard.Chart.NotLoggedIn' : 'No se ha iniciado sesión',
    'MursionPortal.Dashboard.Chart.LoggedIn' : 'Se ha iniciado sesión',
    'MursionPortal.Dashboard.Chart.Scheduling' : 'Usuarios programados',
    'MursionPortal.Dashboard.Chart.NotScheduled' : 'No programado',
    'MursionPortal.Dashboard.Chart.Simulations' : 'Simulaciones',
    'MursionPortal.Dashboard.Chart.LateCancellations' : 'Cancelaciones tardías',
    'MursionPortal.Dashboard.Chart.Completed' : 'Completado',
    'MursionPortal.Dashboard.Chart.Errors' : 'Errores',
    'MursionPortal.Dashboard.Chart.Scheduled' : 'Programado',
    'MursionPortal.Dashboard.Chart.Tooltip.Registered' : 'Usuarios que han sido invitados o usuarios de SSO habilitados y que han iniciado sesión.',
    'MursionPortal.Dashboard.Chart.Tooltip.Unregistered' : 'Usuarios que han sido invitados pero todavía no han iniciado sesión o usuarios de SSO habilitados y que todavía no han iniciado sesión.',
    'MursionPortal.Dashboard.Chart.Tooltip.NotScheduled' : 'Usuarios de SSO registrados/habilitados que todavía no han programado una simulación',
    'MursionPortal.Dashboard.Chart.Tooltip.Scheduled' : 'Usuarios de SSO registrados/habilitados que han programado al menos una simulación',
    'MursionPortal.Label.ProjectId' : 'ID de Contrato',
    'MursionPortal.Label.SoftwareType' : 'Tipo',
    'MursionPortal.Label.SharingOrDownloading' : 'No permitir compartir ni descargar',
    'Settings.SSO.Modal.EmployeeIDClaimName' : 'ID de empleado Reclamación Nombre',
    'Settings.SSO.Modal.LocationClaimName' : 'Ubicación Reclamación Nombre',
    'Settings.SSO.Modal.DepartmentClaimName' : 'Departamento Reclamación Nombre',
    'Settings.SSO.Modal.PhoneClaimName' : 'Teléfono Reclamación Nombre',
    'Settings.SSO.Modal.TitleClaimName' : 'Título Reclamación Nombre',
    'Settings.SSO.Modal.TimezoneClaimName' : 'Zona horaria Reclamación Nombre',
    'Settings.SSO.Modal.LanguageClaimName' : 'Idioma Reclamación Nombre',
    'MursionPortal.ProjectId' : 'ID de Contrato',
    'MursionPortal.AltText.MursionPortal' : 'Portal de Mursion',
    'MursionPortal.Message.ClickToOpenApplicationDefault' : 'Haga clic a continuación para asistir a su simulación',
    'MursionPortal.Message.ClickToOpenApplicationDesktopWeb' : 'Haga clic en el botón de abajo para acceder a su simulación a través de la aplicación de escritorio de Mursion',
    'MursionPortal.Message.OpenWebApplication' : 'También puede acceder a su simulación en Mursion Magic, nuestra aplicación web',
    'MursionPortal.Button.OpenMursionWeb' : 'Ejecutar Mursion Magic',
    'MursionPortal.Message.Or' : 'O',
    'MursionPortal.Message.PortalHeadingText' : 'Simulaciones de entrenamiento de EQ',
    'ITManager.TechRestriction.Table.CreateButton' : 'Añadir restricción',
    'ITManager.TechRestriction.EditDialog.TagName' : 'Nombre de etiqueta',
    'ITManager.TechRestriction.EditDialog.Title' : 'Editar restricción técnica',
    'ITManager.TechRestriction.CreateDialog.Title' : 'Nueva restricción técnica',
    'MursionPortal.DateTimePicker.StartDate' : 'Fecha de inicio',
    'MursionPortal.DateTimePicker.EndDate' : 'Fecha de finalización',
    'MursionPortal.DateTimePicker.TimeNotAvailable' : 'La hora seleccionada no está disponible.',
    'ITManager.TechRestriction.ViewDialog.Title' : 'Ver restricción técnica',
    'MursionPortal.DateTimePicker.Timezone' : 'Zona horaria',
    'MursionPortal.Text.Team' : 'equipo',
    'MursionPortal.Text.Industry' : 'industria',
    'MursionPortal.Text.Environment' : 'ambiente',
    'MursionPortal.Text.Avatar' : 'avatar',
    'MursionPortal.Status.Capitalized.Upcoming' : 'PRÓXIMO',
    'MursionPortal.Status.Capitalized.Undefined' : 'NO DEFINIDO',
    'MursionPortal.Status.Capitalized.Orphan' : 'HUÉRFANO',
    'MursionPortal.Status.Capitalized.Waif' : 'ABANDONADO',
    'MursionPortal.Status.Capitalized.Reserved' : 'RESERVADO',
    'MursionPortal.Status.Capitalized.Booked' : 'RESERVADO',
    'MursionPortal.Status.Capitalized.Pending' : 'PENDIENTE',
    'MursionPortal.Status.Capitalized.Running' : 'EN EJECUCIÓN',
    'MursionPortal.Status.Capitalized.Missed' : 'PERDIDO',
    'MursionPortal.Status.Capitalized.NeedsReview' : 'NECESITA_REVISIÓN',
    'MursionPortal.Status.Capitalized.Reviewed' : 'REVISADO',
    'MursionPortal.Status.Capitalized.Cancelled': 'CANCELADO',
    'MursionPortal.Status.Capitalized.LicenseCancelled' : 'CANCELADO',
    'MursionPortal.Status.Capitalized.Error' : 'ERROR',
    'MursionPortal.Status.Capitalized.SimError': 'ERROR',// TODO add translations
    'MursionPortal.Status.Capitalized.Offline' : 'OFFLINE',
    'MursionPortal.Status.Abbreviation.PendingNoLearners' : 'P',
    'MursionPortal.Status.Abbreviation.PendingNoParticipants' : 'R',
    'MursionPortal.Status.Abbreviation.Swap' : 'S',
    'MursionPortal.Status.Abbreviation.Prebooked' : 'PB',
    'MursionPortal.Status.Abbreviation.Booked' : 'B',
    'MursionPortal.Status.Abbreviation.Active' : 'A',
    'MursionPortal.Status.Abbreviation.Completed' : 'C',
    'MursionPortal.Status.Abbreviation.Missed' : 'M',
    'MursionPortal.Status.Abbreviation.NeedsReview' : 'NR',
    'MursionPortal.Status.Abbreviation.Error' : 'E',
    'Settings.SSO.Table.NoSsoConfigurationsFound' : 'No se han encontrado configuraciones de SSO.',
    'MursionPortal.Label.SelfReviewTracker' : 'RASTREADOR DE AUTORREVISIÓN',
    'MursionPortal.ToggleButton.All' : 'todo',
    'MursionPortal.Label.PerformanceTracker' : 'RASTREADOR DE RENDIMIENTO',
    'MursionPortal.ToggleButton.TurnTaking' : ' CAMBIO DE TURNOS',
    'MursionPortal.Tooltip.Collaborative' : 'Esta es una medida del tiempo en el que tanto usted como el avatar o los avatares se turnaron sin interrumpirse durante la conversación.',
    'MursionPortal.Tooltip.Disruptive' : 'Esta es una medida del tiempo en el que usted o los avatares se interrumpieron mutuamente durante la conversación.',
    'MursionPortal.Tooltip.Speaking' : 'Esta es una medida del tiempo que usted ha pasado hablando con el avatar o los avatares durante la conversación.',
    'MursionPortal.Tooltip.Listening' : 'Esta es una medida del tiempo que usted ha pasado escuchando al avatar o los avatares durante la conversación.',
    'MursionPortal.Tooltip.MiScore' : 'Su eficacia social durante la conversación.',
    'MursionPortal.Tooltip.Percentile' : 'incluir una descripción de lo que significa en algún lugar de la UI',
    'MursionPortal.Label.MiScore' : 'Puntuación MI',
    'MursionPortal.Label.Percentile' : 'percentil',
    'MursionPortal.Label.NotEnoughData' : 'No hay suficientes datos',
    'MursionPortal.Label.Listening' : 'Escuchando',
    'MursionPortal.Label.Speaking' : 'Hablando',
    'MursionPortal.Label.Collaborative' : 'Colaborador',
    'MursionPortal.Label.Disruptive' : 'Perturbador',
    'MursionPortal.ToggleButton.LocalLeaderboard' : 'Tabla de clasificación local',
    'MursionPortal.ToggleButton.GlobalLeaderboard' : 'Tabla de clasificación mundial',
    'MursionPortal.ToggleButton.DeepInsights' : 'Percepciones profundas',
    'MursionPortal.ErrorMessage.DataBeingProcessed' : 'Los datos analíticos de la sesión se procesan en el momento. Los datos se muestran en la página una vez finalizado el procesamiento.',
    'MursionPortal.ErrorMessage.UnsupportedFormat' : 'El análisis de la sesión está vacío o tiene un formato no soportado.',
    'MursionPortal.Label.Positive' : 'Positivo',
    'MursionPortal.Label.Negative' : 'Negativo',
    'MursionPortal.Label.Neutral' : 'Neutral',
    'MursionPortal.Header.Tracking' : 'Seguimiento',
    'MursionPortal.Button.ReadMore' : 'Leer más',
    'MursionPortal.Button.Hide' : 'Esconder',
    'MursionPortal.Title.Interchange' : 'Intercambio. Conversación natural y cambio de turnos sin intervenciones ni exclamaciones',
    'MursionPortal.Title.InterventionByYou' : 'Intervención por parte de usted. Interrumpiendo con éxito a alguien que está hablando, impidiéndoles continuar',
    'MursionPortal.Title.InterventionByAvatars' : 'Intervención por parte de avatares. Interrumpiendo con éxito a alguien que está hablando, impidiéndoles continuar',
    'MursionPortal.Title.InterjectionByYou' : 'interrupción por parte de usted. Intentando interrumpir sin éxito a alguien que está hablando',
    'MursionPortal.Title.InterjectionByAvatars' : 'Interrupción por parte de avatares. Intentando interrumpir sin éxito a alguien que está hablando',
    'MursionPortal.Title.Pauses' : 'Pausas. Más de 0,2 s y menos de 0,75 s',
    'MursionPortal.Label.VoiceSelf' : 'Voz propia',
    'MursionPortal.Label.VoiceOthers' : 'Voz de otros',
    'MursionPortal.Label.Avatars' : 'Avatares',
    'MursionPortal.Label.You' : 'Usted',
    'MursionPortal.ColumnHeader.User' : 'Usuario',
    'MursionPortal.ColumnHeader.Percentile' : 'Percentil',
    'MursionPortal.ColumnHeader.ScenariosCompleted' : 'Escenarios completados',
    'MursionPortal.Message.LeaderboardsUpdated' : 'Las tablas de clasificación se actualizarán a medida que más personas completen sus simulaciones.',
    'MursionPortal.Message.CheckBack' : 'Por favor, vuelva a comprobar de vez en cuando para ver su situación',
    'MursionPortal.Label.SimStart' : 'Inicio de simulación',
    'MursionPortal.Label.SimEnd' : 'Fin de simulación',
    'MursionPortal.Tooltip.Summary.Speaking' : 'Tiempo dedicado a hablar con el avatar o avatares durante toda la conversación',
    'MursionPortal.Tooltip.Summary.Listening' : 'Tiempo dedicado a escuchar al avatar o avatares durante toda la conversación',
    'MursionPortal.Capitalized.NotAvailable' : 'N.D.',
    'MursionPortal.ColumnHeader.Trait' : 'Rasgo',
    'MursionPortal.ColumnHeader.Me' : 'Yo',
    'MursionPortal.ColumnHeader.OthersAvg' : 'Otros (promedio)',
    'MursionPortal.TooltipText.Speaking' : 'Habla más que el {percentage} de la gente.',
    'MursionPortal.TooltipText.Listening' : 'Escucha más que el {percentage} de la gente.',
    'MursionPortal.TooltipText.Collaborative' : 'Usted es más colaborador que el {percentage} de la gente.',
    'MursionPortal.TooltipText.Disruptive' : 'Interrumpe más que el {percentage} de la gente.',
    'MursionPortal.Label.FeedbackValue' : 'Valor de comentarios',
    'MursionPortal.Button.AnalyticsData' : 'Datos analíticos',
    'MursionPortal.Button.RawAnalyticsData' : 'Datos analíticos sin procesar',
    'MursionPortal.Header.SocialEffectiveness' : 'Eficacia social',
    'MursionPortal.Label.Current' : 'Actual',
    'MursionPortal.Header.ArticulationRate' : 'Tasa de articulación',
    'MursionPortal.Label.Average' : 'Promedio',
    'MursionPortal.Header.Conversation' : 'Conversación',
    'MursionPortal.Header.SpeakingListening' : 'Hablando/Escuchando',
    'MursionPortal.Header.CumulativeTurnTaking' : 'Acumulación de cambio de turnos',
    'MursionPortal.Header.InstantaneousTurnTaking' : 'Cambio de turnos instantáneo',
    'MursionPortal.ColumnHeader.ScenarioTemplate' : 'PLANTILLA_ESCENARIO',
    'MursionPortal.ColumnHeader.Provider' : 'PROVEEDOR',
    'MursionPortal.Label.AbbreviatedMinute' : 'm',
    'MursionPortal.Header.Mursion' : '<code>M</code>ursion',
    'MursionPortal.Header.Index' : '<code>Í</code>ndice',
    'MursionPortal.Header.Score' : 'Puntuación',
    'MursionPortal.visibilityHidden.Selected' : 'seleccionado',
    'MursionPortal.AriaLabel.CloseTechSupport' : 'cerrar el soporte técnico',
    'MursionPortal.Monitoring' : 'Monitorización',
    'MursionPortal.Monitoring.User.FirstLetter' : 'U',
    'MursionPortal.Monitoring.Peer.Label' : 'PEER - PEER',
    'MursionPortal.Monitoring.Peer.NoUser' : 'Seleccione el usuario para ver los detalles',
    'MursionPortal.Monitoring.Sessions.Total' : 'Total de sesiones',
    'MursionPortal.Monitoring.Sessions.Displayed' : 'Sesiones ML3 (mostradas)',
    'MursionPortal.Monitoring.Sessions.NotDisplayed' : 'No Sesiones ML3',
    'MursionPortal.Monitoring.Card.StartTime' : 'Hora de inicio',
    'MursionPortal.Monitoring.Notification.NewSession' : 'Se ha añadido una nueva sesión.',
    'MursionPortal.ClickRefresh' : 'Haga clic en "Actualizar" para ver los cambios.',
    'MursionPortal.AriaLabel.DraftScenarioSuperScript' : 'Superíndice de borrador de escenario {statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedScenarioSuperScript' : 'Superíndice de escenario finalizado {statusConfiguration}',
    'MursionPortal.AriaLabel.StartedScenarioSuperScript' : 'Superíndice de escenario iniciado {statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedScenarioSuperScript' : 'Superíndice de escenario acabado {statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedScenarioSuperScript' : 'Superíndice de escenario archivado {statusConfiguration}',
    'Calendar.Button.ScheduleEventON' : 'Programar evento el {thisDate}',
    'Calendar.Button.CantScheduleON' : 'No se puede programar el evento el {thisDate}',
    'MursionPortal.AriaLabel.ChooseFileButton' : 'elegir el botón de archivo',
    'MursionPortal.Label.VisibilityHidden.FileUploaded' : 'Archivo cargado',
    'MursionPortal.VisibilityHidden.EntityAdded' : 'Entidad añadida',
    'MursionPortal.VisibilityHidden.EntityRemoved' : 'Entidad eliminada',
    'MursionPortal.Legend.VisibilityHidden.StationTypeHomeOffice' : 'Tipo de estación (Oficina, Hogar)',
    'MursionPortal.Legend.VisibilityHidden.HoursFullTimePartTime' : 'Horario (tiempo completo, tiempo parcial)',
    'MursionPortal.AriaLabel.ShowTechSupport' : 'mostrar soporte técnico',
    'MursionPortal.Monitoring.Card.UntilTheEnd' : 'Tiempo restante',
    'MursionPortal.Monitoring.Card.OverTime' : 'Horas extras',
    'MursionPortal.Title.DemandBasedSessionScheduling' : 'Programación de sesiones basada en la demanda',
    'MursionPortal.CheckboxLabel.SchedulingByDemand' : 'Programación por demanda',
    'MursionPortal.CheckboxLabel.ProjectTimezone' : 'Zona horaria del proyecto',
    'MursionPortal.CheckboxLabel.DemandTime' : 'Hora de la demanda',
    'MursionPortal.Label.DemandTimeStart' : 'Hora de Inicio de la demanda',
    'MursionPortal.Label.DemandTimeEnd' : 'Hora de finalización de la demanda',
    'MursionPortal.Label.DemandWindow' : 'Ventana de demanda',
    'MursionPortal.Project.Days' : 'Día(s)',
    'MursionPortal.MyProfile.Hover.Profile' : 'perfil {userName}',
    'Mursion.Portal.SessionUserStatus.Connected' : 'CONECTADO',
    'Mursion.Portal.SessionUserStatus.Late' : 'TARDE',
    'Mursion.Portal.SessionUserStatus.Immersing' : 'INMERSIÓN',
    'Mursion.Portal.SessionUserStatus.Left' : 'ABANDONADA',
    'Mursion.Portal.SessionUserStatus.Declined' : 'RECHAZADA',
    'MursionPortal.DemandBasedScheduling.SubmitPage.ThankYou' : '¡Gracias!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.Message' : 'Estamos trabajando para terminar su simulación basada en franjas horarias que usted proporcionó. Espere un correo electrónico nuestro con más detalles. ¡Hasta pronto!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.GoToDashboard' : 'Ir a Mi Tablero',
    'MursionPortal.DemandBasedScheduling.ScheduleASession' : 'Programar una sesión',
    'MursionPortal.DemandBasedScheduling.Details' : 'DETALLES',
    'MursionPortal.DemandBasedScheduling.Slot' : 'FRANJA {count}',
    'MursionPortal.DemandBasedScheduling.PickDateAndTime' : 'Escoger la fecha y la hora para establecer',
    'MursionPortal.DemandBasedScheduling.Button.Set' : 'ESTABLECER',
    'MursionPortal.DemandBasedScheduling.Button.Clear' : 'BORRAR',
    'MursionPortal.DemandBasedScheduling.Jumbotron.Text' : 'Los detalles del escenario se cargarán una vez realizada la selección',
    'MursionPortal.AriaDescribedby.StartDate' : 'Fecha de inicio: Pulse la tecla del signo de interrogación para obtener los atajos de teclado para cambiar las fechas.',
    'MursionPortal.AriaDescribedby.EndDate' : 'Fecha de finalización: Pulse la tecla del signo de interrogación para obtener los atajos de teclado para cambiar las fechas.',
    'MursionPortal.VisibilityHidden.Footer' : 'Pie de página',
    'MursionPortal.VisibilityHidden.Header' : 'Encabezado',
    'MursionPortal.AriaLabel.FullScreen' : 'pantalla completa',
    'MursionPortal.AriaLabel.ClientNameEventsDisplayed' : 'Eventos del cliente {clientName} mostrados en el calendario',
    'MursionPortal.AriaLabel.SideNavigationToggle' : 'Palanca de navegación lateral',
    'MursionPortal.AriaLabel.AddTags' : 'Añadir etiquetas',
    'MursionPortal.VisibilityHidden.Navigation' : 'Navegación',
    'MursionPortal.AriaLabel.SortByDescendingOrderButton' : 'botón de clasificar por orden descendente',
    'MursionPortal.AriaLabel.SortByAscendingOrderButton' : 'botón de clasificar por orden ascendente',
    'MursionPortal.VisibilityHidden.ProjectsTabSelected' : 'Pestaña de proyectos seleccionada',
    'MursionPortal.VisibilityHidden.TeamsTabSelected' : 'Pestaña de equipos seleccionada',
    'MursionPortal.VisibilityHidden.SessionsTabSelected' : 'Pestaña de sesiones seleccionada',
    'MursionPortal.VisibilityHidden.UsersTabSelected' : 'Pestaña de usuarios seleccionada',
    'MursionPortal.Error.PageNotFound' : '404 Página no encontrada',
    'MursionPortal.Text.PageNotFound' : 'Página no encontrada. Si el enlace es correcto, compruebe si tiene permiso para ver la página.',
    'MursionPortal.Text.Success' : '¡Conseguido!',
    'MursionPortal.Text.YouAreNowSignedIn' : 'Ha iniciado sesión en el software de simulación de Mursion.',
    'MursionPortal.Text.PleaseReturnToDesktop' : 'Por favor, vuelva a la aplicación de escritorio haciendo clic en {linebreak} en el icono \'M\' en la barra de tareas de su ordenador (Windows) o en el Dock (Mac).',
    'MursionPortal.Client.EmailSettings.Header.DemandBased' : 'Basado en la demanda',
    'MursionPortal.Client.EmailSettings.TimeSlotsReceived' : 'Franjas horarias recibidas',
    'MursionPortal.Label.Past' : 'Pasado',
    'MursionPortal.Monitoring.SessionsSummary.Preparation' : 'Preparación',
    'MursionPortal.Monitoring.SessionsSummary.Normal' : 'Normal',
    'MursionPortal.Monitoring.SessionsSummary.Error' : 'Error',
    'MursionPortal.Monitoring.SessionsSummary.Issue' : 'Cuestión',
    'MursionPortal.Monitoring.SessionsSummary.NotLoggedIn' : 'No se ha iniciado sesión',
    'MursionPortal.Monitoring.Legend.Label' : 'Leyenda',
    'MursionPortal.Monitoring.Legend.Dashboard' : 'Tablero',
    'MursionPortal.Label.SimSpecialist' : 'Especialista de simulación',
    'MursionPortal.Monitoring.SessionsSummary.ConnectedToPeers' : 'Conectado a compañeros',
    'Requests.Table.CertifiedScenarios' : 'Escenarios certificados',
    'Requests.Table.UncertifiedScenarios' : 'Escenarios no certificados',
    'Requests.Table.NoUpComingDemands' : 'No tiene solicitudes basadas en la demanda con los criterios anteriores.',
    'Requests.Table.DeclineReason' : 'Rechazar - Seleccionar motivo',
    'Requests.Table.UnAvailable' : 'No disponible',
    'Requests.Table.NotPrepared' : 'No está preparado',
    'Requests.Table.Other' : 'Otros',
    'Requests.Table.Availability' : 'Seleccionar la disponibilidad',
    'Requests.Table.Declined' : 'Rechazada',
    'Requests.Table.SuccessfullySent' : 'Solicitud enviada con éxito',
    'Requests.Table.RequestCertificate' : 'Solicitar certificado',
    'Requests.Table.DeclineReasonText' : 'Motivo de rechazo',
    'Requests.Table.AvailabilitySlot' : 'Franja de disponibilidad',
    'Requests.Table.DemandBased' : 'Basado en la demanda',
    'MursionPortal.Setting.Integrations' : 'Integración',
    'MursionPortal.Setting.Integrations.Title' : 'Por favor, seleccione una integración',
    'MursionPortal.Setting.Integrations.Header.PlatformConfigurations' : 'Configuraciones de plataforma',
    'MursionPortal.Button.AddConfigurations' : 'Añadir configuraciones',
    'Settings.LTI.PlaceHolder.EnterConfigName' : 'Introduzca el nombre de la configuración',
    'Settings.LTI.Table.ColumnHeader.DeploymentID' : 'ID de despliegue',
    'Settings.LTI.Table.ColumnHeader.Version' : 'Versión',
    'Settings.LTI.Table.Caption' : 'lista de configuración lti',
    'Settings.LTI.Table.Hover.ViewEditLti' : 'Ver/Editar Lti',
    'Settings.LTI.Table.NoLtiConfigFound' : 'No se ha encontrado ninguna configuración de LTI',
    'MursionPortal.EditLtiModal.Button.DeleteLti' : 'Eliminar configuración',
    'MursionPortal.EditLtiModal.Button.SaveLtiConfig' : 'Guardar configuración',
    'MursionPortal.Lti.NewLTIConfig' : 'Nueva configuración de LTI',
    'MursionPortal.Lti.CreateLTIConfig' : 'Crear configuración de LTI',
    'Settings.LTI.Modal.PlatformIssuerIdentifier' : 'Identificador del emisor de plataforma',
    'Settings.LTI.Modal.PublicKeysetEndpoint' : 'Punto final del conjunto de claves públicas',
    'Settings.LTI.Modal.DeploymentId' : 'ID de despliegue',
    'Settings.LTI.Modal.RolesMapping' : 'Asignación de roles',
    'Settings.LTI.Modal.RolesMapping.PortalRole' : 'Rol de portal',
    'Settings.LTI.Modal.RolesMapping.LTIRole' : 'Rol LTI',
    'MursionPortal.ErrorMessage.LtiIdMustBeSpecified' : 'Se debe especificar el Id. de LTI.',
    'MursionPortal.Setting.Integrations.LTIVersion' : 'Versión LTI',
    'MursionPortal.Dialog.CreateLTIConfiguration.SuccessMessage' : 'La configuración de LTI se ha creado con éxito.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReserved' : 'Franja horaria no disponible - reservado para otra simulación programada.',
    'Requests.Table.ViewAvailability' : 'Ver disponibilidad',
    'Settings.LTI.Modal.DeletionConfirmationText' : '¿Está seguro de que quiere eliminar esta asignación de roles?',
    'Settings.ScenarioBank.Modal.InactiveCertification' : 'Certificación inactiva',
    'Settings.ScenarioBank.Modal.NoInactiveSimSpecialist' : 'Sin especialista de Simulación inactivo',
    'Settings.ScenarioBank.Modal.EditInactiveCertification' : 'Editar certificación inactiva',
    'Settings.ScenarioBank.Modal.NoInactiveCertification' : 'Sin certificación inactiva',
    'MursionPortal.RequestStatus.All' : 'Estatus de la solicitud: Todos',
    'MursionPortal.RequestStatus.Accepted' : 'Estatus de la solicitud: Aceptada',
    'MursionPortal.RequestStatus.Declined' : 'Estatus de la solicitud: Rechazada',
    'MursionPortal.RequestStatus.Pending' : 'Estatus de la solicitud: Pendiente',
    'Requests.Table.RequestCertification' : 'Solicitar certificación',
    'Requests.Table.RequestTime' : 'Solicitar hora',
    'Requests.Table.ScheduleType' : 'Tipo de programación',
    'Requests.Table.AcceptTime' : 'Aceptar la hora',
    'Requests.Table.Scheduling' : 'Programación',
    'Requests.Table.FulfilledIn' : 'Cumplimentada',
    'MursionPortal.DemandBasedScheduling.Timezone' : 'Zona horaria',
    'MursionPortal.DemandBasedScheduling.Timezone.Tooltip' : 'Vaya a los ajustes de perfil para cambiar la zona horaria.',
    'MursionPortal.Results.Found' : '{count} resultados encontrados',
    'MursionPortal.Header.VisibilityHidden.ClientUsers' : 'Usuarios del cliente',
    'MursonPortal.SchedulingType.Auto' : 'AUTOMÁTICO',
    'MursonPortal.SchedulingType.Manual' : 'MANUAL',
    'MursionPortal.ScheduleTypeSelector.All' : 'Horario: Todos',
    'MursionPortal.ScheduleTypeSelector.Auto' : 'Programación: Automática',
    'MursionPortal.ScheduleTypeSelector.Manual' : 'Programación: Manual',
    'MursionPortal.ScheduleTypeSelector.NA' : 'Programación: N.D.',
    'Integrations.LTI.AppId' : 'Id de aplicación',
    'Integrations.LTI.ConfigurationId' : 'Id de configuración',
    'Settings.LTI.ModalHeader.EditLtiConfiguration' : 'Editar la configuración de LTI',
    'Settings.LTI.Modal.DeletionConfigConfirmationText' : '¿Está seguro de que quiere eliminar esta configuración de LTI?',
    'MursionPortal.Rescheduling.SubmitPage.Message' : 'En un momento le enviaremos un correo electrónico con más detalles. ¡Hasta pronto!',
    'MursionPortal.Rescheduling.RescheduleSimulation' : 'Reprogramar simulación',
    'MursionPortal.Rescheduling.SlotSelectionMessage' : 'Sentimos que no pueda realizar su simulación. ¡Escoja un día y hora que le sirvan a usted!',
    'MursionPortal.IVSBrowserWarning.Safari17.Message1': 'The browser version you are currently using is not supported for your Mursion session',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Message2': 'When joining your Mursion simulation, use one of these browsers:',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Firefox': "Firefox",  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome': "Chrome", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome.Subtitle': "Recommended", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Edge': "Edge", // TODO: add translations
    'MursionPortal.Result.Found' : '{count} resultado encontrado',
    'MursionPortal.Project.Rescheduling' : 'Reprogramación',
    'Session.Edit.Modal.MissedSession' : 'SESIÓN PERDIDA',
    'Session.Edit.Modal.NoLongerRequired' : 'ya no se necesita',
    'Session.Edit.Modal.TechDifficulties' : 'estamos teniendo dificultades técnicas',
    'Session.Edit.Modal.Other' : 'otros',
    'Session.Edit.Modal.CancelBecause' : 'Si, cancelar la sesión porque',
    'Session.Edit.Modal.CancelSimulation' : 'Cancelar la simuación',
    'MursionPortal.UserConsent.Heading' : 'Permisos de grabación',
    'MursionPortal.UserConsent.Title' : '¿Quiere una grabación de esta sesión de simulación para su propia trayectoria de aprendizaje?',
    'MursionPortal.UserConsent.Description' : 'Si usted indica "Consiento", el software de Mursion procesará su simulación en vivo y hará una grabación de vídeo de su sesión de simulación para su revisión en el portal del software de Mursion. Tenga en cuenta que a petición del cliente que ha adquirido la licencia del software Mursion en su nombre, se compartirá una copia de su(s) sesión(es) de simulación grabada(s) con un entrenador, mentor o revisor seleccionado por el cliente. Todos los revisores están obligados por contrato a seguir la <code>política de privacidad de Mursion</code>, a menos que usted haya aceptado condiciones de privacidad diferentes. Salvo que lo exija la legislación aplicable, Mursion no distribuirá ni divulgará su grabación de vídeo sin su permiso. Mursion utilizará los datos anónimos de su uso del software para mejorar su tecnología de simulación.',
    'MursionPortal.UserConsent.IConsent' : 'Consiento',
    'MursionPortal.UserConsent.IDoNotConsent' : 'No consiento',
    'MursionPortal.RecordingLinkPermisssion.Title' : 'Permisos de grabación',
    'MursionPortal.RecordingLinkPermisssion.DescriptionOne' : '¿Quiere que le enviemos una grabación de esta sesión?',
    'MursionPortal.RecordingLinkPermisssion.DescriptionTwo' : 'Si es así, compruebe la bandeja de entrada de su correo electrónico y verifique su dirección de correo electrónico. Mañana se le enviará un enlace si está disponible.',
    'MursionPortal.PublicSession.PageTitle' : 'Introduzca sus detalles para unirse a la simulación',
    'MursionPortal.PublicSession.Form.label.FirstName' : 'Nombre propio',
    'MursionPortal.PublicSession.Form.label.LastName' : 'Apellido',
    'MursionPortal.PublicSession.Form.label.Email' : 'Correo electrónico (Opcional)',
    'PublicSession.Form.Button.Join' : 'Unirse',
    'MursionPortal.PublicSession.Form.Placeholder.FirstName' : 'Su nombre propio',
    'MursionPortal.PublicSession.Form.Placeholder.LastName' : 'Su apellido',
    'MursionPortal.PublicSession.Form.Placeholder.Email' : 'name@provider.com',
    'MursionPortal.PublicSession.Form.Placeholder.HaveAccount' : '¿Ya tiene una cuenta?',
    'MursionPortal.PublicSession.Form.Placeholder.LogInHere' : 'Iniciar sesión aquí',
    'Session.Edit.Modal.ToolTip.Google' : 'Añadir evento al calendario de Google. Confirmar la autorización en caso necesario',
    'Session.Edit.Modal.ToolTip.OutlookIcalOther' : 'Haga clic en descargar evento a su escritorio y añádalo a su aplicación de calendario',
    'Session.Edit.Modal.SimSpecialistAssigned' : 'Especialista de simulación asignado',
    'Session.Edit.Modal.SessionSimSpecialistField.AddSIMSpecialist' : 'Añadir especialista de SIM',
    'Session.Edit.Modal.DemandBasedTimeSlots' : 'Franjas horarias basadas en la demanda',
    'Session.Edit.Modal.Reschedule' : 'Reprogramar',
    'Session.Edit.Modal.AddLearner' : 'Añadir alumno',
    'MursionPortal.Label.SearchOrSelectLearners' : 'Buscar o seleccionar alumno(s)',
    'MursionPortal.Client.EmailSettings.Header.Rescheduling' : 'Reprogramación',
    'MursionPortal.Client.EmailSettings.RescheduleMissedSession' : 'Reprogramar sesión perdida',
    'Session.Edit.Modal.OtherPrioritiesAtWork' : 'otras prioridades en el trabajo',
    'MursionPortal.Modal.Header.Sorry' : '¡Perdón!',
    'MursionPortal.Modal.UnschedulableSessionMsg' : 'La sesión es desprogramable.',
    'MursionPortal.Rescheduling.Slot.NoLongerAvailable' : 'La hora seleccionada ya no está disponible, seleccione una opción diferente.',
    'MursionPortal.EmailVerificationPage.Success' : '¡Verificación de correo electrónico exitosa!',
    'MursionPortal.EmailVerificationPage.SuccessDesc' : 'Su correo electrónico ha sido verificado con éxito',
    'MursionPortal.EmailVerificationPage.Error' : '¡Verificación de correo electrónico fallida!',
    'MursionPortal.EmailVerificationPage.ErrorDesc' : 'Algo ha ido mal. Por favor, intente de nuevo verificar su correo electrónico',
    'Settings.Config.Heading.SplashPageAttendance' : 'ASISTENCIA',
    // 'Clients.Modal.Label.ShowSplashPage' : 'Asistencia de usuarios sin registrar/Consentimiento de grabación',
    // 'Clients.Modal.Label.ShowSplashPage.ToolTip' : 'Obtener la asistencia y el consentimiento de grabación para los usuarios no registrados en las simulaciones de ML3z/Meet.',
    // 'Settings.Config.Invitation.ShowSplashPage' : 'Obtener la asistencia y el consentimiento de grabación para los usuarios no registrados en las simulaciones de ML3z/Meet.',
    'Settings.Config.External.Users' : '(Se puede inhabilitar en la configuración del cliente)',
    'Settings.Config.Invitation.ExternalUser' : 'Para asistencia de usuarios externos',
    'MursionPortal.Scenario.Vignette.ButtonText.ShowMore.SessionDetails' : 'detalles de sesión',
    'MursionPortal.DemandBasedScheduling.ProjectDropdown.Placeholder' : 'Seleccionar un proyecto',
    'MursionPortal.DemandBasedScheduling.ScenarioDropdown.Placeholder' : 'Seleccionar un escenario',
    'Session.Edit.Modal.Label.SearchOrSelectASimSpecialist' : 'Buscar o seleccionar un Especialista de SIM',
    'MursionPortal.Button.Exclamation.GotIt' : '¡Conseguido!',
    'MursionPortal.Modal.UnavailableSlotMsg' : 'La hora que ha seleccionado no está disponible ahora. Pille una nueva franja horaria.',
    'MursionPortal.SimAttendance.NoLongerAvailable' : 'Ya no puede editar la asistencia a esta simulación.',
    'MursionPortal.SimAttendance.Midnight' : 'Debe completar la asistencia cada día antes de media noche. La asistencia para esta simulación tendrá como valor predeterminado los estatus del sistema. Contacte con Soporte si ocurre algo anómalo.',
    'MursionPortal.SimAttendance.Instructions' : 'Instrucciones',
    'MursionPortal.SimAttendance.Attendee' : 'Asistente',
    'MursionPortal.SimAttendance.Tooltip.Missed' : 'El alumno nunca se ha conectado a la simulación.',
    'MursionPortal.SimAttendance.Tooltip.Declined' : 'El alumno se conectó a la simulación pero rechazó participar, y se salió antes de completar al menos el 50% de la simulación.',
    'MursionPortal.SimAttendance.Add.Attendee' : 'Añadir asistente',
    'Session.Edit.Modal.EditAttendance' : 'Editar asistencia',
    'Session.Edit.Modal.CompleteAttendance' : 'Completar asistencia',
    'MursionPortal.SimAttendance.Late' : 'Tarde',
    'MursionPortal.SimAttendance.Left' : 'Abandonada',
    'MursionPortal.Instructions.First' : 'Seleccionar un estatus de asistencia para cada asistente (ratón sobre el estatus para saber más), Si el alumno no está en la lista, haga clic en',
    'MursionPortal.Instructions.Second' : 'la función de la parte inferior izquierda.',
    'MursionPortal.TableColumn.SimsAction' : 'Acción de Sims',
    'Session.Edit.Modal.Label.SelectTrainer' : 'Buscar o seleccionar un entrenador',
    'MursionPortal.Project.DemandSlots' : 'Franjas de demanda',
    'MursionPortal.CompanyCard.InviteOperations' : 'Invitar operaciones',
    'MursionPortal.CompanyCard.Modal.Header.NewOperations' : 'Nuevas operaciones',
    'Settings.Config.Invitation.ShowContractFeature' : 'Permitir añadir Contratos',
    'Settings.Config.Contract.Description' : '(para seguimiento de progreso de contrato)',
    'MursionPortal.Contract.Edit' : 'Editar',
    'MursionPortal.Contract.ContractName' : 'Nombre de Contrato',
    'MursionPortal.Contract.ContractID' : 'ID de Contrato',
    'MursionPortal.Contract.ContractID.PlaceHolder' : 'Introducir un ID de Contrato del Hubspot',
    'MursionPortal.Contract.StartAndEndDates' : 'Fechas de inicio y finalización de Contrato',
    'MursionPortal.Contract.Notes.PlaceHolder' : 'Introducir otra información para mostrar únicamente a los CSM y BDD.',
    'MursionPortal.Contract.LineItem.Add' : 'Añadir una partida del Contrato',
    'MursionPortal.Contract.LineItem.Add.Instruction' : 'Puede añadir varias partidas de la simulación. Todos los demás tipos únicamente pueden tener una partida.',
    'MursionPortal.Contract.LineItemType' : 'Tipo de partida',
    'MursionPortal.Contract.LineItemType.PlaceHolder' : 'Seleccionar el tipo de partida',
    'MursionPortal.Contract.Quantity' : 'Cantidad',
    'MursionPortal.Contract.Quantity.PlaceHolder' : 'Introducir cantidad',
    'MursionPortal.Contract.DeliveryMode.PlaceHolder' : 'Seleccionar modo de entrega',
    'MursionPortal.Contract.SaveContract' : 'Guardar detalles del Contrato',
    'MursionPortal.Contract.Length.PlaceHolder' : 'Seleccionar longitud',
    'MursionPortal.Contract.SowName.PlaceHolder' : 'Introducir nombre del Contrato',
    'MursionPortal.Client.Tab.Contracts' : 'Contratos',
    'MursionPortal.Client.Contracts' : 'Contratos de cliente',
    'MursionPortal.Client.Create.Contracts' : 'Crear Contrato',
    'MursionPortal.Client.Contract.ID' : 'ID de Contrato',
    'MursionPortal.NewUser.Optional' : ' (opcional)',
    'MursionPortal.NewUser.SuchAGoogleAccountOrOtherSsoProvider.TooltipText' : 'Tal como una cuenta de Google u otro proveedor de SSO',
    'MursionPortal.UserConsentDeclineConfirmation.Heading' : 'Confirme su elección.',
    // 'MursionPortal.UserConsentDeclineConfirmation.Title' : '¿Está seguro? Para continuar sin grabar, haga clic en el botón Confirmar. Para cambiar su consentimiento de grabación, haga clic en el botón Cancelar.',
    'Settings.Config.Invitation.ShowSowFeature' : 'Permitir que se añadan SOWs',
    'Settings.Config.SOW.Description' : '(para seguimiento de progreso de SOW)',
    'MursionPortal.ScenarioBank.StandardizedScenario' : 'Escenario estandarizado',
    'MursionPortal.ScenarioBank.ShowOnlyBundleScenario' : 'Mostrar únicamente escenarios estandarizados',
    'Settings.Config.Invitation.SimWorkforceManagement' : 'Gestión de fuerza laboral de SIM',
    'Settings.Config.SimWorkforceManagement.Description' : 'Habilitado significa que el informe está disponible en la página del tablero, e inhabilitado significa que el informe no está en absoluto.',
    'MursionPortal.ScenarioBank.BundleScenario' : 'Empaquetar escenarios',
    'MursionPortal.Scheduling.SimAvailable.TimeSlotsTooltipMsg' : '¡Seleccionar esta franja horaria para confirmar automáticamente su reserva de escenario!',
    'Dashboard.LeftPane.CategoryHeader.SupplyManagement' : 'Gestión de fuerza laboral de SIM',
    'Dashboard.LeftPane.SupplyManagement.Scheduling' : 'Programación',
    'Dashboard.SupplyManagement.Scheduling.MainHeader' : 'Programación',
    'Dashboard.SupplyManagement.Scheduling.TableHeader' : 'Datos de programación',
    'Dashboard.LeftPane.SupplyManagement.ClientRoster' : 'Listado de clientes',
    'Dashboard.SupplyManagement.ClientRoster.MainHeader' : 'Listado de clientes',
    'Dashboard.SupplyManagement.ClientRoster.TableHeader' : 'Datos de listado de clientes',
    'MursionPortal.Table.ColumnHeader.Time' : 'Tiempo',
    'MursionPortal.Table.ColumnHeader.AllScheduledSessions' : 'Todas programadas (Sesiones)',
    'MursionPortal.Table.ColumnHeader.SimsAvailable' : 'SIMs disponibles (Personas)',
    'MursionPortal.Table.ColumnHeader.AvailabilityHours' : 'Disponibilidad (Horas)',
    'MursionPortal.Table.ColumnHeader.DemandBasedRequests' : 'Todas las solicitudes basadas en la demanda (Total = 3 solicitudes)',
    'MursionPortal.Table.ColumnHeader.SlotOneRequests' : 'Solicitudes de la franja 1',
    'MursionPortal.Table.ColumnHeader.SlotTwoRequests' : 'Solicitudes de la franja 2',
    'MursionPortal.Table.ColumnHeader.SlotThreeRequests' : 'Solicitudes de la franja 3',
    'MursionPortal.Table.ColumnHeader.PendingRequests' : 'Solicitudes pendientes (Sesiones)',
    'MursionPortal.Table.ColumnHeader.Auto' : 'Automático',
    'MursionPortal.Table.ColumnHeader.Manual' : 'Manual',
    'MursionPortal.Table.ColumnHeader.CapacityIndicator' : 'Indicador de capacidad',
    'MursionPortal.EmptyMessage.NoSupplyManagementDataYet' : 'Todavía no hay datos de gestión de fuerza laboral de simulación',
    'MursionPortal.Table.ColumnHeader.SimCertified' : 'SIMs certificadas',
    'MursionPortal.Table.ColumnHeader.ScheduledHours' : 'Programado (Horas)',
    'MursionPortal.Table.ColumnHeader.Sessions' : 'Sesiones',
    'MursionPortal.Table.ColumnHeader.AvgFullfilled' : 'Cumplimiento promedio en',
    'MursionPortal.Table.ColumnHeader.SlotOneFullFillment' : 'Cumplimiento de franja 1',
    'MursionPortal.Table.ColumnHeader.Cancelled' : 'Cancelado',
    'MursionPortal.Table.ColumnHeader.Missed' : 'Perdido',
    'MursionPortal.Table.ColumnHeader.Reschedules' : 'Reprogramaciones',
    'MursionPortal.Table.ColumnHeader.Error' : 'Error',
    'MursionPortal.Table.ColumnHeader.Completed' : 'Completado',
    'MursionPortal.Table.ColumnHeader.Other' : 'Otros',
    'MursionPortal.Table.ColumnHeader.SchedulingWindow' : 'Ventana de reprogramación',
    'MursionPortal.Table.ColumnHeader.RequestDemand' : 'n.º de solicitud (demanda)',
    'MursionPortal.Table.ColumnHeader.AutoFulfilled' : 'Cumplimentada automáticamente',
    'MursionPortal.Table.ColumnHeader.Pending' : 'Pendiente',
    'MursionPortal.Table.ColumnHeader.SlotTwoFullFillment' : 'Cumplimiento de franja 2',
    'MursionPortal.Table.ColumnHeader.SlotThreeFullFillment' : 'Cumplimiento de franja 3',
    'MursionPortal.Table.ColumnHeader.UnutilizedAvailability' : 'Disponibilidad no utilizada (Horas)',
    'MursionPortal.Table.ColumnHeader.TotalAvailabilityEntered' : 'Disponibilidad total introducida',
    'MursionPortal.Table.ColumnHeader.AverageHoursWeek' : 'Promedio de horas/semana',
    'MursionPortal.Table.ColumnHeader.AvailabilityUtilization' : 'Utilización de disponibilidad',
    'MursionPortal.SupplyManagement.Above' : 'Superior',
    'MursionPortal.SupplyManagement.Below' : 'Inferior',
    'MursionPortal.SupplyManagement.At' : 'En',
    'MyProfile.ProfServiceRole.Tooltiptext' : 'Usuarios con nuestro mayor nivel de acceso',
    'MyProfile.AccManager.Tooltiptext' : 'Crea escenarios, equipos, añade usuario y asigna equipos a simulaciones',
    'MyProfile.SimSpecialist.Tooltiptext' : 'También conocido como "Sim", este rol realiza simulaciones para los alumnos',
    'MyProfile.Buyer.Tooltiptext' : 'Un usuario que gestiona un proyecto de Mursion para su organización',
    'MyProfile.Facilitator.Tooltiptext' : 'Participante de una simulación que ayuda en la entrega de la simulación',
    'MyProfile.Learner.Tooltiptext' : 'Participante de simulación',
    'MyProfile.Operations.Tooltiptext' : 'Empleado de Mursion que tiene la capacidad de añadir y editar contratos',
    'MyProfile.SimDesigner.Tooltiptext' : 'Usuario que tiene la capacidad de crear y editar escenarios de banco',
    'MursionPortal.Label.AssignedUserRoles' : 'Roles de usuario asignados',
    'MursionPortal.Label.AssignedUserRole' : 'Rol de usuario asignado',
    'Clients.Modal.Projects.Button.CreateFromBank' : 'Crear a partir del banco',
    'MursionPortal.Legend.VisibilityHidden.ScenarioPanel.Buttons' : 'Botones para crear nuevo, repetir cliente, crear a partir de escenario de banco',
    'MursionPortal.Label.ArtBundle' : 'Paquete artístico',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContentMsg' : 'Este es el contenido artístico 3D de entornos y avatares que se usarán en la simulación. El contenido de esta carpeta se muestra en la sección titulada "paquete artístico" a continuación.',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoArtBundles' : 'El escenario no tiene paquetes artísticos asignados.',
    'MursionPortal.Label.ArtProject' : 'Proyecto artístico',
    'MursionPortal.Label.UpperCase.ArtProject' : 'PROYECTO ARTÍSTICO',
    'Settings.Config.SimulationContent.ArtProjectFolder' : 'Carpeta de proyecto artístico S3',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoArtProject' : 'El escenario no tiene proyecto artístico asignado',
    'MursionPortal.SessionWizard.Label.SelectArtContentForUse' : 'Seleccionar proyecto artístico para usar',
    'Projects.Message.NoArtProjectFound' : 'No se ha encontrado proyecto artístico',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenarioBank' : 'Hay escenarios subordinados activos creados a partir de este escenario de banco. Debe archivar cada uno de ellos individualmente.',
    'Settings.StandardizedScenarioBank.EditConfirmation' : '¿Está seguro de que quiere actualizar todos los escenarios existentes creados a partir de este?',
    'Settings.StandardizedScenarioBank.AttachmentsEditConfirmation' : 'La actualización de adjuntos cambiará todos los escenarios existentes creados a partir de este.',
    'MursionPortal.Contract.Ending' : 'Finalizando',
    'MursionPortal.ProjectContracts.DuplicateError' : 'El Contrato que ha seleccionado prevalece sobre otro Contrato añadido a este proyecto. Seleccione otro o contacte con operaciones para solicitar una orden de cambio',
    'MursionPortal.ProjectContracts.DateErrorHeading' : 'No hay un Contrato para estas fechas de proyecto',
    'MursionPortal.ProjectContracts.DateError' : 'No hay Contrato que soporte estas fechas de inicio y finalización de proyecto. No se deben crear proyectos sin un contrato válido. ¿Desea proceder?',
    'MursionPortal.Contract.ViewDetails' : 'Ver detalles',
    'MursionPortal.Contract.PleaseAddContractToProject' : 'Añada un contrato al proyecto',
    'MursionPortal.Contract.AddContractToProject' : 'Añadir Contrato al proyecto',
    'MursionPortal.Contract.SelectContractToProject' : 'Seleccionar el Contrato que desee añadir a este proyecto - Añada uno cada vez',
    'MursionPortal.Contract.AddSelectedContract' : 'Añadir el Contrato selecionado',
    'MursionPortal.Contract.AddContract' : 'Añadir Contrato',
    'MursionPortal.Contract.LineItemList' : 'Lista de partidas',
    'MursionPortal.DemandBasedScheduling.InstantBooking' : 'Reserva instantánea',
    'MursionPortal.DemandBasedScheduling.AllSlots' : 'Presentar franjas',
    'MursionPortal.DemandBasedScheduling.ScheduleYourScenario' : 'Programaremos su escenario en una de las franjas horarias que haya elegido.',
    'MursionPortal.DemandBasedScheduling.SelectOneOfTheseSlotsForAnInstantBooking' : 'Seleccione una de estas franjas para una reserva instantánea.',
    'MursionPortal.DemandBasedScheduling.TheseSlotDoNotwork' : 'Si estas franjas no sirven, seleccione "Presentar Franjas" para presentar momentos que le sirvan a usted.',
    'MursionPortal.AltText.CrossButton' : 'imagen de botón de cruz',
    'MursionPortal.Title.BankScenario' : 'Escenario de banco',
    'Dashboard.LeftPane.SupplyManagement.SimRoster' : 'Listado de simulaciones',
    'Dashboard.SupplyManagement.SimRoster.MainHeader' : 'Listado de simulaciones',
    'Dashboard.SupplyManagement.SimRoster.TableHeader' : 'Datos de listado de Sim',
    'MursionPortal.Table.ColumnHeader.SimFullName' : 'Nombre completo de SIM',
    'MursionPortal.Table.ColumnHeader.LanguagesDelivered' : 'Idiomas entregados (certificados en idioma)',
    'MursionPortal.Table.ColumnHeader.Tier' : 'Nivel',
    'MursionPortal.Table.ColumnHeader.Timezone' : 'Zona horaria',
    'MursionPortal.Table.ColumnHeader.Scheduled' : 'Programado',
    'MursionPortal.Table.ColumnHeader.ProjectsHoursWeekTotal' : 'Total de horas proyectadas/semana',
    'MursionPortal.Table.ColumnHeader.ActiveClientCertifications' : 'Certificaciones de cliente activo (facturable)',
    'MursionPortal.Table.ColumnHeader.InactiveCertifications' : 'Certificación inactiva',
    'MursionPortal.Table.ColumnHeader.Declined' : 'Rechazada',
    'MursionPortal.Table.ColumnHeader.AvgFullfillTimeForManual' : 'Tiempo de cumplimiento promedio para manual',
    'MursionPortal.Table.ColumnHeader.Queue' : 'Cola',
    'MursionPortal.Table.ColumnHeader.Availability' : 'Disponibilidad',
    'MursionPortal.Filters.Tier' : 'Nivel',
    'MursionPortal.Filters.PlaceHolder.SearchTier' : 'Buscar nivel',
    'MursionPortal.TierOptions.Tier1' : 'Nivel 1',
    'MursionPortal.TierOptions.Tier2' : 'Nivel 2',
    'MursionPortal.TierOptions.Tier3' : 'Nivel 3',
    'MursionPortal.TierOptions.Tier4' : 'Nivel 4',
    'MursionPortal.TierOptions.LeadSimulation' : 'Especialista de simulación jefe',
    'MursionPortal.Contract.BackToCLient' : 'Volver al cliente',
    'MursionPortal.DeliveryMode.Workshop' : 'Taller',
    'MursionPortal.DeliveryMode.PrivatePractice' : 'Práctica privada',
    'MursionPortal.DemandBasedScheduling.SimOnlySlotSelectionMessage' : 'Elija un día y una franja horaria que le sirva a usted.',
    'Dashboard.LeftPane.CategoryHeader.Performance' : 'UTILIZACIÓN',
    'Dashboard.LeftPane.Performance.ContractOverview' : 'Descripción general',
    'Dashboard.LeftPane.Performance.ContractSummaries' : 'Resúmenes de contratos',
    'Dashboard.LeftPane.Performance.ContractProgress' : 'Progreso de contrato',
    'Dashboard.LeftPane.CategoryHeader.Learners' : 'ALUMNOS',
    'Dashboard.LeftPane.Learners.LearnerActivity' : 'Actividad de alumno',
    'Dashboard.LeftPane.Learners.LearnerSimulations' : 'Simulaciones de alumno',
    'Dashboard.Total' : 'Entradas totales',
    'Filters.SimulationStatus' : 'Estatus de simulación',
    'Filters.SimulationStatusPlaceholder' : 'Buscar estatus de simulación...',
    'Filters.LearnerStatus' : 'Estatus de alumno',
    'Filters.LearnerStatusPlaceHolderHint' : 'Buscar estatus de alumno...',
    'Filters.SimulationRecorded' : 'Simulación grabada',
    'Filters.Standardized' : 'Estandarizado',
    'Filters.Boolean.Yes' : 'Sí',
    'Filters.Boolean.No' : 'No',
    'Dashboard.UserTable.Column.Roles' : 'Rol',
    'Dashboard.ContractSummaries.MainHeader' : 'Resúmenes de contratos',
    'Dashboard.ContractOverview.MainHeader' : 'Descripción general',
    'Dashboard.ContractProgress.MainHeader' : 'Progreso de contrato',
    'Dashboard.ContractProgress.Navigation.ContractItems' : 'Artículos del contrato',
    'Dashboard.ContractProgress.Navigation.Projects' : 'Proyectos',
    'Dashboard.ContractProgress.Navigation.Scenarios' : 'Escenarios',
    'Dashboard.ContractProgress.Column.Item' : 'Artículo',
    'Dashboard.ContractProgress.Column.Purchased' : 'Adquirido',
    'Dashboard.ContractProgress.Column.Remaining' : 'Restante',
    'Dashboard.ContractProgress.Column.Completed' : 'Completado',
    'Dashboard.ContractProgress.Column.Scheduled' : 'Programado',
    'Dashboard.ContractProgress.Column.LateCancellations' : 'Cancelaciones tardías',
    'Dashboard.ContractProgress.Column.ProjectName' : 'Nombre de proyecto',
    'Dashboard.ContractProgress.Column.StartDate' : 'Fecha de inicio',
    'Dashboard.ContractProgress.Column.EndDate' : 'Fecha de finalización',
    'Dashboard.ContractProgress.Column.Error' : 'Error',
    'Dashboard.ContractProgress.Column.ScenarioName' : 'Nombre de escenario',
    'Dashboard.ContractProgress.Column.Standardized' : 'Estandarizado',
    'Dashboard.ContractProgress.Column.LearnersAssigned' : 'Alumnos asignados',
    'Dashboard.ContractProgress.Column.LearnersCompleted' : 'Alumnos que han completado',
    'Dashboard.ContractProgress.Column.LearnersCompletedPercent' : '% de alumnos que han completado',
    'Dashboard.ContractProgress.Column.Client' : 'Cliente',
    'Dashboard.ContractProgress.Column.ContractName' : 'Nombre de Contrato',
    'Dashboard.ContractProgress.Column.ContractId' : 'ID de Contrato',
    'Dashboard.ContractProgress.Column.ContractNameHoverTitle' : 'ID de copia',
    // 'Dashboard.ContractProgress.Column.PercentComplete' : '% completo',
    'Dashboard.ContractProgress.Column.ErrorLearner' : 'Error (Alumno)',
    'Dashboard.ContractProgress.Column.LearnersInvited' : 'Alumnos invitados',
    'Dashboard.ContractProgress.Column.LearnersScheduledPercent' : '% de alumnos programados',
    'Dashboard.ContractProgress.ColumnTooltip.Client' : 'Nombre del cliente',
    'Dashboard.ContractProgress.ColumnTooltip.ContractName' : 'Nombre del Contrato',
    'Dashboard.ContractProgress.ColumnTooltip.PercentComplete' : 'Porcentaje de días completados del contrato',
    'Dashboard.ContractProgress.ColumnTooltip.CompletedUtilization' : 'Simulaciones completadas como porcentaje de simulaciones adquiridas',
    'Dashboard.ContractProgress.ColumnTooltip.ScheduledUtilization' : 'Simulaciones completadas y programadas como porcentaje de simulaciones adquiridas',
    'Dashboard.ContractProgress.ColumnTooltip.MissedLearner' : 'Simulaciones que se ha perdido el alumno.',
    'Dashboard.ContractProgress.ColumnTooltip.ErrorLearner' : 'Simulaciones que han finalizado con error en la conexión del alumno',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersInvited' : 'Alumnos que han sido invitados a programar una simulación',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersScheduledPercent' : 'Alumnos que han programado al menos una simulación como porcentaje de aquellos que han sido invitados a programar una simulación',
    'Dashboard.ContractProgress.ColumnTooltip.Item' : 'Artículos contratados en el Contrato',
    'Dashboard.ContractProgress.ColumnTooltip.Purchased' : 'Número total de simulaciones adquiridas como parte de un contrato',
    'Dashboard.ContractProgress.ColumnTooltip.Completed' : 'Simulaciones que se han completado con éxito',
    'Dashboard.ContractProgress.ColumnTooltip.Scheduled' : 'Próximas simulaciones que están programadas pero todavía no se han completado (incluyen aquellas en marcha)',
    'Dashboard.ContractProgress.ColumnTooltip.Missed' : 'Simulaciones que se ha perdido el alumno',
    'Dashboard.ContractProgress.ColumnTooltip.LateCancellations' : 'Simulaciones que fueron canceladas tarde (después del plazo de cancelación) por el alumno, el moderador o el propietario de la cuenta o simulaciones abandonadas o rechazadas por el alumno',
    'Dashboard.ContractProgress.ColumnTooltip.Error' : 'Simulaciones que han finalizado con error en la conexión del alumno',
    'Dashboard.ContractProgress.ColumnTooltip.ProjectName' : 'Nombre del proyecto',
    'Dashboard.ContractProgress.ColumnTooltip.StartDate' : 'Fecha de inicio de contrato como se indica en el Contrato.',
    'Dashboard.ContractProgress.ColumnTooltip.EndDate' : 'Fecha de finalización de contrato como se indica en el Contrato.',
    'Dashboard.ContractProgress.ColumnTooltip.ScenarioName' : 'Nombre del escenario',
    'Dashboard.ContractProgress.ColumnTooltip.Standardized' : 'Este es un escenario estandarizado',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersAssigned' : 'Número de alumnos a los que se ha asignado un escenario',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompleted' : 'Alumnos que han completado al menos una simulación como porcentaje de aquellos que han sido invitados a programar una simulación',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompletedPercent' : 'Porcentaje de alumnos distintos que han completado el escenario',
    'Dashboard.Learners.Column.Provider' : 'Proveedor',
    'Dashboard.Learners.Column.Client' : 'Cliente',
    'Dashboard.Learners.Column.LearnerName' : 'Nombre de alumno',
    'Dashboard.Learners.Column.DateInvited' : 'Fecha de invitación',
    'Dashboard.Learners.Column.LastLogin' : 'Último inicio de sesión',
    'Dashboard.Learners.Column.NoAssignedTeams' : 'N.º de equipos asignados',
    'Dashboard.Learners.Column.NoAssignedScenarios' : 'N.º de escenarios asignados',
    'Dashboard.Learners.Column.NoCompletedScenarios' : 'N.º de escenarios completados',
    'Dashboard.Learners.Column.Completed' : 'Completado',
    'Dashboard.Learners.Column.Scheduled' : 'Programado',
    'Dashboard.Learners.Column.LateCancelled' : 'Cancelación tardía',
    'Dashboard.Learners.Column.EndedInError' : 'Finalizado con error',
    'Dashboard.Learners.Column.ProjectName' : 'Nombre de proyecto',
    'Dashboard.Learners.Column.ScenarioName' : 'Nombre de escenario',
    'Dashboard.Learners.Column.ScheduledStartDate' : 'Fecha de inicio programada',
    'Dashboard.Learners.Column.ScheduledStartTime' : 'Hora de inicio programada',
    'Dashboard.Learners.Column.ScheduledEndTime' : 'Hora de finalización programada',
    'Dashboard.Learners.Column.SimulationStatus' : 'Estatus de simulación',
    'Dashboard.Learners.Column.LearnerStatus' : 'Estatus de alumno',
    'Dashboard.Learners.Column.LearnerTimeSpent' : 'Tiempo dedicado por el alumno',
    'Dashboard.Learners.Column.SimSpecialistName' : 'Nombre de especialista de simulación',
    'Dashboard.Learners.Column.SimSpecialistStatus' : 'Estatus de especialista de simulación',
    'Dashboard.Learners.Column.Report' : 'Informe',
    'Dashboard.Learners.ColumnTooltip.Provider' : 'Nombre de proveedor/Titular de licencia',
    'Dashboard.Learners.ColumnTooltip.Client' : 'Nombre del cliente',
    'Dashboard.Learners.ColumnTooltip.LearnerName' : 'Nombre y apellido del usuario',
    'Dashboard.Learners.ColumnTooltip.DateInvited' : 'Fecha en la que se invitó por primera vez a un alumno a programar un escenario',
    'Dashboard.Learners.ColumnTooltip.LastLogin' : 'Fecha en la que un usuario inció sesión por última vez en el portal',
    'Dashboard.Learners.ColumnTooltip.NoAssignedTeams' : 'Número de equipos que han sido asignados a un alumno',
    'Dashboard.Learners.ColumnTooltip.NoAssignedScenarios' : 'Número de escenarios distintos que han sido asignados a un alumno',
    'Dashboard.Learners.ColumnTooltip.NoCompletedScenarios' : 'Número de escenarios distintos que han sido completados por un alumno',
    'Dashboard.Learners.ColumnTooltip.Completed' : 'Simulaciones que se han completado con éxito',
    'Dashboard.Learners.ColumnTooltip.Scheduled' : 'Próximas simulaciones que están programadas pero todavía no se han completado (incluyen aquellas en marcha)',
    'Dashboard.Learners.ColumnTooltip.Missed' : 'Simulaciones que se ha perdido el alumno',
    'Dashboard.Learners.ColumnTooltip.LateCancelled' : 'Simulaciones que fueron canceladas tarde (después del plazo de cancelación) por el alumno, el moderador o el propietario de la cuenta o simulaciones.',
    'Dashboard.Learners.ColumnTooltip.EndedInError' : 'Simulaciones que han finalizado con error en la conexión del alumno',
    'Dashboard.Learners.ColumnTooltip.ProjectName' : 'Nombre del proyecto',
    'Dashboard.Learners.ColumnTooltip.ScenarioName' : 'Nombre del escenario',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartDate' : 'Fecha en que se programó que empezara esta simulación',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartTime' : 'Hora en que se programó que empezara esta simulación',
    'Dashboard.Learners.ColumnTooltip.ScheduledEndTime' : 'Hora en que se programó que terminara esta simulación',
    'Dashboard.Learners.ColumnTooltip.SimulationStatus' : 'Estatus final para la simulación',
    'Dashboard.Learners.ColumnTooltip.LearnerStatus' : 'Estatus final del alumno para la simulación',
    'Dashboard.Learners.ColumnTooltip.LearnerTimeSpent' : 'Minutos que el alumno ha dedicado en la simulación. Para alumnos de Zoom y Meet, se estima en función del especialista de SIM si se ha completado',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistName' : 'Especialista de simulación asignado para entregar la simulación',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistStatus' : 'Estatus final de especialista de SIM para la simulación',
    'Dashboard.Learners.ColumnTooltip.SimulationId' : 'Identificador único de la simulación',
    'Dashboard.Learners.ColumnTooltip.Report' : 'Enlace al informe de la simulación para ver (si está disponible) la grabación y el análisis de la simulación',
    'Dashboard.SessionLearners.TableHeader' : 'Datos de alumnos de sesión',
    'MursionPortal.FilterHeading.Filter' : 'FILTRO',
    'MursionPortal.FilterHeading.Graph' : 'GRÁFICOS',
    'MursionPortal.Table.NoRecordsMessage' : 'No se han encontrado resultados!',
    'MursionPortal.Filters.DeliveryMode' : 'Modo de entrega',
    'MursionPortal.Filters.PlaceHolder.DeliveryMode' : 'Buscar modo de entrega...',
    'MursionPortal.Dashboard.Chart.Tooltip.Utilized' : 'Número total utilizado para la partida de contrato. Incluye simulaciones completadas, perdidas o canceladas/reprogramadas (después del plazo).',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Completed' : 'Simulaciones que se han completado con éxito.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LearnerMissed' : 'Simulaciones que se ha perdido el alumno.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Error' : 'Simulaciones que han finalizado con error en la conexión del alumno.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateCancellation' : 'Simulaciones que fueron canceladas tarde (después del plazo de cancelación) por el alumno, el moderador o el propietario de la cuenta o simulaciones abandonadas o rechazadas por el alumno.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.EarlyCancellation' : 'Simulaciones que fueron canceladas pronto (antes del plazo de cancelación) por el alumno, el moderador o el propietario de la cuenta.',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Completed' : 'Completado',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Error' : 'Error',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateCancellation' : 'Cancelación tardía.',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EarlyCancellation' : 'Cacelación temprana.',
    'MursionPortal.Dashboard.Chart.PrivatePractice1' : 'Práctica privada 30 m',
    'MursionPortal.Dashboard.Chart.PrivatePractice2' : 'Práctica privada 60 m',
    'MursionPortal.Dashboard.Chart.Workshop1' : 'Taller 60 m',
    'MursionPortal.Dashboard.Chart.Workshop2' : 'Taller 90 m',
    'MursionPortal.Dashboard.Chart.StringType.Utilized' : 'Utilizado',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown' : 'Descomposición de simulación',
    'MursionPortal.SessionRecording' : 'Grabación de sesión',
    'MursionPortal.VoiceAnalytics' : 'Análisis de voz',
    'MursionPortal.VideoTab' : 'Vídeo',
    'MursionPortal.SessionInformation' : 'Información de sesión',
    'MursionPortal.SmartMatrix' : 'Métricas inteligentes',
    'MursionPortal.DeliveryDetails' : 'Detalles de entrega',
    'MursionPortal.ConversationalFlow' : 'Flujo de conversación',
    'MursionPortal.ConversationalFlowTooltip' : 'Este gráfico representa su eficacia durante una conversación. Se compone de entradas de cambio de turnos que revelan lo bien que usted es capaz de participar en una conversación constructiva.',
    'MursionPortal.BalancedTalkTime' : 'Conversación',
    'MursionPortal.BalancedFeedback' : 'Comentarios equilibrados',
    'MursionPortal.ConversationalDisruptions' : 'Interrupción',
    'MursionPortal.DisruptionsFeedback' : 'Comentarios sobre interrupciones',
    'MursionPortal.Heading.SessionSmartMetrics' : 'Análisis de voz de la sesión:',
    'Clients.Modal.Label.SmartMetrics' : 'Análisis de voz:',
    'Clients.Modal.Projects.Header.SessionSmartMetrics' : 'Análisis de voz de la sesión:',
    'Clients.Modal.Projects.Checkbox.EnableSmartMetrics' : ' Habilitar análisis de voz',
    'Filters.Contract' : 'Contrato',
    'Filters.Session': 'Sesión',
    'Clients.Modal.Label.ShowSowReporting' : 'Mostrar informe de SOW:',
    'Clients.Modal.Label.ShowSurveyEnabled' : 'Enviar encuesta de alumno posterior a la simulación:',
    'Dashboard.SimulationTable.Column.Provider' : 'Proveedor',
    'Dashboard.SimulationTable.Column.Client' : 'Cliente',
    'Dashboard.SimulationTable.Column.Project' : 'Proyecto',
    'Dashboard.SimulationTable.Column.ScenarioName' : 'Nombre de escenario',
    'Dashboard.SimulationTable.Column.ScheduledStartDate' : 'Fecha de inicio programada',
    'Dashboard.SimulationTable.Column.ScheduledStartTime' : 'Hora de inicio programada',
    'Dashboard.SimulationTable.Column.ScheduledEndTime' : 'Hora de finalización programada',
    'Dashboard.SimulationTable.Column.LearnersNames' : 'Nombres de alumno(s)',
    'Dashboard.SimulationTable.Column.LearnersEmails' : 'Correos electrónicos de alumno(s)',
    'Dashboard.SimulationTable.Column.LearnersStatus' : 'Estatus de alumno(s)',
    'Dashboard.SimulationTable.Column.LearnersTimeSpent' : 'Tiempo dedicado por el alumno(s)',
    'Dashboard.SimulationTable.Column.SimSpecialistName' : 'Nombre de especialista de simulación',
    'Dashboard.SimulationTable.Column.SimSpecialistStatus' : 'Estatus de especialista de simulación',
    'Dashboard.SimulationTable.Column.SimSpecialistTimeSpent' : 'Tiempo dedicado por el especialista de SIM',
    'Dashboard.SimulationTable.Column.ScheduledBy' : 'Programado por',
    'Dashboard.SimulationTable.Column.ScheduledAt' : 'Programado en',
    'Dashboard.SimulationTable.Column.Recorded' : 'Grabado',
    'Dashboard.SimulationTable.Column.ClientId' : 'ID de cliente',
    'Dashboard.SimulationTable.Column.LearnerName' : 'Nombre de alumno',
    'Dashboard.SimulationTable.Column.LearnerName.ButtonHoverTitle' : 'Copiar correo electrónico de alumno',
    'Dashboard.SimulationTable.Column.LearnerEmail' : 'Correo electrónico de alumno',
    'Dashboard.SimulationTable.Column.LearnerStatus' : 'Estatus de alumno',
    'Dashboard.SimulationTable.Column.LearnerTimeSpent' : 'Tiempo dedicado por el alumno',
    'Dashboard.SimulationTable.ColumnTooltip.Provider' : 'Nombre de proveedor/Titular de licencia',
    'Dashboard.SimulationTable.ColumnTooltip.Client' : 'Nombre del cliente',
    'Dashboard.SimulationTable.ColumnTooltip.Project' : 'Nombre del proyecto',
    'Dashboard.SimulationTable.ColumnTooltip.ScenarioName' : 'Nombre del escenario',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartDate' : 'Fecha en que se programó que empezara esta simulación',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartTime' : 'Hora en que se programó que empezara esta simulación',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledEndTime' : 'Hora en que se programó que terminara esta simulación',
    'Dashboard.SimulationTable.ColumnTooltip.SimulationStatus' : 'Estatus final para la simulación',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersNames' : 'Nombre(s) de los alumnos registrados y no registrados para la simulación',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersEmails' : 'Correo electrónico de los alumnos registrados y no registrados para la simulación',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersStatus' : 'Estatus final del alumno para la simulación',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersTimeSpent' : 'Tiempo dedicado conectado a la simulación por los alumnos que se unieron a la simulación',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistName' : 'Nombre de especialista de simulación',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistStatus' : 'Estatus final de especialista de SIM para la simulación',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistTimeSpent' : 'Longitud real, en minutos, de esta simulación',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledBy' : 'Nombre del usuario que programó la simulación',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledAt' : 'Fecha y hora en las que se programó esta simulación',
    'Dashboard.SimulationTable.ColumnTooltip.Recorded' : 'Indica si la simulación fue grabada o si no estaba permitido.',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerName' : 'Nombre(s) de los alumnos registrados y no registrados para la simulación',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerStatus' : 'Estatus final del alumno para la simulación',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerTimeSpent' : 'Minutos que el alumno ha dedicado en la simulación. Para alumnos de Zoom y Meet, se estima en función del especialista de SIM si se ha completado',
    'MursionPortal.Label.VideoTimeLineSlice' : 'Fracciones de la cronología del vídeo',
    'MursionPortal.Label.SpeakingMoreThanSixty' : 'Hablando más del 60 %',
    'MursionPortal.Label.SpeakingBetweenSixty' : 'Hablando del 40 % al 60 %',
    'MursionPortal.Label.InterruptionMoreThanSixty' : 'Interrupción más del 60 %',
    'MursionPortal.Label.InterruptionBetweenSixty' : 'Interrupción del 40 % al 60 %',
    'MursionPortal.Label.SpeakingLessThanFourty' : 'Hablando menos del 40 %',
    'MursionPortal.Label.InterruptionLessThanFourty' : 'Interrupción menos del 40 %',
    'MursionPortal.Label.Interruption.ByAvatar' : 'Por el avatar',
    'MursionPortal.Label.Interruption.ByLearner' : 'Por el alumno',
    'MursionPortal.Projects.NoContractsFound' : 'No se han encontrado Contratos.',
    'MursionPortal.Projects.ProjectContracts' : 'Contratos de proyecto',
    'MursionPortal.Tooltip.AvatarInterrupt' : 'El avatar intenta interrumpir al hablante con éxito.',
    'MursionPortal.Tooltip.LearnerInterrupt' : 'El alumno intenta interrumpir al hablante con éxito.',
    'MursionPortal.Tooltip.Pauses' : 'Pausas. Más de 0,2 s y menos de 0,75 s',
    'MursionPortal.Tooltip.Silence' : 'Interrumpe el discurso más de 10 s',
    'MursionPortal.Tooltip.AvatarSpeaking' : 'El avatar intenta hablar con éxito.',
    'MursionPortal.Tooltip.LearnerSpeaking' : 'El alumno intenta hablar con éxito.',
    // 'MursionPortal.label.LearnerInterrupts' : 'El alumno interrumpe',
    // 'MursionPortal.label.AvatarInterrupts' : 'El avatar interrumpe',
    'MursionPortal.label.Pause' : 'Pausa',
    'MursionPortal.Label.Silence' : 'Silencio',
    'MursionPortal.label.AvatarSpeaking' : 'Avatar hablando',
    'MursionPortal.label.LearnerSpeaking' : 'Alumno hablando',
    'MursionPortal.Contract.DuplicateLineItem' : 'Ya existe una partida para esta longitud de simulación y modo de entrega',
    'MursionPortal.Contract.DeleteLineItemIcon' : 'Eliminar icono de partida.',
    'SessionReports.Label.ShareVideoLink' : 'Compartir enlace de vídeo',
    'MursionPortal.Title.Silence' : 'Interrumpe el discurso más de 10 s',
    'Dashboard.LeftPane.User.UserActivity' : 'Actividad de usuario',
    'Dashboard.LeftPane.Performance.SelfService' : 'Utilización de autoservicio',
    'MursionPortal.Placeholder.ClientValue' : 'Seleccionar un cliente',
    'Filters.Date.FromDate' : 'Fecha de inicio',
    'Filters.Date.ToDate' : 'Fecha de finalización',
    'Dashboard.UserActivity.MainHeader' : 'Actividad de usuario',
    'Dashboard.SelfService.MainHeader' : 'Utilización de autoservicio',
    'Dashboard.SelfService.Column.SelfServiceProvider' : 'Proveedor',
    'Dashboard.SelfService.Column.SelfServiceTotalHoursUtilized' : 'Total de horas utilizadas',
    'Dashboard.SelfService.Column.SelfServiceHoursCompleted' : 'Horas completadas',
    'Dashboard.SelfService.Column.SelfServiceHoursMissed' : 'Horas faltantes',
    'Dashboard.SelfService.Column.SelfServiceHoursErrored' : 'Horas con error',
    'Dashboard.SelfService.Column.SelfServiceSimulationsComplete' : 'Simulaciones completadas',
    'Dashboard.SelfService.Column.SelfServiceSimulationsMissed' : 'Simulaciones perdidas',
    'Dashboard.SelfService.Column.SelfServiceSimulationsErrored' : 'Simulaciones con error',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceProvider' : 'Proveedor',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceTotalHoursUtilized' : 'Simulaciones que se han completado con éxito',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursCompleted' : 'Horas completadas',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursMissed' : 'Simulaciones que se ha perdido el alumno',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursErrored' : 'Simulaciones que han finalizado con error en la conexión del alumno',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsComplete' : 'Simulaciones completadas',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsMissed' : 'Simulaciones perdidas',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsErrored' : 'Simulaciones con error',
    'Dashboard.Learners.Column.AssignedProjects' : 'Proyectos asignados',
    'Dashboard.Learners.Column.AssignedScenarios' : 'Escenarios asignados',
    'Dashboard.Learners.Column.ScheduledScenarios' : 'Escenarios programados',
    'Dashboard.Learners.Column.CompletedScenarios' : 'Escenarios completados',
    'Settings.Config.SessionReport.ShowSmartMetrics' : 'Análisis de voz',
    'Settings.SSO.CopySsoDeepLink' : 'Copiar enlace profundo de SSO',
    'Settings.SSO.CopySsoDeepLinkNotification' : 'El enlace de SSO se ha copiado en su portapapeles',
    'MursionPortal.Dashboard.Chart.Assigned' : 'Asignado',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Simulation' : 'Simulaciones desglosadas por cada estatus.',
    'MursionPortal.Dashboard.Chart.Header.NoDataAvailable' : 'No hay datos disponibles',
    'Projects.ProjectInfo.Label.SurveyControl' : 'Encuestas de proyecto',
    'Projects.ProjectInfo.Checkbox.EnablePreSimulationSurvey' : 'Habilitar encuesta de alumno anterior a la simulación',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey' : 'Habilitar encuesta de alumno posterior a la simulación',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled' : 'Encuesta habilitada',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PreSimulationSurvey' : 'Encuesta de alumno anterior a la simulación',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey' : 'Encuesta de alumno posterior a la simulación',
    'Settings.SSO.ModalBody.SelectDestination' : 'Seleccionar destino de enlace',
    'Settings.SSO.ModalBody.SelectDestination.SessionsTab' : 'Pestaña de sesiones',
    'Settings.SSO.ModalBody.SelectDestination.CalendarTab' : 'Pestaña de calendario',
    'Settings.SSO.ModalBody.SelectDestination.ReportsTab' : 'Pestaña de informes',
    'Settings.SSO.ModalBody.SelectDestination.DashboardTab' : 'Pestaña de tablero',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioCard' : 'Tarjeta de escenario',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioTabSelectTitle' : 'Primero seleccionar un proyecto y luego un escenario',
    'Settings.SSO.Button.CreateDeepLink' : 'Crear enlace profundo de SSO',
    'Settings.SSO.ModalHeader.DeepLink' : 'Copiar enlace profundo de SSO',
    'MursionPortal.Dashboard.Chart.Header.LearnerSimulations' : 'Número de escenarios asignados',
    'Clients.Modal.Label.MursionSocialAttendance' : 'Asistencia Social a Mursion:',
    'MursionPortal.CodeOfConduct.Text' : 'Al elegir la programación de su sesión de Mursion, acepta acatar y respetar el <code>Código de conducta</code> de Mursion.',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.CompletedAllAvailable' : 'Alumnos que hayan completado todos los escenarios que estaban disponibles para ellos',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NoneAvailableToSchedule' : 'Alumnos que todavía no han tenido un escenario disponible para programar',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NotYetScheduled' : 'Alumnos con escenarios disponibles para ellos pero todavía no han programado una simulación',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.Scheduled' : 'Alumnos que tienen una o más simulaciones programadas que todavía no han completado',
    'MursionPortal.DateRangeSelector.Label.DateRange' : 'Invervalo de datos',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReservedText' : 'Ya selecionada - seleccione otra franja horaria.',
    'MursionPortal.EndDate' : 'Fecha de finalización',
    'MursionPortal.Label.FilterSIMsBy' : 'Filtrar SIMs por',
    'MursionPortal.LearnerSimSelector.Learner' : 'Alumnos',
    'MursionPortal.LearnerSimSelector.Learner.Selected' : 'Alumno seleccionado',
    'Dashboard.Filters.SimulationID' : 'ID de simulación',
    'MursionPortal.LearnerSimSelector.Sim.Selected' : 'Especialista de simulación',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable' : 'Certificado y no disponible',
    'MursionPortal.LearnerSimSelector.Tab.Search.Learner' : 'Buscar o seleccionar alumno',
    'MursionPortal.LearnerSimSelector.Tab.Search.Sim' : 'Buscar o seleccionar especialista de SIM',
    'MursionPortal.Placeholder.ProvidersValue' : 'Seleccionar un proveedor',
    'MursionPortal.ProjectContract.Confirmation' : '¿Está seguro de que quiere eliminar el contrato de este proyecto?',
    'MursionPortal.Scheduling.Label.DateTimeTab' : 'Fecha + hora',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedAndAvailableSimSpecialist' : 'No hay disponibilidad de una SIM certificada.',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedSimSpecialist' : 'No hay simulaciones disponibles',
    'MursionPortal.SessionFlow.Label.AreYouSure' : '¿Está seguro?',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing' : 'Antes de eliminar asegúrese de lo siguiente:',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text1' : 'Usted ha comprobado que el usuario solicitante tiene autoridad para eliminar (conozca el consentimiento de alumno y las simulaciones de grupo).',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text2' : 'Usted ha creado un caso de soporte que indica quien está solicitando la eliminación y que usted lo ha comprobado con el propietario de la cuenta en caso necesario.',
    'MursionPortal.SessionFlow.Label.DeleteRecording' : 'Eliminar grabación',
    'MursionPortal.SessionFlow.Label.ProviderName' : 'Nombre de proveedor',
    'MursionPortal.SessionFlow.Label.ReminderThisCanNotBeUndone' : 'RECORDATORIO: ¡Esto no se puede deshacer!',
    'MursionPortal.SessionFlow.Label.ScenarioDetails' : 'Detalles de escenario',
    'MursionPortal.SessionFlow.Label.SessionStartEndDateTime' : 'Fecha y hora de inicio y finalización de sesión',
    'MursionPortal.SessionFlow.Label.SupportCase' : 'Caso de Soporte n.º*',
    'MursionPortal.SessionFlow.Label.YouAreAboutToDeletevideoRecording' : 'Se dispone a eliminar una grabación de vídeo',
    'MursionPortal.SessionNotStarted.AudioVideoSettings' : 'Compruebe los ajustes de su audio y vídeo',
    'MursionPortal.SessionNotStarted.DescriptionOne' : 'Mientras usted espera, puede:',
    'MursionPortal.SessionNotStarted.DescriptionTwo' : 'Esta ventana emergente desaparece <code>{value}</code minutos antes de su simulación. Haga clic en actualizar si la página no se actualiza automáticamente en ese momento.',
    'MursionPortal.SessionNotStarted.Heading' : 'Ha llegado un poco pronto',
    'MursionPortal.SessionNotStarted.PreSimTips' : 'Compruebe estos <code>consejos previos a la simulación</code>',
    'MursionPortal.SessionNotStarted.ScenarioDetails' : 'Revise los <code>detalles del escenario</code>',
    'MursionPortal.StartDate' : 'Fecha de inicio',
    'MursionPortal.Survey.Thankyou.Msg' : 'Le agradecemos el tiempo dedicado a realizar esta encuesta. Su respuesta ha sido grabada.',
    'MursionPortal.Title.Hours' : 'Hora(s)',
    'MursionPortal.Title.Minutes' : 'Minuto(s)',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionDoesNotLieWithLearner' : 'Indique si da consentimiento para proceder y que se grabe su simulación(es). Si no da su consentimiento, ya no podrá proceder con su sesión de simulación y el software se cerrará. Si da su consentimiento, su sesión(es) de simulación se grabará(n) y cada grabación se guardará con seguridad durante 12 meses, momento en el que será archivada.',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionsLiesWithLearner' : '¿Está seguro? Para continuar sin grabar, haga clic en el botón Confirmar. Para cambiar su consentimiento de grabación, haga clic en el botón Cancelar.',
    'Session.Edit.Modal.JoinSimulationBtnTooltip' : 'Podrá unirse <code>{minutesBeforeJoin}</code> minutos antes de que empiece su simulación.',
    'Session.Edit.Modal.LastEmailTrigger' : 'Último correo electrónico enviado al alumno: {sentDate}',
    'Session.Edit.Modal.LastEmailTrigger.Sender.UserName' : 'De: {userName} {sentTime}',
    'Session.Rescheduling.Email.Reminder.Msg' : 'Enviar recordatorio por correo electrónico de reprogramación al alumno',
    'Settings.Config.Invitation.MursionSocialAttendance' : 'Obtener asistencia social a Mursion.',
    'Settings.LTI.Modal.SendScore' : 'Enviar puntuación', 
    'Dashboard.ContractProgress.Column.Provider' : 'Proveedor',
    'Settings.SSO.CopyDeepLinkNotification' : 'Su enlace se ha copiado en su portapapeles',
    'MursionPortal.Dashboard.Table.Empty.Body.Message' : 'los detalles se cargarán una vez realizada la selección',
    'Settings.SSO.ModalBody.ProjectSelector.DisabledTitle' : 'Seleccione primero la opción correcta',
    'Settings.SSO.ModalBody.ScenarioSelector.DisabledTitle' : 'Seleccione primero un proyecto',
    'MursionPortal.Label.LearnerListening' : 'Alumno escuchando',
    'MursionPortal.Label.InterruptionCount' : 'Veces',
    'MursionPortal.ConversationalFlow.label.videoTimeLine' : 'Cronología del vídeo',
    'MursionPortal.ConversationalFlow.label.zoomTimeLine' : 'Zoom',
    'MursionPortal.LearnerSimSelector.Sim.Available' : 'Certificado y disponible',
    'MursionPortal.Button.View' : 'Vista',
    'MursionPortal.Dashboard.NewSimulationAssigned' : 'Nueva simulación asignada',
    'MursionPortal.Dashboard.MakeUpSimulation' : 'Componer simulación',
    'MursionPortal.Dashboard.MissedSimulation' : 'Taller perdido',
    'MursionPortal.Dashboard.CompletedSimulations' : 'Simulaciones completadas',
    'MursionPortal.Dashboard.BackToDashboard' : 'Volver al tablero',
    'MursionPortal.Dashboard.CancelledSimulation' : 'Taller perdido',
    'MursionPortal.ScenarioSearchList.Dialog.Title' : 'Usted tiene disponibilidad periódica programada en ese momento. ¿Quiere también actualizar su disponibilidad periódica?',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText' : 'Solo quiero actualizar mi disponibilidad para {startDate}',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText.ToDate' : 'Quiero actualizar mi disponibilidad periódica a partir del {date}',
    'Mursion.Portal.Status.Connected' : 'CONECTADO',
    'Mursion.Portal.Status.Late' : 'TARDE',
    'Mursion.Portal.Status.Immersing' : 'INMERSIÓN',
    'Mursion.Portal.Status.Left' : 'ABANDONADA',
    'Mursion.Portal.Status.Declined' : 'RECHAZADA',    
    'Login.EditFile': 'Editar {file} y guardarlo para volver a cargarlo.',
    'Login.Hello': 'Hola',
    'Login.Title': 'Bienvenido/a al portal de Mursion',
    'Login.Description': 'Diseñe, programe y administre sus simulaciones de realidad virtual',
    'Login.PrivacyPolicy': 'política de privacidad',
    'Login.PrivacyDataInfo': 'información sobre los datos de privacidad',
    'Login.TechnicalSupport': 'asistencia técnica',
    'Login.SignIn': 'Iniciar sesión',
    'Login.Next': 'Siguiente',
    'Login.Email': 'Correo electrónico',
    'Login.Password': 'Contraseña',
    'Login.ForgotPassword': 'He olvidado la contraseña?',
    'Login.Login': 'Iniciar sesión',
    'Login.EmailHint': 'Introduce tu correo electrónico',
    'Login.PasswordHint': 'Ingresa tu contraseña',
    'Login.NewPasswordHint': 'ingrese nueva clave',
    'Login.RepeatPasswordHint': 'ingrese la nueva contraseña nuevamente',
    'Login.Image': 'Mursion-Logo',
    'Filters.Heading': 'FILTROS:',
    'Dashboard.LeftPaneHeader': 'PANEL DE CONTROL',
    'Dashboard.LeftPane.CategoryHeader.Users': 'USUARIOS',
    'Dashboard.LeftPane.CategoryHeader.Projects': 'PROYECTOS',
    'Dashboard.LeftPane.Project.ProjectActivity': 'Actividad de los proyectos',
    'Dashboard.LeftPane.Project.ProjectUtilization': 'Utilización de los proyectos',
    'Dashboard.LeftPane.CategoryHeader.Sessions': 'SESIONES',
    'Dashboard.LeftPane.Session.SessionLearner': 'Estudiantes de la sesión',
    'Dashboard.LeftPane.CategoryHeader.Legacy': 'LEGADO',
    'Dashboard.VideosTable.HoverTitle': ' ', // TODO: add translations
    'Dashboard.Button.ExportToEmailSentMsg': 'El informe se enviará pronto a {email}.',
    'Dashboard.Button.ExportToEmail.HoverTitle': 'Exportar datos',
    'Dashboard.Button.ExportToEmail.HoverTitleNotAvailable': 'La exportación no está disponible',
    'Dashboard.Next': 'Siguiente',
    'Dashboard.Last': 'Último',
    'Dashboard.Help': 'Ayuda',
    'Dashboard.First': 'Primero',
    'Dashboard.Back': 'Atrás',
    'FiltersHeading': 'FILTROS:',
    'Filters.Client': 'Cliente',
    'Filters.ClientPlaceHolderHint': 'Buscar clientes...',
    'Filters.Industries': 'Sectores',
    'Filters.IndustriesPlaceHolderHint': 'Buscar sectores...',
    'Filters.IndustriesDisabledTitle': 'Deshabilitado',
    'Filters.Project': 'Proyecto',
    'Filters.ProjectPlaceHolderHint': 'Buscar proyectos...',
    'Filters.ProjectDisabledTitle': 'Seleccione primero los clientes',
    'Filters.RoleDisabledTitle': 'La selección de roles está deshabilitada',
    'Filters.Scenario': 'Escenario',
    'Filters.ScenarioPlaceHolderHint': 'Buscar escenarios...',
    'Filters.ScenarioDisabledTitle': 'Seleccione primero los clientes y proyectos',
    'Filters.Team': 'Equipo',
    'Filters.TeamPlaceHolderHint': 'Buscar equipos...',
    'Filters.TeamDisabledTitle': 'Seleccione primero los clientes y proyectos',
    'Filters.SessionStatus': 'Estado de la sesión',
    'Filters.SessionStatusPlaceHolderHint': 'Buscar estado de la sesión...',
    'Filters.RegistrationStatus': 'Estado del registro',
    'Filters.RegistrationStatus.ShowAllRegistration': 'Estado del registro: Todos',
    'Filters.RegistrationStatus.ShowAcceptedRegistration': 'Registro aceptado',
    'Filters.RegistrationStatus.ShowNotAcceptedRegistration': 'Registro no aceptado',
    'Filters.Date.LastMonth': 'Último mes',
    'Filters.Date.Yesterday': 'Ayer',
    'Filters.Date.Today': 'Hoy',
    'Filters.Date.Tomorrow': 'Mañana',
    'Filters.Date.NextMonth': 'Siguiente mes',
    'Filters.Date.From': 'Desde',
    'Filters.Date.To': 'hasta',
    'Dashboard.UserActivityTable.HoverTitle': 'Ver resumen del usuario',
    'Dashboard.Export.ErrMsg': 'El límite máximo de entradas para la exportación es {limit}. Por favor, modifique su solicitud y vuelva a intentarlo.',
    'MursionPortal.Close': 'Cerrar',
    'Dashboard.UserTable.Column.Provider': 'Proveedor',
    'Dashboard.UserTable.Column.Client': 'Cliente',
    'Dashboard.UserTable.Column.ProfileId': 'ID del perfil',
    'Dashboard.UserTable.Column.UserEmail': 'Correo electrónico del usuario',
    'Dashboard.UserTable.Column.UserEmail.ButtonHoverTitle': 'Copiar correo electrónico',
    'Dashboard.UserTable.Column.SSO': 'SSO',
    'Dashboard.UserTable.Column.DateRegistered.ButtonTitle': 'Reenviar invitación',
    'Dashboard.UserTable.Column.Scenarios': 'Escenarios',
    'Dashboard.UserTable.Column.Teams': 'Equipos',
    'Dashboard.UserTable.Column.Industry': 'Sector',
    'Dashboard.UserTable.Column.Accepted': 'Aceptado',
    'Session.Tab.Scheduled': 'Programado',
    'Session.Tab.Active': 'Activo',
    'Session.Tab.Past': 'Pasado',
    'Session.Tab.Cancelled': 'Cancelado',
    'Session.Search': 'Buscar',
    'Session.Table.Column.Time': 'Hora',
    'Session.Table.Column.Client': 'Cliente',
    'Session.Table.Column.Project': 'Proyecto',
    'Session.Table.Column.SoftwareVersion': 'Versión del «software»',
    'Session.Table.Column.Scenario': 'Escenario',
    'Session.Table.Column.Trainees': 'Aprendices',
    'Session.Table.Column.Training': 'formación',
    'Session.Table.Column.Session': 'sesión',
    'Session.Token.Label.Clients': 'Clientes',
    'Session.Token.Label.Projects': 'Proyectos',
    'Session.Token.Label.Scenarios': 'Escenarios',
    'Session.Token.Label.Teams': 'Equipos',
    'Session.Token.Label.SessionStatuses': 'Estados de la sesión',
    'Session.Button.More': 'Más',
    'Session.Time.NotRunning': 'Aún no se está ejecutando',
    'Session.Time.Finished': 'Ya ha terminado',
    'Session.Time.Running': 'Ejecutándose ahora',
    'Session.Table.Column.Learners': 'Alumnos',
    'Session.Table.Column.Team': 'Equipo',
    'Session.Table.Column.Trainer': 'Formador',
    'Session.Table.Column.SimSpecialist': 'Especialista en Sim',
    'Session.Table.Column.TimeUntil': 'Tiempo hasta',
    'Session.Table.Column.Created': 'Creado',
    'Session.Table.Column.Cancelled': 'Cancelado',
    'Session.Table.Caption.SessionDetailsList': 'Lista de detalles de la sesión',
    'Session.Export': 'Exportar',
    'Session.Export.Message': 'Exportar contenido filtrado por',
    'Session.CalendarDate.Start': 'Desde:',
    'Session.CalendarDate.End': 'Hasta:',
    'Session.Cancel': 'Cancelar',
    'Session.Confirm': 'Confirmar',
    'Session.Total': 'TOTAL',
    'Session.Filter.SessionNotFoundMessage': 'No se ha encontrado ninguna sesión.',
    'Session.Filter.LearnerUpcomingSessionNotFoundMessage': 'No tiene próximas sesiones programadas.',
    'Session.Filter.UpcomingSessionNotFoundMessage': 'No hay próximas sesiones programadas.',
    'Session.Filter.LearnerActiveSessionNotFoundMessage': 'No tiene sesiones activas.',
    'Session.Filter.ActiveSessionNotFoundMessage': 'No hay sesiones activas.',
    'Session.Filter.LearnerCompletedSessionNotFoundMessage': 'Aún no ha completado ninguna sesión.',
    'Session.Filter.SessionReportNotFoundMessage': 'No hay ningún informe de sesión disponible.',
    'Session.Filter.CompletedSessionNotFoundMessage': 'No se ha completado ninguna sesión.',
    'Session.Filter.CancelledSessionNotFoundMessage': 'No se ha cancelado ninguna sesión.',
    'Session.Edit.Modal.Table.Column.DateTime': 'Fecha/Hora',
    'Session.Edit.Modal.Table.Column.UserRole': 'Usuario (rol)',
    'Session.Edit.Modal.Table.Column.Change': 'Cambiar',
    'Session.Edit.Modal.Table.Column.SessionStatus': 'Estado de la sesión',
    'Session.Edit.Modal.Table.Column.Reason': 'Motivo',
    'Session.Edit.Modal.AssetIndustry': 'Industria de activos',
    'Session.Edit.Modal.SimulationEnvironment': 'Entorno(s) de simulación',
    'Session.Edit.Modal.AvatarInSimulation': 'Avatar(es) en simulación(es)',
    'Session.Edit.Modal.Date': 'Fecha',
    'Session.Edit.Modal.Time': 'Hora',
    'Session.Edit.Modal.DeliveryMode': 'Modo de entrega',
    'Session.Edit.Modal.Duration': 'Duración',
    'Session.Edit.Modal.Status': 'Estado',
    'Session.Edit.Modal.LearnersAttended': 'Alumnos que han asistido',
    'Session.Edit.Modal.ReasonForCancellation': 'Motivo de la cancelación',
    'Session.Edit.Modal.MoreInfo': 'Más información...',
    'Session.Edit.Modal.HideAdditionalInfo': 'Ocultar información adicional...',
    'Session.Edit.Modal.AddToCalendar': 'Añadir al calendario',
    'Session.Edit.Modal.AttendSession': 'Asistir a la sesión',
    'Session.Edit.Modal.PleaseWait': 'Por favor, espere...',
    'Session.Edit.Modal.CopySessionLink': 'Copiar enlace de la sesión',
    'Session.Edit.Modal.SessionLink': 'Enlace de sesión',
    'Session.Edit.Modal.SubmitReview': 'Enviar revisión',
    'Session.Edit.Modal.SessionRequestPending': 'Solicitud de sesión pendiente',
    'Session.Edit.Modal.Back': 'Atrás',
    'Session.Edit.Modal.Learners': 'Alumno(s):',
    'Session.Edit.Modal.Learners.UnauthenticatedCheckbox': 'Solo se unirán los usuarios no autenticados',
    'Session.Edit.Modal.Trainees': 'Aprendiz(ces):',
    'Session.Edit.Modal.Trainer': 'Formador:',
    'Session.Edit.Modal.SimSpecialist': 'Especialista en Sim:',
    'Session.Edit.Modal.Notes': 'Notas:',
    'Session.Edit.Modal.ClientNotes': 'Notas del cliente:',
    'Session.Edit.Modal.Notes.EmptyMessage': 'No hay notas',
    'Session.Edit.Modal.Attendance': 'Asistencia:',
    'Session.Edit.Modal.ShowHistory': 'Mostrar historial...',
    'Session.Edit.Modal.HideHistory': 'Ocultar historial...',
    'Session.Edit.Modal.LearnersAttendedLabel': 'Alumnos que han asistido:',
    'Session.Edit.Modal.SimulationContent': 'Contenido de la simulación',
    'Session.Edit.Modal.SimulationContent.Name': 'Nombre:',
    'Session.Edit.Modal.SimulationContent.Description': 'Descripción:',
    'Session.Edit.Modal.SimulationContent.Image': 'Imagen:',
    'Session.Edit.Modal.RestoreSession.ConfirmationText': '¿Seguro que quiere restaurar esta sesión?',
    'Session.Edit.Modal.CancelTraining.ConfirmationText': '¿Seguro que quiere cancelar esta formación?',
    'Session.Edit.Modal.CancelSession.ConfirmationText': '¿Seguro que quiere cancelar esta sesión?',
    'Session.Edit.Modal.SessionSwap.ConfirmationText': '¿Seguro que quiere aceptar el intercambio de esta sesión?',
    'Session.Edit.Modal.AcceptRequest.ConfirmationText': '¿Seguro que quiere aceptar esta solicitud de sesión?',
    'Session.Edit.Modal.AssignSS.ConfirmationText': '¿Seguro que quiere asignar un nuevo especialista en Sim?',
    'Session.Edit.Modal.ChangeSS.ConfirmationText': '¿Seguro que quiere cambiar de especialista en Sim?',
    'Session.Edit.Modal.Button.RestoreSession': 'Restaurar sesión',
    'Session.Edit.Modal.Button.CancelTraining': 'Cancelar formación',
    'Session.Edit.Modal.Button.CancelSession': 'Cancelar sesión',
    'Session.Edit.Modal.Button.AcceptSwap': 'Aceptar intercambio',
    'Session.Edit.Modal.Button.Participate': 'Participar',
    'Session.Edit.Modal.Button.Swap': 'Intercambio',
    'Session.Edit.Modal.Button.UpdateSession': 'Actualizar sesión',
    'Session.Edit.Modal.Button.UpdateTraining': 'Actualizar formación',
    'Session.Edit.Modal.Button.AcceptRequest': 'Aceptar solicitud',
    'Session.Edit.Modal.Button.AssignSS': 'Asignar ES',
    'Session.Edit.Modal.Button.ChangeSS': 'Cambiar de ES',
    'Session.Attendance.Table.Column.UserName': 'Nombre del usuario',
    'Session.Attendance.Table.Column.Time': 'Hora',
    'Session.Attendance.Table.Column.Status': 'Estado',
    'Session.ViewSessionReport': 'Ver informe de sesión',
    'Session.ViewEdit': 'Ver/editar sesión',
    'Session.Edit.Modal.Table.HistoryNotFoundMessage': 'Todavía no hay ningún historial.',
    'Session.Export.ErrMsg': 'El límite máximo de entradas para la exportación es {limit}. Por favor, modifique su solicitud y vuelva a intentarlo.',
    'Session.Table.Column.Action': 'Acción',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoTrainers': 'No hay formadores para seleccionar',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoSimSpecialists': 'No hay especialistas en sim para seleccionar',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoTrainers': 'No se ha seleccionado ningún formador',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoSimSpecialists': 'No se ha seleccionado ningún especialista en sim',
    'Session.Edit.Modal.SessionSimSpecialistField.EditIconLabel': 'Editar',
    'Session.Edit.Modal.SessionSimSpecialistField.Search.Placeholder': 'Buscar',
    'Session.Edit.Modal.SessionSimSpecialist.Button.Accept': 'Aceptar',
    'ConfirmationDialogue.AddReasonForChange': 'Por favor, añada un motivo para el cambio',
    'ConfirmationDialogue.Button.Submit': 'Enviar',
    'ConfirmationDialogue.Button.Cancel': 'Cancelar',
    'ConfirmationDialogue.Button.Ok': 'Ok',
    'ConfirmationDialogue.ReasonForChange': 'Motivo del cambio',
    'ConfirmButton.CancelSession.ConfirmCancellation': 'Confirmar cancelación',
    'ConfirmButton.CancelSession': 'Cancelar sesión',
    'ConfirmButton.CancelSession.No': 'No',
    'Pagination.Button.First': 'Primero',
    'Pagination.Button.Back': 'Atrás',
    'MursionPortal.UserName': 'Nombre del usuario',
    'MursionPortal.Email': 'Correo electrónico',
    'ClientUserTable.Column.Roles': 'Roles',
    'MursionPortal.Department': 'Departamento',
    'MursionPortal.Location': 'Ubicación',
    'ClientUserTable.Column.Projects': 'Proyectos',
    'ClientUserTable.Column.Teams': 'Equipos',
    'ClientUserTable.Column.Accepted': 'Aceptado',
    'ClientUserTable.HoverTitle': 'Ver/editar usuario',
    'ClientUserTable.NoUserError': 'Todavía no hay usuarios',
    'MursionPortal.FirstName': 'Nombre',
    'MursionPortal.LastName': 'Apellidos',
    'MursionPortal.Phone': 'Teléfono',
    'Users.TableModal.EmailPlaceHolder': 'ejemplo@dominio.com',
    'Users.TableModal.Title': 'Tratamiento',
    'Users.TableModal.LocationPlaceHolder': 'Localidad, País',
    'Users.TableModal.DepartmentPlaceHolder': 'Nombre del departamento',
    'Users.TableModal.TitlePlaceHolder': 'Cargo',
    'Users.Table.Modal.SettingsHeader': 'Configuración del facilitador/alumno',
    'Users.Table.ModalHeader': 'Información del usuario',
    'Users.Table.ModalButton.Cancel': 'Cancelar',
    'Users.Table.ModalButton.Delete': 'Borrar',
    'Users.Table.ModalButton.Save': 'Guardar',
    'Users.Table.ModalButton.SendInvite': 'Enviar invitación',
    'Users.Table.ModalButton.Restore': 'Restaurar',
    'Users.Table.Modal.Teams.Text': 'Todavía no se ha añadido ningún equipo',
    'Users.Table.Modal.Teams': 'Equipos',
    'Users.Table.Modal.EmployeeIdentification': 'Identificación del empleado',
    'Users.Table.Modal.Timezone': 'Zona horaria',
    'Users.Table.Modal.Roles': 'Roles',
    'Users.Table.Modal.Settings.LearnerButton': 'Como alumno',
    'Users.Table.Modal.Settings.FacilitatorButton': 'Como facilitador',
    'Users.Button.CreateUserButton': 'Crear usuario ',
    'Users.Button.Cancel': 'Cancelar',
    'Users.Button.ImportUsers': 'Importar usuarios',
    'Users.Button.Export': 'Exportar',
    'Users.heading.ExportContentFilteredBy': 'Exportar contenido filtrado por',
    'Users.Button.Active': 'ACTIVO',
    'Users.Button.Archived': 'ARCHIVADO',
    'Users.Button.All': 'TODOS',
    'Users.Text.Total': 'TOTAL',
    'Users.Heading.NewUsers': 'Nuevos usuarios',
    'Users.Placeholder': 'ejemplo@dominio.com',
    'Users.Button.AddUser': 'Añadir usuario ',
    'Users.Button.CreateUser': 'Crear usuario',
    'Users.Button.CreateUsers': 'Crear usuarios',
    'Users.Heading.ImportNewUsers': 'Importar nuevos usuarios',
    'Users.Heading.ImportNewExternalUsers': 'Importar nuevos usuarios externos',
    'Users.Button.ImportUserList': 'Importar lista de usuarios',
    'Users.Text.InstructionMessage.UsersWithoutPredefined': 'Los usuarios sin equipos predefinidos se asignarán a',
    'Users.Text.AddCsv': 'Añada un archivo .csv o .xslx que contenga una lista de direcciones de correo electrónico colocadas en una columna.',
    'Users.Text.ToImport': 'Para importar campos adicionales, por favor, siga estas pautas:',
    'Users.Text.ListUser': 'El correo electrónico del usuario debe estar separado de la lista de equipos de usuarios por una coma, un punto y coma, una barra vertical o dos puntos.',
    'Users.Text.ListTeams': 'Los equipos deben estar separados por comas.',
    'Users.Text.ListFields': 'Los campos y cada uno de los equipos deben especificarse en celdas separadas.',
    'Users.Text.UserRole': 'El tipo de rol del usuario se establecerá de forma predeterminada como alumno',
    'Users.Text.UnlessOtherwiseIndicated': 'a menos que exista otra indicación',
    'Users.Text.SampleImport': 'Puede descargar una muestra de archivos importados aquí:',
    'Users.DownloadCsvTemplate': 'Descargar plantilla CSV',
    'Users.DownloadExcelTemplate': 'Descargar plantilla de Excel',
    'Users.Button.Update': 'Actualizar',
    'Users.Labels.Clients': 'Clientes',
    'Users.Labels.Projects': 'Proyectos',
    'Users.Labels.Scenarios': 'Escenarios',
    'Users.Labels.Teams': 'Equipos',
    'Users.Labels.Roles': 'Roles',
    'Users.Text.OnlyAccepted': 'Solo aceptados:',
    'Users.Button.Confirm': 'Confirmar',
    'Users.Button.AccOwner': 'Propietario de la cuenta',
    'Users.Button.Facilitator': 'Facilitador',
    'Users.Button.Learner': 'Alumno',
    'Users.Button.ProfServices': 'Servicios prof.',
    'Users.Button.ProjectManager': 'Jefe de proyecto',
    'Users.Button.SimSpecialist': 'Especialista en Sim',
    'Users.Button.ITManager': 'Gerente de informática',
    'Users.Button.BusinessAdmin': 'Administración de empresas',
    'Users.Button.SupportAdmin': 'Administrador de apoyo',
    'Users.Button.DevAdmin': 'Administrador de desarrollo',
    'Users.Button.ImportExternalUsers': 'Importar usuarios externos',
    'Users.Button.AssignLearners': 'Asignar alumnos',
    'Users.CreateUser.Email.Placeholder': 'ejemplo@dominio.com',
    'Users.Text.Instructions': 'Añada el archivo .csv o .xslx que contiene la lista de usuarios con los siguientes campos: correo electrónico, ssoid, nombre, apellidos, rol, equipo. Todos los campos, excepto el equipo, son obligatorios.',
    'Users.Text.Guidelines': 'Por favor, siga las siguientes pautas:',
    'Users.Button.CreateUser.PencilIcon': 'Editar la información del usuario',
    'Users.Button.CreateUser.TrashIcon': 'Eliminar usuario',
    'Users.ExportButton.TextYes': 'Sí',
    'Users.ExportButton.TextNo': 'No',
    'Users.Button.OK': 'OK',
    'Users.Button.AddProject': 'Añadir proyecto',
    'Users.CreateUser.Label.AddToProjects': 'Agregar a proyecto(s)',
    'Users.UserInfo.Label.AddTeams': 'Añadir equipos',
    'Users.UserInfo.Label. SelectATeam': 'Seleccionar un equipo',
    'Users.UserInfo.Label.AssignToTeam': 'Asignar al equipo:',
    'Users.CreateUser.Label.NoProjectsSpecified': 'No se ha especificado ningún proyecto',
    'Users.Option.SelectClient': 'Seleccionar cliente',
    'Users.Label.Client': 'Cliente:',
    'Users.Label.RemoveTeam': 'Eliminar equipo',
    'Users.Label.ChooseATeam': 'Elegir un equipo',
    'Users.Project.Warning': 'Este cliente no tiene proyectos. Por favor, seleccione otro.',
    'Users.Project.Warning.UnknownProject': 'Proyecto desconocido',
    'Users.ProjectContainer.Warning': 'Se debe añadir al menos un proyecto',
    'Users.CompanyUsers.Button.Ok': 'Ok',
    'Users.CompanyUsers.Button.Accept': 'Aceptar',
    'Users.CreateUser.Label.Tier': 'Nivel',
    'Users.CreateUser.Label.HireDate': 'Fecha de contratación',
    'Users.CreateUser.Label.StationType': 'Tipo de estación',
    'Users.CreateUser.ToggleButton.Home': 'Inicio',
    'Users.CreateUser.ToggleButton.Office': 'Oficina',
    'Users.CreateUser.Label.Hours': 'Horas',
    'Users.CreateUser.ToggleButton.FullTime': 'Jornada completa',
    'Users.CreateUser.ToggleButton.PartTime': 'Tiempo parcial',
    'Users.CreateUser.Label.ZoomID': 'ID de Zoom',
    'Users.CreateUser.ZoomID.Placeholder': 'ID de Zoom',
    'Users.CreateUser.ZoomID.Hover': 'Por favor, introduzca una ID de Zoom',
    'Users.CreateUser.Label.UserRoles': 'Rol(es) del usuario',
    'Users.CreateUser.Label.ProjectManagerSettings': 'Configuración del administrador de proyectos',
    'Users.CreateUser.Label.SimSpecialistSettings': 'Configuración del especialista en Sim',
    'Users.CreateUser.Label.Certificates': 'Certificados',
    'Users.CreateUser.Label.TurnOnEditCertificates': 'Activar la edición de certificados',
    'Users.CreateUser.Label.TurnOffEditCertificates': 'Desactivar la edición de certificados',
    'Users.CreateUser.Label.NoCertificatesHaveBeenAddedYet': 'Todavía no se ha añadido ningún certificado',
    'Users.CreateUser.Label.AddCertificate': 'Añadir certificado',
    'Users.CreateUser.Label.CertificateOwner': 'Propietario del certificado',
    'Users.CreateUser.Button.RemoveCertificate': 'Eliminar certificado',
    'Users.TableHeading.Name': 'Nombre',
    'Users.TableHeading.Email': 'Correo electrónico',
    'Users.TableHeading.Roles': 'Roles',
    'Users.TableHeading.Location': 'Ubicación',
    'Users.TableHeading.Accepted': 'Aceptado',
    'Users.Table.Hover.ViewEditUser': 'Ver/editar usuario',
    'Users.Table.Hover.NoUsersYet': 'Todavía no hay usuarios',
    'Users.CreateUser.Button.Client': 'Cliente',
    'Users.CreateUser.Label.ChooseAClient': 'Elegir un cliente',
    'Users.CreateUser.Label.SelectAClient': 'Seleccionar un cliente',
    'Users.CreateUser.Label.ChooseACertificate': 'Elegir un certificado',
    'Users.CreateUser.Label.SelectACertificate': 'Seleccionar un certificado',
    'Users.CreateUser.Label.NoCertificatesToSelect': 'No hay certificados para seleccionar',
    'Users.CreateUser.Button.AddCertificate': 'Añadir certificado ',
    'Users.CreateUser.PlaceHolder.SelectTier': 'Seleccionar nivel',
    'Users.RequiredField.Warning': 'Por favor, compruebe que todos los campos se hayan rellenado correctamente',
    'Users.RequiredField.ApplyChanges': 'Aplicar cambios',
    'Users.Heading.NewUserInfo': 'Información del nuevo usuario',
    'Users.SearchPlaceHolderText': 'Introducir nombre de usuario',
    'Users.SearchNoUserFoundError': 'No se ha encontrado ningún usuario.',
    'Users.Table.Modal.Settings.AddTeamHeader': 'Añadir equipos',
    'Users.Table.Modal.Settings.ChooseTeamHeader': 'Elegir un equipo',
    'Users.Table.Modal.Settings.AssignTeamHeader': 'Asignar al equipo:',
    'Users.Table.Modal.Settings.SelectTeamOptionHeader': 'Seleccionar un equipo',
    'Users.Table.Modal.Settings.TeamLearner': '(alumno)',
    'Users.Table.Modal.Settings.TeamFacilitator': '(facilitador)',
    'Users.Table.Modal.Settings.RemoveTeamOption': 'Eliminar equipo',
    'Users.Table.Modal.Settings.TurnOnEditTeamsOption': 'Activar la edición de equipos',
    'Users.Table.Modal.Settings.TurnOffEditTeamsOption': 'Desactivar la edición de equipos',
    'Users.Total': 'TOTAL',
    'Users.Table.Modal.ArchiveHeader.ConfirmArchiveUser': 'Confirmar el archivo del usuario',
    'Users.Table.Modal.ArchiveText': '¿Seguro que quiere archivar este usuario?',
    'Users.Table.Modal.ArchiveUserButton': 'Archivar usuario',
    'Users.Table.Modal.RestoreHeader.ConfirmRestoreUser': 'Confirmar la restauración del usuario',
    'Users.Table.Modal.RestoreText': '¿Seguro que quiere restaurar este usuario?',
    'Users.Table.Modal.RestoreUserButton': 'Restaurar usuario',
    'Users.Table.Modal.CancelButton': 'Cancelar',
    'Users.Table.Modal.ArchiveButton': 'Archivar',
    'Users.Table.Modal.RestoreButton': 'Restaurar',
    'Users.Table.Modal.SendInviteButton': 'Enviar invitación',
    'Users.CreateUserButton.Modal.CreateUserButton': 'Crear usuario',
    'Users.Table.Modal.UpdateUserButton': 'Actualizar usuario',
    'Users.ViewUserOptions': 'Ver usuario de {companyName}',
    'Users.NewUserOptions': 'Nuevo usuario de {companyName}',
    'Users.EditUserOptions': 'Editar usuario de {companyName}',
    'Reports.SessionListHeader.Completed': 'Completado',
    'Reports.SessionListHeader.TotalSessionTime': 'Tiempo total de la sesión,',
    'Reports.SessionListHeader.TotalActualTime': 'Tiempo real total,',
    'Reports.SessionListHeader.SimulationCompletionTime': 'Tiempo de finalización de la simulación',
    'Reports.SessionListHeader.CantOpenReport': 'Por favor, espere mientras nuestro motor de análisis procesa sus resultados. Es posible que deba actualizar esta página después de unos minutos.',
    'Reports.SessionListHeader.NoReportsFound': 'No se ha encontrado ningún informe.',
    'Reports.ReportCard.Button.ReviewSimulation': 'REVISAR SIMULACIÓN',
    'Reports.ReportCard.Strategies': 'Estrategias',
    'Reports.ReportCard.NotChecked': 'No revisado',
    'Dashboard.SchedulingInfoTable.Modal.SortBy': 'Ordenar por:',
    'Dashboard.SchedulingInfoTable.Modal.NoItemsFound': 'No se ha encontrado ningún elemento.',
    'Dashboard.SchedulingInfoTable.Modal.Teams': 'Equipos',
    'Dashboard.SchedulingInfoTable.Modal.Teams.Placeholder': 'Introducir nombre del equipo',
    'Dashboard.SchedulingInfoTable.Modal.Teams.EmptyMessage': 'No se ha encontrado ningún equipo',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.TeamName': 'Nombre del equipo:',
    'Dashboard.SchedulingInfoTable.Modal.Learners': 'Alumnos:',
    'Dashboard.SchedulingInfoTable.Modal.Projects': 'Proyectos:',
    'Dashboard.SchedulingInfo.Filter.SearchIndustries.PlaceholderHint': 'Buscar sectores...',
    'Dashboard.SchedulingInfo.Filter.IndustryDisabledTitle': 'Deshabilitado',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion.PlaceholderHint': 'Versiones de «software» utilizadas...',
    'Dashboard.SchedulingInfoTable.Modal.Total': 'TOTAL',
    'Dashboard.SchedulingInfoTable.Modal.ScenarioName': 'Nombre del escenario',
    'Dashboard.SchedulingInfoTable.Modal.SessionDate': 'Fecha de la sesión',
    'Dashboard.SchedulingInfoTable.Modal.ProjectName': 'Nombre del proyecto',
    'Dashboard.SchedulingInfoTable.Modal.SoftwareVersion': 'Versión del «software»',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.DeliveryMode': 'Modo de entrega',
    'Dashboard.SchedulingInfoTable.Modal.SessionStatus': 'Estado de la sesión',
    'Dashboard.SchedulingInfoTable.Modal.Name': 'Nombre',
    'Dashboard.SchedulingInfoTable.Modal.Status': 'Estado',
    'Dashboard.SchedulingInfoTable.Modal.Recording': 'La grabación va a',
    'Dashboard.SchedulingInfoTable.Modal.TeamName': 'Nombre del equipo',
    'Dashboard.SchedulingInfoTable.Modal.Learner': 'Alumnos',
    'Dashboard.SchedulingInfoTable.Modal.CompletedSessions': 'Sesiones completadas',
    'Dashboard.SchedulingInfoTable.Modal.DeliveryMode': 'Modo de entrega',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.ProjectName': 'Nombre del proyecto',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.SoftwareVersion': 'Versión del «software»',
    'Dashboard.SchedulingInfoTable.Modal.Timeline': 'Cronograma',
    'Dashboard.SchedulingInfoTable.Modal.SchedulesSessions': 'Sesiones programadas',
    'Dashboard.SchedulingInfoTable.Modal.MissedSessions': 'Sesiones mixtas',
    'Dashboard.SchedulingInfoTable.Modal.LateCancellations': 'Cancelaciones \"tardías\"',
    'Dashboard.SchedulingInfoTable.Modal.TimeSpent': 'Tiempo empleado',
    'Dashboard.SchedulingInfoTable.Modal.CancelledBy': 'Cancelado por',
    'Dashboard.SchedulingInfoTable.Modal.CancellationReason': 'Motivo de la cancelación',
    'Dashboard.SchedulingInfoTable.Modal.DateScheduled': 'Fecha programada',
    'Dashboard.SchedulingInfoTable.Modal.DateCancelled': 'Fecha cancelada',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Teams': 'Equipos',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterTeamName': 'Introducir nombre del equipo',
    'Dashboard.SchedulingInfoTable.Modal.TeamsEmptyMessage': 'No se ha encontrado ningún equipo',
    'Dashboard.SchedulingInfoTable.Modal.Filter.TeamName': 'Nombre del equipo',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Scenarios': 'Escenarios',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterScenarioName': 'Introducir el nombre del escenario',
    'Dashboard.SchedulingInfoTable.Modal.ScenariosEmptyMessage': 'No se ha encontrado ningún escenario',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioName': 'Nombre del escenario',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ProjectName': 'Nombre del proyecto',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SoftwareVersion': 'Versión del «software»',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioStatus': 'Estado del escenario',
    'Dashboard.SchedulingInfoTable.Modal.Filter.DeliveryMode': 'Modo de entrega',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsScheduled': 'Sesiones programadas',
    'Dashboard.SchedulingInfoTable.Modal.SessionsEmptyMessage': 'No se ha encontrado ninguna sesión',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsDateTime': 'Fecha/hora de la sesión',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsCompleted': 'Sesiones completadas',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsMissed': 'Sesiones perdidas',
    'Dashboard.SchedulingInfoTable.Modal.Filter.LateCancellations': 'Cancelaciones "tardías"',
    'Dashboard.SchedulingInfoTable.Modal.Filter.CancelledBy': 'Cancelado por',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsError': 'Error de sesiones',
    'RestorePassword.EnterEmailAddress': 'Introduzca su dirección de correo electrónico y haga clic en el botón Restaurar; se le enviará un correo electrónico con un enlace para restablecer su contraseña.',
    'RestorePassword.Email': 'Correo electrónico',
    'RestorePassword.Button.Cancel': 'Cancelar',
    'RestorePassword.Button.Restore': 'Restaurar',
    'RestorePassword.Modal.IfValidEmailAddressEntered': 'Si se introdujo una dirección de correo electrónico válida, se ha enviado a la cuenta asociada un correo electrónico con las instrucciones para su restablecimiento. Si el problema persiste, póngase en contacto con el servicio de asistencia al cliente.',
    'RestorePassword.Modal.Button.GotIt': 'Entendido',
    'Trainings.Heading.AllTrainingSessions': 'todas las sesiones de formación',
    'Requests.Table.Swap': 'SWAP',
    'Requests.Table.SchedulingRequests': 'Solicitudes de programación',
    'Requests.Total': 'TOTAL',
    'Requests.Table.NoSessionsFound': 'No se ha encontrado ninguna sesión.',
    'Requests.Table.NoUpcomingSwapSessions': 'No hay próximas sesiones de SWAP.',
    'Requests.Table.NoUpcomingRequests': 'No tiene próximas solicitudes.',
    'GetTimeToEvents.Day': 'Día',
    'GetTimeToEvents.Days': 'Días',
    'GetTimeToEvents.Minute': 'Minuto',
    'GetTimeToEvents.Minutes': 'Minutos',
    'GetTimeToEvents.Hour': 'Hora',
    'GetTimeToEvents.Hours': 'Horas',
    'GetTimeToEvents.LessThanOneMinute': 'Menos de 1 minuto',
    'SessionReports.SessionInformation': 'Información sobre la sesión',
    'SessionReports.SessionInformation.Hover.Information': 'Información',
    'SessionReports.SmartMetrics': 'Métricas inteligentes',
    'SessionReports.SendVideoLink.Modal.ShareLinkToVideo': 'Compartir enlace al vídeo',
    'SessionReports.Button.BackToReports': 'Volver a los informes',
    'SessionReports.Button.BackToTrainings': 'Volver a las formaciones',
    'SessionReports.Button.HideInfo': 'Ocultar información',
    'SessionReports.Button.ShowInfo': 'Mostrar información',
    'SessionReports.Button.SendVideoLink': 'Enviar enlace de vídeo',
    'SessionReports.TryAgain': 'Intentar de nuevo',
    'SessionReports.Mode': 'Modo:',
    'SessionReports.SessionLength': 'Duración de la sesión',
    'SessionReports.LearnersAttended': 'Alumnos que han asistido:',
    'SessionReports.ScenarioTimeline': 'Cronología del escenario:',
    'SessionReports.ActualSessionTime': 'Tiempo real de la sesión:',
    'SessionReports.ActualSimulationTime': 'Tiempo real de la simulación:',
    'SessionReports.LastUserStatus': 'Último estado del usuario:',
    'SessionReports.SessionStatus': 'Estado de la sesión:',
    'SessionReports.Delivery': 'Entrega',
    'SessionReports.SendVideoLink.Modal.AddEmails': 'Añadir correos electrónicos',
    'SessionReports.SendVideoLink.Modal.Button.AddEmail': 'Agregar correo electrónico ',
    'SessionReports.SendVideoLink.Modal.EmailPlaceHolder': 'ejemplo@dominio.com',
    'SessionReports.SendVideoLink.Modal.Button.Cancel': 'Cancelar',
    'SessionReports.SendVideoLink.Modal.Button.Send': 'Enviar',
    'SessionReports.FactSessionInfo': 'Información de la sesión real',
    'SessionReports.Text.NoTraineeParticipatedInSession': 'Ningún aprendiz ha participado en la sesión',
    'SessionReports.Text.NoLearnerParticipatedInSession': 'Ningún alumno ha participado en la sesión',
    'SessionReports.ToolTip.TrackingYours': 'Es muy importante que su percepción de un resultado o un elemento de las estrategias esté alineada con la del avatar o avatares. Eso indica que está rastreando su impacto en los avatares, lo que ayudará a mejorar la efectividad social. Esta información está contenida en la siguiente tabla.',
    'SessionReports.ToolTip.TrackingLearners': 'Es muy importante que la percepción del alumno de un resultado o un elemento de las estrategias esté alineada con la del avatar o avatares. Eso indica que el alumno está rastreando su impacto en los avatares, lo que ayudará a mejorar la eficacia social. Esta información está contenida en la siguiente tabla.',
    'SessionReports.ToolTip.AvatarsHeaderYours': 'Esta columna indica la percepción de los avatares de su desempeño en la simulación. La marca de verificación indica que los avatares percibieron que usted logró un resultado positivo, mientras que una marca en forma de cruz indica que hay margen de mejora.',
    'SessionReports.ToolTip.AvatarsHeaderLearners': 'Esta columna indica la percepción de los avatares acerca del desempeño del alumno en la simulación. La marca de verificación indica que los avatares percibieron que el alumno logró un resultado positivo, mientras que una marca en forma de cruz indica que hay margen de mejora.',
    'SessionReports.ToolTip.SelfHeaderYours': 'Esta columna indica su propia evaluación del desempeño de su simulación. La marca de verificación indica que usted pensó que tuvo éxito, mientras que una marca en forma de cruz indica que hay margen de mejora.',
    'SessionReports.ToolTip.SelfHeaderLearners': 'Esta columna indica la evaluación del alumno de su desempeño en la simulación. La marca de verificación indica que el alumno pensó que tuvo éxito, mientras que una marca en forma de cruz indica que hay margen margen de mejora.',
    'SessionReports.ToolTip.VideoLink': 'Puede compartir con sus compañeros un enlace a su vídeo de desempeño en la simulación. Para ello, simplemente introduzca su correo electrónico en la ventana emergente. Tenga en cuenta que sus análisis no se compartirán con sus compañeros.',
    'SessionReports.ToolTip.SmartMetrics': 'Las métricas inteligentes se generan mediante el uso de algoritmos avanzados. Observe estas medidas generadas por ordenador para ver si tienen algún impacto en su seguimiento general durante varias simulaciones.',
    'SessionReports.ToolTip.MiScore': 'La puntuación del índice de Mursion (MI) es un indicador de su eficacia social durante esta conversación. Se calcula utilizando distintos datos, incluido el patrón de turnos en la conversación y su impacto en los avatares. Trate de evaluar su impacto en los avatares y aborde las conversaciones de diferentes maneras para ver cómo puede modificar de manera positiva su puntuación del MI.',
    'SessionReports.ToolTip.ListeningSpeakingTime': 'Se trata de un indicador que muestra la relación entre el tiempo que pasó hablando y el que pasó escuchando durante la conversación.',
    'SessionReports.ToolTip.Emotions': 'Emociones (Beta): actualmente estamos investigando algoritmos que nos permitan interpretar sus patrones de voz para determinar el sentimiento general. Observe estos datos para ver si tuvo una connotación positiva, negativa o neutral en sus patrones vocales con el paso del tiempo. Y lo que es más importante, observe el impacto que tuvo usted en los patrones vocales de los avatares.',
    'SessionReports.ToolTip.TurnTracking': 'La sucesión de turnos es un conjunto de medidas que arrojan luz sobre la naturaleza colaborativa y la efectividad social entre usted y los avatares.',
    'SessionReports.ToolTip.Pauses': 'Las pausas son una medida indirecta de su fluidez durante la conversación.',
    'SessionReports.ToolTip.ArticulationRate': 'Se trata de una medida del ritmo con el que usted entrega un conjunto continuo de información.',
    'SessionReports.TableHeader.Outcome': 'Resultado',
    'SessionReports.TableHeader.Self': 'Yo',
    'SessionReports.TableHeader.Avatars': 'Avatares',
    'SessionReports.TableHeader.Strategies': 'Estrategias',
    'MursionPortal.Button.Cancel': 'Cancelar',
    'Settings.Config.Heading.Industries': 'SECTORES',
    'Settings.Config.Button.New': 'Nuevo',
    'Settings.Config.Note': 'NOTA:',
    'Settings.Config.Note.Text.ConfigSettingsGlobal': 'Los ajustes de configuración son globales. Los cambios se aplicarán a todos los clientes y usuarios.',
    'Settings.Config.Heading.SimSpecialist': 'NIVELES DE ESPECIALISTA EN SIM',
    'Settings.Config.Heading.Scheduling': 'PROGRAMACIÓN',
    'Settings.Config.Heading.Scheduling.Description': '(Especialista en Sim)',
    'Settings.Config.Scheduling.MaximumFullTimeSimSpecialistHours': 'Máximo de horas de del especialista en Sim a tiempo completo',
    'Settings.Config.Scheduling.MaximumPartTimeSimSpecialistHours': 'Máximo de horas del especialista en Sim a tiempo parcial',
    'Settings.Config.Scheduling.MaximumContinuousSimSpecialistHours': 'Horas máximas continuas del especialista en Sim',
    'Settings.Config.Scheduling.LengthOfSimSpecialistRequiredBreak': 'Duración del descanso requerido por el especialista en Sim',
    'Settings.Config.Scheduling.FutureAvailabilityForSimSpecialist': 'Ventana de disponibilidad futura para especialistas en Sim',
    'Settings.Config.Scheduling.SimSpecialistShiftTime': 'Tiempo del turno del especialista en Sim',
    'Settings.Config.Scheduling.AvailableTimeBlockMaximumPerDay': 'Bloque de tiempo máximo de disponibilidad por día',
    'Settings.Config.Scheduling.AvailableTimeBlockMinimumPerDay': 'Bloque de tiempo mínimo de disponibilidad por día',
    'Settings.Config.Scheduling.AvailabilityTimeStep': 'Paso de tiempo de disponibilidad',
    'Settings.Config.Scheduling.AdvanceSSScheduleConfirmation': 'Con cuánta anticipación se debe confirmar el horario del ES',
    'Settings.Config.Scheduling.RequestUnfulfilledReminder': 'Cuándo se envía el recordatorio de «solicitud no cumplimentada»',
    'Settings.Config.Scheduling.FirstEmailReminder': 'Cuándo se envía el primer recordatorio por correo electrónico',
    'Settings.Config.Scheduling.SecondEmailReminder': 'Cuándo se envía un segundo recordatorio por correo electrónico',
    'Settings.Config.Scheduling.BookedSessionWithNoAssignedLearner': 'Cuándo se notifica una sesión reservada sin alumnos asignados',
    'Settings.Config.Scheduling.UnfulfilledSessionSwapRequestReminder': 'Cuándo se envía un recordatorio sobre la solicitud de intercambio de sesión no cumplimentada',
    'Settings.Config.Scheduling.NextSessionReminder': 'Cuándo se envía el recordatorio de la próxima sesión dependiendo del tiempo entre la creación de la sesión y la hora de inicio de la sesión programada',
    'Settings.Config.Scheduling.ReservedAvailabilityExpired': 'Cuando expiró la reserva de disponibilidad',
    'Settings.Config.Scheduling.StartTimeForConsideringMissedSession': 'Cantidad de tiempo después de la hora de inicio cuando una sesión se considera \"Perdida\"',
    'Settings.Config.Scheduling.EndTimeForConsideringMissedSession': 'Cantidad de tiempo después de la hora de finalización cuando una sesión se considera \"Perdida\"',
    'Settings.Config.Scheduling.RejoinSessionAfterError': 'El tiempo que tienen los participantes en la sesión para volver a unirse a la misma después del error',
    'Settings.Config.Scheduling.TimeDisabledForSessionScheduling': 'Cantidad de tiempo deshabilitada para la programación de sesiones',
    'Settings.Config.Scheduling.SessionTimeStep': 'Paso de tiempo de la sesión',
    'Settings.Config.Heading.ProjectAndScenario': 'PROYECTO Y ESCENARIO',
    'Settings.Config.ProjectAndScenario.MaximumProjectTimelineDuration': 'Duración máxima del cronograma del proyecto',
    'Settings.Config.ProjectAndScenario.MinimumProjectTimelineDuration': 'Duración mínima del cronograma del proyecto',
    'Settings.Config.ProjectAndScenario.MaximumScenarioTimelineDuration': 'Duración máxima del cronograma del escenario',
    'Settings.Config.ProjectAndScenario.MinimumScenarioTimelineDuration': 'Duración mínima del cronograma del escenario',
    'Settings.Config.ProjectAndScenario.MinimumSessionLength': 'Duración mínima de la sesión',
    'Settings.Config.ProjectAndScenario.MaximumSessionLength': 'Duración máxima de la sesión',
    'Settings.Config.ProjectAndScenario.MinimumSimulationLength': 'Duración mínima de la simulación',
    'Settings.Config.ProjectAndScenario.SessionLengthStep': 'Paso de duración de la sesión',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInRemoteSession': 'Número mínimo de alumnos en las sesiones remotas',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInRemoteSession': 'Número máximo de alumnos en las sesiones remotas',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInFishbowlSession': 'Número mínimo de alumnos en las sesiones de pecera local',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInFishbowlSession': 'Número máximo de alumnos en las sesiones de pecera local',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInGroupSession': 'Número mínimo de alumnos en las sesiones grupales',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInGroupSession': 'Número máximo de alumnos en las sesiones grupales',
    'Settings.Config.ProjectAndScenario.AllowedScenarioVersions': 'Versiones de escenario permitidas',
    'Settings.Config.ProjectAndScenario.DefaultScenarioVersion': 'Versión de escenario predeterminada',
    'Settings.Config.Heading.CompanyEvent': 'EVENTO DE EMPRESA',
    'Settings.Config.CompanyEvent.MaximumEventDuration': 'Duración máxima del evento',
    'Settings.Config.CompanyEvent.MinimumEventDuration': 'Duración mínima del evento',
    'Settings.Config.CompanyEvent.EventTimeStep': 'Paso de tiempo del evento',
    'Settings.Config.Heading.SessionReport': 'INFORME DE LA SESIÓN',
    'Settings.Config.SessionReport.ShowSessionReport': 'Mostrar informe de la sesión',
    'Settings.Config.Heading.FeedbackProvider': 'PROVEEDOR DE INFORMACIÓN',
    'Settings.Config.FeedbackProvider.ZenDeskSnippetKey': 'Clave de fragmento de ZenDesk',
    'Settings.Config.Heading.SimulationContent': 'CONTENIDO DE LA SIMULACIÓN',
    'Settings.Config.SimulationContent.SimulationContentFolder': 'Carpeta S3 de contenido de la simulation',
    'Settings.Config.Heading.Invitation': 'INVITACIÓN',
    'Settings.Config.Invitation.ExpireUserRegistrationInvitation': 'Cuándo caduca la invitación de registro del usuario',
    'Settings.Config.Invitation.ReminderToAcceptRegistrationInvitation': 'Cuándo se recuerda a los usuarios no registrados que acepten la invitación de registro',
    'Settings.Config.Invitation.RegistrationInvitationExpiresAfter': 'La invitación de registro vence después de',
    'Settings.Config.IndustriesButton.Modal.EditIndustry': 'Editar sector',
    'Settings.Config.NewButton.Modal.NewIndustry': 'Nuevo sector',
    'Settings.Modal.Placeholder.IndustryName': 'Nombre del sector',
    'Settings.Config.IndustriesButton.Modal.Button.UpdateIndustry': 'Actualizar sector',
    'Settings.Config.NewButton.Modal.Button.CreateIndustry': 'Crear sector',
    'Settings.Config.IndustriesButton.Modal.Button.RestoreIndustry': 'Restaurar sector',
    'Settings.Config.IndustriesButton.Modal.Button.ArchiveIndustry': 'Archivar sector',
    'MursionPortal.Modal.Heading.Name': 'Nombre',
    'Settings.Config.RestoreIndustry.Modal.ConfirmRestore': '¿Seguro que quiere restaurar este sector?',
    'Settings.Config.ArchiveIndustry.Modal.ConfirmArchive': '¿Seguro que quiere archivar este sector?',
    'Settings.Config.Description.PerWeek': '(a la semana)',
    'Settings.Config.Description.PerDay': '(al día)',
    'Settings.Config.Description.BeforeBreak': '(antes del descanso)',
    'Settings.Config.Description.Minutes': '(minutos)',
    'Settings.Config.Description.Days': '(días)',
    'Settings.Config.Description.Hours': '(horas)',
    'Settings.Config.TierButton.Modal.Button.UpdateTier': 'Actualizar nivel',
    'Settings.Config.TierButton.Modal.Heading.EditTier': 'Editar nivel',
    'Settings.Config.TierButton.Modal.Placeholder.TierName': 'Nombre del nivel',
    'MursionPortal.Button.Archive': 'Archivar',
    'Settings.Config.ArchiveIndustry.Modal.Heading.ConfirmArchive': 'Confirmar el archivo',
    'Settings.Config': 'Configuración',
    'Settings.ScenarioBank': 'Banco de escenarios',
    'Settings.Environments': 'Entornos (ML2)',
    'Settings.Avatars': 'Avatares (ML2)',
    'Settings.SSO': 'SSO',
    'Settings.Error.NoSettingsAvailable': 'No hay configuraciones disponibles',
    'MursionPortal.Error.SomethingWentWrong': 'Ups, algo salió mal.',
    'MursionPortal.Error.ReloadingPage': 'Intente volver a cargar la página. Se ha informado del error.',
    'MursionPortal.Error.WorkingHard': 'Estamos trabajando duro para solucionarlo lo antes posible.',
    'MursionPortal.Error.SupportAvailable': 'El equipo de soporte está disponible de lunes a viernes.',
    'MursionPortal.Error.TechnicalAssistance': 'Si necesita asistencia técnica, por favor, póngase en contacto con el equipo de soporte de Mursion.',
    'MursionPortal.At': 'en',
    'MursionPortal.Or': 'o',
    'Settings.Environment.Modal.PlaceHolder.EnvironmentName': 'Nombre del entorno',
    'Settings.Environment.Modal.Description': 'Descripción',
    'Settings.Environment.Modal.Industry': 'Sector',
    'Settings.Environment.Modal.Picture': 'Imagen',
    'MursionPortal.Button.Delete': 'Borrar',
    'Settings.Environment.Modal.Button.UploadFile': 'Subir archivo',
    'Settings.Environment.Button.CreateEnvironment': 'Crear entorno ',
    'Settings.Environment.ModalHeader.NewEnvironment': 'Nuevo entorno',
    'Settings.Environment.ModalHeader.Environment': 'Entorno',
    'Settings.Environment.ModalHeader.Archived': '(Archivado)',
    'Settings.Environment.ModalHeader.EditEnvironment': 'Editar entorno',
    'Settings.Environment.Modal.Button.CreateEnvironment': 'Crear entorno',
    'Settings.Environment.Modal.Button.UpdateEnvironment': 'Actualizar entorno',
    'Settings.Environment.Modal.ArchiveEnvironmentText': '¿Seguro que quiere archivar este entorno?',
    'Settings.Environment.Modal.RestoreEnvironmentText': '¿Seguro que quiere restaurar este entorno?',
    'MursionPortal.Button.Restore': 'Restaurar',
    'MursionPortal.Total': 'TOTAL',
    'MursionPortal.Text.Total': 'TOTAL',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportData': 'Exportar datos',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportNotAvailable': 'La exportación no está disponible',
    'MursionPortal.Button.ExportToEmail': 'Exportar a correo electrónico',
    'MursionPortal.WarningMessage.RequestNotImplemented': 'La solicitud de datos aún no se ha implementado',
    'MursionPortal.Table.ColumnHeader.Client': 'Cliente',
    'MursionPortal.Table.ColumnHeader.Provider': 'Proveedor',
    'MursionPortal.Table.ColumnHeader.Project': 'Proyecto',
    'MursionPortal.Table.ColumnHeader.Scenario': 'Escenario',
    'MursionPortal.Table.ColumnHeader.SoftwareVersion': 'Versión del «software»',
    'MursionPortal.Table.ColumnHeader.DeliveryMode': 'Modo de entrega',
    'MursionPortal.Table.ColumnHeader.SessionStart': 'Inicio de la sesión',
    'MursionPortal.Table.ColumnHeader.SessionEnd': 'Fin de la sesión',
    'MursionPortal.Table.ColumnHeader.LearnerStatus': 'Estado del alumno',
    'MursionPortal.Table.ColumnHeader.SessionStatus': 'Estado de la sesión',
    'MursionPortal.Table.ColumnHeader.LearnerName': 'Nombre del alumno',
    'MursionPortal.Table.ColumnHeader.LearnerEmail': 'Correo electrónico del alumno',
    'MursionPortal.Table.LearnerEmail.HoverTitle.CopyEmail': 'Copiar correo electrónico',
    'MursionPortal.Table.LearnerEmail.HoverTitle.UserEmail': 'Correo electrónico del usuario',
    'MursionPortal.Table.ColumnHeader.SessionId': 'ID de la sesión',
    'MursionPortal.Table.SessionId.HoverTitle.CopySessionId': 'Copiar ID de la sesión',
    'MursionPortal.Table.ColumnHeader.SessionReport': 'Informe de la sesión',
    'MursionPortal.Table.ColumnHeader.SimSpecialistName': 'Nombre del especialista en Sim',
    'MursionPortal.Table.ColumnHeader.LearnerTimeSpent': 'Tiempo empleado por el alumno',
    'MursionPortal.Table.HoverTitle.SessionSummary': 'Resumen de la sesión',
    'MursionPortal.EmptyMessage.NoSessionsYet': 'Todavía no hay ninguna sesión',
    'MursionPortal.Button.ViewReport': 'Ver informe',
    'Mursion.Portal.Status.Requested': 'Solicitado',
    'Mursion.Portal.Status.Pending': 'Pendiente',
    'Mursion.Portal.Status.PendingSwap': 'Pendiente (intercambio)',
    'Mursion.Portal.Status.PreBooked': 'Prerreserva',
    'Mursion.Portal.Status.Booked': 'Reservado',
    'Mursion.Portal.Status.Active': 'Activo',
    'Mursion.Portal.Status.Complete': 'Completado',
    'Mursion.Portal.Status.Error': 'Error',
    'Mursion.Portal.Status.Missed': 'Perdido',
    'Mursion.Portal.Status.NeedsReview': 'Necesita evaluación',
    'Mursion.Portal.Status.Cancelled': 'Cancelado',
    'Dashboard.UsersActivity.Filter.Roles.Placeholder': 'Buscar...',
    'Settings.ScenarioBank.Table.ColumnHeader.Name': 'Nombre',
    'MursionPortal.DeliveryMode': 'Modo de entrega',
    'Settings.ScenarioBank.Table.ColumnHeader.SessionSize': 'Tamaño de la sesión',
    'MursionPortal.SessionLength': 'Duración de la sesión',
    'Settings.ScenarioBank.Table.ViewEditScenario': 'Ver/editar escenario',
    'Settings.ScenarioBank.Table.ThereAreNoScenarios': 'Todavía no hay ningún escenario',
    'MursionPortal.Capitalized.Total': 'TOTAL',
    'Settings.ScenarioBank.Form.NewScenario': 'Nuevo escenario',
    'Settings.ScenarioBank.Form.Scenario': 'Escenario',
    'Settings.ScenarioBank.Form.EditScenario': 'Editar escenario',
    'Settings.ScenarioBank.Form.Save': 'Guardar',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenario': '¿Seguro que quiere archivar este escenario?',
    'Settings.ScenarioBank.Form.ConfirmRestoreScenario': '¿Seguro que quiere restaurar este escenario?',
    'Settings.ScenarioBank.Button.CreateScenario': 'Crear escenario ',
    'MursionPortal.ShortLabel.Minutes': 'min',
    'Settings.ScenarioBank.Modal.Scenarios': 'Escenario(s)',
    'Settings.ScenarioBank.Modal.CompleteProjectInfoFirst': 'Complete primero la información del proyecto',
    'Settings.ScenarioBank.Modal.ScenarioName': 'Nombre del escenario',
    'Settings.ScenarioBank.Modal.Placeholder.EnterScenarioName': 'Introducir el nombre del escenario',
    'Settings.ScenarioBank.Modal.Outcome': 'Resultado',
    'Settings.ScenarioBank.Modal.Strategies': 'Estrategias',
    'Settings.ScenarioBank.Modal.Attachments': 'Archivos adjuntos',
    'Settings.ScenarioBank.Modal.SoftwareVersion': 'Versión del «software»',
    'Settings.ScenarioBank.Modal.WeAlreadyHaveSessions': 'Ya tenemos sesiones para este escenario',
    'Settings.ScenarioBank.Modal.SelectDeliveryMode': 'Seleccionar un modo de entrega',
    'Settings.ScenarioBank.Modal.ShouldBeAtLeast': 'Debe ser de al menos {minutes} minutos.',
    'Settings.ScenarioBank.Modal.SelectSessionLength': 'Seleccionar una duración de la sesión',
    'Settings.ScenarioBank.Modal.SessionSize': 'Tamaño de la sesión',
    'Settings.ScenarioBank.Modal.Learner': 'Alumno',
    'Settings.ScenarioBank.Modal.Learners': 'Alumnos',
    'Settings.ScenarioBank.Modal.SelectSessionSize': 'Seleccionar un tamaño de sesión',
    'MursionPortal.SimulationContent': 'Contenido de la simulación',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContent': 'Este es el contenido artístico en 3D de los entornos y avatares que se utilizarán en la simulación. El contenido de esta carpeta se muestra a continuación en la sección titulada «paquete de contenido».',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoSimulation': 'El escenario no tiene contenido de simulación asignado',
    'Settings.ScenarioBank.Modal.SelectSimulationContentForUse': 'Seleccionar el contenido de la simulación para su uso',
    'Settings.ScenarioBank.Modal.Label.ScenarioOutcome': 'Resultado del escenario',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnOutcome': 'Introducir un resultado',
    'Settings.ScenarioBank.Modal.Description': 'Descripción',
    'Settings.ScenarioBank.Modal.NoDescriptionSpecified': 'No hay ninguna descripción especificada',
    'Settings.ScenarioBank.Modal.ScenarioDescription': 'Descripción del escenario',
    'Settings.ScenarioBank.Modal.AllowedCharactersLimit': 'Se ha superado el límite de caracteres permitidos ({maxLength} caracteres)',
    'Settings.ScenarioBank.Modal.ScenarioStrategies': 'Estrategias del escenario',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnItem': 'Introducir un elemento',
    'Settings.ScenarioBank.Modal.Attachments.DragAndDrop': 'Arrastre y suelte algunos archivos aquí, o haga clic para seleccionar los archivos',
    'Settings.ScenarioBank.Modal.Attachments.UploadedMaximumFiles': 'Ha subido el número máximo disponible de archivos {maxFiles}.',
    'Settings.ScenarioBank.Modal.Attachments.UploadedFiles': 'Archivos subidos,',
    'Settings.ScenarioBank.Modal.CertifiedSimSpecialists': 'Especialistas certificados en Sim',
    'Settings.ScenarioBank.Modal.NoCertifiedSimSpecialist': 'No hay ningún especialista certificado en Sim',
    'Settings.ScenarioBank.Modal.Button.Add': 'Añadir ',
    'Settings.ScenarioBank.Modal.Edit': 'Editar',
    'Settings.ScenarioBank.Modal.AddAttachmentsToScenario': 'Añadir archivos adjuntos al escenario',
    'MursionPortal.Cancel': 'Cancelar',
    'MursionPortal.OK': 'OK',
    'Settings.ScenarioBank.Modal.FileFormatsSupported': 'Se admiten los siguientes formatos:',
    'Settings.ScenarioBank.Modal.Images': 'Imágenes',
    'Settings.ScenarioBank.Modal.Videos': 'Vídeos',
    'Settings.ScenarioBank.Modal.Audios': 'Audios',
    'Settings.ScenarioBank.Modal.Documents': 'Documentos',
    'Settings.ScenarioBank.Modal.New': '(nuevo)',
    'Settings.ScenarioBank.Modal.FileSizeMustNotExceed': 'El tamaño del archivo no debe exceder {size}',
    'Settings.ScenarioBank.Modal.CertifySimSpecialists': 'Certificar especialistas en Sim',
    'MursionPortal.Confirm': 'Confirmar',
    'MursionPortal.Delete': 'Borrar',
    'Settings.ScenarioBank.Modal.Archived': '(Archivado)',
    'Settings.ScenarioBank.Modal.Restore': 'Restaurar',
    'Settings.ScenarioBank.Modal.ConfirmArchive': 'Confirmar el archivo',
    'Settings.ScenarioBank.Modal.Archive': 'Archivar',
    'Settings.ScenarioBank.Modal.ConfirmRestore': 'Confirmar la restauración',
    'MursionPortal.PleaseWait': 'Por favor, espere...',
    'MursionPortal.Loading': 'Cargando...',
    'Settings.ScenarioBank.Modal.Attachments.FileTypeNotSupported': 'El tipo de archivo {extensions} no es compatible',
    'Settings.ScenarioBank.Modal.Attachments.FileTypesNotSupported': 'Los tipos de archivo {extensions} no son compatibles',
    'Settings.ScenarioBank.Modal.Attachments.FileExceedsSizeLimit': 'El archivo {fileNames} supera el límite de tamaño',
    'Settings.ScenarioBank.Modal.Attachments.FilesExceedSizeLimit': 'Los archivos {fileNames} superan el límite de tamaño',
    'MursionPortal.Label.Total': 'TOTAL',
    'MursionPortal.Button.HoverTitle.ExportData': 'Exportar datos',
    'MursionPortal.Button.HoverTitle.ExportNotAvailable': 'La exportación no está disponible',
    'MursionPortal.Heading.Filters': 'FILTROS:',
    'Dashboard.ProjectsTable.Checkbox.OnlyBillable': 'Solo facturable',
    'MursionPortal.Filters.SoftwareVersion': 'Versión del «software»',
    'MursionPortal.Filters.PlaceHolder.SearchVersion': 'Buscar versión...',
    'MursionPortal.Filters.Status': 'Estado',
    'MursionPortal.Filters.PlaceHolder.SearchStatus': 'Buscar estado...',
    'Dashboard.ProjectsTable.ColumnHeader.Provider': 'Proveedor',
    'Dashboard.ProjectsTable.ColumnHeader.Status': 'Estado',
    'Dashboard.ProjectsTable.ColumnHeader.StarAt': 'Empezar a las',
    'Dashboard.ProjectsTable.ColumnHeader.EndAt': 'Terminar a las',
    'Dashboard.ProjectsTable.ColumnHeader.Billable': 'Facturable',
    'Dashboard.ProjectsTable.ColumnHeader.CancellationWindow': 'Ventana de cancelación',
    'MursionPortal.Table.ColumnHeader.Scenarios': 'Escenarios',
    'MursionPortal.Table.ColumnHeader.Teams': 'Equipos',
    'MursionPortal.Table.ColumnHeader.SessionsScheduled': 'Sesiones programadas',
    'MursionPortal.Table.ColumnHeader.SessionsCompleted': 'Sesiones completadas',
    'MursionPortal.Table.ColumnHeader.SessionsMissed': 'Sesiones perdidas',
    'MursionPortal.Table.ColumnHeader.LateCancellations': 'Cancelaciones tardías',
    'MursionPortal.Table.ColumnHeader.Learners': 'Alumnos',
    'Dashboard.ProjectsTable.ColumnHeader.SessionsRecorded': 'Sesiones grabadas',
    'MursionPortal.Table.ColumnHeader.SessionsError': 'Error de sesiones',
    'Dashboard.ProjectsTable.ColumnHeader.ContractedHours': 'Horas contratadas',
    'Dashboard.ProjectsTable.ColumnHeader.UtilizedHours': 'Horas utilizadas',
    'Dashboard.ProjectsTable.ColumnHeader.RemainingHours': 'Horas restantes',
    'Dashboard.ProjectsTable.ColumnHeader.CompletedHours': 'Horas completadas',
    'Dashboard.ProjectsTable.ColumnHeader.MissedHours': 'Horas perdidas',
    'Dashboard.ProjectsTable.HoverTitle.ViewEditProject': 'Ver/editar el proyecto',
    'Dashboard.ProjectsTable.EmptyMessage.NoProjectsYet': 'Todavía no hay ningún proyecto',
    'MursionPortal.Button.Clear': 'Limpiar',
    'Dashboard.Projects.AlertMessage.DataRequestNotImplemented': 'La solicitud de datos aún no se ha implementado',
    'MursionPortal.SelectorMenu.SelectedCountOfTotal': '{formattedSelectedCount} seleccionados de {formattedTotal}',
    'MursionPortal.Dashboard.Table.Modal.Filter.Teams': 'Equipos',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterTeamName': 'Introducir nombre del equipo',
    'MursionPortal.Dashboard.Table.Modal.TeamsEmptyMessage': 'No se ha encontrado ningún equipo',
    'MursionPortal.Dashboard.Table.Modal.Filter.NumberOfLearners': 'N.º de alumnos',
    'MursionPortal.Dashboard.Table.Modal.Filter.Scenarios': 'Escenarios',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterScenarioName': 'Introducir el nombre del escenario',
    'MursionPortal.Dashboard.Table.Modal.ScenariosEmptyMessage': 'No se ha encontrado ningún escenario',
    'MursionPortal.Dashboard.Table.Modal.Filter.Name': 'Nombre',
    'MursionPortal.Dashboard.Table.Modal.Filter.Status': 'Estado',
    'MursionPortal.Dashboard.Table.Modal.Filter.DeliveryMode': 'Modo de entrega',
    'MursionPortal.Dashboard.Table.Modal.Filter.Learners': 'Alumnos',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterLearnerName': 'Introducir el nombre del alumno',
    'MursionPortal.Dashboard.Table.Modal.LearnersEmptyMessage': 'No se ha encontrado ningún alumno',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsScheduled': 'Sesiones programadas',
    'MursionPortal.Dashboard.Table.Modal.SessionsEmptyMessage': 'No se ha encontrado ninguna sesión',
    'MursionPortal.Dashboard.Table.Modal.Filter.ScenarioName': 'Nombre del escenario',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionDateTime': 'Fecha/hora de la sesión',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsCompleted': 'Sesiones completadas',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsError': 'Error de sesiones',
    'Dashboard.ProjectsActivityTable.Modal.Filter.SessionsRecorded': 'Sesiones grabadas',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsMissed': 'Sesiones perdidas',
    'MursionPortal.Dashboard.Table.Modal.Filter.LateCancellations': 'Cancelaciones "tardías"',
    'MursionPortal.Dashboard.Table.Modal.Filter.CancelledBy': 'Cancelado por',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateScheduled': 'Fecha programada',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateCancelled': 'Fecha cancelada',
    'MursionPortal.ProjectStatus.Active': 'Activo',
    'MursionPortal.ProjectStatus.Completed': 'Completado',
    'MursionPortal.ProjectStatus.Archived': 'Archivado',
    'Settings.Avatars.Modal.Name': 'Nombre',
    'Settings.Avatars.Modal.PlaceHolder.AvatarName': 'Nombre del avatar',
    'Settings.Avatars.Modal.Description': 'Descripción',
    'MursionPortal.Name': 'Nombre',
    'MursionPortal.Description': 'Descripción',
    'MursionPortal.Environments': 'Entornos',
    'Settings.Avatars.TableHover.ViewEditAvatar': 'Ver/editar el avatar',
    'Settings.Avatars.NoAvatarText': 'Todavía no hay ningún avatar',
    'Settings.Avatars.ModalHeader.NewAvatar': 'Nuevo avatar',
    'Settings.Avatars.ModalHeader.Avatar': 'Avatar',
    'Settings.Avatars.ModalHeader.EditAvatar': 'Editar avatar',
    'Settings.Avatars.Modal.Button.CreateAvatar': 'Crear avatar',
    'Settings.Avatars.Modal.Button.UpdateAvatar': 'Actualizar avatar',
    'Settings.Avatars.Modal.ArchiveAvatarText': '¿Seguro que quiere archivar este avatar?',
    'Settings.Avatars.Modal.RestoreAvatarText': '¿Seguro que quiere restaurar este avatar?',
    'Settings.Avatars.Modal.Button.Edit': 'Editar',
    'Settings.Avatars.Modal.EditButton.Accept': 'Aceptar',
    'Settings.Avatars.Modal.NoSelectedEnvironments': 'No hay ningún entorno seleccionado',
    'Settings.Avatars.Modal.NoEnvironmentsSetForSelectedIndustry': 'No hay ningún entorno establecido para el sector seleccionado.',
    'Settings.Avatars.Button.CreateAvatar': 'Crear avatar ',
    'MursionPortal.Dashboard': 'Panel de control',
    'MursionPortal.Tutorial': 'Tutorial',
    'MursionPortal.Eula': 'Contrato de licencia para usuario final (EULA)',
    'MursionPortal.Companies': 'Empresas',
    'MursionPortal.SessionFlow': 'Flujo de la sesión',
    'MursionPortal.SessionReport': 'Informe de la sesión',
    'MursionPortal.AssetSync': 'Sincronización de activos',
    'MursionPortal.Users': 'Usuarios',
    'MursionPortal.AppDownload': 'Descargar la aplicación',
    'MursionPortal.Calendar': 'Calendario',
    'MursionPortal.Clients': 'Clientes',
    'MursionPortal.Sessions': 'Sesiones',
    'MursionPortal.Trainings': 'Formaciones',
    'MursionPortal.Requests': 'Solicitudes',
    'MursionPortal.Settings': 'Configuración',
    'MursionPortal.ArtDesigner': 'Art Designer',
    'MursionPortal.ArtDesigner.BodyContents': 'We\'ve opened a new browser tab with the Mursion Designer application.',
    'MursionPortal.Projects': 'Proyectos',
    'MursionPortal.Teams': 'Equipos',
    'MursionPortal.Reports': 'Informes',
    'MursionPortal.Downloads': 'Descargas',
    'MursionPortal.Upcoming': 'próximo',
    'MursionPortal.Auth': 'OAuth',
    'Settings.Avatars.Modal.ErrorMessage.ItemsNotAvailable': 'No hay más de {max} elementos disponibles para elegir',
    'Settings.Avatars.Modal.ErrorMessage.ItemNotAvailable': 'No hay más de {max} elemento disponible para elegir',
    'MursionPortal.Industry': 'Sector',
    'Settings.Environment.TableHover.ViewEditEnvironment': 'Ver/editar el entorno',
    'Settings.Environment.NoEnvironmentText': 'Todavía no hay ningún entorno',
    'Projects.Button.CreateProject': 'Crear proyecto ',
    'Projects.ListHeader.CreationDate': 'Fecha de creación',
    'Projects.ListHeader.ProjectManagers': 'Gestores de proyectos',
    'MursionPortal.Capitalized.Active': 'ACTIVO',
    'MursionPortal.Capitalized.Completed': 'COMPLETADO',
    'MursionPortal.Capitalized.Archived': 'ARCHIVADO',
    'MursionPortal.Capitalized.All': 'TODOS',
    'Projects.ProjectCard.BackToClient': 'Volver al cliente',
    'Projects.ProjectCard.NoProjectsFound': 'No se ha encontrado ningún proyecto',
    'Projects.ProjectCard.PlaceHolder.EnterProjectName': 'Introducir el nombre del proyecto',
    'Projects.ProjectCard.ProjectsList': 'LISTA DE PROYECTOS',
    'MursionPortal.Capitalized.Project': 'PROYECTO',
    'MursionPortal.Scenarios': 'Escenarios',
    'Projects.ScenarioList.ThereAreNoScenarios': 'Todavía no hay ningún escenario.',
    'Projects.ScenarioList.NoScenariosFound': 'No se ha encontrado ningún escenario.',
    'Projects.ScenarioList.Button.Draft': 'Borrador',
    'MursionPortal.Finalized': 'Finalizado',
    'MursionPortal.Started': 'Iniciado',
    'MursionPortal.Finished': 'Finalizado',
    'MursionPortal.Archived': 'Archivado',
    'MursionPortal.EnterScenarioName': 'Introducir el nombre del escenario',
    'Projects.AllProjects': 'todos los proyectos',
    'MursionPortal.Label.LowerCase.Archived': 'archivado',
    'MursionPortal.Label.Active': 'Activo',
    'MursionPortal.Label.Completed': 'Completado',
    'Projects.Tab.Basic': 'Básico',
    'MursionPortal.Heading.ProjectName': 'Nombre del proyecto',
    'MursionPortal.Label.Created': 'Creado',
    'MursionPortal.Label.Status': 'Estado',
    'MursionPortal.Label.Learners': 'Alumnos',
    'MursionPortal.Label.LearnersCount': '{count} alumnos',
    'Projects.Tab.NoLearners': 'Sin alumnos',
    'MursionPortal.Label.SoftwareVersion': 'Versión del «software»',
    'Projects.ProjectInfo.Label.Non-Billable': 'No facturable',
    'Projects.ProjectInfo.Label.Contracted': 'Contratado',
    'Projects.ProjectInfo.Label.CancellationWindow': 'Ventana de cancelación',
    'MursionPortal.Header.Recording': 'Grabación',
    'Projects.ProjectInfo.Checkbox.AllowRecording': 'Permitir grabación',
    'MursionPortal.Label.VideoRecording': 'Grabación en vídeo',
    'MursionPortal.Label.Allowed': 'Permitido',
    'MursionPortal.Label.Disallowed': 'Desautorizado',
    'MursionPortal.Label.RecordedVideosGoBackTo': 'Los vídeos grabados se remontan a:',
    'MursionPortal.Checkbox.RecordingPermissionLiesWithLearner': 'El permiso de grabación recae en el alumno',
    'MursionPortal.Label.LiesWithLearner': 'Recae en el alumno',
    'Projects.ProjectInfo.Label.Enabled': 'Habilitado',
    'Projects.ProjectInfo.Label.Disabled': 'Deshabilitado',
    'MursionPortal.Heading.ProjectManagers': 'Gestores de proyectos',
    'MursionPortal.Heading.Tags': 'Etiquetas',
    'MursionPortal.Button.AreYouSureEditProject': '¿Seguro que quiere editar este proyecto?',
    'MursionPortal.Button.SaveProjectInfo': 'Guardar la información del proyecto',
    'Projects.Tab.Utilization': 'Utilización',
    'MursionPortal.Label.Loading': 'Cargando...',
    'Projects.ProjectInfo.Label.NoProjectManagers': 'No hay ningún gerente de proyecto',
    'Projects.ProjectInfo.Label.NoSelectedProjectManagers': 'No hay ningún gerente de proyecto seleccionado',
    'MursionPortal.Label.ProjectManagers': 'Gestores de proyectos',
    'MursionPortal.Label.RecordingPermission': 'Permiso de grabación:',
    'Projects.Utilization.Label.UtilizedSessions': 'Sesiones utilizadas:',
    'Projects.Utilization.Label.UtilizedMissedSessions': 'Sesiones perdidas utilizadas:',
    'Projects.Utilization.Label.UtilizedCancelledSessions': 'Sesiones canceladas utilizadas:',
    'Projects.Utilization.Label.UtilizedDuration': 'Duración utilizada:',
    'Projects.Utilization.Label.RemainingDuration': 'Duración restante:',
    'MursionPortal.ScenarioListItem.Label.Timeline': 'cronograma',
    'MursionPortal.ScenarioListItem.Label.SessionLength': 'duración de la sesión',
    'MursionPortal.ScenarioListItem.Label.SessionSize': 'tamaño de la sesión',
    'MursionPortal.ScenarioListItem.Label.DeliveryMode': 'modo de entrega',
    'MursionPortal.ScenarioCard.BackToProject': 'Volver al proyecto',
    'MursionPortal.ScenarioCard.NoScenariosFound': 'No se ha encontrado ningún escenario',
    'MursionPortal.Placeholder.EnterScenarioName': 'Introducir el nombre del escenario',
    'MursionPortal.ScenarioCard.Label.ScenariosList': 'LISTA DE ESCENARIOS',
    'MursionPortal.ScenarioCard.Header.Scenario': 'ESCENARIO',
    'MursionPortal.ScenarioCard.Header.ChooseTypeOfEvent': 'Elegir el tipo de evento a programar',
    'MursionPortal.Label.Session': 'Sesión',
    'MursionPortal.Label.Training': 'Formación',
    'MursionPortal.Button.Confirm': 'Confirmar',
    'MursionPortal.Button.Continue': 'Continuar',
    'MursionPortal.Button.Schedule': 'Programar',
    'MursionPortal.Label.Draft': 'Borrador',
    'MursionPortal.Label.Finalized': 'Finalizado',
    'MursionPortal.Label.Started': 'Iniciado',
    'MursionPortal.Label.Finished': 'Finalizado',
    'MursionPortal.Label.Archived': 'Archivado',
    'MursionPortal.Label.Cancel': 'cancelar',
    'MursionPortal.Label.Edit': 'Editar',
    'MursionPortal.Label.CancelEdit': 'Cancelar la edición',
    'MursionPortal.Label.EditScenario': 'Editar escenario',
    'MursionPortal.Label.EditScenarioName': 'Editar el nombre del escenario',
    'MursionPortal.ScenarioCard.Label.ApplyNameChange': 'Aplicar el cambio de nombre',
    'MursionPortal.ScenarioCard.Label.Apply': 'aplicar',
    'MursionPortal.ScenarioCard.Tab.Info': 'INFO',
    'MursionPortal.ScenarioCard.Tab.Learners': 'ALUMNOS',
    'MursionPortal.ScenarioCard.Tab.Sessions': 'SESIONES',
    'MursionPortal.ScenarioCard.ConfirmButton.AreYouSureArchiveScenario': '¿Seguro que quiere archivar este escenario? También se archivarán todos los datos relacionados.',
    'MursionPortal.ScenarioCard.ConfirmButton.ArchiveScenario': 'Archivar el escenario',
    'MursionPortal.Label.Delivery': 'Entrega',
    'MursionPortal.ScenarioInfo.Header.ArchivedScenario': 'ESCENARIO ARCHIVADO',
    'MursionPortal.ScenarioInfo.Button.YouMayOnlyEditTeams': 'Solo puede editar equipos y cronogramas si finaliza un escenario',
    'MursionPortal.ScenarioInfo.Button.FinalizeScenario': 'Finalizar el escenario',
    'MursionPortal.ScenarioInfo.Button.SaveScenario': 'Guardar el escenario',
    'MursionPortal.ScenarioInfo.Button.CreatePlanning': 'Crear planificación ',
    'MursionPortal.ScenarioInfo.Label.SimLength': 'Duración de la Sim',
    'MursionPortal.Header.Outcome': 'Resultado',
    'MursionPortal.Header.Strategies': 'Estrategias',
    'MursionPortal.Header.Attachments': 'Archivos adjuntos',
    'MursionPortal.ScenarioInfo.Button.SaveScenarioInfo': 'Guardar la información del escenario',
    'MursionPortal.Label.ContentBundle': 'Paquete de contenido',
    'MursionPortal.ScenarioInfo.Button.ShowLess': 'Mostrar menos',
    'MursionPortal.ScenarioInfo.Button.ShowMore': 'Mostrar más',
    'MursionPortal.AssetSettings.Label.CurrentScenarioNoAssetSettings': 'El escenario actual no tiene ninguna configuración de activos.',
    'MursionPortal.Teams.Label.NoSelectedTeams': 'No hay ningún equipo seleccionado',
    'MursionPortal.Teams.Label.ClientHasNoAssignedTeams': 'El cliente no tiene ningún equipo asignado',
    'MursionPortal.Label.Teams': 'Equipos',
    'MursionPortal.Label.NoTeamsSpecified': 'No hay ningún equipo especificado',
    'MursionPortal.Label.Capitalized.Draft': 'BORRADOR',
    'MursionPortal.Label.Capitalized.Finalized': 'FINALIZADO',
    'MursionPortal.Label.Capitalized.Started': 'INICIADO',
    'MursionPortal.Label.Capitalized.Finished': 'TERMINADO',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioStillInDevelopmentStage': 'El escenario aún está en etapa de desarrollo',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioDeployedTimelineUpcoming': 'El escenario está implementado, pero el cronograma aún está pendiente',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioActiveLearnersCanSchedule': 'El escenario está activo y los alumnos pueden programar sesiones',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioIsCompleted': 'El escenario está completado',
    'MursionPortal.Button.Add': 'Añadir ',
    'MursionPortal.Label.Warning': 'Advertencia',
    'MursionPortal.SessionWizard.Label.UnableToCreateSession': 'No se puede crear una sesión para el {failedParam} seleccionado.',
    'MursionPortal.SessionWizard.Label.PleaseContinueToFindAnother': 'Pulse Continuar para encontrar otro <code>{failedParam}</code>.',
    'MursionPortal.SessionWizard.Label.ScheduleSession': 'Programar sesión',
    'MursionPortal.SessionWizard.Label.ScheduleTraining': 'Programar formación',
    'MursionPortal.SessionWizard.Label.AddNotes': 'Añadir notas',
    'MursionPortal.SessionWizard.Label.SelectSimulationContentForUse': 'Seleccionar el contenido de la simulación para su uso',
    'MursionPortal.SessionWizard.Label.SelectStep': 'Seleccionar {step}',
    'MursionPortal.SessionWizard.Label.Expired': 'caducado',
    'MursionPortal.SessionWizard.ConfirmButton.AreYouSureCreateEmergencySession': '¿Seguro que quiere crear una sesión de emergencia?',
    'MursionPortal.SessionWizard.Button.Create': 'Crear',
    'MursionPortal.Button.Next': 'Siguiente',
    'MursionPortal.SessionWizard.Label.RemainingTimeToBook': 'pendiente de reservar este bloque de tiempo antes de que expire la retención.',
    'MursionPortal.SessionWizard.Label.TimeBlockHoldExpired': 'La retención del bloque de tiempo ha caducado.',
    'MursionPortal.Label.Date': 'Fecha',
    'MursionPortal.Label.Time': 'Hora',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailableClickSubmit': 'Ese tiempo ya no está disponible, ¿le gustaría seleccionar una opción diferente? Regrese para elegir una opción disponible o haga clic en enviar para solicitar la opción existente.',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailable': 'Ese tiempo ya no está disponible, ¿le gustaría seleccionar una opción diferente? Regrese para elegir una opción disponible.',
    'MursionPortal.SessionWizard.Button.GoBack': 'Volver',
    'MursionPortal.Button.Submit': 'Enviar',
    'MursionPortal.SessionWizard.Label.NoAvailableClients': 'No hay ningún cliente disponible',
    'MursionPortal.SessionWizard.Label.NoAvailableProjects': 'No hay ningún proyecto disponible',
    'MursionPortal.SessionWizard.Label.NoAvailableScenarios': 'No hay ningún escenario disponible',
    'MursionPortal.SessionWizard.Label.PleaseSelectScenario': 'Por favor, seleccione primero el escenario',
    'MursionPortal.SessionWizard.Label.NoImage': 'No hay ninguna imagen',
    'MursionPortal.SessionWizard.EmptyMessage.NoAvailableSImSpecialists': 'No hay ningún especialista en Sim disponible',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoContentBundles': 'El escenario no tiene ningún paquete de contenido asignado.',
    'MursionPortal.SessionWizard.Option.SelectAnAsset': 'Seleccionar un activo',
    'MursionPortal.SimSpecialistStep.Label.NoSimSpecialistsAvailable': 'No hay ningún especialista en SIM disponible para el tiempo seleccionado. La sesión se asignará bajo pedido.',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainees': 'Solo puede añadir hasta {maxLearners} aprendices para este tipo de sesión.',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainee': 'Solo puede añadir hasta {maxLearners} aprendiz para este tipo de sesión.',
    'MursionPortal.EmptyMessage.NoUsersSelected': 'No se ha seleccionado ningún usuario',
    'MursionPortal.Placeholder.Search': 'Buscar',
    'MursionPortal.SsLearnersStep.Label.NoTraineeToSelect': 'No hay ningún aprendiz para seleccionar',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearners': 'Solo puede añadir hasta {maxLearners} alumnos para este tipo de sesión.',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearner': 'Solo puede añadir hasta {maxLearners} alumno para este tipo de sesión.',
    'MursionPortal.LearnersStep.Label.NoLearnersToSelect': 'No hay ningún alumno para seleccionar',
    'MursionPortal.LearnersStep.Label.NoUsersSelected': 'No se ha seleccionado ningún usuario',
    'MursionPortal.LearnersStep.Option.AllTeams': 'Todos los equipos',
    'MursionPortal.LearnersStep.Button.AddEntireTeam': 'Añadir el equipo completo',
    'MursionPortal.LearnersStep.Note.AllowedSessionSIze': 'El tamaño de sesión permitido es igual a {maxLearners}. Seleccionar alumnos específicos',
    'MursionPortal.LearnersStep.Checkbox.OnlyUnauthorizedUsers': 'Solo se unirán los usuarios no autenticados',
    'MursionPortal.NotesStep.SessionNotes': 'Notas de la sesión',
    'MursionPortal.NotesStep.ErrorMessage.AllowedLimitExceeded': 'Se ha superado el límite de caracteres permitidos ({limit} caracteres)',
    'MursionPortal.Button.LoadMore': 'Mostrar más',
    'MursionPortal.Label.Trainer': 'Formador',
    'MursionPortal.Label.Description': 'Descripción',
    'MursionPortal.Label.Outcome': 'Resultado',
    'MursionPortal.Label.Strategies': 'Estrategias',
    'MursionPortal.Label.DeliveryMode': 'Modo de entrega',
    'MursionPortal.Label.SessionLength': 'Duración de la sesión',
    'MursionPortal.Label.NoDescriptionProvided': 'No se ha proporcionado ninguna descripción',
    'MursionPortal.Label.NoOutcomeProvided': 'No se ha proporcionado ningún resultado',
    'MursionPortal.Label.NoStrategiesProvided': 'No se ha proporcionado ninguna estrategia',
    'MursionPortal.Placeholder.Dotted.Search': 'Buscar...',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocks': 'No hay ningún bloque de tiempo disponible',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocksInRange': 'No hay ningún bloque de tiempo disponible en el rango seleccionado',
    'MursionPortal.TimeStep.Footer.BySelectingThisTimeBlock': 'Al seleccionar este bloque de tiempo, estará solicitando un tiempo de sesión que no está disponible actualmente. Haremos todo lo posible para cumplir con esta solicitud si continúa o si desliza el interruptor situado debajo a la posición de «Off», para seleccionar únicamente entre los bloques de tiempo disponibles y confirmados.',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOff': 'Deslice el botón a «Off» para seleccionar únicamente entre los bloques de tiempo disponibles.',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOn': 'Deslice el botón a «On» para solicitar un bloque de tiempo adicional.',
    'MursionPortal.Label.LowerCase.Client': 'cliente',
    'MursionPortal.Label.Project': 'proyecto',
    'MursionPortal.Label.Scenario': 'escenario',
    'MursionPortal.Projects.NoProjectsFound': 'No se ha encontrado ningún proyecto.',
    'MursionPortal.Projects.YourTeamsNotAssigned': 'Sus equipos aún no han sido asignados a ningún proyecto.',
    'MursionPortal.Projects.NoProjectsCreated': 'Todavía no se ha creado ningún proyecto.',
    'MursionPortal.Teams.NoTeamsFound': 'No se ha encontrado ningún equipo.',
    'MursionPortal.Teams.YouHaveNotBeen': 'Todavía no se le ha añadido a ningún equipo.',
    'MursionPortal.Teams.YouHaveNoTeams': 'Todavía no tiene ningún equipo.',
    'MursionPortal.Teams.NoTeamsCreated': 'Todavía no se ha creado ningún equipo.',
    'Settings.SSO.Modal.ConfigurationName': 'Nombre de la configuración',
    'Settings.SSO.Modal.AuthorizationEndpoint': 'Punto final de la autorización',
    'Settings.SSO.Modal.LogoutEndpoint': 'Punto final del cierre de sesión',
    'Settings.SSO.Modal.ClientID': 'ID del cliente',
    'Settings.SSO.Modal.ClientSecret': 'Secreto de cliente',
    'Settings.SSO.Modal.Scope': 'Alcance',
    'Settings.SSO.Modal.UserInfoEndpoint': 'Punto final de la información del usuario',
    'Settings.SSO.Modal.TokenEndpoint': 'Punto final del «token»',
    'Settings.SSO.Modal.TokenHTTPMethod': 'Modo HTTP del «token»',
    'Settings.SSO.Modal.UserIDClaimName': 'Nombre de la reclamación por ID del usuario',
    'Settings.SSO.Modal.EmailClaimName': 'Nombre de la reclamación por correo electrónico',
    'Settings.SSO.Modal.FirstNameClaimName': 'Nombre de la reclamación por el nombre',
    'Settings.SSO.Modal.LastNameClaimName': 'Nombre de la reclamación por los apellidos',
    'Settings.SSO.Modal.LinkAllowed': 'Enlace permitido',
    'Settings.SSO.Modal.UnlinkAllowed': 'Desvinculación permitida',
    'Settings.SSO.Modal.SingleLogoutEnabled': 'Cierre de sesión único habilitado',
    'Settings.SSO.Modal.SingleSignOnServiceEndpoint': 'Punto final del servicio de inicio de sesión único',
    'Settings.SSO.Modal.EntityID': 'ID de la entidad',
    'Settings.SSO.Modal.X509Certificate': 'Certificado X.509',
    'Settings.SSO.Button.CreateSsoConfiguration': 'Crear configuración SSO ',
    'Settings.SSO.Modal.Protocol': 'Protocolo :',
    'Settings.SSO.Modal.Type': 'Tipo :',
    'Settings.SSO.Modal.ID': 'ID :',
    'Settings.SSO.Modal.AssignedClients': 'Clientes asignados',
    'Settings.SSO.Modal.Button.DeleteSso': 'Eliminar SSO',
    'Settings.SSO.ModalHeader.EditSsoConfiguration': 'Editar la configuración SSO',
    'Settings.SSO.ModalHeader.NewSsoConfiguration': 'Nueva configuración SSO',
    'Settings.SSO.Modal.Button.CreateSsoConfiguration': 'Crear configuración SSO',
    'Settings.SSO.Modal.Button.SaveSsoConfiguration': 'Guardar configuración e SSO',
    'Settings.SSO.Modal.DeletionConfirmationText': '¿Seguro que quiere eliminar esta configuración SSO?',
    'Settings.SSO.Modal.AddButton.GroupClaimName': 'Nombre de reclamación de grupo',
    'Settings.SSO.Modal.AddButton.Team': 'Equipo',
    'Settings.SSO.Modal.AddButton.PlaceHolder.GroupClaimValue': 'Nombre de reclamación de grupo',
    'Settings.SSO.Modal.SPInitiatedSsoUrl': 'URL de SSO iniciada por el SP :',
    'Settings.SSO.Modal.Button.DownloadSPMetadata': 'Descargar metadatos de SP',
    'Settings.SSO.Modal.Button.Public': 'Público',
    'Settings.SSO.Modal.Button.Private': 'Privado',
    'Settings.SSO.Modal.ConfigurationIcon': 'Icono de configuración :',
    'Settings.SSO.Modal.PlaceHolder.EnterTeamClaimName': 'Introducir el nombre de la reclamación por equipo',
    'Settings.SSO.Modal.TeamClaimName': 'Nombre de la reclamo por equipo',
    'Settings.SSO.Modal.TeamMapping': 'Mapeo del equipo :',
    'Settings.SSO.Modal.Button.Change': 'Cambiar',
    'MursionPortal.PlaceHolder.SelectAClient': 'Seleccionar un cliente',
    'Settings.SSO.Modal.AddButton.PlaceHolder.SelectATeam': 'Seleccionar un equipo',
    'MursionPortal.Label.Client': 'Cliente',
    'Settings.SSO.Modal.ConfirmDelete': 'Confirmar la eliminación',
    'Settings.SSO.Table.ColumnHeader.ConfigurationName': 'Nombre de la configuración',
    'Settings.SSO.Table.ColumnHeader.Type': 'Tipo',
    'Settings.SSO.Table.ColumnHeader.Protocol': 'Protocolo',
    'Settings.SSO.Table.ColumnHeader.ClientsCount': 'Recuento de clientes',
    'Settings.SSO.PlaceHolder.EnterSsoName': 'Introducir el nombre SSO',
    'Settings.SSO.Table.NoSsoSchemasFound': 'No se ha encontrado ningún esquema SSO.',
    'Settings.SSO.Table.HoverTitle.ViewEditSso': 'Ver/editar SSO',
    'Settings.SSO.Modal.HoverTitle.CopySPInitiatedSsoUrl': 'URL de SSO iniciada por el SP',
    'Settings.SSO.Modal.CopyButton.ModalHeader.SPInitiatedSsoUrl': 'URL de SSO iniciada por el SP',
    'MursionPortal.CopyText.CopiedToClipboard': '¡se ha copiado al portapapeles!',
    'Settings.SSO.Text.Public': 'Público',
    'Settings.SSO.Text.Private': 'Privado',
    'Settings.SSO.Modal.NoClientsAssigned': 'No hay ningún cliente asignado',
    'MursionPortal.Label.NotAvailable': 'n/d',
    'Teams.Modal.Header.NewTeam': 'Nuevo equipo',
    'MursionPortal.Button.More': 'Más',
    'Teams.Modal.Header.AllTeams': 'todos los equipos',
    'MursionPortal.Label.Name': 'Nombre:',
    'MursionPortal.Label.Location': 'Ubicación:',
    'MursionPortal.Label.Tags': 'Etiquetas:',
    'MursionPortal.Header.Facilitators': 'Facilitadores:',
    'MursionPortal.Header.Scenarios': 'Escenarios:',
    'MursionPortal.Button.SaveTeamInfo': 'Guardar la información del equipo',
    'MursionPortal.IconButton.Cancel': 'cancelar',
    'MursionPortal.IconButton.Edit': 'Editar',
    'MursionPortal.ButtonTitle.CancelEdit': 'Cancelar la edición',
    'MursionPortal.ButtonTitle.EditTeam': 'Editar el equipo',
    'MursionPortal.ConfirmationText.RestoreTeam': '¿Seguro que quiere restaurar este equipo?',
    'MursionPortal.ButtonTitle.RestoreTeam': 'Restaurar el equipo',
    'MursionPortal.ConfirmationText.ArchiveTeam': '¿Seguro que quiere archivar este equipo?',
    'MursionPortal.ButtonTitle.ArchiveTeam': 'Archivar el equipo',
    'MursionPortal.EmptyMessage.NoFacilitators': 'No hay facilitadores',
    'MursionPortal.EmptyMessage.NoSelectedFacilitators': 'No hay ningún facilitador seleccionado',
    'MursionPortal.Title.Facilitators': 'Facilitadores',
    'MursionPortal.Heading.Archived': '(Archivado)',
    'Teams.Modal.Header.AllLearners': 'Todos los alumnos',
    'Teams.Modal.Placeholder.SearchLearners': 'Buscar alumnos...',
    'Teams.Modal.EmptyMessage.NoLearnersToSelect': 'No hay ningún alumno para seleccionar',
    'Teams.Modal.Header.TeamLearners': 'Alumnos del equipo',
    'Teams.Modal.Placeholder.SearchTeamLearners': 'Buscar los alumnos del equipo...',
    'Teams.Modal.EmptyMessage.NoTeamLearnersToSelect': 'No hay ningún alumno del equipo para seleccionar',
    'Teams.Modal.Header.AssignLearners': 'Asignar alumnos a un equipo «{teamName}»',
    'Teams.AssignLearners.Modal.Button.SelectAll': 'Seleccionar todo',
    'Teams.AssignLearners.Modal.EmptyMessage.NoItems': 'No hay ningún elemento para seleccionar',
    'MursionPortal.Header.Teams': 'Equipos',
    'Teams.Modal.AlertMessage.Timezone': 'En el caso de un cambio de zona horaria, por favor, verifique los eventos existentes y la disponibilidad.',
    'Teams.SelectorMenu.SelectedOf': '{selected} seleccionados de {totalNumber}',
    'Teams.Modal.AlertMessage.ProfileTimezone': 'La zona horaria de su perfil actual no coincide con la configuración de la fecha y hora de su ordenador ({updatedTimezone}).Haga clic en «Guardar cambios» para continuar de todos modos o seleccione una opción diferente para la zona horaria del perfil.',
    'MursionPortal.Label.Learner': 'Alumno',
    'Teams.Modal.Button.More': '{overlappedAmount} más...',
    'MursionPortal.IconButton.Archive': 'archivo',
    'Teams.ToggleButton.Learners': 'Alumnos',
    'Teams.ToggleButton.Sessions': 'Sesiones',
    'MursionPortal.Status.RegistrationStatus': 'Estado del registro',
    'MursionPortal.Status.RegistrationAccepted': 'Registro aceptado',
    'MursionPortal.Status.RegistrationNotAccepted': 'Registro no aceptado',
    'MursionPortal.SortBy': 'Ordenar por',
    'MursionPortal.Placeholder.SortBy': 'ordenar-por',
    'Clients.Button.CreateClient': 'Crear cliente ',
    'Clients.CreateClient.Header.NewClient': 'Nuevo cliente',
    'Clients.CreateClient.Button.CreateClient': 'Crear cliente',
    'Clients.CreateClient.Label.ClientName': 'Nombre del cliente',
    'Clients.CreateClient.Placeholder.CityCountry': 'Localidad, País',
    'MursionPortal.Timezone': 'Zona horaria',
    'Clients.CreateClient.Label.Logo': 'Logotipo',
    'Clients.Text.NoClientsFound': 'No se ha encontrado ningún cliente.',
    'MursionPortal.Text.Active': 'Activo',
    'MursionPortal.Text.Archived': 'Archivado',
    'Clients.Modal.Button.BackToClientList': 'Volver a la lista de clientes',
    'Clients.Modal.EmptyMessage.NoClientsFound': 'No se ha encontrado ningún cliente',
    'Clients.Modal.Placeholder.EnterClientName': 'Introducir el nombre del cliente',
    'Clients.Modal.Header.ClientsList': 'LISTA DE CLIENTES',
    'Clients.Modal.Header.Client': 'CLIENTE',
    'Clients.Modal.Header.AllClients': 'todos los clientes',
    'MursionPortal.Search': 'Buscar',
    'Clients.Modal.RestoreConfirmText': '¿Seguro que quiere restaurar este cliente?',
    'Clients.Modal.DeleteConfirmText': '¿Seguro que quiere archivar este cliente?',
    'Clients.Modal.RelatedDataText': 'También se archivarán todos los datos relacionados.',
    'Clients.Modal.DeleteErrorText': 'El cliente actual tiene escenarios. Archivar los escenarios del proyecto antes de archivar este cliente.',
    'Clients.Button.RestoreClient': 'Restaurar cliente',
    'Clients.IconButton.Cancel': 'cancelar',
    'Clients.IconButton.Edit': 'Editar',
    'Clients.IconButton.CancelEdit': 'Cancelar la edición',
    'Cleints.IconButton.EditScenarioName': 'Editar el nombre del escenario',
    'Cleints.IconButton.ApplyNameChange': 'Aplicar el cambio de nombre',
    'Clients.IconButton.Apply': 'aplicar',
    'Clients.IconButton.EditClient': 'Editar el cliente',
    'Clients.Button.ArchiveClient': 'Archivar el cliente',
    'Clients.Modal.Heading.Archived': '(Archivado)',
    'Clients.Modal.Label.Name': 'Nombre:',
    'Clients.Modal.Placeholder.ClientName': 'Nombre del cliente',
    'Clients.Modal.Label.Location': 'Ubicación:',
    'Clients.Modal.Label.Industry': 'Sector:',
    'Clients.Modal.Placeholder.SelectIndustry': 'Seleccionar el sector',
    'Clients.Modal.Label.Timezone': 'Zona horaria:',
    'Clients.Modal.Label.SchedulingByRequest': 'Programación por solicitud:',
    'MursionPortal.Enabled': 'Habilitado',
    'MursionPortal.Disabled': 'Deshabilitado',
    'Clients.Modal.Label.SchedulingWindow': 'Programar la ventana:',
    'Clients.Modal.Label.HowFarInAdvance': 'Con cuánta anticipación deben programar los clientes',
    'MursionPortal.Days': 'días',
    'MursionPortal.Day': 'día',
    'Clients.Modal.Label.AvailableSharedSSOTitle': 'La lista de SSO compartidos disponibles que se pueden usar para iniciar sesión',
    'Clients.Modal.Label.AvailableSharedSSOLabel': 'SSO compartido disponible:',
    'Clients.Modal.EmptySelectedMessage': 'No ha ningún SSO seleccionado',
    'Clients.Modal.EmptyListMessage': 'No hay ningún SSO disponible',
    'Clients.Modal.SSOTitle': 'Seleccionar los SSO disponibles',
    'Cleints.Modal.Label.AvailablePrivateSSOTitle': 'La lista de SSO privados disponibles que se pueden usar para iniciar sesión',
    'Clients.Modal.Label.AccountOwners': 'Propietarios de la cuenta:',
    'Clients.Modal.AccountOwner.EmptyListMessage': 'No hay ningún propietario de cuentas',
    'Clients.Modal.AccountOwner.EmptySelectedMessage': 'No hay ningún propietario de cuenta seleccionado',
    'Clients.Modal.AccountOwnersTitle': 'Propietarios de cuentas',
    'MursionPortal.Message.Updating': 'Actualizando...',
    'MursionPortal.Button.SaveClientInfo': 'Guardar la información del cliente',
    'MursionPortal.Add': 'Añadir ',
    'Clients.Modal.Label.EditSSO': 'Editar SSO',
    'Clients.Modal.Label.Edit': 'Editar',
    'MursionPortal.NumberOfFacilitators': 'Número de facilitadores',
    'MursionPortal.NumberOfLearners': 'Número de alumnos',
    'Clients.Modal.Button.CreateTeam': 'Crear equipo ',
    'MursionPortal.NewTeam': 'Nuevo equipo',
    'MursionPortal.NoTags': 'No hay ninguna etiqueta',
    'MursionPortal.Header.Tags': 'Etiquetas',
    'MursionPortal.AddTag': 'Añadir etiqueta',
    'Clients.Modal.Teams.FacilitatorsEmptyListMessage': 'No hay ningún facilitador seleccionado',
    'Clients.Modal.Teams.LearnersEmptyListMessage': 'No hay ningún alumno seleccionado',
    'MursionPortal.Learners': 'Alumnos',
    'MursionPortal.Facilitator': 'Facilitador',
    'MursionPortal.CreateTeam': 'Crear equipo',
    'Clients.Modal.Teams.ConfirmationText.RestoreTeam': '¿Seguro que quiere restaurar este equipo?',
    'Clients.Modal.Teams.ButtonTitle.RestoreTeam': 'Restaurar el equipo',
    'Clients.Modal.Teams.ConfirmationText.ArchiveTeam': '¿Seguro que quiere archivar este equipo?',
    'Clients.Modal.Teams.ButtonTitle.ArchiveTeam': 'Archivar el equipo',
    'Clients.Modal.Projects.Header.NewProject': 'Nuevo proyecto',
    'MursionPortal.ProjectName': 'Nombre del proyecto',
    'MursionPortal.Contracted': 'Contratado',
    'MursionPortal.CancellationWindow': 'Ventana de cancelación',
    'MursionPortal.SoftwareVersion': 'Versión del «software»',
    'MursionPortal.Billing': 'Facturación',
    'Clients.Modal.Projects.Checkbox.NonBillable': 'No facturable',
    'MursionPortal.Recording': 'Grabación',
    'Clients.Modal.Projects.Checkbox.AllowRecording': 'Permitir grabación',
    'Clients.Modal.Projects.Label.RecordedVideos': 'Los vídeos grabados se remontan a:',
    'Clients.Modal.Projects.Checkbox.RecordingPermission': 'El permiso de grabación recae en el alumno',
    'Clients.Modal.Projects.ButtonTitle.SaveProjectInfo': 'Guardar la información del proyecto',
    'Clients.Modal.Projects.Header.ProjectManager': 'Gerente del proyecto',
    'Clients.Modal.Projects.ProjectsEmptyListMessage': 'No hay ningún gerente de proyecto seleccionado',
    'MursionPortal.Header.Bracketed.Scenarios': 'Escenario(s)',
    'Clients.Modal.Projects.Text.CompleteProjectInfoFirst': 'Complete primero la información del proyecto',
    'MursionPortal.Header.CreateScenario': 'Crear escenario',
    'Clients.Modal.Projects.ConfirmationText.RestoreProject': '¿Seguro que quiere restaurar este proyecto?',
    'Clients.Modal.Projects.ButtonTitle.RestoreProject': 'Restaurar el proyecto',
    'Clients.Modal.Projects.ButtonTitle.Edit': 'Editar el proyecto',
    'Clients.Modal.Projects.ButtonTitle.CancelEdit': 'Cancelar la edición',
    'Clients.Modal.Label.Cancel': 'cancelar',
    'Clients.Modal.Projects.ConfirmationText.ArchiveProject': '¿Seguro que quiere archivar este proyecto?',
    'Clients.Modal.Projects.ButtonTitle.ArchiveProject': 'Archivar el proyecto',
    'Clients.Modal.Projects.ArchiveErrorMessage': 'El proyecto actual tiene escenarios activos. Archivar los escenarios del proyecto antes de archivar este proyecto.',
    'Clients.Modal.Projects.ButtonTitle.EditTags': 'Editar las etiquetas',
    'Clients.Modal.Projects.Label.UtilizedMissedSessions': 'Sesiones perdidas utilizadas,',
    'Clients.Modal.Projects.Button.CreateNew': 'Crear nuevo ',
    'Clients.Modal.Projects.Button.RepeatClient': 'Repetir el cliente',
    'Clients.Modal.Projects.Button.RepeatBank': 'Repetir el banco',
    'Clients.Modal.Projects.ButtonTitle.CreateNewScenario': 'Crear nuevo escenario',
    'Clients.Modal.Projects.ButtonTitle.RepeatClientScenario': 'Repetir el escenario del cliente',
    'Clients.Modal.Projects.ButtonTitle.ScenarioBank': 'Crear el escenario a partir del banco de escenarios',
    'Clients.Modal.Button.Schedule': 'Programar',
    'MursionPortal.Button.ActivateScenario': 'Activar el escenario',
    'MursionPortal.Button.ChangeScenario': 'Cambiar el escenario',
    'Clients.IconButton.Confirm': 'confirmar',
    'Clients.IconButton.ConfirmEdit': 'Confirmar la edición',
    'Clients.Modal.Projects.HeaderText.ChooseScenario': 'Elegir el escenario para repetir',
    'Clients.Modal.Projects.HeaderText.ChooseScenarioBank': 'Elegir el escenario del banco de escenarios',
    'MursionPortal.Placeholder.LowerCase.EnterScenarioName': 'Introducir el nombre del escenario',
    'MursionPortal.IconButton.ResetToDefault': 'Restablecer el nombre por defecto',
    'MursionPortal.IconButton.Reset': 'restablecer',
    'Clients.Modal.Header.CreatePlanning': 'Crear la planificación para el nuevo escenario',
    'Clients.Modal.EmptyMessage.NoClientScenariosFound': 'No se ha encontrado ningún escenario del cliente.',
    'Clients.Modal.EmptyMessage.NoBankScenariosFound': 'No se ha encontrado ningún escenario del banco.',
    'Clients.Modal.Message.NoTeamsSpecified': 'No hay ningún equipo especificado',
    'Clients.Modal.EmptyMessage.NoSelectedTeams': 'No hay ningún equipo seleccionado',
    'Clients.Modal.Message.NoAssignedTeams': 'El cliente no tiene ningún equipo asignado',
    'Clients.Modal.Label.Certificate': 'Certificado',
    'Clients.Modal.Label.DeliveryMode': 'Modo de entrega',
    'Clients.Modal.Label.SessionSize': 'Tamaño de la sesión',
    'Clients.Modal.Label.SessionLength': 'Duración de la sesión',
    'Clients.Modal.Label.Outcome': 'Resultado',
    'Clients.Modal.Label.Strategies': 'Estrategias',
    'Clients.Modal.Label.Description': 'Descripción',
    'MursionPortal.Button.MoreInfo': 'Más información...',
    'MursionPortal.Button.HideAdditionalInfo': 'Ocultar información adicional...',
    'MursionPortal.Header.Timeline': 'Cronograma',
    'MursionPortal.Header.Projects': 'Proyectos ',
    'MursionPortal.Header.Sessions': 'Sesiones',
    'MursionPortal.Header.Users': 'Usuarios',
    'MursionPortal.Text.Yes': 'sí',
    'MursionPortal.Text.No': 'no',
    'MursionPortal.Button.Google': 'Google',
    'MursionPortal.Button.Outlook': 'Outlook, iCal, otro',
    'MursionPortal.Heading.GoogleCalendar': 'Calendario de Google ',
    'MursionPortal.Heading.AddToCalendar': 'Añadir al calendario ',
    'Dashboard.Legacy.GoogleCalendarList.Authorized': 'Si aún no lo ha hecho, se le pedirá que <code>autorice</code> el <code>portal de {companyName}</code> para añadir eventos a su calendario.',
    'Dashboard.Legacy.GoogleCalendarList.EventAdd': 'El evento se <code>añadirá a su calendario</code> y aún se podrá acceder al mismo en el portal de {companyName}.',
    'MursionPortal.Heading.OtherCalendars': 'Otros calendarios',
    'Dashboard.Legacy.OtherCalendarList.Downloaded': 'Su evento se <code>descargará</code> como <code>{companyName}</code>',
    'Dashboard.Legacy.OtherCalendarList.SelectCalendar': '<code>Haga clic</code> con el botón derecho, seleccione <code>«Abrir con»</code> y seleccione su aplicación de calendario.',
    'Dashboard.Legacy.OtherCalendarList.ImportWithinCalendar': '(también puede seleccionar «Importar» en su calendario y ubicar el archivo descargado).',
    'Dashboard.Legacy.OtherCalendarList.AddedToCalendar': 'El evento se <code>añadirá a su calendario</code> y aún se podrá acceder al mismo en el portal de {companyName}',
    'MursionPortal.Text.NoLearnersAddedYet': 'Todavía no se ha añadido ningún alumno',
    'MursionPortal.Heading.Schedule': 'Programar',
    'MursionPortal.Heading.Users': 'USUARIOS',
    'MursionPortal.Heading.ProfServices': 'Servicios prof.',
    'MursionPortal.Heading.SimSpecialist': 'Especialistas en Sim',
    'MursionPortal.Heading.Clients': 'Clientes',
    'MursionPortal.Heading.Sessions': 'Sesiones',
    'MursionPortal.Heading.Projects': 'Proyectos',
    'MursionPortal.Heading.Teams': 'Equipos',
    'MyMessages.Button.ArchiveAll': 'Archivar todo',
    'MyMessages.Button.ArchivedMessages': 'Mensajes archivados',
    'MyMessages.Button.ShowMore': 'Mostrar más...',
    'MyMessages.Label.NewMessages': '<code>{newMessagesCount}</code> mensajes nuevos',
    'MyMessages.Label.NewMessage': '<code>{newMessagesCount}</code> mensaje nuevo',
    'MyMessages.HoverTitle.InboxButton': 'botón de la bandeja de entrada',
    'MyMessages.Text.InboxButton': 'Botón de la bandeja de entrada',
    'MyMessages.Text.HaveNewMessages': 'Tiene {total} mensajes nuevos',
    'MyMessages.Text.HaveNewMessage': 'Tiene un mensaje nuevo',
    'MursionPortal.CookiePolicy.Header.CookiePolicy': 'Política de «cookies»',
    'MursionPortal.CookiePolicy.Text.MursionUsesEssentialCookiesOnly': 'Mursion solo utiliza «cookies» esenciales que son necesarias para su seguridad y el funcionamiento de nuestra aplicación web. No utilizamos «cookies» para recopilar datos personales u otra información, como direcciones IP o el uso del sitio web.',
    'MursionPortal.CookiePolicy.Text.ForMoreInformation': ' Para obtener más información, por favor, consulte la <code>Política de privacidad</code>.',
    'MursionPortal.Button.Ok': 'Ok',
    'MyProfile.Button.SaveChanges': 'Guardar cambios',
    'MyProfile.Label.ExternalProfiles': 'Perfiles externos',
    'MyProfile.Label.UserRoles': 'Roles del usuario',
    'MyProfile.Label.UserRole': 'Rol del usuario',
    'MyProfile.Heading.ReviewAccountInformation': 'Por favor, revise la información de su cuenta',
    'MyProfile.HoverTitle.UnlinkProfile': 'Desvincule su perfil de {linkName}',
    'MyProfile.HoverTitle.LinkProfile': 'Vincular el perfil de {linkName}',
    'MyProfile.Button.Unlink': 'Desvincular',
    'MyProfile.Text.UnlinkConfirmationText': '¿Seguro que quiere desvincular su perfil de {name}? Por favor, tenga en cuenta que también se cerrará la sesión de {name}.',
    'MyProfile.Title.ProfileUnlink': 'Desvincular el perfil de {name}',
    'MyProfile.Text.SuccessfullyUnlinkedProfile': 'Ha desvinculado correctamente su perfil de {name}',
    'MyProfile.Text.TimeZoneIsSetAutomatically': 'Su zona horaria se establece automáticamente en {getTimeZone}. Haga clic en «Confirmar» para aceptar esta selección o en «Cancelar» para realizar otra selección.',
    'MyProfile.Text.TimeZoneChange': 'En el caso de un cambio de zona horaria, por favor, verifique los eventos existentes y la disponibilidad.',
    'MyProfile.Text.ProfileTimeZoneNotMatchComputer': 'La zona horaria de su perfil actual no coincide con la configuración de la fecha y hora de su ordenador ({timeZone}).Haga clic en «Guardar cambios» para continuar de todos modos o seleccione una opción diferente para la zona horaria del perfil.',
    'MursionPortal.Button.MyProfile': 'Mi perfil',
    'MursionPortal.Button.LogOut': 'Cerrar sesión',
    'MyProfile.Label.Language': 'Idioma',
    'MursionPortal.Label.Roles': 'Roles',
    'MursionPortal.Label.Role': 'Rol',
    'MursionPortal.Projects.Header.ScenarioType': 'Tipo de escenario',
    'MursionPortal.Projects.Header.Client': 'Cliente',
    'MursionPortal.Projects.Header.Project': 'Proyecto',
    'MursionPortal.Projects.Header.Scenario': 'Escenario',
    'MursionPortal.Projects.Header.SimulationContent': 'Contenido de la simulación',
    'MursionPortal.Projects.Header.Date': 'Fecha',
    'MursionPortal.Projects.Header.Time': 'Hora',
    'MursionPortal.Projects.Header.Notes': 'Notas',
    'Filters.Date.Week': 'Semana',
    'Users.Table.Modal.SignLanguageInterpreter': 'Intérprete de lengua de signos',
    'Calendar.Button.Schedule': 'Calendario',
    'Calendar.Button.Today': 'Hoy',
    'MursionPortal.Availability': 'Disponibilidad',
    'Calendar.Button.Schedule.QualityAssurance': 'Seguro de calidad',
    'MursionPortal.Training': 'Formación',
    'Calendar.Button.Schedule.EmergencySession': 'Sesión de emergencia',
    'Calendar.Button.Schedule.Session': 'Sesión',
    'Calendar.Button.Schedule.BreakPrep': 'Descanso/Preparación',
    'MursionPortal.Month': 'Mes',
    'MursionPortal.Week': 'Semana',
    'Calendar.Heading.Calendars': 'Calendarios',
    'Calendar.Tip.SelectACalendar': 'Seleccione un calendario para ver la disponibilidad',
    'Calendar.ScheduledHours.SumOfAllScheduledSessions': 'Suma de todas las sesiones programadas en la vista actual',
    'MursionPortal.ShortLabel.Hours': 'h',
    'Calendar.ScheduledHours.SumOfAllTrainingSessions': 'Suma de todas las sesiones de formación programadas en la vista actual',
    'Calendar.ScheduledHours.Trainings': 'Formaciones',
    'Calendar.ScheduledHours.SumOfRemainingAvailabilityHours': 'Suma de todas las horas de disponibilidad de SS restantes en la vista actual',
    'Calendar.ScheduledHours.Available': 'Disponible',
    'MursionPortal.Scheduled': 'Programada',
    'Calendar.Label.Display': 'Pantalla',
    'MursionPortal.Client': 'Cliente',
    'Calendar.Filter.Company': 'Empresa',
    'Calendar.Filter.SwapRequests': 'Solicitudes de intercambio',
    'MursionPortal.QA': 'AC',
    'Calendar.SearchFilter.FilterProjects': 'Filtrar proyectos',
    'Calendar.SearchFilter.FilterClients': 'Filtrar clientes',
    'Calendar.SearchFilter.FilterSimSpecialists': 'Filtrar especialistas en simulaciones',
    'Calendar.IndustrySelector.Options.SelectAnIndustry': 'Seleccionar un sector',
    'Calendar.IndustrySelector.Options.AllIndustries': 'Todos los sectores',
    'Calendar.IndustrySelector.Options.NoIndustriesToSelect': 'No hay sectores para seleccionar',
    'MursionPortal.SelectAClient': 'Seleccionar un certificado',
    'Calendar.ClientSelector.Options.AllClients': 'Todos los clientes',
    'Calendar.ClientSelector.Options.NoClientToSelect': 'No hay clientes para seleccionar',
    'MursionPortal.SelectACertificate': 'Seleccionar un certificado',
    'Calendar.CertificateSelector.Options.AllCertificates': 'Todos los certificados',
    'Calendar.CertificateSelector.Options.NoCertificatesToSelect': 'No hay certificados para seleccionar',
    'Calendar.ClientsList.NoClientsFound': 'No se ha encontrado ningún cliente.',
    'MursionPortal.UncheckAll': 'Desmarcar todo',
    'MursionPortal.CheckAll': 'Marcar todo',
    'Calendar.SimSpecialistList.NoSimSpecialistsFound': 'No se han encontrado especialistas en simulaciones.',
    'Calendar.ProjectsList.CloseProjectOverview': 'Cerrar la descripción general del proyecto',
    'Calendar.ProjectsList.AllProjects': 'TODOS LOS PROYECTOS',
    'MursionPortal.Bracketed.Scenarios': 'Escenario(s)',
    'Calendar.ProjectsList.Overview': 'Descripción general',
    'Calendar.ProjectsList.CloseClientOverview': 'cerrar descripción general del cliente',
    'MursionPortal.Scenario': 'escenario',
    'Calendar.ScenariosList.NoScenarios': 'Sin escenarios',
    'MursionPortal.Delivery': 'Entrega',
    'MursionPortal.Mode': 'Modo',
    'MursionPortal.Timeline': 'Cronología',
    'Calendar.ScenariosList.Description': 'Descripción',
    'MursionPortal.Outcome': 'Resultado',
    'MursionPortal.Strategies': 'Estrategias',
    'MursionPortal.ContentBundle': 'Paquete de contenido',
    'MursionPortal.SimulationEnvironments': 'Entorno(s) de simulación',
    'MursionPortal.AvatarsInSimulations': 'Avatar(es) en las simulaciones',
    'Calendar.LearnersList.SessionNotScheduled': 'Sesión no programada',
    'Calendar.LearnersList.SessionScheduled': 'Sesión programada',
    'Calendar.LearnersList.SessionComplete': 'Sesión completa',
    'Calendar.LearnersList.Learner': '{learnersCount} alumno',
    'Calendar.LearnersList.Learners': '{learnersCount} alumnos',
    'Calendar.Button.CantSchedule': 'No se puede programar',
    'Calendar.Button.ScheduleEvent': 'Evento programado',
    'Calendar.MainPanel.ConfirmText.AreYouSure': '¿Seguro que quiere cancelar este evento?',
    'Calendar.MainPanel.CancelEvent': 'Cancelar evento',
    'Calendar.MainPanel.UpdateEvent': 'Actualizar evento',
    'MursionPortal.Submit': 'Enviar',
    'Calendar.MainPanel.NewEvent': 'Nuevo evento',
    'MursionPortal.Project': 'Proyecto',
    'MursionPortal.Button.Reload': 'Recargar',
    'Clients.Modal.Label.FacilitatorVisibility': 'Vista del facilitador:',
    'Clients.Modal.Placeholder.FacilitatorVisibility': 'Seleccionar la vista del facilitador',
    'Clients.Modal.Projects.ConfirmationText.EditProject': '¿Seguro que quiere editar este proyecto?',
    'Projects.ScenarioList.Legend.ScenariosStatusTab': 'Pestañas de estado de los escenarios',
    'Projects.ScenarioList.Legend.CreateNew': 'Crear nuevo, Repetir cliente, Repetir botones del escenario del banco',
    'MursionPortal.ScenarioCard.Tab.Legend.ViewModeTab': 'Pestaña del modo de visualización',
    'MursionPortal.SearchPlaceHolder.SearchClient': 'Buscar cliente...',
    'Settings.SSO.Modal.AddButton.SearchPlaceHolder.SearchTeam': 'Seleccionar equipo...',
    'Settings.SSO.Modal.Button.ChangeLabel': 'Cambiar {label}',
    'MursionPortal.Label.Yes': 'Sí',
    'MursionPortal.Label.No': 'No',
    'Settings.SSO.Modal.AddButton.PlaceHolder.NoOptions': 'Sin opciones',
    'Settings.SSO.Modal.ClientName': 'Nombre del cliente',
    'MursionPortal.Modal.Button.Add': 'Añadir',
    'MursionPortal.Modal.Button.Remove': 'Quitar',
    'Users.Table.Hover.ViewEditAcceptedUsers': 'Ver/Editar usuarios aceptados',
    'Users.Table.Hover.ViewEditUnacceptedUsers': 'Ver/Editar usuarios no aceptados',
    'MursionPortal.Label.Industry': 'Sector',
    'MursionPortal.Label.Timezone': 'Zona horaria',
    'MursionPortal.Header.Client': 'Cliente',
    'MursionPortal.FilterByCertificate': 'Filtrar por certificado',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Header.CreateQAEvent': 'Crear evento de aseguramiento de la calidad',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.TimeBlock': 'Bloque(s) de tiempo',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Participants': 'Participantes',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Button.SubmitNewEvent': 'Enviar nuevo evento',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockOutsideRange': 'El bloque de tiempo está fuera del rango disponible',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockBeforeCurrentTime': 'El bloque de tiempo es anterior a la hora actual',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Start': 'Comenzar',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.End': 'Fin',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Length': 'Longitud',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.NoAvailableTimeBlock': 'No hay ningún bloque de tiempo disponible para el día seleccionado',
    'Calendar.Modal.Button.Client': 'Cliente',
    'Calendar.Modal.Button.Project': 'Proyecto',
    'Calendar.Modal.Button.Scenario': 'Escenario',
    'Calendar.Modal.Header.CompanyEvents': 'Eventos de la empresa',
    'MursionPortal.Header.Session': 'Sesión',
    'MursionPortal.Header.Events': 'Eventos',
    'MursionPortal.Header.Event': 'Evento',
    'Calendar.Modal.Label.Time': 'Hora',
    'Calendar.Label.SelectTimeZone': 'Seleccionar zona horaria',
    'Calendar.Text.Training': 'Formación',
    'Calendar.Text.QA': 'AC',
    'Calendar.Modal.Text.ConfirmDeleteTime': 'Confirmar la eliminación del bloque de tiempo',
    'Calendar.Button.NewTimeBlock': 'Nuevo bloque de tiempo',
    'Calendar.Modal.Label.Capitalized.Time': 'TIEMPO',
    'Calendar.Modal.Label.Participants': 'PARTICIPANTES',
    'Calendar.Modal.Text.NoParticipants': 'Sin participantes',
    'Calendar.Modal.Label.SimulationSpecialist': 'ESPECIALISTA EN SIMULACIÓN',
    'Calendar.Modal.Label.MeetingOrganizer': 'ORGANIZADOR DE LA REUNIÓN',
    'Calendar.Error.ErrorPrefixMustBeBetween': '{errorPrefix} debe estar entre {duration1} y {duration2}.',
    'Calendar.Error.SelectedTimeBlockOverlapsExistingTime': 'El bloque de tiempo seleccionado se solapa con el bloque de tiempo existente.',
    'Calendar.Error.SelectedTimeBlockOverlapsUnavailableTime': 'El bloque de tiempo seleccionado se solapa con el intervalo de tiempo no disponible.',
    'Calendar.Error.SelectedTimeBlockIsNotMultipleOfEventGap': 'El bloque de tiempo seleccionado no es un múltiplo del intervalo de eventos ({duration})',
    'Calendar.Text.AvailabilityUser': 'Disponibilidad {user}',
    'Calendar.Text.AvailabilityTime': 'Disponibilidad {time}',
    'Calendar.Text.AvailabilityDurationAvailabilityTime': '{availabilityDuration} Disponibilidad {time}',
    'Calendar.Text.TrainingsTime': '{eventsCount} Formaciones {time}',
    'Calendar.Text.SessionsTime': '{eventsCount} Sesiones {time}',
    'Calendar.Text.AvailabilityName': 'Disponibilidad {name}',
    'Calendar.Label.Learners': 'Alumnos',
    'Calendar.Text.FullAvailability': 'Disponibilidad total',
    'Calendar.Text.YourAvailability': 'Su disponibilidad',
    'Calendar.Text.Event': 'Evento',
    'Calendar.Header.Trainings': 'Formaciones',
    'Calendar.Header.CompanyEvents': 'Eventos de la empresa',
    'Calendar.Header.Session': 'Sesión ',
    'Calendar.Error.TimeBlockIsOutsideAvailableRange': 'El bloque de tiempo está fuera del rango disponible',
    'Calendar.Error.TimeBlockIsBeforeCurrentTime': 'El bloque de tiempo es anterior a la hora actual',
    'Calendar.Label.Break': 'Descanso',
    'Calendar.Label.Prep': 'Preparación',
    'Calendar.Modal.Header.CreateBreakPrepEvent': 'Crear evento de descanso/preparación',
    'Calendar.Modal.Label.EventType': 'Tipo de evento',
    'Calendar.Modal.Label.TimeBlocks': 'Bloque(s) de tiempo',
    'Calendar.Text.NoTimeBlocksInSelectedRange': 'No hay ningún bloque de tiempo disponible en el rango seleccionado',
    'Calendar.Text.NoAvailableTimeBlocks': 'No hay ningún bloque de tiempo disponible',
    'Calendar.Text.SelectingThisTimeBlock': 'Al seleccionar este bloque de tiempo, estará solicitando un tiempo de sesión que no está disponible actualmente. Haremos todo lo posible para cumplir con esta solicitud si continúa o si desliza el interruptor situado debajo a la posición de «Off», para seleccionar únicamente entre los bloques de tiempo disponibles y confirmados.',
    'Calendar.ToolTip.SlideToggleOff': 'Deslice el botón a «Off» para seleccionar únicamente entre los bloques de tiempo disponibles.',
    'Calendar.ToolTip.SlideToggleOn': 'Deslice el botón a «On» para solicitar un bloque de tiempo adicional.',
    'Calendar.Label.SelectAnyTimeBlock': 'Seleccione cualquier bloque de tiempo',
    'Users.CreateUser.Label.GoogleMeetID': 'Identificación de Google Meet',
    'Users.CreateUser.GoogleMeetId.Placeholder.GoogleMeetID': 'Identificación de Google Meet',
    'Users.CreateUser.GoogleMeetID.Hover': 'Por favor, introduzca una identificación de Google Meet',
    'Setting.Edit.Modal.Error.UnableToCreateSession': 'No se ha podido crear la sesión para el tiempo seleccionado {formattedStartTime} - {formattedEndTime}',
    'Session.Header.Session': 'Sesión',
    'Session.Header.AcceptSessionRequest': 'Por favor, confirme si desea aceptar esta solicitud de sesión y añádala a su programa.',
    'CommonComponent.RecurrenceForm.Label.UntilDate': 'Hasta la fecha',
    'CommonComponent.RecurrenceForm.Label.Period': 'Periodo',
    'CommonComponent.RecurrenceForm.Option.Daily': 'Diario',
    'CommonComponent.RecurrenceForm.Option.Weekly': 'Semanal',
    'CommonComponent.RecurrenceForm.Label.Weekday': 'Día de la semana',
    'CommonComponent.RecurrenceForm.Text.NoSelectedWeekdays': 'No hay días de la semana seleccionados',
    'CommonComponent.RecurrenceForm.Error.PleaseSelectWeekdays': 'Por favor, seleccione los días de la semana',
    'Calendar.EventType.Availability': 'Disponibilidad',
    'Calendar.EventType.Session': 'Sesión',
    'Calendar.EventType.Training': 'Formación',
    'Calendar.EventType.CompanyEvent': 'Evento de la empresa',
    'Calendar.EventType.NewEvent': 'Nuevo evento',
    'Calendar.Text.NewEventTime': 'Nuevo evento {time}',
    'Calendar.Header.Training': 'Formación',
    'Calendar.Modal.Text.Sessions': 'Sesiones',
    'Calendar.Modal.Text.Trainings': 'Formaciones',
    'Calendar.Modal.Text.Events': 'Eventos',
    'Calendar.Modal.Text.PendingNoSS': '- Pendiente (N.º SS).',
    'Calendar.Modal.Text.PendingSwap': '- Pendiente (intercambio).',
    'Calendar.Modal.Text.PreBooked': '- Prerreservado.',
    'Calendar.Modal.Text.NeedsReview': '- Necesita evaluación',
    'Calendar.Modal.Text.JoinSession': '- puede unirse a la sesión.',
    'Calendar.Modal.Text.NoLearnersInSession': '- no hay alumnos en la sesión.',
    'Calendar.Modal.Text.NoLearnersInSessionAfterBookedTime': '- no hay alumnos en la sesión después del tiempo reservado.',
    'Calendar.Modal.Header.Events': 'Eventos',
    'Calendar.Modal.Header.Session': 'sesión',
    'Calendar.Modal.Header.Training': 'formación',
    'Calendar.Modal.Header.Availability': 'disponibilidad',
    'Calendar.Modal.Filter.Client': 'Cliente',
    'Calendar.Modal.Filter.Project': 'Proyecto',
    'Calendar.Modal.Filter.Time': 'Tiempo',
    'Calendar.Modal.Label.Recurrence': 'Recurrencia',
    'Calendar.Modal.Header.CreateAvailability': 'Crear disponibilidad',
    'Calendar.Modal.Text.Recurring': 'Recurrente',
    'Calendar.SimSpecialistButton.Modal.Header.Scheduled': 'Programada',
    'Calendar.SimSpecialistButton.Modal.Header.ThisPeriod': '(este periodo)',
    'Calendar.SimSpecialistButton.Modal.Label.Sessions': 'Sesiones',
    'Calendar.SimSpecialistButton.Modal.Label.Hours': 'Horas',
    'Calendar.SimSpecialistButton.Modal.Label.Trainings': 'Formaciones',
    'Calendar.SimSpecialistButton.Modal.Label.TrainingsHours': 'Horas de formación',
    'Calendar.SimSpecialistButton.Modal.Label.Available': 'Disponible',
    'Calendar.SimSpecialistButton.Modal.Header.Certification': 'Certificación',
    'Calendar.SimSpecialistButton.Modal.Text.NoCertificates': 'Sin certificados',
    'Calendar.SimSpecialistButton.Modal.Header.SimSpecialistOverviewPanel': 'Panel de descripción general del especialista en simulación',
    'Calendar.SimSpecialistButton.Modal.HoverTitle.CloseSimSpecialistOverview': 'cerrar la descripción general del especialista en simulación',
    'MursionPortal.DeliveryMode.OneToOne': '1:1',
    'MursionPortal.DeliveryMode.OneToManyRemote': 'A distancia',
    'MursionPortal.DeliveryMode.OneToManyLocal': 'Local (pecera)',
    'MursionPortal.DeliveryMode.Group': 'Entrega grupal',
    'MursionPortal.Projects.Header.Trainees': 'Personal en prácticas',
    'Projects.Placeholder.SimContentName': 'Introduzca el nombre del contenido de simulación',
    'Projects.Message.NoSimulationContentFound': 'No se ha encontrado el contenido de la simulación',
    'MursionPortal.Button.UncheckAll': 'Desmarcar todo',
    'MursionPortal.Button.CheckAll': 'Marcar todo',
    'Projects.Header.NewScenario': 'Proyecto {projectName}',
    'MursionPortal.Button.SaveAsDraft': 'Guardar como borrador',
    'MursionPortal.Button.Save': 'Guardar',
    'Projects.Message.SelectEnvironmentFirst': 'Seleccione primero un entorno',
    'Projects.Message.LoadingAvatarsList': 'Cargando lista de avatares...',
    'Projects.Message.NoAssignedAvatars': 'El entorno seleccionado no tiene avatares asignados',
    'Projetcs.EmptyMessage.NoSelectedAvatars': 'No hay avatares seleccionados',
    'Projects.Avatars.NoAvatarsSpecified': 'No se ha especificado ningún avatar',
    'Projects.Message.SelectIndustryFirst': 'Seleccione primero un sector',
    'Projects.Message.LoadingEnvironmentsList': 'Cargando lista de entornos...',
    'Projects.Message.NoAssignedEnvironments': 'El sector seleccionado no tiene entornos asignados',
    'Projects.Message.ScenarioHasNoAssignedEnvironment': 'El escenario no tiene ningún entorno asignado',
    'Projects.AltText.EnvironmentImage': 'imagen-del-entorno',
    'Projects.Placeholder.SelectAnEnviroment': 'Seleccionar un entorno',
    'Projects.Message.UnnamedIndustry': 'Sector sin nombre',
    'Projects.Placeholder.SelectAnIndustry': 'Seleccionar un sector',
    'Projects.Header.NewProject': 'Nuevo proyecto',
    'Projects.AltText.ClientLogo': 'Logo del cliente',
    'Projects.Message.SelectAnyTimeBlock': 'Seleccione cualquier bloque de tiempo',
    'Projects.Message.SelectScenarioFirst': 'Por favor, seleccione primero el escenario',
    'MursionPortal.Text.Asset': 'Activo V2',
    'MursionPortal.Button.Accept': 'Aceptar',
    'Projects.Modal.EmptyMessage.NoLearner': 'Sin alumnos',
    'MursionPortal.Label.SelectAll': 'Seleccionar todo',
    'Projects.Modal.Placeholder.ChooseATeam': 'Elegir un equipo',
    'MursionPortal.Placeholder.SearchTeams': 'Buscar equipos...',
    'Projects.Modal.Text.SessionSize': 'El tamaño de sesión permitido es igual a {maxLearners}. Seleccionar alumnos específicos',
    'Projects.Modal.Placeholder.SearchLearners': 'Buscar alumnos',
    'MursionPortal.ErrorMessage.MaxLearners': 'Solo puede añadir hasta {maxLearners} alumnos para este tipo de sesión.',
    'MursionPortal.ErrorMessage.MaxLearner': 'Solo puede añadir hasta {maxLearners} alumno para este tipo de sesión.',
    'MursionPortal.Label.ChooseMembers': 'Elija a los miembros',
    'MursionPortal.Message.NoMembersToSelect': 'No hay miembros para seleccionar',
    'MursionPortal.Message.PleaseSelectATeamFirst': 'Por favor, seleccione primero un equipo',
    'AssetSync.Header.AssetSync': 'Sincronización del activo',
    'AssetSync.Text.AssetsSyncedSuccessfully': 'Los activos se han sincronizado correctamente',
    'AssetSync.Button.Sync': 'Sincronizar',
    'AssetSync.Button.TryAgain': 'Inténtelo de nuevo',
    'AssetSync.SyncButton.Text.Syncing': 'Sincronizando',
    'SessionFlow.Label.SessionExport': 'Exportar sesión',
    'MursionPortal.Button.Export': 'Exportar',
    'SessionFlow.Label.SessionFlowData': 'Datos del flujo de la sesión',
    'SessionFlow.Button.LoadSessionData': 'Cargar datos de la sesión',
    'SessionFlow.Button.StartSessionAnalytics': 'Iniciar el análisis de la sesión',
    'SessionFlow.Label.Checklist': 'Lista de control',
    'SessionFlow.Label.RecordingSettings': 'Ajustes de grabación',
    'SessionFlow.Label.SessionVideoFact': 'Dato de la sesión de vídeo',
    'SessionFlow.Label.UserStatus': 'Estado del usuario',
    'SessionFlow.SessionVideoFact.ColumnHeader.VideoAllowed': 'Vídeo permitido',
    'MursionPortal.Table.ColumnHeader.UserName': 'Nombre de usuario',
    'MursionPortal.Table.ColumnHeader.Email': 'Correo electrónico',
    'MursionPortal.Table.ColumnHeader.Status': 'Estado',
    'SessionFlow.StartSessionAnalyticsButton.Text.EventStartingAnalytics': 'El evento para iniciar el proceso de análisis se ha publicado con éxito',
    'SessionFlow.RecordingSettings.Label.VideoRecording': 'Grabación en vídeo',
    'SessionFlow.RecordingSettings.Text.Allowed': 'Permitida',
    'SessionFlow.RecordingSettings.Text.Disallowed': 'No permitida',
    'SessionFlow.RecordingSettings.Label.RecordingPermission': 'Permiso de grabación',
    'SessionFlow.RecordingSettings.Text.LiesWithLearner': 'Recae en el alumno',
    'SessionFlow.RecordingSettings.Label.RecordedVideosGoBackTo': 'Los vídeos grabados datan de',
    'SessionFlow.Error.CannotFindSession': 'No se ha podido encontrar ninguna sesión con la identificación proporcionada',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Type': 'Tipo',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Date': 'Fecha',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Error': 'Error',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Payload': 'Carga útil',
    'SessionFlow.SessionFlowData.PlaceHolder.SessionID': 'Identificación de la sesión',
    'SessionFlow.Button.HideDetailedEventLog': 'Ocultar registro de eventos detallado...',
    'SessionFlow.Button.ShowDetailedEventLog': 'Mostrar registro de eventos detallado... ',
    'SessionFlow.Button.HideSessionHistory': 'Ocultar historial de sesiones...',
    'SessionFlow.Button.ShowSessionHistory': 'Mostrar historial de sesiones...',
    'SessionFlow.Text.NoEvents': 'Sin eventos',
    'SessionFlow.SessionVideoFact.Label.VideoExists': 'El vídeo existe',
    'SessionFlow.Text.NoLearners': 'Sin alumnos',
    'SessionFlow.Checklist.DataRecording': 'grabación de datos',
    'SessionFlow.Checklist.DataUploading': 'carga de datos',
    'SessionFlow.Checklist.Analytics': 'análisis',
    'Companies.ArchiveIconButton.Modal.ConfirmationText.ArchiveCompany': '¿Seguro que desea archivar esta empresa?',
    'Companies.ArchiveIconButton.Modal.Header.ArchiveCompany': 'Archivar empresa',
    'Companies.Label.CompanyInfo': 'Información de la empresa',
    'Companies.CompanyInfo.Archived': '(Archivada)',
    'Companies.CancelIconButton.HoverTitle.CancelEdit': 'Cancelar la edición',
    'Companies.EditIconButton.HoverTitle.EditCompany': 'Editar empresa',
    'Companies.Button.InviteProfService': 'Invitar al servicio de profesor',
    'Companies.InviteProfServiceButton.Modal.Header.NewProfService': 'Nuevo servicio de profesor',
    'Companies.Label.Config': 'CONFIGURACIÓN',
    'Companies.EditIconButton.HoverTitle.EditConfig': 'Editar configuración',
    'Companies.Config.Button.Test': 'Prueba',
    'Companies.Config.Button.SaveCompanyConfig': 'Guardar la configuración de la empresa',
    'Companies.CompanyInfo.Button.SaveCompanyInfo': 'Guardar la información de la empresa',
    'Companies.ValidationError': 'Hay errores de validación',
    'Companies.Config.SaveCompanyConfigButton.FailedToSaveConfig': 'No se ha podido guardar una configuración. Por favor, corrija los errores.',
    'Companies.Config.Text.TestPassedSuccessfully': 'Prueba superada con éxito',
    'Companies.CreateUserButton.PlaceHolder.CompanyName': 'Nombre de la empresa',
    'Companies.Button.CreateCompany': 'Crear una empresa ',
    'Companies.Error.NoCompanies': 'No hay empresas',
    'Companies.InviteProfServiceButton.Modal.Label.ProfServices': 'Servicios de profesor',
    'Companies.InviteProfServiceButton.Modal.Button.AddUser': 'Agregar usuario ',
    'Companies.CreateUserButton.Modal.Header.NewCompany': 'Nueva empresa',
    'Companies.Header.AllCompanies': 'todas las empresas',
    'MursionPortal.Roles': 'Roles',
    'Users.ArchiveButton.Modal.Header.ArchivedGlobalUser': 'Usuario global archivado',
    'Users.ActiveButton.Modal.Header.EditGlobalUser': 'Editar usuario global',
    'Users.CreateUserButton.Modal.Header.NewGlobalUser': 'Nuevo usuario global',
    'Users.NoGlobalUserFoundError': 'No se han encontrado usuarios globales.',
    'Users.Table.ColumnHeader.Department': 'Departamento',
    'MursionPortal.TechSupport.HoverTitle.ShowFeedBack': 'mostrar comentarios',
    'MursionPortal.TechSupport.HoverTitle.HideFeedBack': 'ocultar comentarios',
    'MursionPortal.TechSupport': 'Soporte técnico',
    'MursionPortal.TechSupport.Header.ContactSupport': 'Contactar con soporte',
    'MursionPortal.TechSupport.Label.Phone': 'Teléfono',
    'MursionPortal.TechSupport.Label.Email': 'Correo electrónico',
    'MursionPortal.TechSupport.Label.Hours': 'Horas',
    'MursionPortal.TechSupport.Link.MursionSupport': 'Soporte de Mursion',
    'Downloads.Text.ClickDownloadButton': 'Haga clic en el botón de descarga situado debajo para obtener la última versión del instalador. Este ejecutable se guardará en la ubicación de descargas predeterminada de su navegador (p. ej., carpeta de descargas).',
    'Downloads.Text.OnceDownloadIsComplete': 'Una vez que se complete la descarga, haga clic en el ejecutable descargado y siga las instrucciones de la pantalla para completar la instalación.',
    'Downloads.Text.AppInstalled': 'Con la aplicación instalada, puede hacer doble clic en el icono de Mursion la próxima vez que necesite iniciarla.',
    'Downloads.Button.Download': 'Descargar',
    'Downloads.Text.PlatformNotCurrentlySupported': 'Lo sentimos, pero su plataforma no es compatible actualmente.',
    'Downloads.Text.FollowOnScreenInstructions': 'Haga clic en {info} y siga las instrucciones de la pantalla para instalar la aplicación',
    'Downloads.Text.ShortcutCreatedOnInstallationComplete': 'Una vez que se complete la instalación, se creará un acceso directo a Mursion en su escritorio. {lineBreak} Puede hacer doble clic {image} en el acceso directo cuando necesite iniciar la aplicación para experimentar una simulación programada.',
    'Filters.Date.LastWeek': 'La semana pasada',
    'MursionPortal.Text.Mins': 'minutos',
    'MursionPortal.Text.Min': 'min',
    'Tutorial.Header.ManageYourTeams': 'Administre sus equipos',
    'MursionPortal.Button.Awesome': 'Impresionante',
    'Tutorial.Header.ReviewYourProjects': 'Revise sus proyectos',
    'MursionPortal.Button.LooksGood': 'Se ve bien',
    'Tutorial.Header.LearnerScheduling': 'Programación del alumno',
    'MursionPortal.Button.AllDone': '¡Terminado!',
    'Tutorial.Header.InviteYourUsers': 'Invite a sus usuarios',
    'MursionPortal.Button.GotIt': 'Entendido',
    'Tutorial.Header.CreateATeam': 'Crear un equipo',
    'Tutorial.Header.ManageYourProjects': 'Gestione sus proyectos',
    'Tutorial.Header.YourTeams': 'Sus equipos',
    'Tutorial.Header.YourProjects': 'Sus proyectos',
    'Tutorial.Header.SessionCalendar': 'Calendario de sesiones',
    'Tutorial.Tooltip.TeamsOrganize': 'Los equipos organizan a sus alumnos y se agregarán a los escenarios',
    'Tutorial.Text.ViewSessions': 'Ver las sesiones <code>próximas y completadas</code> del equipo',
    'Tutorial.Tooltip.ContactManager': 'o póngase en contacto con su jefe de proyecto de {companyName}',
    'Tutorial.Tooltip.YourPointOfContact': 'Su punto de contacto para todo lo que aprenda',
    'Tutorial.Text.TeamAdded': 'Se agregará un equipo <code>a un escenario</code> para comenzar la programación',
    'Tutorial.Text.ViewLearners': 'Ver <code>la actividad y el estado</code> de programación<code> de los alumnos</code>',
    'Tutorial.Text.EnterEmail': 'Introduzca una <code>dirección de correo electrónico</code> para cada nuevo  usuario del portal de {companyName}',
    'Tutorial.Text.RegistrationEmail': 'Se enviará un <code>correo electrónico</code> de registro a su bandeja de entrada',
    'Tutorial.Text.TeamsOrganize': 'Los equipos <code>organizan a sus alumnos</code> y son administrados por un facilitador',
    'Tutorial.Text.UniqueName': 'Póngale a su equipo un <code>nombre único</code> e introduzca una ubicación',
    'Tutorial.Text.SessionDelivery': 'Más tarde, se <code>agregará un equipo a un escenario</code> para <code>la realización de la sesión</code>',
    'Tutorial.Tooltip.Description': 'Descripción, resultado y estrategias',
    'Tutorial.Text.BeginScheduling': 'Se debe <code>agregar un equipo a un escenario</code> para comenzar la programación',
    'Tutorial.Text.MonitorSessions': 'Supervise las <code>próximas sesiones</code> y la <code>disponibilidad de cada proyecto</code>',
    'Tutorial.Text.LearnersWithoutSessions': 'Ver los alumnos que <code>aún no han programado</code> una sesión',
    'Tutorial.Tooltip.LearnersSchedule': 'Los alumnos también pueden programar su propio calendario',
    'Tutorial.Text.AddedToLearners': 'Se le agregará a <code>1 o más</code> equipos de alumnos',
    'Tutorial.Tooltip.TeamAdmin': 'Su administrador de equipo y punto de contacto',
    'Tutorial.Text.TeamAddedBeforeProject': 'Su equipo se agregará <code>a un escenario</code> antes de que comience el proyecto',
    'Tutorial.Text.AddRemoveLearners': '<code>Agregue o elimine</code> alumnos de sus <tooltip>equipos</tooltip>',
    'Tutorial.Text.AddedByCompany': 'El <tooltip>propietario de la cuenta</tooltip> de su empresa le agregará a un escenario/proyecto',
    'Tutorial.Text.ViewYourHours': 'Vea sus horarios, escenarios o póngase en contacto con su <tooltip><code>jefe de proyecto</code></tooltip>',
    'Tutorial.Text.ScenariosDetail': 'Los escenarios contienen los <tooltip>detalles</tooltip> que conforman su <code>{companyName} experiencia</code>',
    'Tutorial.Text.SelectTimeSlot': 'Seleccione un intervalo de tiempo abierto para <tooltip><code>programar a un alumno</code></tooltip> para una sesión',
    'Tutorial.Text.ChooseRole': 'Elija un rol (<tooltip>propietario de cuenta</tooltip>, <secondTooltip>facilitador</secondTooltip> o <thirdTooltip>alumno</thirdTooltip>) para cada nuevo usuario',
    'Tutorial.Tooltip.AdminManages': 'Administrador que gestiona usuarios y proyectos',
    'Tutorial.Tooltip.ManagesScheduling': 'Gestiona la programación y la realización de sesiones',
    'Tutorial.Tooltip.SchedulesSessions': 'Programa y asiste a sesiones',
    'Tutorial.Text.ScenariosContain': 'Los escenarios contienen los <tooltip>detalles</tooltip> que conforman su <code>{companyName} experiencia</code>',
    'Tutorial.Text.SelectOpenTimeSlot': 'Seleccione un intervalo de tiempo abierto para <tooltip><code>programar a un alumno</code></tooltip>',
    'Tutorial.Text.AdminOfTeam': 'El <tooltip>facilitador</tooltip> es el administrador y el punto de contacto de su equipo',
    'Tutorial.Text.ProjectsContain': 'Los proyectos contienen <code>escenarios</code>, con los <tooltip>detalles</tooltip> que conforman su <code>experiencia de aprendizaje</code>',
    'Tutorial.Text.ViewAvailability': 'Ver la disponibilidad y <code>programar sesiones</code>',
    'Tutorial.Text.TrackSessions': 'Realice un seguimiento <code>de las próximas sesiones</code> de cada proyecto. Cancelar/reprogramar si es necesario',
    'Tutorial.Text.ReviewPerformance': 'Revise su <code>rendimiento</code> y las <code>estrategias de finalización</code> después de su sesión',
    'Tutorial.Header.Complete': 'Completada',
    'Tutorial.Text.RevisitThisGuide': 'Puede volver a ver esta guía y otras informaciones útiles {lineBreak} <code>en cualquier momento</code> desde la <code>Base de conocimientos. </code>',
    'Tutorial.Button.TakeMeToThePortal': 'Llevarme al portal',
    'Tutorial.Header.DownloadInstallMursionSimulator': 'Descargue e instale el {lineBreak} simulador de Mursion',
    'Tutorial.Text.PlanningToUseMursionOnComputer': 'Si piensa usar este ordenador para realizar simulaciones con Mursion, le recomendamos encarecidamente que instale ahora el software necesario haciendo clic en el botón «Instalar Mursion» situado debajo.',
    'Tutorial.Text.InstallSoftwareFromPortal': 'Puede optar por instalar más tarde el software directamente desde el portal. Tenga en cuenta que el simulador inmersivo de Mursion puede necesitar varios minutos para instalarse por primera vez. Por favor, instale el software al menos 30 minutos antes de que comience su simulación, para así tener tiempo suficiente y poder disfrutar plenamente de la experiencia Mursion.',
    'Tutorial.Text.ProvideInstructionsForInstallation': 'Ocasionalmente, seguiremos ofreciendo instrucciones para la instalación y otros flujos de trabajo a través de correos electrónicos y tutoriales dentro del portal y en el simulador de Mursion. ¡Esperamos que disfrute de sus simulaciones tanto como nosotros disfrutamos creándolas!',
    'Tutorial.Text.TheMursionTeam': 'El equipo de Mursion.',
    'Tutorial.Button.InstallLater': 'Instalar más tarde',
    'Tutorial.Button.InstallMursion': 'Instalar Mursion',
    'Tutorial.Header.LetsGetStarted': 'Comencemos',
    'Tutorial.Text.ShortIntro': 'En primer lugar, aquí tiene una <code>breve introducción</code> que muestra algunas de {lineBreak} las funciones habituales de <code>su rol de usuario</code>.',
    'Tutorial.Button.Proceed': 'Continuar',
    'Tutorial.Label.Step': 'PASO',
    'Tutorial.Label.NoSound': 'Este vídeo no tiene sonido.',
    'Tutorial.Button.SkipTutorial': 'Omitir tutorial',
    'Tutorial.Button.CloseTutorial': 'Cerrar tutorial',
    'MursionPortal.Text.On': 'Encendido',
    'MursionPortal.Text.Off': 'Apagado',
    'MursionPortal.Text.MursionIcon': 'icono-de-mursion',
    'MursionPortal.AriaLabel.CreateNew': 'crear nuevo',
    'MursionPortal.Legend.VisibilityHidden.Buttons': 'Crear nuevo, Repetir cliente, Repetir botones del escenario del banco',
    'MursionPortal.Label.VisibilityHidden.EnterEmailId': 'Introduzca la identificación del correo electrónico',
    'MursionPortal.Label.VisibilityHidden.SelectRegistrationStatus': 'Seleccione el Estado de registro',
    'MursionPortal.Label.VisibilityHidden.ClientLogoUpload': 'cargar logo de cliente',
    'MursionPortal.Header.VisibilityHidden.CompanyUsers': 'Usuarios de la empresa',
    'MursionPortal.Legend.VisibilityHidden.StationType': 'Tipo de puesto',
    'MursionPortal.Legend.VisibilityHidden.Hours': 'Horas',
    'MursionPortal.Caption.VisibilityHidden.CompanyUsersList': 'lista de usuarios de la empresa',
    'MursionPortal.AriaLabel.Search': 'buscar',
    'MursionPortal.AriaLabel.From': 'Desde',
    'MursionPortal.AriaLabel.To': 'Hasta',
    'MursionPortal.AriaLabel.RemoveAccountOwner': 'eliminar el propietario de la cuenta',
    'MursionPortal.AriaLabel.InboxMessagePanel': 'panel de mensajes de la bandeja de entrada',
    'MursionPortal.AriaLabel.Breadcrumb': 'ruta de navegación',
    'MursionPortal.Message.NoItemsHaveBeenFound': 'No se ha encontrado ningún elemento.',
    'MursionPortal.AriaLabel.Add': 'añadir',
    'MursionPortal.AriaLabel.SortByDescendingOrder': 'ordenar de manera descendente',
    'MursionPortal.AriaLabel.SortByAscendingOrder': 'ordenar de manera ascendente',
    'MursionPortal.AriaLabel.AddButton': 'añadir botón',
    'MursionPortal.AriaLabel.Timezone': 'zona horaria',
    'MursionPortal.AriaLabel.Combobox': 'casilla combinada',
    'MursionPortal.AriaLabel.PreviousMonth': 'mes anterior',
    'MursionPortal.AriaLabel.NextMonth': 'mes próximo',
    'MursionPortal.AriaLabel.SearchInput': 'buscar entrada',
    'MursionPortal.AriaLabel.Informational': 'Informativo',
    'MursionPortal.Text.RedirectToCalendarPage': 'redirigir a la página del calendario',
    'MursionPortal.AriaLabel.StartDate': 'fecha de inicio {startFormattedDate}',
    'MursionPortal.AriaLabel.EndDate': 'fecha de finalización {endFormattedDate}',
    'MursionPortal.AriaLabel.CreateProject': 'crear proyecto',
    'MursionPortal.AriaLabel.ContentInfo': 'información del contenido',
    'MursionPortal.AriaLabel.DraftSuperScript': 'Superíndice del borrador {statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedSuperScript': 'Superíndice finalizado {statusConfiguration}',
    'MursionPortal.AriaLabel.StartedSuperScript': 'Superíndice iniciado {statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedSuperScript': 'Superíndice terminado {statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedSuperScript': 'Superíndice archivado {statusConfiguration}',
    'MursionPortal.AriaLabel.EnterScenarioName': 'introduzca el nombre del escenario',
    'MursionPortal.AriaLabel.Capitalized.Search': 'Buscar',
    'MursionPortal.AriaLabel.Close': 'Cerrar',
    'MursionPortal.AriaLabel.CreateTeam': 'crear equipo',
    'MursionPortal.AriaLabel.CreateClient': 'crear cliente',
    'MursionPortal.AriaLabel.CreateUser': 'crear usuario',
    'MursionPortal.AriaLabel.ZoomId': 'Identificación de Zoom',
    'MursionPortal.AriaLabel.PrimaryContent': 'Contenido principal',
    'MursionPortal.AriaLabel.RegistrationStatus': 'estado del registro',
    'MursionPortal.AriaLabel.EnterTagName': 'introducir el nombre de la etiqueta',
    'MursionPortal.AriaLabel.EnterUserName': 'introducir el nombre de usuario',
    'MursionPortal.AriaLabel.SearchProjectManagers': 'buscar jefes de proyecto',
    'MursionPortal.AriaLabel.SearchSSO': 'buscar SSO',
    'MursionPortal.AriaLabel.SearchAccountOwner': 'buscar el propietario de la cuenta',
    'MursionPortal.WarningMessage.AllowedCharacterLimit': 'Se ha superado el límite de caracteres permitido ({maximumLength} caracteres)',
    'MursionPortal.WarningMessage.InvalidCharactersDetected': 'Se han detectado caracteres no válidos',
    'MursionPortal.Days.Sunday': 'Domingo',
    'MursionPortal.Days.Monday': 'Lunes',
    'MursionPortal.Days.Tuesday': 'Martes',
    'MursionPortal.Days.Wednesday': 'Miércoles',
    'MursionPortal.Days.Thursday': 'Jueves',
    'MursionPortal.Days.Friday': 'Viernes',
    'MursionPortal.Days.Saturday': 'Sábado',
    'MursionPortal.Button.AddNew': 'Añadir nuevo',
    'MursionPortal.Title.EditNotes': 'Editar notas',
    'MursionPortal.IconButton.Remove': 'eliminar',
    'MursionPortal.Filter.ProviderAll': 'Proveedor',
    'MursionPortal.Placeholder.SearchProviders': 'Buscar proveedores...',
    'MursionPortal.AriaLabel.InboxButton': 'botón de la bandeja de entrada Tiene {messageCount} mensajes nuevos',
    'MursionPortal.AriaLabel.Event': 'evento',
    'MursionPortal.Title.SelectedDate': 'Fecha seleccionada',
    'MursionPortal.Title.CurrentDate': 'Fecha actual',
    'MursionPortal.Title.OffRangeDate': 'fecha fuera de rango',
    'MursionPortal.Label.ClientLevel': 'Nivel-de-cliente',
    'MursionPortal.Label.TeamLevel': 'Nivel-de-equipo',
    'MursionPortal.AltText.UserImage': 'imagen-del-usuario',
    'MursionPortal.AltText.KbMenu': 'menú-kb',
    'MursionPortal.AltText.AvatarImage': 'imagen-de-avatar',
    'MursionPortal.AltText.EnvironmentImage': 'imagen-del-entorno',
    'MursionPortal.AriaLabel.Cancel': 'cancelar',
    'MursionPortal.AriaLabel.Edit': 'editar',
    'MursionPortal.AriaLabel.EditAccountOwner': 'editar propietario de la cuenta',
    'MursionPortal.AriaLabel.Remove': 'eliminar',
    'MursionPortal.AriaLabel.SearchFilter': 'buscar filtro',
    'MursionPortal.AriaLabel.TierCombobox': 'casilla combinada de nivel',
    'MursionPortal.AriaLabel.SortByAscending': 'ordenar por ascendente',
    'MursionPortal.AriaLabel.SortByDescending': 'ordenar por descendente',
    'MursionPortal.Message.VisibilityHidden.SelectingDeliveryMode': 'seleccionar un modo de realización permitirá configurar la duración de la sesión y el tamaño de la misma',
    'MursionPortal.AltText.DownloadComputerIcon': 'Icono para ordenador de la descarga de Mursion',
    'MursionPortal.AltText.DownloadIcon': 'Icono de descarga de Mursion',
    'MursionPortal.AltText.MursionLogo': 'Logo de Mursion',
    'MursionPortal.AriaLabel.MursionSupport': 'soporte de Mursion',
    'MursionPortal.AltText.MursionDashboard': 'Panel-de-control-de-Mursion',
    'MursionPortal.AltText.BackgroundMaleUser': 'Usuario masculino de fondo en Mursio',
    'MursionPortal.AltText.BackgroundGroupUser': 'Usuario grupal de fondo en Mursion',
    'MursionPortal.AltText.BackgroundFemaleUser': 'Usuario femenino de fondo en Mursion',
    'MursionPortal.ErrorMessage.CharacterLimitExceeded': 'Se ha superado el límite de caracteres permitido ({maximumLength} caracteres)',
    'MursionPortal.ErrorMessage.InvalidCharactersDetected': 'Se han detectado caracteres no válidos',
    'MursionPortal.ErrorMessage.EnterValidPhoneNumber': 'Debe introducir un número de teléfono válido.',
    'MursionPortal.Message.InvitationLinkInvalid': 'Su enlace de invitación no es válido o puede haber caducado. {lineBreak} Si aún no ha terminado de registrarse {lineBreak}, solicite una nueva invitación a su responsable.',
    'MursionPortal.Header.SetYourPassword': 'Establece tu contraseña',
    'MursionPortal.Message.PasswordMessage': 'Su contraseña debe contener lo siguiente:',
    'MursionPortal.Label.NewPassword': 'Nueva contraseña',
    'MursionPortal.Label.RepeatPassword': 'Repetir contraseña',
    'MursionPortal.Button.SetPassword': 'Establecer contraseña',
    'MursionPortal.Header.ErrorMessage.SomethingWentWrong': 'Se ha producido un {lineBreak} error, póngase en contacto {lineBreak} con soporte',
    'MursionPortal.Header.ContactSupport': 'CONTACTAR CON SOPORTE',
    'MursionPortal.Label.Phone': 'Teléfono',
    'MursionPortal.Label.Email': 'Correo electrónico',
    'MursionPortal.Label.Hours': 'Horas',
    'MursionPortal.Message.EnterPasswordToConfirm': 'Por favor, introduzca su contraseña para confirmar este cambio',
    'MursionPortal.Label.Password': 'Contraseña',
    'MursionPortal.Message.BrowserDialog': 'Si no ve el cuadro de diálogo del navegador, haga clic en <code>Abrir Mursion</code>, situado debajo {lineBreak}, o utilice el acceso directo del escritorio de Mursion para abrir la aplicación de Mursion.',
    'MursionPortal.Button.OpenMursion': 'Abrir ML3 Desktop App',
    'MursionPortal.Message.FollowInstructions': 'Haga clic en {appName}y siga las instrucciones de la pantalla para instalar la aplicación',
    'MursionPortal.Text.PositiveImpact': 'Cuando crea que está teniendo un impacto positivo, haga clic en el botón <code>{icon}</code>.',
    'MursionPortal.Text.NegativeImpact': 'Cuando crea que está teniendo un impacto negativo, haga clic en el botón <code>{icon}</code>.',
    'MursionPortal.Text.NeutralImpact': 'Cuando cera que el impacto no ha sido ni positivo ni negativo, haga clic en el botón <code>{icon}</code>.',
    'MursionPortal.Title.Next': 'Siguiente',
    'MursionPortal.Title.Start': 'Comenzar',
    'MursionPortal.Text.UpdateYourRating': 'Mientras ve el vídeo, puede actualizar su calificación en el avatar en cualquier momento que crea que está teniendo un impacto diferente.',
    'MursionPortal.Text.SeeCurrentRating': 'El gráfico situado debajo del vídeo se actualizará para que pueda ver su calificación actual y modificarla si cree que su impacto ha cambiado.',
    'MursionPortal.Text.ClickStartToBegin': 'Haga clic en «Comenzar» para empezar. Puede hacer clic en «Completada» después de haber calificado toda la simulación. Tenga en cuenta que la función de búsqueda en el vídeo puede estar desactivada durante algunas partes del mismo para preservar la integridad de los datos.',
    'MursionPortal.Header.HowWouldYouProceed': '¿Cómo le gustaría continuar?',
    'MursionPoral.Button.SwitchUser': 'Cambiar usuario',
    'MursionPortal.Button.ContinueAs': 'Continuar como <code>{userName}</code>',
    'MursionPortal.Text.ToContinue': 'Para continuar como <code>{loginData}</code>, haga clic en <code>Cambiar de usuario</code>.',
    'MursionPortal.Message.YouAreSignedIn': 'Actualmente está registrado como <code>{userName}</code> ({userProfile}).',
    'MursionPortal.Title.ContinueAs': 'Continuar como {userName}',
    'MursionPortal.Message.OpenLink': 'Abrir enlace',
    'MursionPortal.Message.Allow': 'Permitir',
    'MursionPortal.Message.Open': 'Abrir',
    'MursionPortal.Message.Yes': 'Sí',
    'MursionPortal.Message.OpenMursionApp': 'Abrir MursionApp',
    'MursionPortal.PasswordRequirementMessage.MinimumCharacters': 'Al menos {PASSWORD_MIN_LENGTH} caracteres',
    'MursionPortal.Message.PortalHeading': 'Diseñe, programe y administre su',
    'MursionPortal.PasswordRequirementMessage.UppercaseLetters': 'Mayúsculas + Minúsculas',
    'MursionPortal.PasswordRequirementMessage.Numbers': 'cualquier combinación de números',
    'MursionPortal.ErrorMessage.InvalidEmailFormat': 'Formato de correo electrónico no válido.',
    'MursionPortal.ErrorMessage.EmailCanNotBeEmpty': 'El correo electrónico no puede estar vacío.',
    'MursionPortal.ErrorMessage.EnterValidEmailAddress': 'Por favor, introduzca una dirección de correo electrónico válida.',
    'MursionPortal.ErrorMessage.PasswordCannotBeEmpty': 'La contraseña no puede estar vacía',
    'MursionPortal.ErrorMessage.UrlCannotBeEmpty': 'La url no puede estar vacía',
    'MursionPortal.ErrorMessage.UrlIsNotValid': 'La url no es válida',
    'MursionPortal.ErrorMessage.NoMeetingId': 'No contiene la identificación de la reunión',
    'MursionPortal.ValidationErrorMessage.FileRequestFailed': 'Solicitud de archivo fallida',
    'MursionPortal.ValidationErrorMessage.FileExceedsLimit': 'El archivo supera el límite de 5 MB',
    'MursionPortal.ValidationErrorMessage.EnterMessage': 'Por favor, introduzca su mensaje antes de enviar',
    'MursionPortal.ValidationErrorMessage.MessageTooLong': 'Mensaje demasiado largo',
    'MursionPortal.ValidationErrorMessage.InvalidUrl': 'La URL del archivo no es válida',
    'MursionPortal.Button.SubmitFeedback': 'Enviar comentario',
    'MursionPortal.Header.Feedback': 'Comentario',
    'MursionPortal.Text.AdditionalComments': 'Comentarios o sugerencias adicionales',
    'MursionPortal.Header.ThanksForFeedback': '¡Gracias por sus comentarios!',
    'MursionPortal.Button.Close': 'Cerrar',
    'MursionPortal.Title.ShowFeedback': 'mostrar comentarios',
    'MursionPortal.Title.HideFeedback': 'ocultar comentarios',
    'MursionPortal.ErrorMessage.FileTypeNotSupported': 'El tipo de archivo {type} no es compatible',
    'MursionPortal.ErrorMessage.ScenarioDuration': 'La duración del escenario debe estar entre {durationMinimum} y {durationMaximum}.',
    'MursionPortal.ErrorMessage.ScenarioScheduledSessions': 'Hay {sessionsCount} sesiones programadas para este escenario. Cancele estas sesiones antes de archivar este escenario.',
    'MursionPortal.ErrorMessage.ScenarioScheduledSession': 'Hay {sessionsCount} sesión programada para este escenario. Cancele esta sesión antes de archivar este escenario.',
    'MursionPortal.ErrorMessage.ClientScheduledSessions': 'Hay {sessionsCount} sesiones programadas para este cliente. Cancele estas sesiones antes de archivar el cliente.',
    'MursionPortal.ErrorMessage.ClientScheduledSession': 'Hay {sessionsCount}sesión programada para este cliente. Cancele esta sesión antes de archivar el cliente.',
    'MursionPortal.EulaAgreement.EndUserAgreement': 'Este acuerdo de usuario final («acuerdo») es un acuerdo legal entre usted, el usuario y Mursion, Inc. («Mursion») y está sujeto a los términos y condiciones del software de Mursion como acuerdo de servicio entre Mursion y la entidad cliente de Mursion a través de la cual está utilizando el software de Mursion. Para mayor claridad, usted reconoce y acepta que, como condición para el uso y acceso al software de Mursion («software de Mursion»), usted, así como la entidad que le proporciona acceso al software de Mursion, están sujetos y obligados por los términos y condiciones de este acuerdo.',
    'MursionPortal.EulaAgreement.TermsAndConditions': 'Sujeto a los términos y condiciones de este acuerdo y del software de Mursion como acuerdo de servicio, Mursion le otorga derechos limitados, no exclusivos, no transferibles y no sublicenciables para acceder y utilizar el software de Mursion.',
    'MursionPortal.EulaAgreement.AgreeAndAcknowledge': 'Usted acepta y reconoce que no tiene y no tendrá derecho a copiar, modificar, realizar ingeniería inversa, descompilar, desensamblar o crear obras derivadas basadas, total o parcialmente, en el software de Mursion o en materiales relacionados con este. Igualmente, acepta no anular, deshabilitar ni eludir ningún mecanismo de protección relacionado con el software de Mursion. Cuando tenga conocimiento de cualquier posesión, uso o acceso no autorizado al software de Mursion, lo notificará a Mursion de inmediato, y además acepta proporcionar con prontitud los detalles de cada uno de esos eventos, para ayudar de manera razonable a prevenir cualquier repetición de los mismos (en la medida de lo posible) y cooperar plenamente en cualquier procedimiento que se emprenda para proteger los derechos de Mursion.',
    'MursionPortal.EulaAgreement.ConstruedAsGranting': 'Aparte de lo indicado explícitamente en este documento, nada de lo contenido en el presente acuerdo se interpretará como una concesión, implícita o de otro modo, de derechos adicionales respecto a la propiedad intelectual de Mursion sin la aprobación previa y por escrito de Mursion.',
    'MursionPortal.EulaAgreement.NonDisclosure': 'Usted acepta no mostrar ni divulgar a nadie el software de Mursion o cualquier parte del mismo para cualquier propósito que no sea el expresamente permitido en este documento.',
    'MursionPortal.EulaAgreement.AgreeAndRelease': 'Usted acepta, y por la presente, libera, exime y exonera de toda responsabilidad a Mursion, sus empleados, responsables, agentes, afiliados y directores ante todas y cada una de las reclamaciones, conocidas y desconocidas, siempre que surjan, resulten, provengan o estén relacionadas con su uso del software de Mursion.',
    'MursionPortal.EulaAgreement.UnderstandAndAcknowledge': 'Usted comprende y reconoce que Mursion puede recopilar, mantener, usar, almacenar y analizar información y datos recopilados a partir de su uso del software de Mursion, sujeto a los términos de la política de privacidad de Mursion que se pueden encontrar aquí.',
    'MursionPortal.EulaAgreement.AccessRights': 'Los derechos de uso y acceso que le otorga Mursion terminarán inmediatamente cuando el software de Mursion, como acuerdo de servicio mediante el cual se le proporciona acceso al software de Mursion, expire o finalice, o inmediatamente después de que incumpla cualquier disposición de este acuerdo.',
    'MursionPortal.EulaAgreement.AgreementGoverned': 'Este acuerdo se regirá e interpretará de acuerdo con las leyes del Estado de Delaware, con independencia de los conflictos con los principios legales que surjan del mismo. Todas las disputas que surjan o estén relacionadas con su uso del software de Mursion o con este acuerdo se llevarán ante un tribunal estatal o federal de jurisdicción competente del Estado de Delaware y, por la presente, usted da su consentimiento a la jurisdicción personal en dichos tribunales.',
    'MursionPortal.EulaAgreement.ProvisionsDeclaredInvalid': 'Si alguna de las disposiciones de este acuerdo se declara no válida, dichas disposiciones se separarán del mismo, mientras que las demás disposiciones permanecerán en pleno vigor y efecto.',
    'MursionPortal.EulaAgreement.AgreementConflict': 'En caso de que los términos de este acuerdo entren en conflicto con los términos del software de Mursion como acuerdo de servicio, regirán los términos aquí incluidos.',
    'MursionPortal.EulaAgreement.AccessToMursionSofware': 'Su uso o acceso al software de Mursion constituye la aceptación de este acuerdo y de los términos y condiciones aquí establecidos.',
    'MursionPortal.Header.MursionUserAgreement': 'Acuerdo entre el usuario y Mursion',
    'MursionPortal.Button.Back': 'Volver',
    'MursionPortal.Button.Skip': 'Omitir',
    'MursionPortal.Title.BackToSessions': 'Volver a a las sesiones',
    'MursionPortal.Title.Proceed': 'Continuar',
    'MursionPortal.Message.SkipActivity': 'Si elige la opción «omitir», perderá la información sobre el rendimiento que pueda proporcionar nuestro motor de análisis del comportamiento. Le recomendamos que no omita esta actividad.',
    'MursionPortal.HeaderTitle.ConfirmCancellation': 'Confirmar la cancelación',
    'MursionPortal.Label.Delete': 'borrar',
    'MursionPortal.Label.Restore': 'restaurar',
    'MursionPortal.HeaderTitle.ConfirmRestore': 'Confirmar la restauración',
    'MursionPortal.Label.Archive': 'archivo',
    'MursionPortal.Button.CancelTraining': 'Cancelar la formación',
    'MursionPortal.Button.No': 'No',
    'MursionPortal.Button.CancelEvent': 'Cancelar el evento',
    'MursionPortal.Label.Update': 'actualizar',
    'MursionPortal.HeaderTitle.ConfirmUpdate': 'Confirmar la actualización',
    'MursionPortal.Button.UpdateSession': 'Actualizar la sesión',
    'MursionPortal.Label.Swap': 'intercambiar',
    'MursionPortal.HeaderTitle.ConfirmAcceptedSwap': 'Confirmar el intercambio aceptado',
    'MursionPortal.Button.AcceptSwap': 'Aceptar el intercambio',
    'MursionPortal.Label.Reason': 'Motivo',
    'MursionPortal.Placeholder.ReasonForSwapping': 'Por favor, añada un motivo para el intercambio.',
    'MursionPortal.Label.Request': 'solicitud',
    'MursionPortal.HeaderTitle.ConfirmAcceptedRequest': 'Confirmar la solicitud aceptada',
    'MursionPortal.Button.AcceptRequest': 'Aceptar solicitud',
    'MursionPortal.Placeholder.ReasonForAccepting': 'Por favor, añada un motivo para aceptar.',
    'MursionPortal.Button.ConfirmProjectChange': 'Confirmar el cambio de proyecto',
    'MursionPortal.HeaderTitle.Confirm': 'Confirmar',
    'MursionPortal.Button.ConfirmCreateSession': 'Confirmar la creación de la sesión',
    'MursionPortal.Placeholder.ReasonForCreation': 'Por favor, añada un motivo para la creación.',
    'MursionPortal.Button.ConfirmAssigningSimSpecialist': 'Confirmar la asignación del especialista en simulación',
    'MursionPortal.Placeholder.ReasonForAssigning': 'Por favor, añada un motivo para la asignación.',
    'MursionPortal.Button.ConfirmChangingSimSpecialist': 'Confirmar el cambio de especialista en simulación',
    'MursionPortal.Placeholder.ReasonForChanging': 'Por favor, añada un motivo para el cambio.',
    'MursionPortal.HeaderTitle.ConfirmTimeZone': 'Confirmar la zona horaria',
    'MursionPortal.Message.DontHaveMursionInstalled': '¿No tiene ML3 Desktop App instalado? Haga clic en <code>Descargar</code> para instalarlo.',
    'MursionPortal.ErrorMessage.CannotContainCharacter': 'El nombre del escenario no debe contener el carácter "_"',
    'MursionPortal.ErrorMessage.EnterDifferentName': '{capitalized} {fieldName} ya existe. Por favor, introduzca un {objectName} {fieldName}.',
    'MursionPortal.ErrorMessage.EnterDifferentEmail': 'El correo electrónico del usuario ya existe. Por favor, introduzca una dirección de correo electrónico distinta.',
    'MursionPortal.ErrorMessage.NameExists': 'Ya existe un usuario con el mismo {fieldName}. Por favor, introduzca un {fieldName} distinto.',
    'MursionPortal.ErrorMessage.UnableToRemove': 'No se puede eliminar {entity}. Esta {entity} se está utilizando actualmente en un activo {depEntity}.',
    'MursionPortal.ErrorMessage.OverlapsExistingTimeBlock': 'El bloque de tiempo seleccionado se solapa con un bloque de tiempo existente.',
    'MursionPortal.ErrorMessage.AccessIsDenied': 'El acceso se ha denegado.',
    'MursionPortal.ErrorMessage.SomethingMustBeBroken': 'Algo debe estar mal. Por favor, inténtelo de nuevo más tarde.',
    'MursionPortal.ErrorMessage.Unauthorized': 'No autorizado',
    'MursionPortal.ErrorMessage.RefreshTokenNotFound': 'Actualizar token no encontrado',
    'MursionPortal.ErrorMessage.CannotRefreshToken': 'No se puede actualizar el token',
    'MursionPortal.QADialog.NoParticipants': 'Sin participantes',
    'MursionPortal.ErrorMessage.UsersUnavailable': 'Algunos de sus usuarios no están disponibles en la hora seleccionada.',
    'Filters.SsoProtocolType': 'Protocolo: Todos',
    'Filters.SsoProtocolTypePlaceHolderHint': 'Buscar protocolo SSO',
    'Filters.SSO.PrivacyAll': 'Tipo todo',
    'Filters.SSO.PrivacyPublic': 'Tipo público',
    'Filters.SSO.PrivacyPrivate': 'Tipo privado',
    'Filters.SSO.PrivacyAriaLabel': 'filtro de tipo de privacidad sso',
    'Cleints.Modal.Label.DomainsTitle': 'La lista de dominios que se pueden usar para iniciar sesión',
    'MursionPortal.NoDomains': 'Sin dominios',
    'MursionPortal.Header.Domains': 'Dominios',
    'MursionPortal.AddDomain': 'Añadir dominio',
    'Clients.Modal.Projects.ButtonTitle.EditDomains': 'Editar dominios',
    'Clients.Modal.Projects.DomainField.EnterDomain': 'Introduzca un nombre de dominio (p. ej., ejemplo.com)',
    'Clients.Modal.Projects.DomainField.InvalidDomain': 'Formato de dominio no válido',
    'MursionPortal.ErrorMessage.SomethingWrongWithResponse': 'Se ha producido un error con la respuesta.',
    'MursionPortal.ErrorMessage.AuthorizationHeaderIsEmpty': 'El encabezado de autorización está vacío',
    'MursionPortal.ErrorMessage.CannotRefreshAccessToken': 'No se puede actualizar el token de acceso',
    'MursionPortal.ErrorMessage.AuthorizationTokenIsEmpty': 'El token de autorización está vacío',
    'MursionPortal.ErrorMessage.AuthenticationFailed': 'La autenticación ha fallado.',
    'MursionPortal.ErrorMessage.UnsupportedResponseFormat': 'Formato de respuesta no compatible',
    'MursionPortal.ErrorMessage.AccountBlocked': 'Su cuenta ha sido bloqueada hasta el {blockedDate}. Intente iniciar sesión más tarde.',
    'MursionPortal.ErrorMessage.AttemptsRemaining': 'Quedan {attempts} intentos antes de un bloqueo de 24 horas. Si necesita ayuda, por favor, restablezca su contraseña.',
    'MursionPortal.ErrorMessage.ClientIdMustBeSpecified': 'Debe especificarse la identificación del cliente',
    'MursionPortal.ErrorMessage.ProjectIdMustBeSpecified': 'Debe especificarse la identificación del proyecto',
    'MursionPortal.ErrorMessage.SessionIdMustBeSpecified': 'Debe especificarse la identificación de la sesión',
    'MursionPortal.ErrorMessage.SSOIdMustBeSpecified': 'Debe especificarse la identificación del SSO',
    'MursionPortal.ErrorMessage.LearnerIdMustBeSpecified': 'Debe especificarse la identificación del alumno',
    'MursionPortal.ErrorMessage.UserIdMustBeSpecified': 'Debe especificarse la identificación de usuario',
    'MursionPortal.ErrorMessage.TeamIdMustBeSpecified': 'Debe especificarse la identificación del equipo',
    'MursionPortal.ErrorMessage.AssetProjectIdMustBeSpecified': 'Debe especificarse la identificación del proyecto del activo',
    'MursionPortal.ErrorMessage.AssetSettingsIdMustBeSpecified': 'Debe especificarse la identificación de configuración del activo',
    'MursionPortal.ErrorMessage.ScenarioIdMustBeSpecified': 'Debe especificarse la identificación del escenario',
    'MursionPortal.ErrorMessage.ScenarioTemplateIdMustBeSpecified': 'Debe especificarse la identificación de la plantilla de escenario',
    'MursionPortal.ErrorMessage.LicenseIdMustBeSpecified': 'fetchUsersList : Debe especificarse la identificación del licenciatario',
    'MursionPortal.ErrorMessage.LicenseCertificateIdMustBeSpecified': 'fetchLicenseeCertificates: Debe especificarse la identificación del licenciatario',
    'MursionPortal.ErrorMessage.LicenseConfigIdMustBeSpecified': 'fetchConfig: Debe especificarse la identificación del licenciatario',
    'MursionPortal.ErrorMessage.LicenseUpdateConfigIdMustBeSpecified': 'updateConfig: Debe especificarse la identificación del licenciatario',
    'MursionPortal.ErrorMessage.LicenseTestConfigIdMustBeSpecified': 'testConfig: Debe especificarse la identificación del licenciatario',
    'MursionPortal.ErrorMessage.LicenseFetchTiersIdMustBeSpecified': 'fetchTiers: Debe especificarse la identificación del licenciatario',
    'MursionPortal.ErrorMessage.LicenseUpdateTiersIdMustBeSpecified': 'updateTiers: Debe especificarse la identificación del licenciatario',
    'MursionPortal.ErrorMessage.GlobalUserIdMustBeSpecified': 'Debe especificarse la identificación de usuario global',
    'MursionPortal.ErrorMessage.ScenarioTemplateListLicenseIdMustBeSpecified': 'fetchScenarioTemplatesList: Debe especificarse la identificación del licenciatario',
    'MursionPortal.ErrorMessage.ScenarioAttachmentListIdMustBeSpecified': 'fetchScenarioAttachmentLink: Deben especificarse la identificación del escenario y la identificación del archivo adjunto',
    'MursionPortal.ErrorMessage.FetchScenarioIdMustBeSpecified': 'fetchScenario: Debe especificarse la identificación del escenario',
    'Projects.ProjectInfo.Checkbox.AllowRecordingInfo': 'Esto no eliminará ninguna grabación guardada actualmente.',
    'MursionPortal.AriaLabel.ToggleMenu': 'alternar menú',
    'MursionPortal.ClientListItem.RedirectToUserDetails': 'Redirigir a los detalles de {name}',
    'MursionPortal.AriaLabel.PreviousWeek': 'semana anterior',
    'MursionPortal.AriaLabel.PreviousDay': 'día anterior',
    'MursionPortal.AriaLabel.NextWeek': 'semana siguiente',
    'MursionPortal.AriaLabel.NextDay': 'día anterior',
    'Dashboard.LeftPane.CategoryHeader.SessionsDashboard': 'Panel de sesiones',
    'MursionPortal.Title.SessionsPage': 'Página de sesiones',
    'Clients.heading.Clients': 'Clientes',
    'MursionPortal.AriaLabel.Client': 'cliente',
    'MursionPortal.AriaLabel.SimSpecialist': 'Especialista en simulación',
    'MursionPortal.Project.TimeZoneStartDate': 'Fecha de inicio de la zona horaria',
    'MursionPortal.Project.TimeZoneEndDate': 'Fecha de finalización de la zona horaria',
    'MursionPortal.AriaLabel.SelectTimezoneCombobox': 'seleccione el cuadro combinado de la zona horaria',
    'MursionPortal.Support.PhoneNumber': '1-855-999-5818',
    'MursionPortal.Message.LearnerEmpty': 'Por favor, seleccione los alumnos que participaron en la sesión',
    'Dashboard.ProjectsTable.StatusValue.Active': 'ACTIVO',
    'Dashboard.ProjectsTable.StatusValue.Completed': 'COMPLETADO',
    'Dashboard.ProjectsTable.StatusValue.Archived': 'ARCHIVADO',
    'MursionPortal.Button.Refresh': 'Actualizar',
    'MursionPortal.Prompt.CalendarUpdated': 'El calendario se ha actualizado.',
    'MursionPortal.Prompt.ClickRefresh': 'Haga clic en «Actualizar» para ver los cambios.',
    'MursionPortal.Label.SessionAttendance': 'Asistencia a la sesión',
    'MursionPortal.Label.SessionVideoUrl': 'URL del vídeo de la sesión',
    'MursionPortal.Label.LearnerAttendance': 'Asistencia del alumno',
    'MursionPortal.Text.UserProfileUpdated': '¡Se ha actualizado un perfil de usuario!',
    'MursionPortal.Text.ClientUpdated': '¡Se ha actualizado un cliente!',
    'MursionPortal.Text.ProjectUpdated': '¡Se ha actualizado un proyecto!',
    'MursionPortal.Text.ScenarioUpdated': '¡Se ha actualizado un escenario!',
    'MursionPortal.Text.LicenseeConfigUpdated': '¡Se ha actualizado la configuración del titular de la licencia!',
    'MursionPortal.Prompt.TrainingScheduled': '¡Se ha programado la <code>formación</code>!',
    'Settings.SSO.Table.ColumnHeader.ClientName': 'Nombre del cliente',
    'MursionPortal.Text.UserCreatedSuccessfully': 'Se ha creado el usuario correctamente',
    'MursionPortal.Text.UserNotCreated': 'El usuario no se ha creado',
    'MursionPortal.Text.AssignedTeams': 'Equipos asignados',
    'MursionPortal.Client.EmailSettings.Invitation': 'Invitación',
    'MursionPortal.Client.EmailSettings.InvitationReminder': 'Recordatorio de invitación',
    'MursionPortal.Client.EmailSettings.Confirmation': 'Confirmación',
    'MursionPortal.Client.EmailSettings.Cancellation': 'Cancelación',
    'MursionPortal.Client.EmailSettings.FirstReminder': 'Primer recordatorio',
    'MursionPortal.Client.EmailSettings.SecondReminder': 'Segundo recordatorio',
    'MursionPortal.Client.EmailSettings.SessionRecording': 'La grabación de la sesión está lista para el propietario/facilitador de la cuenta',
    'MursionPortal.Client.EmailSettings.LearnerSession': 'La grabación de la sesión del alumno está lista',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejected': 'Rechazada la grabación en vídeo de la sesión de simulación',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoDisallowed': 'No se permite la grabación en vídeo de la sesión de simulación',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejectedUser': 'La grabación en vídeo de la sesión de simulación ha sido rechazada por algún usuario',
    'MursionPortal.Client.EmailSettings.RequestRecieved': 'Solicitud de confirmación recibida',
    'MursionPortal.Client.EmailSettings.RequestNotFullfilled': 'Solicitud no cumplimentada',
    'MursionPortal.Client.EmailSettings.RequestDateTime': 'Es necesaria la solicitud de cambio de fecha/hora',
    'MursionPortal.Client.EmailSettings.Title.SelectEmails': 'Seleccione los correos electrónicos que le gustaría enviar a los alumnos. Mursion recomienda que deje todos los correos electrónicos habilitados.',
    'MursionPortal.Client.EmailSettings.Header.Registration': 'Registro',
    'MursionPortal.Client.EmailSettings.Header.TrainingSession': 'Sesión de formación',
    'MursionPortal.Client.EmailSettings.Header.Videos': 'Vídeos',
    'MursionPortal.Client.EmailSettings.Header.Requests': 'Solicitudes',
    'MursionPortal.AriaLabel.ToggleButton': 'Alternar botón',
    'MursionPortal.Client.EmailSettings.Hint.RecordingEnabled': 'solo si las grabaciones están habilitadas',
    'MursionPortal.Client.EmailSettings.Hint.RequestFeature': 'solo si la función de solicitud está habilitada',
    'MursionPortal.SuccessDialog.Title': 'Diálogo de éxito',
    'MursionPortal.Dialog.CreateSSOConfiguartion.SuccessMessage': 'La configuración de SSO del cliente se ha creado correctamente.',
    'ITManager.TechRestriction.Table.EmptyMessage': 'No se han encontrado restricciones de tecnología bancaria.',
    'ITManager.TechRestriction.Table.Tag': 'Etiqueta',
    'ITManager.TechRestriction.Table.StartDate': 'Fecha de inicio',
    'ITManager.TechRestriction.Table.EndDate': 'Fecha de finalización',
    'ITManager.TechRestriction.EditDialog.EnterTag': 'Introduzca el nombre de la etiqueta',
    'ITManager.TechRestriction.Table.HoverTitle': 'Ver/editar restricción tecnológica',
    'ITManager.TechRestriction.Table.NoTechRestrictionError': 'Todavía no hay restricciones tecnológicas',
    'MursionPortal.DateTimePicker.StartTime': 'Hora de inicio',
    'MursionPortal.DateTimePicker.EndTime': 'Hora de finalización',
    'MursionPortal.TechRestrictions': 'Restricciones tecnológicas',
    'MursionPortal.Caption.ScenarioBankUsersList': 'lista de usuarios del banco de escenarios',
    'MursionPortal.Caption.SsoConfigurationList': 'lista de configuración del sso',
    'MursionPortal.AriaLabel.DateRangeCalendarButton': 'pulse «enter» para interactuar con el calendario y seleccione la fecha de inicio y finalización',
    'MursionPortal.Modal.Header.SessionTimeout': 'Tiempo de espera de la sesión',
    'MursionPortal.Modal.Body.SessionTimeout': 'Your session will timeout in 1 minute, please continue to extend your session', // TODO
    'MursionPortal.Status.PendingNoSS': 'Pendiente (n.º SS)',
    'MursionPortal.Status.PendingNoL': 'Pendiente (N.º L)',
    'MursionPortal.Status.PendingNoLearners': 'Pendiente (sin alumnos)',
    'MursionPortal.Status.PendingNoLSS': 'Pendiente (N.º L/SS)',
    'Dashboard.LeftPane.SupplyManagement.SimView': 'Vista de la SIM',
    'Dashboard.LeftPane.SupplyManagement.SchedulingView': 'Vista de la Programación',
    'MursionPortal.Table.ColumnSelection.Button.Label': 'Columnas',
    'Session.Table.Column.SubmitRequest': 'Enviar Solicitud',
    'Filters.SimSpecialistStatus': 'Estado del SIM Specialist',
    'Filters.SimSpecialistStatusPlaceHolderHint': 'Buscar filtro de Estado del SIM Specialist...',
    'Users.TableModal.SSOUserID': 'ID de usuario SSO',
    'MursionPortal.UnavailableSlotsError.SorryText': '¡Hola! ¡Lo siento mucho!',
    'MursionPortal.UnavailableSlotsError.BusyText': 'Parece que estamos ocupados simulando con otros alumnos.',
    'MursionPortal.UnavailableSlotsError.TryAgainText': '¡Por favor, vuelve a intentarlo mañana u otro día!',
    'MursionPortal.Session.Survey.ClickHere': 'Pulsa aquí',
    'MursionPortal.Session.SurveyNotLoading': '¿No se carga la encuesta?',
    'Users.Table.Heading.Viewonly': '(solo ver)',
    'MursionPortal.BookItUI.BusyText': 'Parece que en este momento estamos ocupados simulando con otros alumnos.',
    'MursionPortal.BookItUI.TryLaterText': 'Pronto se abrirán más sesiones de simulación; por favor, vuelve a intentarlo hoy más tarde.',
    'MursionPortal.BookItUI.MoreSimulationsText': 'Pronto se abrirán más sesiones de simulación.',
    'MursionPortal.BookItUI.ComeBackText': 'Por favor, vuelve si quieres hacer tu simulación hoy más tarde.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Archived': 'Usuario archivado',
    'ClientUserTable.AddNewUsers.UpdateArchivedUserWarning': 'Los usuarios {archivedUsers} están actualmente archivados. Al continuar se añadirán estos usuarios a los equipos seleccionados y se des-archivarán los usuarios.',
    'ClientUserTable.AddNewUsers.UpdateExistingArchivedUserWarning': 'Estos usuarios {existingUsers} ya existen y los usuarios {archivedUsers} están actualmente archivados. Al proceder se añadirán estos usuarios a los equipos seleccionados y también se des-archivarán los usuarios archivados.',
    'MursionPortal.ProjectCreateStep.UpperLimitValidation.Hours': 'No puede ser superior a {code} horas',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability': 'Próxima Disponibilidad',
    'MursionPortal.Table.ColumnHeader.TotalSession': 'Sesión Total',
    'MursionPortal.Table.ColumnHeader.ActiveCertifications': 'Certificaciones Activas',
    'MursionPortal.Button.Decline': 'Reducción',
    'MursionPortal.Having.Issues.Logging.In': '¿Tienes problemas para iniciar sesión?',
    'MursionPortal.Label.Call': 'Llama:',
    'MursionPortal.Project.Hours': 'Hora(s)',
    'Users.Button.ProfService': 'Servicio Profesional',
    'Users.Button.Operations': 'Servicio de Operaciones',
    'Users.Button.SimDesigner': 'SIM Designer',
    'MursionPortal.ArtProject.Placeholder.EnterOrSelect': 'Seleccionar o Intro',
    'MursionPortal.Setting.LearnerCommunicationPersonalization': 'Personalización de la Comunicación del Alumno',
    'MursionPortal.LearnerCommunicationPersonalization.Heading': 'Aquí puedes personalizar mensajes específicos para tus alumnos.',
    'MursionPortal.SupplyBasedScheduling.SubmitPage.SimulationConfirmation': '¡Tu simulación está confirmada! Te recomendamos que te presentes unos minutos antes de la hora de inicio.',
    'MursionPortal.DemandBasedScheduling.SubmitPage.LearnerConfirmed': '{learners} ¡confirmado para la simulación!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.DateAndTime': 'Fecha y Hora',
    'MursionPortal.Personalized.LearnerDashboard.Section': 'Mensaje que deseas que todos tus alumnos vean en el Tablero del Alumno',
    'MursionPortal.Personalized.SchedulingPage.Section': 'Mensaje que deseas que vean todos sus alumnos en la Página de Programación',
    'MursionPortal.Personalized.Section.Tooltip': 'El mensaje se actualizará en todos los sitios',
    'MursionPortal.CheckboxLabel.JoinNow': 'Únete Ahora',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability.Tooltip': 'Se mostrarían los roles de los clientes espacios de tiempo deshabilitados para aquellos \'Próxima Disponibilidad\' hrs después \'Disponibilidad SIM\'. Si \'Próxima Disponibilidad\' es 0, no se mostrarían los espacios de tiempo deshabilitados y sólo \'Disponibilidad SIM\' se expondrían los espacios de tiempo. Si \'Próxima Disponibilidad\' son 24 hrs, todas los espacios de tiempo disponibles después \'Disponibilidad Sim\' se mostrarían las horas como espacios de tiempo deshabilitados para un día.',
    'MursionPortal.LearnerDashboard.JoinNow.OutsideOfficeHoursText': 'Por favor, vuelve más tarde, durante las horas de oficina de tu organización, para tomar este escenario.',
    'MursionPortal.Setting.Personalization.LastUpdated': 'Última Actualización:',
    'MursionPortal.CheckboxLabel.JoinNowRequests': 'Únete Ahora a Solicitudes',
    'MursionPortal.LearnerDashboard.JoinNow.HeaderText': 'Te estamos emparejando con un avatar <code>para una simulación en directo</code>. Por favor, revisa la información más abajo para prepararte.',
    'MursionPortal.LearnerDashboard.JoinNow.SorryText': '¡Lo sentimos!',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsBusyText': 'Todos nuestros avatares están ocupados con otros alumnos. Vuelve más tarde o inténtalo de nuevo en unos minutos.',
    'MursionPortal.LearnerDashboard.JoinNow.CongratsText': '¡Enhorabuena! ¡Se te ha emparejado con un avatar!',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsMatchText': '¡El avatar te está esperando! Haz clic en Unirse a la Simulación en Directo cuando estés listo o tu experiencia comenzará automáticamente tras la cuenta atrás.',
    'MursionPortal.LearnerDashboard.JoinNow.StartLearning': 'If you are not able to get connected, click below to start learning.',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.MyLearning': 'Starbucks MyLearning',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.LiveSimulationExp': 'This session is a live simulation experience. Partners can now choose to take an eLearning on de-escalating conflict in MyLearning, as an alternative to the live safety simulation.',
    'MursionPortal.Button.Leave': 'Márchate',
    'MursionPortal.Personalized.ScenarioPage.Section': 'Mensaje que deseas que vean los alumnos en la Página del Escenario',
    'MursionPortal.Personalized.CreateNewScenario.Button.Label': 'Crear nuevo [desde cero] para escenario específico',
    'MursionPortal.Personalized.Scenario.Search.Placeholder': 'Buscar o seleccionar Escenario(s):',
    'MursionPortal.Personalized.Scenario.Selection.Title': 'Añadir nuevo escenario específico',
    'MursionPortal.Personalized.NoMessage': 'Actualmente no hay ningún mensaje establecido',
    'MursionPortal.Personalized.MetaData.NoHistory': 'Aún no hay antecedentes.',
    'MursionPortal.JoinNow.AlreadyBooked.Message': '{code} está reservado para una sesión en este momento.',
    'Users.Table.SendInvite.TooltipText': 'Al desactivar los correos electrónicos de registro también se desactivan los botones «clic para invitar» en el portal',
    'MursionPortal.JoinNow.AlreadyBooked.JoinSession.Message': 'Haz clic en Unirse a la Simulación en Directo para unirte o reanudar la sesión.',
    'Dashboard.LeftPane.CategoryHeader.Scenarios': 'Escenarios',
    'Dashboard.LeftPane.Learners.TeamAndProjectActivity': 'Actividad de Equipos y Proyectos',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetScheduled': 'Aún No programado',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.Scheduled': 'Aún No Completado',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': 'Completado',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetAssigned': 'Aún No Asignado',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetScheduled': 'Distinto número de alumnos que están asignados al menos a un equipo, que a su vez está asignado al menos a un escenario, pero el alumno nunca ha programado una sesión.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.Scheduled': 'Distinto número de alumnos que programaron al menos una sesión, pero que aún no han asistido a, ni completado, al menos una sesión.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.CompletedAssigned': 'Distinto número de alumnos que han completado al menos una sesión.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetAssigned': 'Distinto número de alumnos que no están asignados a ningún equipo o alumnos que forman parte de un equipo que no está asignado a ningún escenario.',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerStatus': 'Finalización del Alumno',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerSequence': 'Alumnos Únicos',
    'MursionPortal.Dashboard.ScenarioLearner.Header.SequenceCount': '(Máximo de {sessionCount} sesiones)',
    'Dashboard.SequenceOrder.Header.Title': 'Actividad Perdida, Cancelada con Retraso & Reprogramada con Retraso',
    'MursionPortal.Integrations': 'Integraciones',
    'MursionPortal.Personalized.Message.Delete.Title': '¿Estás seguro de que quieres borrar el mensaje?',
    'MursionPortal.Personalized.Message.Delete.Tooltip': 'Borrar Mensaje',
    'MursionPortal.Personalized.Scenario.Message.OnlyOneAtTime': 'Sólo se pueden hacer cambios en un mensaje a la vez. Por favor, guarda o cancela el que ya está en modo edición.',
    'MursionPortal.Error.Time': '6 AM - 10 PM ET',
    'MursionPortal.CheckboxLabel.JoinNow.Tooltip.Text': 'La programación por BookIt siempre está habilitada cuando Unirse Ahora está así mismo habilitado.',
    'MursionPortal.JoinNow.FinalizedScenario.Message': 'El escenario está finalizado, por favor vuelve una vez que el escenario esté activo.',
    'MursionPortal.CheckboxLabel.sessionMissedTime': 'Tiempo de Sesión Perdido',
    'MursionPortal.Title.Seconds': 'Segundo(s)',
    'MursionPortal.RequestTable.Accept.ErrorMessage': 'Lo sentimos, la sesión se ha cancelado.',
    'MursionPortal.Client.EmailTypeFilter.SchedulingLocked': 'Programación Bloqueada',
    'MursionPortal.Client.EmailTypeFilter.SchedulingUnlocked': 'Programación Desbloqueada',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time': 'Programación de la Restricción de Acceso',
    'MursionPortal.Table.ColumnHeader.SimAvailable': 'SIM Disponible',
    'MursionPortal.CheckboxLabel.sessionMissedTime.Tooltip.Text': 'Tiempo a partir del cual la sesión se marcaría como Perdida si no se incorpora ningún alumno o SIM.',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetCompleted': 'Aún No Completado',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedOneSession': '1 sesión completada',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedTwoPlusSession': '2 o más sesiones completadas',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.OneSession': '1 Sesión',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.TwoSession': '2 Sesión',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ThreeSession': '3 Sesión',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.FourPlusSession': '4+ Sesión [4 o más sesiones]',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetCompleted': 'Número de alumnos únicos asignados a este escenario pero que aún no lo han completado',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedOneSession': 'Número de alumnos únicos asignados a este escenario que lo han completado una vez.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedTwoPlusSession': 'Número de alumnos únicos asignados a este escenario que lo han completado dos veces o más.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.OneSession': 'Número de alumnos únicos que han faltado a una sesión, la han cancelado tarde o la han reprogramado tarde.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.TwoSession': 'Número de alumnos únicos que han faltado, cancelado tarde y/o reprogramado tarde dos sesiones.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ThreeSession': 'Número de alumnos únicos que han perdido, cancelado tarde y/o reprogramado tarde tres sesiones.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.FourPlusSession': 'Número de alumnos únicos que han perdido, cancelado tarde y/o reprogramado tarde cuatro o más sesiones.',
    'MursionPortal.ScenarioBank.ItemBank.Label': 'Bloques de Construcción',
    'MursionPortal.ScenarioBank.ItemBank.Skills': 'Habilidades',
    'MursionPortal.ScenarioBank.ItemBank.Domains': 'Dominios',
    'MursionPortal.ScenarioBank.ItemBank.Events': 'Eventos',
    'MursionPortal.ScenarioBank.ItemBank.Pathways': 'Rutas',
    'MursionPortal.ScenarioBank.ItemBank.Resources': 'Recursos',
    'MursionPortal.ScenarioBank.ItemBank.Outcomes': 'Resultados',
    'MursionPortal.ScenarioBank.ItemBank.Mindsets': 'Mentalidades',
    'MursionPortal.ScenarioBank.ItemBank.Avatars': 'Avatares',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries': 'Resúmenes de Investigación',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstructions': 'Instrucciones para el Alumno',
    'MursionPortal.ScenarioBank.ItemBank.Column.Level': 'Nivel',
    'MursionPortal.ScenarioBank.ItemBank.Column.Domain': 'Dominio',
    'MursionPortal.ScenarioBank.ItemBank.Column.Translations': 'Traducciones',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Skill': 'Desarrollar Habilidad',
    'MursionPortal.Dashboard.Label.ScenarioActivity.ErrorMessage': 'Por favor, selecciona al menos un Proyecto y un Escenario de entre los filtros',
    'MursionPortal.Learner.Onboarding': 'Capacitación de alumnos',
    'MursionPortal.Tab.Onboarding': 'CAPACITACIÓN',
    'MursionPortal.OrganizationName.Placeholder': 'introduce el nombre de tu organización',
    'Dashboard.LeftPane.Learners.LearnerLockedOut': 'Alumno Bloqueado',
    'Dashboard.Learners.LearnerLockedOut.TableHeader': 'Alumnos con la programación bloqueada debido a su tasa de pérdida/cancelación',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn': 'Fecha/Hora Bloqueada para la Programación',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn': 'Fecha/Hora Hasta la Liberación Automatizada',
    'MursionPortal.DontHaveEmail.Text': '¿No tienes una dirección de correo electrónico en tu organización?',
    'MursionPortal.InvalidCompanyName.Error': 'Tu organización requiere que los usuarios inicien sesión con su dirección de correo electrónico. Por favor, introdúcela arriba.',
    'MursionPortal.LearnerOnboardingPage.Text.Welcome': 'Bienvenido',
    'MursionPortal.LearnerOnboardingPage.Text.MursionVideoOverview': 'Vídeo Informativo sobre Mursion',
    'MursionPortal.LearnerOnboardingPage.Text.KeyThingsToKnow': 'Claves A Conocer',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Text': '¡Estamos encantados de darte la bienvenida a tu programa de aprendizaje Mursion! Mursion utiliza simulaciones inmersivas impulsadas por la tecnología human-in-the-loop (HITL), para proporcionar una experiencia de aprendizaje integral que te dotará de las herramientas y técnicas necesarias para tener éxito en un mundo en constante evolución.',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Beginner.Text': 'Tanto si eres un principiante como si eres un profesional experimentado, nuestro programa tiene algo que ofrecer a todo el mundo. Estamos deseando que empieces.',
    'MursionPortal.LearnerOnboardingPage.Video.Section.Text': '¡En primer lugar, mira este vídeo informativo <strong>sobre Mursion!</strong>.',
    'MursionPortal.LearnerOnboardingPage.Scheduled.Section.Text': '<strong>Ahora estás listo para:</strong>¡Programar tu primera simulación!',
    'MursionPortal.LearnerOnboardingPage.keysThingsToKnow.Section.Heading': '!A continuación, vamos a repasar algunas cosas clave que hay que conocer¡',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology': 'Metodología Mursion',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology.Summary': 'Mursion se basa en la investigación y ha demostrado que impulsa el cambio del comportamiento. Nuestras simulaciones, basadas en avatares, te ayudarán a aprender a responder adecuadamente en situaciones de gran carga emocional.',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Text': 'Por Qué Avatares',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Summary': 'Mursion es una herramienta más eficaz que los métodos tradicionales o los juegos de rol. La investigación demuestra que las personas confían más en los avatares que en sus jefes y compañeros, cuando interactuan con los primeros, así como también sienten más curiosidad por los avatares que por sus compañeros o jefes.',
    'MursionPortal.LearnerOnboardingPage.Human.Text': 'Human-In-The -Loop (HITL)',
    'MursionPortal.LearnerOnboardingPage.Human.Summary': 'Cada simulación de Mursion cuenta con un Simulation Specialist que se encarga de los diálogos hablados y los movimientos de hasta cinco avatares virtuales en cada simulación.',
    'MursionPortal.LearnerOnboardingPage.Practice.Text': 'Por Qué es Importante la Práctica',
    'MursionPortal.LearnerOnboardingPage.Practice.Text.Summary': 'Mejorar las habilidades de liderazgo no es tarea fácil. Pero la investigación demuestra que el aprendizaje tiene un mayor impacto cuando incluye la práctica. Mursion da prioridad a la práctica creando espacios seguros, dinámicos y accesibles.',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Domain': 'Crear Dominio',
    'MursionPortal.ScenarioBank.ItemBank.Level.L1': 'L1',
    'MursionPortal.ScenarioBank.ItemBank.Level.L2': 'L2',
    'MursionPortal.ScenarioBank.ItemBank.Level.L3': 'L3',
    'MursionPortal.Label.OrganizationName': 'Nombre de la Organización',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Pathway': 'Crear Ruta',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Event': 'Crear Evento',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Resource': 'Crear Recurso',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Outcome': 'Crear Resultado',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Mindset': 'Desarrollar Mentalidad',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Avatar': 'Crear Nombre de Avatar',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.ResearchSummary': 'Crear un Resumen de la Investigación',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.LearnerInstruction': 'Crear una Instrucción para el Alumno',
    'MursionPortal.ScenarioBank.ItemBank.BackButton.Label': 'Volver al Banco de Escenarios',
    'MursionPortal.ScenarioBank.ItemBank.Title.Placeholder': 'Escribe aquí',
    'MursionPortal.Dashboard.NextOnProject.Footer.MissOrCancelationRateFulfilled.Text': 'You cannot schedule this simulation as you have reached the missed or cancellation limit set by your organization. Please reach out to your program admin to allow scheduling.',// TODO add translations
    'MursionPortal.ScenarioInfo.Show': 'Mostrar',
    'MursionPortal.ScenarioInfo.CertificationHistory': 'Historial de Certificación',
    'MursionPortal.Session.AlreadyBooked': 'Lo siento, la sesión ya ha sido aceptada por otra SIM.',
    'MursionPortal.CustomizeInvitation.Title': 'Personaliza tus invitaciones',
    'MursionPortal.CustomizeInvitation.SubTitle': 'Para personalizar, sobrescribe el texto de cada campo a continuación o deja el texto predeterminado.',
    'MursionPortal.CustomizeInvitation.IntroMessage': 'Introduce Mensaje para los Alumnos (los escenarios disponibles se auto-rellenarán automáticamente debajo de este mensaje):',
    'MursionPortal.CustomizeInvitation.Label.SenderName': 'Nombre del Remitente (la dirección de correo electrónico será de noreply@mursion.com)',
    'MursionPortal.CustomizeInvitation.Label.Subject': 'Asunto',
    'MursionPortal.InviteLearnersTeam.Section.Title': '¿A qué equipo(s) te gustaría invitar a programar?',
    'MursionPortal.InviteLearnersTeam.Section.SubTitle': 'Se invitará a los alumnos a todos los escenarios de este proyecto que sean elegibles para programar',
    'MursionPortal.InviteLearnersTeam.ScenariosAssigned.Text': 'escenarios asignados',
    'MursionPortal.Confirmation.Modal.Invitations.Text': '¡Sus invitaciones han sido programadas!',
    'MursionPortal.ScenarioSetting.ValidationMessage': 'debe ser inferior o igual al mismo ajuste que al nivel del cliente y/o del proyecto.',
    'MursionPortal.ScenarioBank.EventDetail.Details': 'Detalles',
    'MursionPortal.ScenarioBank.CharacterLimit.TitleMessage': 'Límite de caracteres:{titleMaxLength}',
    'MursionPortal.ScenarioBank.CharacterLimit.DescriptionMessage': 'Límite de caracteres:{descriptionMaxLength}',
    'MursionPortal.ScenarioBank': 'Plantilla de eventos',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header': 'Inspirar confianza y seguridad para que los demás compartan información sobre su trabajo',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title': 'Detalles del Dominio',
    'MursionPortal.ScenarioBank.ItemBank.Edit.DescriptionOfUpdate.Label': 'Al guardar estos cambios se actualizarán {type}en todos los escenarios. Añade una breve descripción de los cambios:',
    'Dashboard.Learners.LearnerLockedOut.LearnerColumn.Tooltip': 'Nombre o correo electrónico del usuario',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Certified': 'Certificado',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Decertified': 'Sin Certificar',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Inactive': 'Inactivo',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease': 'Liberar al Alumno para la Programación',
    'MursionPortal.CustomizeInvitation.Sender.Name': 'Mursion',
    'MursionPortal.CustomizeInvitation.Subject.Title': '¡Prepárate para practicar tus habilidades! Tus sesiones de Mursion están listas para ser programadas.',
    'MursionPortal.CustomizeInvitation.Intro.Message': 'Has sido invitado a participar en una experiencia de simulación en Mursion. Haz clic a continuación para programar una sesión o visita tu Tablero y explora.',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.ConfirmationText': '¿Estás seguro de que deseas liberar al {code} alumno(s) para la programación?',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.SuccessText': '{code} alumno(s) ha(n) sido liberado(s) para la programación.',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn.Tooltip': 'Fecha/hora en que el alumno quedó bloqueado para la programación',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn.Tooltip': 'Fecha/hora en que el alumno será liberado automáticamente',
    'MursionPortal.ScenarioBank.ItemBank.NextGenScenario': 'Escenario NextGen',
    'MursionPortal.ScenarioBank.ResourceFilter.Book': 'Reserva',
    'MursionPortal.ScenarioBank.ResourceFilter.Article': 'Artículo',
    'MursionPortal.ScenarioBank.Filter.Label.Publisher': 'Editorial',
    'MursionPortal.ScenarioBank.ItemBank.Level.Placeholder': 'Seleccionar nivel',
    'MursionPortal.ScenarioBank.ItemBank.Domain.Placeholder': 'Buscar o seleccionar dominio:',
    'MursionPortal.Label.CompletionRate.Tooltip': 'La tasa de finalización es el número de veces que un alumno puede completar una simulación antes de perder la capacidad de programar (bloqueo)',
    'MursionPortal.Label.SchedulingRate.Tooltip': 'La tasa de programación es el número de veces que un alumno puede programar una simulación antes de perder la capacidad de programar (bloqueo)',
    'MursionPortal.Label.MissCancellationRate.Tooltip': 'La tasa de pérdida/cancelación es el número de veces que un alumno puede perderse o cancelar una simulación antes de perder la capacidad de programar (bloqueo)',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time.Tooltip': 'Horas de Bloqueo de Programación es el número de horas que durará el bloqueo de la programación',
    'MursionPortal.ScenarioBank.ItemBank.Mindset.Type.Derailing': 'Fracaso',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Skill': 'Detalles sobre una Habilidad',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Mindset': 'Detalles sobre la Mentalidad',
    'MursionPortal.ProjectCreate.DeliveryHours': 'Horas para la Entrega',
    'MursionPortal.ProjectCreate.DeliveryHours.Tooltip': 'Plazo durante el cual se mostrarán los espacios de tiempo instantáneos a los alumnos',
    'MursionPortal.ProjectCreate.DeliveryHours.Start': 'Inicio de las Horas para la Entrega',
    'MursionPortal.ProjectCreate.DeliveryHours.End': 'Fin de las Horas para la Entrega',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.LearnerInstruction': 'Detalles sobre la Instrucción para el Alumno',
    'MursionPortal.ScenarioBank.ItemBank.Avatar.Edit.Header.Title': 'Detalles del Avatar',
    'MursionPortal.ScenarioBank.Label.AddLink': 'Añadir Enlace',
    'MursionPortal.ScenarioBank.Year.placeholderText': 'Año',
    'MursionPortal.ScenarioBank.ItemBank.Resource.Edit.Header.Title': 'Detalles del Recurso',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Edit.Header.Title': 'Detalles del Resultado',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Advanced': 'Avanzado',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Intermediate': 'Intermedio',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Foundational': 'Fundacional',
    'MursionPortal.ScenarioBank.ItemBank.Skill': 'Habilidad',
    'MursionPortal.Resources.Search.Text': 'Buscar o seleccionar recurso(s)',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries.Details': 'Detalles de Resúmenes de Investigación',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary': 'Resumen de la Investigación',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Pathway': 'Detalles de la Ruta',
    'MursionPortal.ScenarioBank.ItemBank.Pathway': 'Ruta',
    'MursionPortal.ScenarioBank.ConfirmationText.Archive': '¿Estás seguro de que quieres archivar este{block} ?',
    'MursionPortal.ScenarioBank.ConfirmationText.Unarchived': '¿Estás seguro de que quieres des-archivar este{block} ?',
    'MursionPortal.ScenarioBank.Unarchived': 'Des-archivar',
    'MursionPortal.ScenarioBank.ItemBank.Resource': 'Recurso',
    'MursionPortal.ScenarioBank.ItemBank.Mindset': 'Mentalidad',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction': 'Instrucción para el Alumno',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Event': 'Detalles del Evento',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives': 'Perspectivas del Avatar',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.Intro': 'Intro',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.DiscProfile': 'Perfil del Disco',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.VerbalStyle': 'Estilo Verbal',
    'MursionPortal.ScenarioBank.Content': 'Contenido',
    'MursionPortal.ScenarioBank.Content.ScenarioTitle': 'Título del Escenario',
    'MursionPortal.ScenarioBank.Content.ScenarioCoverStory': 'Historia de Portada del Escenario',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Placeholder': 'Selecciona un resultado del banco',
    'MursionPortal.ScenarioBank.Content.WhyThisMatters': 'Por qué Esto es Importante',
    'MursionPortal.ScenarioBank.NextGen.AvatarGuide': 'Guía del Avatar',
    'MursionPortal.ScenarioBank.NextGen.ViewScenario': 'Ver Escenario',
    'MursionPortal.ScenarioBank.NextGen.Event': 'Icono de evento',
    'MursionPortal.NextOnProject.Card.JoinNow': 'Únete a la Simulación en Directo',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Title': 'Cómo Funciona',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Intro': '¡Hola! Estás a punto de ser emparejado con un avatar en breve para <code>unirte a una simulación en directo ahora</code>.',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Matched': 'Una vez emparejado con un avatar, haz clic en <code>’Unirse a la Simulación en directo’</code>',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Allow': 'Haz clic en <code>’Permitir’</code> para acceder al micrófono/cámara cuando se te solicite',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.StartSession': 'Haz clic en <code>’Iniciar Sesión’</code> y espera a que el avatar comience tu simulación',
    'MursionPortal.ScenarioBank.NextGen.OutOfSite.Label': 'Fuera del sitio, en primer plano',
    'MursionPortal.FirstGen.Scenario.Button.Text': '1ª Gen',
    'MursionPortal.NextGen.Scenario.Button.Text': '2ª Gen',
    'MursionPortal.NextGen.Scenario.Assessment': 'Evaluación',
    'MursionPortal.NextGen.Scenario.OutOfSite': 'Fuera del sitio, en primer plano',
    'MursionPortal.NextGen.Scenario.Synopsis': 'Sinopsis',
    'MursionPortal.NextGen.Scenario.MinimumPoints': 'Puntos Mínimos Necesarios para Obtener un Resultado Satisfactorio',
    'MursionPortal.NextGen.Scenario.Successful': 'Exitoso',
    'MursionPortal.NextGen.Scenario.OpportunityDevelop': 'Oportunidad de Continuar Desarrollándose',
    'MursionPortal.NextGen.Scenario.LibraryInfo': 'Información de la Biblioteca',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DifficultyLevel': 'Nivel de Dificultad',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioCoverImage': 'Imagen de Portada del Escenario',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioThumbnailImage': 'Imagen en Miniatura del Escenario',
    'MursionPortal.NextGen.Scenario.LibraryInfo.CoverImageDescription': 'Descripción de la Imagen de Portada',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ThumbnailImageDescription': 'Descripción de la Imagen en Miniatura',
    'MursionPortal.ScenarioBank.CharacterLimit.ResponseMessage': 'Límite de caracteres: {responseMaxLength}',
    'MursionPortal.ScenarioBank.EventDetail.AvatarResponseTitle': 'Posible(s) Respuesta(s) del Avatar',
    'MursionPortal.NextGen.Scenario.ReflectionGuide': 'Guía de Reflexión',
    'MursionPortal.NextGen.Scenario.Scoring': 'Puntuación',
    'MursionPortal.ErrorMessage.buildingBlock': 'Debe especificarse el id del Bloque de Construcción',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DragAndDropText': 'Arrastra y suelta tu archivo aquí o haz clic para seleccionar archivos',
    'MursionPortal.NextGen.Scenario.Publish': 'Publica',
    'MursionPortal.NextGen.Scenario.Publish.Label': 'Rellena los campos obligatorios (*) para poder publicar.',
    'MursionPortal.NextGen.Scenario.Last.Publish.Label': 'Última publicación {publishDate} por {publishedBy}',
    'MursionPortal.EulaAgreement.CodeOfConductCheckBox.Text': 'Acepto el modelo de entrega de simulación de Mursion y me comprometo a respetar el Código de Conducta de Mursion en todas las simulaciones en las que participe. Leé más sobre las simulaciones de Mursion y nuestro Código de Conducta <code>aquí.</code>',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Text': 'No tengo tiempo para hacer esto ahora',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Text': 'No sé qué es esto',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Text': 'Volveré para hacerlo más tarde',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Description.Text': 'La mayoría de las personas que han hecho esta simulación han encontrado que es un valioso uso de su tiempo. Le llevará 30 minutos o menos.',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Description.Text': 'En lugar de un vídeo, esta práctica interactiva te ayuda a desarrollar competencias y habilidades en un espacio seguro y dinámico.',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Description.Text': '¡Seguro! ¡Puede volver en cualquier momento durante el horario establecido por la organización y participar en la simulación en directo para practicar sus habilidades!',
    'MursionPortal.JoinNow.CancellationText.Heading': '¿Por qué quieres cancelarlo?',
    'MursionPortal.JoinNow.CancellationPage.Title': '¡Oportunidad de practicar con el avatar!',
    'MursionPortal.JoinNow.CancellationPage.Text': 'Identifica formas positivas de resolver la situación dentro de las políticas y directrices de Starbucks y busca oportunidades para crear Mejores Momentos para todos los clientes.',
    'MursionPortal.JoinNow.JoinSimulation.Button.Text': 'Seguir Unido a la Simulación',
    'MursionPortal.LearnerDashboard.JoinNow.SearchingAvatar': '¡Buscamos un avatar <code>para tu simulación en directo!</code>',
    'MursionPortal.LearnerDashboard.JoinNow.SuccessText': '<code>¡Felicidades</code>, se te ha asignado un avatar para tu simulación en directo!',
    'MursionPortal.LearnerDashboard.JoinNow.ConnectingText': 'Estamos <code>conectándote a tu avatar</code>, ¡y tu simulación en directo comenzará en breve!',
    'MursionPortal.LearnerDashboard.JoinNow.DoNotCloseText': '¡Por favor, no cierres esta ventana del navegador!',
    'MursionPortal.LearnerDashboard.JoinNow.StrategiesToUse': 'Estrategias a Utilizar',
    'MursionPortal.LearnerDashboard.JoinNow.PracticeOpportunityText': '¡Oportunidad de practicar con el avatar!',
    'MursionPortal.Text.Sorry': 'Lo siento',
    'MursionPortal.Text.Matching': 'Emparejando',
    'MursionPortal.Text.Connecting': 'Conectando',
    'MursionPortal.JoinNowScenarioInfoCard.Text': '¡Por favor, vuelve más tarde o inténtalo de nuevo en unos minutos!',
    'MursionPortal.JoinNowScenarioInfoCard.HeaderText': '¡Todos nuestros avatares están ocupados con otros alumnos!',
    'MursionPortal.JoinNow.CancellationPage.GoBackButton.Text': 'Cancelar y volver al tablero',
    'MursionPortal.NextGen.Scenario.Event.AddMessage': 'Añada un mínimo de 2',
    'MursionPortal.ScenarioBank.ItemBank.Events.AddButton': '+Añadir',
    'MursionPortal.NextGen.ScenarioBank.Event.Select.Placeholder': 'Selecciona un Evento',
    'MursionPortal.NextGen.ScenarioBank.Skill.Select.Placeholder': 'Selecciona una Habilidad',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarBehavior.Label': 'Comportamiento del Avatar*.',
    'MursionPortal.NextGen.ScenarioBank.Event.LearnerResponse.Label': 'Respuesta Esperada del Alumno*',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarAdd.Button': 'Añadir Respuesta',
    'MursionPortal.NextGen.ScenarioBank.AvatarResponseName.Select.Placeholder': 'Seleccionar un Avatar',
    'MursionPortal.NextGen.ScenarioBank.Label.AvatarPossibleResponse': 'Posible(s) Respuesta(s) del Avatar',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentObservedAction.Label': 'Describir la Acción Observada',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentImapct.Label': 'Explicar el Impacto',
    'MursionPortal.NextGen.ScenarioBank.StrengthAssessment': 'Evaluación(es) de la Fuerza',
    'MursionPortal.NextGen.ScenarioBank.Button.StrengthAssessment': '+ Añadir Evaluación de la Fuerza',
    'MursionPortal.NextGen.ScenarioBank.Label.Strength': 'Fuerza',
    'MursionPortal.NextGen.ScenarioBank.OpportunityAssessment': 'Evaluación(es) de la Oportunidad',
    'MursionPortal.NextGen.ScenarioBank.Button.OpportunityAssessment': '+ Añadir Evaluación de la Oportunidad',
    'MursionPortal.NextGen.ScenarioBank.Label.Opportunity': 'Oportunidad',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Select.Placeholder': 'Seleccionar una Mentalidad Positiva',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Select.Placeholder': 'Seleccionar una Mentalidad de Fracaso',
    'MursionPortal.NextGen.Scenario.Event.Scoring.Title': 'Puntuación del Evento',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Title': 'Añadir Mentalidades Positivas y de Fracaso*',
    'MursionPortal.NextGen.Scenario.Event.Positive.Button.Text': 'Añadir una Mentalidad Positiva Adicional',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Button.Text': 'Añadir una Mentalidad de Fracaso Adicional',
    'MursionPortal.Text.Searching': 'Buscando',
    'MursionPortal.NextGen.Scenario.Event.MindsetsNavigated': 'Mentalidades Navegadas',
    'Dashboard.ContractProgress.Column.RemainingUtilization': 'Utilización Restante',
    'Dashboard.ContractProgress.Column.EstimatedRemaining': 'Restante Estimado',
    'MursionPortal.Label.Completion': 'Tasa de Finalización',
    'Dashboard.Report.ReportSessionOption.AllDays': 'Todas las Sesiones',
    'MursionPortal.Status.Abbreviation.EarlyCancelled': 'CE',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EstimatedRemaining': 'Restante Estimado',
    'Dashboard.ContractProgress.ColumnTooltip.RemainingUtilization': 'Simulaciones Restantes y programadas como número de simulaciones adquiridas',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdownQuickView': 'Desglose de la Sesión - Vista Rápida',
    'MursionPortal.NextGen.SelectorText.Race': 'Raza',
    'MursionPortal.NextGen.SelectorText.Ethnicity': 'Etnicidad',
    'MursionPortal.NextGen.SelectorText.None': 'Ninguno',
    'MursionPortal.NextGen.SelectorText.Woman': 'Hay que ser mujer',
    'MursionPortal.NextGen.SelectorText.Man': 'Hay que ser un hombre',
    'MursionPortal.NextGen.SelectorText.Wheelchair': 'Uno debe estar en silla de ruedas',
    'MursionPortal.NextGen.SelectorText.agedText': 'Uno debe ser mayor de 55 años',
    'MursionPortal.NextGen.Avatar.Restriction': 'Restricción del Avatar',
    'MursionPortal.NextGen.AvatarOrScenarioComposition': 'Composición del Escenario/Avatar',
    'MursionPortal.Label.Opening': 'Apertura',
    'MursionPortal.Label.Closing': 'Cierre',
    'MursionPortal.Label.Introduction': 'Introducción',
    'MursionPortal.ReflectionGuide.LearnerAssessment': 'Evaluación del Alumno',
    'MursionPortal.ReflectionGuide.HostAssessment': 'Evaluación del Anfitrión [Host]',
    'MursionPortal.ReflectionGuide.HostOpportunity': 'Oportunidad de Acoger',
    'MursionPortal.ReflectionGuide.Introduction.Text': '¡Bienvenido de nuevo! ¿Te sientes preparado para reflexionar sobre tu experiencia? (permite que el alumno dé su consentimiento antes de continuar)',
    'MursionPortal.Label.Say': 'DIGA',
    'MursionPortal.Label.Ask': 'PREGUNTA',
    'MursionPortal.Label.Bullet': 'Bala',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FirstQuestion': '¿Cómo fue la conversación?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.SecondQuestion': '¿Cómo se ajustó la realidad de la conversación a tu plan para la misma?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.ThirdQuestion': '¿Qué ha pasado?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FourthQuestion': '¿Hubo alguna señal (verbal y no verbal) del avatar que te diera una pista sobre cómo iba a ir la conversación?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FifthQuestion': '¿Hubo alguna señal (verbal y no verbal) del avatar que te diera una pista sobre cómo iba a ir la conversación?',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FirstSay': 'Esta conversación te hizo navegar por algunas dinámicas desafiantes; Mickey estaba frustrado.',
    'MursionPortal.ReflectionGuide.LearnerAssessment.SecondSay': 'Aunque bienintencionados, a menudo veo que los líderes reaccionan de forma no tan productiva debido a:',
    'MursionPortal.ReflectionGuide.LearnerAssessment.ThirdSay': 'También he visto a líderes reaccionar de forma más productiva debido a:',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FourthSay': 'Esta conversación te hizo navegar por algunas dinámicas desafiantes',
    'MursionPortal.ReflectionGuide.LearnerAssessment.Ask': '¿Dónde crees que has aterrizado? (seguimiento: ¿Por qué/ ¿Cómo es eso?/ ¿Cómo lo sabes?): ¿Dónde crees que aterrizaste? (seguimiento: ¿Por qué/ ¿Cómo es eso?/ ¿Cómo lo sabes?)',
    'MursionPortal.ReflectionGuide.HostAssessment.Opening': 'Si yo pudiera promover uno de los puntos fuertes que mostraste durante la reunión (selecciona uno):',
    'MursionPortal.ReflectionGuide.HostAssessment.Closing': '¿Coinciden mis reflexiones con las tuyas? ¿Por qué o por qué no?',
    'MursionPortal.ReflectionGuide.HostAssessment.Opportunity.Opening': 'Si pudiera promover una de las oportunidades de desarrollo que he observado (selecciona una):',
    'MursionPortal.ReflectionGuide.Closing.Say': '¡ESTO ES materia difícil y te agradezco que hoy hayas practicado estas importantes habilidades con Mursion!',
    'MursionPortal.ReflectionGuide.Closing.Ask': '¿Qué es algo que te llevas de esta experiencia que utilizarás la próxima vez que te enfrentes a una situación similar en la vida real?',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary.Placeholder': 'Selecciona un Resumen de Investigación',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction.Placeholder': 'Selecciona una Instrucción para el Alumno',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ClinkHereToReplaceThisFile': 'Haz clic aquí para sustituir este archivo.',
    'MursionPortal.Status.Empty': ' ',
    'MursionPortal.ScenarioContent.Label': 'Añade un mínimo de {min} y un máximo de {max}',
    'MursionPortal.Search.Text.Skills': 'Buscar o seleccionar Habilidad(es)',
    'MursionPortal.Search.Text.Avatar': 'Buscar o seleccionar Avatar(s)',
    'MursionPortal.NextGen.Scenario.TimelineAndScheduling': 'Horario y Programación',
    'MursionPortal.NextGen.Session.Replay': 'Repetición de la Sesión',
    'MursionPortal.NextGen.StrengthsAndOpportunities': 'Puntos Fuertes y Oportunidades',
    'MursionPortal.NextGen.Recommendations': 'Recomendaciones',
    'MursionPortal.NextGen.MoreToDiscover': 'Más por Descubrir',
    'MursionPortal.NextGen.MissionAccomplished': '¡Misión Cumplida!',
    'MursionPortal.NextGen.Learner.ScenarioMaterials': 'Materiales del Escenario',
    'MursionPortal.NextGen.Learner.PathwayAffiliation': 'Ruta de Afiliación',
    'MursionPortal.NextGen.Learner.SkillLevel': 'Nivel de Habilidad',
    'MursionPortal.NextGen.Learner.YourMission': 'Tu misión',
    'MursionPortal.ScenarioBank.ShowOnlySecondGenscenarios': 'Mostrar sólo escenarios de 2ª Gen',
    'MursionPortal.NextGen.CreateNewSkill.Header.Text': 'Crear una Nueva Habilidad del Banco',
    'MursionPortal.NextGen.Learner.StartSimulation': 'Iniciar Simulación',
    'MursionPortal.SimMaterials.Label.CoverStory': 'Historia de Portada',
    'MursionPortal.SimMaterials.Label.LearnerMission': 'Misión del Alumno',
    'MursionPortal.SimMaterials.Label.Background': 'Fondo / Antecedentes',
    'MursionPortal.SimMaterials.Label.PerspectivesAndIdentities': 'Perspectivas & Identidades',
    'MursionPortal.SimMaterials.HeaderText.SimDashboard': 'Tablero SIM',
    'MursionPortal.NextGen.Learner.ToStart': 'Comenzar',
    'MursionPortal.Project.DeliveryHours.Standard': 'Horas Estándar de Entrega',
    'MursionPortal.Project.DeliveryHours.Global': 'Horas Globales de Entrega',
    'MursionPortal.Label.Monday.ShortForm': 'lu',
    'MursionPortal.Label.Tuesday.ShortForm': 'ma',
    'MursionPortal.Label.Wednesday.ShortForm': 'mi',
    'MursionPortal.Label.Thursday.ShortForm': 'ju',
    'MursionPortal.Label.Friday.ShortForm': 'vi',
    'MursionPortal.Label.Saturday.ShortForm': 'sa',
    'MursionPortal.Label.Sunday.ShortForm': 'do',
    'MursionPortal.NextGen.CreateFromBank.Customized.Label': 'Personalizado del escenario del banco',
    'MursionPortal.NextGen.CreateFromBank.CustomizedFromBank.Tooltip': 'Este escenario ha sido personalizado a partir del escenario original del banco. Cualquier actualización realizada del escenario del banco no se propagará a este escenario.',
    'MursionPortal.BuildingBlock.Label.PublishedDate': 'Fecha de Publicación',
    'MursionPortal.Label.CopyTeamInviteLink': 'Copiar Enlace de Invitación al Equipo',
    'MursionPortal.NextGen.SessionReplayText' : 'Esta conversación fue una oportunidad práctica para que  facilitaras una conversación que apoyara a un miembro del equipo en un momento de dificultad. Tu éxito dependía de tu capacidad para tener empatía hacia Jo.',
    'MursionPortal.NextGen.TheMission' : 'La misión:',
    'MursionPortal.NextGen.DemonstratedStrength' : 'Punto Fuerte Demostrado',
    'MursionPortal.NextGen.OpportunityForFutureGrowth' : 'Oportunidad de Crecimiento Futuro',
    'MursionPortal.NextGen.CompetenciesFocus':'Las competencias en las que se centró fueron:',
    'MursionPortal.NextGen.StrengthAndOpportunitiesText' : 'Durante el informe de la conversación, se identificaron un punto fuerte observado y una oportunidad de crecimiento:',
    'MursionPortal.NextGen.WeObserved' : 'Observamos....',
    'MursionPortal.NextGen.WhyThisMatters' : 'Por qué esto es importante',
    'MursionPortal.ScenarioSetting.MissOrCancelationRate.ValidationMessage': 'La Hora de Bloqueo de la Programación no puede ser nula si la Tasa de Faltas/Cancelaciones no es nula.',
    'MursionPortal.NextGen.CreateFromBank.Customized.Modal.Text': 'Has personalizado un escenario del banco y cualquier cambio futuro en el escenario del banco no se reflejará en este escenario ¿Deseas continuar?',
    'MursionPortal.SimMaterials.Event.SkillsInFocus.Label' : 'Las Habilidades Focalizadas',
    'MursionPortal.SimMaterials.Label.SimulationEvents': 'Eventos de Simulación',
    'MursionPortal.SimMaterials.Event.AvatarBehavior.Label':'Comportamiento del Avatar',
    'MursionPortal.SimMaterials.Event.LearnerResponse.Label':'Respuesta Esperada del Alumno',
    'MursionPortal.SimMaterials.Label.ReflectionAndDebrief': 'Reflexión e Informe',
    'MursionPortal.Label.NoTimeSelected': 'Sin Hora Seleccionada',
    'MursionPortal.Label.StartTime.EndTime.Selected': 'Deben seleccionarse tanto la Hora de Inicio como la Hora de Finalización',
    'MursionPortal.NextGen.Scenario.Planning': 'Planificación',
    'MursionPortal.HowItWorks.Experience.Text': '¡Tu experiencia comenzará automáticamente en pocos segundos!',
    'MursionPortal.HowItWorks.MicCamera': 'Mic/Cámara',
    'MursionPortal.HowItWorks.Prompt.Text': '¡Cuando se te solicite, haz clic en <strong>‘Permitir’</strong> para acceder al mic/cámara y que el avatar pueda verte y oírte!',
    'MursionPortal.HowItWorks.StartSession.Text': '¡Haz clic en <strong>‘Iniciar Sesión’</strong> y revisa la descripción del escenario para prepararte!',
    'MursionPortal.LearnerMaterials.KeyCompetencies': 'Competencias Clave',
    'MursionPortal.LearnerMaterials.ResearchMaterials': 'Materiales de Investigación',
    'MursionPortal.LearnerMaterials.PracticeOpportunity': 'Oportunidad para Practicar',
    'MursionPortal.ScenarioInfo.Label.ScenarioBank': 'El escenario se crea a partir del banco',
    'MursionPortal.DemandBasedScheduling.SlotSelectionMessage': 'Elije las franjas horarias que más te convengan. Programaremos tu escenario en una de las franjas horarias elegidas.',
    'MursionPortal.NextGen.ScenarioBank.SubStrengthAssessment': 'Punto Fuerte',
    'MursionPortal.NextGen.ScenarioBank.SubOpportunityAssessment': 'Oportunidad',
    // needs translations
    'MursionPortal.EngagementHub.EmailLearners': 'Email Learners', // TODO add translations
    'MursionPortal.EngagementHub.InviteToTeam': 'Invite to Team', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleTitle': 'Invite your learners to start or continue their practice', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleSummary': 'Engage your learners via email or by embedding shared links in your own communications.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations': 'View Invitations', // TODO add translations
    'MursionPortal.EngagementHub.CreateInvite': 'Create Invite', // TODO add translations
    'MursionPortal.NextGen.replayConversation':'Replay the Conversation', // TODO add translations
    'MursionPortal.EngagementHub.BackTo': 'Back to {clientName}', // TODO add translations
    'MursionPortal.EngagementHub.LearningPotential': 'At Mursion, we believe in maximizing every learner\'s potential.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationLearnMore': 'Communication Is Key! Learn more', // TODO add translations
    'MursionPortal.EngagementHub.UnlockTeamPotential': 'Unlock Your Team’s Potential', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Title': 'Invite your learners to schedule their simulations', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Summary': '58% of users are more likely to sign up for their simulation if you send a custom invitation to schedule.', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Label': 'Your Email, Your Way!', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Title': 'Manage email settings and gain insights', // TODO add translations
    'MursionPortal.EngagementHub.Customize': 'Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Title': 'Email Configurations', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Summary': 'Customize email messages that resonate with your learners and drive adoption and engagement.', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Title': 'Powerful Email Analytics', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Summary': 'Measure email open, click, and unread rates to discover insights and make data-driven decisions.', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Label': 'Add Personal Touch', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Title': 'Personalize messaging in the Mursion Portal', // TODO add translations
    'MursionPortal.EngagementHub.LearnerDashboard': 'Learner Dashboard', // TODO add translations
    'MursionPortal.EngagementHub.ScenarioPage': 'Scenario Pages', // TODO add translations
    'MursionPortal.EngagementHub.SchedulingPage': 'Scheduling Page', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackTooltip': 'This email will only go out if feedback report is enabled at project level. Please reach out to your Mursion representative to learn more.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Title': 'Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Summary': 'Use these tools to help customize different emails.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailName': 'Email Name', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SenderName': 'Sender Name (email will be no-reply@mursion.com)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ViewCustomize': 'View/Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EnableDisable': 'Enable/Disable', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockout': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.VideoFeedback': 'Video & Feedback', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ModifySettings': 'Click edit to modify the settings', // TODO add translations
    'MursionPortal.EngagementHub.BackTitle': 'Back to Engagement Hub', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey': 'Communication Is Key!', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.summary': 'A great Mursion rollout starts with effective communication to your learners. It\'s important to ensure they are prepared and understand the connection between Mursion and your learning and development program goals.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.TItle': 'Elements of Effective Learner Communication', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Title': 'Lead with Learner Benefits', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Summary': 'Lead with learner benefits, not product features. Share learner testimonials and tie the skills they are developing to business objectives.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Title': 'Practice makes Progress', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Summary': 'Practice is essential to developing new skills. Ensure your message highlights The Importance of Practice - And Our Reluctance To Do It to acknowledge challenges that learners face.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Title': 'Message from Leaders', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Summary': 'Ask senior leaders to communicate (meetings, written, video, etc) why Mursion sessions are valuable and how developing the target skills is tied to business priorities and growth opportunities.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Title': 'Set Expectations', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Summary': 'Mursion is a new technology that uses avatars powered by humans. Prepare learners for these conversations by sharing why and how avatars create psychology safety and is an effective way to practice.', // TODO add translations
    'MursionPortal.Tab.Engagement': 'Engagement', // TODO add translations
    'MursionPortal.Tab.EngagementHub': 'Engagement Hub', // TODO add translations
    'MursionPortal.Tab.EmailManagement': 'Email Management', // TODO add translations
    'MursionPortal.Tab.PortalMessaging': 'Portal Messaging', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.BackButton.Label': 'Back to Invite to Schedule', // TODO add translations
    'MursionPortal.Portal.Messaging.Heading.Text': 'Inform, engage, and delight learners using your organization’s unique brand voice.',  // TODO add translations
    'MursionPortal.Portal.Messaging.SubHeading.Text': 'Add custom messaging throughout the Portal to engage your learners.',  // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.InvitationDate': 'Invitation Date', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.MessageToLearners': 'Message to Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.ExcludedLearners': 'Excluded Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.CreatedBy': 'Created By', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text': '{scenariosCount} scenarios assigned to {learnerCount} learners', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text.AddLearners': 'Add Learners', // TODO add translations
    'MursionPortal.Tab.EmailManagement.Analytics': 'Analytics', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Teams.Invitation.Message': 'The following team(s) already have scheduled an invitation on this day:', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.New.Invitation.Message': 'Please make a note and start a new invitation', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Learners.Invitation.Text': 'Learners will receive the invitation in the early morning hours (Pacific time) on this day.', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.SendDate.Label': 'Send Date', // TODO add translations
    'MursionPortal.Scenario.Generation.FilterType.Label' : 'Generation', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.CancelInvitation.Modal.Text': 'Are you sure you want to cancel the invitation created by {createdBy}?', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackReport': 'Learner Feedback Report', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByLearner': 'Cancellation by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.MissedSessionByLearner': 'Missed by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerReleased': 'Learner Released', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockOut': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SessionConfirmation': 'Session Confirmation', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Invitation': 'Welcome to Mursion - a platform for you to practice your skills', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.InvitationReminder': `Reminder:You're invited to {licenseeName}`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Confirmation': 'Thanks for signing up! Your Simulation is confirmed.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.FirstReminder': `Don't forget! Your Mursion simulation is soon!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.SecondReminder': `Get excited! Your Mursion simulation starts in {upcomingBeforeStart} minutes.`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CancellationByByLearner': 'Your simulation was canceled', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.MissedSessionByLearner': 'We missed you today!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerLockedOut': 'You missed/cancelled this scenario too many times', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerReleased': 'Your session is available to schedule', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerSessionRecordingIsReady': 'Want to see how you did? Visit your Mursion Dashboard.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerFeedbackReport': 'Want to see how you did? Review your session report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerSessionRecordingReady': 'Learner Session Recording Ready', // TODO add translations
    'MursionPortal.EngagementHub.BackToEmailSettings': 'Back to Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.CustomizeEmail.Text': 'Learners will start receiving this email after you save.', // TODO add translations
    'MursionPortal.NextGen.Learner.FeedbackFrom': 'Feedback from', // TODO add translations
    'MursionPortal.NextGen.Scenario.SimMaterials': 'SIM Materials', // TODO add translations
    'MursionPortal.NextGen.Scenario.LearnerMaterials': 'Learner Materials', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Modal.BodyText': 'Are you sure you want to save the Enable/Disable settings', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByPS': 'Session Rescheduled by PS', // TODO add translations
    'MursionPortal.EngagementHub.EmailPreview.Sender': 'Sender', // TODO add translations
    'MursionPortal.Scenario.CoverStory.Tooltip.Text': 'Cover Story will be visible to clients in the scenario library as well as learners and SIMs in their materials',// TODO add translations
    'MursionPortal.Scenario.Summary.Tooltip.Text':'Summary will be visible to clients in the scenario library',// TODO add translations
    'MursionPortal.NextGen.Event.positiveMindset': 'There must be at least ({startCount}) positive mindset defined. You can specify a maximum of ({endCount}) positive mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.derailingMindset': 'There must be at least ({startCount}) derailing mindset defined. You can specify a maximum of ({endCount}) derailing mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment': 'There must be at least ({startCount}) strength assessment defined. You can specify a maximum of ({endCount}) strength assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.OpportunityAssessment': 'There must be at least ({startCount}) opportunity assessment defined. You can specify a maximum of ({endCount}) opportunity assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment.AvatarResponse': 'You have added {addedCount} avatar response for strength assessment(s), you must add at least {totalCount} avatar response for strength assessment(s).', // TODO add translations 
    'MursionPortal.NextGen.Event.OpportunityAssessment.AvatarResponse': 'You have added {addedCount} avatar response for opportunity assessment(s), you must add at least {totalCount} avatar response for opportunity assessment(s).', // TODO add translations 
    'MursionPortal.Label.ClientLevelMissCancelSetting': 'Client Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ProjectLevelMissCancelSetting':'Project Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ScenarioLevelMissCancelSetting':'Scenario Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.MissedCancelledSessions':'Missed/Cancelled Sessions', // TODO add translations 
    'MursionPortal.EngagementHub.ViewInvitations.Column.SenderName': 'Sender Name', // TODO add translations,
    'MursionPortal.NextGen.Event.Delete.ConfirmBox': 'Are you sure you want to delete this {message}?', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.PositiveMindset': 'Positive Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.DerailingMindset': 'Derailing Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.StrengthAssessment': 'Strength assessment', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.OpportunityAssessment': 'Opportunity assessment', // TODO add translations,
    'MursionPortal.PersonalizedMessageTitle': 'Personalized message header. This will appear before every personalized message in Portal.', // TODO add translations
    'MursionPortal.NextGen.ScenarioReferences': 'Scenario References', // TODO add translations 
    'MursionPortal.NextGen.ChildScenarios': 'Child Scenarios', // TODO add translations 
    'MursionPortal.NextGen.Label.Customized': 'Customized', // TODO add translations 
    'MursionPortal.EngagementHub.EmailSettings.InvitationPasswordUsers': 'Invitation (Password Users)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InvitationReminderPasswordUsers': 'Invitation Reminder (Password Users)', // TODO add translations
    'MursionPortal.Label.Clicked': 'Clicked', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByLearner': 'Session Rescheduled by Learner', // TODO add translations
    'MursionPortal.Client.Team.CopyTeamInviteLink.Tooltip':'Users who have not yet registered or have not yet been added to the Portal will not be able to access this link. Please click the "Add Learners" button.', // TODO add translations
    'Settings.Config.LearnerSessionReportDelay': 'Learner Session Report Delay', // TODO add translations
    'MursionPortal.SkillsReferences.Label': 'Skills References', // TODO add translations
    'MursionPortal.ResearchSummariesReferences.Label': 'Research Summaries References', // TODO add translations
    'MursionPortal.Search.Text': 'Search or select', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Event': 'Please select Event', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Skill': 'Please select Skill', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarBehavior': 'Avatar Behavior is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExpectedLearnerResponse': 'Expected Learner Response is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindset': 'Please Select Positive Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindsetDescription': 'Positive mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindset': 'Please Select Derailing Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindsetDescription': 'Derailing mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DescribeObservedAction': 'Describe Observed Action is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExplainImpact': 'Explain Impact is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarName': 'Please select avatar name', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PossibleAvatarResponse':'Possible Avatar Response is required', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate': 'Scheduling Lockout Date', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate.Tooltip': 'Lockout effective date for all learners who miss and/or late cancel', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.MinimumScore': 'You’ve changed the number of events. Please update the minimum score.', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SynopsisDescription': 'Synopsis description is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SuccessfulAssessment': 'Successful Assessment is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.OpportunityAssessment': 'Opportunity Assessment is required', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.PathwaysAffiliation': 'Pathway Affiliation(s)', // TODO add translations
    'MursionPortal.NextGen.BankScenarioReferences': 'Bank Scenario References', // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerActivationStatus':'Learner Activation',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteTitle':'Unlock Your Team’s Potential',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteMessage':'Target invitations to your learners with custom messaging',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInvite':'Invite to Schedule',  // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetRegistered':'Not Yet Registered', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetScheduled':'Not Yet Scheduled', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ScheduledNotYetCompleted':'Scheduled - Not Yet Completed', // TODO add translations
    'MursionPortal.NextGen.ViewAndInviteLearners': 'View & Invite Learners', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.SchedulingEmail': 'Session Specific Emails and other Join Session Emails', // TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.InviteToScheduleEmail': 'Invite-to-Schedule Emails', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.CallToActionEmail': 'Emails sent to learners inviting them to schedule scenario(s)', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations': 'Client Customizations', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InviteToSchedule.Tooltip': 'Invite to Schedule emails are able to be customized each time you send them.', // TODO add translations
    'MursionPortal.Contract.ContractTimezone': 'Contract Timezone', // TODO add translations
    'Dashboard.LeftPane.Simulations.SessionSearch':'Search for Session', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle': 'Client Scenario Title', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle.Tooltip': 'Client learners and program admins will see this title only. SIMs will see the bank scenario title and client scenario title in their SIM materials.', // TODO add translations
    'MursionPortal.NextGen.Event.DeleteMessage': 'Minimum 2 Events are mandatory for a Scenario.', // TODO add translations
    'Settings.Config.ProjectAndScenario.AllowSimPracticeSession': 'SIM Practice Session', // TODO add translations
    'MursionPortal.Calendar.Button.Practice.Session': 'Practice Session', // TODO add translations
    'MursionPortal.Scenario.InTrainingSimSpecialist': 'In Training SIM Specialists', // TODO add translations
    'MursionPortal.Scenario.NoInTrainingSimSpecialist': 'No In Training Sim Specialist', // TODO add translations
    'MursionPortal.Simspecialist.Thankyou.summary': 'You are scheduled for your practice simulation', // TODO add translations
    'MursionPortal.Button.DoNotSaveMyWork': 'Don’t Save my Work', // TODO add translations
    'MursionPortal.NextGen.Confirm.BrowserBack': 'You have not saved your changes, and navigating away will cause you to lose your work. Please complete required fields.', // TODO add translations
    'MursionPortal.UserConsent.Learner.Description': 'Si dice "Acepto", el software Mursion procesará su simulación en vivo y realizará una grabación de video de su sesión de simulación para su revisión en el portal del software Mursion. Salvo que lo exija la ley aplicable o con fines de control de calidad, Mursion no distribuirá ni divulgará su grabación de vídeo sin su permiso. Mursion utilizará datos anonimizados del uso que usted haga de su software para mejorar su tecnología de simulación.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.UserConsent.LearnerAndAccountOwnerOrFacilitator.Description': 'Su(s) sesión(es) de simulación se grabarán tanto en audio como en video a solicitud del cliente que obtuvo la licencia del software Mursion en su nombre. Si acepta continuar, se compartirá una copia de sus sesiones de simulación grabadas con un tercero seleccionado por el cliente para analizar y calificar las grabaciones. Estas personas están obligadas contractualmente a seguir el <code>aviso de privacidad de Mursion</code>, a menos que haya aceptado términos de privacidad diferentes. Sin embargo, no recibirá ni tendrá acceso a una copia de sus sesiones de simulación grabadas según los términos de la tarea o evaluación que haya aceptado completar. Mursion puede utilizar su grabación de vídeo con fines de control de calidad y utilizará datos anónimos del uso de su software para mejorar su tecnología de simulación.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.UserConsent.AccountOwnerOrFacilitator.Description': 'Su(s) sesión(es) de simulación se grabarán tanto en audio como en video a solicitud del cliente que obtuvo la licencia del software Mursion en su nombre. Si acepta continuar, se compartirá una copia de sus sesiones de simulación grabadas con un tercero seleccionado por el cliente para analizar y calificar las grabaciones. Estas personas están obligadas contractualmente a seguir el <code>aviso de privacidad de Mursion</code>, a menos que haya aceptado términos de privacidad diferentes. Sin embargo, no recibirá ni tendrá acceso a una copia de sus sesiones de simulación grabadas según los términos de la tarea o evaluación que haya aceptado completar. Mursion puede utilizar su grabación de vídeo con fines de control de calidad y utilizará datos anónimos del uso de su software para mejorar su tecnología de simulación.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.ProjectCreate.DeliveryHours.Validation': 'Delivery Hours Start time should be less than End time.', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetRegistered':'Unique number of learners who have been added to the Portal but have not yet completed registration', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetScheduled':'Unique number of learners who have registered but have not yet scheduled a session', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ScheduledNotYetCompleted':'Unique number of learners who have registered and scheduled at least one session but have not yet completed a session', // TODO add translations
    'MursionPortal.Restriction': 'Restriction', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Placeholder' : 'Paste a session ID and hit return',  // TODO add translations
    'Operations.TechRestriction.CreateDialog.Title': 'New Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.EditDialog.Title': 'Edit Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.ViewDialog.Title': 'View Holiday Tech Restriction', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerHours': 'Trigger (Hours)', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerMin': 'Trigger (Minutes)', // TODO add translations
    'MursionPortal.Duplicate.Scenario.Confirmation.Text': 'Are you sure you want to duplicate this scenario?',  // TODO add translations
    'MursionPortal.Contract.LineItem.ToolTip': 'A contract can only contain one type of line item. If your contract has both sessions and hours, please convert the sessions into hours and add it as an hours line item.', // TODO add translations
    'MursionPortal.ReflectionGuide.LearnerPerspective': 'Learner Perspective', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Table.Placeholder': 'Search for Session will load once you search session ID', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.EmptySession': 'No sessions match that ID', // TODO add translations
    'MursionPortal.SimMaterials.Label.IntroAndPreReflection': 'Intro & Pre-Reflection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown': 'Learner Breakdown', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown.Activated': 'Activated', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionNumber': 'Version Number', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionDetails': 'Version Details', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderHours': 'Trigger {tiggerValue} hours before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderMinutes': 'Trigger {tiggerValue} minutes before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.FirstEmailReminder.Tooltip': 'Time before Session when First Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SecondEmailReminder.Tooltip': 'Time before Session when Second Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.SimAttendance.MissedSimulation.Message': 'You missed this simulation so you are unable to edit attendance. Please contact Support if you did not miss the simulation or had a technical issue that caused you to miss the simulation.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Disclaimer': 'Invites are visible for last 30 days only', // TODO add translations
    'MursionPortal.PortalVersion': 'Portal Version', // TODO add translations
    'MursionPortal.ScenarioInfo.Label.AutoSIM': 'AutoSIM', // TODO add translations
    'MursionPortal.ProjectContract.AddConfirmation': 'Are you sure you want to add the contract to this project?', // TODO add translations
    'MursionPortal.ProjectContract.CompletedContract.AddConfirmation': 'Are you sure you want to add the completed contract to this project?', // TODO add translations
    'MursionPortal.Label.NewPortal' : 'New Portal', // TODO add translations
    'MursionPortal.Label.OldPortal' : 'Old Portal', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ReminderValidation': 'Second Reminder should be less than First Reminder', // TODO add translations
    'MursionPortal.Project.InviteToSchedule.EmailDisabled.Tooltip' :'Invite to Schedule is disabled as email settings are disabled. Enable them to send this invite', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimReportedError' :'SIM Reported Error (Does not impact session status)', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimLateOrNoShow' :'SIM late or no show', // TODO add translations
    'MursionPortal.SimAttendance.Error.SessionStartedTooEarly' :'Session started too early', // TODO add translations
    'MursionPortal.SimAttendance.Error.AvatarIssue' :'Avatar Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.MouthlipSyncIssueDuringSession' :'Mouth/lip sync issue during session', // TODO add translations
    'MursionPortal.SimAttendance.Error.Hardware' :'Hardware', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForSim' :'Audio/video issue for SIM', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForLearner' :'Audio/video issue for Learner', // TODO add translations
    'MursionPortal.SimAttendance.Error.OtherSimHardwareIssues' :'Other SIM hardware issues (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerAndSimBothJoined' :'Learner and SIM both joined, but no connection established', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimMomentIssue' :'SIM Moment issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.WifiIssue' :'Wifi Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerWifi' :'Learner Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimWifi' :'SIM Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.AdditionalDetails' :'Additional Details', // TODO add translations
    'MursionPortal.SimAttendance.Error.DescribeYourError' :'Describe your error...', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByProfessionalServiceOrAccountOwner': 'Cancellation by PS or AO', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CompleteLearnerSurvey': 'Complete Learner Survey', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CompleteLearnerSurvey': 'How\'d it go?', // TODO add translations
    'MursionPortal.CustomizeInvitation.ButtonText.Label': 'Button Text', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerLockedOut': 'You are locked out!', // TODO add translations
    'MursionPortal.Filter.ReleaseLevel.Placeholder': 'Release Level', // TODO add translations
    'MursionPortal.TOTP.Page.Heading': 'We just sent a security code to your {email}. Enter the code to sign in.', // TODO add translations
    'MursionPortal.TOTP.ResendOTP.Text': 'Resend Code', // TODO add translations
    'MursionPortal.TOTP.Code.Label': 'Code', // TODO add translations
    'MursionPortal.TOTP.IncorrectCode.ErrorMsg': 'The code is incorrect. Enter the correct code.', // TODO add translations
    'MursionPortal.TOTP.ResendNewCode.ErrorMsg': 'The code has expired, click "Resend" to receive a new code.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerReleased': 'You are ready to schedule again.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.FirstReminder': `You’ve an upcoming Mursion Session in {firstReminderTime} hours!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.SecondReminder': `Your Mursion Session is starting in {upcomingBeforeStart} minutes!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerFeedbackReport': 'Mursion - Review Your Session Performance Report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerSessionRecordingIsReady': 'Your Session Recording is now up! Review how you did', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.Confirmation': 'Your Mursion Session is Confirmed!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.CancellationByLearnerOrPSOrAO': 'Your {licenseeName} session was canceled', // TODO add translations
    'MursionPortal.Personalized.PathwayPage.Section': 'Message you want learners to see on Pathway Page', // TODO add translations
    'MursionPortal.Personalized.CreateNewPathway.Button.Label': 'Create new for specific pathway', // TODO add translations
    'Projects.ScenarioList.NoPathwaysFound': 'No Pathways have been found.',// TODO add translations
    'MursionPortal.Personalized.Pathway.Search.Placeholder': 'Search or select Pathway(s):',// TODO add translations
    'Mursion.Portal.Status.SimTechnicalError': 'Sim Technical Error',// TODO add translations
    'MursionPortal.SimAttendance.Sim.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Sim’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'Session.Edit.Modal.Notes.BlankNoteValidation': 'Either add a valid note or remove this block', // TODO add translations
    'MursionPortal.Scenario.Error.Msg': 'Please complete required field.', // TODO add translations
    'MursionPortal.Scenario.Skills.Error.Msg': 'The number of skills must be in the range of {minSkills} and {maxSkills}.', // TODO add translations
    'MursionPortal.Capitalized.Incomplete': 'INCOMPLETE', // TODO add translations
    'MursionPortal.Dashboard.Chart.LicenseBreakdown.Title': 'License Breakdown – Contract Item',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnerLicenses': 'Learner Licenses',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersRegistered': 'Learners Registered',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersScheduled': 'Learners Scheduled',  // TODO add translations
    'MursionPortal.SessionAttendance.AddAttendees': 'Add attendees',  // TODO add translations
    'MursionPortal.SessionAttendance.SimulationSpecialist': 'Simulation Specialist',  // TODO add translations
    'MursionPortal.Dashboard.SimulationSpecialist.Status': 'Simulation Specialist Status',  // TODO add translations
    'MursionPortal.Dashboard.Technical.Issue.Summary': 'If a technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.Dashboard.Provide.Details': 'Please provide more details on what happened',  // TODO add translations
    'MursionPortal.SessionAttendance.SwitchToSimRole.Message': 'Please switch role to Sim Specialist to view the page!', // TODO add translations
    'MursionPortal.SessionAttendance.Heading': '<code>Instructions:</code> Review the Session, Simulation Specialist, and Learner Status\' below and update as required. This form may be edited for 24 hours after the end of the session', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Label': 'This is automatically determined by the Learner and Simulation Specialist status. No action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Heading': '(selections below do not impact session status)', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Instructions': '<code>Instructions:</code> Session details <code>must</code> be provided for any session that has a Learner Status other than ‘Complete’ or ‘No Show’. To complete, select one or more issue type(s) and describe details in the text box. Session detail information does not impact the Learner, Simulation Specialist, or Session status.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Text': `Mursion Simulation Specialists only: If you filled out a UTS ticket for this session, please include the link to the Slack post in the text box (click the 3 vertical dots to the right of the post and select ‘Copy link').`, // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Simspecialist.Label': 'This is automatically determined by the system. If you encountered an error that prevented the session from being completed and the indicated status is ‘Left’, select ‘Technical Error (SIMS)’. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Learner.Label': 'This is automatically determined by the system. If the indicated status is inaccurate, select the correct status and explain the reason for the revision in Session Details. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.SimStatus.Label': 'Technical Error (SIMs)', // TODO add translations
    'Mursion.Portal.Status.UnableToComplete': 'Unable to Complete', // TODO add translations
    'Mursion.Portal.Status.ElectedToLeave': 'Elected to Leave', // TODO add translations
    'Mursion.Portal.Status.TechnicalErrorLearner': 'Technical Error (Learner)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.Audio': 'Audio', // TODO add translations
    'MursionPortal.SessionAttendance.Error.ConnectionInternet': 'Connection / Internet', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerTechnicalIssues': 'Learner technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MursionTechnicalIssues': 'Mursion technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherTechnicalIssues': 'Other technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MomentSoftware': 'Moment Software', // TODO add translations
    'MursionPortal.SessionAttendance.Error.AvatarOrEnvironmentLipSyncMovementEtc': 'Avatar or Environment (lip sync, movement, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.HardwareControllerLaptopEtc': 'Hardware (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerAndSimulationSpecialistBothJoined': 'Learner and Simulation Specialist both joined, no connection established', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherPleaseExplainBelow': 'Other (please explain below)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.NonTechnicalIssues': 'Non-technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerMaterialsOrPreparation': 'Learner materials or preparation ', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerLanguageFluency': 'Learner language fluency', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerElectedToLeave': 'Learner elected to leave', // TODO add translations
    'MursionPortal.Dashboard.Overview.Empty.Body.Message': 'Contract report will load once you make a selection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Simulation':'Session Breakdown – Simulation Contract Item', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Hour':'Session Breakdown – Hour Contract Item', // TODO add translations
    'MursionPortal.Copy.Tooltip.Text': 'Copy (plain text only, no formatting)', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Green': 'Green', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Thick': 'Thick', // TODO add translations
    'MursionPortal.Status.Connected.Label': 'Connected',  // TODO add translations
    'MursionPortal.Status.Immersing.Label': 'Immersing',  // TODO add translations
    'MursionPortal.Dashboard.NonTechnical.Issue.Summary': 'If a non-technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.AutoSim.Scenario.TooltipLabel': 'AutoSim scenario can’t be scheduled.', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam': 'Auto-Assign Team', // TODO add translations,
    'MursionPortal.Scenario.Planing.AutoAssignTeam.Tooltip': 'Automatically assign this scenario to the selected teams when learners access the scenario page via the provided link.', // TODO add translations
    'MursionPortal.Dashboard.Overview.NoAssociatedDataForSelectedContract': 'No associated data for the selected contract(s)', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelOne': 'Level 1', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelTwo': 'Level 2', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelThree': 'Level 3', // TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Title': 'Make sure you’re ready to take the session by testing your connection before joining.',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Button.Label': 'Test Connection',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link.Title': 'If any test fails, copy results at the end of the test and share them with our support team by',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link': 'Submitting a Support ticket.',// TODO add translations
    'MursionPortal.Dashboard.OnDemand.Text':'Ready, set, practice on-the-go',
    'MursionPortal.Dashboard.OnDemand.Title':'Try On-Demand Simulations',
    'MursionPortal.Dashboard.OnDemand.SubTitle':'Get a first look at the future of skill-building at work - dynamic GenAI simulations available instantly, no scheduling required. Practice anytime and receive actionable feedback that helps you grow and master new skills.',
    'MursionPortal.Dashboard.OnDemand.SmallTitle':'Early access, real results—get ahead now.',
    'MursionPortal.Dashboard.OnDemand.Button.Label':'Go to Mursion On-Demand',
    'MursionPortal.Dashboard.OnDemand.Button.Hide.Label':'Hide this',
    'MursionPortal.Dashboard.OnDemand.Preview':'PREVIEW',
    'MursionPortal.Dashboard.OnDemand.Text1':'✓ Scalable, focused practice',
    'MursionPortal.Dashboard.OnDemand.Text2':'✓ Flexible access',
    'MursionPortal.Dashboard.OnDemand.Text3':'✓ Targeted practice for mastery',
    'MursionPortal.Dashboard.OnDemand.Text4':'✓ Guided self-reflection',
    'MursionPortal.Dashboard.OnDemand.Mursion':'Mursion',
  },
};
export default spanishLangTranslations;
