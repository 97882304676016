import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TLocaleId } from 'src/i18n';

const TranslateMessage = (key: TLocaleId, values = {}) => {
  const props = {
    id: key,
    values: { ...values }
  };

  return <FormattedMessage {...props}/>;
};

export default TranslateMessage;