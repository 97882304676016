import { LOCALES } from 'src/i18n/locales';

export default {
  [LOCALES['English (United States)']]: {
    'Login.EditFile': 'Edit {file} and save to reload.',
    'Login.Hello': 'Hello',
    'Login.Title': 'Welcome to the Mursion Portal',
    'Login.Description': 'Design, schedule, and manage your virtual reality simulations',
    'Login.PrivacyPolicy': 'privacy policy',
    'Login.PrivacyDataInfo': 'privacy data info',
    'Login.TechnicalSupport': 'technical support',
    'Login.SignIn': 'Sign In',
    'Login.Next': 'Next',
    'Login.Email': 'Email',
    'Login.Password': 'Password',
    'Login.ForgotPassword': 'Forgot Password?',
    'Login.Login': 'Login',
    'Login.LoginAgain': 'Login again',
    'Login.EmailHint': 'enter your email',
    'Login.PasswordHint': 'enter your password',
    'Login.NewPasswordHint': 'enter new password',
    'Login.RepeatPasswordHint': 'enter new password again',
    'Login.Image': 'Mursion-Logo',
    'Filters.Heading': 'FILTERS:',
    'Dashboard.LeftPaneHeader': 'DASHBOARD',
    'Dashboard.LeftPane.CategoryHeader.Users': 'USERS',
    'Dashboard.LeftPane.User.UserActivity': 'User Activity',
    'Dashboard.LeftPane.User.SchedulingInfo': 'Scheduling Info',
    'Dashboard.LeftPane.CategoryHeader.Simulations': 'SESSIONS',
    'Dashboard.LeftPane.Simulations.SimulationSummaries': 'Session Summaries',
    'Dashboard.LeftPane.Simulations.SimulationDetail': 'Session Detail',
    'Dashboard.LeftPane.CategoryHeader.Projects': 'PROJECTS',
    'Dashboard.LeftPane.Project.ProjectActivity': 'Projects Activity',
    'Dashboard.LeftPane.Project.ProjectUtilization': 'Projects Utilization',
    'Dashboard.LeftPane.CategoryHeader.Sessions': 'SESSIONS',
    'Dashboard.LeftPane.Session.SessionLearner': 'Session Learners',
    'Dashboard.LeftPane.CategoryHeader.Legacy': 'LEGACY',
    'Dashboard.LeftPane.CategoryHeader.Performance': 'UTILIZATION',
    'Dashboard.LeftPane.Performance.ContractSummaries': 'Contract Summaries',
    'Dashboard.LeftPane.Performance.ContractOverview': 'Overview',
    'Dashboard.LeftPane.Performance.ContractProgress': 'Contract Progress',
    'Dashboard.LeftPane.Performance.SelfService': 'Self-Service Utlization',
    'Dashboard.LeftPane.CategoryHeader.Learners': 'LEARNERS',
    'Dashboard.LeftPane.Learners.LearnerActivity': 'Learner Activity',
    'Dashboard.LeftPane.Learners.LearnerSimulations': 'Learner Sessions',
    'Dashboard.LeftPane.CategoryHeader.Videos': 'VIDEOS', // TODO: add translations
    'Dashboard.LeftPane.Videos.NotUploaded': 'Recordings Not Uploaded', // TODO: add translations
    'Dashboard.NotUploaded.MainHeader': 'Recordings Not Uploaded', // TODO: add translations
    'Dashboard.NotUploaded.TableHeader': 'Recordings Not Uploaded Data', // TODO: add translations
    'Dashboard.VideosTable.Column.SessionId': 'SessionID',// TODO: add translations
    'Dashboard.VideosTable.Column.SimspecialistId': 'SIMSpecialistID',// TODO: add translations
    'Dashboard.VideosTable.Column.ClientId': 'ClientID',// TODO: add translations
    'Dashboard.VideosTable.Column.SessionStart': 'Session Start',// TODO: add translations
    'Dashboard.VideosTable.Column.SessionEnd': 'Session End',// TODO: add translations
    'Dashboard.VideosTable.Column.SoftwareVersion': 'Scenario Version',// TODO: add translations
    'Dashboard.VideosTable.HoverTitle': ' ', // TODO: add translations
    'Dashboard.VideosTable.NoVideosError': 'No videos have been found.', // TODO: add translations
    'Dashboard.Button.ExportToEmail': 'Export Data',
    'Dashboard.Button.ExportToEmailSentMsg': 'The report will be sent to {email} soon.',
    'Dashboard.Button.ExportToEmail.HoverTitle': 'Export Data',
    'Dashboard.Button.ExportToEmail.HoverTitleNotAvailable': 'Export is not available',
    'Dashboard.Next': 'Next',
    'Dashboard.Last': 'Last',
    'Dashboard.Help': 'Help',
    'Dashboard.First': 'First',
    'Dashboard.Back': 'Back',
    'Dashboard.Total': 'Total Entries',
    'Dashboard.Simulations.SimulationSummaries.TableHeader': 'Session Summaries Data',
    'Dashboard.Learners.LearnerActivity.TableHeader':'Learner Activity Data',
    'Dashboard.Learners.LearnerSimulation.TableHeader' : 'Learner Sessions Data',
    'FiltersHeading': 'FILTERS:',
    'Filters.All': 'All',
    'Filters.More': ' more',
    'Filters.AllExcept': 'All, except ',
    'Filters.MoreClients': ' more clients',
    'Filters.Client': 'Client',
    'Filters.SimSpecialist': 'SIM Name',
    'Filters.AllClients': 'All Clients',
    'Filters.ClientPlaceHolderHint': 'Search Clients...',
    'Filters.SimSpecialistPlaceHolderHint': 'Search SimSpecialists',
    'MursionPortal.Placeholder.ClientValue': 'Select a Client',
    'MursionPortal.Placeholder.ContractValue': 'Select a Contract',
    'MursionPortal.Placeholder.NoContractValue': 'No Contract Available',
    'MursionPortal.Placeholder.SimSpecialistValue': 'Select a SimSpecialist',
    'Filters.Industries': 'Industries',
    'Filters.IndustriesPlaceHolderHint': 'Search Industries...',
    'Filters.IndustriesDisabledTitle': 'Disabled',
    'Filters.Project': 'Project',
    'Filters.ProjectPlaceHolderHint': 'Search Projects...',
    'Filters.ProjectDisabledTitle': 'Select Clients first',
    'Filters.Role': 'Role',
    'Filters.RoleDisabledTitle': 'Selecting role is disabled',
    'Filters.Scenario': 'Scenario',
    'Filters.ScenarioPlaceHolderHint': 'Search Scenarios...',
    'Filters.ScenarioDisabledTitle': 'Select Clients and Projects first',
    'Filters.Team': 'Team',
    'Filters.TeamPlaceHolderHint': 'Search Teams...',
    'Filters.TeamDisabledTitle': 'Select Clients and Projects first',
    'Filters.SessionStatus': 'Session Status',
    'Filters.SessionStatusPlaceHolderHint': 'Search Session Status...',
    'Filters.SimulationStatus': 'Session Status',
    'Filters.SimulationStatusPlaceholder': 'Search Simulation Status...',
    'Filters.LearnerStatus': 'Learner Status',
    'Filters.LearnerStatusPlaceHolderHint': 'Search Learner Status...',
    'Filters.RegistrationStatus': 'Registration Status',
    'Filters.RegistrationStatus.ShowAllRegistration': 'Registration Status: All',
    'Filters.RegistrationStatus.ShowAcceptedRegistration': 'Registration Accepted',
    'Filters.RegistrationStatus.ShowNotAcceptedRegistration': 'Registration Not Accepted',
    'Filters.TimeZone': 'Time Zone',
    'Filters.Date.LastMonth': 'Last Month',
    'Filters.Date.Yesterday': 'Yesterday',
    'Filters.Date.Week': 'Week',
    'Filters.Date.Today': 'Today',
    'Filters.Date.Tomorrow': 'Tomorrow',
    'Filters.Date.NextMonth': 'Next Month',
    'Filters.Date.LastSevenDays' : 'Last Week', // TODO: add translation
    'Filters.Date.NextSevenDays' : 'Next Week', // TODO: add translation
    'Filters.Date.ThisYear' : 'This Year', // TODO: add translation
    'Filters.Date.From': 'From',
    'Filters.Date.FromDate': 'From Date',
    'Filters.Date.To': 'to',
    'Filters.Date.ToDate': 'To Date',
    'Filters.SimulationRecorded': 'Simulation Recorded',
    'Filters.Standardized': 'Standardized',
    'Filters.LateRescheduled': 'Late Rescheduled',
    'Filters.Boolean.Yes': 'Yes',
    'Filters.Boolean.No': 'No',
    'Filters.SsoProtocolType': 'Protocol: All',
    'Filters.SsoProtocolTypePlaceHolderHint': 'Search SSO Protocol',
    'Filters.SSO.PrivacyAll': 'Type: All',
    'Filters.SSO.PrivacyPublic': 'Type: Public',
    'Filters.SSO.PrivacyPrivate': 'Type: Private',
    'Filters.SSO.PrivacyAriaLabel': 'sso privacy type filter',
    'Filters.Contract': 'Contract',
    'Filters.Session': 'Session', // TODO: add translation
    'Dashboard.Filters.SimulationID': 'Session Id',
    'Dashboard.UserActivity.MainHeader': 'User Activity',
    'Dashboard.UserActivity.TableHeader': 'User Activity Data', // TODO: add translation
    'Dashboard.UserActivityTable.HoverTitle': 'View User Summary',
    'Dashboard.Export.ErrMsg': 'The maximum limit of entries for export is {limit}. Please modify your request and try again.',
    'MursionPortal.Close': 'Close',
    'MursionPortal.GenerateLearnerSurveyLink': 'Copy Survey Link',
    'MursionPortal.SurveyLinkLabel': 'Your survey link has been copied to your clipboard',
    'MursionPortal.Attention': 'Attention!',
    'MursionPortal.LinkCannotBeGenerated': 'Link can not be generated as the Contract ID field is missing in this scenarios project.',
    'Dashboard.UserTable.Column.Provider': 'Provider',
    'Dashboard.UserTable.Column.Client': 'Client',
    'Dashboard.UserTable.Column.ProfileId': 'Profile ID',
    'Dashboard.UserTable.Column.UserName': 'Name',
    'Dashboard.UserTable.Column.UserEmail': 'User Email',
    'Dashboard.UserTable.Column.UserEmail.ButtonHoverTitle': 'Copy Email',
    'Dashboard.UserTable.Column.Roles': 'Role',
    'Dashboard.UserTable.Column.SSO': 'SSO',
    'Dashboard.UserTable.Column.DateInvited': 'Invited',
    'Dashboard.UserTable.Column.DateRegistered': 'Registered',
    'Dashboard.UserTable.Column.DateRegistered.ButtonTitle': 'Resend Invite',
    'Dashboard.UserTable.Column.DateArchived': 'Archived',
    'Dashboard.UserTable.Column.DateLastLogin': 'Last Login',
    'Dashboard.UserTable.Column.Scenarios': 'Scenarios',
    'Dashboard.UserTable.Column.Teams': 'Teams',
    'Dashboard.UserTable.Column.Industry': 'Industry',
    'Dashboard.UserTable.Column.UsedSoftwareVersions': 'Software',
    'Dashboard.UserTable.Column.Accepted': 'Accepted',
    'Dashboard.UserTable.Column.SessionsScheduled': 'Simulations Scheduled',
    'Dashboard.UserTable.Column.SessionsCompleted': 'Simulations Completed',
    'Dashboard.UserTable.Column.SessionsMissed': 'Simulations Missed',
    'Dashboard.UserTable.Column.LateCancellations': 'Simulations Cancelled',
    'Dashboard.UserTable.Column.SessionsError': 'Simulations Errors',
    'Session.Tab.Scheduled': 'Scheduled',
    'Session.Tab.Active': 'Active',
    'Session.Tab.Past': 'Past',
    'Session.Tab.Cancelled': 'Cancelled',
    'Session.Search': 'Search',
    'Session.Table.Column.Time': 'Time',
    'Session.Table.Column.Client': 'Client',
    'Session.Table.Column.Project': 'Project',
    'Session.Table.Column.ProjectId': 'Contract ID',// TODO: add translation',
    'Session.Table.Column.SoftwareVersion': 'Software Version',
    'Session.Table.Column.Scenario': 'Scenario',
    'Session.Table.Column.Trainees': 'Trainees',
    'Session.Table.Column.Training': 'training',
    'Session.Table.Column.Session': 'session',
    'Session.Token.Label.Clients': 'Clients',
    'Session.Token.Label.Projects': 'Projects',
    'Session.Token.Label.Scenarios': 'Scenarios',
    'Session.Token.Label.Teams': 'Teams',
    'Session.Token.Label.SessionStatuses': 'Session Statuses',
    'Session.Button.More': 'More',
    'Session.Time.NotRunning': 'Not yet running',
    'Session.Time.Finished': 'Already finished',
    'Session.Time.Running': 'Running Now',
    'Session.Table.Column.Learners': 'Learners',
    'Session.Table.Column.Team': 'Team',
    'Session.Table.Column.Trainer': 'Trainer',
    'Session.Table.Column.SimSpecialist': 'Sim Specialist',
    'Session.Table.Column.TimeUntil': 'Time Until',
    'Session.Table.Column.Created': 'Created',
    'Session.Table.Column.Cancelled': 'Cancelled',
    'Session.Table.Caption.SessionDetailsList': 'Session details list',
    'Session.Export': 'Export',
    'Session.Export.Message': 'Export content filtered by',
    'Session.CalendarDate.Start': 'From:',
    'Session.CalendarDate.End': 'To:',
    'Session.Cancel': 'Cancel',
    'Session.Confirm': 'Confirm',
    'Session.Total': 'TOTAL',
    'Session.Filter.SessionNotFoundMessage': 'No Sessions have been found.',
    'Session.Filter.LearnerUpcomingSessionNotFoundMessage': 'You have no upcoming sessions scheduled.',
    'Session.Filter.UpcomingSessionNotFoundMessage': 'No upcoming sessions scheduled.',
    'Session.Filter.LearnerActiveSessionNotFoundMessage': 'You have no active sessions.',
    'Session.Filter.ActiveSessionNotFoundMessage': 'No active sessions.',
    'Session.Filter.LearnerCompletedSessionNotFoundMessage': 'You have not yet completed any sessions.',
    'Session.Filter.SessionReportNotFoundMessage': 'No available session report.',
    'Session.Filter.CompletedSessionNotFoundMessage': 'No sessions have been completed.',
    'Session.Filter.CancelledSessionNotFoundMessage': 'No sessions have been cancelled.',
    'Session.Edit.Modal.Table.Column.DateTime': 'Date/Time',
    'Session.Edit.Modal.Table.Column.UserRole': 'User (Role)',
    'Session.Edit.Modal.Table.Column.Change': 'Change',
    'Session.Edit.Modal.Table.Column.SessionStatus': 'Session Status',
    'Session.Edit.Modal.Table.Column.Reason': 'Reason',
    'Session.Edit.Modal.AssetIndustry': 'Asset Industry',
    'Session.Edit.Modal.SimulationEnvironment': 'Simulation Environment(s)',
    'Session.Edit.Modal.AvatarInSimulation': 'Avatar(s) in Simulation(s)',
    'Session.Edit.Modal.Date': 'Date',
    'Session.Edit.Modal.Time': 'Time',
    'Session.Edit.Modal.DeliveryMode': 'Delivery Mode',
    'Session.Edit.Modal.Duration': 'Duration',
    'Session.Edit.Modal.Status': 'Status',
    'Session.Edit.Modal.LearnersAttended': 'Learners Attended',
    'Session.Edit.Modal.ReasonForCancellation': 'Reason for cancellation',
    'Session.Edit.Modal.MoreInfo': 'More info...',
    'Session.Edit.Modal.HideAdditionalInfo': 'Hide additional info...',
    'Session.Edit.Modal.AddToCalendar': 'Add To Calendar',
    'Session.Edit.Modal.AttendSession': 'Attend Session',
    'Session.Edit.Modal.PleaseWait': 'Please wait...',
    'Session.Edit.Modal.CopySessionLink': 'Copy Session Link',
    'Session.Edit.Modal.SessionLink': 'Session Link',
    'Session.Edit.Modal.SubmitReview': 'Submit Review',
    'Session.Edit.Modal.SessionRequestPending': 'Session Request Pending',
    'Session.Edit.Modal.Back': 'Back',
    'Session.Edit.Modal.Learners': 'Learner(s):',
    'Session.Edit.Modal.Learners.UnauthenticatedCheckbox': 'Only unauthenticated users will join',
    'Session.Edit.Modal.Trainees': 'Trainee(s):',
    'Session.Edit.Modal.Trainer': 'Trainer:',
    'Session.Edit.Modal.SimSpecialist': 'Sim Specialist:',
    'Session.Edit.Modal.Notes': 'Notes:',
    'Session.Edit.Modal.ClientNotes': 'Client Notes:',
    'Session.Edit.Modal.Notes.EmptyMessage': 'There are no notes',
    'Session.Edit.Modal.Attendance': 'Attendance:',
    'Session.Edit.Modal.ShowHistory': 'Show history...',
    'Session.Edit.Modal.HideHistory': 'Hide history...',
    'Session.Edit.Modal.LearnersAttendedLabel': 'Learners attended:',
    'Session.Edit.Modal.SimulationContent': 'Simulation Content',
    'Session.Edit.Modal.SimulationContent.Name': 'Name:',
    'Session.Edit.Modal.SimulationContent.Description': 'Description:',
    'Session.Edit.Modal.SimulationContent.Image': 'Image:',
    'Session.Edit.Modal.RestoreSession.ConfirmationText': 'Are you sure you want to restore this session?',
    'Session.Edit.Modal.CancelTraining.ConfirmationText': 'Are you sure you want to cancel this training?',
    'Session.Edit.Modal.CancelSession.ConfirmationText': 'Are you sure you want to cancel this session?',
    'Session.Edit.Modal.SessionSwap.ConfirmationText': 'Are you sure you want to accept swap this session?',
    'Session.Edit.Modal.AcceptRequest.ConfirmationText': 'Are you sure you want to accept this session request?',
    'Session.Edit.Modal.AssignSS.ConfirmationText': 'Are you sure you want to assign a new Sim Specialist?',
    'Session.Edit.Modal.ChangeSS.ConfirmationText': 'Are you sure you want to change a Sim Specialist?',
    'Session.Edit.Modal.Button.RestoreSession': 'Restore Session',
    'Session.Edit.Modal.Button.CancelTraining': 'Cancel Training',
    'Session.Edit.Modal.Button.CancelSession': 'Cancel Session',
    'Session.Edit.Modal.Button.AcceptSwap': 'Accept Swap',
    'Session.Edit.Modal.Button.Leave': 'Cancel My Session', // TODO: add translation
    'Session.Edit.Modal.Button.Participate': 'Participate',
    'Session.Edit.Modal.Button.Swap': 'Swap',
    'Session.Edit.Modal.Button.UpdateSession': 'Update Session',
    'Session.Edit.Modal.Button.UpdateTraining': 'Update Training',
    'Session.Edit.Modal.Button.AcceptRequest': 'Accept Request',
    'Session.Edit.Modal.Button.AssignSS': 'Assign SS',
    'Session.Edit.Modal.Button.ChangeSS': 'Change SS',
    'Session.Attendance.Table.Column.UserName': 'User Name',
    'Session.Attendance.Table.Column.Time': 'Time',
    'Session.Attendance.Table.Column.Status': 'Status',
    'Session.ViewSessionReport': 'View Session Report',
    'Session.ViewEdit': 'View/Edit Session',
    'Session.Edit.Modal.Table.HistoryNotFoundMessage': 'There is no history yet',
    'Session.Export.ErrMsg': 'The maximum limit of entries for export is {limit}. Please modify your request and try again.',
    'Session.Table.Column.Action': 'Action',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoTrainers': 'No trainers to select',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoSimSpecialists': 'No sim specialists to select',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoTrainers': 'No trainers selected',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoSimSpecialists': 'No sim specialists selected',
    'Session.Edit.Modal.SessionSimSpecialistField.EditIconLabel': 'edit',
    'Session.Edit.Modal.SessionSimSpecialistField.Search.Placeholder': 'Search',
    'Session.Edit.Modal.SessionSimSpecialist.Button.Accept': 'Accept',
    'ConfirmationDialogue.AddReasonForChange': 'Please, add a reason for change',
    'ConfirmationDialogue.Button.Submit': 'Submit',
    'ConfirmationDialogue.Button.Cancel': 'Cancel',
    'ConfirmationDialogue.Button.Ok': 'Ok',
    'ConfirmationDialogue.ReasonForChange': 'Reason for change',
    'ConfirmButton.CancelSession.ConfirmCancellation': 'Confirm Cancellation',
    'ConfirmButton.CancelSession': 'Cancel session',
    'ConfirmButton.CancelSession.No': 'No',
    'Pagination.Button.First': 'First',
    'Pagination.Button.Back': 'Back',
    'MursionPortal.UserName': 'User Name',
    'MursionPortal.Email': 'Email',
    'ClientUserTable.Column.Roles': 'Roles',
    'MursionPortal.Department': 'Department',
    'MursionPortal.Location': 'Location',
    'ClientUserTable.Column.Projects': 'Projects',
    'ClientUserTable.Column.Teams': 'Teams',
    'ClientUserTable.Column.Accepted': 'Accepted',
    'ClientUserTable.HoverTitle': 'View/Edit User',
    'ClientUserTable.NoUserError': 'There are no users yet',
    'MursionPortal.FirstName': 'First Name',
    'MursionPortal.LastName': 'Last Name',
    'MursionPortal.Phone': 'Phone',
    'Users.TableModal.EmailPlaceHolder': 'example@domain.com',
    'Users.TableModal.Title': 'Title',
    'Users.TableModal.LocationPlaceHolder': 'City, Country',
    'Users.TableModal.DepartmentPlaceHolder': 'Department Name',
    'Users.TableModal.TitlePlaceHolder': 'Job Title',
    'Users.TableModal.SSOIDPlaceHolder': 'SSO ID',
    'Users.Table.Modal.SSOID': 'SSO ID',
    'Users.Table.Modal.SettingsHeader': 'Facilitator/Learner Settings',
    'Users.Table.Modal.SignLanguageInterpreter': 'Sign Language Interpreter',
    'Users.Table.ModalHeader': 'User info',
    'Users.Table.ModalButton.Cancel': 'Cancel',
    'Users.Table.ModalButton.Delete': 'Delete',
    'Users.Table.ModalButton.Save': 'Save',
    'Users.Table.ModalButton.SendInvite': 'Send Invite',
    'Users.Table.ModalButton.Restore': 'Restore',
    'Users.Table.ErrorOnLoadUserMessage': 'An error occurred while loading the user!',
    'Users.Table.Modal.Teams.Text': 'No teams have been added yet',
    'Users.Table.Modal.Teams': 'Teams',
    'Users.Table.Modal.EmployeeIdentification': 'Employee Identification',
    'Users.Table.Modal.Timezone': 'Timezone',
    'Users.Table.Modal.Roles': 'Roles',
    'Users.Table.Modal.Settings.LearnerButton': 'As Learner',
    'Users.Table.Modal.Settings.FacilitatorButton': 'As Facilitator',
    'Users.Button.CreateUserButton': 'Create User ',
    'Users.Button.Cancel': 'Cancel',
    'Users.Button.ImportUsers': 'Import Users',
    'Users.Button.Export': 'Export',
    'Users.heading.ExportContentFilteredBy': 'Export content filtered by',
    'Users.Button.Active': 'ACTIVE',
    'Users.Button.Archived': 'ARCHIVED',
    'Users.Button.All': 'ALL',
    'Users.Text.Total': 'TOTAL',
    'Users.Heading.NewUsers': 'New Users',
    'Users.Placeholder': 'example@domain.com',
    'Users.Button.AddUser': 'Add user ',
    'Users.Button.CreateUser': 'Create User',
    'Users.Button.CreateUsers': 'Create Users',
    'Users.Heading.ImportNewUsers': 'Import New Users',
    'Users.Heading.ImportNewExternalUsers': 'Import New External Users',
    'Users.Button.ImportUserList': 'Import Users List',
    'Users.Text.InstructionMessage.UsersWithoutPredefined': 'Users without predefined teams will be assigned to',
    'Users.Text.AddCsv': 'Add .csv or .xslx file containing a list of email addresses placed in one column.',
    'Users.Text.ToImport': 'To import additional fields, please follow these guidelines:',
    'Users.Text.ListUser': 'User email must be separated from the list of user teams by Comma, Semicolon, Pipe or Colon.',
    'Users.Text.ListTeams': 'Teams must be separated from each other by Comma.',
    'Users.Text.ListFields': 'Fields and each of the Team must be specified in separate cells.',
    'Users.Text.UserRole': 'User role type will default to Learner',
    'Users.Text.UnlessOtherwiseIndicated': ' unless otherwise indicated',
    'Users.Text.SampleImport': 'You can download a sample import files here:',
    'Users.DownloadCsvTemplate': 'Download Csv template',
    'Users.DownloadExcelTemplate': 'Download Excel template',
    'Users.Button.Update': 'Update',
    'Users.Labels.Clients': 'Clients',
    'Users.Labels.Projects': 'Projects',
    'Users.Labels.Scenarios': 'Scenarios',
    'Users.Labels.Teams': 'Teams',
    'Users.Labels.Roles': 'Roles',
    'Users.Text.OnlyAccepted': 'Only Accepted:',
    'Users.Button.Confirm': 'Confirm',
    'Users.Button.AccOwner': 'Account Owner',
    'Users.Button.Facilitator': 'Facilitator',
    'Users.Button.Learner': 'Learner',
    'Users.Button.ProfServices': 'Prof. Services',
    'Users.Button.ProjectManager': 'Project Manager',
    'Users.Button.SimSpecialist': 'Sim Specialist',
    'Users.Button.ITManager': 'IT Manager',
    'ITManager.TechRestriction.Table.CreateButton': 'Add Restriction',
    'ITManager.TechRestriction.Table.EmptyMessage': 'No bank tech restrictions have been found.',
    'ITManager.TechRestriction.Table.Tag': 'Tag',
    'ITManager.TechRestriction.Table.StartDate': 'Start Date',
    'ITManager.TechRestriction.Table.EndDate': 'End Date',
    'ITManager.TechRestriction.EditDialog.TagName': 'Tag Name',
    'ITManager.TechRestriction.EditDialog.Title': 'Edit Tech Restriction',
    'ITManager.TechRestriction.CreateDialog.Title': 'New Tech Restriction',
    'ITManager.TechRestriction.ViewDialog.Title': 'View Tech Restriction',
    'ITManager.TechRestriction.EditDialog.EnterTag': 'Enter tag name',
    'ITManager.TechRestriction.Table.HoverTitle': 'View/Edit tech restriction',
    'ITManager.TechRestriction.Table.NoTechRestrictionError': 'There are no tech restrictions yet',
    'Users.Button.BusinessAdmin': 'Business Admin',
    'Users.Button.SupportAdmin': 'Support Admin',
    'Users.Button.DevAdmin': 'Dev Admin',
    'Users.Button.ImportExternalUsers': 'Import External Users',
    'Users.Button.AssignLearners': 'Assign Learners',
    'Users.CreateUser.Email.Placeholder': 'example@domain.com',
    'Users.Text.Instructions': ' Add .csv or .xslx file which contains the list of users with the following fields: email, ssoid, First name, Last name, Role, Team. All fields, except Team are required.',
    'Users.Text.Guidelines': 'Please follow these guidelines:',
    'Users.Button.CreateUser.PencilIcon': 'Edit user details',
    'Users.Button.CreateUser.TrashIcon': 'Remove user',
    'Users.ExportButton.TextYes': 'Yes',
    'Users.ExportButton.TextNo': 'No',
    'Users.Button.OK': 'OK',
    'Users.Button.AddProject': 'Add project',
    'Users.CreateUser.Label.AddToProjects': 'Add to project(s)',
    'Users.UserInfo.Label.AddTeams': 'Add Teams',
    'Users.UserInfo.Label. SelectATeam': ' Select a team',
    'Users.UserInfo.Label.AssignToTeam': 'Assign to Team:',
    'Users.CreateUser.Label.NoProjectsSpecified': 'No projects specified',
    'Users.Option.SelectClient': 'Select Client',
    'Users.Label.Client': 'Client:',
    'Users.Label.RemoveTeam': 'Remove team',
    'Users.Label.ChooseATeam': 'Choose a team',
    'Users.Project.Warning': 'This Client doesn\'t have projects. Please select another one.',
    'Users.Project.Warning.UnknownProject': 'Unknown project',
    'Users.ProjectContainer.Warning': 'At least one project should be added',
    'Users.CompanyUsers.Button.Ok': 'Ok',
    'Users.CompanyUsers.Button.Accept': 'Accept',
    'Users.CreateUser.Label.Tier': 'Tier',
    'Users.CreateUser.Label.HireDate': 'Hire Date',
    'Users.CreateUser.Label.StationType': 'Station Type',
    'Users.CreateUser.ToggleButton.Home': 'Home',
    'Users.CreateUser.ToggleButton.Office': 'Office',
    'Users.CreateUser.Label.Hours': 'Hours',
    'Users.CreateUser.ToggleButton.FullTime': 'Full Time',
    'Users.CreateUser.ToggleButton.PartTime': 'Part Time',
    'Users.CreateUser.Label.ZoomID': 'Zoom ID',
    'Users.CreateUser.ZoomID.Placeholder': 'Zoom ID',
    'Users.CreateUser.ZoomID.Hover': 'Please enter a Zoom ID',
    'Users.CreateUser.Label.UserRoles': 'User Role(s)',
    'Users.CreateUser.Label.ProjectManagerSettings': 'Project Manager Settings',
    'Users.CreateUser.Label.SimSpecialistSettings': 'Sim Specialist Settings',
    'Users.CreateUser.Label.Certificates': 'Certificates',
    'Users.CreateUser.Label.TurnOnEditCertificates': 'Turn On edit certificates',
    'Users.CreateUser.Label.TurnOffEditCertificates': 'Turn Off edit certificates',
    'Users.CreateUser.Label.NoCertificatesHaveBeenAddedYet': 'No certificates have been added yet',
    'Users.CreateUser.Label.AddCertificate': 'Add Certificate',
    'Users.CreateUser.Label.CertificateOwner': 'Certificate Owner',
    'Users.CreateUser.Button.RemoveCertificate': 'Remove certificate',
    'Users.TableHeading.Name': 'Name',
    'Users.TableHeading.Email': 'Email',
    'Users.TableHeading.Roles': 'Roles',
    'Users.TableHeading.Location': 'Location',
    'Users.TableHeading.Accepted': 'Accepted',
    'Users.Table.Hover.ViewEditUser': 'View/Edit User',
    'Users.Table.Hover.NoUsersYet': 'There are no users yet',
    'Users.CreateUser.Button.Client': 'Client',
    'Users.CreateUser.Label.ChooseAClient': 'Choose a client',
    'Users.CreateUser.Label.SelectAClient': 'Select a client',
    'Users.CreateUser.Label.ChooseACertificate': 'Choose a certificate',
    'Users.CreateUser.Label.SelectACertificate': 'Select a certificate',
    'Users.CreateUser.Label.NoCertificatesToSelect': 'No certificates to select',
    'Users.CreateUser.Button.AddCertificate': 'Add Certificate ',
    'Users.CreateUser.PlaceHolder.SelectTier': 'Select Tier',
    'Users.RequiredField.Warning': 'Please check that all the fields are filled correctly',
    'Users.RequiredField.ApplyChanges': 'Apply changes',
    'Users.Heading.NewUserInfo': 'New User info',
    'Users.SearchPlaceHolderText': 'Enter User Name',
    'Users.SearchNoUserFoundError': 'No client users have been found.',
    'Users.Table.Modal.Settings.AddTeamHeader': 'Add Teams',
    'Users.Table.Modal.Settings.ChooseTeamHeader': 'Choose a team',
    'Users.Table.Modal.Settings.AssignTeamHeader': 'Assign to Team:',
    'Users.Table.Modal.Settings.SelectTeamOptionHeader': 'Select a team',
    'Users.Table.Modal.Settings.TeamLearner': '(learner)',
    'Users.Table.Modal.Settings.TeamFacilitator': '(facilitator)',
    'Users.Table.Modal.Settings.RemoveTeamOption': 'Remove Team',
    'Users.Table.Modal.Settings.TurnOnEditTeamsOption': 'Turn on edit teams',
    'Users.Table.Modal.Settings.TurnOffEditTeamsOption': 'Turn off edit teams',
    'Users.Total': 'TOTAL',
    'Users.Table.Modal.ArchiveHeader.ConfirmArchiveUser': 'Confirm Archive User',
    'Users.Table.Modal.ArchiveText': 'Are you sure you want to archive this user?',
    'Users.Table.Modal.ArchiveUserButton': 'Archive User',
    'Users.Table.Modal.RestoreHeader.ConfirmRestoreUser': 'Confirm Restore User',
    'Users.Table.Modal.RestoreText': 'Are you sure you want to restore this user?',
    'Users.Table.Modal.RestoreUserButton': 'Restore User',
    'Users.Table.Modal.CancelButton': 'Cancel',
    'Users.Table.Modal.ArchiveButton': 'Archive',
    'Users.Table.Modal.RestoreButton': 'Restore',
    'Users.Table.Modal.SendInviteButton': 'Send Invite',
    'Users.CreateUserButton.Modal.CreateUserButton': 'Create User',
    'Users.Table.Modal.UpdateUserButton': 'Update User',
    'Users.ViewUserOptions': 'View {companyName} User',
    'Users.NewUserOptions': 'New {companyName} User',
    'Users.EditUserOptions': 'Edit {companyName} User',
    'Reports.SessionListHeader.Completed': 'Completed',
    'Reports.SessionListHeader.TotalSessionTime': ' Total Session time: ',
    'Reports.SessionListHeader.TotalActualTime': ' Total Actual time: ',
    'Reports.SessionListHeader.SimulationCompletionTime': 'Simulation Completion time',
    'Reports.SessionListHeader.CantOpenReport': 'Please wait while our analytics engine processes your results. You may need to refresh this page after a few minutes.',
    'Reports.SessionListHeader.NoReportsFound': 'No reports found.',
    'Reports.ReportCard.Button.ReviewSimulation': 'REVIEW SIMULATION',
    'Reports.ReportCard.Strategies': 'Strategies',
    'Reports.ReportCard.NotChecked': 'Not Checked',
    'Dashboard.SchedulingInfoTable.Modal.SortBy': 'Sort by:',
    'Dashboard.SchedulingInfoTable.Modal.NoItemsFound': 'No items have been found.',
    'Dashboard.SchedulingInfoTable.Modal.Teams': 'Teams',
    'Dashboard.SchedulingInfoTable.Modal.Teams.Placeholder': 'Enter Team Name',
    'Dashboard.SchedulingInfoTable.Modal.Teams.EmptyMessage': 'No teams have been found',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.TeamName': 'Team Name:',
    'Dashboard.SchedulingInfoTable.Modal.Learners': 'Learners:',
    'Dashboard.SchedulingInfoTable.Modal.Projects': 'Projects:',
    'Dashboard.SchedulingInfo.Filter.IndustriesAll': 'Industry',
    'Dashboard.SchedulingInfo.Filter.SearchIndustries.PlaceholderHint': 'Search Industries...',
    'Dashboard.SchedulingInfo.Filter.IndustryDisabledTitle': 'Disabled',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion': 'Software',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion.PlaceholderHint': 'Used Software Versions...',
    'Dashboard.SchedulingInfoTable.Modal.Total': 'TOTAL',
    'Dashboard.SchedulingInfoTable.Modal.ScenarioName': 'Scenario name',
    'Dashboard.SchedulingInfoTable.Modal.SessionDate': 'Session date',
    'Dashboard.SchedulingInfoTable.Modal.ProjectName': 'Project name',
    'Dashboard.SchedulingInfoTable.Modal.SoftwareVersion': 'Software version',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.DeliveryMode': 'Delivery Mode',
    'Dashboard.SchedulingInfoTable.Modal.SessionStatus': 'Session status',
    'Dashboard.SchedulingInfoTable.Modal.Name': 'Name',
    'Dashboard.SchedulingInfoTable.Modal.Status': 'Status',
    'Dashboard.SchedulingInfoTable.Modal.Recording': 'Recording goes to',
    'Dashboard.SchedulingInfoTable.Modal.TeamName': 'Team name',
    'Dashboard.SchedulingInfoTable.Modal.Learner': 'Learners',
    'Dashboard.SchedulingInfoTable.Modal.CompletedSessions': 'Completed Sessions',
    'Dashboard.SchedulingInfoTable.Modal.DeliveryMode': 'Delivery mode',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.ProjectName': 'Project Name',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.SoftwareVersion': 'Software Version',
    'Dashboard.SchedulingInfoTable.Modal.Timeline': 'Timeline',
    'Dashboard.SchedulingInfoTable.Modal.SchedulesSessions': 'Schedules sessions',
    'Dashboard.SchedulingInfoTable.Modal.MissedSessions': 'Mixed Sessions',
    'Dashboard.SchedulingInfoTable.Modal.LateCancellations': '\"Late\" Cancellations',
    'Dashboard.SchedulingInfoTable.Modal.TimeSpent': 'Time spent',
    'Dashboard.SchedulingInfoTable.Modal.CancelledBy': 'Cancelled by',
    'Dashboard.SchedulingInfoTable.Modal.CancellationReason': 'Cancellation reason',
    'Dashboard.SchedulingInfoTable.Modal.DateScheduled': 'Date Scheduled',
    'Dashboard.SchedulingInfoTable.Modal.DateCancelled': 'Date Cancelled',
    'Dashboard.SchedulingInfo.MainHeader': 'Scheduling Info',
    'Dashboard.SchedulingInfo.TableHeader': 'Scheduling Info Data',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Teams': 'Teams',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterTeamName': 'Enter Team Name',
    'Dashboard.SchedulingInfoTable.Modal.TeamsEmptyMessage': 'No teams have been found',
    'Dashboard.SchedulingInfoTable.Modal.Filter.TeamName': 'Team Name',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Scenarios': 'Scenarios',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterScenarioName': 'Enter Scenario Name',
    'Dashboard.SchedulingInfoTable.Modal.ScenariosEmptyMessage': 'No scenarios have been found',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioName': 'Scenario Name',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ProjectName': 'Project Name',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SoftwareVersion': 'Software Version',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioStatus': 'Scenario Status',
    'Dashboard.SchedulingInfoTable.Modal.Filter.DeliveryMode': 'Delivery Mode',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsScheduled': 'Sessions Scheduled',
    'Dashboard.SchedulingInfoTable.Modal.SessionsEmptyMessage': 'No sessions have been found',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsDateTime': 'Session date/time',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsCompleted': 'Sessions Completed',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsMissed': 'Sessions Missed',
    'Dashboard.SchedulingInfoTable.Modal.Filter.LateCancellations': '"Late" Cancellations',
    'Dashboard.SchedulingInfoTable.Modal.Filter.CancelledBy': 'Cancelled By',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsError': 'Sessions Error',
    'Dashboard.ContractSummaries.MainHeader': 'Contract Summaries',
    'Dashboard.ContractOverview.MainHeader': 'Overview',
    'Dashboard.ContractProgress.MainHeader': 'Contract Progress',
    'Dashboard.ContractProgress.Navigation.ContractItems': 'Contract Items',
    'Dashboard.ContractProgress.Navigation.Projects': 'Projects',
    'Dashboard.ContractProgress.Navigation.Scenarios': 'Scenarios',
    'Dashboard.ContractProgress.Column.Item': 'Item',
    'Dashboard.ContractProgress.Column.Purchased': 'Purchased',
    'Dashboard.ContractProgress.Column.Remaining': 'Remaining',
    'Dashboard.ContractProgress.Column.Completed': 'Completed',
    'Dashboard.ContractProgress.Column.Scheduled': 'Scheduled',
    'Dashboard.ContractProgress.Column.Missed': 'Learner Missed',
    'Dashboard.ContractProgress.Column.LateCancellations': 'Late Cancellations',
    'Dashboard.ContractProgress.Column.TotalRescheduled':'Total Rescheduled',
    'Dashboard.ContractProgress.Column.RescheduledMakeup':'Rescheduled Makeup',
    'Dashboard.ContractProgress.Column.LateRescheduledSimulations':'Late Rescheduled',
    'Dashboard.ContractProgress.Column.EarlyRescheduledSimulations':'Early Rescheduled',
    'Dashboard.ContractProgress.Column.ProjectName': 'Project Name',
    'Dashboard.ContractProgress.Column.StartDate': 'Start Date',
    'Dashboard.ContractProgress.Column.EndDate': 'End Date',
    'Dashboard.ContractProgress.Column.Error': 'Error',
    'Dashboard.ContractProgress.Column.ScenarioName': 'Scenario Name',
    'Dashboard.ContractProgress.Column.Standardized': 'Standardized',
    'Dashboard.ContractProgress.Column.LearnersAssigned': 'Learners Assigned',
    'Dashboard.ContractProgress.Column.LearnersCompleted': 'Learners Completed',
    'Dashboard.ContractProgress.Column.LearnersCompletedPercent': '% Learners Completed',
    'Dashboard.ContractProgress.Column.Provider': 'Provider',
    'Dashboard.ContractProgress.Column.Client': 'Client',
    'Dashboard.ContractProgress.Column.ContractName': 'Contract Name',
    'Dashboard.ContractProgress.Column.ContractId': 'Contract ID',
    'Dashboard.ContractProgress.Column.ContractNameHoverTitle': 'Copy ID',
    'Dashboard.ContractProgress.Column.CompletedUtilization': 'Session Completion',
    'Dashboard.ContractProgress.Column.ScheduledUtilization': 'Estimated Completion',
    'Dashboard.ContractProgress.Column.RemainingUtilization': 'Remaining Utilization',// TODO add translationst
    'Dashboard.ContractProgress.Column.EstimatedRemaining': 'Estimated Remaining',// TODO add translation
    'Dashboard.ContractProgress.Column.ErrorLearner': 'Error (Learner)',
    'Dashboard.ContractProgress.Column.LearnersInvited': 'Learners Invited',
    'Dashboard.ContractProgress.Column.LearnersScheduledPercent': '% Learners Scheduled',
    'Dashboard.ContractProgress.ColumnTooltip.Client': 'Name of the client',
    'Dashboard.ContractProgress.ColumnTooltip.ContractName': 'Name of the Contract',
    'Dashboard.ContractProgress.ColumnTooltip.PercentComplete': 'Percentage of days completed of the contract',
    'Dashboard.ContractProgress.ColumnTooltip.CompletedUtilization': 'Simulations completed as a percentage of simulations purchased',
    'Dashboard.ContractProgress.ColumnTooltip.ScheduledUtilization': 'Simulations completed and scheduled as a percentage of simulations purchased',
    'Dashboard.ContractProgress.ColumnTooltip.RemainingUtilization': 'Simulations Remaining and scheduled as a number of simulations purchased', // TODO add translations
    'Dashboard.ContractProgress.ColumnTooltip.MissedLearner': 'Simulations that have been missed by the learner.',
    'Dashboard.ContractProgress.ColumnTooltip.ErrorLearner': 'Simulations that ended in error on the learner side of connection',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersInvited': 'Learners who have been invited to schedule a simulation',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersScheduledPercent': 'Learners who have scheduled at least one simulation as a percentage of those who have been invited to schedule a simulation',
    'Dashboard.ContractProgress.ColumnTooltip.Item': 'Items contracted in the Contract',
    'Dashboard.ContractProgress.ColumnTooltip.Purchased': 'Total number of simulations purchased as part of a contract',
    'Dashboard.ContractProgress.ColumnTooltip.Remaining': 'Purchased sessions less any completed sessions',
    'Dashboard.ContractProgress.ColumnTooltip.Completed': 'Simulations that have been successfully completed',
    'Dashboard.ContractProgress.ColumnTooltip.Scheduled': 'Upcoming simulations that are scheduled but have not yet been completed (includes those running)',
    'Dashboard.ContractProgress.ColumnTooltip.Missed': 'Simulations that have been missed by the learner',
    'Dashboard.ContractProgress.ColumnTooltip.LateCancellations': 'Sessions that were canceled late (after the cancellation deadline) by the account owner, facilitator, or learner.',
    'Dashboard.ContractProgress.ColumnTooltip.TotalRescheduled':'Total simulations that are scheduled', // TODO: add translations
    'Dashboard.ContractProgress.ColumnTooltip.RescheduledMakeup':'Total simulations that are marked as rescheduled makeup', // TODO: add translations
    'Dashboard.ContractProgress.ColumnTooltip.LateRescheduledSimulations':'simulations that are rescheduled late.', // TODO: add translations
    'Dashboard.ContractProgress.ColumnTooltip.EarlyRescheduledSimulations':'simulations that are rescheduled early.', // TODO: add translations
    'Dashboard.ContractProgress.ColumnTooltip.Error': 'Simulations that ended in error on the learner side of connection',
    'Dashboard.ContractProgress.ColumnTooltip.ProjectName': 'Name of the project',
    'Dashboard.ContractProgress.ColumnTooltip.StartDate': 'Contract start date as stated in the Contract.',
    'Dashboard.ContractProgress.ColumnTooltip.EndDate': 'Contract end date as stated in the Contract.',
    'Dashboard.ContractProgress.ColumnTooltip.ScenarioName': 'Name of the scenario',
    'Dashboard.ContractProgress.ColumnTooltip.Standardized': 'This is a standardized scenario',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersAssigned': 'Number of learners who\'ve been assigned a scenario',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompleted': 'Learners who have completed at least one simulation as a percentage of those who have been invited to schedule a simulation',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompletedPercent': 'Percentage of distinct learners who\'ve completed the scenario',
    'Dashboard.ContractProgress.InfoBadge.ContractDates': 'Contract Dates:',
    'Dashboard.ContractProgress.InfoBadge.ContractID': 'Contract ID:',
    'Dashboard.SelfService.MainHeader': 'Self-Service Utilization',
    'Dashboard.SelfService.Column.SelfServiceProvider': 'Provider',
    'Dashboard.SelfService.Column.SelfServiceTotalHoursUtilized': 'Total Hours Utilized',
    'Dashboard.SelfService.Column.SelfServiceHoursCompleted': 'Hours Completed',
    'Dashboard.SelfService.Column.SelfServiceHoursMissed': 'Hours Missed',
    'Dashboard.SelfService.Column.SelfServiceHoursErrored': 'Hours Errored',
    'Dashboard.SelfService.Column.SelfServiceSimulationsComplete': 'Simulations Complete',
    'Dashboard.SelfService.Column.SelfServiceSimulationsMissed': 'Simulations Missed',
    'Dashboard.SelfService.Column.SelfServiceSimulationsErrored': 'Simulations Errored',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceProvider': 'Provider',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceTotalHoursUtilized': 'Simulations that have been successfully completed',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursCompleted': 'Hours Completed',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursMissed': 'Simulations that have been missed by the learner',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursErrored': 'Simulations that ended in error on the learner side of connection',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsComplete': 'Simulations Complete',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsMissed': 'Simulations Missed',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsErrored': 'Simulations Errored',
    'Dashboard.Learners.Column.Provider': 'Provider',
    'Dashboard.Learners.Column.Client': 'Client',
    'Dashboard.Learners.Column.LearnerName': 'Learner Name',
    'Dashboard.Learners.Column.DateInvited': 'Date Invited',
    'Dashboard.Learners.Column.LastLogin': 'Last Login',
    'Dashboard.Learners.Column.NoAssignedTeams': 'No. Assigned Teams',
    'Dashboard.Learners.Column.AssignedProjects': 'Assigned Projects',
    'Dashboard.Learners.Column.AssignedTeams':'Assigned Teams',
    'Dashboard.Learners.Column.AssignedScenarios': 'Assigned Scenarios',
    'Dashboard.Learners.Column.ScheduledScenarios': 'Scheduled Scenarios',
    'Dashboard.Learners.Column.CompletedScenarios': 'Completed Scenarios',
    'Dashboard.Learners.Column.NoAssignedScenarios': 'No. Assigned Scenarios',
    'Dashboard.Learners.Column.NoCompletedScenarios': 'No. Completed Scenarios',
    'Dashboard.Learners.Column.Completed': 'Completed',
    'Dashboard.Learners.Column.Scheduled': 'Scheduled',
    'Dashboard.Learners.Column.LateCancelled': 'Late Canceled',
    'Dashboard.Learners.Column.EndedInError': 'Ended In Error',
    'Dashboard.Learners.Column.EarlyCancelled': 'Early Canceled',
    'Dashboard.Learners.Column.LateReschedules': 'Late Reschedules',
    'Dashboard.Learners.Column.EarlyReschedules': 'Early Reschedules',
    'Dashboard.Learners.Column.RescheduledMakeUp': 'Rescheduled Make Up',
    'Dashboard.Learners.Column.ErrorSim': 'Error (SIM)',
    'Dashboard.Learners.Column.CancelledOps': 'Cancelled (Ops)',
    'Dashboard.Learners.Column.ProjectName': 'Project Name',
    'Dashboard.Learners.Column.ScenarioName': 'Scenario Name',
    'Dashboard.Learners.Column.ScheduledStartDate': 'Scheduled Start Date',
    'Dashboard.Learners.Column.ScheduledStartTime': 'Scheduled Start Time',
    'Dashboard.Learners.Column.ScheduledEndTime': 'Scheduled End Time',
    'Dashboard.Learners.Column.SimulationStatus': 'Session Status',
    'Dashboard.Learners.Column.LearnerStatus': 'Learner Status',
    'Dashboard.Learners.Column.LearnerTimeSpent': 'Learner Time Spent',
    'Dashboard.Learners.Column.SimSpecialistName': 'Sim Specialist Name',
    'Dashboard.Learners.Column.SimSpecialistStatus': 'Sim Specialist Status',
    'Dashboard.Learners.Column.SimulationId': 'Session ID',
    'Dashboard.Learners.Column.SimulationId.CopyHover': 'Copy Session ID',
    'Dashboard.Learners.Column.SSO_ID':'SSO ID',
    'Dashboard.Learners.Column.Report': 'Report',
    'Dashboard.Learners.Column.Recorded':'Recorded',
    'Dashboard.Learners.Column.LearnerRecordingConsent':'Learner Recording Consent', 
    'Dashboard.Learners.Column.RecordingRecipients':'Recording Recipients',
    'Dashboard.Learners.Column.RecordingUploaded':'Recording Uploaded',
    'Dashboard.Learners.ColumnTooltip.Provider': 'Name of the provider/licensee',
    'Dashboard.Learners.ColumnTooltip.Client': 'Name of the client',
    'Dashboard.Learners.ColumnTooltip.LearnerName': 'User\'s last and first name',
    'Dashboard.Learners.ColumnTooltip.DateInvited': 'Date that a learner was first invited to schedule a scenario',
    'Dashboard.Learners.ColumnTooltip.LastLogin': 'Date that a user last logged into the portal',
    'Dashboard.Learners.ColumnTooltip.AssignedProjects': 'Projects that this learner is currently assigned to',
    'Dashboard.Learners.ColumnTooltip.AssignedTeams': 'Teams that this learner is currently assigned to',
    'Dashboard.Learners.ColumnTooltip.AssignedScenarios': 'Scenarios that this learner is currently assigned to',
    'Dashboard.Learners.ColumnTooltip.ScheduledScenarios': 'Scenarios that this learner has scheduled but not yet completed',
    'Dashboard.Learners.ColumnTooltip.CompletedScenarios': 'Scenarios that this learner has completed',
    'Dashboard.Learners.ColumnTooltip.NoAssignedTeams': 'Number of teams that have been assigned to a learner',
    'Dashboard.Learners.ColumnTooltip.NoAssignedScenarios': 'Number of distinct scenarios that have been assigned to a learner',
    'Dashboard.Learners.ColumnTooltip.NoCompletedScenarios': 'Number of distinct scenarios that have been completed by a learner',
    'Dashboard.Learners.ColumnTooltip.Completed': 'Simulations that have been successfully completed',
    'Dashboard.Learners.ColumnTooltip.Scheduled': 'Upcoming simulations that are scheduled but have not yet been completed (includes those running)',
    'Dashboard.Learners.ColumnTooltip.Missed': 'Simulations that have been missed by the learner',
    'Dashboard.Learners.ColumnTooltip.LateCancelled': 'Sessions that were canceled late (after the cancellation deadline) by the account owner, facilitator, or learner.',
    'Dashboard.Learners.ColumnTooltip.EndedInError': 'Simulations that ended in error on the learner side of connection',
    'Dashboard.Learners.ColumnTooltip.EarlyCancelled': 'Simulations that were canceled early (before the cancellation deadline) by the learner, facilitator, or account owner or simulations',
    'Dashboard.Learners.ColumnTooltip.LateReschedules': 'Simulations that were rescheduled late (after the cancellation deadline) by the learner, facilitator, or account owner',
    'Dashboard.Learners.ColumnTooltip.RescheduledMakeUp': 'Simulations that were missed earlier by the learner, facilitator,account owner or simulations',
    'Dashboard.Learners.ColumnTooltip.EarlyReschedules': 'Simulations hours that were rescheduled early (before the cancellation deadline) by the learner, facilitator, or account owner',
    'Dashboard.Learners.ColumnTooltip.ErrorSim': 'Simulations that ended in error on the SIM side of connection',
    'Dashboard.Learners.ColumnTooltip.CancelledOps': 'Simulations that were cancelled by Mursion',
    'Dashboard.Learners.ColumnTooltip.ProjectName': 'Name of the project',
    'Dashboard.Learners.ColumnTooltip.ScenarioName': 'Name of the scenario',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartDate': 'Date this simulation was scheduled to start',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartTime': 'Time this simulation was scheduled to start',
    'Dashboard.Learners.ColumnTooltip.ScheduledEndTime': 'Time this simulation was scheduled to end',
    'Dashboard.Learners.ColumnTooltip.SimulationStatus': 'Final status for the simulation',
    'Dashboard.Learners.ColumnTooltip.LearnerStatus': 'Final learner status for the simulation',
    'Dashboard.Learners.ColumnTooltip.LearnerTimeSpent': 'Minutes learner spent in the simulation. For Zoom and Meet learners, this is estimated based on the SIM specialist if completed',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistName': 'Simulation specialist assigned to deliver the simulation',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistStatus': 'Final SIM specialist status for the simulation',
    'Dashboard.Learners.ColumnTooltip.SimulationId': 'Unique identifier of the simulation',
    'Dashboard.Learners.ColumnTooltip.SSOID':'Users who have been provisioned ',
    'Dashboard.Learners.ColumnTooltip.Report': 'Link to the simulation report to view (if available) the recording and analytics for the simulation',
    'Dashboard.Learners.ColumnTooltip.Recorded':'Denotes if the simulation was recorded or if it was disallowed.',// TODO: add translations
    'Dashboard.Learners.ColumnTooltip.LearnerRecordingConsent':'Denotes if the learner gave consent to the simulation being recorded.',// TODO: add translations
    'Dashboard.Learners.ColumnTooltip.RecordingRecipients':'Roles that the simulation recording is sent to.',// TODO: add translations
    'Dashboard.Learners.ColumnTooltip.RecordingUploaded':'Denotes if the simulation recording was uploaded.',// TODO: add translations
    'RestorePassword.EnterEmailAddress': 'Enter your email address and click the Restore button - an email will be sent to you with a link to reset your password.',
    'RestorePassword.ResetPassword': 'Reset Password',
    'RestorePassword.EmailHint': 'enter your email',
    'RestorePassword.Email': 'Email',
    'RestorePassword.Button.Cancel': 'Cancel',
    'RestorePassword.Button.Restore': 'Restore',
    'RestorePassword.Modal.IfValidEmailAddressEntered': 'If a valid email address was entered, an email with reset instructions has been sent to the associated account. Contact tech support if the issue persists.',
    'RestorePassword.Modal.Button.GotIt': 'Got It',
    'Trainings.Heading.AllTrainingSessions': 'all training sessions',
    'Requests.Table.Swap': 'SWAP',
    'Requests.Table.SchedulingRequests': 'Scheduling Requests',
    'Requests.Total': 'TOTAL',
    'Requests.Table.NoSessionsFound': 'No Sessions have been found.',
    'Requests.Table.NoUpcomingSwapSessions': 'There is no upcoming SWAP sessions.',
    'Requests.Table.NoUpcomingRequests': 'You have no upcoming requests.',
    'GetTimeToEvents.Day': 'Day',
    'GetTimeToEvents.Days': 'Days',
    'GetTimeToEvents.Minute': 'Minute',
    'GetTimeToEvents.Minutes': 'Minutes',
    'GetTimeToEvents.Hour': 'Hour',
    'GetTimeToEvents.Hours': 'Hours',
    'GetTimeToEvents.LessThanOneMinute': 'Less than 1 minute',
    'SessionReports.SessionInformation': 'Session Information',
    'SessionReports.SessionInformation.Hover.Information': 'Information',
    'SessionReports.SmartMetrics': 'Voice Analytics Details',
    'SessionReports.SendVideoLink.Modal.ShareLinkToVideo': 'Share link to video',
    'SessionReports.Button.BackToReports': 'Back To Reports',
    'SessionReports.Button.BackToTrainings': 'Back To Trainings',
    'SessionReports.Button.HideInfo': 'Hide Info',
    'SessionReports.Button.ShowInfo': 'Show Info',
    'SessionReports.Button.SendVideoLink': 'Send Video Link',
    'SessionReports.ErrorOnLoadingVideo': 'Please use a laptop or desktop computer to view your recording.', // TODO add translations
    'SessionReports.TryAgain': 'Try again',
    'SessionReports.VideoRecordingPermissionNotGranted': 'This video is unavailable because a participant did not want to be recorded.',
    'SessionReports.NoVideoAvailableForSession': 'There is no video for this session.',
    'SessionReports.Mode': 'Mode:',
    'SessionReports.SessionLength': 'Session Length:',
    'SessionReports.LearnersAttended': 'Learners Attended:',
    'SessionReports.ScenarioTimeline': 'Scenario Timeline:',
    'SessionReports.ActualSessionTime': 'Actual Session Time:',
    'SessionReports.ActualSimulationTime': 'Actual SimulationTime:',
    'SessionReports.LastUserStatus': 'Last User Status:',
    'SessionReports.SessionStatus': 'Session Status:',
    'SessionReports.Delivery': 'Delivery',
    'SessionReports.SendVideoLink.Modal.AddEmails': 'Add emails',
    'SessionReports.SendVideoLink.Modal.Button.AddEmail': 'Add email ',
    'SessionReports.SendVideoLink.Modal.EmailPlaceHolder': 'example@domain.com',
    'SessionReports.SendVideoLink.Modal.Button.Cancel': 'Cancel',
    'SessionReports.SendVideoLink.Modal.Button.Send': 'Send',
    'SessionReports.FactSessionInfo': 'Fact Session Info',
    'SessionReports.Text.NoTraineeParticipatedInSession': 'No Trainee has participated in the session',
    'SessionReports.Text.NoLearnerParticipatedInSession': 'No Learner has participated in the session',
    'SessionReports.ToolTip.TrackingYours': 'It is most important that your perception of an outcome or strategies item is aligned with that of the avatar(s). This indicates that you are tracking your impact on the avatar(s) thereby helping improve social effectiveness. This information is contained in the table below.',
    'SessionReports.ToolTip.TrackingLearners': 'It is most important that Learner perception of an outcome or strategies item is aligned with that of the avatar(s). This indicates that Learner is tracking his/her impact on the avatar(s) thereby helping improve social effectiveness. This information is contained in the table below.',
    'SessionReports.ToolTip.AvatarsHeaderYours': 'This column indicates the avatar(s) perception of your simulation performance. A checkmark indicates that the avatars perceived you to be successful in achieving the outcome while a cross-mark indicates room for improvement.',
    'SessionReports.ToolTip.AvatarsHeaderLearners': 'This column indicates the avatar(s) perception of learner\'s simulation performance. A checkmark indicates that the avatars perceived the learner to be successful in achieving the outcome while a cross-mark indicates room for improvement.',
    'SessionReports.ToolTip.SelfHeaderYours': 'This column indicates your own assessment of your simulation performance. A checkmark indicates that you thought you were successful while a cross-mark indicates room for improvement.',
    'SessionReports.ToolTip.SelfHeaderLearners': 'This column indicates Learner assessment of his/her simulation performance. A checkmark indicates that the Learner thought he/she was successful while a cross-mark indicates room for improvement.',
    'SessionReports.ToolTip.VideoLink': 'You may share a link to your simulation performance video with your peers. Simply enter their email in the pop-up box to do so. Note that your analytics will not be shared with your peers.',
    'SessionReports.ToolTip.SmartMetrics': 'Smart-metrics are generated through the use of advanced algorithms. Observe these computer-generated measures to see if they have any impact on your overall tracking during various simulations.',
    'SessionReports.ToolTip.MiScore': 'The Mursion Index (MI) Score is an indicator of your social effectiveness during this conversation. It is computed using a variety of data including your conversational turn taking pattern and its impact on the avatar(s). Try to assess your impact on the avatar(s) and approach conversations in different ways to see how you can positively impact your MI Score.',
    'SessionReports.ToolTip.ListeningSpeakingTime': 'This is an indicator showing the ratio of the time you spent speaking vs. listening during the conversation.',
    'SessionReports.ToolTip.Emotions': 'Emotions (Beta): We are currently investigating algorithms that allow us to interpret your voice patterns to determine overall sentiment. Observe this data to see if you had a positive, negative or neutral connotation in your vocal patterns over time. More importantly, observe the impact you had on the avatar(s) vocal patterns.',
    'SessionReports.ToolTip.TurnTracking': 'Turn taking is a set of measures that shed insight into the collaborative nature / social effectiveness between you and the avatars.',
    'SessionReports.ToolTip.Pauses': 'Pauses are an indirect measure of your fluency during conversation.',
    'SessionReports.ToolTip.ArticulationRate': 'This is a measure of the pace at which a continuous set of information is delivered by you.',
    'SessionReports.TableHeader.Outcome': 'Outcome',
    'SessionReports.TableHeader.Self': 'Self',
    'SessionReports.TableHeader.Avatars': 'Avatar(s)',
    'SessionReports.TableHeader.Strategies': 'Strategies',
    'MursionPortal.NotAvailable': 'N/A',
    'Dashboard.ProjectUtilization.MainHeader': 'Projects Utilization',
    'Calendar.Button.Schedule': 'Schedule',
    'Calendar.Button.Today': 'Today',
    'MursionPortal.Availability': 'Availability',
    'Calendar.Button.Schedule.QualityAssurance': 'Quality Assurance',
    'MursionPortal.Training': 'Training',
    'Calendar.Button.Schedule.EmergencySession': 'Emergency Session',
    'Calendar.Button.Schedule.Session': 'Session',
    'Calendar.Button.Schedule.BreakPrep': 'Break/Prep',
    'MursionPortal.Month': 'Month',
    'MursionPortal.Week': 'Week',
    'MursionPortal.Day': 'Day',
    'Calendar.Heading.Calendars': 'Calendars',
    'Calendar.Tip.SelectACalendar': 'Select a calendar to view availability',
    'Calendar.ScheduledHours.SumOfAllScheduledSessions': 'Sum of all scheduled sessions in the current view',
    'MursionPortal.ShortLabel.Hours': 'hrs',
    'Calendar.ScheduledHours.SumOfAllTrainingSessions': 'Sum of all scheduled training sessions in the current view',
    'Calendar.ScheduledHours.Trainings': 'Trainings',
    'Calendar.ScheduledHours.SumOfRemainingAvailabilityHours': 'Sum of all remaining SS availability hours in the current view',
    'Calendar.ScheduledHours.Available': 'Available',
    'MursionPortal.Scheduled': 'Scheduled',
    'Calendar.Label.Display': 'Display',
    'MursionPortal.Client': 'Client',
    'Calendar.Filter.Company': 'Company',
    'MursionPortal.Sessions': 'Sessions',
    'MursionPortal.SessionRecording': 'Session Recording',
    'MursionPortal.VoiceAnalytics': 'Voice Analytics',
    'MursionPortal.VideoTab': 'Video',
    'MursionPortal.SessionInformation': 'Session Information',
    'MursionPortal.SmartMatrix': 'Voice Analytics Details',
    'MursionPortal.DeliveryDetails': 'Delivery Details',
    'MursionPortal.ConversationalFlow': 'Conversational Flow',
    'MursionPortal.ConversationalFlowTooltip': 'This graphs depicts your effectiveness during a conversation. It is made up of turn taking inputs which reveal how well you are able to participate in a constructive conversation.',
    'MursionPortal.BalancedTalkTime': 'Conversation Breakdown',
    'MursionPortal.BalancedFeedback': 'Balanced Feedback',
    'MursionPortal.ConversationalDisruptions': 'Participant Interjection',
    'MursionPortal.DisruptionsFeedback': 'Disruptions Feedback',
    'Calendar.Filter.SwapRequests': 'Swap requests',
    'MursionPortal.QA': 'QA',
    'Calendar.SearchFilter.FilterProjects': 'Filter Projects',
    'Calendar.SearchFilter.FilterClients': 'Filter Clients',
    'Calendar.SearchFilter.FilterSimSpecialists': 'Filter Sim Specialists',
    'MursionPortal.Search': 'Search',
    'Calendar.IndustrySelector.Options.SelectAnIndustry': 'Select an industry',
    'Calendar.IndustrySelector.Options.AllIndustries': 'All industries',
    'Calendar.IndustrySelector.Options.NoIndustriesToSelect': 'No industries to select',
    'MursionPortal.SelectAClient': 'Select a certificate',
    'Calendar.ClientSelector.Options.AllClients': 'All clients',
    'Calendar.ClientSelector.Options.NoClientToSelect': 'No client to select',
    'MursionPortal.SelectACertificate': 'Select a certificate',
    'Calendar.CertificateSelector.Options.AllCertificates': 'All certificates',
    'Calendar.CertificateSelector.Options.NoCertificatesToSelect': 'No certificates to select',
    'Calendar.ClientsList.NoClientsFound': 'No clients have been found.',
    'MursionPortal.UncheckAll': 'Uncheck All',
    'MursionPortal.CheckAll': 'Check All',
    'Calendar.SimSpecialistList.NoSimSpecialistsFound': 'No simspecialists have been found.',
    'MursionPortal.Projects': 'PROJECTS',
    'Calendar.ProjectsList.CloseProjectOverview': 'Close Project Overview',
    'Calendar.ProjectsList.AllProjects': 'All PROJECTS',
    'MursionPortal.Bracketed.Scenarios': 'Scenario(s)',
    'Calendar.ProjectsList.Overview': 'Overview',
    'Calendar.ProjectsList.CloseClientOverview': 'close client overview',
    'Calendar.ProjectsList.ShowOnlyActiveProjects': 'Show only Active Projects', // TODO: add translations
    'MursionPortal.Learners': 'Learners',
    'MursionPortal.Scenario': 'scenario',
    'Calendar.ScenariosList.NoScenarios': 'No scenarios',
    'MursionPortal.Delivery': 'Delivery',
    'MursionPortal.Mode': 'Mode',
    'MursionPortal.SessionLength': 'Session Length',
    'MursionPortal.Timeline': 'Timeline',
    'Calendar.ScenariosList.Description': 'Description',
    'MursionPortal.Outcome': 'Outcome',
    'MursionPortal.Strategies': 'Strategies',
    'MursionPortal.ContentBundle': 'Content Bundle',
    'MursionPortal.SimulationEnvironments': 'Simulation Environment(s)',
    'MursionPortal.AvatarsInSimulations': 'Avatar(s) in Simulation(s)',
    'Calendar.LearnersList.SessionNotScheduled': 'Session Not Scheduled',
    'Calendar.LearnersList.SessionScheduled': 'Session Scheduled',
    'Calendar.LearnersList.SessionComplete': 'Session Complete',
    'Calendar.LearnersList.Learner': '{learnersCount} learner',
    'Calendar.LearnersList.Learners': '{learnersCount} learners',
    'Calendar.Button.CantSchedule': 'Can\'t schedule',
    'Calendar.Button.ScheduleEvent': 'Schedule Event',
    'Calendar.MainPanel.ConfirmText.AreYouSure': 'Are you sure you want to cancel this event?',
    'Calendar.MainPanel.CancelEvent': 'Cancel Event',
    'Calendar.MainPanel.UpdateEvent': 'Update Event',
    'MursionPortal.Submit': 'Submit',
    'Calendar.MainPanel.NewEvent': 'New Event',
    'MursionPortal.Project': 'Project',
    'MursionPortal.Scenarios': 'Scenarios',
    'MursionPortal.Button.Cancel': 'Cancel',
    'Settings.Config.Heading.Industries': 'INDUSTRIES',
    'Settings.Config.Button.New': 'New',
    'Settings.Config.Note': 'NOTE:',
    'Settings.Config.Note.Text.ConfigSettingsGlobal': 'Config settings are global. Changes will apply across all clients and users.',
    'Settings.Config.Heading.SimSpecialist': 'SIMSPECIALIST TIERS',
    'Settings.Config.Heading.Scheduling': 'SCHEDULING',
    'Settings.Config.Heading.Scheduling.Description': '(Sim-Specialist)',
    'Settings.Config.Scheduling.MaximumFullTimeSimSpecialistHours': 'Maximum full-time Sim-Specialist hours',
    'Settings.Config.Scheduling.MaximumPartTimeSimSpecialistHours': 'Maximum part-time Sim-Specialist hours',
    'Settings.Config.Scheduling.MaximumContinuousSimSpecialistHours': 'Maximum continuous Sim-Specialist hours',
    'Settings.Config.Scheduling.LengthOfSimSpecialistRequiredBreak': 'Length of Sim Specialist\'s required break',
    'Settings.Config.Scheduling.FutureAvailabilityForSimSpecialist': 'Future availability window for Sim Specialists',
    'Settings.Config.Scheduling.SimSpecialistShiftTime': 'Sim Specialist Shift Time',
    'Settings.Config.Scheduling.AvailableTimeBlockMaximumPerDay': 'Availability time block maximum per day',
    'Settings.Config.Scheduling.AvailableTimeBlockMinimumPerDay': 'Availability time block minimum per day',
    'Settings.Config.Scheduling.AvailabilityTimeStep': 'Availability time step',
    'Settings.Config.Scheduling.AdvanceSSScheduleConfirmation': 'How far in advance should SS schedule be confirmed',
    'Settings.Config.Scheduling.RequestUnfulfilledReminder': 'When to send out "request is unfulfilled" reminder',
    'Settings.Config.Scheduling.FirstEmailReminder': 'When to send out first e-mail reminder',
    'Settings.Config.Scheduling.SecondEmailReminder': 'When to send out second e-mail reminder',
    'Settings.Config.Scheduling.BookedSessionWithNoAssignedLearner': 'When to notify of booked Session with no assigned Learner',
    'Settings.Config.Scheduling.UnfulfilledSessionSwapRequestReminder': 'When to send out reminder about unfulfilled Session swap request',
    'Settings.Config.Scheduling.NextSessionReminder': 'When to send out next Session reminder depending on time between Session creation and scheduled Session start time',
    'Settings.Config.Scheduling.ReservedAvailabilityExpired': 'When reserved availability expired',
    'Settings.Config.Scheduling.StartTimeForConsideringMissedSession': 'Amount of time after start-time when a session is considered \"Missed\"',
    'Settings.Config.Scheduling.EndTimeForConsideringMissedSession': 'Amount of time after end-time when a session is considered \"Missed\"',
    'Settings.Config.Scheduling.RejoinSessionAfterError': 'The time session participants have to rejoin the session after error',
    'Settings.Config.Scheduling.TimeDisabledForSessionScheduling': 'Amount of time disabled for session scheduling',
    'Settings.Config.Scheduling.SessionTimeStep': 'Session time step',
    'Settings.Config.Heading.ProjectAndScenario': 'PROJECT AND SCENARIO',
    'Settings.Config.ProjectAndScenario.MaximumProjectTimelineDuration': 'Maximum project timeline duration',
    'Settings.Config.ProjectAndScenario.MinimumProjectTimelineDuration': 'Minimum project timeline duration',
    'Settings.Config.ProjectAndScenario.MaximumScenarioTimelineDuration': 'Maximum scenario timeline duration',
    'Settings.Config.ProjectAndScenario.MinimumScenarioTimelineDuration': 'Minimum scenario timeline duration',
    'Settings.Config.ProjectAndScenario.MinimumSessionLength': 'Minimum session length',
    'Settings.Config.ProjectAndScenario.MaximumSessionLength': 'Maximum session length',
    'Settings.Config.ProjectAndScenario.MinimumSimulationLength': 'Minimum simulation length',
    'Settings.Config.ProjectAndScenario.SessionLengthStep': 'Session length step',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInRemoteSession': 'Minimum number of Learners in Remote sessions',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInRemoteSession': 'Maximum number of Learners in Remote sessions',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInFishbowlSession': 'Minimum number of Learners in Local Fishbowl sessions',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInFishbowlSession': 'Maximum number of Learners in Local Fishbowl sessions',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInGroupSession': 'Minimum number of Learners in Group sessions',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInGroupSession': 'Maximum number of Learners in Group sessions',
    'Settings.Config.ProjectAndScenario.AllowedScenarioVersions': 'Allowed scenario versions',
    'Settings.Config.ProjectAndScenario.DefaultScenarioVersion': 'Default scenario version',
    'Settings.Config.ProjectAndScenario.AllowMursionMagic': 'Mursion Magic type',
    'Settings.Config.Heading.CompanyEvent': 'COMPANY EVENT',
    'Settings.Config.CompanyEvent.MaximumEventDuration': 'Maximum event duration',
    'Settings.Config.CompanyEvent.MinimumEventDuration': 'Minimum event duration',
    'Settings.Config.CompanyEvent.EventTimeStep': 'Event time step',
    'Settings.Config.Heading.SessionReport': 'SESSION REPORT',
    'Settings.Config.SessionReport.ShowSessionReport': 'Show Session Report',
    'Settings.Config.SessionReport.ShowSmartMetrics': 'Voice Analytics',
    'Settings.Config.Heading.FeedbackProvider': 'FEEDBACK PROVIDER',
    'Settings.Config.FeedbackProvider.ZenDeskSnippetKey': 'ZenDesk snippet key',
    'Settings.Config.Heading.SimulationContent': 'SIMULATION CONTENT',
    'Settings.Config.SimulationContent.SimulationContentFolder': 'Simulation Content S3 folder',
    'Settings.Config.Heading.Invitation': 'INVITATION',
    'Settings.Config.Invitation.ExpireUserRegistrationInvitation': 'When to expire user registration invitation',
    'Settings.Config.Invitation.ReminderToAcceptRegistrationInvitation': 'When to remind un-registered users to accept registration invitation',
    'Settings.Config.Invitation.RegistrationInvitationExpiresAfter': 'Registration invitation expires after',
    'Settings.Config.IndustriesButton.Modal.EditIndustry': 'Edit Industry',
    'Settings.Config.NewButton.Modal.NewIndustry': 'New Industry',
    'Settings.Modal.Placeholder.IndustryName': 'Industry Name',
    'Settings.Config.IndustriesButton.Modal.Button.UpdateIndustry': 'Update Industry',
    'Settings.Config.NewButton.Modal.Button.CreateIndustry': 'Create Industry',
    'Settings.Config.IndustriesButton.Modal.Button.RestoreIndustry': 'Restore Industry',
    'Settings.Config.IndustriesButton.Modal.Button.ArchiveIndustry': 'Archive Industry',
    'MursionPortal.Modal.Heading.Name': 'Name',
    'Settings.Environment.Modal.PlaceHolder.EnvironmentName': 'Environment Name',
    'Settings.Environment.Modal.Description': 'Description',
    'Settings.Environment.Modal.Industry': 'Industry',
    'Settings.Environment.Modal.Picture': 'Picture',
    'MursionPortal.Button.Delete': 'Delete',
    'Settings.Environment.Modal.Button.UploadFile': 'Upload file',
    'Settings.Environment.Button.CreateEnvironment': 'Create Environment ',
    'Settings.Environment.ModalHeader.NewEnvironment': 'New Environment',
    'Settings.Environment.ModalHeader.Environment': 'Environment',
    'Settings.Environment.ModalHeader.Archived': '(Archived)',
    'Settings.Environment.ModalHeader.EditEnvironment': 'Edit Environment',
    'Settings.Environment.Modal.Button.CreateEnvironment': 'Create Environment',
    'Settings.Environment.Modal.Button.UpdateEnvironment': 'Update Environment',
    'Settings.Environment.Modal.ArchiveEnvironmentText': 'Are you sure you want to archive this environment?',
    'Settings.Environment.Modal.RestoreEnvironmentText': 'Are you sure you want to restore this environment?',
    'MursionPortal.Button.Archive': 'Archive',
    'MursionPortal.Button.Restore': 'Restore',
    'MursionPortal.Total': 'TOTAL',
    'Dashboard.SessionLearners.MainHeader': 'Session Learners',
    'Dashboard.SessionLearners.TableHeader': 'Session Learners Data',
    'MursionPortal.Text.Total': 'TOTAL',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportData': 'Export data',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportNotAvailable': 'Export is not available',
    'MursionPortal.Button.ExportToEmail': 'Export to email',
    'MursionPortal.WarningMessage.RequestNotImplemented': 'Data request is not implemented yet',
    'MursionPortal.FilterHeading.Filter': 'FILTER', // TODO: add translates
    'MursionPortal.FilterHeading.Graph': 'SHOW FLOW GRAPH', // TODO: add translates
    'MursionPortal.Table.ColumnHeader.Client': 'Client',
    'MursionPortal.Table.ColumnHeader.Provider': 'Provider',
    'MursionPortal.Table.ColumnHeader.Project': 'Project',
    'MursionPortal.Table.ColumnHeader.Scenario': 'Scenario',
    'MursionPortal.Table.ColumnHeader.SoftwareVersion': 'Software Version',
    'MursionPortal.Table.ColumnHeader.DeliveryMode': 'Delivery Mode',
    'MursionPortal.Table.ColumnHeader.SessionStart': 'Session Start',
    'MursionPortal.Table.ColumnHeader.SessionEnd': 'Session End',
    'MursionPortal.Table.ColumnHeader.LearnerStatus': 'Learner Status',
    'MursionPortal.Table.ColumnHeader.SessionStatus': 'Session Status',
    'MursionPortal.Table.ColumnHeader.LearnerName': 'Learner Name',
    'MursionPortal.Table.ColumnHeader.LearnerEmail': 'Learner Email',
    'MursionPortal.Table.LearnerEmail.HoverTitle.CopyEmail': 'Copy Email',
    'MursionPortal.Table.LearnerEmail.HoverTitle.UserEmail': 'User Email',
    'MursionPortal.Table.ColumnHeader.SessionId': 'Session Id',
    'MursionPortal.Table.SessionId.HoverTitle.CopySessionId': 'Copy Session Id',
    'MursionPortal.Table.ColumnHeader.SessionReport': 'Session Report',
    'MursionPortal.Table.ColumnHeader.SimSpecialistName': 'Sim Specialist Name',
    'MursionPortal.Table.ColumnHeader.LearnerTimeSpent': 'Learner Time Spent',
    'MursionPortal.Table.HoverTitle.SessionSummary': 'Session summary',
    'MursionPortal.Table.ScrollMessage': 'scroll to view more',
    'MursionPortal.Table.NoRecordsMessage': 'No results found!',
    'MursionPortal.EmptyMessage.NoSessionsYet': 'There are no sessions yet',
    'MursionPortal.Button.ViewReport': 'View Report',
    'MursionPortal.DateTimePicker.Timezone': 'Timezone',
    'MursionPortal.DateTimePicker.StartTime': 'Start time',
    'MursionPortal.DateTimePicker.EndTime': 'End time',
    'MursionPortal.DateTimePicker.StartDate': 'Start date',
    'MursionPortal.DateTimePicker.EndDate': 'End date',
    'MursionPortal.DateTimePicker.TimeNotAvailable': 'Selected time is not available.',
    'Mursion.Portal.Status.Requested': 'Requested',
    'Mursion.Portal.Status.Pending': 'Pending',
    'Mursion.Portal.Status.PendingSwap': 'Pending (Swap)',
    'Mursion.Portal.Status.PreBooked': 'Pre-booked',
    'Mursion.Portal.Status.Booked': 'Booked',
    'Mursion.Portal.Status.Active': 'Active',
    'Mursion.Portal.Status.Complete': 'Complete',
    'Mursion.Portal.Status.Error': 'Error',
    'Mursion.Portal.Status.Missed': 'Missed',
    'Mursion.Portal.Status.LearnerMissed': 'Learner Missed', // TODO add translations
    'Mursion.Portal.Status.NeedsReview': 'Needs Review',
    'Mursion.Portal.Status.Cancelled': 'Cancelled',
    'Mursion.Portal.Status.SystemCancelled':'Cancelled', // TODO add translations
    'Mursion.Portal.Status.LateCancelled': 'Late Canceled', // TODO add translations
    'Mursion.Portal.Status.EarlierCancelled': 'Early Canceled', // TODO add translations
    'Mursion.Portal.Status.Connected': 'CONNECTED',
    'Mursion.Portal.Status.Late': 'LATE',
    'Mursion.Portal.Status.Immersing': 'IMMERSING',
    'Mursion.Portal.Status.Left': 'LEFT',
    'Mursion.Portal.Status.Declined': 'DECLINED',
    'Dashboard.UsersActivity.Filter.Roles.Placeholder': 'Search...',
    'Settings.Config.RestoreIndustry.Modal.ConfirmRestore': 'Are you sure you want to restore this industry?',
    'Settings.Config.ArchiveIndustry.Modal.ConfirmArchive': 'Are you sure you want to archive this industry?',
    'Settings.Config.Description.PerWeek': '(per week)',
    'Settings.Config.Description.PerDay': '(per day)',
    'Settings.Config.Description.BeforeBreak': '(before break)',
    'Settings.Config.Description.Minutes': '(minutes)',
    'Settings.Config.Description.Days': '(days)',
    'Settings.Config.Description.Hours': '(hours)',
    'Settings.Config.TierButton.Modal.Button.UpdateTier': 'Update Tier',
    'Settings.Config.TierButton.Modal.Heading.EditTier': 'Edit Tier',
    'Settings.Config.TierButton.Modal.Placeholder.TierName': 'Tier Name',
    'Settings.Config.ArchiveIndustry.Modal.Heading.ConfirmArchive': 'Confirm Archive',
    'Settings.Config': 'Config',
    'Settings.ScenarioBank': 'Scenario Bank',
    'Settings.Environments': 'Environments (ML2)',
    'Settings.Avatars': 'Avatars (ML2)',
    'Settings.SSO': 'SSO',
    'Settings.SSO.CopySsoDeepLink': 'Copy SSO Deep Link',
    'Settings.SSO.CopySsoDeepLinkNotification': 'Your SSO link:',
    'Settings.SSO.CopyDeepLinkNotification': 'Your link:', // TODO: add translations
    'Settings.SSO.CopyScenarioLink': 'Copy Scenario Invite Link',
    'Settings.Error.NoSettingsAvailable': 'No settings available',
    'MursionPortal.Error.SomethingWentWrong': 'Sorry, something went wrong.',
    'MursionPortal.Error.ReloadingPage': 'Try reloading the page. The error has been reported.',
    'MursionPortal.Error.WorkingHard': 'We\'re working hard to fix it as soon as possible.',
    'MursionPortal.Error.SupportAvailable': 'Support is available Monday through Friday',
    'MursionPortal.Error.TechnicalAssistance': 'If you need technical assistance, please contact Mursion Support',
    'MursionPortal.At': 'at',
    'MursionPortal.Or': 'or',
    'MursionPortal.Button.Reload': 'Reload',
    'Settings.ScenarioBank.Table.ColumnHeader.Name': 'Name',
    'MursionPortal.DeliveryMode': 'Delivery Mode',
    'Settings.ScenarioBank.Table.ColumnHeader.SessionSize': 'Session Size',
    'Settings.ScenarioBank.Table.ViewEditScenario': 'View/Edit Scenario',
    'Settings.ScenarioBank.Table.ThereAreNoScenarios': 'There are no scenarios yet',
    'MursionPortal.Capitalized.Total': 'TOTAL',
    'Settings.ScenarioBank.Form.NewScenario': 'New Scenario',
    'Settings.ScenarioBank.Form.Scenario': 'Scenario',
    'Settings.ScenarioBank.Form.EditScenario': 'Edit Scenario',
    'Settings.ScenarioBank.Form.Save': 'Save',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenario': 'Are you sure you want to archive this scenario?',
    'Settings.ScenarioBank.Form.ConfirmRestoreScenario': 'Are you sure you want to restore this scenario?',
    'Settings.ScenarioBank.Button.CreateScenario': 'Create Scenario ',
    'MursionPortal.ShortLabel.Minutes': 'min',
    'Settings.ScenarioBank.Modal.Scenarios': 'Scenario(s)',
    'Settings.ScenarioBank.Modal.CompleteProjectInfoFirst': 'Complete Project info First',
    'Settings.ScenarioBank.Modal.ScenarioName': 'Scenario Name',
    'Settings.ScenarioBank.Modal.Placeholder.EnterScenarioName': 'Enter Scenario Name',
    'Settings.ScenarioBank.Modal.Outcome': 'Outcome',
    'Settings.ScenarioBank.Modal.Strategies': 'Strategies',
    'Settings.ScenarioBank.Modal.Attachments': 'Attachments',
    'Settings.ScenarioBank.Modal.SoftwareVersion': 'Software version',
    'Settings.ScenarioBank.Modal.WeAlreadyHaveSessions': 'We already have sessions for this scenario',
    'Settings.ScenarioBank.Modal.SelectDeliveryMode': 'Select a delivery mode',
    'Settings.ScenarioBank.Modal.ShouldBeAtLeast': 'Should be at least {minutes} minutes',
    'Settings.ScenarioBank.Modal.SelectSessionLength': 'Select a session length',
    'Settings.ScenarioBank.Modal.SessionSize': 'Session size',
    'Settings.ScenarioBank.Modal.Learner': 'Learner',
    'Settings.ScenarioBank.Modal.Learners': 'Learners',
    'Settings.ScenarioBank.Modal.SelectSessionSize': 'Select a session size',
    'MursionPortal.SimulationContent': 'Simulation Content',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContent': 'This is the 3D art content of environments and avatars that will be used in the simulation. Contents of this folder are displayed in the section titled "content bundle" below.',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoSimulation': 'The scenario has no assigned simulation content',
    'Settings.ScenarioBank.Modal.SelectSimulationContentForUse': 'Select Simulation Content for Use',
    'Settings.ScenarioBank.Modal.Label.ScenarioOutcome': 'Scenario Outcome',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnOutcome': 'Enter an outcome',
    'Settings.ScenarioBank.Modal.Description': 'Description',
    'Settings.ScenarioBank.Modal.NoDescriptionSpecified': 'No description specified',
    'Settings.ScenarioBank.Modal.ScenarioDescription': 'Scenario description',
    'Settings.ScenarioBank.Modal.AllowedCharactersLimit': 'Allowed character limit ({maxLength} characters) has been exceeded',
    'Settings.ScenarioBank.Modal.ScenarioStrategies': 'Scenario Strategies',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnItem': 'Enter an item',
    'Settings.ScenarioBank.Modal.Attachments.DragAndDrop': 'Drag \'n\' drop some files here, or click to select files',
    'Settings.ScenarioBank.Modal.Attachments.UploadedMaximumFiles': 'You have uploaded the maximum available number of files {maxFiles}.',
    'Settings.ScenarioBank.Modal.Attachments.UploadedFiles': 'Uploaded files: ',
    'Settings.ScenarioBank.Modal.CertifiedSimSpecialists': 'Certified Sim Specialists',
    'Settings.ScenarioBank.Modal.NoCertifiedSimSpecialist': 'No Certified Sim Specialist',
    'Settings.ScenarioBank.Modal.Button.Add': 'Add ',
    'Settings.ScenarioBank.Modal.Edit': 'edit',
    'Settings.ScenarioBank.Modal.AddAttachmentsToScenario': 'Add attachments to scenario',
    'MursionPortal.Cancel': 'Cancel',
    'MursionPortal.OK': 'OK',
    'Settings.ScenarioBank.Modal.FileFormatsSupported': 'The following file formats are supported:',
    'Settings.ScenarioBank.Modal.Images': 'Images',
    'Settings.ScenarioBank.Modal.Videos': 'Videos',
    'Settings.ScenarioBank.Modal.Audios': 'Audios',
    'Settings.ScenarioBank.Modal.Documents': 'Documents',
    'Settings.ScenarioBank.Modal.New': '(new)',
    'Settings.ScenarioBank.Modal.FileSizeMustNotExceed': 'File size must not exceed {size}',
    'Settings.ScenarioBank.Modal.CertifySimSpecialists': 'Certify Sim Specialists',
    'MursionPortal.Confirm': 'Confirm',
    'MursionPortal.Delete': 'Delete',
    'Settings.ScenarioBank.Modal.Archived': '(Archived)',
    'Settings.ScenarioBank.Modal.Restore': 'Restore',
    'Settings.ScenarioBank.Modal.ConfirmArchive': 'Confirm Archive',
    'Settings.ScenarioBank.Modal.Archive': 'Archive',
    'Settings.ScenarioBank.Modal.ConfirmRestore': 'Confirm Restore',
    'MursionPortal.PleaseWait': 'Please wait...',
    'MursionPortal.Loading': 'Loading...',
    'Settings.ScenarioBank.Modal.Attachments.FileTypeNotSupported': 'File type {extensions} is not supported',
    'Settings.ScenarioBank.Modal.Attachments.FileTypesNotSupported': 'File types {extensions} are not supported',
    'Settings.ScenarioBank.Modal.Attachments.FileExceedsSizeLimit': 'The file {fileNames} exceeds the size limit',
    'Settings.ScenarioBank.Modal.Attachments.FilesExceedSizeLimit': 'The files {fileNames} exceed the size limit',
    'Dashboard.ProjectsActivity.MainHeader': 'Project Activity',
    'MursionPortal.Label.Total': 'TOTAL',
    'MursionPortal.Button.HoverTitle.ExportData': 'Export data',
    'MursionPortal.Button.HoverTitle.ExportNotAvailable': 'Export is not available',
    'MursionPortal.Heading.Filters': 'FILTERS:',
    'Dashboard.ProjectsTable.Checkbox.OnlyBillable': ' Only Billable',
    'MursionPortal.Filters.SoftwareVersion': 'Software Version',
    'MursionPortal.Filters.DeliveryMode': 'Delivery Mode',
    'MursionPortal.Filters.PlaceHolder.DeliveryMode': 'Search Delivery Mode...',
    'MursionPortal.Filters.PlaceHolder.SearchVersion': 'Search Version...',
    'MursionPortal.Filters.Status': 'Status',
    'MursionPortal.Filters.PlaceHolder.SearchStatus': 'Search Status...',
    'Dashboard.ProjectsTable.ColumnHeader.Provider': 'Provider',
    'MursionPortal.Table.ColumnHeader.ProjectId': 'Contract ID',// TODO: add translation',
    'Dashboard.ProjectsTable.ColumnHeader.Status': 'Status',
    'Dashboard.ProjectsTable.ColumnHeader.StarAt': 'Start At',
    'Dashboard.ProjectsTable.ColumnHeader.EndAt': 'End At',
    'Dashboard.ProjectsTable.ColumnHeader.Billable': 'Billable',
    'Dashboard.ProjectsTable.ColumnHeader.CancellationWindow': 'Cancellation Window',
    'MursionPortal.Table.ColumnHeader.Scenarios': 'Scenarios',
    'MursionPortal.Table.ColumnHeader.Teams': 'Teams',
    'MursionPortal.Table.ColumnHeader.SessionsScheduled': 'Sessions Scheduled',
    'MursionPortal.Table.ColumnHeader.SessionsCompleted': 'Sessions Completed',
    'MursionPortal.Table.ColumnHeader.SessionsMissed': 'Sessions Missed',
    'MursionPortal.Table.ColumnHeader.LateCancellations': 'Late Cancellations',
    'MursionPortal.Table.ColumnHeader.Learners': 'Learners',
    'Dashboard.ProjectsTable.ColumnHeader.SessionsRecorded': 'Sessions Recorded',
    'MursionPortal.Table.ColumnHeader.SessionsError': 'Sessions Error',
    'Dashboard.ProjectsTable.ColumnHeader.ContractedHours': 'Contracted Hours',
    'Dashboard.ProjectsTable.ColumnHeader.UtilizedHours': 'Utilized Hours',
    'Dashboard.ProjectsTable.ColumnHeader.RemainingHours': 'Remaining Hours',
    'Dashboard.ProjectsTable.ColumnHeader.CompletedHours': 'Completed Hours',
    'Dashboard.ProjectsTable.ColumnHeader.MissedHours': 'Missed Hours',
    'Dashboard.ProjectsTable.HoverTitle.ViewEditProject': 'View/Edit Project',
    'Dashboard.ProjectsTable.EmptyMessage.NoProjectsYet': 'There are no projects yet',
    'MursionPortal.Button.Clear': 'Clear',
    'MursionPortal.Button.ClearAll': 'Clear All',
    'Dashboard.Projects.AlertMessage.DataRequestNotImplemented': 'Data request is not implemented yet',
    'MursionPortal.SelectorMenu.SelectedCountOfTotal': 'Selected {formattedSelectedCount} of {formattedTotal}',
    'MursionPortal.Dashboard.Chart.Registration': 'User Registration',
    'MursionPortal.Dashboard.Chart.InviteAll': 'Invite All',
    'MursionPortal.Dashboard.Chart.Unregistered': 'Unregistered',
    'MursionPortal.Dashboard.Chart.Registered': 'Registered',
    'MursionPortal.Dashboard.Chart.Login': 'Login',
    'MursionPortal.Dashboard.Chart.NotLoggedIn': 'Not Logged In',
    'MursionPortal.Dashboard.Chart.LoggedIn': 'Logged In',
    'MursionPortal.Dashboard.Chart.Scheduling': 'Users Scheduled',
    'MursionPortal.Dashboard.Chart.NotScheduled': 'Not Scheduled',
    'MursionPortal.Dashboard.Chart.Simulations': 'Simulations',
    'MursionPortal.Dashboard.Chart.LateCancellations': 'Late Cancellations',
    'MursionPortal.Dashboard.Chart.Completed': 'Completed',
    'MursionPortal.Dashboard.Chart.Assigned': 'Assigned',
    'MursionPortal.Dashboard.Chart.Errors': 'Errors',
    'MursionPortal.Dashboard.Chart.Scheduled': 'Scheduled',
    'MursionPortal.Dashboard.Chart.Tooltip.Registered': 'Users who have been invited or SSO users provisioned and logged in.',
    'MursionPortal.Dashboard.Chart.Tooltip.Unregistered': 'Users who have been invited but not yet logged in or SSO users provisioned and have not yet logged in.',
    'MursionPortal.Dashboard.Chart.Tooltip.NotScheduled': 'Registered/provisioned SSO users who have not yet scheduled a simulation',
    'MursionPortal.Dashboard.Chart.Tooltip.Scheduled': 'Registered/provisioned SSO users who have scheduled at least one simulation',
    'MursionPortal.Dashboard.Chart.Tooltip.Utilized': 'Simulations completed as a percentage of simulations purchased.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Scheduled': 'Upcoming sessions that are scheduled that have not yet been completed (includes those running)', // TODO: add translation'
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Completed': 'Sessions that have been successfully completed.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LearnerMissed': 'Sessions that have been missed by the learner.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateReschedule': 'Sessions that have been late reschedule by the learner.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Error': 'Sessions that ended in error on the learner side of connection.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateCancellation': 'Sessions that were canceled late (after the cancellation deadline) by the account owner, facilitator, or learner.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.EarlyCancellation': 'Sessions that were cancelled early (before the cancellation deadline) by the learner, facilitator, or account owner.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Simulation': 'Sessions broken down by each status.',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Completed': 'Completed',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Error': 'Error',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateReschedule': 'Late Rescheduled',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateCancellation': 'Late Canceled',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EarlyCancellation': 'Early Canceled',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EstimatedRemaining': 'Estimated Remaining',
    'MursionPortal.Dashboard.Chart.PrivatePractice1': '30m Private Practice',
    'MursionPortal.Dashboard.Chart.PrivatePractice2': '60m Private Practice',
    'MursionPortal.Dashboard.Chart.Workshop1': '60m Workshop',
    'MursionPortal.Dashboard.Chart.Workshop2': '90m Workshop',
    'MursionPortal.Dashboard.Chart.StringType.Utilized': 'Utilized',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown': 'Session Breakdown',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdownQuickView': 'Session Breakdown – Quick View', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.NoDataAvailable': 'No Data Available',
    'MursionPortal.Dashboard.Chart.Header.LearnerStatus': 'Learner Status (All Contracts)',
    'MursionPortal.Dashboard.Chart.Header.LearnerSimulations': 'Number of Scenarios Assigned',
    'MursionPortal.Dashboard.Table.Modal.Filter.Teams': 'Teams',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterTeamName': 'Enter Team Name',
    'MursionPortal.Dashboard.Table.Modal.TeamsEmptyMessage': 'No teams have been found',
    'MursionPortal.Dashboard.Table.Modal.Filter.NumberOfLearners': '# of Learners',
    'MursionPortal.Dashboard.Table.Modal.Filter.Scenarios': 'Scenarios',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterScenarioName': 'Enter Scenario Name',
    'MursionPortal.Dashboard.Table.Modal.ScenariosEmptyMessage': 'No scenarios have been found',
    'MursionPortal.Dashboard.Table.Modal.Filter.Name': 'Name',
    'MursionPortal.Dashboard.Table.Modal.Filter.Status': 'Status',
    'MursionPortal.Dashboard.Table.Modal.Filter.DeliveryMode': 'Delivery Mode',
    'MursionPortal.Dashboard.Table.Modal.Filter.Learners': 'Learners',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterLearnerName': 'Enter Learner Name',
    'MursionPortal.Dashboard.Table.Modal.LearnersEmptyMessage': 'No learners have been found',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsScheduled': 'Sessions Scheduled',
    'MursionPortal.Dashboard.Table.Modal.SessionsEmptyMessage': 'No sessions have been found',
    'MursionPortal.Dashboard.Table.Modal.Filter.ScenarioName': 'Scenario Name',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionDateTime': 'Session date/time',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsCompleted': 'Sessions Completed',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsError': 'Sessions Error',
    'Dashboard.ProjectsActivityTable.Modal.Filter.SessionsRecorded': 'Sessions Recorded',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsMissed': 'Sessions Missed',
    'MursionPortal.Dashboard.Table.Modal.Filter.LateCancellations': '"Late" Cancellations',
    'MursionPortal.Dashboard.Table.Modal.Filter.CancelledBy': 'Cancelled By',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateScheduled': 'Date Scheduled',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateCancelled': 'Date Cancelled',
    'MursionPortal.Dashboard.Table.Empty.Body.Message': 'details will load once you make a selection',
    'MursionPortal.ProjectStatus.Active': 'Active',
    'MursionPortal.ProjectStatus.Completed': 'Completed',
    'MursionPortal.ProjectStatus.Archived': 'Archived',
    'Settings.Avatars.Modal.Name': 'Name',
    'Settings.Avatars.Modal.PlaceHolder.AvatarName': 'Avatar Name',
    'Settings.Avatars.Modal.Description': 'Description',
    'MursionPortal.Name': 'Name',
    'MursionPortal.Description': 'Description',
    'MursionPortal.Environments': 'Environments',
    'Settings.Avatars.TableHover.ViewEditAvatar': 'View/Edit Avatar',
    'Settings.Avatars.NoAvatarText': 'There are no avatars yet',
    'Settings.Avatars.ModalHeader.NewAvatar': 'New Avatar',
    'Settings.Avatars.ModalHeader.Avatar': 'Avatar',
    'Settings.Avatars.ModalHeader.EditAvatar': 'Edit Avatar',
    'Settings.Avatars.Modal.Button.CreateAvatar': 'Create Avatar',
    'Settings.Avatars.Modal.Button.UpdateAvatar': 'Update Avatar',
    'Settings.Avatars.Modal.ArchiveAvatarText': 'Are you sure you want to archive this avatar?',
    'Settings.Avatars.Modal.RestoreAvatarText': 'Are you sure you want to restore this avatar?',
    'Settings.Avatars.Modal.Button.Edit': 'edit',
    'Settings.Avatars.Modal.EditButton.Accept': 'Accept',
    'Settings.Avatars.Modal.NoSelectedEnvironments': 'No selected environments',
    'Settings.Avatars.Modal.NoEnvironmentsSetForSelectedIndustry': 'No environments set for selected industry.',
    'Settings.Avatars.Button.CreateAvatar': 'Create Avatar ',
    'MursionPortal.Dashboard': 'Dashboard',
    'MursionPortal.Dashboard.UpcomingSessions': 'Upcoming Sessions',
    'MursionPortal.Tutorial': 'Tutorial',
    'MursionPortal.Eula': 'Eula',
    'MursionPortal.Companies': 'Companies',
    'MursionPortal.SessionFlow': 'Session Flow',
    'MursionPortal.SessionReport': 'Session Report',
    'MursionPortal.Monitoring': 'Monitoring',
    'MursionPortal.Monitoring.User.FirstLetter': 'U',
    'MursionPortal.Monitoring.Peer.Label': 'PEER - PEER',
    'MursionPortal.Monitoring.Peer.NoUser': 'Please select user to see details',
    'MursionPortal.Monitoring.Sessions.Total': 'Total sessions',
    'MursionPortal.Monitoring.Sessions.Displayed': 'ML3 Sessions (displayed)',
    'MursionPortal.Monitoring.Sessions.NotDisplayed': 'Not ML3 Sessions',
    'MursionPortal.Monitoring.Card.StartTime': 'Start time',
    'MursionPortal.Monitoring.Notification.NewSession': 'New session has been added.',
    'MursionPortal.ClickRefresh': 'Click "Refresh" to view the changes.',
    'MursionPortal.AssetSync': 'Asset Sync',
    'MursionPortal.Users': 'Users',
    'MursionPortal.TechRestrictions': 'Tech Restrictions',
    'MursionPortal.AppDownload': 'App Download',
    'MursionPortal.Calendar': 'Calendar',
    'MursionPortal.Clients': 'Clients',
    'MursionPortal.Trainings': 'Trainings',
    'MursionPortal.Requests': 'Requests',
    'MursionPortal.Settings': 'Settings',
    'MursionPortal.ArtDesigner': 'Art Designer',
    'MursionPortal.ArtDesigner.BodyContents': 'We\'ve opened a new browser tab with the Mursion Designer application.',
    'MursionPortal.Teams': 'Teams',
    'MursionPortal.Reports': 'Reports',
    'MursionPortal.Downloads': 'Downloads',
    'MursionPortal.Upcoming': 'upcoming',
    'MursionPortal.Auth': 'Oauth',
    'Settings.Avatars.Modal.ErrorMessage.ItemsNotAvailable': 'Not more than {max} items are available to pick',
    'Settings.Avatars.Modal.ErrorMessage.ItemNotAvailable': 'Not more than {max} item is available to pick',
    'MursionPortal.Industry': 'Industry',
    'Settings.Environment.TableHover.ViewEditEnvironment': 'View/Edit Environment',
    'Settings.Environment.NoEnvironmentText': 'There are no environments yet',
    'MursionPortal.SortBy': 'Sort by',
    'MursionPortal.Placeholder.SortBy': 'sort-by',
    'Clients.Button.CreateClient': ' Create Client ',
    'Clients.CreateClient.Header.NewClient': 'New Client',
    'Clients.CreateClient.Button.CreateClient': 'Create Client',
    'Clients.CreateClient.Label.ClientName': 'Client Name',
    'Clients.CreateClient.Placeholder.CityCountry': 'City, Country',
    'MursionPortal.Timezone': 'Timezone',
    'Clients.CreateClient.Label.Logo': 'Logo',
    'Clients.Text.NoClientsFound': 'No Clients have been found.',
    'MursionPortal.Text.Active': 'Active',
    'MursionPortal.Text.Archived': 'Archived',
    'Clients.Modal.Button.BackToClientList': 'Back to Clients List',
    'Clients.Modal.EmptyMessage.NoClientsFound': 'No clients found',
    'Clients.Modal.Placeholder.EnterClientName': 'Enter Client Name',
    'Clients.Modal.Header.ClientsList': 'CLIENTS LIST',
    'Clients.Modal.Header.Client': 'CLIENT',
    'Clients.Modal.Header.AllClients': 'all clients',
    'MursionPortal.Button.LoadMore': 'Load more',
    'Clients.Modal.RestoreConfirmText': 'Are you sure you want to restore this client?',
    'Clients.Modal.DeleteConfirmText': 'Are you sure you want to archive this client?',
    'Clients.Modal.RelatedDataText': ' All related data will also be archived.',
    'Clients.Modal.DeleteErrorText': 'The current client has scenario(-s). Archive project scenario(-s) before archiving this client.',
    'Clients.Button.RestoreClient': 'Restore Client',
    'Clients.IconButton.Cancel': 'cancel',
    'Clients.IconButton.Edit': 'edit',
    'Clients.IconButton.CancelEdit': 'Cancel Edit',
    'Cleints.IconButton.EditScenarioName': 'Edit Scenario Name',
    'Cleints.IconButton.ApplyNameChange': 'Apply Name Change',
    'Clients.IconButton.Apply': 'apply',
    'Clients.IconButton.EditClient': 'Edit Client',
    'Clients.Button.ArchiveClient': 'Archive Client',
    'Clients.Modal.Heading.Archived': '(Archived)',
    'Clients.Modal.Label.Name': 'Name:',
    'Clients.Modal.Placeholder.ClientName': 'Client Name',
    'Clients.Modal.Label.Location': 'Location:',
    'Clients.Modal.Label.Industry': 'Industry:',
    'Clients.Modal.Placeholder.SelectIndustry': 'Select industry',
    'Clients.Modal.Label.FacilitatorVisibility': 'Facilitator View: ',
    'Clients.Modal.Placeholder.FacilitatorVisibility': 'Select Facilitator View',
    'Clients.Modal.Label.Timezone': 'Timezone:',
    'Clients.Modal.Label.SchedulingByRequest': 'Scheduling by Request:',
    'MursionPortal.Enabled': 'Enabled',
    'MursionPortal.Disabled': 'Disabled',
    'Clients.Modal.Label.SchedulingWindow': 'Scheduling Window:',
    'Clients.Modal.Label.HowFarInAdvance': 'How far in advance should clients schedule',
    'MursionPortal.Days': 'days',
    'Clients.Modal.Label.SmartMetrics': 'Voice Analytics:',
    'Clients.Modal.Label.AvailableSharedSSOTitle': 'The list of available Shared SSO that could be used for sign in',
    'Clients.Modal.Label.AvailableSharedSSOLabel': 'Available Shared SSO:',
    'Clients.Modal.EmptySelectedMessage': 'No SSO selected',
    'Clients.Modal.EmptyListMessage': 'No SSO available',
    'Clients.Modal.SSOTitle': 'Select Available SSO',
    'Cleints.Modal.Label.AvailablePrivateSSOTitle': 'The list of available Private SSO that could be used for sign in',
    'Cleints.Modal.Label.AvailablePrivateSSOLabel': 'Enable Private SSO:',
    'Cleints.Modal.Label.DomainsTitle': 'The list of domains that could be used for sign in',
    'Cleints.Modal.Label.DomainsLabel': 'Client Web Domain:',
    'Clients.Modal.Label.Logo': 'Client Logo:',
    'Clients.Modal.Label.AccountOwners': 'Account Owners:',
    'Clients.Modal.AccountOwner.EmptyListMessage': 'No Account Owners',
    'Clients.Modal.AccountOwner.EmptySelectedMessage': 'No selected account owner',
    'Clients.Modal.AccountOwnersTitle': 'Account Owner(s)',
    'MursionPortal.Message.Updating': 'Updating...',
    'MursionPortal.Button.SaveClientInfo': 'Save client info',
    'MursionPortal.Add': 'Add',
    'Clients.Modal.Label.EditSSO': 'Edit SSO',
    'Clients.Modal.Label.Edit': 'edit',
    'MursionPortal.NumberOfFacilitators': 'Number of facilitators',
    'MursionPortal.NumberOfLearners': 'Number of learners',
    'Clients.Modal.Button.CreateTeam': 'Create Team ',
    'Clients.Portal.Button.EditMemberships': 'Edit Memberships',
    'MursionPortal.NewTeam': 'New Team',
    'MursionPortal.NoTags': 'No tags',
    'MursionPortal.NoDomains': 'No domains',
    'MursionPortal.Header.Tags': 'Tags',
    'MursionPortal.Header.Domains': 'Domains',
    'MursionPortal.AddTag': 'Add tag',
    'MursionPortal.AddDomain': 'Add Domain',
    'Clients.Modal.Teams.FacilitatorsEmptyListMessage': 'No selected facilitators',
    'Clients.Modal.Teams.LearnersEmptyListMessage': 'No selected learners',
    'MursionPortal.Facilitator': 'Facilitator',
    'MursionPortal.CreateTeam': 'Create Team',
    'Clients.Modal.Teams.ConfirmationText.RestoreTeam': 'Are you sure you want to restore this team?',
    'Clients.Modal.Teams.ButtonTitle.RestoreTeam': 'Restore Team',
    'Clients.Modal.Teams.ConfirmationText.ArchiveTeam': 'Are you sure you want to archive this team?',
    'Clients.Modal.Teams.ButtonTitle.ArchiveTeam': 'Archive Team',
    'Clients.Modal.Projects.Header.NewProject': 'New Project',
    'MursionPortal.ProjectName': 'Project Name',
    'MursionPortal.TimelineUTC': 'Project Timeline<code>{word}</code> (UTC)',
    'MursionPortal.Contracted': 'Contracted',
    'MursionPortal.CancellationWindow': 'Cancellation Window',
    'MursionPortal.SoftwareVersion': 'Software version',
    'MursionPortal.Billing': 'Billing',
    'Clients.Modal.Projects.Checkbox.NonBillable': 'Non-billable',
    'MursionPortal.ProjectId': 'Contract ID',// TODO: add translation',
    'MursionPortal.Recording': 'Recording',
    'Clients.Modal.Projects.Checkbox.AllowRecording': 'Allow recording',
    'Clients.Modal.Projects.Label.RecordedVideos': 'Recorded videos go back to:',
    'Clients.Modal.Projects.Checkbox.RecordingPermission': 'Recording permission lies with learner',
    'Clients.Modal.Projects.Header.SessionSmartMetrics': 'Session Voice Analytics:',
    'Clients.Modal.Projects.Checkbox.EnableSmartMetrics': ' Enable Voice Analytics',
    'Clients.Modal.Projects.ButtonTitle.SaveProjectInfo': 'Save project info',
    'Clients.Modal.Projects.Header.ProjectManager': 'Project manager',
    'Clients.Modal.Projects.ProjectsEmptyListMessage': 'No selected project managers',
    'MursionPortal.Header.Bracketed.Scenarios': 'Scenario(s)',
    'Clients.Modal.Projects.Text.CompleteProjectInfoFirst': 'Complete Project info First',
    'MursionPortal.Header.CreateScenario': 'Create Scenario',
    'Clients.Modal.Projects.ConfirmationText.RestoreProject': 'Are you sure you want to restore this project?',
    'Clients.Modal.Projects.ConfirmationText.EditProject': 'Are you sure you want to edit this project?',
    'Clients.Modal.Projects.ButtonTitle.RestoreProject': 'Restore Project',
    'Clients.Modal.Projects.ButtonTitle.Edit': 'Edit Project',
    'Clients.Modal.Projects.ButtonTitle.CancelEdit': 'Cancel Edit',
    'Clients.Modal.Label.Cancel': 'cancel',
    'Clients.Modal.Projects.ConfirmationText.ArchiveProject': 'Are you sure you want to archive this project?',
    'Clients.Modal.Projects.ButtonTitle.ArchiveProject': 'Archive Project',
    'Clients.Modal.Projects.ArchiveErrorMessage': 'The current project has active scenario(-s). Archive project scenario(-s) before archiving this project.',
    'Clients.Modal.Projects.ButtonTitle.EditTags': 'Edit Tags',
    'Clients.Modal.Projects.ButtonTitle.EditDomains': 'Edit Domains',
    'Clients.Modal.Projects.DomainField.EnterDomain': 'Enter a domain name (eg. example.com)',
    'Clients.Modal.Projects.DomainField.InvalidDomain': 'Invalid domain format',
    'MursionPortal.Button.Confirm': ' Confirm',
    'Clients.Modal.Projects.Label.UtilizedMissedSessions': 'Utilized Missed Sessions: ',
    'Clients.Modal.Projects.Button.CreateNew': 'Create New ',
    'Clients.Modal.Projects.Button.RepeatClient': 'Repeat Client',
    'Clients.Modal.Projects.Button.RepeatBank': 'Repeat Bank',
    'Clients.Modal.Projects.ButtonTitle.CreateNewScenario': 'Create new scenario',
    'Clients.Modal.Projects.ButtonTitle.RepeatClientScenario': 'Repeat client scenario',
    'Clients.Modal.Projects.ButtonTitle.ScenarioBank': 'Create scenario from scenario bank',
    'Clients.Modal.Button.Schedule': 'Schedule',
    'MursionPortal.Button.ActivateScenario': 'Activate scenario',
    'MursionPortal.Button.ChangeScenario': 'Change scenario',
    'MursionPortal.Button.Next': 'Next',
    'Clients.IconButton.Confirm': 'confirm',
    'Clients.IconButton.ConfirmEdit': 'Confirm Edit',
    'Clients.Modal.Projects.HeaderText.ChooseScenario': 'Choose scenario to repeat',
    'Clients.Modal.Projects.HeaderText.ChooseScenarioBank': 'Choose scenario from Scenario Bank',
    'MursionPortal.Placeholder.LowerCase.EnterScenarioName': 'Enter scenario name',
    'MursionPortal.IconButton.ResetToDefault': 'Reset to default name',
    'MursionPortal.IconButton.Reset': 'reset',
    'Clients.Modal.Header.CreatePlanning': 'Create planning for the new scenario',
    'Clients.Modal.EmptyMessage.NoClientScenariosFound': 'No client scenarios have been found.',
    'Clients.Modal.EmptyMessage.NoBankScenariosFound': 'No bank scenarios have been found.',
    'Clients.Modal.Message.NoTeamsSpecified': 'No teams specified',
    'Clients.Modal.EmptyMessage.NoSelectedTeams': 'No selected teams',
    'Clients.Modal.Message.NoAssignedTeams': 'The client has no assigned teams',
    'Clients.Modal.Label.Certificate': 'Certificate',
    'Clients.Modal.Label.DeliveryMode': 'Delivery Mode',
    'Clients.Modal.Label.SessionSize': 'Session Size',
    'Clients.Modal.Label.SessionLength': 'Session Length',
    'Clients.Modal.Label.Outcome': 'Outcome',
    'Clients.Modal.Label.Strategies': 'Strategies',
    'Clients.Modal.Label.Description': 'Description',
    'MursionPortal.Button.MoreInfo': 'More info...',
    'MursionPortal.Button.HideAdditionalInfo': 'Hide additional info...',
    'MursionPortal.Header.Teams': 'Teams',
    'MursionPortal.Header.Timeline': 'Timeline',
    'MursionPortal.Header.Projects': 'Projects ',
    'MursionPortal.Header.Sessions': 'Sessions',
    'MursionPortal.Header.Users': 'Users',
    'MursionPortal.Text.Yes': 'yes',
    'MursionPortal.Text.No': 'no',
    'MursionPortal.Button.Google': 'Google',
    'MursionPortal.Button.Outlook': 'Outlook, iCal, Other',
    'MursionPortal.Heading.GoogleCalendar': ' Google Calendar ',
    'MursionPortal.Heading.AddToCalendar': ' Add to Calendar ',
    'Dashboard.Legacy.GoogleCalendarList.Authorized': ' If you haven\'t done so already, you will be prompted to <code>authorize</code> the <code>{companyName} Portal</code> to add events to your calendar.',
    'Dashboard.Legacy.GoogleCalendarList.EventAdd': 'The event will be <code>added to your calendar</code>, and can still be accessed on the {companyName} Portal.',
    'MursionPortal.Heading.OtherCalendars': 'Other Calendars',
    'Dashboard.Legacy.OtherCalendarList.Downloaded': 'Your event will <code>download</code> as <code>{companyName}</code>',
    'Dashboard.Legacy.OtherCalendarList.SelectCalendar': '<code>Right click</code>, select <code>"Open With"</code>, and select your calendar application.',
    'Dashboard.Legacy.OtherCalendarList.ImportWithinCalendar': '(You can also select "Import" within your calendar, and locate the downloaded file)',
    'Dashboard.Legacy.OtherCalendarList.AddedToCalendar': 'The event will be <code>added to your calendar</code>, and can still be accessed on the {companyName} Portal',
    'MursionPortal.Text.NoLearnersAddedYet': 'No learners have been added yet',
    'MursionPortal.Button.More': 'More',
    'MursionPortal.Heading.Schedule': 'Schedule',
    'MursionPortal.Heading.Users': 'USERS',
    'MursionPortal.Heading.ProfServices': 'Prof. Services',
    'MursionPortal.Heading.ProjectManagers': 'Project Managers',
    'MursionPortal.Heading.SimSpecialist': 'Sim Specialists',
    'MursionPortal.Heading.Clients': 'Clients',
    'MursionPortal.Heading.Sessions': 'Sessions',
    'MursionPortal.Heading.Projects': 'Projects',
    'MursionPortal.Heading.Teams': 'Teams',
    'Projects.Button.CreateProject': 'Create Project ',
    'Projects.ListHeader.CreationDate': 'Creation date',
    'Projects.ListHeader.ProjectManagers': 'Project Managers',
    'MursionPortal.Capitalized.Active': 'ACTIVE',
    'MursionPortal.Capitalized.Completed': 'COMPLETED',
    'MursionPortal.Capitalized.Archived': 'ARCHIVED',
    'MursionPortal.Capitalized.All': 'ALL',
    'MursionPortal.Label.ProjectId': 'Contract ID',// TODO: add translation',
    'Projects.ProjectCard.BackToClient': 'Back to Client',
    'Projects.ProjectCard.NoProjectsFound': 'No projects found',
    'Projects.ProjectCard.PlaceHolder.EnterProjectName': 'Enter Project Name',
    'Projects.ProjectCard.ProjectsList': 'PROJECTS LIST',
    'MursionPortal.Capitalized.Project': 'PROJECT',
    'Projects.ScenarioList.ThereAreNoScenarios': 'There are no scenarios yet.',
    'Projects.ScenarioList.NoScenariosFound': 'No Scenarios have been found.',
    'Projects.ScenarioList.Button.Draft': 'Draft',
    'Projects.ScenarioList.Legend.ScenariosStatusTab': 'Scenarios Status Tabs',
    'Projects.ScenarioList.Legend.CreateNew': 'Create new, Repeat client, Repeat Bank scenario buttons',
    'MursionPortal.Finalized': 'Finalized',
    'MursionPortal.Started': 'Started',
    'MursionPortal.Finished': 'Finished',
    'MursionPortal.Archived': 'Archived',
    'MursionPortal.EnterScenarioName': 'Enter scenario name',
    'Projects.AllProjects': 'all projects',
    'MursionPortal.Label.LowerCase.Archived': 'archived',
    'MursionPortal.Label.Active': 'Active',
    'MursionPortal.Label.Completed': 'Completed',
    'MursionPortal.Label.Opened': 'Opened',
    'MursionPortal.Label.ActedOn': 'Acted On',
    'MursionPortal.Label.Unread': 'Unread',
    'Projects.Tab.Basic': 'Basic',
    'MursionPortal.Heading.ProjectName': 'Project Name',
    'MursionPortal.Label.Created': 'Created',
    'MursionPortal.Label.Status': 'Status',
    'MursionPortal.Label.Learners': 'Learners',
    'MursionPortal.Label.LearnersCount': '{count} Learners',
    'Projects.Tab.NoLearners': 'No Learners',
    'MursionPortal.Label.SoftwareVersion': 'Software Version',
    'MursionPortal.Label.SoftwareType': 'Type',
    'Projects.ProjectInfo.Label.Non-Billable': 'Non-Billable',
    'Projects.ProjectInfo.Label.Contracted': 'Contracted',
    'Projects.ProjectInfo.Label.CancellationWindow': 'Cancellation Window',
    'MursionPortal.Header.Recording': 'Recording',
    'Projects.ProjectInfo.Label.SurveyControl': 'Project Surveys',
    'Projects.ProjectInfo.Checkbox.EnablePreSimulationSurvey': 'Enable pre-simulation learner survey',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey': 'Enable post-simulation learner survey',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.OneToOne': 'Enable post-simulation SIM Feedback survey for 1:1 deliveries',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.Workshop': 'Enable post-simulation SIM Feedback survey for workshop deliveries',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled': 'Project Surveys',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PreSimulationSurvey': 'Pre-simulation Learner Survey',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey': 'Post-simulation Learner Survey',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.OneToOne': 'Post-simulation SIM Feedback Survey 1:1 Deliveries',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.Workshop': 'Post-simulation SIM Feedback Survey Workshop Deliveries',
    'Projects.ProjectInfo.Checkbox.AllowRecording': 'Allow recording',
    'Projects.ProjectInfo.Checkbox.AllowRecordingInfo': 'This will not delete any currently saved recordings.',
    'MursionPortal.Label.VideoRecording': 'Video Recording',
    'MursionPortal.Label.Allowed': 'Allowed',
    'MursionPortal.Label.Disallowed': 'Disallowed',
    'MursionPortal.Label.RecordedVideosGoBackTo': 'Recorded videos go back to:',
    'MursionPortal.Checkbox.RecordingPermissionLiesWithLearner': 'Recording permission lies with learner',
    'MursionPortal.Label.LiesWithLearner': 'Lies with learner',
    'MursionPortal.Heading.SessionSmartMetrics': 'Session Voice Analytics:',
    'MursionPortal.Checkbox.EnableSmartMetrics': 'Enable Session Learner Voice Analytics',
    'MursionPortal.Label.VideoRecordingSharing': 'User sharing or downloading of their session videos',
    'MursionPortal.Label.SharingOrDownloading': 'Do not allow sharing or downloading',
    'Projects.ProjectInfo.Label.Enabled': 'Enabled',
    'Projects.ProjectInfo.Label.Disabled': 'Disabled',
    'MursionPortal.Heading.Tags': 'Tags',
    'MursionPortal.Button.AreYouSureEditProject': 'Are you sure you want to edit this project?',
    'MursionPortal.Button.SaveProjectInfo': 'Save project info',
    'Projects.Tab.Utilization': 'Utilization',
    'MursionPortal.Label.Loading': 'Loading...',
    'Projects.ProjectInfo.Label.NoProjectManagers': 'No project managers',
    'Projects.ProjectInfo.Label.NoSelectedProjectManagers': 'No selected project managers',
    'MursionPortal.Label.ProjectManagers': 'Project Managers',
    'MursionPortal.Label.RecordingPermission': 'Recording permission:',
    'MursionPortal.Label.Timeline': 'Project Timeline',
    'Projects.ProjectInfo.Label.TimelineUtc': 'Project Timeline (UTC)',
    'Projects.Utilization.Label.UtilizedSessions': 'Utilized Sessions:',
    'Projects.Utilization.Label.UtilizedMissedSessions': 'Utilized Missed Sessions:',
    'Projects.Utilization.Label.UtilizedCancelledSessions': 'Utilized Cancelled Sessions:',
    'Projects.Utilization.Label.UtilizedDuration': 'Utilized Duration:',
    'Projects.Utilization.Label.RemainingDuration': 'Remaining Duration:',
    'MursionPortal.ScenarioListItem.Label.Timeline': 'timeline',
    'MursionPortal.ScenarioListItem.Label.SessionLength': 'session length',
    'MursionPortal.ScenarioListItem.Label.SessionSize': 'session size',
    'MursionPortal.ScenarioListItem.Label.DeliveryMode': 'delivery mode',
    'MursionPortal.ScenarioCard.BackToProject': 'Back to Project',
    'MursionPortal.ScenarioCard.NoScenariosFound': 'No scenarios found',
    'MursionPortal.Placeholder.EnterScenarioName': 'Enter Scenario Name',
    'MursionPortal.ScenarioCard.Label.ScenariosList': 'SCENARIOS LIST',
    'MursionPortal.ScenarioCard.Header.Scenario': 'SCENARIO',
    'MursionPortal.ScenarioCard.Header.ChooseTypeOfEvent': 'Choose the type of event to schedule',
    'MursionPortal.Label.Session': 'Session',
    'MursionPortal.Label.Training': 'Training',
    'MursionPortal.Button.Continue': 'Continue',
    'MursionPortal.Button.Schedule': 'Schedule',
    'MursionPortal.Label.Draft': 'Draft',
    'MursionPortal.Label.Finalized': 'Finalized',
    'MursionPortal.Label.Started': 'Started',
    'MursionPortal.Label.Finished': 'Finished',
    'MursionPortal.Label.Archived': 'Archived',
    'MursionPortal.Label.Cancel': 'cancel',
    'MursionPortal.Label.Edit': 'edit',
    'MursionPortal.Label.CancelEdit': 'Cancel Edit',
    'MursionPortal.Label.EditScenario': 'Edit Scenario',
    'MursionPortal.Label.EditScenarioName': 'Edit Scenario Name',
    'MursionPortal.ScenarioCard.Label.ApplyNameChange': 'Apply Name Change',
    'MursionPortal.ScenarioCard.Label.Apply': 'apply',
    'MursionPortal.ScenarioCard.Tab.Info': 'INFO',
    'MursionPortal.ScenarioCard.Tab.Learners': 'LEARNERS',
    'MursionPortal.ScenarioCard.Tab.Sessions': 'SESSIONS',
    'MursionPortal.ScenarioCard.Tab.Legend.ViewModeTab': 'View Mode Tab',
    'MursionPortal.ScenarioCard.ConfirmButton.AreYouSureArchiveScenario': 'Are you sure you want to archive this scenario? All related data will also be archived.',
    'MursionPortal.ScenarioCard.ConfirmButton.ArchiveScenario': 'Archive Scenario',
    'MursionPortal.Label.Delivery': 'Delivery',
    'MursionPortal.ScenarioInfo.Header.ArchivedScenario': 'ARCHIVED SCENARIO',
    'MursionPortal.ScenarioInfo.Button.YouMayOnlyEditTeams': 'You may only edit teams and timeline if you finalize a scenario',
    'MursionPortal.ScenarioInfo.Button.FinalizeScenario': 'Finalize scenario',
    'MursionPortal.ScenarioInfo.Button.SaveScenario': 'Save scenario',
    'MursionPortal.ScenarioInfo.Button.CreatePlanning': 'Create Planning ',
    'MursionPortal.ScenarioInfo.Header.Description': 'Description',
    'MursionPortal.ScenarioInfo.Label.SimLength': 'Sim Length',
    'MursionPortal.Header.Outcome': 'Outcome',
    'MursionPortal.Header.Strategies': 'Strategies',
    'MursionPortal.Header.Attachments': 'Attachments',
    'MursionPortal.ScenarioInfo.Button.SaveScenarioInfo': 'Save scenario info',
    'MursionPortal.Label.ContentBundle': 'Content Bundle',
    'MursionPortal.ScenarioInfo.Button.ShowLess': 'Show less',
    'MursionPortal.ScenarioInfo.Button.ShowMore': 'Show more',
    'MursionPortal.AssetSettings.Label.CurrentScenarioNoAssetSettings': 'Current scenario has no asset settings.',
    'MursionPortal.Teams.Label.NoSelectedTeams': 'No selected teams',
    'MursionPortal.Teams.Label.ClientHasNoAssignedTeams': 'The client has no assigned teams',
    'MursionPortal.Label.Teams': 'Teams',
    'MursionPortal.Label.NoTeamsSpecified': 'No teams specified',
    'MursionPortal.Label.Capitalized.Draft': 'DRAFT',
    'MursionPortal.Label.Capitalized.Finalized': 'FINALIZED',
    'MursionPortal.Label.Capitalized.Started': 'STARTED',
    'MursionPortal.Label.Capitalized.Finished': 'FINISHED',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioStillInDevelopmentStage': 'Scenario is still in development stage',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioDeployedTimelineUpcoming': 'Scenario is deployed, but timeline is still upcoming',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioActiveLearnersCanSchedule': 'Scenario is active, and learners can schedule sessions',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioIsCompleted': 'Scenario is completed',
    'MursionPortal.Button.Add': 'Add',
    'MursionPortal.Label.Warning': 'Warning',
    'MursionPortal.SessionWizard.Label.UnableToCreateSession': 'Unable to create session for the selected {failedParam}.',
    'MursionPortal.SessionWizard.Label.PleaseContinueToFindAnother': 'Press Continue to find another <code>{failedParam}</code>.',
    'MursionPortal.SessionWizard.Label.ScheduleSession': 'Schedule Session',
    'MursionPortal.SessionWizard.Label.ScheduleTraining': 'Schedule Training',
    'MursionPortal.SessionWizard.Label.AddNotes': 'Add notes',
    'MursionPortal.SessionWizard.Label.SelectSimulationContentForUse': 'Select Simulation Content for Use',
    'MursionPortal.SessionWizard.Label.SelectStep': 'Select {step}',
    'MursionPortal.SessionWizard.Label.Expired': 'expired',
    'MursionPortal.SessionWizard.ConfirmButton.AreYouSureCreateEmergencySession': 'Are you sure you want to create emergency session?',
    'MursionPortal.SessionWizard.Button.Create': 'Create',
    'MursionPortal.SessionWizard.Label.RemainingTimeToBook': 'remaining to book this time block before hold expires.',
    'MursionPortal.SessionWizard.Label.TimeBlockHoldExpired': 'Time block hold has been expired.',
    'MursionPortal.Label.Date': 'Date',
    'MursionPortal.Label.Time': 'Time',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailableClickSubmit': 'That time is no longer available, would you like to select a different option? Go back to pick an available option or click submit to request the existing option.',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailable': 'That time is no longer available, would you like to select a different option? Go back to pick an available option.',
    'MursionPortal.SessionWizard.Button.GoBack': 'Go Back',
    'MursionPortal.Button.Submit': 'Submit',
    'MursionPortal.SessionWizard.Label.NoAvailableClients': 'No Available Clients',
    'MursionPortal.SessionWizard.Label.NoAvailableProjects': 'No Available Projects',
    'MursionPortal.SessionWizard.Label.NoAvailableScenarios': 'No Available Scenarios',
    'MursionPortal.SessionWizard.Label.PleaseSelectScenario': 'Please select the scenario first',
    'MursionPortal.SessionWizard.Label.NoImage': 'No image',
    'MursionPortal.SessionWizard.EmptyMessage.NoAvailableSImSpecialists': 'No Available Sim Specialists',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoContentBundles': 'The scenario has no assigned content bundles.',
    'MursionPortal.SessionWizard.Option.SelectAnAsset': 'Select an asset',
    'MursionPortal.SimSpecialistStep.Label.NoSimSpecialistsAvailable': 'There are no sim-specialists available for the selected time. Session will be assigned by request.',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainees': 'You can only add up to {maxLearners} trainees for this type of session.',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainee': 'You can only add up to {maxLearners} trainee for this type of session.',
    'MursionPortal.EmptyMessage.NoUsersSelected': 'No users selected',
    'MursionPortal.Placeholder.Search': 'Search',
    'MursionPortal.SsLearnersStep.Label.NoTraineeToSelect': 'No trainee to select',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearners': 'You can only add up to {maxLearners} learners for this type of session.',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearner': 'You can only add up to {maxLearners} learner for this type of session.',
    'MursionPortal.LearnersStep.Label.NoLearnersToSelect': 'No learners to select',
    'MursionPortal.LearnersStep.Label.NoUsersSelected': 'No users selected',
    'MursionPortal.LearnersStep.Option.AllTeams': 'All teams',
    'MursionPortal.LearnersStep.Button.AddEntireTeam': 'Add entire team',
    'MursionPortal.LearnersStep.Note.AllowedSessionSIze': 'Allowed session size equals to {maxLearners}. Select particular Learners',
    'MursionPortal.LearnersStep.Checkbox.OnlyUnauthorizedUsers': 'Only unauthenticated users will join',
    'MursionPortal.NotesStep.SessionNotes': 'Session notes',
    'MursionPortal.NotesStep.ErrorMessage.AllowedLimitExceeded': 'Allowed character limit ({limit} characters) has been exceeded',
    'MursionPortal.Label.Trainer': 'Trainer',
    'MursionPortal.Label.Description': 'Description',
    'MursionPortal.Label.Outcome': 'Outcome',
    'MursionPortal.Label.Strategies': 'Strategies',
    'MursionPortal.Label.DeliveryMode': 'Delivery Mode',
    'MursionPortal.Label.SessionLength': 'Session Length',
    'MursionPortal.Label.NoDescriptionProvided': 'No description provided',
    'MursionPortal.Label.NoOutcomeProvided': 'No outcome provided',
    'MursionPortal.Label.NoStrategiesProvided': 'No strategies provided',
    'MursionPortal.Placeholder.Dotted.Search': 'Search...',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocks': 'No available time blocks',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocksInRange': 'No available time blocks in selected range',
    'MursionPortal.TimeStep.Footer.BySelectingThisTimeBlock': 'By selecting this time block you will be requesting a currently unavailable session time. We will do our best to fulfill this request if you proceed, or you slide the below toggle “Off” to select only from confirmed available time blocks.',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOff': 'Slide the toggle "Off" to select only from available time blocks.',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOn': 'Slide the toggle "On" to request an additional time block.',
    'MursionPortal.Label.LowerCase.Client': 'client',
    'MursionPortal.Label.Project': 'project',
    'MursionPortal.Label.Scenario': 'scenario',
    'MursionPortal.Projects.NoProjectsFound': 'No Projects have been found.',
    'MursionPortal.Projects.YourTeamsNotAssigned': 'Your teams have not yet been assigned to any projects.',
    'MursionPortal.Projects.NoProjectsCreated': 'No Projects have been created yet.',
    'MursionPortal.Teams.NoTeamsFound': 'No Teams have been found.',
    'MursionPortal.Teams.YouHaveNotBeen': 'You have not yet been added to any teams.',
    'MursionPortal.Teams.YouHaveNoTeams': 'You have no teams yet.',
    'MursionPortal.Teams.NoTeamsCreated': 'No teams have been created yet.',
    'Settings.SSO.ModalBody.SelectDestination': 'Select link destination', // TODO: add translations
    'Settings.SSO.ModalBody.ProjectSelector.DisabledTitle': 'Select the right option first', // TODO: add translations
    'Settings.SSO.ModalBody.ScenarioSelector.DisabledTitle': 'Select a project first', // TODO: add translations
    'Settings.SSO.ModalBody.SelectDestination.SessionsTab': 'Sessions Tab', // TODO: add translations
    'Settings.SSO.ModalBody.SelectDestination.CalendarTab': 'Calendar Tab', // TODO: add translations
    'Settings.SSO.ModalBody.SelectDestination.ReportsTab': 'Reports Tab', // TODO: add translations
    'Settings.SSO.ModalBody.SelectDestination.DashboardTab': 'Dashboard Tab', // TODO: add translations
    'Settings.SSO.ModalBody.SelectDestination.ScenarioCard': 'Scenario Card', // TODO: add translations
    'Settings.SSO.ModalBody.SelectDestination.ScenarioTabSelectTitle': 'First select a project then scenario', // TODO: add translations
    'Settings.SSO.Modal.ConfigurationName': 'Configuration Name',
    'Settings.SSO.Modal.AuthorizationEndpoint': 'Authorization Endpoint',
    'Settings.SSO.Modal.LogoutEndpoint': 'Logout Endpoint',
    'Settings.SSO.Modal.ClientID': 'Client ID',
    'Settings.SSO.Modal.ClientSecret': 'Client Secret',
    'Settings.SSO.Modal.Scope': 'Scope',
    'Settings.SSO.Modal.UserInfoEndpoint': 'User Info Endpoint',
    'Settings.SSO.Modal.TokenEndpoint': 'Token Endpoint',
    'Settings.SSO.Modal.TokenHTTPMethod': 'Token HTTP Method',
    'Settings.SSO.Modal.UserIDClaimName': 'User ID Claim Name',
    'Settings.SSO.Modal.EmailClaimName': 'Email Claim Name',
    'Settings.SSO.Modal.FirstNameClaimName': 'First Name Claim Name',
    'Settings.SSO.Modal.LastNameClaimName': 'Last Name Claim Name',
    'Settings.SSO.Modal.EmployeeIDClaimName': 'Employee ID Claim Name',
    'Settings.SSO.Modal.LocationClaimName': 'Location Claim Name',
    'Settings.SSO.Modal.DepartmentClaimName': 'Department Claim Name',
    'Settings.SSO.Modal.PhoneClaimName': 'Phone Claim Name',
    'Settings.SSO.Modal.TitleClaimName': 'Title Claim Name',
    'Settings.SSO.Modal.TimezoneClaimName': 'Timezone Claim Name',
    'Settings.SSO.Modal.LanguageClaimName': 'Language Claim Name',
    'Settings.SSO.Modal.LinkAllowed': 'Link Allowed',
    'Settings.SSO.Modal.UnlinkAllowed': 'Unlink Allowed',
    'Settings.SSO.Modal.SingleLogoutEnabled': 'Single Logout Enabled',
    'Settings.SSO.Modal.SingleSignOnServiceEndpoint': 'Single Sign On Service Endpoint',
    'Settings.SSO.Modal.EntityID': 'Entity ID',
    'Settings.SSO.Modal.X509Certificate': 'X.509 Certificate',
    'Settings.SSO.Button.CreateSsoConfiguration': 'Create SSO Configuration ',
    'Settings.SSO.Button.CreateDeepLink': 'Create SSO Deep Link', // TODO: add translations
    'Settings.SSO.Modal.Protocol': 'Protocol :',
    'Settings.SSO.Modal.Type': 'Type :',
    'Settings.SSO.Modal.ID': 'ID :',
    'Settings.SSO.Modal.AssignedClients': 'Assigned Clients',
    'Settings.SSO.Modal.Button.DeleteSso': 'Delete SSO',
    'Settings.SSO.ModalHeader.EditSsoConfiguration': 'Edit SSO Configuration',
    'Settings.SSO.ModalHeader.DeepLink': 'Create SSO Deep Link',
    'Settings.SSO.ModalHeader.NewSsoConfiguration': 'New SSO Configuration',
    'Settings.SSO.Modal.Button.CreateSsoConfiguration': 'Create SSO Configuration',
    'Settings.SSO.Modal.Button.SaveSsoConfiguration': 'Save SSO Configuration',
    'Settings.SSO.Modal.DeletionConfirmationText': 'Are you sure you want to delete this SSO configuration?',
    'Settings.SSO.Modal.AddButton.GroupClaimName': 'Group Claim Name',
    'Settings.SSO.Modal.AddButton.Team': 'Team',
    'Settings.SSO.Modal.AddButton.PlaceHolder.GroupClaimValue': 'Group Claim Name',
    'Settings.SSO.Modal.SPInitiatedSsoUrl': 'SP Initiated SSO URL :',
    'Settings.SSO.Modal.Button.DownloadSPMetadata': 'Download SP Metadata',
    'Settings.SSO.Modal.Button.Public': 'Public',
    'Settings.SSO.Modal.Button.Private': 'Private',
    'Settings.SSO.Modal.ConfigurationIcon': 'Configuration Icon :',
    'Settings.SSO.Modal.PlaceHolder.EnterTeamClaimName': 'Enter Team Claim Name',
    'Settings.SSO.Modal.TeamClaimName': 'Team Claim Name',
    'Settings.SSO.Modal.TeamMapping': 'Team mapping :',
    'Settings.SSO.Modal.Button.Change': 'Change',
    'MursionPortal.PlaceHolder.SelectAClient': 'Select a Client',
    'Settings.SSO.Modal.AddButton.PlaceHolder.SelectATeam': 'Select a team',
    'MursionPortal.Label.Client': 'Client',
    'Settings.SSO.Modal.ConfirmDelete': 'Confirm Delete',
    'Settings.SSO.Table.ColumnHeader.ClientName': 'Client Name',
    'Settings.SSO.Table.ColumnHeader.ConfigurationName': 'Configuration Name',
    'Settings.SSO.Table.ColumnHeader.Type': 'Type',
    'Settings.SSO.Table.ColumnHeader.Protocol': 'Protocol',
    'Settings.SSO.Table.ColumnHeader.ClientsCount': 'Clients Count',
    'Settings.SSO.PlaceHolder.EnterSsoName': 'Enter SSO Name',
    'Settings.SSO.Table.NoSsoSchemasFound': 'No SSO Schemas have been found.',
    'Settings.SSO.Table.NoSsoConfigurationsFound': 'No SSO Configurations have been found.',
    'Settings.SSO.Table.HoverTitle.ViewEditSso': 'View/Edit SSO',
    'Settings.SSO.Modal.HoverTitle.CopySPInitiatedSsoUrl': 'Copy SP Initiated SSO URL',
    'Settings.SSO.Modal.CopyButton.ModalHeader.SPInitiatedSsoUrl': 'SP Initiated SSO URL',
    'MursionPortal.CopyText.CopiedToClipboard': '  <code>{value}</code> {lineBreak} has been copied to clipboard!',
    'Settings.SSO.Text.Public': 'Public',
    'Settings.SSO.Text.Private': 'Private',
    'Settings.SSO.Modal.NoClientsAssigned': 'No Clients Assigned',
    'MursionPortal.Label.NotAvailable': 'n/a',
    'MursionPortal.SearchPlaceHolder.SearchClient': 'Search Client...',
    'Settings.SSO.Modal.AddButton.SearchPlaceHolder.SearchTeam': 'Select Team...',
    'Settings.SSO.Modal.Button.ChangeLabel': 'Change {label}',
    'MursionPortal.Label.Yes': 'Yes',
    'MursionPortal.Label.No': 'No',
    'Settings.SSO.Modal.AddButton.PlaceHolder.NoOptions': 'No options',
    'MyMessages.Button.ArchiveAll': 'Archive All',
    'MyMessages.Button.ArchivedMessages': 'Archived Messages',
    'MyMessages.Button.ShowMore': 'Show More...',
    'MyMessages.Label.NewMessages': '<code>{newMessagesCount}</code> New Messages',
    'MyMessages.Label.NewMessage': '<code>{newMessagesCount}</code> New Message',
    'MyMessages.HoverTitle.InboxButton': 'inbox button',
    'MyMessages.Text.InboxButton': 'Inbox Button',
    'MyMessages.Text.HaveNewMessages': 'You have {total} new messages',
    'MyMessages.Text.HaveNewMessage': 'You have a new message',
    'MursionPortal.CookiePolicy.Header.CookiePolicy': 'Cookie Policy',
    'MursionPortal.CookiePolicy.Text.MursionUsesEssentialCookiesOnly': 'Mursion only uses essential cookies that are required for your security and our web application to operate. We do not use cookies to collect any Personal Data or other information, such as IP addresses or website usage.',
    'MursionPortal.CookiePolicy.Text.ForMoreInformation': ' For more information, please see <code>Privacy Policy</code>',
    'MursionPortal.Button.Ok': 'Ok',
    'MyProfile.Button.SaveChanges': 'Save Changes',
    'MyProfile.Label.ExternalProfiles': 'External Profiles',
    'MyProfile.Label.UserRoles': 'User Roles',
    'MyProfile.Label.UserRole': 'User Role',
    'MyProfile.Heading.ReviewAccountInformation': 'Please review your account information',
    'MyProfile.HoverTitle.UnlinkProfile': 'Unlink your {linkName} profile',
    'MyProfile.HoverTitle.LinkProfile': 'Link {linkName} profile',
    'MyProfile.Button.Unlink': 'Unlink',
    'MyProfile.Text.UnlinkConfirmationText': 'Are you sure you want to unlink your {name} profile? Please note that you will also be signed out from the {name}.',
    'MyProfile.Title.ProfileUnlink': '{name} profile unlink',
    'MyProfile.Text.SuccessfullyUnlinkedProfile': 'You have successfully unlinked your {name} profile',
    'MyProfile.Text.TimeZoneIsSetAutomatically': 'Your time zone is set automatically to {getTimeZone}. Click "Confirm" to accept this selection or "Cancel" to make another selection.',
    'MyProfile.Text.TimeZoneChange': 'In the case of timezone change please check existing events and availabilities.',
    'MyProfile.Text.ProfileTimeZoneNotMatchComputer': ' Your current profile timezone selection does not match your computer Date & Time settings ({timeZone}).Click "Save Changes" to proceed anyway, or select a different profile timezone option.',
    'MursionPortal.Button.MyProfile': 'My Profile',
    'MursionPortal.Button.LogOut': 'Log Out',
    'MursionPortal.Label.ChangePassword': 'Change Password',
    'MursionPortal.Label.ChangeYourPassword': 'Change Your Password',
    'MyProfile.Label.Language': 'Language',
    'MursionPortal.Label.Roles': 'Roles',
    'MursionPortal.Label.Role': 'Role',
    'Settings.SSO.Modal.ClientName': 'Client Name',
    'Teams.Modal.Header.NewTeam': 'New Team',
    'Teams.Modal.Header.AllTeams': 'all teams',
    'MursionPortal.Label.Name': 'Name:',
    'MursionPortal.Label.Location': 'Location:',
    'MursionPortal.Label.Tags': 'Tags:',
    'MursionPortal.Header.Facilitators': 'Facilitators:',
    'MursionPortal.Header.Scenarios': 'Scenarios:',
    'MursionPortal.Button.SaveTeamInfo': 'Save team info',
    'MursionPortal.IconButton.Cancel': 'cancel',
    'MursionPortal.IconButton.Edit': 'edit',
    'MursionPortal.ButtonTitle.CancelEdit': 'Cancel Edit',
    'MursionPortal.ButtonTitle.EditTeam': 'Edit Team',
    'MursionPortal.ConfirmationText.RestoreTeam': 'Are you sure you want to restore this team?',
    'MursionPortal.ButtonTitle.RestoreTeam': 'Restore Team',
    'MursionPortal.ConfirmationText.ArchiveTeam': 'Are you sure you want to archive this team?',
    'MursionPortal.ButtonTitle.ArchiveTeam': 'Archive Team',
    'MursionPortal.EmptyMessage.NoFacilitators': 'No facilitators',
    'MursionPortal.EmptyMessage.NoSelectedFacilitators': 'No selected facilitators',
    'MursionPortal.Title.Facilitators': 'Facilitators',
    'MursionPortal.Heading.Archived': '(Archived)',
    'Teams.Modal.Header.AllLearners': 'All Learners',
    'Teams.Modal.Placeholder.SearchLearners': 'Search Learners...',
    'Teams.Modal.EmptyMessage.NoLearnersToSelect': 'No Learners to select',
    'Teams.Modal.Header.TeamLearners': 'Team Learners',
    'Teams.Modal.Placeholder.SearchTeamLearners': 'Search Team Learners...',
    'Teams.Modal.EmptyMessage.NoTeamLearnersToSelect': 'No Team Learners to select',
    'Teams.Modal.Header.AssignLearners': 'Assign Learners to a Team "{teamName}"',
    'MursionPortal.Modal.Button.Add': 'Add',
    'MursionPortal.Modal.Button.Remove': 'Remove',
    'Teams.AssignLearners.Modal.Button.SelectAll': 'Select All',
    'Teams.AssignLearners.Modal.EmptyMessage.NoItems': 'No items to select',
    'Teams.Modal.AlertMessage.Timezone': ' In the case of timezone change please check existing events and availabilities.',
    'Teams.SelectorMenu.SelectedOf': 'Selected {selected} of {totalNumber}',
    'Teams.Modal.AlertMessage.ProfileTimezone': 'Your current profile timezone selection does not match your computer\'s Date & Time settings ({updatedTimezone}).Click "Save Changes" to proceed anyway, or select a different profile timezone option.',
    'MursionPortal.Label.Learner': 'Learner',
    'Teams.Modal.Button.More': '{overlappedAmount} more...',
    'MursionPortal.IconButton.Archive': 'archive',
    'Teams.ToggleButton.Learners': 'Learners',
    'Teams.ToggleButton.Sessions': 'Sessions',
    'MursionPortal.Status.RegistrationStatus': 'Registration Status',
    'MursionPortal.Status.RegistrationAccepted': 'Registration Accepted',
    'MursionPortal.Status.RegistrationNotAccepted': 'Registration Not Accepted',
    'Users.Table.Hover.ViewEditAcceptedUsers': 'View/Edit Accepted Users',
    'Users.Table.Hover.ViewEditUnacceptedUsers': 'View/Edit Unaccepted Users',
    'MursionPortal.Label.Industry': 'Industry:',
    'MursionPortal.Label.Timezone': 'Timezone:',
    'MursionPortal.Header.Client': 'Client',
    'MursionPortal.FilterByCertificate': 'Filter by Certificate',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Header.CreateQAEvent': 'Create QA Event',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.TimeBlock': 'Time Block(s)',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Participants': 'Participants',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Button.SubmitNewEvent': 'Submit New Event',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockOutsideRange': 'The time block is outside the available range',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockBeforeCurrentTime': 'The time block is before the current time',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Start': 'Start',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.End': 'End',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Length': 'Length',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.NoAvailableTimeBlock': 'There is no available time block for the selected day',
    'Calendar.Modal.Button.Client': 'Client',
    'Calendar.Modal.Button.Project': 'Project',
    'Calendar.Modal.Button.Scenario': 'Scenario',
    'Calendar.Modal.Header.CompanyEvents': 'Company Events',
    'MursionPortal.Header.Session': 'Session',
    'MursionPortal.Header.Events': 'Events',
    'MursionPortal.Header.Event': 'Event',
    'Calendar.Modal.Label.Time': 'Time:',
    'Calendar.Label.SelectTimeZone': 'Select time zone',
    'Calendar.Text.Training': ' Training',
    'Calendar.Text.QA': ' QA',
    'Calendar.Modal.Text.ConfirmDeleteTime': 'Confirm Delete Time Block',
    'Calendar.Button.NewTimeBlock': 'New Timeblock',
    'Calendar.Modal.Label.Capitalized.Time': 'TIME',
    'Calendar.Modal.Label.Participants': 'PARTICIPANTS',
    'Calendar.Modal.Text.NoParticipants': 'No participants',
    'Calendar.Modal.Label.SimulationSpecialist': 'SIMULATION SPECIALIST',
    'Calendar.Modal.Label.MeetingOrganizer': 'MEETING ORGANIZER',
    'Calendar.Error.ErrorPrefixMustBeBetween': '{errorPrefix} must be between {duration1} and {duration2}.',
    'Calendar.Error.SelectedTimeBlockOverlapsExistingTime': 'Selected time block overlaps existing time block.',
    'Calendar.Error.SelectedTimeBlockOverlapsUnavailableTime': 'Selected time block overlaps unavailable time interval.',
    'Calendar.Error.SelectedTimeBlockIsNotMultipleOfEventGap': 'Selected time block is not a multiply of event gap ({duration})',
    'Calendar.Text.AvailabilityUser': 'Availability {user}',
    'Calendar.Text.AvailabilityTime': 'Availability {time}',
    'Calendar.Text.AvailabilityDurationAvailabilityTime': '{availabilityDuration} Availability {time}',
    'Calendar.Text.TrainingsTime': '{eventsCount} Trainings {time}',
    'Calendar.Text.SessionsTime': '{eventsCount} Sessions {time}',
    'Calendar.Text.AvailabilityName': 'Availability {name}',
    'Calendar.Label.Learners': 'Learners:',
    'Calendar.Text.FullAvailability': 'Full Availability',
    'Calendar.Text.YourAvailability': 'Your availability',
    'Calendar.Text.Event': 'Event',
    'Calendar.Header.Trainings': 'Trainings',
    'Calendar.Header.CompanyEvents': 'Company Events',
    'Calendar.Header.Session': ' Session ',
    'Calendar.Error.TimeBlockIsOutsideAvailableRange': 'The time block is outside the available range',
    'Calendar.Error.TimeBlockIsBeforeCurrentTime': 'The time block is before the current time',
    'Calendar.Label.Break': 'Break',
    'Calendar.Label.Prep': 'Prep',
    'Calendar.Modal.Header.CreateBreakPrepEvent': 'Create Break/Prep Event',
    'Calendar.Modal.Label.EventType': 'EventType',
    'Calendar.Modal.Label.TimeBlocks': 'Time Block(s)',
    'Calendar.Text.NoTimeBlocksInSelectedRange': 'No available time blocks in selected range',
    'Calendar.Text.NoAvailableTimeBlocks': 'No available time blocks',
    'Calendar.Text.SelectingThisTimeBlock': 'By selecting this time block you will be requesting a currently unavailable session time. We will do our best to fulfill this request if you proceed, or you slide the below toggle “Off” to select only from confirmed available time blocks.',
    'Calendar.ToolTip.SlideToggleOff': 'Slide the toggle "Off" to select only from available time blocks.',
    'Calendar.ToolTip.SlideToggleOn': 'Slide the toggle "On" to request an additional time block.',
    'Calendar.Label.SelectAnyTimeBlock': 'Select any time block',
    'Users.CreateUser.Label.GoogleMeetID': 'Google Meet ID',
    'Users.CreateUser.GoogleMeetId.Placeholder.GoogleMeetID': 'Google Meet ID',
    'Users.CreateUser.GoogleMeetID.Hover': 'Please enter a Google Meet ID',
    'Setting.Edit.Modal.Error.UnableToCreateSession': 'Unable to create session for the selected time {formattedStartTime} - {formattedEndTime}',
    'Session.Header.Session': 'Session ',
    'Session.Header.AcceptSessionRequest': 'Please confirm whether you want to accept this session request and add it to your schedule.',
    'CommonComponent.RecurrenceForm.Label.UntilDate': 'Until Date',
    'CommonComponent.RecurrenceForm.Label.Period': 'Period',
    'CommonComponent.RecurrenceForm.Option.Daily': 'Daily',
    'CommonComponent.RecurrenceForm.Option.Weekly': 'Weekly',
    'CommonComponent.RecurrenceForm.Label.Weekday': 'Weekday',
    'CommonComponent.RecurrenceForm.Text.NoSelectedWeekdays': 'No selected weekdays',
    'CommonComponent.RecurrenceForm.Error.PleaseSelectWeekdays': 'Please select the Weekday(s)',
    'Calendar.EventType.Availability': 'Availability',
    'Calendar.EventType.Session': 'Session',
    'Calendar.EventType.Training': 'Training',
    'Calendar.EventType.CompanyEvent': 'Company Event',
    'Calendar.EventType.NewEvent': 'New event',
    'Calendar.Text.NewEventTime': 'New event {time}',
    'Calendar.Header.Training': ' Training ',
    'Calendar.Modal.Text.Sessions': 'Sessions',
    'Calendar.Modal.Text.Trainings': 'Trainings',
    'Calendar.Modal.Text.Events': 'Events',
    'Calendar.Modal.Text.PendingNoSS': '- Pending (No SS).',
    'Calendar.Modal.Text.PendingSwap': '- Pending (Swap).',
    'Calendar.Modal.Text.PreBooked': '- Pre-booked.',
    'Calendar.Modal.Text.NeedsReview': '- Needs Review.',
    'Calendar.Modal.Text.JoinSession': '- you can join the session.',
    'Calendar.Modal.Text.NoLearnersInSession': '- there are no learners in the session.',
    'Calendar.Modal.Text.NoLearnersInSessionAfterBookedTime': '- there are no learners in the session after booked time.',
    'Calendar.Modal.Header.Events': 'Events',
    'Calendar.Modal.Header.Session': 'session',
    'Calendar.Modal.Header.Training': 'training',
    'Calendar.Modal.Header.Availability': 'availability',
    'Calendar.Modal.Filter.Client': 'Client',
    'Calendar.Modal.Filter.Project': 'Project',
    'Calendar.Modal.Filter.Time': 'Time',
    'Calendar.Modal.Label.Recurrence': 'Recurrence',
    'Calendar.Modal.Header.CreateAvailability': 'Create Availability',
    'Calendar.Modal.Text.Recurring': 'Recurring',
    'Calendar.SimSpecialistButton.Modal.Header.Scheduled': 'Scheduled',
    'Calendar.SimSpecialistButton.Modal.Header.ThisPeriod': '(this period)',
    'Calendar.SimSpecialistButton.Modal.Label.Sessions': 'Sessions:',
    'Calendar.SimSpecialistButton.Modal.Label.Hours': 'Hours:',
    'Calendar.SimSpecialistButton.Modal.Label.Trainings': 'Trainings:',
    'Calendar.SimSpecialistButton.Modal.Label.TrainingsHours': 'Trainings hours:',
    'Calendar.SimSpecialistButton.Modal.Label.Available': 'Available:',
    'Calendar.SimSpecialistButton.Modal.Header.Certification': 'Certification',
    'Calendar.SimSpecialistButton.Modal.Text.NoCertificates': 'No certificates',
    'Calendar.SimSpecialistButton.Modal.Header.SimSpecialistOverviewPanel': 'Sim Specialist Overview Panel',
    'Calendar.SimSpecialistButton.Modal.HoverTitle.CloseSimSpecialistOverview': 'close simspecialist overview',
    'MursionPortal.DeliveryMode.OneToOne': '1:1',
    'MursionPortal.DeliveryMode.OneToManyRemote': 'Remote',
    'MursionPortal.DeliveryMode.OneToManyLocal': 'Local (Fishbowl)',
    'MursionPortal.DeliveryMode.Group': 'Group Delivery',
    'MursionPortal.Projects.Header.ScenarioType': 'Scenario type',
    'MursionPortal.Projects.Header.Client': 'Client',
    'MursionPortal.Projects.Header.Project': 'Project',
    'MursionPortal.Projects.Header.Scenario': 'Scenario',
    'MursionPortal.Projects.Header.SimulationContent': 'Simulation Content',
    'MursionPortal.Projects.Header.Date': 'Date',
    'MursionPortal.Projects.Header.Time': 'Time',
    'MursionPortal.Projects.Header.Notes': 'Notes',
    'MursionPortal.Projects.Header.Trainees': 'Trainees',
    'Projects.Placeholder.SimContentName': 'Enter Sim Content Name',
    'Projects.Message.NoSimulationContentFound': 'No Simulation Content Found',
    'MursionPortal.Button.UncheckAll': 'Uncheck All',
    'MursionPortal.Button.CheckAll': 'Check All',
    'Projects.Header.NewScenario': 'Project {projectName}: new scenario',
    'MursionPortal.Button.SaveAsDraft': 'Save As Draft',
    'MursionPortal.Button.Save': 'Save',
    'Projects.Message.SelectEnvironmentFirst': 'Select an environment first',
    'Projects.Message.LoadingAvatarsList': 'Loading avatars list...',
    'Projects.Message.NoAssignedAvatars': 'Selected environment has no assigned avatars',
    'Projetcs.EmptyMessage.NoSelectedAvatars': 'No selected avatars',
    'Projects.Avatars.NoAvatarsSpecified': 'No avatars specified',
    'Projects.Message.SelectIndustryFirst': 'Select an industry first',
    'Projects.Message.LoadingEnvironmentsList': 'Loading environments list...',
    'Projects.Message.NoAssignedEnvironments': 'Selected industry has no assigned environments',
    'Projects.Message.ScenarioHasNoAssignedEnvironment': 'The scenario has no assigned environment',
    'Projects.AltText.EnvironmentImage': 'environment-image',
    'Projects.Placeholder.SelectAnEnviroment': 'Select an environment',
    'Projects.Message.UnnamedIndustry': 'Unnamed industry',
    'Projects.Placeholder.SelectAnIndustry': 'Select an industry',
    'Projects.Header.NewProject': 'New Project',
    'Projects.AltText.ClientLogo': 'Client Logo',
    'Projects.Message.SelectAnyTimeBlock': 'Select any time block',
    'Projects.Message.SelectScenarioFirst': 'Please select the scenario first',
    'MursionPortal.Text.Asset': 'Asset V2',
    'MursionPortal.Button.Accept': 'Accept',
    'Projects.Modal.EmptyMessage.NoLearner': 'No Learner',
    'MursionPortal.Label.SelectAll': 'Select all',
    'Projects.Modal.Placeholder.ChooseATeam': 'Choose a team',
    'MursionPortal.Placeholder.SearchTeams': 'Search Teams...',
    'Projects.Modal.Text.SessionSize': 'Allowed session size equals to {maxLearners}. Select particular Learners',
    'Projects.Modal.Placeholder.SearchLearners': 'Search Learners',
    'MursionPortal.ErrorMessage.MaxLearners': 'You can only add up to {maxLearners} learners for this type of session.',
    'MursionPortal.ErrorMessage.MaxLearner': 'You can only add up to {maxLearners} learner for this type of session.',
    'MursionPortal.Label.ChooseMembers': 'Choose members',
    'MursionPortal.Message.NoMembersToSelect': 'No members to select',
    'MursionPortal.Message.PleaseSelectATeamFirst': 'Please select a team first',
    'AssetSync.Header.AssetSync': 'Asset Sync',
    'AssetSync.Text.AssetsSyncedSuccessfully': 'Assets have been synced successfully',
    'AssetSync.Button.Sync': 'Sync',
    'AssetSync.Button.TryAgain': 'Try again',
    'AssetSync.SyncButton.Text.Syncing': 'Syncing',
    'SessionFlow.Label.SessionExport': 'Session Export',
    'MursionPortal.Button.Export': 'Export',
    'SessionFlow.Label.SessionFlowData': 'Session Flow Data',
    'SessionFlow.Button.LoadSessionData': 'Load session data',
    'SessionFlow.Button.StartSessionAnalytics': 'Start session analytics',
    'SessionFlow.Label.Checklist': 'Checklist',
    'SessionFlow.Label.RecordingSettings': 'Recording Settings',
    'SessionFlow.Label.SessionVideoFact': 'Session Video fact',
    'SessionFlow.Label.UserStatus': 'User Status',
    'SessionFlow.SessionVideoFact.ColumnHeader.VideoAllowed': 'Video Allowed',
    'MursionPortal.Table.ColumnHeader.UserName': 'User Name',
    'MursionPortal.Table.ColumnHeader.Email': 'Email',
    'MursionPortal.Table.ColumnHeader.Status': 'Status',
    'SessionFlow.StartSessionAnalyticsButton.Text.EventStartingAnalytics': 'The event to start the analytics process has been successfully published',
    'SessionFlow.RecordingSettings.Label.VideoRecording': 'Video Recording:',
    'SessionFlow.RecordingSettings.Text.Allowed': 'Allowed',
    'SessionFlow.RecordingSettings.Text.Disallowed': 'Disallowed',
    'SessionFlow.RecordingSettings.Label.RecordingPermission': 'Recording permission:',
    'SessionFlow.RecordingSettings.Text.LiesWithLearner': 'Lies with learner',
    'SessionFlow.RecordingSettings.Label.RecordedVideosGoBackTo': 'Recorded videos go back to:',
    'SessionFlow.Error.CannotFindSession': 'Cannot find a session with the provided id',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Type': 'Type',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Date': 'Date',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Error': 'Error',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Payload': 'Payload',
    'SessionFlow.SessionFlowData.PlaceHolder.SessionID': 'Session ID',
    'SessionFlow.Button.HideDetailedEventLog': 'Hide detailed event log...',
    'SessionFlow.Button.ShowDetailedEventLog': 'Show detailed event log...',
    'SessionFlow.Button.HideSessionHistory': 'Hide session history...',
    'SessionFlow.Button.ShowSessionHistory': 'Show session history...',
    'SessionFlow.Text.NoEvents': 'No events',
    'SessionFlow.SessionVideoFact.Label.VideoExists': 'Video Exists:',
    'SessionFlow.Text.NoLearners': 'No learners',
    'SessionFlow.Checklist.DataRecording': 'data recording',
    'SessionFlow.Checklist.DataUploading': 'data uploading',
    'SessionFlow.Checklist.Analytics': 'analytics',
    'Companies.ArchiveIconButton.Modal.ConfirmationText.ArchiveCompany': 'Are you sure you want to archive this company?',
    'Companies.ArchiveIconButton.Modal.Header.ArchiveCompany': 'Archive Company',
    'Companies.Label.CompanyInfo': 'Company Info',
    'Companies.CompanyInfo.Archived': '(Archived)',
    'Companies.CancelIconButton.HoverTitle.CancelEdit': 'Cancel Edit',
    'Companies.EditIconButton.HoverTitle.EditCompany': 'Edit Company',
    'Companies.Button.InviteProfService': 'Invite Prof Service',
    'Companies.InviteProfServiceButton.Modal.Header.NewProfService': 'New Prof Service',
    'Companies.Label.Config': 'CONFIG',
    'Companies.EditIconButton.HoverTitle.EditConfig': 'Edit Config',
    'Companies.Config.Button.Test': 'Test',
    'Companies.Config.Button.SaveCompanyConfig': 'Save company config',
    'Companies.CompanyInfo.Button.SaveCompanyInfo': 'Save company info',
    'Companies.ValidationError': 'There are validation errors',
    'Companies.Config.SaveCompanyConfigButton.FailedToSaveConfig': 'Failed to save a config. Please, fix the errors.',
    'Companies.Config.Text.TestPassedSuccessfully': 'Test passed successfully',
    'Companies.CreateUserButton.PlaceHolder.CompanyName': 'Company Name',
    'Companies.Button.CreateCompany': 'Create Company ',
    'Companies.Error.NoCompanies': 'No Companies',
    'Companies.InviteProfServiceButton.Modal.Label.ProfServices': 'Prof Services',
    'Companies.InviteProfServiceButton.Modal.Button.AddUser': 'Add user ',
    'Companies.CreateUserButton.Modal.Header.NewCompany': 'New Company',
    'Companies.Header.AllCompanies': 'all companies',
    'MursionPortal.Roles': 'Roles',
    'Users.ArchiveButton.Modal.Header.ArchivedGlobalUser': 'Archived Global User',
    'Users.ActiveButton.Modal.Header.EditGlobalUser': 'Edit Global User',
    'Users.CreateUserButton.Modal.Header.NewGlobalUser': 'New Global User',
    'Users.NoGlobalUserFoundError': 'No global users have been found.',
    'Users.Table.ColumnHeader.Department': 'Department',
    'MursionPortal.TechSupport.HoverTitle.ShowFeedBack': 'show feedback',
    'MursionPortal.TechSupport.HoverTitle.HideFeedBack': 'hide feedback',
    'MursionPortal.TechSupport': 'Tech Support',
    'MursionPortal.TechSupport.Header.ContactSupport': 'Contact Support',
    'MursionPortal.TechSupport.Label.Phone': 'Phone:',
    'MursionPortal.TechSupport.Label.Email': 'Email:',
    'MursionPortal.TechSupport.Label.Hours': 'Hours:',
    'MursionPortal.TechSupport.Link.MursionSupport': 'Mursion Support',
    'Downloads.Text.ClickDownloadButton': 'Click the download button below to obtain the latest version of the installer. This executable will be saved to your default browser downloads location (e.g. downloads folder).',
    'Downloads.Text.OnceDownloadIsComplete': 'Once download is complete, please click the downloaded executable and follow the on-screen prompts to complete installation.',
    'Downloads.Text.AppInstalled': 'With the app installed, you can double-click the Mursion icon the next time you need to launch it.',
    'Downloads.Button.Download': 'Download',
    'Downloads.Text.PlatformNotCurrentlySupported': 'Sorry, but your platform is not currently supported.',
    'Downloads.Text.FollowOnScreenInstructions': 'Click {info} and follow the on-screen instructions to install the application',
    'Downloads.Text.ShortcutCreatedOnInstallationComplete': 'Once installation is complete, a shortcut to Mursion will be created on your desktop. {lineBreak} You may double-click {image} shortcut when you need to launch the application to experience a scheduled simulation.',
    'Filters.Date.LastWeek': 'Last Week',
    'MursionPortal.Text.Mins': 'mins',
    'MursionPortal.Text.Min': 'min',
    'Tutorial.Header.ManageYourTeams': 'Manage Your Teams',
    'MursionPortal.Button.Awesome': 'Awesome',
    'Tutorial.Header.ReviewYourProjects': 'Review Your Projects',
    'MursionPortal.Button.LooksGood': 'Looks Good',
    'Tutorial.Header.LearnerScheduling': 'Learner Scheduling',
    'MursionPortal.Button.AllDone': 'All Done!',
    'Tutorial.Header.InviteYourUsers': 'Invite Your Users',
    'MursionPortal.Button.GotIt': 'Got It',
    'Tutorial.Header.CreateATeam': 'Create A Team',
    'Tutorial.Header.ManageYourProjects': 'Manage Your Projects',
    'Tutorial.Header.YourTeams': 'Your Teams',
    'Tutorial.Header.YourProjects': 'Your Projects',
    'Tutorial.Header.SessionCalendar': 'Session Calendar',
    'Tutorial.Tooltip.TeamsOrganize': 'Teams organize your learners, and will be added to scenarios',
    'Tutorial.Text.ViewSessions': 'View the team\'s <code>upcoming & completed</code> sessions',
    'Tutorial.Tooltip.ContactManager': 'or contact your {companyName} Project Manager',
    'Tutorial.Tooltip.YourPointOfContact': 'Your point-of-contact for everything learning',
    'Tutorial.Text.TeamAdded': 'A team will be <code>added to a scenario</code> to begin scheduling',
    'Tutorial.Text.ViewLearners': 'View learners\' <code>scheduling activity</code> & <code>status</code>',
    'Tutorial.Text.EnterEmail': 'Enter an <code>email address</code> for each new {companyName} Portal user',
    'Tutorial.Text.RegistrationEmail': 'A <code>registration email</code> will be sent to their inbox',
    'Tutorial.Text.TeamsOrganize': 'Teams <code>organize your Learners</code> & are managed by a Facilitator',
    'Tutorial.Text.UniqueName': 'Give your team a <code>unique name</code> & enter a location',
    'Tutorial.Text.SessionDelivery': 'Later, a team will be <code>added to a scenario</code> for <code>session delivery</code>',
    'Tutorial.Tooltip.Description': 'Description, outcome, and strategies',
    'Tutorial.Text.BeginScheduling': 'A team must be <code>added to a scenario</code> to begin scheduling',
    'Tutorial.Text.MonitorSessions': 'Monitor each project\'s <code>upcoming sessions</code> & <code>availability</code>',
    'Tutorial.Text.LearnersWithoutSessions': 'View learners who have <code>not yet scheduled</code> a session',
    'Tutorial.Tooltip.LearnersSchedule': 'Learners can schedule themselves as well',
    'Tutorial.Text.AddedToLearners': 'You will be added to <code>1 or more</code> teams of learners',
    'Tutorial.Tooltip.TeamAdmin': 'Your team admin and point-of-contact',
    'Tutorial.Text.TeamAddedBeforeProject': 'Your team will be <code>added to a scenario</code> before the project begins',
    'Tutorial.Text.AddRemoveLearners': '<code>Add or remove</code> learners from your <tooltip>teams</tooltip>',
    'Tutorial.Text.AddedByCompany': 'You will be added to a scenario/project by your company <tooltip>Account Owner</tooltip>',
    'Tutorial.Text.ViewYourHours': 'View your hours, scenarios, or contact your <tooltip><code>Project Manager</code></tooltip>',
    'Tutorial.Text.ScenariosDetail': 'Scenarios contain the <tooltip>details</tooltip> that drives your <code>{companyName} experience</code>',
    'Tutorial.Text.SelectTimeSlot': 'Select an open timeslot to <tooltip><code>schedule a learner</code></tooltip> for a session',
    'Tutorial.Text.ChooseRole': 'Choose a role (<tooltip>Account Owner</tooltip> <secondTooltip>Facilitator</secondTooltip> or <thirdTooltip>Learner</thirdTooltip>) for each new user',
    'Tutorial.Tooltip.AdminManages': 'Admin who manages users and projects',
    'Tutorial.Tooltip.ManagesScheduling': 'Manages scheduling and session delivery',
    'Tutorial.Tooltip.SchedulesSessions': 'Schedules and attends sessions',
    'Tutorial.Text.ScenariosContain': 'Scenarios contain the <tooltip>details</tooltip> that drives your <code>{companyName} experience</code>',
    'Tutorial.Text.SelectOpenTimeSlot': 'Select an open timeslot to <tooltip><code>schedule a learner</code></tooltip>',
    'Tutorial.Text.AdminOfTeam': 'The <tooltip>Facilitator</tooltip> is the admin & point-of-contact for your team',
    'Tutorial.Text.ProjectsContain': 'Projects contain <code>scenarios</code>, with the <tooltip>details</tooltip> that drives your <code>learning experience</code>',
    'Tutorial.Text.ViewAvailability': 'View availability & <code>schedule sessions</code>',
    'Tutorial.Text.TrackSessions': 'Track <code>upcoming sessions</code> for each project. Cancel/reschedule if needed',
    'Tutorial.Text.ReviewPerformance': 'Review your <code>performance</code> & <code>strategies completion</code> after your session',
    'Tutorial.Header.Complete': 'Complete',
    'Tutorial.Text.RevisitThisGuide': 'You can revisit this guide and other helpful info {lineBreak} <code>anytime</code> from the <code>Knowledge Base. </code>',
    'Tutorial.Button.TakeMeToThePortal': 'Take me to the Portal',
    'Tutorial.Header.DownloadInstallMursionSimulator': 'Download and Install {lineBreak} the Mursion Simulator',
    'Tutorial.Text.PlanningToUseMursionOnComputer': 'If you plan on using this computer to run Mursion simulations, we highly recommend installing the necessary software now by clicking the "Install Mursion" button below.',
    'Tutorial.Text.InstallSoftwareFromPortal': 'You may chose to install the software later directly from the portal. Please keep in mind that Mursion\'s immersive simulator may require several minutes to install for first time use. Please plan to install the software at least 30 minutes before your simulation begins to allow sufficient time to fully enjoy the Mursion experience.',
    'Tutorial.Text.ProvideInstructionsForInstallation': 'We will continue to provide instructions for installation and other workflows from time to time via emails and tutorials within Mursion\'s portal and simulator. We hope you enjoy you simulations as much as we enjoy creating them!',
    'Tutorial.Text.TheMursionTeam': 'The Mursion Team.',
    'Tutorial.Button.InstallLater': 'Install Later',
    'Tutorial.Button.InstallMursion': 'Install Mursion',
    'Tutorial.Header.LetsGetStarted': 'Let\'s Get Started',
    'Tutorial.Text.ShortIntro': 'First, here\'s a <code>short intro</code> to showcase some of {lineBreak} the common functions of <code>your user role</code>:',
    'Tutorial.Button.Proceed': 'Proceed',
    'Tutorial.Label.Step': 'STEP:',
    'Tutorial.Label.NoSound': 'This video does not have sound.',
    'Tutorial.Button.SkipTutorial': 'Skip Tutorial',
    'Tutorial.Button.CloseTutorial': 'Close Tutorial',
    'MursionPortal.Text.On': 'On',
    'MursionPortal.Text.Off': 'Off',
    'MursionPortal.Text.MursionIcon': 'mursion-icon',
    'MursionPortal.AriaLabel.CreateNew': 'create new',
    'MursionPortal.Legend.VisibilityHidden.Buttons': 'Create new, Repeat client, Repeat Bank scenario buttons',
    'MursionPortal.Label.VisibilityHidden.EnterEmailId': 'Enter email id',
    'MursionPortal.Label.VisibilityHidden.SelectRegistrationStatus': 'Select Registration Status',
    'MursionPortal.Label.VisibilityHidden.ClientLogoUpload': 'client logo upload',
    'MursionPortal.Header.VisibilityHidden.CompanyUsers': 'Company Users',
    'MursionPortal.Legend.VisibilityHidden.StationType': 'Station Type',
    'MursionPortal.Legend.VisibilityHidden.Hours': 'Hours',
    'MursionPortal.Caption.VisibilityHidden.CompanyUsersList': 'company users list',
    'MursionPortal.AriaLabel.Search': 'search',
    'MursionPortal.AriaLabel.From': 'From',
    'MursionPortal.AriaLabel.To': 'To',
    'MursionPortal.AriaLabel.InboxMessagePanel': 'inbox message panel',
    'MursionPortal.AriaLabel.Breadcrumb': 'breadcrumb',
    'MursionPortal.Message.NoItemsHaveBeenFound': 'No items have been found.',
    'MursionPortal.AriaLabel.Add': 'add',
    'MursionPortal.AriaLabel.SortByDescendingOrder': 'sort by descending order',
    'MursionPortal.AriaLabel.SortByAscendingOrder': 'sort by ascending order',
    'MursionPortal.AriaLabel.AddButton': 'add button',
    'MursionPortal.AriaLabel.Timezone': 'timezone',
    'MursionPortal.AriaLabel.Combobox': 'combobox',
    'MursionPortal.AriaLabel.PreviousMonth': 'previous month',
    'MursionPortal.AriaLabel.NextMonth': 'next month',
    'MursionPortal.AriaLabel.SearchInput': 'search input',
    'MursionPortal.AriaLabel.Informational': 'Informational',
    'MursionPortal.Text.RedirectToCalendarPage': 'redirect to calendar page',
    'MursionPortal.AriaLabel.StartDate': 'startdate {startFormattedDate}',
    'MursionPortal.AriaLabel.EndDate': ' enddate {endFormattedDate}',
    'MursionPortal.AriaLabel.CreateProject': 'create project',
    'MursionPortal.AriaLabel.ContentInfo': 'content info',
    'MursionPortal.AriaLabel.DraftSuperScript': 'Draft superscript {statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedSuperScript': 'Finalized superscript {statusConfiguration}',
    'MursionPortal.AriaLabel.StartedSuperScript': 'Started superscript {statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedSuperScript': 'Finished superscript {statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedSuperScript': 'Archived superscript {statusConfiguration}',
    'MursionPortal.AriaLabel.EnterScenarioName': 'enter scenario name',
    'MursionPortal.AriaLabel.Capitalized.Search': 'Search',
    'MursionPortal.AriaLabel.Close': 'Close',
    'MursionPortal.AriaLabel.CreateTeam': 'create team',
    'MursionPortal.AriaLabel.CreateClient': 'create client',
    'MursionPortal.AriaLabel.CreateUser': 'create user',
    'MursionPortal.AriaLabel.ZoomId': 'Zoom ID',
    'MursionPortal.AriaLabel.PrimaryContent': 'Primary Content',
    'MursionPortal.AriaLabel.RegistrationStatus': 'registration status',
    'MursionPortal.AriaLabel.EnterTagName': 'enter tag name',
    'MursionPortal.AriaLabel.EnterUserName': 'enter user name',
    'MursionPortal.AriaLabel.SearchProjectManagers': 'search project managers',
    'MursionPortal.AriaLabel.SearchSSO': 'search SSO',
    'MursionPortal.AriaLabel.SearchAccountOwner': 'search account owner',
    'MursionPortal.WarningMessage.AllowedCharacterLimit': 'Allowed character limit ({maximumLength} characters) has been exceeded',
    'MursionPortal.WarningMessage.InvalidCharactersDetected': 'Invalid characters detected',
    'MursionPortal.Days.Sunday': 'Sunday',
    'MursionPortal.Days.Monday': 'Monday',
    'MursionPortal.Days.Tuesday': 'Tuesday',
    'MursionPortal.Days.Wednesday': 'Wednesday',
    'MursionPortal.Days.Thursday': 'Thursday',
    'MursionPortal.Days.Friday': 'Friday',
    'MursionPortal.Days.Saturday': 'Saturday',
    'MursionPortal.Button.AddNew': 'Add new',
    'MursionPortal.Title.EditNotes': 'Edit Notes',
    'MursionPortal.IconButton.Remove': 'remove',
    'MursionPortal.Filter.ProviderAll': 'Provider',
    'MursionPortal.Placeholder.SearchProviders': 'Search Providers...',
    'MursionPortal.Placeholder.ProvidersValue': 'Select a Provider',
    'MursionPortal.AriaLabel.InboxButton': 'inbox button you have {messageCount} new messages',
    'MursionPortal.AriaLabel.Event': 'event',
    'MursionPortal.Title.SelectedDate': 'Selected date',
    'MursionPortal.Title.CurrentDate': 'Current date',
    'MursionPortal.Title.OffRangeDate': 'off range date',
    'MursionPortal.Label.ClientLevel': 'Client-level',
    'MursionPortal.Label.TeamLevel': 'Team-level',
    'MursionPortal.AltText.UserImage': 'user-image',
    'MursionPortal.AltText.KbMenu': 'kb-menu',
    'MursionPortal.AltText.AvatarImage': 'avatar-image',
    'MursionPortal.AltText.EnvironmentImage': 'environment-image',
    'MursionPortal.AriaLabel.Cancel': 'cancel',
    'MursionPortal.AriaLabel.Edit': 'edit',
    'MursionPortal.AriaLabel.EditAccountOwner': 'edit account owner',
    'MursionPortal.AriaLabel.RemoveAccountOwner': 'remove account owner',
    'MursionPortal.AriaLabel.Remove': 'remove',
    'MursionPortal.AriaLabel.SearchFilter': 'search filter',
    'MursionPortal.AriaLabel.TierCombobox': 'tier combobox',
    'MursionPortal.AriaLabel.SortByAscending': 'sort by ascending',
    'MursionPortal.AriaLabel.SortByDescending': 'sort by descending',
    'MursionPortal.Message.VisibilityHidden.SelectingDeliveryMode': 'selecting a delivery mode will allow setting session length and setting session size',
    'MursionPortal.AltText.DownloadComputerIcon': 'Mursion download computer icon',
    'MursionPortal.AltText.DownloadIcon': 'Mursion download icon',
    'MursionPortal.AltText.MursionLogo': 'Mursion logo',
    'MursionPortal.AriaLabel.MursionSupport': 'mursion support',
    'MursionPortal.AltText.MursionDashboard': 'Mursion-Dashboard',
    'MursionPortal.AltText.MursionPortal': 'Mursion Portal',
    'MursionPortal.AltText.BackgroundMaleUser': 'Mursion background male user',
    'MursionPortal.AltText.BackgroundGroupUser': 'Mursion background group user',
    'MursionPortal.AltText.BackgroundFemaleUser': 'Mursion background female user',
    'MursionPortal.AriaLabel.ToggleMenu': 'toggle menu',
    'MursionPortal.ClientListItem.RedirectToUserDetails': 'Redirect to {name} details',
    'MursionPortal.Caption.ScenarioBankUsersList': 'scenario bank user list',
    'MursionPortal.Caption.SsoConfigurationList': 'sso configuration list',
    'MursionPortal.AriaLabel.DateRangeCalendarButton': 'press enter to interact with the calendar and select the start and end date',
    'MursionPortal.AriaLabel.PreviousWeek': 'previous week',
    'MursionPortal.AriaLabel.PreviousDay': 'previous day',
    'MursionPortal.AriaLabel.NextWeek': 'next week',
    'MursionPortal.AriaLabel.NextDay': 'previous day',
    'Dashboard.LeftPane.CategoryHeader.SessionsDashboard': 'Sessions dashboard',
    'MursionPortal.Title.SessionsPage': 'Sessions page',
    'Clients.heading.Clients': 'Clients',
    'MursionPortal.AriaLabel.Client': 'client',
    'MursionPortal.AriaLabel.SimSpecialist': 'SimSpecialist',
    'MursionPortal.Project.TimeZoneStartDate': 'Time zone start date',
    'MursionPortal.Project.TimeZoneEndDate': 'Time zone end Date',
    'MursionPortal.AriaLabel.SelectTimezoneCombobox': 'select time zone combo box',
    'MursionPortal.ErrorMessage.CharacterLimitExceeded': 'Allowed character limit ({maximumLength} characters) has been exceeded',
    'MursionPortal.ErrorMessage.InvalidCharactersDetected': 'Invalid characters detected',
    'MursionPortal.ErrorMessage.EnterValidPhoneNumber': 'You must enter a valid phone number.',
    'MursionPortal.Message.InvitationLinkInvalid': 'Your invitation link is invalid or might have expired. {lineBreak} If you have not finished registration yet {lineBreak} please ask your manager for a re-invitation.',
    'MursionPortal.Header.SetYourPassword': 'Set Your Password',
    'MursionPortal.Message.PasswordMessage': 'Your password must contain the following:',
    'MursionPortal.Label.NewPassword': 'New Password',
    'MursionPortal.Label.NewChangePassword': 'Type your new password',
    'MursionPortal.Label.OldPassword': 'Type your current password',
    'MursionPortal.Label.RepeatChangePassword': 'Retype your new password',
    'MursionPortal.Label.RepeatPassword': 'Repeat Password',
    'MursionPortal.Button.SetPassword': 'Set Password',
    'MursionPortal.Header.ErrorMessage.SomethingWentWrong': 'Something went {lineBreak} wrong, please {lineBreak} contact support',
    'MursionPortal.Header.ContactSupport': 'CONTACT SUPPORT',
    'MursionPortal.Label.Phone': 'Phone:',
    'MursionPortal.Label.Email': 'Email:',
    'MursionPortal.Label.Hours': 'Hours:',
    'MursionPortal.Message.EnterPasswordToConfirm': 'Please enter your password to confirm this change',
    'MursionPortal.Label.Password': 'Password',
    'MursionPortal.Message.ClickToOpenApplicationDefault': 'Click below to attend your simulation', // TODO: add translations
    'MursionPortal.Message.ClickToOpenApplicationDesktopWeb': 'Click the button below to access your simulation via the Mursion desktop application:', // TODO: add translations
    'MursionPortal.Message.OpenWebApplication': 'You can also access your simulation in Mursion Magic, our web-based application:', // TODO: add translations
    'MursionPortal.Message.BrowserDialog': 'If you don’t see a browser dialog, please click <code>Open Mursion</code> below {lineBreak} or use the Mursion desktop shortcut to open the Mursion application.',
    'MursionPortal.Button.OpenMursion': 'Open ML3 Desktop App',
    'MursionPortal.Button.OpenMursionWeb': 'Launch Mursion Magic',
    'MursionPortal.Message.Or': 'OR',
    'MursionPortal.Message.FollowInstructions': 'Click {appName} and follow the on-screen instructions to install the application',
    'MursionPortal.Text.PositiveImpact': 'When you feel you were having a positive impact, click on the <code>{icon}</code> button.',
    'MursionPortal.Text.NegativeImpact': 'When you feel you were having a negative impact, click on the <code>{icon}</code> button.',
    'MursionPortal.Text.NeutralImpact': 'When you feel your impact was neither positive nor negative, click on the <code>{icon}</code> button.',
    'MursionPortal.Title.Next': 'Next',
    'MursionPortal.Title.Start': 'Start',
    'MursionPortal.Text.UpdateYourRating': 'As you watch the video, you may update your rating at any time that you think you are having a different impact on the avatar.',
    'MursionPortal.Text.SeeCurrentRating': 'The graph below the video will update so that you can see your current rating and change it if you think your impact has changed.',
    'MursionPortal.Text.ClickStartToBegin': 'Click "Start" to begin. You may click "Complete" after you have rated the entire simulation.Please note that video seek functionality may be disabled during parts of this video to preserve data integrity.',
    'MursionPortal.Header.HowWouldYouProceed': 'How would you like to proceed?',
    'MursionPoral.Button.SwitchUser': 'Switch User',
    'MursionPortal.Button.ContinueAs': 'Continue as <code>{userName}</code>',
    'MursionPortal.Text.ToContinue': 'To continue as <code>{loginData}</code> please click <code>Switch User</code>.',
    'MursionPortal.Message.YouAreSignedIn': 'You are currently signed in as <code>{userName}</code> ({userProfile}).',
    'MursionPortal.Title.ContinueAs': 'Continue as {userName}',
    'MursionPortal.Message.OpenLink': 'Open link',
    'MursionPortal.Message.Allow': 'Allow',
    'MursionPortal.Message.Open': 'Open',
    'MursionPortal.Message.Yes': 'Yes',
    'MursionPortal.Message.OpenMursionApp': 'Open MursionApp',
    'MursionPortal.PasswordRequirementMessage.MinimumCharacters': 'at least {PASSWORD_MIN_LENGTH} characters',
    'MursionPortal.Message.PortalHeading': 'Design, schedule and manage your',
    'MursionPortal.Message.PortalHeadingText': 'EQ training simulations',
    'MursionPortal.PasswordRequirementMessage.UppercaseLetters': 'uppercase + lowercase letters',
    'MursionPortal.PasswordRequirementMessage.Numbers': 'any combination of numbers',
    'MursionPortal.PasswordRequirementMessage.SpecialCharaters': 'at least one special character',
    'MursionPortal.ErrorMessage.InvalidEmailFormat': 'Invalid email format.',
    'MursionPortal.ErrorMessage.EmailCanNotBeEmpty': 'Email can not be empty.',
    'MursionPortal.ErrorMessage.EnterValidEmailAddress': 'Please enter a valid email address.',
    'MursionPortal.ErrorMessage.PasswordCannotBeEmpty': 'Password can not be empty',
    'MursionPortal.ErrorMessage.UrlCannotBeEmpty': 'Url can not be empty',
    'MursionPortal.ErrorMessage.UrlIsNotValid': 'Url is not valid',
    'MursionPortal.ErrorMessage.NoMeetingId': 'Does not contain meeting id',
    'MursionPortal.ValidationErrorMessage.FileRequestFailed': 'File requiest failed',
    'MursionPortal.ValidationErrorMessage.FileExceedsLimit': 'File exceeds 5MB limit',
    'MursionPortal.ValidationErrorMessage.EnterMessage': 'Please enter your message before submitting',
    'MursionPortal.ValidationErrorMessage.MessageTooLong': 'Message too long',
    'MursionPortal.ValidationErrorMessage.InvalidUrl': 'File url is not valid',
    'MursionPortal.Button.SubmitFeedback': 'Submit Feedback',
    'MursionPortal.Header.Feedback': 'Feedback',
    'MursionPortal.Text.AdditionalComments': 'Additional comments or suggestions',
    'MursionPortal.Header.ThanksForFeedback': 'Thanks for your Feedback!',
    'MursionPortal.Button.Close': 'Close',
    'MursionPortal.Title.ShowFeedback': 'show feedback',
    'MursionPortal.Title.HideFeedback': 'hide feedback',
    'MursionPortal.ErrorMessage.FileTypeNotSupported': 'File type {type} is not supported',
    'MursionPortal.ErrorMessage.ScenarioDuration': 'Scenario duration must be between {durationMinimum} and {durationMaximum}.',
    'MursionPortal.ErrorMessage.ScenarioScheduledSessions': 'There are {sessionsCount} scheduled sessions for this scenario. Cancel these sessions before archiving this scenario.',
    'MursionPortal.ErrorMessage.ScenarioScheduledSession': 'There is {sessionsCount} scheduled session for this scenario. Cancel this session before archiving this scenario.',
    'MursionPortal.ErrorMessage.ClientScheduledSessions': 'There are {sessionsCount} scheduled sessions for this client. Cancel these sessions before archiving the client.',
    'MursionPortal.ErrorMessage.ClientScheduledSession': 'There is {sessionsCount} scheduled session for this client. Cancel this session before archiving the client.',
    'MursionPortal.EulaAgreement.EndUserAgreement': 'This End User Agreement (“Agreement”) is a legal agreement between you, the user, and Mursion, Inc. (“Mursion”) and is subject to the terms and conditions of the Mursion Software as a Service Agreement between Mursion and the Mursion client entity through which you are using the Mursion Software.  For purposes of clarity, you acknowledge and agree that as a condition of your use of and access to the Mursion Software (“Mursion Software”), you, as well as the entity that is providing you access to the Mursion Software, are subject to and bound by the terms and conditions of this Agreement.',
    'MursionPortal.EulaAgreement.TermsAndConditions': 'Subject to the terms and conditions of this Agreement and the Mursion Software as a Service Agreement, Mursion hereby permits you limited, non-exclusive, non-transferrable, non-sublicensable rights to access and use the Mursion Software.',
    'MursionPortal.EulaAgreement.AgreeAndAcknowledge': 'You agree and acknowledge that you do not have the right to, and will not, copy, modify, reverse engineer, decompile, disassemble, or create derivative works based, in whole or in part, on the Mursion Software or related materials.  You agree not to defeat, disable or circumvent any protection mechanism related to the Mursion Software.  Upon learning of any unauthorized possession or use of or access to the Mursion Software, you will notify Mursion promptly and you further agree to promptly furnish details of each such occurrence, to reasonably assist in preventing any recurrence thereof (to the extent that is possible) and to cooperate fully in any proceedings undertaken to protect the rights of Mursion.',
    'MursionPortal.EulaAgreement.ConstruedAsGranting': 'Other than as explicitly stated herein, nothing contained in this Agreement shall be construed as granting, by implication or otherwise, any additional rights to Mursion’s intellectual property without Mursion’s prior written approval.',
    'MursionPortal.EulaAgreement.NonDisclosure': 'You agree not to show or disclose Mursion Software or any part thereof to anyone for any purpose other than as expressly permitted herein.',
    'MursionPortal.EulaAgreement.AgreeAndRelease': 'You agree to, and do hereby, release, discharge and otherwise hold harmless Mursion, its employees, officers, agents, affiliates, officers and directors from any and all claims, known and unknown, whenever arising, that result from, arise out of, or otherwise relate to your use of Mursion Software.',
    'MursionPortal.EulaAgreement.UnderstandAndAcknowledge': 'You understand and acknowledge that Mursion may collect, maintain, use, store, and analyze information and data collected from your use of the Mursion Software, subject to the terms of Mursion’s privacy policy which can be found here: <code>https://www.mursion.com/privacynotice/</code>.',
    'MursionPortal.EulaAgreement.AccessRights': 'The use and access rights granted to you by Mursion shall terminate immediately when the Mursion Software as a Service Agreement, by which access to the Mursion Software is provided to you, expires or is terminated, or immediately upon a breach by you of any provision of this Agreement.',
    'MursionPortal.EulaAgreement.AgreementGoverned': 'This Agreement shall be governed and construed in accordance with the laws of the State of Delaware without regard to the conflicts of laws principles thereof. All disputes arising out of or related to your use of the Mursion Software or this agreement shall be brought in a state or federal court of competent jurisdiction in the State of Delaware and you hereby consent to personal jurisdiction in such courts.',
    'MursionPortal.EulaAgreement.ProvisionsDeclaredInvalid': 'If any provisions of this Agreement are declared to be invalid, such provisions shall be severed from this Agreement and other provisions herein shall remain in full force and effect.',
    'MursionPortal.EulaAgreement.AgreementConflict': 'In the event the terms of this Agreement conflict with the terms of the Mursion Software as a Service Agreement, the terms contained herein shall govern.',
    'MursionPortal.EulaAgreement.AccessToMursionSofware': 'Your use of or access to the Mursion Software constitutes agreement to this Agreement and the terms and conditions stated herein.',
    'MursionPortal.Header.MursionUserAgreement': 'Mursion & User Agreement',
    'MursionPortal.Button.Back': 'Back',
    'MursionPortal.Button.Skip': 'Skip',
    'MursionPortal.Title.BackToSessions': 'Back to Sessions',
    'MursionPortal.Title.Proceed': 'Proceed',
    'MursionPortal.Message.SkipActivity': 'If you choose to skip, you will lose the preformance insights that may be provided by our behavioral analytics engine.We recommend that you do not skip this activity.',
    'MursionPortal.HeaderTitle.ConfirmCancellation': 'Confirm Cancellation',
    'MursionPortal.Label.Delete': 'delete',
    'MursionPortal.Label.Restore': 'restore',
    'MursionPortal.HeaderTitle.ConfirmRestore': 'Confirm Restore',
    'MursionPortal.Label.Archive': 'archive',
    'MursionPortal.Button.CancelTraining': 'Cancel training',
    'MursionPortal.Button.No': 'No',
    'MursionPortal.Button.CancelEvent': 'Cancel event',
    'MursionPortal.Label.Update': 'update',
    'MursionPortal.HeaderTitle.ConfirmUpdate': 'Confirm Update',
    'MursionPortal.Button.UpdateSession': 'Update session',
    'MursionPortal.Label.Swap': 'swap',
    'MursionPortal.HeaderTitle.ConfirmAcceptedSwap': 'Confirm Accepted Swap',
    'MursionPortal.Button.AcceptSwap': 'Accept swap',
    'MursionPortal.Label.Reason': 'Reason',
    'MursionPortal.Placeholder.ReasonForSwapping': 'Please, add a reason for swapping.',
    'MursionPortal.Label.Request': 'request',
    'MursionPortal.HeaderTitle.ConfirmAcceptedRequest': 'Confirm Accepted Request',
    'MursionPortal.Button.AcceptRequest': 'Accept request',
    'MursionPortal.Placeholder.ReasonForAccepting': 'Please, add a reason for accepting.',
    'MursionPortal.Button.ConfirmProjectChange': 'Confirm Project Change',
    'MursionPortal.HeaderTitle.Confirm': 'Confirm',
    'MursionPortal.Button.ConfirmCreateSession': 'Confirm Create Session',
    'MursionPortal.Placeholder.ReasonForCreation': 'Please, add a reason for creation.',
    'MursionPortal.Button.ConfirmAssigningSimSpecialist': 'Confirm Assigning Sim Specialist',
    'MursionPortal.Placeholder.ReasonForAssigning': 'Please, add a reason for assigning.',
    'MursionPortal.Button.ConfirmChangingSimSpecialist': 'Confirm Changing Sim Specialist',
    'MursionPortal.Placeholder.ReasonForChanging': 'Please, add a reason for changing.',
    'MursionPortal.HeaderTitle.ConfirmTimeZone': 'Confirm time zone',
    'MursionPortal.Message.DontHaveMursionInstalled': 'Don\'t have ML3 Desktop App installed? Click <code>Download</code> to install.',
    'MursionPortal.ErrorMessage.CannotContainCharacter': 'Scenario name should not contain "_" character',
    'MursionPortal.ErrorMessage.EnterDifferentName': '{capitalized} {fieldName} currently exists. Please enter a different {objectName} {fieldName}.',
    'MursionPortal.ErrorMessage.EnterDifferentEmail': 'User email currently exists. Please enter a different email address.',
    'MursionPortal.ErrorMessage.NameExists': 'A user with the same {fieldName} already exists. Please enter a different {fieldName}.',
    'MursionPortal.ErrorMessage.UnableToRemove': 'Unable to remove {entity}. This {entity} is currently being used in an active {depEntity}.',
    'MursionPortal.ErrorMessage.OverlapsExistingTimeBlock': 'Selected time block overlaps an existing time block.',
    'MursionPortal.ErrorMessage.AccessIsDenied': 'Access is denied.',
    'MursionPortal.ErrorMessage.SomethingMustBeBroken': 'Something must be broken. Please try again later.',
    'MursionPortal.ErrorMessage.Unauthorized': 'Unauthorized',
    'MursionPortal.ErrorMessage.RefreshTokenNotFound': 'Refresh token not found',
    'MursionPortal.ErrorMessage.CannotRefreshToken': 'Cannot refresh token',
    'MursionPortal.ErrorMessage.UsersUnavailable': 'Some of your users are unavailable at the selected time.',
    'MursionPortal.QADialog.NoParticipants': 'No participants',
    'MursionPortal.ErrorMessage.SomethingWrongWithResponse': 'Something wrong with response.',
    'MursionPortal.ErrorMessage.AuthorizationHeaderIsEmpty': 'Authorization header is empty',
    'MursionPortal.ErrorMessage.CannotRefreshAccessToken': 'Cannot refresh access token',
    'MursionPortal.ErrorMessage.AuthorizationTokenIsEmpty': 'Authorization token is empty',
    'MursionPortal.ErrorMessage.AuthenticationFailed': 'Authentication failed.',
    'MursionPortal.ErrorMessage.UnsupportedResponseFormat': 'Unsupported response format.',
    'MursionPortal.ErrorMessage.AccountBlocked': 'Your account has been blocked until {blockedDate}. Try to log in after.',
    'MursionPortal.ErrorMessage.AttemptsRemaining': '{attempts} attempts remaining before a 24 hour lockout. If you need help, please reset your password.',
    'MursionPortal.ErrorMessage.ClientIdMustBeSpecified': 'Client id must be specified',
    'MursionPortal.ErrorMessage.ProjectIdMustBeSpecified': 'Project id must be specified',
    'MursionPortal.ErrorMessage.SessionIdMustBeSpecified': 'Session id must be specified',
    'MursionPortal.ErrorMessage.SSOIdMustBeSpecified': 'SSO id must be specified',
    'MursionPortal.ErrorMessage.LearnerIdMustBeSpecified': 'Learner id must be specified',
    'MursionPortal.ErrorMessage.UserIdMustBeSpecified': 'User id must be specified',
    'MursionPortal.ErrorMessage.TeamIdMustBeSpecified': 'Team id must be specified',
    'MursionPortal.ErrorMessage.AssetProjectIdMustBeSpecified': 'Asset project id must be specified',
    'MursionPortal.ErrorMessage.AssetSettingsIdMustBeSpecified': 'Asset settings id must be specified',
    'MursionPortal.ErrorMessage.ScenarioIdMustBeSpecified': 'Scenario id must be specified',
    'MursionPortal.ErrorMessage.ScenarioTemplateIdMustBeSpecified': 'Scenario template id must be specified',
    'MursionPortal.ErrorMessage.LicenseIdMustBeSpecified': 'fetchUsersList: Licensee id must be specified',
    'MursionPortal.ErrorMessage.LicenseCertificateIdMustBeSpecified': 'fetchLicenseeCertificates: Licensee id must be specified',
    'MursionPortal.ErrorMessage.LicenseConfigIdMustBeSpecified': 'fetchConfig: Licensee id must be specified',
    'MursionPortal.ErrorMessage.LicenseUpdateConfigIdMustBeSpecified': 'updateConfig: Licensee id must be specified',
    'MursionPortal.ErrorMessage.LicenseTestConfigIdMustBeSpecified': 'testConfig: Licensee id must be specified',
    'MursionPortal.ErrorMessage.LicenseFetchTiersIdMustBeSpecified': 'fetchTiers: Licensee id must be specified',
    'MursionPortal.ErrorMessage.LicenseUpdateTiersIdMustBeSpecified': 'updateTiers: Licensee id must be specified',
    'MursionPortal.ErrorMessage.GlobalUserIdMustBeSpecified': 'Global User id must be specified',
    'MursionPortal.ErrorMessage.ScenarioTemplateListLicenseIdMustBeSpecified': 'fetchScenarioTemplatesList: Licensee id must be specified',
    'MursionPortal.ErrorMessage.ScenarioAttachmentListIdMustBeSpecified': 'fetchScenarioAttachmentLink: scenario id and attachment id must be specified',
    'MursionPortal.ErrorMessage.FetchScenarioIdMustBeSpecified': 'fetchScenario: scenario id must be specified',
    'MursionPortal.Support.PhoneNumber': '1-855-999-5818',
    'MursionPortal.Message.LearnerEmpty': 'Please select the learners participated in the session',
    'Dashboard.ProjectsTable.StatusValue.Active': 'ACTIVE',
    'Dashboard.ProjectsTable.StatusValue.Completed': 'COMPLETED',
    'Dashboard.ProjectsTable.StatusValue.Archived': 'ARCHIVED',
    'MursionPortal.Button.Refresh': 'Refresh',
    'MursionPortal.Prompt.CalendarUpdated': 'The calendar has been updated.',
    'MursionPortal.Prompt.ClickRefresh': 'Click "Refresh" to view the changes.',
    'MursionPortal.Label.SessionAttendance': 'Session attendance ',
    'MursionPortal.Label.SessionVideoUrl': 'Session Video URL',
    'MursionPortal.Label.LearnerAttendance': 'Learner attendance',
    'MursionPortal.Text.UserProfileUpdated': 'A user profile has been updated!',
    'MursionPortal.Text.ClientUpdated': 'A client has been updated!',
    'MursionPortal.Text.ProjectUpdated': 'A project has been updated!',
    'MursionPortal.Text.ScenarioUpdated': 'A scenario has been updated!',
    'MursionPortal.Text.LicenseeConfigUpdated': 'Licensee config has been updated!',
    'MursionPortal.Prompt.TrainingScheduled': '<code>Training</code> has been scheduled!',
    'MursionPortal.Prompt.SessionScheduled': '<code>Session</code> has been cancelled!',
    'MursionPortal.SuccessDialog.Title': 'Success Dialog',
    'MursionPortal.Dialog.CreateSSOConfiguartion.SuccessMessage': 'Client SSO configuration has been created successfully.',
    'MursionPortal.Modal.Header.SessionTimeout': 'Session Timeout',
    'MursionPortal.Modal.Body.SessionTimeout': 'Your session will timeout in 1 minute, please continue to extend your session',
    'MursionPortal.Text.UserCreatedSuccessfully': 'User is created successfully',
    'MursionPortal.Text.UserNotCreated': 'User in not created',
    'MursionPortal.Text.AssignedTeams': 'Assigned teams',
    'MursionPortal.Client.EmailSettings.Invitation': 'Invitation',
    'MursionPortal.Client.EmailSettings.InvitationReminder': 'Invitation Reminder',
    'MursionPortal.Client.EmailSettings.Confirmation': 'Confirmation',
    'MursionPortal.Client.EmailSettings.Cancellation': 'Cancellation',
    'MursionPortal.Client.EmailSettings.FirstReminder': 'First Reminder',
    'MursionPortal.Client.EmailSettings.SecondReminder': 'Second Reminder',
    'MursionPortal.Client.EmailSettings.SessionRecording': 'Session recording is ready for Account Owner/ Facilitator',
    'MursionPortal.Client.EmailSettings.LearnerSession': 'Learner Session Recording is ready',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejected': 'Simulation session video recording rejected',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoDisallowed': 'Simulation session video recording disallowed',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejectedUser': 'Simulation session video recording rejected by some user',
    'MursionPortal.Client.EmailSettings.RequestRecieved': 'Request Received Confirmation',
    'MursionPortal.Client.EmailSettings.RequestNotFullfilled': 'Request Not Fulfilled',
    'MursionPortal.Client.EmailSettings.RequestDateTime': 'Request Date/Time Change Needed',
    'MursionPortal.Client.EmailSettings.Title.SelectEmails': 'Select the emails you\'d like to send to learners. Mursion recommends that you leave all emails enabled.',
    'MursionPortal.Client.EmailSettings.Header.Registration': 'Registration',
    'MursionPortal.Client.EmailSettings.Header.TrainingSession': 'Training Session',
    'MursionPortal.Client.EmailSettings.Header.Videos': 'Videos',
    'MursionPortal.Client.EmailSettings.Header.Requests': 'Requests',
    'MursionPortal.AriaLabel.ToggleButton': ' Toggle Button',
    'MursionPortal.Client.EmailSettings.Hint.RecordingEnabled': ' only if Recordings enabled',
    'MursionPortal.Client.EmailSettings.Hint.RequestFeature': ' only if Request Feature enabled',
    'MursionPortal.Status.Undefined': 'Undefined',
    'MursionPortal.Status.Orphan': 'Orphan',
    'MursionPortal.Status.Reserved': 'Reserved',
    'MursionPortal.Status.Waif': 'Waif',
    'MursionPortal.Status.Booked': 'Booked',
    'MursionPortal.Status.Upcoming': 'Upcoming',
    'MursionPortal.Status.Pending': 'Pending',
    'MursionPortal.Status.Running': 'Running',
    'MursionPortal.Completed': 'Completed',
    'MursionPortal.Status.Missed': 'Missed',
    'MursionPortal.Status.NeedsReview': 'Needs Review',
    'MursionPortal.Status.Reviewed': 'Reviewed',
    'MursionPortal.Status.Cancelled': 'Cancelled', // TODO: add translation
    'MursionPortal.Status.LateCancelled': 'Late Canceled', // TODO add translations
    'MursionPortal.Status.EarlyCancelled': 'Early Canceled', // TODO add translations
    'MursionPortal.Status.LicenseCancelled': 'Cancelled', // TODO add translations
    'MursionPortal.Status.Error': 'Error',
    'MursionPortal.Status.Capitalized.Upcoming': 'UPCOMING',
    'MursionPortal.Status.Capitalized.Undefined': 'UNDEFINED',
    'MursionPortal.Status.Capitalized.Orphan': 'ORPHAN',
    'MursionPortal.Status.Capitalized.Waif': 'WAIF',
    'MursionPortal.Status.Capitalized.Reserved': 'RESERVED',
    'MursionPortal.Status.Capitalized.Booked': 'BOOKED',
    'MursionPortal.Status.Capitalized.Pending': 'PENDING',
    'MursionPortal.Status.Capitalized.Running': 'RUNNING',
    'MursionPortal.Status.Capitalized.Missed': 'MISSED',
    'MursionPortal.Status.Capitalized.NeedsReview': 'NEEDS_REVIEW',
    'MursionPortal.Status.Capitalized.Reviewed': 'REVIEWED',
    'MursionPortal.Status.Capitalized.Cancelled': 'CANCELLED', // TODO add translations
    'MursionPortal.Status.Capitalized.LicenseCancelled': 'CANCELLED', // TODO add translations
    'MursionPortal.Status.Capitalized.LateCancelled': 'LATE_CANCELLED', // TODO add translations
    'MursionPortal.Status.Capitalized.EarlyCancelled': 'EARLIER_CANCELLED', // TODO add translations
    'MursionPortal.Status.Capitalized.Error': 'ERROR',
    'MursionPortal.Status.Capitalized.SimError': 'ERROR',
    'MursionPortal.Status.Capitalized.Offline': 'OFFLINE',
    'MursionPortal.Status.Abbreviation.PendingNoLearners': 'P',
    'MursionPortal.Status.Abbreviation.PendingNoParticipants': 'R',
    'MursionPortal.Status.Abbreviation.Swap': 'S',
    'MursionPortal.Status.Abbreviation.Prebooked': 'PB',
    'MursionPortal.Status.Abbreviation.Booked': 'B',
    'MursionPortal.Status.Abbreviation.Active': 'A',
    'MursionPortal.Status.Abbreviation.Completed': 'C',
    'MursionPortal.Status.Abbreviation.Missed': 'M',
    'MursionPortal.Status.Abbreviation.NeedsReview': 'NR',
    'MursionPortal.Status.Abbreviation.Error': 'E',
    'MursionPortal.Status.Abbreviation.LateCancelled': 'LC', // TODO add translations
    'MursionPortal.Status.Abbreviation.EarlyCancelled': 'EC', // TODO add translations
    'MursionPortal.Status.Abbreviation.LicenseCancelled':  'C', // TODO add translations
    'MursionPortal.Status.Abbreviation.Cancelled':  'C', // TODO add translations
    'MursionPortal.Status.Empty': ' ',
    'MursionPortal.Status.PendingNoSS': 'Pending (No SS)',
    'MursionPortal.Status.PendingNoL': 'Pending (No L)',
    'MursionPortal.Status.PendingNoLearners': 'Pending (No Learners)',
    'MursionPortal.Status.PendingNoLSS': 'Pending (No L/SS)',
    'MursionPortal.Text.Team': 'team',
    'MursionPortal.Text.Industry': 'industry',
    'MursionPortal.Text.Environment': 'environment',
    'MursionPortal.Text.Avatar': 'avatar',
    'MursionPortal.Label.SelfReviewTracker': 'SELF-REVIEW TRACKER',
    'MursionPortal.ToggleButton.All': 'all',
    'MursionPortal.Label.PerformanceTracker': 'PERFORMANCE TRACKER',
    'MursionPortal.ToggleButton.TurnTaking': ' TURN TAKING',
    'MursionPortal.Tooltip.Collaborative': 'This is a measure of the time where both you and the avatar(s) took turns without interrupting each other during the conversation.',
    'MursionPortal.Tooltip.Disruptive': 'This is a measure of the time where either you or the avatars(s) interrupted each other during the conversation.',
    'MursionPortal.Tooltip.Speaking': 'This is a measure of the time you spent speaking with the avatar (s) during the conversation.',
    'MursionPortal.Tooltip.Listening': 'This is a measure of the time you spent listening to the avatar (s) during the conversation.',
    'MursionPortal.Tooltip.MiScore': 'Your social effectiveness during the conversation.',
    'MursionPortal.Tooltip.Percentile': 'include a description of what this means somewhere in the UI',
    'MursionPortal.Label.MiScore': 'MI Score',
    'MursionPortal.Label.Percentile': 'percentile',
    'MursionPortal.Label.NotEnoughData': 'Not enough data',
    'MursionPortal.Label.Listening': 'Listening',
    'MursionPortal.Label.LearnerListening': 'Learner Listening',
    'MursionPortal.Label.Speaking': 'Speaking',
    'MursionPortal.Label.Silence': 'Silence',
    'MursionPortal.Label.Collaborative': 'Collaborative',
    'MursionPortal.Label.Disruptive': 'Disruptive',
    'MursionPortal.ToggleButton.LocalLeaderboard': 'Local Leaderboard',
    'MursionPortal.ToggleButton.GlobalLeaderboard': 'Global Leaderboard',
    'MursionPortal.ToggleButton.DeepInsights': 'Deep Insights',
    'MursionPortal.ErrorMessage.DataBeingProcessed': 'Session analytics data is being processed at the moment. The data will be displayed on the page once processing is finished.',
    'MursionPortal.ErrorMessage.UnsupportedFormat': 'Session analytics is empty or has unsupported format.',
    'MursionPortal.ErrorMessage.NoDataAvailable': 'There wasn’t enough data from this session to provide session analytics.',
    'MursionPortal.Message.NotEnoughDataToDisplay': 'There wasn’t enough data from this session.',
    'MursionPortal.Message.Notdata.SessionMatrix':'There wasn’t enough data from this session to provide session analytics.',
    'MursionPortal.Message.NotEnoughDataToDisplay.ConversationalFlow': 'There wasn’t enough data from this session to create this chart',
    'MursionPortal.Label.Positive': 'Positive',
    'MursionPortal.Label.Negative': 'Negative',
    'MursionPortal.Label.Neutral': 'Neutral',
    'MursionPortal.Header.Tracking': 'Tracking',
    'MursionPortal.Button.ReadMore': 'Read More',
    'MursionPortal.Button.Hide': 'Hide',
    'MursionPortal.Title.Interchange': 'Interchange. Natural conversation and turn taking without interventions or interjection',
    'MursionPortal.Title.InterventionByYou': 'Intervention By You. Successfully interrupting someone who is speaking, preventing them from continuing',
    'MursionPortal.Title.InterventionByAvatars': 'Interruption by Avatars. Successfully interrupting someone who is speaking, preventing them from continuing',
    'MursionPortal.Title.InterjectionByYou': 'Interruption by Learner. Attempting to interrupt someone who is speaking without success',
    'MursionPortal.Title.InterjectionByAvatars': 'Interjection By Avatars. Attempting to interrupt someone who is speaking without success',
    'MursionPortal.Title.Pauses': 'Pauses. Longer than 0.2 s and less than 0.75s',
    'MursionPortal.Title.Silence': 'Break in speech longer than 10 sec',
    'MursionPortal.Label.VoiceSelf': 'Voice self',
    'MursionPortal.Label.VoiceOthers': 'Voice others',
    'MursionPortal.Label.Avatars': 'Avatar',
    'MursionPortal.Label.You': 'Learner',
    'MursionPortal.ColumnHeader.User': 'User',
    'MursionPortal.ColumnHeader.Percentile': 'Percentile',
    'MursionPortal.ColumnHeader.ScenariosCompleted': 'Scenarios Completed',
    'MursionPortal.Message.LeaderboardsUpdated': 'Leaderboards will be updated as and when more people complete their simulations.',
    'MursionPortal.Message.CheckBack': 'Please check back in occasionally to see where you stand!',
    'MursionPortal.Label.SimStart': 'Sim Start',
    'MursionPortal.Label.SimEnd': 'Sim End',
    'MursionPortal.Tooltip.Summary.Speaking': 'Time spent speaking to the avatar(s) during the entire conversation',
    'MursionPortal.Tooltip.Summary.Listening': 'Time spent listening to the avatar(s) during the entire conversation',
    'MursionPortal.Capitalized.NotAvailable': 'N/A',
    'MursionPortal.ColumnHeader.Trait': 'Trait',
    'MursionPortal.ColumnHeader.Me': 'Me',
    'MursionPortal.ColumnHeader.OthersAvg': 'Others (avg.)',
    'MursionPortal.TooltipText.Speaking': 'You speak more than {percentage} of people.',
    'MursionPortal.TooltipText.Listening': 'You listen more than {percentage} of people.',
    'MursionPortal.TooltipText.Collaborative': 'You are more collaborative than {percentage} of people.',
    'MursionPortal.TooltipText.Disruptive': 'You are more disruptive than {percentage} of people.',
    'MursionPortal.Label.FeedbackValue': 'Feedback Value',
    'MursionPortal.Button.AnalyticsData': 'Analytics Data',
    'MursionPortal.Button.RawAnalyticsData': 'Raw Analytics Data',
    'MursionPortal.Header.SocialEffectiveness': 'Social Effectiveness',
    'MursionPortal.Label.Current': 'Current',
    'MursionPortal.Header.ArticulationRate': 'Articulation Rate',
    'MursionPortal.Label.Average': 'Average:',
    'MursionPortal.Header.Conversation': 'Conversation',
    'MursionPortal.Header.SpeakingListening': 'Speaking / Listening',
    'MursionPortal.Header.CumulativeTurnTaking': 'Cumulative Turn Taking',
    'MursionPortal.Header.InstantaneousTurnTaking': 'Instantaneous Turn Taking',
    'MursionPortal.ColumnHeader.ScenarioTemplate': 'SCENARIO_TEMPLATE',
    'MursionPortal.ColumnHeader.Provider': 'PROVIDER',
    'MursionPortal.Label.AbbreviatedMinute': 'm',
    'MursionPortal.Header.Mursion': '<code>M</code>ursion',
    'MursionPortal.Header.Index': '<code>I</code>ndex',
    'MursionPortal.Header.Score': 'Score',
    'MursionPortal.visibilityHidden.Selected': 'selected',
    'MursionPortal.AriaLabel.CloseTechSupport': 'close tech support',
    'Mursion.Portal.SessionUserStatus.Connected': 'CONNECTED',
    'Mursion.Portal.SessionUserStatus.Late': 'LATE',
    'Mursion.Portal.SessionUserStatus.Immersing': 'IMMERSING',
    'Mursion.Portal.SessionUserStatus.Left': 'LEFT',
    'Mursion.Portal.SessionUserStatus.Declined': 'DECLINED',
    'MursionPortal.AriaLabel.DraftScenarioSuperScript': 'Draft scenario superscript {statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedScenarioSuperScript': 'Finalized scenario superscript {statusConfiguration}',
    'MursionPortal.AriaLabel.StartedScenarioSuperScript': 'Started scenario superscript {statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedScenarioSuperScript': 'Finished scenario superscript {statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedScenarioSuperScript': 'Archived scenario superscript {statusConfiguration}',
    'Calendar.Button.ScheduleEventON': 'Schedule event on {thisDate}',
    'Calendar.Button.CantScheduleON': 'Can\'t schedule event on {thisDate}',
    'MursionPortal.AriaLabel.ChooseFileButton': 'choose file button',
    'MursionPortal.Label.VisibilityHidden.FileUploaded': 'File uploaded',
    'MursionPortal.VisibilityHidden.EntityAdded': 'Entity added',
    'MursionPortal.VisibilityHidden.EntityRemoved': 'Entity removed',
    'MursionPortal.Legend.VisibilityHidden.StationTypeHomeOffice': 'Station type (Office, Home)',
    'MursionPortal.Legend.VisibilityHidden.HoursFullTimePartTime': 'Hours (Full Time, Part Time)',
    'MursionPortal.AriaLabel.ShowTechSupport': 'show tech support',
    'MursionPortal.Monitoring.Card.UntilTheEnd': 'Time remaining', // TODO: translate
    'MursionPortal.Monitoring.Card.OverTime': 'Overtime', // TODO: translate
    'MursionPortal.MyProfile.Hover.Profile': 'profile {userName}',
    'MursionPortal.AriaDescribedby.StartDate': 'Start Date: Press the question mark key to get the keyboard shortcuts for changing dates.',// TODO: add translation
    'MursionPortal.AriaDescribedby.EndDate': 'End Date: Press the question mark key to get the keyboard shortcuts for changing dates.',// TODO: add translation
    'MursionPortal.VisibilityHidden.Footer': 'Footer',
    'MursionPortal.VisibilityHidden.Header': 'Header',
    'MursionPortal.AriaLabel.FullScreen': 'full screen',
    'MursionPortal.AriaLabel.ClientNameEventsDisplayed': 'Client {clientName} events displayed on the calendar',
    'MursionPortal.AriaLabel.SideNavigationToggle': 'Side navigation toggle',
    'MursionPortal.AriaLabel.AddTags': 'Add tags',
    'MursionPortal.VisibilityHidden.Navigation': 'Navigation',
    'MursionPortal.AriaLabel.SortByDescendingOrderButton': 'sort by descending order button',
    'MursionPortal.AriaLabel.SortByAscendingOrderButton': 'sort by ascending order button',
    'MursionPortal.VisibilityHidden.ProjectsTabSelected': 'Projects tab selected',
    'MursionPortal.VisibilityHidden.TeamsTabSelected': 'Teams tab selected',
    'MursionPortal.VisibilityHidden.SessionsTabSelected': 'Sessions tab selected',
    'MursionPortal.VisibilityHidden.UsersTabSelected': 'Users tab selected',
    'MursionPortal.Title.DemandBasedSessionScheduling': 'Demand Based Session Scheduling',
    'MursionPortal.CheckboxLabel.SchedulingByDemand': 'Scheduling by Demand',
    'MursionPortal.CheckboxLabel.ProjectTimezone': 'Project Timezone',
    'MursionPortal.CheckboxLabel.DemandTime': 'Demand Time',
    'MursionPortal.Label.DemandTimeStart': 'Demand time start',
    'MursionPortal.Label.DemandTimeEnd': 'Demand time end',
    'MursionPortal.Label.DemandWindow': 'Demand Window',
    'MursionPortal.Tooltip.SchedulingWindow': 'End-users can set this value in days, hours, minutes, after which only the user would be able to schedule a session.',
    'MursionPortal.Project.Days': 'Day(s)',
    'MursionPortal.DemandBasedScheduling.SubmitPage.Message': 'We\'re working to finalize your simulation based on the timeslots you provided. Expect an email from us  with more details. See you soon!',
    'MursionPortal.DemandBasedScheduling.SlotSelectionMessage': 'Please choose time slots that would work for you. We\'ll schedule your scenario in one of your chosen time slots.',
    'MursionPortal.DemandBasedScheduling.SubmitPage.ThankYou': 'Thank You!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.GoToDashboard': 'Go to My Dashboard',
    'MursionPortal.DemandBasedScheduling.ScheduleASession': 'Schedule a Simulation',
    'MursionPortal.DemandBasedScheduling.Details': 'DETAILS',
    'MursionPortal.DemandBasedScheduling.Slot': 'SLOT {count}',
    'MursionPortal.DemandBasedScheduling.PickDateAndTime': 'Pick date + time to set',
    'MursionPortal.DemandBasedScheduling.Button.Set': 'SET',
    'MursionPortal.DemandBasedScheduling.Button.Clear': 'CLEAR',
    'MursionPortal.DemandBasedScheduling.Jumbotron.Text': 'Scenario details will load once you make a selection',
    'MursionPortal.Error.PageNotFound': '404 Page Not Found',
    'MursionPortal.Text.PageNotFound': 'Page not found. Check if you have the permissions to view the page if the link is correct.',
    'MursionPortal.Text.Success': 'Success!',
    'MursionPortal.Text.YouAreNowSignedIn': 'You are now signed in to the Mursion simulation software.',
    'MursionPortal.Text.PleaseReturnToDesktop': 'Please return to the desktop application by clicking {linebreak} on the \'M\' icon in your computer\'s taskbar (Windows) or Dock (Mac).',
    'MursionPortal.Client.EmailSettings.Header.DemandBased': 'Demand-Based',
    'MursionPortal.Client.EmailSettings.TimeSlotsReceived': 'Time Slots Received',
    'MursionPortal.Label.Past': 'Past',
    'MursionPortal.Monitoring.SessionsSummary.Preparation': 'Preparation',
    'MursionPortal.Monitoring.SessionsSummary.Normal': 'Normal',
    'MursionPortal.Monitoring.SessionsSummary.Error': 'Error',
    'MursionPortal.Monitoring.SessionsSummary.Issue': 'Issue',
    'MursionPortal.Monitoring.SessionsSummary.NotLoggedIn': 'Not Logged In',
    'MursionPortal.Monitoring.Legend.Label': 'Legend',
    'MursionPortal.Monitoring.Legend.Dashboard': 'Dashboard',
    'MursionPortal.Label.SimSpecialist': 'Sim Specialist',
    'MursionPortal.Monitoring.SessionsSummary.ConnectedToPeers': 'Connected To Peers',
    'Requests.Table.CertifiedScenarios': 'Certified Scenarios',
    'Requests.Table.UncertifiedScenarios': 'Uncertified Scenarios',
    'Requests.Table.NoUpComingDemands': 'You have no demand-based requests with the above criteria.',
    'Requests.Table.DeclineReason': 'Decline - Select Reason',
    'Requests.Table.UnAvailable': 'Unavailable',
    'Requests.Table.NotPrepared': 'Not Prepared',
    'Requests.Table.Other': 'Other',
    'Requests.Table.Availability': 'Select Availability',
    'Requests.Table.Declined': 'Declined',
    'Requests.Table.SuccessfullySent': 'Request Sent Successfully',
    'Requests.Table.RequestCertificate': 'Request Certificate',
    'Requests.Table.DeclineReasonText': 'Decline Reason',
    'Requests.Table.AvailabilitySlot': 'Availability Slot',
    'Requests.Table.DemandBased': 'Demand Based',
    'MursionPortal.Setting.Integrations': 'Integration',
    'MursionPortal.Setting.Integrations.Title': 'Please select an integration',
    'MursionPortal.Setting.Integrations.Header.PlatformConfigurations': 'Platform Configurations',
    'MursionPortal.Button.AddConfigurations': 'Add Configurations',
    'Settings.LTI.PlaceHolder.EnterConfigName': 'Enter Config Name',
    'Settings.LTI.Table.ColumnHeader.DeploymentID': 'Deployment ID',
    'Settings.LTI.Table.ColumnHeader.Version': 'Version',
    'Settings.LTI.Table.Caption': 'lti configuration list',
    'Settings.LTI.Table.Hover.ViewEditLti': 'View/Edit Lti',
    'Settings.LTI.Table.NoLtiConfigFound': 'No LTI configuration found',
    'MursionPortal.EditLtiModal.Button.DeleteLti': 'Delete Configuration',
    'MursionPortal.EditLtiModal.Button.SaveLtiConfig': 'Save Configuration',
    'MursionPortal.Lti.NewLTIConfig': 'New LTI Configuration',
    'MursionPortal.Lti.CreateLTIConfig': 'Create LTI Configuration',
    'Settings.LTI.Modal.PlatformIssuerIdentifier': 'Platform issuer Identifier',
    'Settings.LTI.Modal.PublicKeysetEndpoint': 'Public Keyset Endpoint',
    'Settings.LTI.Modal.DeploymentId': 'Deployment ID',
    'Settings.LTI.Modal.RolesMapping': 'Roles Mapping',
    'Settings.LTI.Modal.RolesMapping.PortalRole': 'Portal Role',
    'Settings.LTI.Modal.RolesMapping.LTIRole': 'LTI Role',
    'MursionPortal.Setting.Integrations.LTIVersion': 'LTI Version',
    'MursionPortal.ErrorMessage.LtiIdMustBeSpecified': 'LTI Id must be specified.',
    'MursionPortal.Dialog.CreateLTIConfiguration.SuccessMessage': 'LTI configuration has been created successfully.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReserved': 'Timeblock unavailable - reserved for another scheduled simulation.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableSelectDate': 'We don’t have any available time slots right now. Please try again later. <code1></code1>Contact <code>support</code> if you need immediate help.',
    'Requests.Table.ViewAvailability': 'View Availability',
    'Settings.LTI.Modal.DeletionConfirmationText': 'Are you sure you want to delete this role mapping?',
    'Settings.ScenarioBank.Modal.InactiveCertification': 'Inactive Certification',
    'Settings.ScenarioBank.Modal.NoInactiveSimSpecialist': 'No Inactive Sim Specialist',
    'Settings.ScenarioBank.Modal.EditInactiveCertification': 'Edit Inactive Certification',
    'Settings.ScenarioBank.Modal.NoInactiveCertification': 'No Inactive Certification',
    'MursionPortal.RequestStatus.All': 'Request Status: All',
    'MursionPortal.RequestStatus.Accepted': 'Request Status: Accepted',
    'MursionPortal.RequestStatus.Declined': 'Request Status: Declined',
    'MursionPortal.RequestStatus.Pending': 'Request Status: Pending',
    'Requests.Table.RequestCertification': 'Request Certification',
    'Requests.Table.RequestTime': 'Request Time',
    'Requests.Table.ScheduleType': 'Schedule Type',
    'Requests.Table.AcceptTime': 'Accept Time',
    'Requests.Table.Scheduling': 'Scheduling',
    'Requests.Table.FulfilledIn': 'Fulfilled In',
    'MursionPortal.DemandBasedScheduling.Timezone': 'Timezone',
    'MursionPortal.DemandBasedScheduling.Timezone.Tooltip': 'Go to your profile settings to change the timezone.',
    'MursionPortal.Results.Found': '{count} results found',
    'MursionPortal.Header.VisibilityHidden.ClientUsers': 'Client Users',
    'MursonPortal.SchedulingType.Auto': 'AUTO',
    'MursonPortal.SchedulingType.Manual': 'MANUAL',
    'MursionPortal.ScheduleTypeSelector.All': 'Schedule: All',
    'MursionPortal.ScheduleTypeSelector.Auto': 'Schedule: Auto',
    'MursionPortal.ScheduleTypeSelector.Manual': 'Schedule: Manual',
    'MursionPortal.ScheduleTypeSelector.NA': 'Schedule: N/A',
    'Integrations.LTI.AppId': 'App Id',
    'Integrations.LTI.ConfigurationId': 'Configuration Id',
    'Settings.LTI.ModalHeader.EditLtiConfiguration': 'Edit LTI Configuration',
    'Settings.LTI.Modal.DeletionConfigConfirmationText': 'Are you sure you want to delete this LTI configuration?',
    'MursionPortal.Rescheduling.SubmitPage.Message': 'We\'ll send you an email in a moment with more details. See you soon!',
    'MursionPortal.Rescheduling.RescheduleSimulation': 'Reschedule Simulation',
    'MursionPortal.Rescheduling.SlotSelectionMessage': 'We\'re sorry you couldn\'t make your simulation. Pick a day and time that works for you!',
    'MursionPortal.IVSBrowserWarning.Safari17.Message1': 'The browser version you are currently using is not supported for your Mursion session',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Message2': 'When joining your Mursion simulation, use one of these browsers:',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Firefox': "Firefox",  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome': "Chrome", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome.Subtitle': "Recommended", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Edge': "Edge", // TODO: add translations
    'MursionPortal.Result.Found': '{count} result found',
    'MursionPortal.Project.Rescheduling': 'Rescheduling',
    'MursionPortal.Project.SameDay': 'Same-day',
    'Session.Edit.Modal.MissedSession': 'MISSED SESSION',
    'Session.Edit.Modal.NoLongerRequired': 'no longer required',
    'Session.Edit.Modal.TechDifficulties': 'having technical difficulties',
    'Session.Edit.Modal.Other': 'other',
    'Session.Edit.Modal.CancelBecause': 'Yes, cancel the session because',
    'Session.Edit.Modal.CancelSimulation': 'Cancel Simulation',
    'MursionPortal.UserConsent.Heading': 'Recording Permissions',
    'MursionPortal.UserConsent.Title': 'Do you want a recording of this simulation session for your own learning journey?',
    'MursionPortal.UserConsent.Description': 'If you say "I Consent," the Mursion software will process your live simulation and make a video recording of your simulation session for your review in the Mursion software portal.  Please note that at the request of the client who licensed Mursion software on your behalf, a copy of your recorded simulation session(s) will be shared with a coach, mentor, or reviewer selected by the client. All reviewers are contractually obligated to follow <code>Mursion’s privacy policy</code>, unless you have agreed to different privacy terms.  Except as required by applicable law, Mursion will not distribute or disclose your video recording without your permission.  Mursion will use anonymized data from your use of its software to improve its simulation technology.',
    'MursionPortal.UserConsent.Learner.Description': 'If you say "I Consent," the Mursion software will process your live simulation and make a video recording of your simulation session for your review in the Mursion software portal. Except as required by applicable law or for quality assurance purposes, Mursion will not distribute or disclose your video recording without your permission. Mursion will use anonymized data from your use of its software to improve its simulation technology.',
    'MursionPortal.UserConsent.LearnerAndAccountOwnerOrFacilitator.Description': 'If you say "I Consent," the Mursion software will process your live simulation and make a video recording of your simulation session for your review in the Mursion software portal. Please note that at the request of the client who licensed Mursion software on your behalf, a copy of your recorded simulation session(s) will be shared with a coach, mentor, or reviewer selected by the client. All reviewers are contractually obligated to follow <code>Mursion’s privacy notice</code>, unless you have agreed to different privacy terms. Except as required by applicable law or for quality assurance purposes, Mursion will not distribute or disclose your video recording without your permission. Mursion will use anonymized data from your use of its software to improve its simulation technology.',
    'MursionPortal.UserConsent.AccountOwnerOrFacilitator.Description': 'Your simulation session(s) will be recorded in both audio and video at the request of the client who licensed the Mursion Software on your behalf. If you consent to proceed, a copy of your recorded simulation session(s) will be shared with a client-selected third party in order to analyze and score the recording(s). These individuals are contractually obligated to follow <code>Mursion’s privacy notice</code>, unless you have agreed to different privacy terms. You will not, however, receive or have access to a copy of your recorded simulation session(s) as per the terms of the assignment or assessment you have agreed to complete. Mursion may use your video recording for quality assurance purposes and will use anonymized data from your use of its software to improve its simulation technology.',
    'MursionPortal.UserConsent.IConsent': 'I Consent',
    'MursionPortal.UserConsent.IDoNotConsent': 'I Do Not Consent',
    'MursionPortal.RecordingLinkPermisssion.Title': 'Recording Permissions',
    'MursionPortal.RecordingLinkPermisssion.DescriptionOne': 'Do you want to be sent a recording of this session?',
    'MursionPortal.RecordingLinkPermisssion.DescriptionTwo': 'If so, please check your email inbox and verify your email address. You’ll be sent a link tomorrow if available.',
    'MursionPortal.PublicSession.PageTitle': 'Enter Your Details to Join a Simulation',
    'MursionPortal.PublicSession.Form.label.FirstName': 'First Name',
    'MursionPortal.PublicSession.Form.label.LastName': 'Last Name',
    'MursionPortal.PublicSession.Form.label.Email': 'Email (Optional)',
    'PublicSession.Form.Button.Join': 'Join',
    'MursionPortal.PublicSession.Form.Placeholder.FirstName': 'Your first name',
    'MursionPortal.PublicSession.Form.Placeholder.LastName': 'Your last name',
    'MursionPortal.PublicSession.Form.Placeholder.Email': 'name@provider.com',
    'MursionPortal.PublicSession.Form.Placeholder.HaveAccount': 'Already have an account?',
    'MursionPortal.PublicSession.Form.Placeholder.LogInHere': 'Log in here',
    'Session.Edit.Modal.ToolTip.Google': 'Add event to your Google calendar. Confirm authorization if needed',
    'Session.Edit.Modal.ToolTip.OutlookIcalOther': 'Click to download event to your desktop and add to your calendar app',
    'Session.Edit.Modal.SimSpecialistAssigned': 'Sim Specialist Assigned',
    'Session.Edit.Modal.SessionSimSpecialistField.AddSIMSpecialist': 'Add SIM Specialist',
    'Session.Edit.Modal.DemandBasedTimeSlots': 'Demand Based Time Slots',
    'Session.Edit.Modal.Reschedule': 'Reschedule',
    'Session.Edit.Modal.JoinSession': 'Start Session',
    'Session.Edit.Modal.AddLearner': 'Add Learner',
    'MursionPortal.Label.SearchOrSelectLearners': 'Search or select learner(s)',
    'MursionPortal.Label.SIMSpecialist': 'SIM Specialist',
    'MursionPortal.Client.EmailSettings.Header.Rescheduling': 'Rescheduling',
    'MursionPortal.Client.EmailSettings.RescheduleMissedSession': 'Reschedule Missed Session',
    'Session.Edit.Modal.OtherPrioritiesAtWork': 'other priorities at work',
    'MursionPortal.Modal.Header.Sorry': 'Sorry!',
    'MursionPortal.Modal.UnschedulableSessionMsg': 'The session is unschedulable.',
    'MursionPortal.Rescheduling.Slot.NoLongerAvailable': 'Selected time is no longer available, please select a different option.',
    'MursionPortal.EmailVerificationPage.Success': 'Email Verificaion Success!',
    'MursionPortal.EmailVerificationPage.SuccessDesc': 'Your email has been verified successfully',
    'MursionPortal.EmailVerificationPage.Error': 'Email Verificaion Failed!',
    'MursionPortal.EmailVerificationPage.ErrorDesc': 'Something went wrong, Please try again to verify your email',
    'Settings.Config.Heading.SplashPageAttendance': 'ATTENDANCE',
    'Clients.Modal.Label.ShowSowReporting': 'Show Utilization Reporting:',
    'Clients.Modal.Label.ShowSurveyEnabled': 'Send Post-Simulation Learner Survey:',
    'Clients.Modal.Label.MursionSocialAttendance': 'Mursion Social Attendance:',
    'Clients.Modal.Label.MursionSocialAttendance.ToolTip': 'Collect learner attendance during Mursion Social sessions.',
    'Settings.Config.Invitation.MursionSocialAttendance': 'Gather Mursion Social Attendance.',
    'Settings.Config.External.Users': '(Must also be enabled in client’s configuration)',
    'Settings.Config.Invitation.ExternalUser': 'For external user attendance',
    'MursionPortal.Scenario.Vignette.ButtonText.ShowMore.SessionDetails': 'Session details',
    'MursionPortal.DemandBasedScheduling.ProjectDropdown.Placeholder': 'Select a Project',
    'MursionPortal.DemandBasedScheduling.ScenarioDropdown.Placeholder': 'Select a Scenario',
    'Session.Edit.Modal.Label.SearchOrSelectASimSpecialist': 'Search or select a SIM Specialist',
    'MursionPortal.Button.Exclamation.GotIt': 'Got it!',
    'MursionPortal.Modal.UnavailableSlotMsg': 'The time you selected is now unavailable. Please pick a new time slot.',
    'MursionPortal.SimAttendance.NoLongerAvailable': 'You can no longer edit the attendance for this simulation.',
    'MursionPortal.SimAttendance.Midnight': 'You must complete attendance by midnight each day. Attendance for this simulation will default to system statuses. Please contact Support if anything abnormal ocurred.',
    'MursionPortal.SimAttendance.Instructions': 'Instructions',
    'MursionPortal.SimAttendance.Attendee': 'Attendee',
    'MursionPortal.SimAttendance.Tooltip.Completed': 'Learner completed the simulation portion of the session (not including the debrief at the end of the session).',
    'MursionPortal.SimAttendance.Tooltip.Missed': 'Learner never connected to the simulation.',
    'MursionPortal.SimAttendance.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Learner’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',
    'MursionPortal.SimAttendance.Tooltip.Late': 'Learner connected too late to complete the simulation.',
    'MursionPortal.SimAttendance.Tooltip.Left': 'Learner intentionally disconnected before completing the simulation. This could be due to a learner being unprepared, or a learner may leave for an emergency.',
    'MursionPortal.SimAttendance.Tooltip.Declined': 'Learner connected to simulation but declined to participate, and exited before completing at least 50% of the simulation.',
    'MursionPortal.SimAttendance.Add.Attendee': 'Add attendee',
    'Session.Edit.Modal.EditAttendance': 'Edit  Attendance',
    'Session.Edit.Modal.CompleteAttendance': 'Complete  Attendance',
    'MursionPortal.SimAttendance.Late': 'Late',
    'MursionPortal.SimAttendance.Left': 'Left',
    'MursionPortal.Instructions.First': 'Select an attendance status for each attendee (mouse over the status to learn more). If a learner is not listed, click',
    'MursionPortal.Instructions.Second': 'function in the lower left.',
    'MursionPortal.TableColumn.SimsAction': 'Sim\'s Action',
    'Session.Edit.Modal.Label.SelectTrainer': 'Search or select a trainer',
    'MursionPortal.Project.DemandSlots': 'Demand Slots',
    'Dashboard.SimulationTable.Column.Provider': 'Provider',
    'Dashboard.SimulationTable.Column.Client': 'Client',
    'Dashboard.SimulationTable.Column.Project': 'Project Name',
    'Dashboard.SimulationTable.Column.ScenarioName': 'Scenario Name',
    'Dashboard.SimulationTable.Column.ScheduledStartDate': 'Scheduled Start Date',
    'Dashboard.SimulationTable.Column.ScheduledStartTime': 'Scheduled Start Time',
    'Dashboard.SimulationTable.Column.ScheduledEndTime': 'Scheduled End Time',
    'Dashboard.SimulationTable.Column.SimulationStatus': 'Session Status',
    'Dashboard.SimulationTable.Column.LearnersNames': 'Learner(s) Names',
    'Dashboard.SimulationTable.Column.LearnersEmails': 'Learner(s) Emails',
    'Dashboard.SimulationTable.Column.LearnersStatus': 'Learner(s) Status',
    'Dashboard.SimulationTable.Column.LearnersTimeSpent': 'Learner(s) Time Spent',
    'Dashboard.SimulationTable.Column.SimSpecialistName': 'Sim Specialist Name',
    'Dashboard.SimulationTable.Column.SimSpecialistStatus': 'Sim Specialist Status',
    'Dashboard.SimulationTable.Column.SimSpecialistTimeSpent': 'SIM Specialist Time Spent',
    'Dashboard.SimulationTable.Column.ScheduledBy': 'Scheduled By',
    'Dashboard.SimulationTable.Column.ScheduledAt': 'Scheduled At',
    'Dashboard.SimulationTable.Column.TimesLateRescheduled': 'Times Late Rescheduled',
    'Dashboard.SimulationTable.Column.Recorded': 'Recorded',
    'Dashboard.SimulationTable.Column.ClientId': 'Client Id',
    'Dashboard.SimulationTable.Column.LearnerName': 'Learner Name',
    'Dashboard.SimulationTable.Column.LearnerName.ButtonHoverTitle': 'Copy Learner Email',
    'Dashboard.SimulationTable.Column.LearnerEmail': 'Learner Email',
    'Dashboard.SimulationTable.Column.LearnerStatus': 'Learner Status',
    'Dashboard.SimulationTable.Column.LearnerTimeSpent': 'Learner TimeSpent',
    'Dashboard.SimulationTable.Column.LearnerRecordingConsent': 'Learner Recording Consent',
    'Dashboard.SimulationTable.Column.RecordingRecipients': 'Recording Recipients',
    'Dashboard.SimulationTable.Column.RecordingUploaded': 'Recording Uploaded',
    'Dashboard.SimulationTable.ColumnTooltip.Provider': 'Name of the provider/licensee',
    'Dashboard.SimulationTable.ColumnTooltip.Client': 'Name of the client',
    'Dashboard.SimulationTable.ColumnTooltip.Project': 'Name of the project',
    'Dashboard.SimulationTable.ColumnTooltip.ScenarioName': 'Name of the scenario',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartDate': 'Date this simulation was scheduled to start',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartTime': 'Time this simulation was scheduled to start',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledEndTime': 'Time this simulation was scheduled to end',
    'Dashboard.SimulationTable.ColumnTooltip.SimulationStatus': 'Final status for the simulation',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersNames': 'Name(s) of the registered and unregistered learners for the simulation',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersEmails': 'Email(s) of the registered and unregistered learners for the simulation',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersStatus': 'Final learner status for the simulation',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersTimeSpent': 'Time spent connected to the simulation by the learners who joined the simulation',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistName': 'Simulation specialist assigned to deliver the simulation',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistStatus': 'Final SIM specialist status for the simulation',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistTimeSpent': 'Actual length, in minutes, of this simulation',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledBy': 'Name of the user who scheduled the simulation',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledAt': 'Date and time that this simulation was scheduled',
    'Dashboard.SimulationTable.ColumnTooltip.TimesLateRescheduled': 'Times that this simulation was late rescheduled',
    'Dashboard.SimulationTable.ColumnTooltip.Recorded': 'Denotes if the simulation was recorded or if it was disallowed..',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerName': 'Name(s) of the registered and unregistered learners for the simulation',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerStatus': 'Final learner status for the simulation',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerTimeSpent': 'Minutes learner spent in the simulation. For Zoom and Meet learners, this is estimated based on the SIM specialist if completed',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerRecordingConsent': 'Denotes if the learner gave consent to the simulation being recorded',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingRecipients': 'Roles that the simulation recording is sent to',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingUploaded': 'Denotes if the simulation recording was uploaded',
    'MursionPortal.CompanyCard.InviteOperations': 'Invite Operations',
    'MursionPortal.CompanyCard.Modal.Header.NewOperations': 'New Operations',
    'Settings.Config.Invitation.ShowContractFeature': 'Allow Contracts to be added',
    'Settings.Config.Contract.Description': '(for tracking contract progress)',
    'MursionPortal.Contract.Edit': 'Edit',
    'MursionPortal.Contract.ContractName': 'Contract Name',
    'MursionPortal.Contract.ContractID': 'Contract ID',
    'MursionPortal.Contract.ContractID.PlaceHolder': 'Enter Contract ID from Hubspot',
    'MursionPortal.Contract.StartAndEndDates': 'Contract Start and End Dates',
    'MursionPortal.Contract.Notes.PlaceHolder': 'Enter other information to be shown to CSMs and BDDs only.',
    'MursionPortal.Contract.LineItem.Add': 'Add an Contract Line Item',
    'MursionPortal.Contract.LineItem.Add.Instruction': 'You can add multiple simulation line items. All other types can only have one line item.',
    'MursionPortal.Contract.LineItemType': 'Line Item Type',
    'MursionPortal.Contract.LineItemType.PlaceHolder': 'Select line item type',
    'MursionPortal.Contract.Quantity': 'Quantity',
    'MursionPortal.Contract.Quantity.PlaceHolder': 'Enter Quantity',
    'MursionPortal.Contract.DeliveryMode.PlaceHolder': 'Select delivery mode',
    'MursionPortal.Contract.SaveContract': 'Save Contract Details',
    'MursionPortal.Contract.Length.PlaceHolder': 'Select length',
    'MursionPortal.Contract.SowName.PlaceHolder': 'Enter Contract Name',
    'MursionPortal.Client.Tab.Contracts': 'Contracts',
    'MursionPortal.Client.Contracts': 'Client Contracts',
    'MursionPortal.Client.Create.Contracts': 'Create Contract',
    'MursionPortal.Client.Contract.ID': 'Contract ID',
    'MursionPortal.NewUser.Optional': ' (optional)',
    'MursionPortal.Text.Congratulation': 'Congratulations !',
    'MursionPortal.Text.PasswordChanged': 'Your password has been changed',
    'MursionPortal.Text.PasswordShouldBeSame': 'Your new passwords must match.',
    'MursionPortal.NewUser.SuchAGoogleAccountOrOtherSsoProvider.TooltipText': 'Such as a Google account or other SSO provider',
    'MursionPortal.UserConsentDeclineConfirmation.Heading': 'Please confirm your choice.',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionsLiesWithLearner': 'Are you sure? To proceed without recording, click Confirm button. To change your recording consent, click Cancel button.',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionDoesNotLieWithLearner': 'Please indicate whether or not you consent to proceed and have your simulation(s) recorded. If you do not consent, you may not proceed any further with your simulation session and the software will quit. If you consent, your simulation session(s) will be recorded.',
    'Settings.Config.Invitation.ShowSowFeature': 'Allow SOWs to be added',
    'Settings.Config.SOW.Description': '(for tracking SOW progress)',
    'MursionPortal.ScenarioBank.StandardizedScenario': 'Standardized Scenario',
    'MursionPortal.ScenarioBank.ShowOnlyBundleScenario': 'Show only standardized scenarios',
    'Dashboard.LeftPane.CategoryHeader.SupplyManagement': 'SIM Workforce Mgmt',
    'Dashboard.LeftPane.SupplyManagement.Scheduling': 'Scheduling',
    'Dashboard.SupplyManagement.Scheduling.MainHeader': 'Scheduling',
    'Dashboard.SupplyManagement.Scheduling.TableHeader': 'Scheduling Data',
    'Dashboard.LeftPane.SupplyManagement.ClientRoster': 'Client Roster',
    'Dashboard.SupplyManagement.ClientRoster.MainHeader': 'Client Roster',
    'Dashboard.SupplyManagement.ClientRoster.TableHeader': 'Client Roster Data',
    'MursionPortal.Table.ColumnHeader.Time': 'Time',
    'MursionPortal.Table.ColumnHeader.AllScheduledSessions': 'All Scheduled (Sessions)',
    'MursionPortal.Table.ColumnHeader.SimsAvailable': 'SIMs Available (People)',
    'MursionPortal.Table.ColumnHeader.AvailabilityHours': 'Availability (Hours)',
    'MursionPortal.Table.ColumnHeader.DemandBasedRequests': 'All Demand Based Requests (Total - all 3 requests)',
    'MursionPortal.Table.ColumnHeader.SlotOneRequests': 'Slot 1 Requests',
    'MursionPortal.Table.ColumnHeader.SlotTwoRequests': 'Slot 2 Requests',
    'MursionPortal.Table.ColumnHeader.SlotThreeRequests': 'Slot 3 Requests',
    'MursionPortal.Table.ColumnHeader.PendingRequests': 'Requests Pending (Sesions)',
    'MursionPortal.Table.ColumnHeader.Auto': 'Auto',
    'MursionPortal.Table.ColumnHeader.Manual': 'Manual',
    'MursionPortal.Table.ColumnHeader.CapacityIndicator': 'Capacity Indicator',
    'MursionPortal.EmptyMessage.NoSupplyManagementDataYet': 'There are no sim workforce management data yet',
    'MursionPortal.Table.ColumnHeader.SimCertified': 'SIMs Certified',
    'MursionPortal.Table.ColumnHeader.ScheduledHours': 'Scheduled (Hours)',
    'MursionPortal.Table.ColumnHeader.Sessions': 'Sessions',
    'MursionPortal.Table.ColumnHeader.Cancelled': 'Cancelled',
    'MursionPortal.Table.ColumnHeader.Missed': 'Missed',
    'MursionPortal.Table.ColumnHeader.Reschedules': 'Reschedules',
    'MursionPortal.Table.ColumnHeader.Error': 'Error',
    'MursionPortal.Table.ColumnHeader.Completed': 'Completed',
    'MursionPortal.Table.ColumnHeader.Other': 'Other',
    'MursionPortal.Table.ColumnHeader.SchedulingWindow': 'Scheduling Window',
    'MursionPortal.Table.ColumnHeader.RequestDemand': '# of Request (demand)',
    'MursionPortal.Table.ColumnHeader.AutoFulfilled': 'Auto Fulfilled',
    'MursionPortal.Table.ColumnHeader.Pending': 'Pending',
    'MursionPortal.Table.ColumnHeader.AvgFullfilled': 'Avg Fullfilled In',
    'MursionPortal.Table.ColumnHeader.SlotOneFullFillment': 'Slot 1 FullFillment',
    'MursionPortal.Table.ColumnHeader.SlotTwoFullFillment': 'Slot 2 FullFillment',
    'MursionPortal.Table.ColumnHeader.SlotThreeFullFillment': 'Slot 3 FullFillment',
    'MursionPortal.Table.ColumnHeader.UnutilizedAvailability': 'Unutilized Availability (Hours)',
    'MursionPortal.Table.ColumnHeader.TotalAvailabilityEntered': 'Total Availability Entered',
    'MursionPortal.Table.ColumnHeader.AverageHoursWeek': 'Average Hours/Week',
    'MursionPortal.Table.ColumnHeader.AvailabilityUtilization': 'Availability Utilization',
    'MursionPortal.SupplyManagement.Above': 'Above',
    'MursionPortal.SupplyManagement.Below': 'Below',
    'MursionPortal.SupplyManagement.At': 'At',
    'MyProfile.ProfServiceRole.Tooltiptext': 'Users with our highest access level',
    'MyProfile.AccManager.Tooltiptext': 'Creates scenarios, teams, add  users and assigns teams to simulations',
    'MyProfile.SimSpecialist.Tooltiptext': 'Also known as a ‘Sim’, this role performs simulations for learners',
    'MyProfile.Buyer.Tooltiptext': 'A user who manages a Mursion project for their organization',
    'MyProfile.Facilitator.Tooltiptext': 'Simulation participant who assists in the delivery of the simulation',
    'MyProfile.Learner.Tooltiptext': 'Simulation participant',
    'MyProfile.Operations.Tooltiptext': 'Mursion employee that has the ability to add and edit contracts',
    'MyProfile.SimDesigner.Tooltiptext': 'User who has the ability to create and edit bank scenarios',
    'Settings.Config.Invitation.SimWorkforceManagement': 'SIM Workforce Management',
    'Settings.Config.SimWorkforceManagement.Description': 'Enabled means the reporting is available in the dashboard page, and disabled means the reporting is not there at all.',
    'MursionPortal.ScenarioBank.BundleScenario': 'Bundle scenarios',
    'MursionPortal.Scheduling.SimAvailable.TimeSlotsTooltipMsg': 'Select this time slot to automatically confirm your scenario booking!',
    'MursionPortal.Label.AssignedUserRoles': 'Assigned User Roles',
    'MursionPortal.Label.AssignedUserRole': 'Assigned User Role',
    'Clients.Modal.Projects.Button.CreateFromBank': 'Create From Bank',
    'MursionPortal.Legend.VisibilityHidden.ScenarioPanel.Buttons': 'Create new, Repeat client, Create from bank scenario buttons',
    'MursionPortal.Label.ArtBundle': 'Art Bundle',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContentMsg': 'This is the 3D art content of environments and avatars that will be used in the simulation. Contents of this folder are displayed in the section titled "art bundle" below.',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoArtBundles': 'The scenario has no assigned art bundles.',
    'MursionPortal.Label.ArtProject': 'Art Project',
    'MursionPortal.Label.UpperCase.ArtProject': 'ART PROJECT',
    'Settings.Config.SimulationContent.ArtProjectFolder': 'Art Project S3 folder',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoArtProject': 'The scenario has no assigned art project',
    'MursionPortal.SessionWizard.Label.SelectArtContentForUse': 'Select Art Project for Use',
    'Projects.Message.NoArtProjectFound': 'No Art Project Found',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenarioBank': 'There are active child scenarios created from this bank scenario. You must archive each of those individually.',
    'Settings.StandardizedScenarioBank.EditConfirmation': 'Are you sure you want to update all existing scenarios created from this one?',
    'Settings.StandardizedScenarioBank.AttachmentsEditConfirmation': 'Attachments update will change all existing scenarios created from this one.',
    'MursionPortal.Contract.Ending': 'Ending',
    'MursionPortal.ProjectContracts.DuplicateError': 'The Contract you’ve selected overlaps with another Contract added to this project. Please select another or contact operations to request a change order',
    'MursionPortal.ProjectContracts.DateErrorHeading': 'No Contract exists for these project dates',
    'MursionPortal.ProjectContracts.DateError': 'The Contracts you\'re attempting to assign to this project do not sit within the elected project dates. Please adjust the project dates or reach out to Operations about Contract Dates.',
    'MursionPortal.Contract.ViewDetails': 'View Details',
    'MursionPortal.Contract.PleaseAddContractToProject': 'Please add contract to project',
    'MursionPortal.Contract.AddContractToProject': 'Add Contract to Project',
    'MursionPortal.Contract.SelectContractToProject': 'Select Contract you wish to add to this project - please add one at a time',
    'MursionPortal.Contract.AddSelectedContract': 'Add Selected Contract',
    'MursionPortal.Contract.AddContract': 'Add Contract',
    'MursionPortal.Contract.LineItemList': 'Line Item List',
    'MursionPortal.DemandBasedScheduling.InstantBooking': 'Instant Booking',
    'MursionPortal.DemandBasedScheduling.AllSlots': 'Submit Slots',
    'MursionPortal.DemandBasedScheduling.ScheduleYourScenario': 'We’ll schedule your scenario in one of your chosen time slots.',
    'MursionPortal.DemandBasedScheduling.SelectOneOfTheseSlotsForAnInstantBooking': 'Select one of these slots for an instant booking.',
    'MursionPortal.Label.VideoTimeLineSlice': 'Video Timeline Slices',
    'MursionPortal.Label.SpeakingMoreThanSixty': 'is more than 60%',
    'MursionPortal.Label.SpeakingBetweenSixty': 'is between 40% and 60%',
    'MursionPortal.Label.SpeakingLessThanFourty': 'is less than 40%',
    'MursionPortal.Label.SpeakingIsZero': 'is 0%',
    'MursionPortal.Label.InterruptionMoreThanSixty': 'which is more than 60%',
    'MursionPortal.Label.InterruptionBetweenSixty': 'which is in between 40% and 60%',
    'MursionPortal.Label.InterruptionLessThanFourty': 'which is less than 40%',
    'MursionPortal.Label.InterruptionIsZero': 'which is 0%',
    'MursionPortal.Label.Interruption.ByAvatar': 'Avatar',
    'MursionPortal.Label.Interruption.ByLearner': 'Learner',
    'MursionPortal.Label.InterruptionCount': 'Times',
    'MursionPortal.ConversationalFlow.label.videoTimeLine': 'Video Timeline',
    'MursionPortal.ConversationalFlow.label.zoomTimeLine': 'Timeline View',
    'MursionPortal.ConversationalFlow.label.zoom.all': 'All',
    'MursionPortal.ConversationalFlow.label.zoom': 'Zoom',
    'MursionPortal.DemandBasedScheduling.TheseSlotDoNotwork': 'If these slots do not work, please select “Submit Slots” to submit times that work for you.',
    'MursionPortal.AltText.CrossButton': 'cross button image',
    'MursionPortal.Title.BankScenario': 'Bank Scenario',
    'Session.Edit.Modal.JoinSessionBtnTooltip': 'The Start session button would be enabled before <code>{minutesBeforeJoin}</code> minutes of the start of the session.',
    'Dashboard.LeftPane.SupplyManagement.SimRoster': 'Sim Roster',
    'Dashboard.SupplyManagement.SimRoster.MainHeader': 'Sim Roster',
    'Dashboard.SupplyManagement.SimRoster.TableHeader': 'Sim Roster Data',
    'MursionPortal.Table.ColumnHeader.SimFullName': 'SIM Full Name',
    'MursionPortal.Table.ColumnHeader.LanguagesDelivered': 'Languages Delivered (certified on language)',
    'MursionPortal.Table.ColumnHeader.Tier': 'Tier',
    'MursionPortal.Table.ColumnHeader.Timezone': 'Timezone',
    'MursionPortal.Table.ColumnHeader.Scheduled': 'Scheduled',
    'MursionPortal.Table.ColumnHeader.ProjectsHoursWeekTotal': 'Projected Hours/Week Total',
    'MursionPortal.Table.ColumnHeader.ActiveClientCertifications': 'Active Client (billable) Certifications',
    'MursionPortal.Table.ColumnHeader.InactiveCertifications': 'Inactive Certification',
    'MursionPortal.Table.ColumnHeader.Declined': 'Declined',
    'MursionPortal.Table.ColumnHeader.AvgFullfillTimeForManual': 'Avg Fullfill Time For Manual',
    'MursionPortal.Table.ColumnHeader.Queue': 'Queue',
    'MursionPortal.Table.ColumnHeader.Availability': 'Availability',
    'MursionPortal.Filters.Tier': 'Tier',
    'MursionPortal.Filters.PlaceHolder.SearchTier': 'Search Tier',
    'MursionPortal.TierOptions.Tier1': 'Tier 1',
    'MursionPortal.TierOptions.Tier2': 'Tier 2',
    'MursionPortal.TierOptions.Tier3': 'Tier 3',
    'MursionPortal.TierOptions.Tier4': 'Tier 4',
    'MursionPortal.TierOptions.LeadSimulation': 'Lead Simulation Specialist',
    'MursionPortal.Contract.BackToCLient': 'Back to Client',
    'MursionPortal.DeliveryMode.Workshop': 'Workshop',
    'MursionPortal.DeliveryMode.PrivatePractice': 'Private Practice',
    'MursionPortal.DemandBasedScheduling.SimOnlySlotSelectionMessage': 'Please choose a day and time slot that works for you.',
    'MursionPortal.Projects.NoContractsFound': 'No Contracts have been found.',
    'MursionPortal.Projects.ProjectContracts': 'Project Contracts',
    'MursionPortal.Tooltip.AvatarInterrupt': 'Avatar attempts to interrupts speaker successfully.',
    'MursionPortal.Tooltip.LearnerInterrupt': 'Learner attempts to interrupts speaker successfully.',
    'MursionPortal.Tooltip.Pauses': 'Pauses. Longer than 0.2 s and less than 0.75s',
    'MursionPortal.Tooltip.Silence': 'Break in speech longer than 10 sec',
    'MursionPortal.Tooltip.AvatarSpeaking': 'Avatar attempts for speaking with success.',
    'MursionPortal.Tooltip.LearnerSpeaking': 'Learner attempts for speaking with success.',
    'MursionPortal.label.LearnerInterjects': 'Learner Interjects',
    'MursionPortal.label.AvatarInterjects': 'Avatar Interjects',
    'MursionPortal.label.LearnerInterrupted': 'Learner Interjected',
    'MursionPortal.label.AvatarInterrupted': 'Avatar Interjected',
    'MursionPortal.label.Pause': 'Pause',
    'MursionPortal.label.Silence': 'Silence',
    'MursionPortal.label.AvatarSpeaking': 'Avatar Speaking',
    'MursionPortal.label.LearnerSpeaking': 'Learner Speaking',
    'MursionPortal.Contract.DuplicateLineItem': 'An line item already exists for this simulation length and delivery mode',
    'MursionPortal.Contract.DeleteLineItemIcon': 'Delete line item icon',
    'SessionReports.Label.ShareVideoLink': 'Share Video Link',
    'SessionReports.Label.CopyVideoLink': 'Copy Video Link',
    'MursionPortal.SessionNotStarted.Heading': 'You’re a little early',
    'MursionPortal.SessionNotStarted.DescriptionOne': 'While you wait you can:',
    'MursionPortal.SessionNotStarted.AudioVideoSettings': 'Check your audio and video settings',
    'MursionPortal.SessionNotStarted.ScenarioDetails': 'Review the <code>scenario details</code>',
    'MursionPortal.SessionNotStarted.PreSimTips': 'Check out these <code>pre-simulation tips</code>',
    'MursionPortal.SessionNotStarted.DescriptionTwo': 'This pop-up will go away <code>{value}</code> minutes before your simulation. Click Refresh if the page doesn\'t update automatically at that time.',
    'Settings.LTI.Modal.SendScore': 'Send Score',
    'MursionPortal.EndDate': 'End date',
    'MursionPortal.StartDate': 'Start date',
    'MursionPortal.LearnerSimSelector.Tab.Search.Learner': 'Search or select learner',
    'MursionPortal.LearnerSimSelector.Tab.Search.Sim': 'Search or select SIM specialist',
    'MursionPortal.LearnerSimSelector.Learner': 'Learners',
    'MursionPortal.LearnerSimSelector.Sim.Available': 'Available',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable': 'Unavailable',
    'MursionPortal.LearnerSimSelector.Learner.Selected': 'Selected Learner',
    'MursionPortal.LearnerSimSelector.Sim.Selected': 'Sim Specialist',
    'MursionPortal.Label.FilterSIMsBy': 'Filter SIMs by',
    'MursionPortal.Scheduling.Label.DateTimeTab': 'Date + Time',
    'MursionPortal.ProjectContract.Confirmation': 'Are you sure you want to delete the contract from this project?',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NotYetScheduled': 'Learners with scenarios available to them but have not yet scheduled a simulation',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.CompletedAllAvailable': 'Learners who\'ve completed all scenarios that were available to them',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.Scheduled': 'Learners who have one or more simulations scheduled that they haven\'t yet completed',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NoneAvailableToSchedule': 'Learners who have not yet had a scenario available to schedule',
    'MursionPortal.Title.Hours': 'Hour(s)',
    'MursionPortal.Title.Minutes': 'Minute(s)',
    'MursionPortal.Dashboard.UpcomingSession': 'Upcoming Session',
    'MursionPortal.Button.View': 'View',
    'MursionPortal.Dashboard.NewSimulationAssigned': 'New Simulation Assigned',
    'MursionPortal.Dashboard.MakeUpSimulation': 'Makeup Simulation',
    'MursionPortal.Dashboard.MissedSimulation': 'Missed Workshop',
    'MursionPortal.Dashboard.CompletedSimulations': 'Completed Simulations',
    'MursionPortal.Dashboard.CompletedSessions': 'Completed Sessions',
    'MursionPortal.Dashboard.ViewAll': 'View All',
    'MursionPortal.Dashboard.BackToDashboard': 'Back to Dashboard',
    'MursionPortal.Dashboard.DateOfSimulation': 'Date of Session',
    'MursionPortal.Dashboard.CancelledSimulation': 'Cancelled Workshop',
    'MursionPortal.CodeOfConduct.Text': 'By choosing to schedule your Mursion session, you are agreeing to uphold and honor the Mursion <code>Code of Conduct</code>.',
    'MursionPortal.SessionFlow.Label.ScenarioDetails': 'Scenario Details',
    'MursionPortal.SessionFlow.Label.ProviderName': 'Provider Name',
    'MursionPortal.SessionFlow.Label.SessionStartEndDateTime': 'Session Start & End Date Time',
    'MursionPortal.SessionFlow.Label.DeleteRecording': 'Delete Recording',
    'MursionPortal.SessionFlow.Label.YouAreAboutToDeletevideoRecording': 'You’re about to delete a video recording',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing': 'Before deletion please ensure the following:',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text1': 'You’ve checked that the user requesting has authority to delete (beware of group simulations and learner consent).',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text2': 'You’ve created a support case that states who’s requesting the deletion and that you’ve checked with the Account Owner if necessary.',
    'MursionPortal.SessionFlow.Label.SupportCase': 'Support Case #*',
    'MursionPortal.SessionFlow.Label.ReminderThisCanNotBeUndone': 'REMINDER: This can not be undone!',
    'MursionPortal.SessionFlow.Label.AreYouSure': 'Are you sure?',
    'SessionReports.VideoRecordingDeletedWithSupportCase': 'Unfortunately, this video is unable to view due to a pending #{supportCase}.',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedAndAvailableSimSpecialist': 'No availability exists for a Certified SIM.',
    'MursionPortal.DateRangeSelector.Label.DateRange': 'Date Range ',
    'Session.Edit.Modal.LastEmailTrigger': 'Last email sent to learner: {sentDate}',
    'Session.Edit.Modal.LastEmailTrigger.Sender.UserName': 'By: {userName} {sentTime}',
    'Session.Rescheduling.Email.Reminder.Msg': 'Sending rescheduling email to the learner!',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedSimSpecialist': 'There are no certified Sims available',
    'MursionPortal.Survey.Thankyou.Msg': 'We thank you for your time spent taking this survey. Your response has been recorded.',
    'Session.Edit.Modal.JoinSimulation': 'Join Session',
    'Session.Edit.Modal.JoinSimulationBtnTooltip': 'You\'ll be able to join <code>{minutesBeforeJoin}</code> minutes before your simulation begins.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReservedText': 'Already selected - please select another timeblock.',
    'MursionPortal.Dashboard.Join.Tooltip': 'Once this Session becomes available, you will be able to join it',
    'MursionPortal.ScenarioSearchList.Dialog.Title': 'You have recurring availability scheduled during this time. Do you also want to update your recurring availability? ',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText': 'I only want to update my availability for {startDate}',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText.ToDate': 'I want to update my recurring availability from {date}',
    'Clients.Modal.Label.UserRecordingConsent': 'User Recording Consent:',
    'Clients.Modal.Label.UserRecordingConsent.ToolTip': 'Ask User Recording Consent',
    'MursionPortal.Notset': 'Not set',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays': 'Video Recording Data Retention (in days):',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy': 'Video Recording Data-Retention Policy:',
    'Clients.Modal.Label.UserML3SocialRecordingConsent': 'Mursion Social User Recording Consent:',
    'Clients.Modal.Label.UserML3SocialRecordingConsent.ToolTip': 'Ask user for their consent to be recorded. If this is not enabled, the SIM Specialist will ask verbally.',
    'MursionPortal.LearnerSimSelector.Sim.Available.ToolTip': 'Certified SIMs who are not booked for a session and have availability for the selected time',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable.ToolTip': 'Certified SIMs who have no availability in the system and are not booked for another session at the selected time',
    'Settings.Config.Client.VideoRecordingDataRetentionPolicy': 'Show Video Recording Data Retention Policy Config',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToScenario': 'Back to Scenario',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToCalendar': 'Back to Calendar',
    'SessionReports.VideoRecordingDeletedDueToDataRetentionPolicy': 'This video is unavailable due to your company\'s data retention policy.', 
    'SessionReports.VideoRecordingAvailabilityMessage': 'This video will be available for viewing until {videoAvailabilityDate}',
    'SessionReports.SessionOrLearnerStatusError':'No video was recorded for this session due to a technical error.', // TODO: add translations
    'Clients.ImportUsers.NextStepButton': 'Next Step',
    'Clients.ImportUsers.CancelButton': 'Cancel',
    'Clients.ImportUsers.UploadValidateButton': 'Upload + Validate',
    'Clients.ImportUsers.DownloadErrorsButton': 'Download Errors',
    'Session.Table.Column.Attendance': 'Attendance',
    'Session.Table.Column.Value.CompleteAttendance': 'Complete Attendance',
    'Session.Table.Column.Value.AttendanceComplete': 'Attendance Complete',
    'Session.Table.Column.Value.NotApplicable': '(Not Applicable)',
    'Session.Table.Column.Survey': 'Survey',
    'Session.Table.Column.Value.CompleteGoogleForm': 'Complete Google Form',
    'Session.Table.Column.Value.CompleteSurvey': 'Complete Survey',
    'Session.Table.Column.Value.SurveyComplete': 'Survey Complete',
    'Projects.Modal.Text.LearnerSelected': 'At least {maxLearners} learner needs to be selected',
    'Session.Table.Column.Value.SessionNotCompleted': 'Session is not completed',
    'MursionPortal.Project.InviteToSchedule': 'Invite to Schedule',
    'MursionPortal.Project.InvitedToday': 'Invited today',
    'MursionPortal.Project.YourInvitationsText': 'Your invitations will be sent overnight.',
    'MursionPortal.Project.InviteToSchedule.Tooltip': 'Invitations are already being sent for the scenario. You will be able to send invitations again tomorrow.',
    'MursionPortal.Project.InvitedToday.Tooltip': 'Learners who have not scheduled or canceled/missed this scenario will receive an email invitation to schedule.',
    'MursionPortal.Project.InviteMessage.SentDate': 'Last sent {sentDate}',
    'ITManager.TechRestriction.EditDialog.ProviderErrorMsg': 'Please Select Provider',
    'ITManager.TechRestriction.EditDialog.GlobalRestriction': 'Global Restriction',
    'ITManager.TechRestriction.EditDialog.HolidayRestriction': 'Holiday Restriction',
    'ITManager.TechRestriction.Table.Provider': 'Providers',
    'Session.Table.Column.Survey.HoverText': 'If enabled SIMs will receive a link to complete feedback on the learner',
    'MursionPortal.Project.InviteMessage.InvitedBy': 'Invited by {user} - {role}',
    'MursionPortal.Project.LearnersNotYetScheduled': '{learners} learners still need to schedule',
    'Session.Table.Column.Value.AttendanceRequired': 'Attendance Required',
    'MursionPortal.Button.Schedule.Another': 'Schedule Another',
    'MursionPortal.EulaAgreement.CodeOfConduct': 'Every Mursion simulation involves a single live Simulation Specialist who provides the spoken dialogue for up to five virtual avatars in a learning session. The Simulation Specialist’s job is to present conversational challenges to enable Learners, such as yourself, to practice the complex human skills in each scenario. At Mursion we recognize the importance of creating a safe space where people can bravely practice potentially stressful, nuanced, and complex conversations. To ensure that both Learners and the Simulation Specialist can participate confidently, we ask individuals in both groups to commit to a basic Code of Conduct.',
    'MursionPortal.EulaAgreement.CodeOfConductCheckBox.Text': 'I agree to the Mursion’s simulation delivery model and confirm to uphold and honor the Mursion Code of Conduct in all simulations in which I participate. Read more about Mursion simulations and our Code of Conduct <code>here.</code>',
    'MursionPortal.EulaAgreement.CodeOfConduct.Header': 'Methodology Overview & Code of Conduct',
    'MursionPortal.EulaAgreement.UserAgreement.Header': 'User Agreement',
    'MursionPortal.Import.BackToUser': 'Back to User',
    'MursionPortal.Import.BackToTeam': 'Back to Team',
    'Mursion.Portal.Edit.Team.Upload.AccurateUploading': 'Make sure your data is accurate before uploading.',
    'Mursion.Portal.Edit.Team.Upload.AskedReUpload': 'If there are any errors in your file, they will be shown in a resulting spreadsheet in Step 4. Follow the instructions in Step 4 to correct the errors in the original template and re-upload the file.',
    'Mursion.Portal.Edit.Team.Upload.IncludeNewUser': 'You can also include new users to be imported along with the failed users while re-uploading.',
    'Mursion.Portal.Edit.Team.ReadyToUpload': 'When your file is ready and properly formatted please upload the file for validation:',
    'Mursion.Portal.Edit.Team.UploadAndValidationFile': 'Uploading + Validating File',
    'Mursion.Portal.Edit.Team.TeamAssignmentsUpdateSuccessfully': 'Team Assignments Updated Successfully',
    'Mursion.Portal.Edit.Team.UploadSuccessTitle': '{userCount} Users Imported!',
    'Mursion.Portal.Edit.Team.UploadFailureTitle': 'Error!',
    'Mursion.Portal.Edit.Team.UploadSuccess': 'All users were successfully added to the portal.',
    'Mursion.Portal.Edit.Team.DoneButton': 'Done',
    'Mursion.Portal.Edit.Team.DownloadSuccessful': 'Download Successful - Proceed to Next Step',
    'Mursion.Portal.Edit.Team.DownloadError': 'Download Error - Please Try Again',
    'Mursion.Portal.Edit.Team.UploadFileAgain': 'Upload file again',
    'Mursion.Portal.Edit.Team.ContinueWithoutCorrecting': 'Continue without correcting errors',
    'Mursion.Portal.Edit.Team.SuccessfulImportTitle': 'Successful Import',
    'Mursion.Portal.Edit.Team.UnSuccessfulImportTitle': 'Import Unsuccessful',
    'Mursion.Portal.Import.User.HeaderTitle': 'Import Users',
    'Mursion.Portal.Import.User.Upload.AskedReUpload': 'If there are any errors in your file, they will be shown in a resulting spreadsheet in Step 4. Follow the instructions in Step 4 to correct the errors in the <strong>original template</strong> and re-upload the file.',
    'Mursion.Portal.Import.User.ReadyToUpload': 'When your file is ready and properly formatted, please upload file for validation:',
    'Mursion.Portal.Import.User.ValidatingYourDataUntilTheUpload': 'We’re validating the data and importing users. Please check back in 15 minutes or when you receive an alert that this process is complete.',
    'Mursion.Portal.Import.User.UploadSuccessTitle': '{successCount} Users Imported!',
    'Mursion.Portal.Import.User.ErrorContinueTitle': 'Continue without correcting errors!',
    'Mursion.Portal.Import.User.ErrorContinue': '{failedCount} users failed to import as the errors were not fixed.',
    'Settings.ScenarioBank.Form.ArtBundlesWarning':'Please rename the art bundle(s) from default names',
    'Mursion.Portal.Import.User.Instruction.Upload': 'Click <strong>Download Excel Template</strong> below and open the downloaded file (either <strong>client_internal_users.xlsx</strong> or <strong>client_external_users.xlsx</strong>).',
    'Mursion.Portal.Import.User.Instruction.Format': '<strong>In the template, the email, role and SSO ID fields are required – Do not leave these blank. Add the user information correctly under each header.</strong> DO NOT change the headers, template structure or filename as this could result in a failed import.',
    'Mursion.Portal.Import.User.Instruction.NewUser': 'In <strong>Columns J-S or F-O</strong> add the names of any Teams the user should be assigned to (make sure your spelling matches the name exactly).',
    'Mursion.Portal.Import.User.Instruction.Error': 'Once you enter all your users, save the file to your desktop or somewhere you’ll remember and click <strong>Proceed</strong>.',
    'Mursion.Portal.Import.User.Instruction.OriginalTemplate': 'If there are any errors in your file, they will be shown in a resulting spreadsheet in Step 4. Follow the instructions to correct the errors in the <strong>original template</strong> and re-upload the file.',
    'Mursion.Portal.Import.User.Instruction.UploadList': 'If you choose to continue without fixing the errors, the <strong>Download Failed Upload List</strong> file will be available for 7 days only.',
    'Mursion.Portal.Import.User.DownloadExcelTemplate': 'Download Excel Template',
    'Mursion.Portal.Import.User.UploadFileStatus': 'Uploading file',
    'Mursion.Portal.Import.User.DoNotCloseUntilUpload': 'We’re validating the data and importing users. This process will continue in the background, so you can navigate to another tab or window, and return to this screen at any time. This import process should take less than 5 minutes.',
    'Mursion.Portal.Import.User.FileStatus.validation': 'Importing users',
    'Mursion.Portal.Import.User.CorrectErrorSubHeading': 'Final step: Correct errors',
    'Mursion.Portal.Import.User.SuccessCount': '{successCount} users were successfully imported; {failedCount} users failed to import – please review.',
    'Mursion.Portal.Import.User.DownloadFile': 'Click the <strong>Download Errors</strong> button and open the file to view the errors.',
    'Mursion.Portal.Import.User.FileTabs': '<strong>In the template, the email and role fields are required – Do not leave these blank</strong>. DO NOT change the headers, template structure or filename as this could result in a failed import.',
    'Mursion.Portal.Import.User.ReviewCorrect': 'Correct the errors in the <strong>original template</strong> (either <strong>client_internal_users.xlsx</strong> or <strong>client_external_users.xlsx</strong>) and re-upload the file.',
    'Mursion.Portal.Import.User.DownloadErrorButton': 'Download Errors',
    'Mursion.Portal.Edit.Team.EditTeamTitle': 'Edit Team Memberships',
    'Mursion.Portal.Edit.Team.Instruction.ExportFile' : 'Click <strong>Download Current Memberships</strong> below to update team assignments for learners (<strong>edit_learner_team_memberships.xlsx</strong>).',
    'Mursion.Portal.Edit.Team.Instruction.UpdateFile' : 'In <strong>Columns L through U</strong>, add or remove the names of any teams the learner should be assigned to (i.e. teams to be added or updated to), one column per team. It’s okay to leave some team columns blank if needed. Please ensure this is done correctly, since <strong>this will overwrite existing team assignments</strong>.',
    'Mursion.Portal.Edit.Team.Instruction.Process' : 'Upload one file at a time - limit number to 5,000 users per file.', 
    'Mursion.Portal.Edit.Team.Instruction.ProfileFields' : 'The email and role fields are required – Do not leave these blank. Do not change the headers, template structure or filename as this could result in a failed import.',
    'Mursion.Portal.Edit.Team.Instruction.ErrorOccur' : 'Supported file formats are .xlsx only.',
    'Mursion.Portal.Edit.Team.DownloadExcelTemplate' : 'Download Current Memberships',
    'Mursion.Portal.Edit.Team.DoNotCloseUntilUpload': 'We’re validating the data and updating team assignments. This process will continue in the background, so you can navigate to another tab or window, and return to this screen at any time. This process should take less than 5 minutes.',
    'Mursion.Portal.Edit.Team.FileStatus.validation': 'Updating team memberships',
    'Mursion.Portal.Edit.Team.UploadFailureNotification': 'Your file contains more than 5k users. Please contact support or upload multiple files with users fewer than 5k.',
    'Mursion.Portal.Edit.Team.ReviewCorrect': '<strong>In the template, the email and role fields are required – Do not leave these blank.</strong> DO NOT change the headers, template structure or filename as this could result in a failed import.',
    'Mursion.Portal.Edit.Team.DownloadFile': 'Correct the errors in the original template file (<strong>edit_learner_team_memberships.xlsx</strong>) -- DO NOT change the headers, template structure or filename as this could result in a failed import.',
    'Mursion.Portal.Edit.Team.DownloadErrorButton': 'Download Errors',
    'Mursion.Settings.ScenarioBank.Modal.ChecklistURL': 'SIM Checklist URL',
    'Mursion.Settings.ScenarioBank.Modal.MustBeAValidURL': 'Must be a valid URL',
    'MursionPortal.CodeOfConduct.SchedulingFlow.Text': 'By choosing to schedule your Mursion session, you are agreeing to Mursion’s simulation delivery model and confirming that you will uphold and honor the Mursion Code of Conduct. Read more <code>here</code>.',
    'Mursion.Portal.Import.User.InvalidFile':'Only csv or excel template will be uploaded.',
    'Mursion.Portal.Import.User.MaxFileSize':'File size must not exceed 5MB',
    'Mursion.Portal.Import.User.Tooltip': 'Click here to import new users or update existing users from an export file.',
    'Mursion.Portal.Edit.Team.Tooltip': 'Click here to assign or reassign team membership in bulk.',
    'MursionPortal.Label.Completion': 'Completion Rate',
    'MursionPortal.Project.InviteLearnersToSchedule': 'Invite Learners to Schedule',
    'MursionPortal.CheckboxLabel.SchedulingByBookIt': 'Scheduling by BookIt',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing': 'Scenario Sequencing',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing.Text': 'Click button to view and configure order of scenarios in this project.',
    'Projects.ProjectInfo.Button.ScenarioSequence': 'Scenario Sequence',
    'MursionPortal.Projects.DragAndDropModal.Text': 'Drag and drop scenarios in order of preference:',
    'MursionPortal.Projects.DragAndDropModal.DragHandlerImage': 'Drag Handle Image',
    'MursionPortal.Projects.DragAndDropModal.ModalCloseIcon': 'Close Icon',
    'MursionPortal.Project.Scheduled.NextDate': 'Scheduled {nextDate}',
    'Dashboard.Report.SendReport': 'Send Report',
    'Dashboard.Report.ScheduleReport': 'Schedule Report',
    'Dashboard.Report.EditSchedule': 'Edit Schedule',
    'Dashboard.Report.ViewSchedule': 'View Schedule',
    'Dashboard.Report.DeleteSchedule': 'Delete Schedule',
    'Dashboard.Report.Filter.Title': 'Filters Selected',
    'Dashboard.Report.SelectReportFrequency.Label': 'Select report frequency',
    'Dashboard.Report.SelectSessions.Label': 'Select sessions to include',
    'Dashboard.Report.SelectReportStartDate.Label': 'Select report start date',
    'Dashboard.Report.SelectReportStartDate.Tooltip': 'If you’ve chosen to receive reports weekly, your report will come every week on the day you’ve selected. If you’ve selected monthly, it will come on that date every month.',
    'Dashboard.Report.SelectAccountOwner.Label': 'Select account owner or facilitator',
    'Dashboard.Report.Dropdown.Select.Placeholder' : 'Select',
    'Dashboard.Report.ManuallyAddRecipients.Label': 'Manually add recipients',
    'Dashboard.Report.ManuallyAddRecipients.Label.Placeholder': 'Add email here',
    'Dashboard.Report.RecipientsBox.Label': 'Recipients',
    'Dashboard.Report.RecipientsBox.RecipientMaxLimit' : 'Recipients cannot be more then 30',
    'Dashboard.Report.SendReport.ConfirmationModal.Text': 'Report has been sent to recipients.',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.HeadingText': 'Your report has been scheduled!',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.Text': 'Stop or edit anytime by clicking <strong>Edit Schedule.</strong>',
    'Dashboard.Report.EditSchedule.ConfirmationModal.HeadingText': 'Changes have been saved!',
    'Dashboard.Report.EditSchedule.ConfirmationModal.Text': 'Stop reports anytime by clicking <strong>Edit Schedule.</strong>',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.Text': 'Schedule has been deleted',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.ConfirmationText': 'Are you sure you want to delete the schedule for this report?',
    'Dashboard.Report.ConfirmationModal.Button': 'Got it!',
    'Dashboard.Report.DeleteSchedule.ConfirmationButton': 'Yes, Delete',
    'Dashboard.Report.EditSchedule.EditButton': 'Save Scheduling Edits',
    'Dashboard.Report.ReportFrequencyOption.Daily': 'DAILY',
    'Dashboard.Report.ReportFrequencyOption.Weekly': 'WEEKLY',
    'Dashboard.Report.ReportFrequencyOption.Monthly': 'MONTHLY',
    'Dashboard.Report.ReportSessionOption.AllDays': 'All Sessions',
    'Dashboard.Report.ReportSessionOption.PreviousDay': 'Sessions Previous Day',
    'Dashboard.Report.ReportSessionOption.Previous7Days': 'Sessions Previous 7 Days',
    'Dashboard.Report.ReportSessionOption.CurrentSessions': 'Current Day’s Sessions',
    'Dashboard.Report.ReportSessionOption.Next7Days': 'Sessions Next 7 Days',
    'Dashboard.Report.ReportSessionOption.MonthToDate': 'Sessions Month-to-date',
    'Dashboard.Report.ReportSessionOption.PreviousFullMonth': 'Sessions Previous Full Month',
    'Dashboard.Report.ReportSessionOption.Previous30Days': 'Sessions Previous 30 Days',
    'MursionPortal.Project.Sequencing': 'Sequencing',
    'MursionPortal.Project.Checkbox.Sequencing': 'Enable Sequencing',
    'MursionPortal.Project.InviteToScheduleProject.Tooltip': 'Learners who have not scheduled or canceled/missed scenarios in this project will receive an email invitation to schedule.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts': 'Keyboard Shortcuts',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar': 'Space bar key',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar.Text': ' Select or drop the scenario.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys': 'Right and left arrow keys or up and down arrow keys',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys.Text': ' Drag scenario left and right.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc': 'Esc key',
    'MursionPortal.Esc': 'Esc',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc.Text': ' Close Scenario Sequence Modal',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter': 'Enter key',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter.Text': ' Close keyboard shortcuts tooltip',
    'MursionPortal.Projects.DragAndDropModal.Tooltip': 'Open the keyboard shortcuts tooltip',
    'Dashboard.Report.DuplicateEntry.ErrorMessage': 'Duplicate entry is not allowed',
    'Projects.ProjectInfo.EnableSessionTimeStep': 'Enable Session Time Step',
    'Projects.ProjectInfo.SessionTimeStepInfo': 'Licensee level session time step will be used if session time step value is not set on project level ',
    'MursionPortal.Selector.RequestStatus': 'Request Status',
    'MursionPortal.Dashboard.RetakeSimulation': 'Retake Simulation',
    'MursionPortal.Dashboard.NextSimulationAssigned': 'Next Simulation Assigned',
    'MursionPortal.AriaLabel.SelectTime': 'Select time',
    'Dashboard.Report.Frequency.Date.Select.Placeholder' : 'Select Start Date',
    'MursionPortal.InstructoScenarioTeamMapping.Header' : 'Select the scenario and team you want mapped',
    'MursionPortal.InstructoScenarioTeamMapping.MappingAlreadyExistMessage' : 'This scenario and team have already been mapped to a different context.',
    'MursionPortal.DataSummaryPoints.manualFulFilled': 'Manually Fullfilled',
    'MursionPortal.DataSummaryPoints.pendingRequest': 'Pending Demand Based',
    'MursionPortal.DataSummaryPoints.declined': 'Declined Requests',
    'MursionPortal.DataSummaryPoints.avgFulfilled': 'Average Fullfill for Manual',
    'MursionPortal.DataSummaryPoints.totalSwap': 'Total Swaps',
    'MursionPortal.DataSummaryPoints.swapAccepted': 'Accepted Swaps',
    'MursionPortal.Dashboard.MissedSimulations': 'Missed Simulations',
    'MursionPortal.Dashboard.RescheduledSimulations': 'Rescheduled Simulations',
    'MursionPortal.Dashboard.BookedSimulations': 'Booked Simulations',
    'MursionPortal.Dashboard.CancelledSimulations': 'Cancelled Simulations',
    'Dashboard.UserTable.Column.In': 'In',
    'Projects.ProjectInfo.ConfirmationDialog.ScenarioSequencing.Text': 'Are you sure you want to disable sequencing? This would also remove sequence numbering in each scenario.',
    'Projects.ProjectInfo.Disable.Sequencing': 'Disable Sequencing',
    'MursionPortal.ConfirmationModal.Project.InvitationsConfirmationText' : 'Are you sure you want to invite <strong>all assigned learners</strong> who have not yet completed scenarios in the project? They will receive an email invitation to schedule overnight.',
    'MursionPortal.ConfirmationModal.Scenario.InvitationsConfirmationText' : 'Are you sure you want to invite <strong>all assigned learners</strong> who have not yet completed this scenario? They will receive an email invitation to schedule overnight.',
    'MursionPortal.ErrorMessage.TechnicalIssueMsg' : 'Please try again. We’ve encountered a technical issue.',
    'SessionFlow.Text.Event.DeletedByUsername' : 'Deleted by {user}',
    'MursionPortal.Dashboard.NextOnProject': 'Scenarios to Schedule',
    'MursionPortal.Dashboard.NextOnProject.Footer.EndDate': 'You have until {endDate} to complete this scenario.',
    'MursionPortal.Dashboard.NextOnProject.Footer.CompletionRate': 'You have until {endDate} to complete up to {completionRate} times.',
    'Settings.ScenarioBank.Modal.timestepShouldBeAtLeast': 'Either uncheck "Enable session time step" or enter time step value at least {minutes} minutes',
    'MursionPortal.Dashboard.NothingPlanned.text1': 'You’re all caught up! When you have',
    'MursionPortal.Dashboard.NothingPlanned.text2': 'something to schedule, it’ll show up here.',
    'MursionPortal.Learner.Missed': 'LEARNER MISSED',
    'MursionPortal.AriaImage.Attribute.Previous': 'Previous',
    'MursionPortal.AriaImage.Attribute.Next': 'Next',
    'MursionPortal.AriaImage.Attribute.Close': 'Close',
    'MursionPortal.AriaImage.Attribute.Back': 'Back',
	  'MursionPortal.AriaImage.Attribute.Delete': 'Delete',
	  'MursionPortal.AriaImage.Attribute.Notify': 'Notify',
    'MursionPortal.AriaImage.Attribute.Upload':'Upload',
    'MursionPortal.AriaImage.Attribute.Remove': 'Remove',
    'MursionPortal.AriaImage.Attribute.Document': 'Document',
    'MursionPortal.AriaImage.Attribute.Video': 'Video',
    'MursionPortal.AriaImage.Attribute.DeleteDocument': 'Delete document',
    'MursionPortal.Project.InviteToSchedule.Sequencing.Tooltip': 'Sequencing is set for this scenario and no learners have yet scheduled the previous scenario.',
    'MursionPortal.Report.FilterHeading.SoftwareVersions': 'Software Versions', // TODO add translations
    'MursionPortal.Report.FilterHeading.DeliveryModes': 'Delivery Modes', // TODO add translations
    'MursionPortal.Report.FilterHeading.LearnerSessionStatuses': 'Learner Session Statuses', // TODO add translations
    'MursionPortal.Report.FilterHeading.LearnerStatuses': 'Learner Statuses', // TODO add translations
    'MursionPortal.Report.FilterHeading.SimulationStatus': 'Simulation Status', // TODO add translations
    'MursionPortal.Project.EmailMissedLink.TooLateJoin.ClickRescheduleTxt': 'Click below to reschedule your simulation.',
    'MursionPortal.ScenarioInfo.Label.Until': 'You have until {date} to take this up to {completionRate} times.',
    'MursionPortal.ScenarioInfo.CompletionRate': '{completionRate} Times',
    'Clients.Modal.Button.Schedule.Tooltip.Text': 'You need to schedule the previous scenario first',
    'MursionPortal.Scenario.Name': 'Scenario Name',
    'MursionPortal.ScenarioCard.BackToProjects.Button': 'Back to Projects',
    'MursionPortal.ScenarioCard.ViewMore.Button': 'View more',
    'MursionPortal.Project.DuplicateProjectBtnTooltip': 'This would duplicate all the settings and configuration of this project to a new project',
    'MursionPortal.Project.DuplicateProjectBtnText': 'Duplicate To Magic Project',
    'MursionPortal.Project.MissedAlert.TooLateJoin': 'You arrived a little too late to join',
    'MursionPortal.Project.MissedAlert.DontWorry': 'Don\'t worry! It happens.',
    'MursionPortal.Project.MissedAlert.ClickReschedule': 'Click reschedule your simulation.',
    'MursionPortal.ScenarioInfo.Documents': 'Scenario Documents',
    'MursionPortal.ScenarioInfo.Documents.File': '{extension} File',
    'MursionPortal.ScenarioCard.ViewSessions': 'View Sessions',
    'MursionPortal.Label.ScenarioPreview': 'Scenario Preview',
    'MursionPortal.ScenarioInfo.AssignedTeams': 'Search or select Team(s):',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialists': 'Certified SIM Specialists',
    'MursionPortal.ScenarioInfo.AddSimSpecialist': 'Add Sim Specialist',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.PencilIcon': 'Edit Icon',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.DeleteButton': 'Delete SIM',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.SearchText': 'Search or select SIM Specialist(s):',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.AddSim': 'Add Sim',
    'MursionPortal.Label.InactiveSIMSpecialists': 'Inactive SIM Specialists',
    'MursionPortal.Label.Search.InactiveSIMSpecialists': 'Search or select Inactive SIM Specialist(s):',
    'MursionPortal.ErrorMessage.InvalidCaptcha': 'Invalid Captcha. Please try again.',
    'MursionPortal.Password.Strength.Tooweak': 'Password is Too weak',
    'MursionPortal.Password.Strength.Weak': 'Password is Weak',
    'MursionPortal.Password.Strength.Medium': 'Password is Medium',
    'MursionPortal.Password.Strength.Strong': 'Password is Strong',
    'Clients.Modal.Label.FacilitatorView.Tooltip': 'If set to Client Level, facilitators will be able to see Account Owner level details. If set to team level, the facilitator can only see information about the teams to which they are assigned.',
    'Clients.Modal.Label.VoiceAnalytics.Tooltip': 'Provides learners with an automated analysis of their session.',
    'Clients.Modal.Label.Show.Utilization.Reporting.Tooltip': 'Adds Utilization Reporting to the Account Owner’s Dashboard.',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays.Tooltip': 'Number of days the client would like to retain their video recording data. After the number of days, the video recordings will be deleted.',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy.Tooltip': 'Enable if the client has a custom data retention policy.',
    'MursionPortal.Button.RefreshPage': 'Refresh Page',
    'MursionPortal.Prompt.CalendarUpdatedOutdatedNotification': 'Are you sure you want to close, your calendar page would remain outdated.',
    'Reports.SessionListHeader.Label.AverageTime': 'Average time <code>in each session</code>',
    'MursionPortal.Users.Archive.HasSessions': 'Cannot archive Simulation Specialist as one or more sessions are scheduled for this Simulation Specialist. Please swap or change Sim Specialist.',
    'MursionPortal.Label.InactiveSIMCertification.Tooltip': 'Use if there’s a Sim who is certified on this simulation, but who is not available for new simulations.',
    'MursionPortal.Label.InactiveSIMCertification': 'Inactive Sim Certification',
    'Dashboard.Filters.FilterBySessionDate': 'FILTER BY SESSION DATES',
    'Dashboard.LeftPane.CategoryHeader.Emails': 'EMAILS',
    'MursionPortal.Label.SoftwareType.Tooltip': '"Web Only" indicates Learners can only access their sessions via Mursion Magic, the browser-based platform.',
    'Projects.ProjectInfo.Label.CancellationDeadline': 'Cancelation Deadline',
    'Projects.ProjectInfo.Label.CancellationDeadline.Tooltip': 'This is the number of days/hours/minutes a learner would have to cancel, once registered for a Simulation within the project. If learners cancel within the deadline, the session will be considered complete for billing purposes.',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording': 'Allow Session Recording',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording.Tooltip': 'Uncheck to prevent recordings.',
    'MursionPortal.Label.SendRecordedVideosTo': 'Send recorded videos to:',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording': 'Allow learner to join session if learner does not consent to recording',
    'Projects.ProjectInfo.label.Tags.Tooltip': 'Tags are organizational groupings to quickly find similar projects at the client level.',
    'Projects.ProjectInfo.label.DemandTime.Tooltip': 'Timeframe when learners can request simulations for the demand slots',
    'Projects.ProjectInfo.label.DemandSlots.Tooltip': 'Sets the number of scheduling options a learner sees when scheduling. If set to zero, the learner will be able to instantly book an open time slot.',
    'Projects.ProjectInfo.label.Rescheduling.Tooltip': 'Allows learners to reschedule sessions. Note that the learner cannot book a session on the same day unless Same-day is checked.',
    'MursionPortal.Project.SameDay.Tooltip': 'Allows learners to reschedule for later in the same calendar day.',
    'Projects.ProjectInfo.Label.Timeline.Tooltip': 'All sessions for this project must be completed within the Project Timeline.',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording.Tooltip': 'Learners can opt out of recording. If unchecked, and a learner declines recording permission, they will not be able to attend the session.',
    'Projects.ProjectInfo.SchedulingInterval': 'Scheduling Interval',
    'Projects.ProjectInfo.SchedulingInterval.Tooltip': 'Enables scheduling to happen on a cadence other than the duration of the session.',
    'Projects.ProjectInfo.SchedulingIntervalLength': 'Scheduling Interval Length',
    'Projects.ProjectInfo.SchedulingIntervalLength.tooltip': 'Determines the frequency the scheduling blocks a learner sees. If set to 15, learners will see scheduling blocks in 15minute intervals. Does not set the duration of the session, only how frequently sessions run.Licensee level session time step will be used if session time step value is not set on project level',
    'Projects.ProjectInfo.DeliveryMode.tooltip': 'The platform used by your learners. Set to ML3 if you wish to enable Mursion Magic and/or ML3 direct connection software.  ML3z = Zoom.  Mursion Meet = Google Meet.',
    'MursionPortal.Label.WebOnly': 'Web Only',
    'MursionPortal.Label.DesktopOnly': 'Desktop Only',
    'MursionPortal.Label.DesktopAndWeb': 'Desktop and Web',
    'MursionPortal.EmailCategory.CallsToAction': 'Calls-to-Action',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Sent': 'Sent',
    'MursionPortal.Dashboard.EmailAnalytics.Label.AllEmails': 'All Emails',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Trends': 'Trends',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CategorySummary': 'Category Summaries',
    'MursionPortal.Dashboard.EmailAnalytics.Label.RegistrationEmail': 'Registration Emails',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CallToActionEmail': 'Calls-to-Action Emails',
    'MursionPortal.Dashboard.EmailAnalytics.Label.SchedulingEmail': 'Scheduling Emails',
    'MursionPortal.Dashboard.EmailAnalytics.Label.ReSchedulingEmail': 'Rescheduling  Emails',
    'MursionPortal.Dashboard.EmailAnalytics.Label.VideoEmail': 'Video Emails',
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailSent': 'Email Sent',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailAnalytics': 'Email analytics provide you with an overview of how learners are engaging with <link>email communications from Mursion</link>. Emails can fall into one of the following three categories',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailOpened': 'A learner has opened one or more of the emails sent to them.',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailActedOn': 'A learner has opened the email and clicked one or more links, like the <strong>Schedule</strong> or <strong>Join Simulation</strong> links.',
    'MursionPortal.Dashboard.EmailAnalytics.Info.Unread': 'A learner has not opened the email. If there are a large number of unread emails, confirm that your learners are receiving them. Check with your IT Team to confirm that <color>no-reply@mursion.com</color> is not blocked by your organization\'s spam filters.',
    'MursionPortal.Dashboard.Tooltip.RegisterEmail': 'Emails to a User inviting them to create a Mursion Account',
    'MursionPortal.Dashboard.Tooltip.CallToActionEmail': 'Reminder Emails sent by an Account Owner from the Project or Scenario Page',
    'MursionPortal.Dashboard.Tooltip.SchedulingEmail': 'Session Specific Emails. Invite to Schedule and other Join Session Emails',
    'MursionPortal.Dashboard.Tooltip.ReschedulingEmail': 'Emails prompting a learner to Reschedule after a missed or canceled simulation',
    'MursionPortal.Dashboard.Tooltip.VideoEmail': 'Emails alerting a learner that a video of their simulation is available to view',
    'MursionPortal.Dashboard.EmailCategoryFilter': 'Email Category',
    'MursionPortal.Dashboard.EmailCategoryFilter.Search': 'Search Email Categories...',
    'MursionPortal.Dashboard.EmailTypeFilter.SessionRescheduled': 'Session Rescheduled',
    'MursionPortal.Dashboard.EmailTypeFilter.Search': 'Search Email Types...',
    'MursionPortal.Text.NoImageAvailable': 'No Image Available',
    'MursionPortal.Project.CancelationDeadline.Validation': 'Cancelation Deadline should be greater than 0.',
    'ClientUserTable.AddNewUsers.RolesHeading': 'You can only add one kind of user at a time. Select the kind of user you want to add.',
    'ClientUserTable.AddNewUsers.Roles.AccountOwner.Tooltip': 'An Account Owner is the primary administrator for your entire organization.',
    'ClientUserTable.AddNewUsers.Roles.Facilitator.Tooltip': 'A Facilitator is a team-level administrator who manages a specific team or teams.',
    'ClientUserTable.AddNewUsers.Roles.Learner.Tooltip': 'A Learner is anyone attending a Mursion simulation. This will be the bulk of your user base.',
    'ClientUserTable.AddNewUsers.Heading': 'Add New User(s)',
    'ClientUserTable.AddNewUsers.Roles.Heading': 'Assign a role to the users',
    'ClientUserTable.AddNewUsers.ImportUsers': 'Please complete the steps below for up to 500 users. If importing more than 500, use our "<code>Import Users</code>" feature.',
    'ClientUserTable.AddNewAttributes.label': 'Add user attributes',
    'ClientUserTable.AddNewAttributes.Header.Text': 'Note that these attributes will be applied to all users in this list.',
    'ClientUserTable.UserAuthentication.label': 'User Authentication',
    'ClientUserTable.NewUsersAdded.Heading': 'New User(s) Added!',
    'ClientUserTable.AddNewUsers.AssignTeams.Heading': 'Assign team(s) to the users',
    'ClientUserTable.AddNewUsers.AssignTeams.CreateTeam': 'Learners and Facilitators must be assigned to a team. If you haven\'t created a team yet please <code>click here.</code>',
    'ClientUserTable.AddNewUsers.AssignTeams.SelectTeams': 'Select Team(s)',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Validation': 'Please add 500 or fewer users. To import more than 500 users you can use our "Import Users" feature.',
    'ClientUserTable.AddNewUsers.AssignTeams.Validation': 'You must select at least one team.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Heading': 'Add user email addresses',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.SubHeading': 'You can add one email at a time or paste a list. Emails should be separated by a space, comma or semicolon.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Placeholder': 'Type or paste email addresses here.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.MaximumUsersAllowed': '{maximumUsersAllowed} users at a time',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Total': 'Total',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Duplicate': 'Duplicate',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.AlreadyExists': 'Already Exists',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Invalid': 'Invalid',
    'ClientUserTable.AddNewUsers.AddNewAttributes.SignLanguageInterpreter': 'Sign-Language Interpreter',
    'ClientUserTable.AssignTeams.TotalUsers': 'You\'re about to add <code>{users} new {role}</code>',
    'ClientUserTable.AssignTeams.AddAndUpdateUsers': 'You\'re about to add <code>{users} new {role}</code> and update <code>{existingUsers} existing {role}</code>',
    'ClientUserTable.AssignTeams.UpdateUsers': 'You\'re about to update <code>{existingUsers} existing {role}</code>',
    'ClientUserTable.AssignTeams.NumberOfTeams': 'Will be assigned to <code>{numberOfTeams} team(s)</code>',
    'ClientUserTable.Confirmation.Heading': 'Please confirm before adding',
    'MursionPortal.InviteToSchedule.Confirmation.Text': 'Learn more here: <code>Invite to Schedule</code>',
    'MursionPortal.AccountOwnerGuides.Confirmation.Text': 'Account Owner Guides',
    'MursionPortal.FacilitatorGuides.Confirmation.Text': 'Facilitator Guides',
    'MursionPortal.Users.Confirmation.Text': 'Your user(s) have been added to the Mursion portal.',
    'MursionPortal.Confirmation.Header.Text.BackToUsers': 'Back To Users',
    'MursionPortal.Users.Next.Steps.Text': 'Next Steps',
    'MursionPortal.Users.StepOne.Text': 'Step 1:',
    'MursionPortal.Users.StepTwo.Text': 'Step 2:',
    'MursionPortal.Users.StepThree.Text': 'Step 3:',
    'MursionPortal.Users.ThatIt.Text': 'That’s it!',
    'MursionPortal.Users.InviteToScheduleLearner.Text': 'Invite your Learners to Schedule',
    'MursionPortal.Users.LearnersLink.Text': 'You can send this link to your learners',
    'MursionPortal.Users.InviteToSchedule.ProjectorScenario.Text': 'Use the <strong>Invite to Schedule</strong> button on any project or scenario.',
    'MursionPortal.Users.CopyInviteLink.Text': 'Copy Invite Link',
    'MursionPortal.Users.ShareContent.Text': 'Share Support Content with your Admins',
    'MursionPortal.Users.AOAndFacilitators.Responsibilities.Text': 'Account Owners and Facilitators have different responsibilities and may need to learn more about the portal. Share these links with your Account Owners and Facilitators so they can find answers to any questions they may have along their Mursion journey.',
    'MursionPortal.Users.Administrators.Text': 'Your Administrators will now have access to the Mursion portal.',
    'MursionPortal.Users.Progress.Text': 'Your learners will begin scheduling their simulations. You can track their progress on your Dashboard.',
    'MursionPortal.Users.Access.Text': 'Make sure your IT Department has given these users access to the Mursion App',
    'MursionPortal.Users.Registrationemail.Text': 'Your users should have just received their registration email from no-reply@mursion.com.',
    'MursionPortal.AddMoreUsers.Btn.Text': 'Add More Users',
    'MursionPortal.Users.Ok.Btn.Text': 'OK, got it!',
    'Users.Button.AddNewUsers': 'Add New Users', 
    'MursionPortal.Project.CancelationDeadline.UpperLimit.Validation': 'Cancelation Deadline can\'t be greater than 365 days.',
    'MursionPortal.Dashboard.EmailTypeFilter.PasswordReset': 'Password Reset',
    'MursionPortal.Dashboard.EmailTypeFilter.Verification': 'Verification',
    'MursionPortal.Dashboard.EmailTypeFilter.InviteToSchedule': 'Invite-to-schedule',
    'MursionPortal.Dashboard.EmailTypeFilter.Summary': 'Summary',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleMissed': 'Reschedule Missed',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleCancelled': 'Reschedule Cancelled',
    'MursionPortal.Dashboard.EmailTypeFilter.PSRescheule': 'PS Reschedule',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingCorrupted': 'Video Recordings Corrupted',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingNotUploaded': 'Video Recordings Not Uploaded',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingAvailable': 'Video Recording Available',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingShared': 'Video Recording Shared',
    'MursionPortal.Dashboard.EmailTypeFilter.Booking': 'Booking',
    'MursionPortal.Dashboard.EmailTypeFilter.CodeOfConuct': 'Code of Conduct',
    'MursionPortal.Dashboard.EmailTypeFilter.UserRemoved': 'User Removed',
    'MursionPortal.Dashboard.EmailTypeFilter.AttendanceReminer': 'Attendance Reminder',
    'Users.Button.AddNewLearners': 'Add New Learners',
    'MursionPortal.Scenario.Label.LastDelivered': 'Last Delivered',
    'MursionPortal.Scenario.Label.TotalDeliveries': 'Total Deliveries',
    'MursionPortal.Scenario.Text.NotYetDelivered': 'Not Yet Delivered',
    'ClientUserTable.AddNewUsers.UpdateUserWarning': 'These {existingUsers} users already exist. Proceeding will add these users to the teams selected (in addition to any teams they’re already assigned).',
    'MursionPortal.AddNewUsers.Facilitators': 'Facilitator(s)',
    'ClientUserTable.AddNewUsers.Learners': 'Learner(s)',
    'MursionPortal.Scheduling.TimeSlotsUnavailableMessage': 'The selected timeslot(s) are no longer available. Please refresh to view schedulable timeslots',
    'MursionPortal.Client.EmailTypeFilter.SessionFirstReminder': 'Session First Reminder',
    'MursionPortal.Client.EmailTypeFilter.SessionSecondReminder': 'Session Second Reminder',
    'Projects.ProjectInfo.Label.ShowSIMAvailaiblity': 'Show SIM Availability',
    'Projects.ProjectInfo.Label.ShowAvailaiblity.Tooltip': 'When a learner schedules, they’ll see SIM Specialist availability for the range you select.',
    'Projects.InputValidation.GreaterThanZero': 'Should be greater than 0.',
    'MursionPortal.Weeks': 'Week(s)',
    'MursionPortal.Scenario.CompletionRateFulfilled': 'You cannot schedule this simulation as you have completed the maximum number of simulations for this scenario or engagement as set by your organization.',
    'MursionPortal.Dashboard.EmailTypeFilter.TimeSlotRequest': 'Time Slot Request',
    'MursionPortal.Dashboard.EmailAnalytics.OpenAndActed.Label': 'Open and Acted on data is accurate as of Nov 24, 2022.',
    'MursionPortal.Dashboard.EmailAnalytics.Prior.Label': 'Prior to this date all emails are categorized as Unread.',
    'MursionPortal.Learners.Filters.LearnerCompletion': 'Learner Completion',
    'MursionPortal.Learners.Filters.LearnerCompletion.NotYetCompleted': 'Not yet completed',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedOneSession': 'Completed 1 session',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedTwoPlusSessions': 'Completed 2+ sessions',
    'MursionPortal.Learners.Filters.MissedLateActivity': 'Missed/Late Activity',
    'MursionPortal.Learners.Filters.MissedLateActivity.1Session': '1 session',
    'MursionPortal.Learners.Filters.MissedLateActivity.2Sessions': '2 sessions',
    'MursionPortal.Learners.Filters.MissedLateActivity.3Sessions': '3 sessions',
    'MursionPortal.Learners.Filters.MissedLateActivity.4PlusSessions': '4+ sessions',
    'MursionPortal.BackToCompletedSessions': 'Back to Completed Sessions',
    'MursionPortal.Scenario.InviteToSchedule.Modal.Text': 'Would you like to send invitations to <strong>all</strong> assigned learners who have not yet completed this scenario or <strong>select learners</strong> to invite?',
    'ClientUserTable.AddNewUsers.Procced.Button.Tooltip': 'Please remove any duplicate or invalid emails before proceeding.',
    'MursionPortal.BackToSessionSummaries': 'Back to Session Summaries', 
    'MursionPortal.BackToLearnerSessions': 'Back to Learner Sessions', 
    'MursionPortal.LearnerSessionReport.SessionObjective':'Session objective', // TODO add translations
    'MursionPortal.LearnerSessionReport.SessionStrategy':'How well did you apply the strategies?', // TODO add translations
    'MursionPortal.LearnerSessionReport.SessionStrategyIcon':'Session Strategy Bullseye Target', // TODO add translations
    'MursionPortal.LearnerSessionReport.SessionPerspective.Title':'Keep at it!', // TODO add translations
    'MursionPortal.LearnerSessionReport.SessionDetail':'Scenario Description', // TODO add translations
    'MursionPortal.LearnerSessionReport.SessionDate':'Session Date', // TODO add translations
    'MursionPortal.BackToSessionDetail': 'Back to Session Detail',
    'MursionPortal.SimAttendance.Instructions.Label': 'Mursion Simulation Specialists:',
    'MursionPortal.SimAttendance.Instructions.LabelText': 'Select an attendance status for each attendee (mouse over the status to learn more). Your status is gathered automatically – these statuses are for the learner ONLY.',
    'MursionPortal.SimAttendance.Instructions.Label1': 'Software License Simulation Specialists:',
    'MursionPortal.SimAttendance.Instructions.LabelText1': 'Select an attendance status for each attendee (mouse over the status to learn more). If you experienced an error in the session, please also provide the error details. To do this, select the type of error under the “SIM Reported Error” section and add context in the Additional Details text box. Additional details about attendance--even if it’s not an Error--are encouraged, but are optional. If you have any further questions about attendance, please reach out to your Simulation Program Admin.',
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailAnalytics': 'Email Analytics',
    'MursionPortal.Session.LearnerSession.Report': 'Report', 
    'MursionPortal.Session.LearnerSession.Recording': 'Recording', 
    'MursionPortal.Session.LearnerSession.SessionDetails': 'Session Details', 
    'MursionPortal.Session.LearnerSessionReport': 'Learner Session Report', 
    'MursionPortal.Scenario.InviteToScedule.Modal.SelectLearners': 'Select learners',
    'MursionPortal.Scenario.Button.Label': 'Copy Learner Emails',
    'MursionPortal.VideoStatusFilter.Label': 'Video Status',
    'MursionPortal.TooltipText.VideoNotUploaded': 'If this video is not uploaded after 48 hours, please contact support.',
    'MursionPortal.VideoStatusFilter.SearchText': 'Search Video Status...',
    'MursionPortal.VideoStatusFilter.Uploaded': 'Uploaded',
    'MursionPortal.VideoStatusFilter.NotUploaded': 'Not Uploaded',
    'MursionPortal.Scenario.Invite.Modal.Button.Label': 'Invite',
    'MursionPortal.Scenario.CopyEmails.Modal.Button.Label': 'Copy Emails',
    'MursionPortal.Scenario.AllLearners.InvitationsSentOvernight': 'Your invitations will be sent overnight if the learner hasn\'t already scheduled or received an invite in the last 2 days.',
    'MursionPortal.Scenario.SelectedLearners.InvitationsSentOvernight': '<code>Selected learners</code> ({count}) will receive an email overnight inviting them to schedule, if they haven\'t already scheduled or received an invite in the last 2 days.',
    'MursionPortal.Scenario.SelectedLearners.Confirm.Text': 'Are you sure you want to invite <code>all selected learners</code>',
    'ClientUserTable.Column.LastInvited': 'Last Invited',
    'ClientUserTable.ColumnTooltip.LastInvited': 'The date of the last email invitation to schedule was sent. This may be tomorrow if just invited.',
    'MursionPortal.Scenario.SelectedLearners.EmailsCopied': 'Email addresses of selected learners have been copied to your clipboard',
    'MursionPortal.ScenarioInfo.MarkAllInactive': 'Mark All Inactive',
    'MursionPortal.ScenarioInfo.DecertifyAll': 'Decertify All',
    'MursionPortal.ScenarioInfo.MarkAllInactive.Confirmation.Text': 'Are you sure you want to inactive all SIM Specialists?',
    'MursionPortal.ScenarioInfo.DecertifyAll.Confirmation.Text': 'Are you sure you want to decertify all SIM Specialists?',
    'Dashboard.Button.ViewSession' : 'View Session',
    'MursionPortal.SessionReport.NotAvailable' : 'Not Available',
    'Projects.ProjectInfo.Checkbox.EnableReportTabSurvey.OneToOne': 'Enable Report tab for 1:1 deliveries', 
    'MursionPortal.Learner.Session.Report' : 'Learner Session Report',
    'MursionPortal.Scenario.Sim.InactivedAll': '{effectedSims} out of {totalSims} SIMs inactivated.',
    'MursionPortal.Scenario.Sim.DecertifiedAll': '{effectedSims} out of {totalSims} SIMs decertified.',
    'MursionPortal.Scenario.Sim.DecertifiedFew': '{remainingSims} SIMs cannot be decertified because they have sessions scheduled. Mark them inactive to honor their sessions and prevent future session assignments.',
    'MursionPortal.AriaLabel.ViewMore': 'View More',// TODO add translations
    'MursionPortal.SimAttendance.SimulationAttendance': 'Simulation Learner Attendance',
    'Mursion.Portal.Status.LearnerTechnicalError': 'Learner Technical Error',
    'Mursion.Portal.Status.TooLateToComplete': 'Too Late to Complete',
    'Mursion.Portal.Status.LearnerElectedToLeave': 'Learner Elected to Leave',
    'Mursion.Portal.Status.Unprepared.Tooltip': 'Learner joined but did not complete the simulation due to being unprepared or for other reasons.',
    'MursionPortal.Label.SchedulingRate': 'Scheduling Rate',
    'Mursion.Portal.Status.No.Show': 'No Show',
    'MursionPortal.ScenarioInfo.SchedulingRate.Text': '{code} Times',
    'MursionPortal.Scenario.Modal.Search.Placeholder': 'Search email address, first name, last name',
    'MursionPortal.Scenario.SchedulingRateFulfilled': 'You\'ve to complete the scheduled session first.',
    'MursionPortal.Scenario.InviteToScedule.Modal.Button.Tooltip': 'Select Learner to invite',
    'MursionPortal.Learner.Session.Report.Report.SimulationRecording': 'Reflect on the conversation by playing your simulation recording',
    'Dashboard.SimulationTable.ColumnTooltip.SoftwareVersion': 'Denotes session software used',
    'Dashboard.SimulationTable.Column.SoftwareVersion': 'Software',
    'MursionPortal.Users.EditUser.InactivatedAll.Message': '{code} Certificate(s) got inactivated.',
    'MursionPortal.Users.EditUser.InactivateAll.Confirmation': 'Are you sure you want to inactivate all certificates for this SIM Specilaist?',
    'MursionPortal.Users.Modal.ActiveCerts.EmptyMessage': 'No active certificates',
    'MursionPortal.Users.Modal.InactiveCerts.EmptyMessage': 'No inactive certificates',
    'MursionPortal.Users.Modal.ActiveCerts': 'Active Certificates',
    'MursionPortal.Users.Modal.InactiveCerts': 'Inactive Certificates',
    'MursionPortal.Users.ActiveCerts.MarkAllInactive.Button': 'Mark All Inactive',
    'MursionPortal.LearnerSessionReport.YourPerspective': 'Your perspective',
    'MursionPortal.LearnerSessionReport.YourPerspectiveDescription': 'Your confidence going into the simulation was <strong>{preConfidenceLevel}</strong> and after the simulation was <strong>{postConfidenceLevel}.</strong>',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryLow': 'very low',
    'MursionPortal.LearnerSessionReport.YourPerspective.Low': 'low',
    'MursionPortal.LearnerSessionReport.YourPerspective.Moderate': 'moderate',
    'MursionPortal.LearnerSessionReport.YourPerspective.High': 'high',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryHigh': 'very high',
    'MursionPortal.LearnerSessionReport.Strategies.SuccessfullyStrategy': 'You <strong>successfully</strong> demonstrated this strategy',
    'MursionPortal.LearnerSessionReport.Strategies.BenefitStrategy': 'You’d <strong>benefit from some more practice</strong> implementing this strategy',
    'MursionPortal.AriaLabel.NormalFont': 'Normal Font',
    'MursionPortal.AriaLabel.MediumFont': 'Medium Font',
    'MursionPortal.AriaLabel.LargeFont': 'Large Font',
    'MursionPortal.AriaLabel.Font': 'Font',
    'Dashboard.LeftPane.CategoryHeader.RescheduledAndCancelled': 'Rescheduled & Cancelled',
    'MursionPortal.SchedulingRate.ValidationMessage': 'Scheduling Rate must be less than or equal to the Completion Rate',
    'MursionPortal.LearnerSessionReport.Footer.FooterTitle': 'Next steps: Practice makes perfect!',
    'MursionPortal.LearnerSessionReport.Footer.FooterSubTitle': 'Repetition is the key to making these skills second nature.',
    'MursionPortal.LearnerSessionReport.SessionObjective.Body': 'Your report will be ready shortly',
    'MursionPortal.Label.MissCancellationRate': 'Miss/Cancellation Rate',
    'MursionPortal.sessionTable.Column.OriginalSim': 'Original SIM',
    'MursionPortal.sessionTable.Column.NewSim': 'New SIM',
    'MursionPortal.Dashboard.Summary.Certifications': 'Certifications',
    'MursionPortal.SessionsTable.ActionTitle.Pickup': 'pick up',
    'MursionPortal.Table.ColumnSelection.Button.Label': 'Columns',
    'Session.Table.Column.SubmitRequest': 'Submit Request',
    'MursionPortal.SurveyFilter.Incomplete': 'Incomplete',
    'MursionPortal.SurveyFilter.SearchText': 'Search Survey...',
    'MursionPortal.Dashboard.SurveyReminder.Text': 'You have {surveyCount} surveys to complete',
    'MursionPortal.Dashboard.RemindMeLater': 'Remind me later',
    'MursionPortal.Dashboard.SurveysIncomplete': 'Surveys Incomplete',
    'MursionPortal.Scenario.CharacterLimitExceeded.Message': 'You’ve exceeded the character limit by {exceededCharLength} characters. Please revise.',
    'MursionPortal.Scenario.AddedCharacter.Message': '{added} of {totalChar}',
    'MursionPortal.Scenario.CharacterLimit.Message': 'Character limit: {maxLength}',
    'MursionPortal.Scenario.Description.block.default.text': 'Add Description here',
    'MursionPortal.Scenario.AttachmentField.text': 'You can add more files',
    'MursionPortal.Dashboard.NextOnProject.Footer.schedulingRateFulfilled.Text': 'You cannot schedule this simulation at this time as you have currently reached the scheduling limit for this scenario or engagement set by your organization.',
    'MursionPortal.Label.NotAllowed': 'Not Allowed',
    'Dashboard.LeftPane.SupplyManagement.SimView': 'Sim View',
    'Dashboard.LeftPane.SupplyManagement.SchedulingView': 'Scheduling View',
    'Filters.SimSpecialistStatus': 'Sim Specialist Status',
    'Filters.SimSpecialistStatusPlaceHolderHint': 'Search Sim Specialist Status filter...',
    'Users.TableModal.SSOUserID': 'SSO user ID',
    'MursionPortal.UnavailableSlotsError.SorryText': 'Hello! I am so sorry!',
    'MursionPortal.UnavailableSlotsError.BusyText': 'It looks like we are busy simulating with other learners.',
    'MursionPortal.UnavailableSlotsError.TryAgainText': 'Please try us again tomorrow or another day!',
    'MursionPortal.Session.Survey.ClickHere': 'Click here',
    'MursionPortal.Session.SurveyNotLoading': 'Survey not loading?',
    'Users.Table.Heading.Viewonly': '(View only)',
    'MursionPortal.BookItUI.BusyText': 'It looks like we are busy simulating with other learners at the moment.',
    'MursionPortal.BookItUI.TryLaterText': 'More simulation sessions should open up soon, please try again later today.',
    'MursionPortal.BookItUI.MoreSimulationsText': 'More simulation sessions should open up soon.',
    'MursionPortal.BookItUI.ComeBackText': 'Please come back if you want to do your simulation later today.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Archived': 'Archived User',
    'ClientUserTable.AddNewUsers.UpdateArchivedUserWarning': '{archivedUsers} user(s) are currently archived. Proceeding will add these users to the teams selected and unarchive the users.',
    'ClientUserTable.AddNewUsers.UpdateExistingArchivedUserWarning': 'These {existingUsers} users already exist and {archivedUsers} users are currently archived. Proceeding will add these users to the teams selected and also unarchive the archived users.',
    'MursionPortal.ProjectCreateStep.UpperLimitValidation.Hours': 'Can\'t be greater than {code} hours',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability': 'Upcoming Availability',
    'MursionPortal.Table.ColumnHeader.TotalSession': 'Total Session',
    'MursionPortal.Table.ColumnHeader.ActiveCertifications': 'Active Certifications',
    'MursionPortal.Button.Decline': 'Decline',
    'MursionPortal.Having.Issues.Logging.In': 'Having issues logging in?',
    'MursionPortal.Label.Call': 'Call:',
    'MursionPortal.Project.Hours': 'Hour(s)',
    'Users.Button.ProfService': 'Professional Service',
    'Users.Button.Operations': 'Operations Service',
    'Users.Button.SimDesigner': 'Sim Designer',
    'MursionPortal.ArtProject.Placeholder.EnterOrSelect': 'Select or Enter',
    'MursionPortal.Setting.LearnerCommunicationPersonalization': 'Learner Communication Personalization',
    'MursionPortal.LearnerCommunicationPersonalization.Heading': 'You can customize specific messages for your learners here.',
    'MursionPortal.SupplyBasedScheduling.SubmitPage.SimulationConfirmation': 'You are confirmed for your simulation! We recommend coming in few minutes before the start time.',
    'MursionPortal.DemandBasedScheduling.SubmitPage.LearnerConfirmed': '{learners} confirmed for the simulation!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.DateAndTime': 'Date and Time',
    'MursionPortal.Personalized.LearnerDashboard.Section': 'Message you want all your learners to see on the Learner Dashboard',
    'MursionPortal.Personalized.SchedulingPage.Section': 'Message you want all your learners to see on the Scheduling Page',
    'MursionPortal.Personalized.Section.Tooltip': 'The message will get updated in all the places',
    'MursionPortal.CheckboxLabel.JoinNow': 'Join Now',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability.Tooltip': 'Client roles would be shown slots disabled for given \'Upcoming Availability\' hrs after \'Sim Availability\'. If \'Upcoming Availability\' is 0, no disabled slots would be shown and only \'Sim Availability\' slots would be displayed. If \'Upcoming Availability\' is 24 hrs, all available slots after \'Sim Availability\' hours would be shown disabled slots for a day.',
    'MursionPortal.LearnerDashboard.JoinNow.OutsideOfficeHoursText' :'Please come back later during your organization\'s office hours times to take this scenario.',
    'MursionPortal.Setting.Personalization.LastUpdated': 'Last Updated:',
    'MursionPortal.CheckboxLabel.JoinNowRequests': 'Join Now Requests',
    'MursionPortal.LearnerDashboard.JoinNow.HeaderText': 'We are matching you with an avatar <code>for a live simulation</code>. Please review the materials below to prepare.',
    'MursionPortal.LearnerDashboard.JoinNow.SorryText': 'We are sorry!',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsBusyText': 'All of our avatars are busy with other learners. Please come back later or try again in a few minutes.',
    'MursionPortal.LearnerDashboard.JoinNow.CongratsText': 'Congrats! You have been matched with an avatar!',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsMatchText': 'The avatar is waiting for you! Click Join Live Simulation when you\'re ready or your experience will automatically begin after the countdown.',
    'MursionPortal.LearnerDashboard.JoinNow.StartLearning': 'If you are not able to get connected, click below to start learning.',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.MyLearning': 'Starbucks MyLearning',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.LiveSimulationExp': 'This session is a live simulation experience. Partners can now choose to take an eLearning on de-escalating conflict in MyLearning, as an alternative to the live safety simulation.',
    'MursionPortal.Button.Leave': 'Leave',
    'MursionPortal.Personalized.ScenarioPage.Section': 'Message you want learners to see on Scenario Page',
    'MursionPortal.Personalized.CreateNewScenario.Button.Label': 'Create new for specific scenario',
    'MursionPortal.Personalized.Scenario.Search.Placeholder': 'Search or select Scenario(s):',
    'MursionPortal.Personalized.Scenario.Selection.Title': 'Add New Specific Scenario',
    'MursionPortal.Personalized.NoMessage': 'Currently no message set',
    'MursionPortal.Personalized.MetaData.NoHistory': 'No history yet.',
    'MursionPortal.JoinNow.AlreadyBooked.Message': '{code} is booked for a session at this time.',
    'Users.Table.SendInvite.TooltipText': 'Disabling registration emails also disables ‘click-to-invite’ buttons in the portal',
    'MursionPortal.JoinNow.AlreadyBooked.JoinSession.Message':'Click Join Live Simulation to join or resume the session.',
    'Dashboard.LeftPane.CategoryHeader.Scenarios': 'SCENARIOS',
    'Dashboard.LeftPane.Learners.TeamAndProjectActivity': 'Team & Project Activity',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetScheduled': 'Not Yet Scheduled',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.Scheduled': 'Not Yet Completed',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': 'Completed',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetAssigned': 'Not Yet Assigned',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetScheduled': 'Distinct number of learners who are assigned to at least one team that’s assigned to at least one scenario but the learner has not ever scheduled a session.', 
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.Scheduled': 'Distinct number of learners who scheduled at least one session but haven’t yet attended and completed at least one session.', 
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.CompletedAssigned': 'Distinct number of learners that have completed at least one session.', 
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetAssigned': 'Distinct number of learners who are not assigned to a team or learners that are part of a team that is not assigned to any scenario.', 
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerStatus':'Learner Completion',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerSequence':'Unique Learners',
    'MursionPortal.Dashboard.ScenarioLearner.Header.SequenceCount':'(Maximum of {sessionCount} sessions)',
    'Dashboard.SequenceOrder.Header.Title' : 'Missed, Late Cancelation & Late Reschedule Activity',
    'MursionPortal.Integrations': 'Integrations',
    'MursionPortal.Personalized.Message.Delete.Title': 'Are you sure you want to delete the message?',
    'MursionPortal.Personalized.Message.Delete.Tooltip': 'Delete Message',
    'MursionPortal.Personalized.Scenario.Message.OnlyOneAtTime': 'Changes can be made to only one message at a time. Please save or cancel the one already in edit mode.',
    'MursionPortal.Error.Time': '6am - 10pm ET',
    'MursionPortal.CheckboxLabel.JoinNow.Tooltip.Text': 'Scheduling by BookIt is always enabled when Join Now is enabled.',
    'MursionPortal.JoinNow.FinalizedScenario.Message': 'Scenario is finalized, please come back once scenario is active.',
    'MursionPortal.CheckboxLabel.sessionMissedTime': 'Session Missed Time',
    'MursionPortal.Title.Seconds': 'Second(s)',
    'MursionPortal.RequestTable.Accept.ErrorMessage': 'Sorry session is Cancelled.',
    'MursionPortal.Client.EmailTypeFilter.SchedulingLocked': 'Scheduling Locked',
    'MursionPortal.Client.EmailTypeFilter.SchedulingUnlocked': 'Scheduling Unlocked',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time': 'Scheduling Lockout',
    'MursionPortal.Table.ColumnHeader.SimAvailable': 'Sim Available',
    'MursionPortal.CheckboxLabel.sessionMissedTime.Tooltip.Text': 'Time after which session would be marked as Missed if no learner or SIM joins.',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetCompleted':'Not Yet Completed',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedOneSession':'Completed 1 session',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedTwoPlusSession':'Completed 2+ sessions',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.OneSession':'1 Session',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.TwoSession':'2 Session',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ThreeSession':'3 Session',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.FourPlusSession':'4+ Session',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetCompleted' : 'Number of unique learners assigned to this scenario but have not yet completed it',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedOneSession' : 'Number of unique learners assigned to this scenario that have completed it once.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedTwoPlusSession' : 'Number of unique learners assigned to this scenario that have completed it two times or more.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.OneSession':'Number of unique learners who have missed, late canceled or late rescheduled one session.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.TwoSession':'Number of unique learners who have missed, late canceled and/or late rescheduled two sessions.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ThreeSession':'Number of unique learners who have missed, late canceled and/or late rescheduled three sessions.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.FourPlusSession':'Number of unique learners who have missed, late canceled and/or late rescheduled four or more sessions.',
    'MursionPortal.ScenarioBank.ItemBank.Label': 'Building Blocks',
    'MursionPortal.ScenarioBank.ItemBank.Skills': 'Skills',
    'MursionPortal.ScenarioBank.ItemBank.Domains': 'Domains',
    'MursionPortal.ScenarioBank.ItemBank.Events': 'Events',
    'MursionPortal.ScenarioBank.ItemBank.Pathways': 'Pathways',
    'MursionPortal.ScenarioBank.ItemBank.Resources': 'Resources',
    'MursionPortal.ScenarioBank.ItemBank.Outcomes': 'Outcomes',
    'MursionPortal.ScenarioBank.ItemBank.Mindsets': 'Mindsets',
    'MursionPortal.ScenarioBank.ItemBank.Avatars': 'Avatars',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries': 'Research Summaries',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstructions': 'Learner Instructions',
    'MursionPortal.ScenarioBank.ItemBank.Column.Level': 'Level',
    'MursionPortal.ScenarioBank.ItemBank.Column.Domain': 'Domain',
    'MursionPortal.ScenarioBank.ItemBank.Column.Translations': 'Translations',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Skill': 'Create Skill',
    'MursionPortal.Dashboard.Label.ScenarioActivity.ErrorMessage' : 'Please select atleast one Project and Scenario from filters',
    'MursionPortal.Learner.Onboarding': 'Learner Onboarding',
    'MursionPortal.Tab.Onboarding': 'ONBOARDING',
    'MursionPortal.OrganizationName.Placeholder': 'enter the name of your organization',
    'Dashboard.LeftPane.Learners.LearnerLockedOut': 'Learner Locked Out',
    'Dashboard.Learners.LearnerLockedOut.TableHeader': 'Learners who are locked from scheduling due to miss/cancellation rate',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn': 'Date/Time Locked for Scheduling',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn': 'Date/Time Till Automated Release',
    'MursionPortal.DontHaveEmail.Text': 'Don\'t have an email address with your organization?',
    'MursionPortal.InvalidCompanyName.Error': 'Your organization requires users to log in using their email address. Please enter it above.',
    'MursionPortal.LearnerOnboardingPage.Text.Welcome': 'Welcome',
    'MursionPortal.LearnerOnboardingPage.Text.MursionVideoOverview': 'Mursion Video Overview',
    'MursionPortal.LearnerOnboardingPage.Text.KeyThingsToKnow': 'Key Things To Know',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Text': 'We are thrilled to welcome you to your Mursion learning program! Mursion uses immersive simulations driven by human-in-the-loop technology to provide a comprehensive learning experience that will equip you with the necessary tools and techniques to succeed in the ever-evolving world.',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Beginner.Text': 'Whether you are a beginner or an experienced professional, our program has something to offer everyone. We’re so excited for you to get started.',
    'MursionPortal.LearnerOnboardingPage.Video.Section.Text': 'First, watch this video overview <strong>about Mursion!</strong>',
    'MursionPortal.LearnerOnboardingPage.Scheduled.Section.Text': '<strong>Now you’re ready to:</strong>Schedule your first simulation!',
    'MursionPortal.LearnerOnboardingPage.keysThingsToKnow.Section.Heading': 'Next, let’s go through some key things to know!',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology': 'Mursion Methodology',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology.Summary': 'Mursion is grounded in research and proven to drive behavior change. Our avatar-based simulations will help you learn to respond appropriately in emotionally-charged situations.',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Text': 'Why Avatars',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Summary': 'Mursion is a more effective tool than traditional or role-play methods. Research shows that people are more trusting and curious when engaging with avatars as opposed to interacting with their peers or managers.',
    'MursionPortal.LearnerOnboardingPage.Human.Text': 'The Human in the Loop',
    'MursionPortal.LearnerOnboardingPage.Human.Summary': 'Every Mursion simulation features a  live Simulation Specialist who provides the spoken dialogue and movements for as many as five virtual avatars in each simulation.',
    'MursionPortal.LearnerOnboardingPage.Practice.Text': 'Why Practice Matters',
    'MursionPortal.LearnerOnboardingPage.Practice.Text.Summary': 'Improving leadership skills is not an easy task. But research shows that learning is most impactful when it includes practice. Mursion prioritizes practice by creating spaces that are safe, dynamic, and accessible.',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Domain': 'Create Domain',
    'MursionPortal.ScenarioBank.ItemBank.Level.L1': 'L1',
    'MursionPortal.ScenarioBank.ItemBank.Level.L2': 'L2',
    'MursionPortal.ScenarioBank.ItemBank.Level.L3': 'L3',
    'MursionPortal.Label.OrganizationName': 'Organization Name',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Pathway': 'Create Pathway',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Event': 'Create Event',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Resource': 'Create Resource',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Outcome': 'Create Outcome',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Mindset': 'Create Mindset',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Avatar': 'Create Avatar Name',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.ResearchSummary': 'Create Research Summary',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.LearnerInstruction': 'Create Learner Instruction',
    'MursionPortal.ScenarioBank.ItemBank.BackButton.Label': 'Back to Scenario Bank',
    'MursionPortal.ScenarioBank.ItemBank.Title.Placeholder': 'Type here',
    'MursionPortal.Dashboard.NextOnProject.Footer.MissOrCancelationRateFulfilled.Text': 'You cannot schedule this simulation as you have reached the missed or cancellation limit set by your organization. Please reach out to your program admin to allow scheduling.',
    'MursionPortal.ScenarioInfo.Show': 'Show',
    'MursionPortal.ScenarioInfo.CertificationHistory': 'Certification History',
    'MursionPortal.Session.AlreadyBooked': 'Sorry session is already accepted by another SIM.',
    'MursionPortal.CustomizeInvitation.Title': 'Customize your invitations',
    'MursionPortal.CustomizeInvitation.SubTitle': 'To customize, overwrite the text for each field below or leave the default text.',
    'MursionPortal.CustomizeInvitation.IntroMessage': 'Intro Message to Learners (available scenarios will auto-fill below this message):',
    'MursionPortal.CustomizeInvitation.Label.SenderName': 'Sender Name (email address will be from noreply@mursion.com)',
    'MursionPortal.CustomizeInvitation.Label.Subject': 'Subject',
    'MursionPortal.InviteLearnersTeam.Section.Title': 'What team(s) would you like to invite to schedule?',
    'MursionPortal.InviteLearnersTeam.Section.SubTitle': 'Learners will be invited to all the scenarios in this project they’re eligible to schedule',
    'MursionPortal.InviteLearnersTeam.ScenariosAssigned.Text': 'scenarios assigned',
    'MursionPortal.Confirmation.Modal.Invitations.Text': 'Your Invitations have been scheduled!',
    'MursionPortal.ScenarioSetting.ValidationMessage': 'must be less than or equal to the same setting at the client and/or project level.',
    'MursionPortal.ScenarioBank.EventDetail.Details' : 'Details',
    'MursionPortal.ScenarioBank.CharacterLimit.TitleMessage': 'Character limit: {titleMaxLength}',
    'MursionPortal.ScenarioBank.CharacterLimit.DescriptionMessage': 'Character limit: {descriptionMaxLength}',
    'MursionPortal.ScenarioBank': 'Event Template',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header': 'Inspire confidence and trust for others to share information about their work',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title': 'Domain Details',
    'MursionPortal.ScenarioBank.ItemBank.Edit.DescriptionOfUpdate.Label': 'Saving these changes will update this {type} across all scenarios. Please add a short description of the changes:',
    'Dashboard.Learners.LearnerLockedOut.LearnerColumn.Tooltip': 'User\'s name or email',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Certified': 'Certified',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Decertified': 'Decertified',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Inactive': 'Inactive',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease': 'Release Learner for Scheduling',
    'MursionPortal.CustomizeInvitation.Sender.Name': 'Mursion',
    'MursionPortal.CustomizeInvitation.Subject.Title': 'Get ready to practice your skills! Your Mursion sessions are ready to be scheduled.',
    'MursionPortal.CustomizeInvitation.Intro.Message': 'You’ve been invited to participate in a simulation experience at Mursion. Click below to schedule a session, or visit your Dashboard and explore.',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.ConfirmationText': 'Are you sure you want to release {code} learner(s) for scheduling?',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.SuccessText': '{code} learner(s) got released for scheduling.',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn.Tooltip': 'Date/time when learner got locked for scheduling',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn.Tooltip': 'Date/time when learner will get automatically released',
    'MursionPortal.ScenarioBank.ItemBank.NextGenScenario': 'NextGen Scenario',
    'MursionPortal.ScenarioBank.ResourceFilter.Book': 'Book',
    'MursionPortal.ScenarioBank.ResourceFilter.Article': 'Article',
    'MursionPortal.ScenarioBank.Filter.Label.Publisher': 'Publisher',
    'MursionPortal.ScenarioBank.ItemBank.Level.Placeholder': 'Select Level',
    'MursionPortal.ScenarioBank.ItemBank.Domain.Placeholder': 'Search or select domain:',
    'MursionPortal.Label.CompletionRate.Tooltip': 'Completion rate is how many times a learner can complete a simulation before losing the ability to schedule (locked out)',
    'MursionPortal.Label.SchedulingRate.Tooltip': 'Scheduling rate is how many times a learner can schedule a simulation before losing the ability to schedule (locked out)',
    'MursionPortal.Label.MissCancellationRate.Tooltip': 'Miss/Cancel rate is how many times a learner can miss or cancel a simulation before losing the ability to schedule (locked out)',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time.Tooltip': 'Scheduling Lockout Hours is how many hours the scheduling lockout will last',
    'MursionPortal.ScenarioBank.ItemBank.Mindset.Type.Derailing': 'Derailing',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Skill': 'Skill Details',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Mindset': 'Mindset Details',
    'MursionPortal.ProjectCreate.DeliveryHours': 'Delivery Hours',
    'MursionPortal.ProjectCreate.DeliveryHours.Tooltip': 'Timeframe for which learners will be shown instant slots',
    'MursionPortal.ProjectCreate.DeliveryHours.Start': 'Delivery Hours Start',
    'MursionPortal.ProjectCreate.DeliveryHours.End': 'Delivery Hours End',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.LearnerInstruction': 'Learner Instruction Details',
    'MursionPortal.ScenarioBank.ItemBank.Avatar.Edit.Header.Title': 'Avatar Details',
    'MursionPortal.ScenarioBank.Label.AddLink': 'Add Link',
    'MursionPortal.ScenarioBank.Year.placeholderText': 'Year',
    'MursionPortal.ScenarioBank.ItemBank.Resource.Edit.Header.Title': 'Resource Details',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Edit.Header.Title': 'Outcome Details',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Advanced': 'Advanced',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Intermediate': 'Intermediate',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Foundational': 'Foundational',
    'MursionPortal.ScenarioBank.ItemBank.Skill': 'Skill',
    'MursionPortal.Resources.Search.Text': 'Search or select resource(s)',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries.Details': 'Research Summaries Details',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary': 'Research Summary',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Pathway': 'Pathway Details',
    'MursionPortal.ScenarioBank.ItemBank.Pathway': 'Pathway',
    'MursionPortal.ScenarioBank.ConfirmationText.Archive': 'Are you sure you want to archive this {block}?',
    'MursionPortal.ScenarioBank.ConfirmationText.Unarchived': 'Are you sure you want to unarchive this {block}?',
    'MursionPortal.ScenarioBank.Unarchived': 'Unarchive',
    'MursionPortal.ScenarioBank.ItemBank.Resource': 'Resource',
    'MursionPortal.ScenarioBank.ItemBank.Mindset': 'Mindset',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction': 'Learner Instruction',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Event': 'Event Details',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives': 'Avatar Perspectives',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.Intro': 'Intro',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.DiscProfile': 'Disc Profile',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.VerbalStyle': 'Verbal Style',
    'MursionPortal.ScenarioBank.Content': 'Content',
    'MursionPortal.ScenarioBank.Content.ScenarioTitle': 'Scenario Title',
    'MursionPortal.ScenarioBank.Content.ScenarioCoverStory': 'Scenario Cover Story',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Placeholder': 'Select a bank outcome',
    'MursionPortal.ScenarioBank.Content.WhyThisMatters': 'Why This Matters',
    'MursionPortal.ScenarioBank.NextGen.AvatarGuide': 'Avatar Guide',
    'MursionPortal.ScenarioBank.NextGen.ViewScenario': 'View Scenario',
    'MursionPortal.ScenarioBank.NextGen.Event': 'Event icon',
    'MursionPortal.NextOnProject.Card.JoinNow': 'Join Live Simulation',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Title': 'How It Works',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Intro': 'Hello! You are about to get matched with an avatar shortly to <code>join a live simulation now</code>.',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Matched': 'Once you are matched with an avatar, click <code>’Join Live Simulation’</code>',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Allow': 'Click <code>’Allow’</code> for mic/camera access when you are prompted',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.StartSession': 'Click <code>’Start Session’</code> and wait for the avatar to begin your simulation',
    'MursionPortal.ScenarioBank.NextGen.OutOfSite.Label': 'Out of site, top of mind',
    'MursionPortal.FirstGen.Scenario.Button.Text': '1st Gen',
    'MursionPortal.NextGen.Scenario.Button.Text': '2nd Gen',
    'MursionPortal.NextGen.Scenario.Assessment': 'Assessment',
    'MursionPortal.NextGen.Scenario.OutOfSite': 'Out of site, top of mind',
    'MursionPortal.NextGen.Scenario.Synopsis': 'Synopsis',
    'MursionPortal.NextGen.Scenario.MinimumPoints': 'Minimum Points Required for Successful Outcome',
    'MursionPortal.NextGen.Scenario.Successful': 'Successful',
    'MursionPortal.NextGen.Scenario.OpportunityDevelop': 'Opportunity to Develop Further',
    'MursionPortal.NextGen.Scenario.LibraryInfo': 'Library Info',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DifficultyLevel': 'Difficulty Level',
    'MursionPortal.NextGen.Scenario.LibraryInfo.PathwaysAffiliation': 'Pathway Affiliation(s)',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioCoverImage': 'Scenario Cover Image',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioThumbnailImage': 'Scenario Thumbnail Image',
    'MursionPortal.NextGen.Scenario.LibraryInfo.CoverImageDescription': 'Cover Image Description',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ThumbnailImageDescription': 'Thumbnail Image Description',
    'MursionPortal.ScenarioBank.CharacterLimit.ResponseMessage': 'Character limit: {responseMaxLength}',
    'MursionPortal.ScenarioBank.EventDetail.AvatarResponseTitle': 'Avatar Possible Response(s)',
    'MursionPortal.NextGen.Scenario.ReflectionGuide': 'Reflection Guide',
    'MursionPortal.NextGen.Scenario.Scoring': 'Scoring',
    'MursionPortal.ErrorMessage.buildingBlock': 'Building Block id must be specified',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DragAndDropText': 'Drag and drop your file here or click to select files',
    'MursionPortal.NextGen.Scenario.Publish': 'Publish',
    'MursionPortal.NextGen.Scenario.Publish.Label': 'Please complete the required fields (*) in order to publish.',
    'MursionPortal.NextGen.Scenario.Last.Publish.Label': 'Last published {publishDate} by {publishedBy}',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Text': 'I don’t have time to do this right now',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Text': 'I don’t know what this is',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Text': 'I will come back to do this later',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Description.Text': 'Most people who have done this simulation found it a valuable use of their time. This will take 30 minutes or less.',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Description.Text': 'Instead of a video, this interactive practice helps you develop competencies and skills in a safe and dynamic space.',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Description.Text': 'Sure! You can come back anytime during the organizations set hours and join the live simulation to practice your skills!',
    'MursionPortal.JoinNow.CancellationText.Heading': 'Why do you want to cancel?',
    'MursionPortal.JoinNow.CancellationPage.Title': 'Practice opportunity with the avatar!',
    'MursionPortal.JoinNow.CancellationPage.Text': 'Identify positive ways to resolve the situation within Starbucks policies and guidelines and look for opportunities to create Best Moments for all customers.',
    'MursionPortal.JoinNow.JoinSimulation.Button.Text': 'Continue to Join Simulation',
    'MursionPortal.LearnerDashboard.JoinNow.SearchingAvatar': 'We are searching for an avatar <code>for your live simulation!</code>',
    'MursionPortal.LearnerDashboard.JoinNow.SuccessText': '<code>Congrats</code>, you have been matched with an avatar for your live simulation!',
    'MursionPortal.LearnerDashboard.JoinNow.ConnectingText': 'We are <code>connecting you to your avatar</code>, and your live simulation will begin shortly!',
    'MursionPortal.LearnerDashboard.JoinNow.DoNotCloseText': 'Please do not close this browser window!',
    'MursionPortal.LearnerDashboard.JoinNow.StrategiesToUse': 'Strategies to Use',
    'MursionPortal.LearnerDashboard.JoinNow.PracticeOpportunityText': 'Practice opportunity with the avatar!',
    'MursionPortal.Text.Sorry': 'Sorry',
    'MursionPortal.Text.Matching': 'Matching',
    'MursionPortal.Text.Connecting': 'Connecting',
    'MursionPortal.JoinNowScenarioInfoCard.Text': 'Please come back later or try again in a few minutes!',
    'MursionPortal.JoinNowScenarioInfoCard.HeaderText': 'All of our avatars are busy with other learners!',
    'MursionPortal.JoinNow.CancellationPage.GoBackButton.Text': 'Cancel, and go back to dashboard',
    'MursionPortal.NextGen.Scenario.Event.AddMessage': 'Add a minimum of 2',
    'MursionPortal.ScenarioBank.ItemBank.Events.AddButton':'+ Add',
    'MursionPortal.NextGen.ScenarioBank.Event.Select.Placeholder':'Select a Event',
    'MursionPortal.NextGen.ScenarioBank.Skill.Select.Placeholder':'Select a Skill',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarBehavior.Label':'Avatar Behavior*',
    'MursionPortal.NextGen.ScenarioBank.Event.LearnerResponse.Label':'Expected Learner Response*',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarAdd.Button':'Add Response',
    'MursionPortal.NextGen.ScenarioBank.AvatarResponseName.Select.Placeholder':'Select a Avatar',
    'MursionPortal.NextGen.ScenarioBank.Label.AvatarPossibleResponse': 'Possible Avatar Response(s)',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentObservedAction.Label':'Describe Observed Action',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentImapct.Label':'Explain Impact',
    'MursionPortal.NextGen.ScenarioBank.StrengthAssessment': 'Strength Assessment(s)*',
    'MursionPortal.NextGen.ScenarioBank.Button.StrengthAssessment': '+ Add Strength Assessment',
    'MursionPortal.NextGen.ScenarioBank.Label.Strength': 'Strength',
    'MursionPortal.NextGen.ScenarioBank.OpportunityAssessment': 'Opportunity Assessment(s)*',
    'MursionPortal.NextGen.ScenarioBank.Button.OpportunityAssessment': '+ Add Opportunity Assessment',
    'MursionPortal.NextGen.ScenarioBank.Label.Opportunity': 'Opportunity',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Select.Placeholder':'Select Positive Mindset',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Select.Placeholder':'Select Derailing Mindset',
    'MursionPortal.NextGen.Scenario.Event.Scoring.Title': 'Event Scoring',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Title':'Add Positive and Derailing Mindsets*',
    'MursionPortal.NextGen.Scenario.Event.Positive.Button.Text':'Add Additional Positive Mindset',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Button.Text':'Add Additional Derailing Mindset',
    'MursionPortal.Text.Searching': 'Searching',
    'MursionPortal.ScenarioContent.Label': 'Add a minimum of {min} and maximum of {max}',
    'MursionPortal.Search.Text.Skills': 'Search or select Skill(s)',
    'MursionPortal.Search.Text.Avatar': 'Search or select Avatar(s)',
    'MursionPortal.NextGen.Scenario.TimelineAndScheduling': 'Timeline & Scheduling',
    'MursionPortal.NextGen.Scenario.Event.MindsetsNavigated': 'Mindsets Navigated',
    'MursionPortal.NextGen.ScenarioBank.SubStrengthAssessment': 'Strength',
    'MursionPortal.NextGen.ScenarioBank.SubOpportunityAssessment': 'Opportunity',
    'MursionPortal.NextGen.SelectorText.Race': 'Race',
    'MursionPortal.NextGen.SelectorText.Ethnicity': 'Ethnicity',
    'MursionPortal.NextGen.SelectorText.None': 'None',
    'MursionPortal.NextGen.SelectorText.Woman': 'One must be a woman',
    'MursionPortal.NextGen.SelectorText.Man': 'One must be a man',
    'MursionPortal.NextGen.SelectorText.Wheelchair': 'One must be in a wheelchair',
    'MursionPortal.NextGen.SelectorText.agedText': 'One must be aged 55+',
    'MursionPortal.NextGen.Avatar.Restriction': 'Avatar Restriction',
    'MursionPortal.NextGen.AvatarOrScenarioComposition': 'Avatar/Scenario Composition',
    'MursionPortal.NextGen.Session.Replay': 'Session Replay',
    'MursionPortal.NextGen.StrengthsAndOpportunities': 'Strengths and Opportunities',
    'MursionPortal.NextGen.Recommendations': 'Recommendations',
    'MursionPortal.NextGen.MoreToDiscover': 'More to Discover',
    'MursionPortal.NextGen.MissionAccomplished': 'Mission Accomplished!',
    'MursionPortal.Label.Opening': 'Opening',
    'MursionPortal.Label.Closing': 'Closing',
    'MursionPortal.Label.Introduction': 'Introduction',
    'MursionPortal.ReflectionGuide.LearnerAssessment': 'Learner Assessment',
    'MursionPortal.ReflectionGuide.HostAssessment': 'Host Assessment',
    'MursionPortal.ReflectionGuide.HostOpportunity': 'Host Opportunity',
    'MursionPortal.ReflectionGuide.Introduction.Text': 'Welcome back! Do you feel ready to reflect on your experience? (allow learner to consent before continuing)',
    'MursionPortal.Label.Say': 'SAY:',
    'MursionPortal.Label.Ask': 'ASK:',
    'MursionPortal.Label.Bullet': 'Bullet',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FirstQuestion': 'How did the conversation go?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.SecondQuestion': 'How did the reality of the conversation map to your plan for the conversation?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.ThirdQuestion': 'What happened?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FourthQuestion': 'Were there any cues (verbal and nonverbal)  from the avatar that gave you a hint about how the conversation was going to go?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FifthQuestion': 'Were there any cues (verbal and nonverbal)  from the avatar that gave you a hint about how the conversation was going to go?',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FirstSay': 'This conversation had you navigate some challenging dynamics– Mickey was frustrated.',
    'MursionPortal.ReflectionGuide.LearnerAssessment.SecondSay': 'While well-intentioned, I often see leaders react in a not so productive way by:',
    'MursionPortal.ReflectionGuide.LearnerAssessment.ThirdSay': 'I also have seen see leaders react in a more productive way by:',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FourthSay': 'This conversation had you navigate some challenging dynamics',
    'MursionPortal.ReflectionGuide.LearnerAssessment.Ask': 'Where do you think you landed? (follow up: Why/ How So/ How do you know?): Where do you think you landed? (follow up: Why/ How So/ How do you know?)',
    'MursionPortal.ReflectionGuide.HostAssessment.Opening': 'If I could elevate one of the strengths you showed during the meeting (select one):',
    'MursionPortal.ReflectionGuide.HostAssessment.Closing': 'Do my reflections align with your reflections?  Why or Why not?',
    'MursionPortal.ReflectionGuide.HostAssessment.Opportunity.Opening': 'If I could elevate one of the opportunities for development that I observed (select one):',
    'MursionPortal.ReflectionGuide.Closing.Say': 'THIS IS tough stuff and I thank you for practicing these important skills with Mursion today!',
    'MursionPortal.ReflectionGuide.Closing.Ask': 'What is one thing you are taking away from this experience that you will use the next time you face a similar situation in real-life?',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary.Placeholder': 'Select a Research Summary',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction.Placeholder': 'Select a Learner Instruction',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ClinkHereToReplaceThisFile': 'Click here to replace this file.',
    'MursionPortal.NextGen.Learner.ScenarioMaterials': 'Scenario Materials',
    'MursionPortal.NextGen.Learner.PathwayAffiliation': 'Pathway Affiliation',
    'MursionPortal.NextGen.Learner.SkillLevel': 'Skill Level',
    'MursionPortal.NextGen.Learner.YourMission': 'Your mission',
    'MursionPortal.ScenarioBank.ShowOnlySecondGenscenarios': 'Show only 2nd Gen scenarios',
    'MursionPortal.NextGen.CreateNewSkill.Header.Text': 'Create a New Bank Skill',
    'MursionPortal.NextGen.Learner.StartSimulation': 'Start Simulation',
    'MursionPortal.SimMaterials.Label.CoverStory': 'Cover Story',
    'MursionPortal.SimMaterials.Label.LearnerMission': 'Learner’s Mission',
    'MursionPortal.SimMaterials.Label.Background': 'Background',
    'MursionPortal.SimMaterials.Label.PerspectivesAndIdentities': 'Perspectives & Identities',
    'MursionPortal.SimMaterials.HeaderText.SimDashboard': 'SIM Dashboard',
    'MursionPortal.NextGen.Learner.ToStart': 'to Start',
    'MursionPortal.Project.DeliveryHours.Standard': 'Standard Delivery Hours',
    'MursionPortal.Project.DeliveryHours.Global': 'Global Delivery Hours',
    'MursionPortal.Label.Monday.ShortForm': 'Mo',
    'MursionPortal.Label.Tuesday.ShortForm': 'Tu',
    'MursionPortal.Label.Wednesday.ShortForm': 'We',
    'MursionPortal.Label.Thursday.ShortForm': 'Th',
    'MursionPortal.Label.Friday.ShortForm': 'Fr',
    'MursionPortal.Label.Saturday.ShortForm': 'Sa',
    'MursionPortal.Label.Sunday.ShortForm': 'Su',
    'MursionPortal.NextGen.CreateFromBank.Customized.Label': 'Customized from bank scenario',
    'MursionPortal.NextGen.CreateFromBank.CustomizedFromBank.Tooltip': 'This scenario has been customized from the original bank scenario. Any updates made to the bank scenario will not be propagated to this scenario.',
    'MursionPortal.BuildingBlock.Label.PublishedDate': 'Published Date',
    'MursionPortal.Label.CopyTeamInviteLink': 'Copy Team Invite Link',
    'MursionPortal.NextGen.TheMission' : 'The mission:',
    'MursionPortal.NextGen.DemonstratedStrength' : 'Demonstrated Strength',
    'MursionPortal.NextGen.OpportunityForFutureGrowth' : 'Opportunity for Future Growth',
    'MursionPortal.NextGen.CompetenciesFocus':'The competencies in focus were:',
    'MursionPortal.NextGen.StrengthAndOpportunitiesText' : 'During the conversation debrief, an observed strength and an opportunity for growth were identified:',
    'MursionPortal.NextGen.WeObserved' : 'We observed....',
    'MursionPortal.NextGen.WhyThisMatters' : 'Why this matters',
    'MursionPortal.ScenarioSetting.MissOrCancelationRate.ValidationMessage': 'Scheduling Lockout Time can\'t be null if Miss/Cancellation Rate is not null.',
    'MursionPortal.NextGen.CreateFromBank.Customized.Modal.Text': 'You’ve customized a bank scenario and any future changes to the bank scenario will not be reflected in this scenario. Do you want to proceed?',
    'MursionPortal.SimMaterials.Event.SkillsInFocus.Label' : 'Skills in Focus',
    'MursionPortal.SimMaterials.Label.SimulationEvents': 'Simulation Events',
    'MursionPortal.SimMaterials.Event.AvatarBehavior.Label':'Avatar Behavior',
    'MursionPortal.SimMaterials.Event.LearnerResponse.Label':'Expected Learner Response',
    'MursionPortal.Label.NoTimeSelected': 'No Time Selected',
    'MursionPortal.Label.StartTime.EndTime.Selected': 'Both Start Time and End Time must be selected',
    'MursionPortal.SimMaterials.Label.ReflectionAndDebrief': 'Reflection & Debrief',
    'MursionPortal.NextGen.Scenario.Planning': 'Planning',
    'MursionPortal.EngagementHub.EmailLearners': 'Email Learners',
    'MursionPortal.EngagementHub.InviteToTeam': 'Invite to Team',
    'MursionPortal.EngagementHub.InviteLearnersToScheduleTitle': 'Invite your learners to start or continue their practice',
    'MursionPortal.EngagementHub.InviteLearnersToScheduleSummary': 'Engage your learners via email or by embedding shared links in your own communications.',
    'MursionPortal.EngagementHub.ViewInvitations': 'View Invitations',
    'MursionPortal.EngagementHub.CreateInvite': 'Create Invite',
    'MursionPortal.NextGen.replayConversation':'Replay the Conversation',
    'MursionPortal.HowItWorks.Experience.Text': 'Your experience will automatically begin in a few seconds!',
    'MursionPortal.HowItWorks.MicCamera': 'Mic/Camera',
    'MursionPortal.HowItWorks.Prompt.Text': 'When prompted, click <strong>‘Allow’</strong> for mic/camera access so the avatar can see and hear you!',
    'MursionPortal.HowItWorks.StartSession.Text': 'Click <strong>‘Start Session’</strong> and review the scenario description to prepare!',
    'MursionPortal.LearnerMaterials.KeyCompetencies': 'Key Competencies',
    'MursionPortal.LearnerMaterials.ResearchMaterials': 'Research Materials',
    'MursionPortal.LearnerMaterials.PracticeOpportunity': 'Practice Opportunity',
    'MursionPortal.ScenarioInfo.Label.ScenarioBank': 'Scenario is created from bank',
    'MursionPortal.NextGen.SessionReplayText' : 'This conversation was a practice opportunity for you to facilitate a conversation that supported a team member during a time of distress. Your success here was dependent on your ability to have empathy for Jo.',
    'MursionPortal.EngagementHub.BackTo': 'Back to {clientName}',
    'MursionPortal.EngagementHub.LearningPotential': 'At Mursion, we believe in maximizing every learner\'s potential.',
    'MursionPortal.EngagementHub.CommunicationLearnMore': 'Communication Is Key! Learn more',
    'MursionPortal.EngagementHub.UnlockTeamPotential': 'Unlock Your Team’s Potential',
    'MursionPortal.EngagementHub.InviteLearners.Title': 'Invite your learners to schedule their simulations',
    'MursionPortal.EngagementHub.InviteLearners.Summary': '58% of users are more likely to sign up for their simulation if you send a custom invitation to schedule.',
    'MursionPortal.EngagementHub.ManageEmailSettings.Label': 'Your Email, Your Way!',
    'MursionPortal.EngagementHub.ManageEmailSettings.Title': 'Manage email settings and gain insights',
    'MursionPortal.EngagementHub.Customize': 'Customize',
    'MursionPortal.EngagementHub.EmailConfigurations.Title': 'Email Configurations',
    'MursionPortal.EngagementHub.EmailConfigurations.Summary': 'Customize email messages that resonate with your learners and drive adoption and engagement.',
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Title': 'Powerful Email Analytics',
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Summary': 'Measure email open, click, and unread rates to discover insights and make data-driven decisions.',
    'MursionPortal.EngagementHub.PersonalizeMessaging.Label': 'Add Personal Touch',
    'MursionPortal.EngagementHub.PersonalizeMessaging.Title': 'Personalize messaging in the Mursion Portal',
    'MursionPortal.EngagementHub.LearnerDashboard': 'Learner Dashboard',
    'MursionPortal.EngagementHub.ScenarioPage': 'Scenario Pages',
    'MursionPortal.EngagementHub.SchedulingPage': 'Scheduling Page',
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackTooltip': 'This email will only go out if feedback report is enabled at project level. Please reach out to your Mursion representative to learn more.',
    'MursionPortal.EngagementHub.EmailSettings.Title': 'Email Settings',
    'MursionPortal.EngagementHub.EmailSettings.Summary': 'Use these tools to help customize different emails.',
    'MursionPortal.EngagementHub.EmailSettings.EmailName': 'Email Name',
    'MursionPortal.EngagementHub.EmailSettings.SenderName': 'Sender Name (email will be no-reply@mursion.com)',
    'MursionPortal.EngagementHub.EmailSettings.ViewCustomize': 'View/Customize',
    'MursionPortal.EngagementHub.EmailSettings.EnableDisable': 'Enable/Disable',
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockout': 'Learner Lockout',
    'MursionPortal.EngagementHub.EmailSettings.VideoFeedback': 'Video & Feedback',
    'MursionPortal.EngagementHub.EmailSettings.ModifySettings': 'Click edit to modify the settings',
    'MursionPortal.EngagementHub.BackTitle': 'Back to Engagement Hub',
    'MursionPortal.EngagementHub.CommunicationKey': 'Communication Is Key!',
    'MursionPortal.EngagementHub.CommunicationKey.summary': 'A great Mursion rollout starts with effective communication to your learners. It\'s important to ensure they are prepared and understand the connection between Mursion and your learning and development program goals.',
    'MursionPortal.EngagementHub.CommunicationKey.TItle': 'Elements of Effective Learner Communication',
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Title': 'Lead with Learner Benefits',
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Summary': 'Lead with learner benefits, not product features. Share learner testimonials and tie the skills they are developing to business objectives.',
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Title': 'Practice makes Progress',
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Summary': 'Practice is essential to developing new skills. Ensure your message highlights The Importance of Practice - And Our Reluctance To Do It to acknowledge challenges that learners face.',
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Title': 'Message from Leaders',
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Summary': 'Ask senior leaders to communicate (meetings, written, video, etc) why Mursion sessions are valuable and how developing the target skills is tied to business priorities and growth opportunities.',
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Title': 'Set Expectations',
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Summary': 'Mursion is a new technology that uses avatars powered by humans. Prepare learners for these conversations by sharing why and how avatars create psychology safety and is an effective way to practice.',
    'MursionPortal.Tab.Engagement': 'Engagement',
    'MursionPortal.Tab.EngagementHub': 'Engagement Hub',
    'MursionPortal.Tab.EmailManagement': 'Email Management',
    'MursionPortal.Tab.PortalMessaging': 'Portal Messaging',
    'MursionPortal.EngagementHub.ViewInvitations.BackButton.Label': 'Back to Invite to Schedule',
    'MursionPortal.Portal.Messaging.Heading.Text': 'Inform, engage, and delight learners using your organization’s unique brand voice.',
    'MursionPortal.Portal.Messaging.SubHeading.Text': 'Add custom messaging throughout the Portal to engage your learners.',
    'MursionPortal.EngagementHub.ViewInvitations.Column.InvitationDate': 'Invitation Date',
    'MursionPortal.EngagementHub.ViewInvitations.Column.MessageToLearners': 'Message to Learners',
    'MursionPortal.EngagementHub.ViewInvitations.Column.ExcludedLearners': 'Excluded Learners',
    'MursionPortal.EngagementHub.ViewInvitations.Column.CreatedBy': 'Created By',
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text': '{scenariosCount} scenarios assigned to {learnerCount} learners',
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text.AddLearners': 'Add Learners',
    'MursionPortal.Tab.EmailManagement.Analytics': 'Analytics',
    'MursionPortal.CustomizeLearnerInvitations.Teams.Invitation.Message': 'The following team(s) already have scheduled an invitation on this day:',
    'MursionPortal.CustomizeLearnerInvitations.New.Invitation.Message': 'Please make a note and start a new invitation',
    'MursionPortal.CustomizeLearnerInvitations.Learners.Invitation.Text': 'Learners will receive the invitation in the early morning hours (Pacific time) on this day.',
    'MursionPortal.CustomizeLearnerInvitations.SendDate.Label': 'Send Date',
    'MursionPortal.Scenario.Generation.FilterType.Label' : 'Generation',
    'MursionPortal.EngagementHub.ViewInvitations.CancelInvitation.Modal.Text': 'Are you sure you want to cancel the invitation created by {createdBy}?',
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackReport': 'Learner Feedback Report',
    'MursionPortal.EngagementHub.EmailSettings.CancellationByLearner': 'Cancellation by Learner',
    'MursionPortal.EngagementHub.EmailSettings.MissedSessionByLearner': 'Missed by Learner',
    'MursionPortal.EngagementHub.EmailSettings.LearnerReleased': 'Learner Released',
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockOut': 'Learner Lockout',
    'MursionPortal.EngagementHub.EmailSettings.SessionConfirmation': 'Session Confirmation',
    'MursionPortal.EngagementHub.EmailSettings.Subject.Invitation': 'Welcome to Mursion - a platform for you to practice your skills',
    'MursionPortal.EngagementHub.EmailSettings.Subject.InvitationReminder': `Reminder:You're invited to {licenseeName}`,
    'MursionPortal.EngagementHub.EmailSettings.Subject.Confirmation': 'Thanks for signing up! Your Simulation is confirmed.',
    'MursionPortal.EngagementHub.EmailSettings.Subject.FirstReminder': `Don't forget! Your Mursion simulation is soon!`,
    'MursionPortal.EngagementHub.EmailSettings.Subject.SecondReminder': `Get excited! Your Mursion simulation starts in {upcomingBeforeStart} minutes.`,
    'MursionPortal.EngagementHub.EmailSettings.Subject.CancellationByByLearner': 'Your simulation was canceled',
    'MursionPortal.EngagementHub.EmailSettings.Subject.MissedSessionByLearner': 'We missed you today!',
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerLockedOut': 'You missed/cancelled this scenario too many times',
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerReleased': 'Your session is available to schedule',
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerSessionRecordingIsReady': 'Want to see how you did? Visit your Mursion Dashboard.',
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerFeedbackReport': 'Want to see how you did? Review your session report.',
    'MursionPortal.EngagementHub.EmailSettings.LearnerSessionRecordingReady': 'Learner Session Recording Ready',
    'MursionPortal.EngagementHub.BackToEmailSettings': 'Back to Email Settings',
    'MursionPortal.EngagementHub.CustomizeEmail.Text': 'Learners will start receiving this email after you save.',
    'MursionPortal.NextGen.Learner.FeedbackFrom': 'Feedback from',
    'MursionPortal.NextGen.Scenario.SimMaterials': 'SIM Materials',
    'MursionPortal.NextGen.Scenario.LearnerMaterials': 'Learner Materials',
    'MursionPortal.EngagementHub.EmailSettings.Modal.BodyText': 'Are you sure you want to save the Enable/Disable settings',
    'MursionPortal.Client.EmailSettings.SessionRescheduledByPS': 'Session Rescheduled by PS',
    'MursionPortal.EngagementHub.EmailPreview.Sender': 'Sender',
    'MursionPortal.Scenario.CoverStory.Tooltip.Text':'Cover Story will be visible to clients in the scenario library as well as learners and SIMs in their materials',
    'MursionPortal.Scenario.Summary.Tooltip.Text':'Summary will be visible to clients in the scenario library',
    'MursionPortal.NextGen.Event.positiveMindset': 'There must be at least ({startCount}) positive mindset defined. You can specify a maximum of ({endCount}) positive mindsets.',
    'MursionPortal.NextGen.Event.derailingMindset': 'There must be at least ({startCount}) derailing mindset defined. You can specify a maximum of ({endCount}) derailing mindsets.',
    'MursionPortal.NextGen.Event.StrengthAssessment': 'There must be at least ({startCount}) strength assessment defined. You can specify a maximum of ({endCount}) strength assessments.',
    'MursionPortal.NextGen.Event.OpportunityAssessment': 'There must be at least ({startCount}) opportunity assessment defined. You can specify a maximum of ({endCount}) opportunity assessments.',
    'MursionPortal.NextGen.Event.StrengthAssessment.AvatarResponse': 'You have added {addedCount} avatar response for strength assessment(s), you must add at least {totalCount} avatar response for strength assessment(s).', 
    'MursionPortal.NextGen.Event.OpportunityAssessment.AvatarResponse': 'You have added {addedCount} avatar response for opportunity assessment(s), you must add at least {totalCount} avatar response for opportunity assessment(s).',
    'MursionPortal.Label.ClientLevelMissCancelSetting': 'Client Level Miss/Cancel Setting',
    'MursionPortal.Label.ProjectLevelMissCancelSetting':'Project Level Miss/Cancel Setting',
    'MursionPortal.Label.ScenarioLevelMissCancelSetting':'Scenario Level Miss/Cancel Setting',
    'MursionPortal.Label.MissedCancelledSessions':'Missed/Cancelled Sessions',
    'MursionPortal.NextGen.ScenarioReferences': 'Scenario References',
    'MursionPortal.NextGen.ChildScenarios': 'Child Scenarios',
    'MursionPortal.NextGen.Label.Customized': 'Customized',
    'MursionPortal.EngagementHub.ViewInvitations.Column.SenderName': 'Sender Name',
    'MursionPortal.NextGen.Event.Delete.ConfirmBox': 'Are you sure you want to delete this {message}?',
    'MursionPortal.NextGen.Event.Message.PositiveMindset': 'Positive Mindset',
    'MursionPortal.NextGen.Event.Message.DerailingMindset': 'Derailing Mindset',
    'MursionPortal.NextGen.Event.Message.StrengthAssessment': 'Strength assessment',
    'MursionPortal.NextGen.Event.Message.OpportunityAssessment': 'Opportunity assessment',
    'MursionPortal.PersonalizedMessageTitle': 'Personalized message header. This will appear before every personalized message in Portal.',
    'MursionPortal.EngagementHub.EmailSettings.InvitationPasswordUsers': 'Invitation (Password Users)',
    'MursionPortal.EngagementHub.EmailSettings.InvitationReminderPasswordUsers': 'Invitation Reminder (Password Users)',
    'MursionPortal.Label.Clicked': 'Clicked',
    'MursionPortal.Client.EmailSettings.SessionRescheduledByLearner': 'Session Rescheduled by Learner',
    'MursionPortal.Client.Team.CopyTeamInviteLink.Tooltip':'Users who have not yet registered or have not yet been added to the Portal will not be able to access this link. Please click the "Add Learners" button.',
    'Settings.Config.LearnerSessionReportDelay': 'Learner Session Report Delay',
    'MursionPortal.SkillsReferences.Label': 'Skills References',
    'MursionPortal.ResearchSummariesReferences.Label': 'Research Summaries References',
    'MursionPortal.Search.Text': 'Search or select',
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Event': 'Please select Event',
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Skill': 'Please select Skill',
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarBehavior': 'Avatar Behavior is required',
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExpectedLearnerResponse': 'Expected Learner Response is required',
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindset': 'Please Select Positive Mindset',
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindsetDescription': 'Positive mindset is required',
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindset': 'Please Select Derailing Mindset',
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindsetDescription': 'Derailing mindset is required',
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DescribeObservedAction': 'Describe Observed Action is required',
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExplainImpact': 'Explain Impact is required',
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarName': 'Please select avatar name',
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PossibleAvatarResponse':'Possible Avatar Response is required',
    'MursionPortal.Label.SchedulingLockoutDate': 'Scheduling Lockout Date',
    'MursionPortal.Label.SchedulingLockoutDate.Tooltip': 'Lockout effective date for all learners who miss and/or late cancel',
    'MursionPortal.NextGen.scorining.Message.MinimumScore': 'You’ve changed the number of events. Please update the minimum score.',
    'MursionPortal.NextGen.scorining.Message.SynopsisDescription': 'Synopsis description is required',
    'MursionPortal.NextGen.scorining.Message.SuccessfulAssessment': 'Successful Assessment is required',
    'MursionPortal.NextGen.scorining.Message.OpportunityAssessment': 'Opportunity Assessment is required',
    'MursionPortal.NextGen.BankScenarioReferences': 'Bank Scenario References',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerActivationStatus':'Learner Activation',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteTitle':'Unlock Your Team’s Potential',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteMessage':'Target invitations to your learners with custom messaging',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInvite':'Invite to Schedule',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetRegistered':'Not Yet Registered',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetScheduled':'Not Yet Scheduled',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ScheduledNotYetCompleted':'Scheduled - Not Yet Completed',
    'MursionPortal.NextGen.ViewAndInviteLearners': 'View & Invite Learners',
    'MursionPortal.Dashboard.Tooltip.Label.SchedulingEmail': 'Session Specific Emails and other Join Session Emails',
    'MursionPortal.Dashboard.EmailAnalytics.Label.InviteToScheduleEmail': 'Invite-to-Schedule Emails',
    'MursionPortal.Dashboard.Tooltip.Label.CallToActionEmail': 'Emails sent to learners inviting them to schedule scenario(s)',
    'MursionPortal.NextGen.Scenario.ClientCustomizations': 'Client Customizations',
    'MursionPortal.EngagementHub.EmailSettings.InviteToSchedule.Tooltip': 'Invite to Schedule emails are able to be customized each time you send them.',
    'MursionPortal.Contract.ContractTimezone': 'Contract Timezone',
    'Dashboard.LeftPane.Simulations.SessionSearch':'Search for Session',
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle': 'Client Scenario Title',
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle.Tooltip': 'Client learners and program admins will see this title only. SIMs will see the bank scenario title and client scenario title in their SIM materials.',
    'MursionPortal.NextGen.Event.DeleteMessage': 'Minimum 2 Events are mandatory for a Scenario.',
    'Settings.Config.ProjectAndScenario.AllowSimPracticeSession': 'SIM Practice Session',
    'MursionPortal.Calendar.Button.Practice.Session': 'Practice Session',
    'MursionPortal.Scenario.InTrainingSimSpecialist': 'In Training SIM Specialists',
    'MursionPortal.Scenario.NoInTrainingSimSpecialist': 'No In Training Sim Specialist',
    'MursionPortal.Simspecialist.Thankyou.summary': 'You are scheduled for your practice simulation',
    'MursionPortal.Button.DoNotSaveMyWork': 'Don’t Save my Work',
    'MursionPortal.NextGen.Confirm.BrowserBack': 'You have not saved your changes, and navigating away will cause you to lose your work. Please complete required fields.',
    'MursionPortal.ProjectCreate.DeliveryHours.Validation': 'Delivery Hours Start time should be less than End time.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetRegistered':'Unique number of learners who have been added to the Portal but have not yet completed registration',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetScheduled':'Unique number of learners who have registered but have not yet scheduled a session',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ScheduledNotYetCompleted':'Unique number of learners who have registered and scheduled at least one session but have not yet completed a session',
    'MursionPortal.Restriction': 'Restriction',
    'MursionPortal.Dashboard.SessionSearch.Placeholder' : 'Paste a session ID and hit return',
    'Operations.TechRestriction.CreateDialog.Title': 'New Holiday Tech Restriction',
    'Operations.TechRestriction.EditDialog.Title': 'Edit Holiday Tech Restriction',
    'Operations.TechRestriction.ViewDialog.Title': 'View Holiday Tech Restriction',
    'MursionPortal.Client.EmailSettings.TriggerHours': 'Trigger (Hours)',
    'MursionPortal.Client.EmailSettings.TriggerMin': 'Trigger (Minutes)',
    'MursionPortal.Duplicate.Scenario.Confirmation.Text': 'Are you sure you want to duplicate this scenario?',
    'MursionPortal.Contract.LineItem.ToolTip': 'A contract can only contain one type of line item. If your contract has both sessions and hours, please convert the sessions into hours and add it as an hours line item.',
    'MursionPortal.ReflectionGuide.LearnerPerspective': 'Learner Perspective',
    'MursionPortal.Dashboard.SessionSearch.Table.Placeholder': 'Search for Session will load once you search session ID', 
    'MursionPortal.Dashboard.SessionSearch.EmptySession': 'No sessions match that ID',
    'MursionPortal.SimMaterials.Label.IntroAndPreReflection': 'Intro & Pre-Reflection',
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown': 'Learner Breakdown',
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown.Activated': 'Activated',
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionNumber': 'Version Number',
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionDetails': 'Version Details',
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderHours': 'Trigger {tiggerValue} hours before Session.',
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderMinutes': 'Trigger {tiggerValue} minutes before Session.',
    'MursionPortal.EngagementHub.EmailSettings.FirstEmailReminder.Tooltip': 'Time before Session when First Reminder would go. If not set Licensee Setting would be used.',
    'MursionPortal.EngagementHub.EmailSettings.SecondEmailReminder.Tooltip': 'Time before Session when Second Reminder would go. If not set Licensee Setting would be used.',
    'MursionPortal.SimAttendance.MissedSimulation.Message': 'You missed this simulation so you are unable to edit attendance. Please contact Support if you did not miss the simulation or had a technical issue that caused you to miss the simulation.',
    'MursionPortal.EngagementHub.ViewInvitations.Disclaimer': 'Invites are visible for last 30 days only',
    'MursionPortal.PortalVersion': 'Portal Version',
    'MursionPortal.ScenarioInfo.Label.AutoSIM': 'AutoSIM',
    'MursionPortal.ProjectContract.AddConfirmation': 'Are you sure you want to add the contract to this project?',
    'MursionPortal.ProjectContract.CompletedContract.AddConfirmation': 'Are you sure you want to add the completed contract to this project?',
    'MursionPortal.Label.NewPortal' : 'New Portal',
    'MursionPortal.Label.OldPortal' : 'Old Portal',
    'MursionPortal.EngagementHub.EmailSettings.ReminderValidation': 'Second Reminder should be less than First Reminder',
    'MursionPortal.Project.InviteToSchedule.EmailDisabled.Tooltip' :'Invite to Schedule is disabled as email settings are disabled. Enable them to send this invite',
    'MursionPortal.SimAttendance.Error.SimReportedError' :'SIM Reported Error (Does not impact session status)',
    'MursionPortal.SimAttendance.Error.SimLateOrNoShow' :'SIM late or no show',
    'MursionPortal.SimAttendance.Error.SessionStartedTooEarly' :'Session started too early',
    'MursionPortal.SimAttendance.Error.AvatarIssue' :'Avatar Issue',
    'MursionPortal.SimAttendance.Error.MouthlipSyncIssueDuringSession' :'Mouth/lip sync issue during session',
    'MursionPortal.SimAttendance.Error.Hardware' :'Hardware',
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForSim' :'Audio/video issue for SIM',
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForLearner' :'Audio/video issue for Learner',
    'MursionPortal.SimAttendance.Error.OtherSimHardwareIssues' :'Other SIM hardware issues (controller, laptop, etc.)',
    'MursionPortal.SimAttendance.Error.LearnerAndSimBothJoined' :'Learner and SIM both joined, but no connection established',
    'MursionPortal.SimAttendance.Error.SimMomentIssue' :'SIM Moment issue',
    'MursionPortal.SimAttendance.Error.WifiIssue' :'Wifi Issue',
    'MursionPortal.SimAttendance.Error.LearnerWifi' :'Learner Wifi',
    'MursionPortal.SimAttendance.Error.SimWifi' :'SIM Wifi',
    'MursionPortal.SimAttendance.Error.AdditionalDetails' :'Additional Details',
    'MursionPortal.SimAttendance.Error.DescribeYourError' :'Describe your error...',
    'MursionPortal.EngagementHub.EmailSettings.CancellationByProfessionalServiceOrAccountOwner': 'Cancellation by PS or AO',
    'MursionPortal.EngagementHub.EmailSettings.CompleteLearnerSurvey': 'Complete Learner Survey',
    'MursionPortal.EngagementHub.EmailSettings.Subject.CompleteLearnerSurvey': 'How\'d it go?',
    'MursionPortal.CustomizeInvitation.ButtonText.Label': 'Button Text',
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerLockedOut': 'You are locked out!',
    'MursionPortal.Filter.ReleaseLevel.Placeholder': 'Release Level',
    'MursionPortal.TOTP.Page.Heading': 'We just sent a security code to your {email}. Enter the code to sign in.',
    'MursionPortal.TOTP.ResendOTP.Text': 'Resend Code',
    'MursionPortal.TOTP.Code.Label': 'Code',
    'MursionPortal.TOTP.IncorrectCode.ErrorMsg': 'The code is incorrect. Enter the correct code.',
    'MursionPortal.TOTP.ResendNewCode.ErrorMsg': 'The code has expired, click "Resend" to receive a new code.',
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerReleased': 'You are ready to schedule again.',
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.FirstReminder': `You’ve an upcoming Mursion Session in {firstReminderTime} hours!`,
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.SecondReminder': `Your Mursion Session is starting in {upcomingBeforeStart} minutes!`,
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerFeedbackReport': 'Mursion - Review Your Session Performance Report.',
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerSessionRecordingIsReady': 'Your Session Recording is now up! Review how you did',
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.Confirmation': 'Your Mursion Session is Confirmed!',
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.CancellationByLearnerOrPSOrAO': 'Your {licenseeName} session was canceled',
    'MursionPortal.Personalized.PathwayPage.Section': 'Message you want learners to see on Pathway Page',
    'MursionPortal.Personalized.CreateNewPathway.Button.Label': 'Create new for specific pathway',
    'Projects.ScenarioList.NoPathwaysFound': 'No Pathways have been found.',
    'MursionPortal.Personalized.Pathway.Search.Placeholder': 'Search or select Pathway(s):',
    'Mursion.Portal.Status.SimTechnicalError': 'Sim Technical Error',
    'MursionPortal.SimAttendance.Sim.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Sim’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',
    'Session.Edit.Modal.Notes.BlankNoteValidation': 'Either add a valid note or remove this block',
    'MursionPortal.Scenario.Error.Msg': 'Please complete required field.',
    'MursionPortal.Scenario.Skills.Error.Msg': 'The number of skills must be in the range of {minSkills} and {maxSkills}.',
    'MursionPortal.Capitalized.Incomplete': 'INCOMPLETE',
    'MursionPortal.Dashboard.Chart.LicenseBreakdown.Title': 'License Breakdown – Contract Item',
    'MursionPortal.Dashboard.Chart.LearnerLicenses': 'Learner Licenses',
    'MursionPortal.Dashboard.Chart.LearnersRegistered': 'Learners Registered',
    'MursionPortal.Dashboard.Chart.LearnersScheduled': 'Learners Scheduled',
    'MursionPortal.SessionAttendance.AddAttendees': 'Add attendees',
    'MursionPortal.SessionAttendance.SimulationSpecialist': 'Simulation Specialist',
    'MursionPortal.Dashboard.SimulationSpecialist.Status': 'Simulation Specialist Status',
    'MursionPortal.Dashboard.Technical.Issue.Summary': 'If a technical issue occurred during this session, please indicate the type(s) of issue encountered:',
    'MursionPortal.Dashboard.Provide.Details': 'Please provide more details on what happened',
    'MursionPortal.SessionAttendance.SwitchToSimRole.Message': 'Please switch role to Sim Specialist to view the page!',
    'MursionPortal.SessionAttendance.Heading': '<code>Instructions:</code> Review the Session, Simulation Specialist, and Learner Status\' below and update as required. This form may be edited for 24 hours after the end of the session',
    'MursionPortal.SessionAttendance.SessionStatus.Label': 'This is automatically determined by the Learner and Simulation Specialist status. No action required.',
    'MursionPortal.SessionAttendance.SessionDetails.Heading': '(selections below do not impact session status)',
    'MursionPortal.SessionAttendance.SessionDetails.Instructions': '<code>Instructions:</code> Session details <code>must</code> be provided for any session that has a Learner Status other than ‘Complete’ or ‘No Show’. To complete, select one or more issue type(s) and describe details in the text box. Session detail information does not impact the Learner, Simulation Specialist, or Session status.',
    'MursionPortal.SessionAttendance.SessionDetails.Text': `Mursion Simulation Specialists only: If you filled out a UTS ticket for this session, please include the link to the Slack post in the text box (click the 3 vertical dots to the right of the post and select ‘Copy link').`,
    'MursionPortal.SessionAttendance.SessionStatus.Simspecialist.Label': 'This is automatically determined by the system. If you encountered an error that prevented the session from being completed and the indicated status is ‘Left’, select ‘Technical Error (SIMS)’. Otherwise, no action required.',
    'MursionPortal.SessionAttendance.SessionStatus.Learner.Label': 'This is automatically determined by the system. If the indicated status is inaccurate, select the correct status and explain the reason for the revision in Session Details. Otherwise, no action required.',
    'MursionPortal.SessionAttendance.SessionStatus.SimStatus.Label': 'Technical Error (SIMs)',
    'Mursion.Portal.Status.UnableToComplete': 'Unable to Complete',
    'Mursion.Portal.Status.ElectedToLeave': 'Elected to Leave',
    'Mursion.Portal.Status.TechnicalErrorLearner': 'Technical Error (Learner)',
    'MursionPortal.SessionAttendance.Error.Audio': 'Audio',
    'MursionPortal.SessionAttendance.Error.ConnectionInternet': 'Connection / Internet',
    'MursionPortal.SessionAttendance.Error.LearnerTechnicalIssues': 'Learner technical issues',
    'MursionPortal.SessionAttendance.Error.MursionTechnicalIssues': 'Mursion technical issues',
    'MursionPortal.SessionAttendance.Error.OtherTechnicalIssues': 'Other technical issues',
    'MursionPortal.SessionAttendance.Error.MomentSoftware': 'Moment Software',
    'MursionPortal.SessionAttendance.Error.AvatarOrEnvironmentLipSyncMovementEtc': 'Avatar or Environment (lip sync, movement, etc.)',
    'MursionPortal.SessionAttendance.Error.HardwareControllerLaptopEtc': 'Hardware (controller, laptop, etc.)',
    'MursionPortal.SessionAttendance.Error.LearnerAndSimulationSpecialistBothJoined': 'Learner and Simulation Specialist both joined, no connection established',
    'MursionPortal.SessionAttendance.Error.OtherPleaseExplainBelow': 'Other (please explain below)',
    'MursionPortal.SessionAttendance.Error.NonTechnicalIssues': 'Non-technical issues',
    'MursionPortal.SessionAttendance.Error.LearnerMaterialsOrPreparation': 'Learner materials or preparation ',
    'MursionPortal.SessionAttendance.Error.LearnerLanguageFluency': 'Learner language fluency',
    'MursionPortal.SessionAttendance.Error.LearnerElectedToLeave': 'Learner elected to leave',
    'MursionPortal.Dashboard.Overview.Empty.Body.Message': 'Contract report will load once you make a selection',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Simulation':'Session Breakdown – Simulation Contract Item',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Hour':'Session Breakdown – Hour Contract Item',
    'MursionPortal.Copy.Tooltip.Text': 'Copy (plain text only, no formatting)',
    'MursionPortal.RTF.DropDown.Text.Green': 'Green',
    'MursionPortal.RTF.DropDown.Text.Thick': 'Thick',
    'MursionPortal.Status.Connected.Label': 'Connected',
    'MursionPortal.Status.Immersing.Label': 'Immersing',
    'MursionPortal.Dashboard.NonTechnical.Issue.Summary': 'If a non-technical issue occurred during this session, please indicate the type(s) of issue encountered:',
    'MursionPortal.AutoSim.Scenario.TooltipLabel': 'AutoSim scenario can’t be scheduled.',
    'MursionPortal.Scenario.Planing.AutoAssignTeam': 'Auto-Assign Team',
    'MursionPortal.Scenario.Planing.AutoAssignTeam.Tooltip': 'Automatically assign this scenario to the selected teams when learners access the scenario page via the provided link.',
    'MursionPortal.Dashboard.Overview.NoAssociatedDataForSelectedContract': 'No associated data for the selected contract(s)',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelOne': 'Level 1',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelTwo': 'Level 2',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelThree': 'Level 3',
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Title': 'Make sure you’re ready to take the session by testing your connection before joining.',
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Button.Label': 'Test Connection',
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link.Title': 'If any test fails, copy results at the end of the test and share them with our support team by',
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link': 'Submitting a Support ticket.',
    'MursionPortal.Dashboard.OnDemand.Text':'Ready, set, practice on-the-go',
    'MursionPortal.Dashboard.OnDemand.Title':'Try On-Demand Simulations',
    'MursionPortal.Dashboard.OnDemand.SubTitle':'Get a first look at the future of skill-building at work - dynamic GenAI simulations available instantly, no scheduling required. Practice anytime and receive actionable feedback that helps you grow and master new skills.',
    'MursionPortal.Dashboard.OnDemand.SmallTitle':'Early access, real results—get ahead now.',
    'MursionPortal.Dashboard.OnDemand.Button.Label':'Go to Mursion On-Demand',
    'MursionPortal.Dashboard.OnDemand.Button.Hide.Label':'Hide this',
    'MursionPortal.Dashboard.OnDemand.Preview':'PREVIEW',
    'MursionPortal.Dashboard.OnDemand.Text1':'✓ Scalable, focused practice',
    'MursionPortal.Dashboard.OnDemand.Text2':'✓ Flexible access',
    'MursionPortal.Dashboard.OnDemand.Text3':'✓ Targeted practice for mastery',
    'MursionPortal.Dashboard.OnDemand.Text4':'✓ Guided self-reflection',
    'MursionPortal.Dashboard.OnDemand.Mursion':'Mursion',
  },
};
