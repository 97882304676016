import { createIntl, createIntlCache } from 'react-intl';
import languages from 'src/i18n/languages';
import { LOCALES } from 'src/i18n/locales';

export const intl = () =>{
  let locale = localStorage.getItem("locale") || navigator.language;
  const supportedLanguages = Object.values(LOCALES);
  const index = supportedLanguages.indexOf(locale);
  if (index > -1) {
    locale = supportedLanguages[index];
  } else {
    locale = supportedLanguages[0];
  }
  const cache = createIntlCache();
  return createIntl({ locale, messages: languages[locale], }, cache);
};