import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import languages from './languages';
import selectors from 'src/app/redux/selectors';
import { useSelector } from 'react-redux';

const Provider = (props: any) => {

  const locale = useSelector(selectors.profile.getUserLanguage);

  return (
    <IntlProvider
      textComponent={Fragment}
      locale={locale}
      messages={languages[locale]}
    >
      {props.children}
    </IntlProvider>
  );
};

export default Provider;